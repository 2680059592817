import React from 'react';
import { EmptyState } from '../../../components/empty-states/EmptyState';
import EventListIllustration from '../../../components/images/illustrations/sipgate_team_eventlist.svg';
import { LinksState } from '../../../redux/slices/links';
import { Translate } from '../../../redux/slices/translations';
import { TimeToLiveSettingsLink } from '../TimeToLiveSettingsLink';
import { UserInfo } from '../../../redux/modules/userinfo';
import classes from './DisabledEventlistEmptyState.scss';

interface Props {
	translate: Translate;
	links: LinksState;
	userInfo: UserInfo;
}

export class DisabledEventlistEmptyState extends React.Component<Props> {
	public render() {
		if (!this.props.links.fetched) {
			return null;
		}

		return (
			<EmptyState
				title={this.props.translate('EVENTLIST_DISABLED_TITLE')}
				illustration={<img alt="" src={EventListIllustration} height="100%" />}
			>
				<p>{this.props.translate('EVENTLIST_DISABLED_TEXT')}</p>

				{this.props.userInfo.isAdmin ? (
					<div className={classes.linkContainer}>
						<TimeToLiveSettingsLink
							renderButton
							accountSettingsUrl={this.props.links.items.accountSettingsUrl}
							translate={this.props.translate}
						/>
					</div>
				) : (
					<p>{this.props.translate('EVENTLIST_DISABLED_NON_ADMIN_HINT')}</p>
				)}
			</EmptyState>
		);
	}
}
