import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../../utils/wrapper';
import { SliceData, useAction } from '../../../index';
import { discardEvent, fetchEvents, forceFetchEvents } from '../actions';
import { ApiAccountEvent } from '../../../../api/types/account-events';

export const useEvents = (): SliceData<ApiAccountEvent[]> => {
	const dispatch = useDispatch();
	const events = useSelector(state => state.events);

	useEffect(() => {
		dispatch(fetchEvents());
	}, [dispatch]);

	if (!events.fetched) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: events.items,
		fetched: true,
	};
};

export const useEvent = (name: string): SliceData<ApiAccountEvent> => {
	const dispatch = useDispatch();
	const events = useSelector(state => state.events);

	useEffect(() => {
		dispatch(forceFetchEvents());
	}, [dispatch]);

	if (!events.fetched) {
		return {
			data: null,
			fetched: false,
		};
	}

	const event = events.items.find(e => e.eventName === name);

	if (!event) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: event,
		fetched: true,
	};
};

export const useEventsAction = () => ({
	discardEvent: useAction(discardEvent),
});

export default useEvents;
