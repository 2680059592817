import { useEffect } from 'react';
import {
	Acd,
	createAcd,
	deleteAcd,
	fetchAcds,
	setAcdAgentDevices,
	setAcdAgents,
	setAcdFollowUpTime,
	setAcdGreetingAudioFile,
	setAcdName,
	setAcdQueueWaitingAudioFile,
	setAcdRingingOrder,
	setAcdRingTime,
	setAcdTeamLeads,
} from './index';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { SliceData, useAction } from '../../index';

export const useAcds = (): SliceData<Acd[]> => {
	const acds = useSelector(state => state.acds);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAcds());
	}, [dispatch]);

	if (!acds.fetched) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: acds.items,
		fetched: true,
	};
};

export const useAcdActions = () => ({
	createAcd: useAction(createAcd),
	setAcdAgents: useAction(setAcdAgents),
	setAcdAgentDevices: useAction(setAcdAgentDevices),
	setAcdName: useAction(setAcdName),
	deleteAcd: useAction(deleteAcd),
	setAcdQueueWaitingAudioFile: useAction(setAcdQueueWaitingAudioFile),
	setAcdGreetingAudioFile: useAction(setAcdGreetingAudioFile),
	setAcdFollowUpTime: useAction(setAcdFollowUpTime),
	setAcdRingTime: useAction(setAcdRingTime),
	setAcdRingingOrder: useAction(setAcdRingingOrder),
	setAcdTeamLeads: useAction(setAcdTeamLeads),
});
