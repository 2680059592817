import { useEffect } from 'react';
import { SliceData, useAction } from '../../index';
import { createJoyride, fetchJoyrides } from './actions';
import { useDispatch, useSelector } from '../../utils/wrapper';

export const useJoyrides = (): SliceData<string[]> => {
	const { fetched, items } = useSelector(state => state.joyrides);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchJoyrides());
	}, [dispatch]);

	if (!fetched) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: items,
		fetched: true,
	};
};

export const useJoyride = (name: string): boolean => {
	const { data, fetched } = useJoyrides();

	if (!fetched) {
		return true;
	}

	return data.includes(name);
};

export const useJoyridesActions = () => ({
	addSeenJoyride: useAction(createJoyride),
});
