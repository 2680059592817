import { handleActions } from '../../..';

import { UserCallRestrictionState } from './types';
import * as actions from './actions';

const initialState: UserCallRestrictionState = {
	fetching: false,
	fetched: false,
	items: [],
};

export default handleActions<UserCallRestrictionState, PossibleActions<typeof actions>>(
	{
		CALLRESTRICTIONS_FETCH_PENDING: state => ({ ...state, fetching: true }),

		CALLRESTRICTIONS_FETCH_SUCCESS: (state, { payload }) => {
			return {
				items: payload.items,
				fetching: false,
				fetched: true,
			};
		},

		CALLRESTRICTIONS_SET_PENDING: (state, { data }) => {
			return {
				fetching: false,
				fetched: true,
				items: state.items.map(item => {
					if (item.userId !== data.userId) {
						return item;
					}

					return {
						...item,
						[data.restriction]: data.enabled,
					};
				}),
			};
		},
	},
	initialState
);
