import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, ReduxProps } from '../redux';
import { setAfterVideoIdent } from '../redux/modules/identityVerification';

const mapDispatchToProps = {
	setAfterVideoIdent,
};

type Props = ReduxProps<typeof mapDispatchToProps> & RouteComponentProps;

class AfterVideoIdentView extends React.Component<Props> {
	public componentDidMount() {
		this.props.setAfterVideoIdent(true);
		this.props.history.replace('/');
	}

	public render() {
		return null;
	}
}

export default connect(undefined, mapDispatchToProps)(AfterVideoIdentView);
