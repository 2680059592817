import { Button } from '@panda/ui';
import React from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router';

import { Phoneline } from '../../../api/types/phonelines';
import Spinner from '../../../components/spinner/Spinner';
import { Group } from '../../../redux/modules/groups';
import { Translate } from '../../../redux/slices/translations';
import { OverlayTransition } from '../../../slide-in/OverlayTransition';
import { SlideIn } from '../../../slide-in/SlideIn';
import { activeFilterCount } from '../selections';
import { selectionParsers, Selections } from '../selections/parsers';
import { ChangeUrlParameter } from '../selections/types';
import { State as ReduxState } from '../state/state';
import { ConnectionFilter } from './ConnectionFilter';
import { DateFilter } from './DateFilter';
import { DirectionFilter } from './DirectionFilter';
import classes from './FilterGroup.scss';
import { LabelsFilter } from './LabelsFilter';
import { MarkingsFilter } from './MarkingsFilter';
import { PhonenumberFilter } from './PhonenumberFilter';
import { StatusFilter } from './StatusFilter';
import { TypeFilter } from './TypeFilter';
import { Acd } from '../../../redux/slices/acds';
import { SipgateDomain } from '../../../redux/modules/userinfo';

const FILTER_SLIDE_IN_PATH = 'filters';

type FilterTypes =
	| 'STATUS'
	| 'DIRECTION'
	| 'TYPE'
	| 'DATE'
	| 'LABELS'
	| 'MARKINGS'
	| 'CONNECTIONS'
	| 'PHONENUMBER';

interface ExternalProps {
	translate: Translate;
	domain: SipgateDomain;
	selections: Selections;
	labels: ReduxState['labels'];
	contacts: ReduxState['contacts'];
	onChange: ChangeUrlParameter;
	matchedEventCount: number | null;
	phonelines?: Phoneline[];
	groups?: Group[];
	acds?: Acd[];
}

interface State {
	openFilter: FilterTypes | null;
}

type Props = ExternalProps & RouteComponentProps;

class PureFilterGroup extends React.PureComponent<Props, State> {
	public state: State = {
		openFilter: null,
	};

	private onOpenClose(open: boolean, filter: FilterTypes) {
		if (open) {
			this.setState({ openFilter: filter });
		} else {
			this.setState(state => {
				if (state.openFilter === filter) {
					return {
						openFilter: null,
					};
				}

				return state;
			});
		}
	}

	private onStatusOpenClose = (open: boolean) => {
		this.onOpenClose(open, 'STATUS');
	};

	private onTypeOpenClose = (open: boolean) => {
		this.onOpenClose(open, 'TYPE');
	};

	private onDirectionOpenClose = (open: boolean) => {
		this.onOpenClose(open, 'DIRECTION');
	};

	private onDateOpenClose = (open: boolean) => {
		this.onOpenClose(open, 'DATE');
	};

	private onLabelsOpenClose = (open: boolean) => {
		this.onOpenClose(open, 'LABELS');
	};

	private onMarkingsOpenClose = (open: boolean) => {
		this.onOpenClose(open, 'MARKINGS');
	};

	private onConnectionsOpenClose = (open: boolean) => {
		this.onOpenClose(open, 'CONNECTIONS');
	};

	private onPhonenumberOpenClose = (open: boolean) => {
		this.onOpenClose(open, 'PHONENUMBER');
	};

	private onReset = () => {
		this.props.onChange({
			status: [],
			type: [],
			direction: [],
			starred: [],
			offset: 0,
			from: null,
			to: null,
			labelIds: [],
			read: [],
			connectionIds: [],
			phonenumber: '',
		});
	};

	private onOpenFilterSlideIn = () => {
		this.props.history.push({
			pathname: `${this.props.location.pathname}/${FILTER_SLIDE_IN_PATH}`,
			hash: this.props.location.hash,
		});
	};

	private onCloseFilterSlideIn = () => {
		this.props.history.push({
			pathname: this.props.match.url,
			hash: this.props.location.hash,
		});
	};

	private activeFilterCount(exclusions?: string[]) {
		return activeFilterCount(selectionParsers, this.props.selections, exclusions);
	}

	private renderDropdowns() {
		const FILTERS_FOR_EXCLUSION = ['direction', 'starred', 'read', 'type'];
		return (
			<div className={classes.filterGroup} data-test-selector="eventlist-filter">
				<MarkingsFilter
					open={this.state.openFilter === 'MARKINGS'}
					translate={this.props.translate}
					onOpenClose={this.onMarkingsOpenClose}
					readSelection={this.props.selections.read}
					starredSelection={this.props.selections.starred}
					onChange={this.props.onChange}
				/>
				<DirectionFilter
					open={this.state.openFilter === 'DIRECTION'}
					translate={this.props.translate}
					onOpenClose={this.onDirectionOpenClose}
					selection={this.props.selections.direction}
					onChange={this.props.onChange}
				/>
				<TypeFilter
					open={this.state.openFilter === 'TYPE'}
					translate={this.props.translate}
					onOpenClose={this.onTypeOpenClose}
					selection={this.props.selections.type}
					onChange={this.props.onChange}
				/>
				<StatusFilter
					open={this.state.openFilter === 'STATUS'}
					translate={this.props.translate}
					onOpenClose={this.onStatusOpenClose}
					selection={this.props.selections.status}
					onChange={this.props.onChange}
				/>
				<PhonenumberFilter
					translate={this.props.translate}
					onChange={this.props.onChange}
					contacts={this.props.contacts}
					phonenumber={this.props.selections.phonenumber}
					open={this.state.openFilter === 'PHONENUMBER'}
					onOpenClose={this.onPhonenumberOpenClose}
					domain={this.props.domain}
				/>
				{this.props.phonelines &&
				this.props.groups &&
				this.props.acds &&
				this.props.phonelines.length + this.props.groups.length + this.props.acds.length > 1 ? (
					<ConnectionFilter
						open={this.state.openFilter === 'CONNECTIONS'}
						translate={this.props.translate}
						selection={this.props.selections.connectionIds}
						onOpenClose={this.onConnectionsOpenClose}
						phonelines={this.props.phonelines}
						groups={this.props.groups}
						acds={this.props.acds}
						onChange={this.props.onChange}
					/>
				) : null}
				<LabelsFilter
					open={this.state.openFilter === 'LABELS'}
					translate={this.props.translate}
					selection={this.props.selections.labelIds}
					onOpenClose={this.onLabelsOpenClose}
					labels={this.props.labels}
					onChange={this.props.onChange}
				/>
				<DateFilter
					open={this.state.openFilter === 'DATE'}
					translate={this.props.translate}
					onOpenClose={this.onDateOpenClose}
					startDate={this.props.selections.from}
					endDate={this.props.selections.to}
					onChange={this.props.onChange}
					isSlideIn={false}
				/>
				<div className={classes.allFilters}>
					<Button onClick={this.onOpenFilterSlideIn}>
						{this.activeFilterCount() > 0
							? this.props.translate('ALL_FILTERS_ACTIVE', this.activeFilterCount())
							: this.props.translate('ALL_FILTERS')}
					</Button>
				</div>
				<div className={classes.moreFilters}>
					<Button onClick={this.onOpenFilterSlideIn}>
						{this.activeFilterCount(FILTERS_FOR_EXCLUSION) > 0
							? this.props.translate(
									'MORE_FILTERS_ACTIVE',
									this.activeFilterCount(FILTERS_FOR_EXCLUSION)
								)
							: this.props.translate('MORE_FILTERS')}
					</Button>
				</div>
				{this.activeFilterCount() > 0 ? (
					<div className={classes.reset}>
						<Button icon="close" iconOnly variant="quiet" onClick={this.onReset}>
							{this.props.translate('FILTER_GROUP_RESET_FILTERS')}
						</Button>
					</div>
				) : null}
			</div>
		);
	}

	private renderSlideInButton() {
		if (this.props.matchedEventCount === null) {
			return (
				<button type="button" className={classes.mobileCloseButton}>
					<Spinner className={classes.spinner} />
				</button>
			);
		}

		return (
			<button
				type="button"
				className={classes.mobileCloseButton}
				onClick={this.onCloseFilterSlideIn}
			>
				{this.props.matchedEventCount === 0
					? this.props.translate('FILTER_FOUND_NO_EVENTS')
					: this.props.translate('FILTER_FOUND_EVENTS', this.props.matchedEventCount)}
			</button>
		);
	}

	private renderSlideIn() {
		return (
			<SlideIn
				title={this.props.translate('FILTER_SLIDE_IN_TITLE')}
				onClose={this.onCloseFilterSlideIn}
			>
				<div className={classes.filterSlideIn}>
					<div className={classes.slideInFilters}>
						<div className={classes.visibilitySwitchForFilters}>
							<MarkingsFilter
								translate={this.props.translate}
								readSelection={this.props.selections.read}
								starredSelection={this.props.selections.starred}
								onChange={this.props.onChange}
							/>
							<DirectionFilter
								translate={this.props.translate}
								selection={this.props.selections.direction}
								onChange={this.props.onChange}
							/>
							<TypeFilter
								translate={this.props.translate}
								selection={this.props.selections.type}
								onChange={this.props.onChange}
							/>
						</div>
						<StatusFilter
							translate={this.props.translate}
							selection={this.props.selections.status}
							onChange={this.props.onChange}
						/>
						<PhonenumberFilter
							translate={this.props.translate}
							onChange={this.props.onChange}
							contacts={this.props.contacts}
							phonenumber={this.props.selections.phonenumber}
							domain={this.props.domain}
						/>
						{this.props.phonelines &&
						this.props.groups &&
						this.props.acds &&
						this.props.phonelines.length + this.props.groups.length + this.props.acds.length > 1 ? (
							<ConnectionFilter
								translate={this.props.translate}
								selection={this.props.selections.connectionIds}
								onChange={this.props.onChange}
								phonelines={this.props.phonelines}
								groups={this.props.groups}
								acds={this.props.acds}
							/>
						) : null}
						<LabelsFilter
							translate={this.props.translate}
							selection={this.props.selections.labelIds}
							labels={this.props.labels}
							onChange={this.props.onChange}
						/>

						<DateFilter
							translate={this.props.translate}
							startDate={this.props.selections.from}
							endDate={this.props.selections.to}
							onChange={this.props.onChange}
							isSlideIn
						/>
					</div>
					{this.activeFilterCount() > 0 ? this.renderSlideInButton() : null}
				</div>
			</SlideIn>
		);
	}

	public render() {
		return (
			<>
				{this.renderDropdowns()}

				<OverlayTransition
					transitionClasses={SlideIn.transitionClasses}
					timeout={SlideIn.transitionDuration}
					location={this.props.location}
					basepath={this.props.match.url}
				>
					<Route
						path={`${this.props.match.url}/${FILTER_SLIDE_IN_PATH}`}
						render={() => this.renderSlideIn()}
					/>
				</OverlayTransition>
			</>
		);
	}
}

export const FilterGroup = withRouter(PureFilterGroup);
