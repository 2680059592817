import React from 'react';
import { EmptyState } from '@panda/ui';
import { Location, History } from 'history';
import { Route, Switch } from 'react-router';
import { compile as insertParamsIntoPath } from 'path-to-regexp';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import { chainValidators, validateMaxLength, validateNonEmpty } from '@web-apps/forms';
import { State as StoreState } from '../state/state';
import { Translate } from '../../../redux/slices/translations';
import { CreateLabelSlideInContent } from './CreateLabelSlideInContent';
import { EditLabelSlideInContent } from './EditLabelSlideInContent';
import { EmptyLabelSlideInContent } from './EmptyLabelSlideInContent';
import { ListLabelSlideInContent } from './ListLabelSlideInContent';
import { SlideIn } from '../../../slide-in/SlideIn';

import CenteredSpinner from '../../../components/spinner/CenteredSpinner';
import classes from './LabelSlideIn.scss';
import { ApiLabel } from '../../../api/types/labels';
import { InlineMarkdown } from '../../../helpers/Markdown';
import emptyLabelIllustration from '../../../media/illustrations/error.svg';
import {
	labelContainsInvalidCharacters,
	labelIsSystemLabel,
} from '../../../redux/modules/labels/validators';

interface Props {
	translate: Translate;
	onClose: () => void;
	basePath: `/${string}`;

	labels: StoreState['labels'];
	events: StoreState['entries'];

	createLabel: (name: string) => Promise<ApiLabel>;
	deleteLabel: (labelId: number) => Promise<void>;
	editLabel: (id: number, newName: string) => Promise<void>;
	onAttachLabel: (labelId: number) => void;
	onDetachLabel: (labelId: number) => void;

	signalFailure: (message: string) => void;
	history: History;
	location: Location;
}

export class LabelSlideIn extends React.PureComponent<Props> {
	public componentDidUpdate() {
		if (!this.props.events.result) {
			return;
		}

		if (!this.props.events.result.normalized.find(event => event.selected)) {
			this.props.onClose();
		}
	}

	private isSubPage = () => this.props.basePath !== this.props.location.pathname;

	private editLabelPath = () => `${this.props.basePath}/:labelId` as const;

	private createLabelPath = () => `${this.props.basePath}/create` as const;

	private buildEditLabelLink = (labelId: number) => {
		return insertParamsIntoPath(this.editLabelPath())({ labelId });
	};

	private goToList = () => {
		this.props.history.replace({
			pathname: this.props.basePath,
			hash: this.props.location.hash,
		});
	};

	private createLabel = (name: string) => {
		this.props.createLabel(name).then(
			(label: ApiLabel) => this.props.onAttachLabel(label.id),
			() => {
				this.props.signalFailure(this.props.translate('SYSTEM_LABEL_ERROR', name));
			}
		);
	};

	private deleteLabel = (id: number) => {
		this.props
			.deleteLabel(id)
			.catch(() => this.props.signalFailure(this.props.translate('LABEL_DELETE_ERROR')));
	};

	private editLabel = (id: number, newName: string) => {
		this.props.editLabel(id, newName).catch(() => {
			this.props.signalFailure(this.props.translate('LABEL_CREATE_ERROR', newName));
		});
	};

	private validateLabel = (translate: Translate, currentLabel?: string) =>
		chainValidators(
			validateNonEmpty(this.props.translate),
			validateMaxLength(this.props.translate, 24),
			value => {
				if (labelContainsInvalidCharacters(value)) {
					return { valid: false, error: translate('LABEL_CREATE_INVALID_CHARACTER_ERROR') };
				}

				if (
					this.props.labels &&
					this.props.labels.find(label => label.name.toLowerCase() === value.toLocaleLowerCase()) &&
					value.toLowerCase() !== currentLabel?.toLowerCase()
				) {
					return { valid: false, error: translate('LABEL_CREATE_EXISTS_ERROR') };
				}

				if (labelIsSystemLabel(value)) {
					return { valid: false, error: translate('LABEL_CREATE_SYSTEM_LABEL_ERROR') };
				}

				return { valid: true, value };
			}
		);

	private renderTitle() {
		return (
			<Switch>
				<Route path={this.props.basePath} exact>
					{this.props.labels && this.props.labels.length === 0
						? this.props.translate('LABEL_CREATE_SLIDE_IN_TITLE')
						: this.props.translate('LABEL_SLIDE_IN_TITLE')}
				</Route>
				<Route path={this.createLabelPath()}>
					{this.props.translate('LABEL_CREATE_SLIDE_IN_TITLE')}
				</Route>
				<Route path={this.editLabelPath()}>
					{this.props.translate('LABEL_EDIT_SLIDE_IN_TITLE')}
				</Route>
			</Switch>
		);
	}

	private renderLoading() {
		return <CenteredSpinner />;
	}

	private renderError() {
		return (
			<EmptyState
				actionElements={[]}
				heading={this.props.translate('EDIT_LABEL_ERROR_HEADLINE')}
				image={<img src={emptyLabelIllustration} width="100%" alt="" />}
				text={
					<InlineMarkdown>{this.props.translate('EDIT_LABEL_ERROR_DESCRIPTION')}</InlineMarkdown>
				}
			/>
		);
	}

	private renderList() {
		if (!this.props.labels || !this.props.events.result) {
			return null;
		}

		if (this.props.labels.length === 0) {
			return (
				<EmptyLabelSlideInContent
					translate={this.props.translate}
					createLabel={this.createLabel}
					validateLabel={this.validateLabel}
				/>
			);
		}

		return (
			<ListLabelSlideInContent
				translate={this.props.translate}
				onClose={this.props.onClose}
				labels={this.props.labels}
				events={this.props.events.result.normalized}
				createLabel={this.createLabel}
				onAttachLabel={this.props.onAttachLabel}
				onDetachLabel={this.props.onDetachLabel}
				createLabelPath={this.createLabelPath()}
				validateLabel={this.validateLabel}
				buildEditLabelLink={this.buildEditLabelLink}
				history={this.props.history}
			/>
		);
	}

	private renderContent() {
		if (this.props.events.error) {
			return this.renderError();
		}

		if (!this.props.labels || !this.props.events.result) {
			return this.renderLoading();
		}

		return (
			<TransitionGroup>
				<CSSTransition
					classNames={{
						enter: classes.enter,
						enterActive: classes.enterActive,
						exit: classes.exit,
						exitActive: classes.exitActive,
					}}
					timeout={200}
					key={this.props.location.key}
				>
					<div className={classnames(classes.content, { [classes.subPage]: this.isSubPage() })}>
						<Switch location={this.props.location}>
							<Route path={this.props.basePath} exact render={() => this.renderList()} />

							<Route
								path={this.createLabelPath()}
								render={() => (
									<CreateLabelSlideInContent
										translate={this.props.translate}
										onBack={this.goToList}
										createLabel={this.createLabel}
										validateLabel={this.validateLabel}
									/>
								)}
							/>

							<Route
								path={`${this.props.basePath}/:labelId`}
								render={routerProps => {
									if (!this.props.labels) {
										return null;
									}

									return (
										<EditLabelSlideInContent
											translate={this.props.translate}
											onBack={this.goToList}
											labels={this.props.labels}
											labelId={routerProps.match.params.labelId}
											editLabel={this.editLabel}
											deleteLabel={this.deleteLabel}
											validateLabel={this.validateLabel}
										/>
									);
								}}
							/>
						</Switch>
					</div>
				</CSSTransition>
			</TransitionGroup>
		);
	}

	public render() {
		return (
			<SlideIn
				title={this.renderTitle()}
				onBack={this.isSubPage() ? this.goToList : undefined}
				onClose={this.props.onClose}
			>
				{this.renderContent()}
			</SlideIn>
		);
	}
}
