import React from 'react';
import { Step } from 'react-joyride';
import { TourProps } from './types';
import JoyrideTour from './JoyrideTour';
import tileClasses from '../../views/admin-dashboard/components/Tile/Tile.scss';
import { getRestriction, useRestrictions } from '../../redux/modules/restrictions';
import { useFeatureFlag, useUserInfo } from '../../redux/modules/userinfo';

const ChannelReleaseTour = (props: TourProps) => {
	const restrictions = useRestrictions(['CAN_USE_ACD']);
	const isAcdLegacyCustomer = useFeatureFlag('ACD_LEGACY_CUSTOMER');
	const userInfo = useUserInfo();

	if (!restrictions.fetched) {
		return null;
	}

	const canUseChannels = getRestriction(restrictions.data, 'CAN_USE_ACD', userInfo.sub).value;

	if (!canUseChannels || isAcdLegacyCustomer) {
		return null;
	}

	const steps: Step[] = [
		{
			target: `.${tileClasses.channelReleaseTourTarget}`,
			title: props.translate('CHANNEL_RELEASE_TOUR_TITLE'),
			content: props.translate.markdown.block('CHANNEL_RELEASE_TOUR_DESCRIPTION'),
			disableBeacon: true,
		},
	];

	return <JoyrideTour onDone={props.onDone} steps={steps} translate={props.translate} />;
};

export default ChannelReleaseTour;
