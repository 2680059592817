/* eslint-disable react/no-unused-prop-types */
import React, { ComponentProps } from 'react';
import { SubTab } from './SubTab';

type Props = {
	id: string;
	/**
	 * Das `label` beschreibt den Inhalt des Tabs in 1-2 Wörtern.
	 */
	label: string;
	sublabel: string;
	children:
		| React.ReactElement<ComponentProps<typeof SubTab>>[]
		| React.ReactElement<ComponentProps<typeof SubTab>>;
	isActive: boolean;
	isSelected?: boolean;
	/** Der disabled State kann auch über den Context gesteuert werden. [Info zu Disabled Context](../?path=/docs/utilities-disabledcontext--docs) */
	disabled?: boolean;
};

/**
 * This components exist so the usage of <Tabs/> has a pretty API
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MainTab = (props: Props) => null;
