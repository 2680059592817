import React from 'react';
import { Translate, useTranslate } from '../redux/slices/translations';

export interface TranslateProps {
	translate: Translate;
}

export function withTranslate<Props extends TranslateProps = TranslateProps>(
	Component: React.ComponentType<Props>
) {
	return (props: Omit<Props, keyof TranslateProps>) => {
		const translate = useTranslate();

		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...(props as Props)} translate={translate} />;
	};
}
