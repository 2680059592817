import { AppSubscriptionName } from '../../../../../redux/slices/sipgateApp';

export const getLanguageKeyForSubscriptionName = (subscriptionName: AppSubscriptionName) => {
	switch (subscriptionName) {
		case 'PRO':
			return 'SIPGATE_APP_CLINQ_PRO';
		case 'PREMIUM':
			return 'SIPGATE_APP_CLINQ_PREMIUM';
		case 'FREE':
		default:
			return 'SIPGATE_APP_CLINQ_FREE';
	}
};
