import { PersonalAccessTokenState } from '.';
import { UserInfoState } from '../userinfo';

export const isPersonalAccessTokenFetching = (
	webuserId: string,
	state: PersonalAccessTokenState
): boolean => {
	return state.fetchingForUser.includes(webuserId);
};

export const isPersonalAccessTokenFetched = (
	webuserId: string,
	state: PersonalAccessTokenState
): boolean => {
	return state.fetchedForUser.includes(webuserId);
};

export const isFetching = (
	userInfo: UserInfoState,
	personalAccessTokenState: PersonalAccessTokenState,
	webuserId: string
) => {
	if (!userInfo.fetched) {
		return true;
	}

	return !isPersonalAccessTokenFetched(webuserId, personalAccessTokenState);
};
