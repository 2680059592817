import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchFaxlines } from './actions';

export const useFaxlines = () => {
	const dispatch = useDispatch();

	const faxlines = useSelector(s => s.faxlines);

	useEffect(() => {
		dispatch(fetchFaxlines());
	}, [dispatch]);

	return faxlines.fetched ? faxlines.items : null;
};
