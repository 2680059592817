import React from 'react';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import Calendar from 'react-calendar';
import '../../../components/react-calendar.scss';

import classnames from 'classnames';
import { ChangeUrlParameter } from '../selections/types';
import { Translate } from '../../../redux/slices/translations';
import { FilterDropdown } from './FilterDropdown';
import { Filter } from './Filter';
import classes from './DateFilter.scss';

interface Props {
	open?: boolean;
	translate: Translate;
	onOpenClose?: (open: boolean) => void;
	onChange: ChangeUrlParameter;
	startDate: Moment | null;
	endDate: Moment | null;
	isSlideIn: boolean;
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export class DateFilter extends React.PureComponent<Props> {
	private onReset = () => {
		this.props.onChange({
			from: null,
			to: null,
		});

		if (this.props.onOpenClose) {
			this.props.onOpenClose(false);
		}
	};

	private handleDateChange = ([startDate, endDate]: [Date, Date] | [Date]) => {
		this.props.onChange({
			from: moment(startDate),
			to: moment(endDate),
		});
	};

	private getButtonLabel = () => {
		const start = this.props.startDate ? this.props.startDate.format('DD.MM') : '';
		const end = this.props.endDate ? this.props.endDate.format('DD.MM') : '';

		if (!this.props.startDate && !this.props.endDate) {
			return this.props.translate('FILTER_DATE');
		}
		if (!this.props.startDate) {
			return end;
		}
		if (!this.props.endDate) {
			return start;
		}
		return `${start} - ${end}`;
	};

	private isActive() {
		return !!(this.props.startDate || this.props.endDate);
	}

	private renderContent() {
		const startDate: ValuePiece = this.props.startDate?.toDate() || null;
		const endDate: ValuePiece = this.props.endDate?.toDate() || null;

		return (
			<div
				className={classnames(classes.calendarWrapper, { [classes.slideIn]: this.props.isSlideIn })}
			>
				<Calendar
					value={[startDate, endDate]}
					maxDate={moment().endOf('day').toDate()}
					maxDetail="month"
					minDetail="month"
					selectRange
					className={classes.calendar}
					defaultView="month"
					onChange={(dates: Value) => {
						if (
							Array.isArray(dates) &&
							dates.length === 2 &&
							dates[0] != null &&
							dates[1] != null
						) {
							this.handleDateChange(dates as [Date, Date]);
						}
					}}
					nextAriaLabel={this.props.translate('FILTER_DATE_NEXT_MONTH')}
					next2AriaLabel={this.props.translate('FILTER_DATE_MONTH_NEXT_YEAR')}
					prevAriaLabel={this.props.translate('FILTER_DATE_PREVIOUS_MONTH')}
					prev2AriaLabel={this.props.translate('FILTER_DATE_MONTH_PREVIOUS_YEAR')}
				/>

				{this.isActive() ? (
					<button type="button" className={classes.reset} onClick={this.onReset}>
						{this.props.translate('FILTER_DATE_RESET')}
					</button>
				) : null}
			</div>
		);
	}

	private renderDropdown() {
		if (!this.props.onOpenClose) {
			return null;
		}

		return (
			<FilterDropdown
				buttonLabel={this.getButtonLabel()}
				active={this.isActive()}
				onOpenClose={this.props.onOpenClose}
				open={!!this.props.open}
				disableFocustrap
				className={classes.dropdown}
			>
				{this.props.open ? this.renderContent() : null}
			</FilterDropdown>
		);
	}

	public render() {
		if (this.props.onOpenClose) {
			return this.renderDropdown();
		}

		return <Filter title={this.props.translate('FILTER_DATE')}>{this.renderContent()}</Filter>;
	}
}
