import { Trunk } from './types';

export const selectTrunk = (trunks: Trunk[], trunkId: string) =>
	trunks.find(trunk => trunk.id === trunkId);

export const selectTotalContingentUsage = (contingents: { amount: number; used: number }[]) => {
	if (contingents.length === 0) {
		return null;
	}

	return contingents.reduce(
		(sum, contingent) => ({
			used: sum.used + contingent.used,
			amount: sum.amount + contingent.amount,
		}),
		{ used: 0, amount: 0 }
	);
};
