import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useOutsideClick } from '@panda/ui/src/hooks/useOutsideClick';
import { SideNavigationMenuGroup } from '../SideNavigation.types';
import { SideNavigationLink } from '../SideNavigationLink/SideNavigationLink';
import classes from './SideNavigationGroup.scss';
import {
	ActiveGroupContext,
	AreColorsInvertedContext,
	IsSideNavigationOpenContext,
	IsTransitioningContext,
} from '../SideNavigation.utils';
import { useTranslate } from '../../../redux/slices/translations';

type Props = {
	item: SideNavigationMenuGroup;
	toggleOpenGroup: () => void;
};

export const SideNavigationGroup = ({ item, toggleOpenGroup }: Props) => {
	const isSideNavigationOpen = React.useContext(IsSideNavigationOpenContext);
	const ref = React.useRef<HTMLLIElement>(null);
	const isTransitioning = React.useContext(IsTransitioningContext);
	const areColorsInverted = React.useContext(AreColorsInvertedContext);
	const openGroup = React.useContext(ActiveGroupContext);
	const history = useHistory();
	const open = openGroup.includes(item.title);

	const hasOpenSubpage = item.links.some(
		link =>
			(link.isActive && link.isActive(history.location.pathname)) ||
			link.path === history.location.pathname
	);

	useOutsideClick(ref, () => {
		if (open && !isSideNavigationOpen) {
			toggleOpenGroup();
		}
	});

	useEffect(() => {
		if (hasOpenSubpage && isSideNavigationOpen && !open) {
			toggleOpenGroup();
		}

		// eslint-disable-next-line
	}, [hasOpenSubpage, isSideNavigationOpen]);

	const translate = useTranslate();

	return (
		<li
			className={classnames(classes.groupElement, {
				[classes.invertColors]: areColorsInverted,
				[classes.closed]: !isSideNavigationOpen,
				[classes.transitioning]: isTransitioning,
			})}
			key={`${item.kind}_${item.title}`}
			ref={ref}
		>
			<button
				className={classnames({ [classes.open]: open, [classes.hasOpenSubPage]: hasOpenSubpage })}
				type="button"
				onClick={toggleOpenGroup}
				role="menuitem"
			>
				{item.icon && (
					<span
						data-testid="icon"
						className={classes.icon}
						style={{ maskImage: `url(${item.icon})`, WebkitMaskImage: `url(${item.icon})` }}
					/>
				)}

				<span className={classes.text}>{translate(item.title)}</span>
			</button>
			<ul
				className={classnames(classes.group, { [classes.open]: open })}
				aria-labelledby={`side_navigation_group_${item.title}`}
			>
				<li
					className={classes.groupTitle}
					aria-hidden="true"
					id={`side_navigation_group_${item.title}`}
				>
					{translate(item.title)}
				</li>
				{item.links.map(link => (
					<SideNavigationLink key={link.path} item={link} inGroup />
				))}
			</ul>
		</li>
	);
};
