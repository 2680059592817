import React, { Dispatch } from 'react';

interface TableContextType {
	isSmallView: boolean;
	activeRow: number;
	setActiveRow: Dispatch<number>;
	hasControls: boolean;
	headerTitles: string[];
}

export const TableContext = React.createContext<TableContextType | undefined>(undefined);

export const useTableContext = () => {
	const context = React.useContext(TableContext);

	if (context === undefined) {
		throw new Error('useTableContext needs to be used inside a TableContext.Provider');
	}

	return context;
};
