import { Headline } from '@panda/ui';
import React from 'react';
import classnames from 'classnames';
import classes from './Widget.scss';

interface Props {
	headline: string;
	children: React.ReactNode;
	fancy?: boolean;
}

export const Widget = ({ headline, children, fancy }: Props) => (
	<>
		<Headline className={classes.headline}>{headline}</Headline>
		<div className={classnames(classes.container, { [classes.fancy]: !!fancy })}>{children}</div>
	</>
);
