/* eslint-disable react/no-unused-prop-types */
import React from 'react';

export type Props = {
	/**
	 * Ein eindeutiger Bezeichner für die Column.
	 */
	columnKey: string;
	children: React.ReactNode;
};

/**
 * This components exist so the usage of <ListNavView/> has a pretty API
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ListNavViewColumn = (props: Props) => null;
