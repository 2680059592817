import { useCallback, useEffect } from 'react';
import {
	createUserNotification as createUserNotificationAction,
	deleteUserNotification,
	fetchUserNotifications,
	replaceUserNotification,
} from './actions';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	CreateUserNotificationRequest,
	ReplaceUserNotificationRequest,
} from '../../../api/types/userNotification';
import { forceFetchLabels } from '../labels';

export const useUserNotifications = () => {
	const dispatch = useDispatch();
	const userNotifications = useSelector(state => state.userNotifications);

	useEffect(() => {
		dispatch(fetchUserNotifications());
	}, [dispatch]);

	return userNotifications.fetched ? userNotifications.items : null;
};

export const useCreateUserNotification = () => {
	const dispatch = useDispatch();

	return useCallback(
		async (createUserNotificationRequest: CreateUserNotificationRequest) => {
			const result = await dispatch(createUserNotificationAction(createUserNotificationRequest))
				.payload.promise;

			dispatch(fetchUserNotifications(true));
			dispatch(forceFetchLabels());

			return result;
		},
		[dispatch]
	);
};

export const useReplaceUserNotification = () => {
	const dispatch = useDispatch();

	return useCallback(
		async (replaceUserNotificationRequest: ReplaceUserNotificationRequest) => {
			const result = await dispatch(replaceUserNotification(replaceUserNotificationRequest)).payload
				.promise;

			dispatch(fetchUserNotifications(true));
			dispatch(forceFetchLabels());

			return result;
		},
		[dispatch]
	);
};

export const useDeleteUserNotification = (notificationId: string) => {
	const dispatch = useDispatch();

	return useCallback(async () => {
		await dispatch(deleteUserNotification(notificationId)).payload.promise;
	}, [dispatch, notificationId]);
};
