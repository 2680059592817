import { handleActions } from '../..';
import * as actions from './actions';
import { TrunkContractsState } from './types';

const initialState: TrunkContractsState = {
	items: [],
	fetching: false,
	fetched: false,
};

export default handleActions<TrunkContractsState, PossibleActions<typeof actions>>(
	{
		TRUNK_CONTRACTS_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),

		TRUNK_CONTRACTS_FETCH_SUCCESS: (state, { payload, data }) => ({
			...state,
			fetching: false,
			fetched: true,
			items: payload.items.map(item => ({
				// API responds with full extension IDs including mastersipid for god-knows-what-reason
				trunkId: item.trunkId.includes('t') ? `t${item.trunkId.split('t')[1]}` : item.trunkId,
				contractId: item.contractId,
				productId: item.productId,
				productName: item.productName,
				recurring: item.recurring,
				cost: {
					amount: {
						// Can we use item.recurring here? I kinda do not trust it as nothing uses it atm.
						amount: item.recurringFee || item.nonRecurringFee,
						fraction: 10_000,
						currency: data.domain === 'sipgate.de' ? 'EUR' : 'GBP',
					},
					// Can we use item.recurring here? I kinda do not trust it as nothing uses it atm.
					interval: item.recurringFee ? 'monthly' : 'onetime',
					isNetto: false,
				},
				cancellationDate: item.cancellationDate ? new Date(item.cancellationDate) : null,
				replacedBy: item.replacedBy,
			})),
		}),

		TRUNK_CONTRACTS_REVOKE_CANCELLATION_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(contract => {
				if (contract.contractId !== data.contractId) {
					return contract;
				}

				return {
					...contract,
					cancellationDate: null,
				};
			}),
		}),
	},
	initialState
);
