import React from 'react';

import { ButtonGroup } from './buttons/ButtonGroup';
import { Button } from './buttons/Button';
import { Translate } from '../redux/slices/translations';
import classes from './Pagination.scss';

interface Props {
	translate: Translate;
	totalEntries: number;
	hideTotal?: boolean;
	lastShownEntry: number;
	firstShownEntry: number;

	pageSize: number;
	onPageChange: (offset: number) => void;
}

export class Pagination extends React.PureComponent<Props> {
	private onFirstPage = () => {
		if (this.props.firstShownEntry !== 1) {
			this.props.onPageChange(0);
		}
	};

	private onPreviousPage = () => {
		if (this.props.firstShownEntry !== 1) {
			this.props.onPageChange(Math.max(0, this.props.firstShownEntry - this.props.pageSize - 1));
		}
	};

	private onNextPage = () => {
		if (this.props.lastShownEntry !== this.props.totalEntries) {
			this.props.onPageChange(this.props.lastShownEntry);
		}
	};

	private onLastPage = () => {
		if (this.props.lastShownEntry !== this.props.totalEntries) {
			let newOffset = this.props.totalEntries - (this.props.totalEntries % this.props.pageSize);
			if (newOffset === this.props.totalEntries) {
				newOffset -= this.props.pageSize;
			}

			this.props.onPageChange(newOffset);
		}
	};

	public render() {
		return (
			<nav aria-label={this.props.translate('EVENTLIST_PAGINATION_LABEL')}>
				<ButtonGroup>
					<Button
						disabled={this.props.firstShownEntry === 1}
						onClick={this.onFirstPage}
						ariaLabel={this.props.translate('EVENTLIST_PAGINATION_GO_TO_FIRST_PAGE_LABEL')}
					>
						{'<<'}
					</Button>
					<Button
						disabled={this.props.firstShownEntry === 1}
						onClick={this.onPreviousPage}
						ariaLabel={this.props.translate('EVENTLIST_PAGINATION_GO_TO_PREVIOUS_PAGE_LABEL')}
					>
						{'<'}
					</Button>
					<span className={classes.currentPage}>
						{this.props.firstShownEntry}-{this.props.lastShownEntry}
						<span className={classes.totalEntries}>
							{this.props.translate('PAGINATION_OF')}{' '}
							{this.props.hideTotal
								? this.props.translate('PAGINATION_MANY')
								: this.props.totalEntries}
						</span>
					</span>
					<Button
						disabled={this.props.lastShownEntry === this.props.totalEntries}
						onClick={this.onNextPage}
						ariaLabel={this.props.translate('EVENTLIST_PAGINATION_GO_TO_NEXT_PAGE_LABEL')}
					>
						{'>'}
					</Button>
					<Button
						disabled={this.props.hideTotal || this.props.lastShownEntry === this.props.totalEntries}
						onClick={this.onLastPage}
						ariaLabel={this.props.translate('EVENTLIST_PAGINATION_GO_TO_LAST_PAGE_LABEL')}
					>
						{'>>'}
					</Button>
				</ButtonGroup>
			</nav>
		);
	}
}
