import { Store } from '@reduxjs/toolkit';

import { fetchHistory } from '../fetchHistory/action';
import { State } from '../../state';
import {
	ArchiveEntriesPendingAction,
	ArchiveEntriesSuccessAction,
	ArchiveEntriesErrorAction,
} from './types';
import api from '../../../../../api';
import { Selections } from '../../../selections/parsers';

export const archiveEntries =
	(store: Store<State>) => async (eventIds: string[], selections: Selections) => {
		const { result } = store.getState().entries;
		const archivingAll = !!result && result.totalCount === eventIds.length;
		const archivingPage = !!result && result.normalized.length === eventIds.length;

		store.dispatch<ArchiveEntriesPendingAction>({
			type: 'ARCHIVE_ENTRIES_PENDING',
			eventIds,
		});

		try {
			await api.updateHistoryEntries(eventIds.map(eventId => ({ id: eventId, archived: true })));

			store.dispatch<ArchiveEntriesSuccessAction>({ type: 'ARCHIVE_ENTRIES_SUCCESS' });
		} catch (e) {
			store.dispatch<ArchiveEntriesErrorAction>({ type: 'ARCHIVE_ENTRIES_ERROR' });

			throw e;
		} finally {
			if (archivingPage && !archivingAll) {
				fetchHistory(store)(selections);
			}
		}
	};
