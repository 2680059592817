import React, { AriaAttributes, ForwardedRef, forwardRef } from 'react';
import classnames from 'classnames';

import classes from './DropdownButton.scss';

type Props = {
	id?: string;
	open: boolean;
	active?: boolean;
	children?: string;
	className?: string;
	testSelector?: string;
	disabled?: boolean;
	grayVariant?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	forwardedRef: ForwardedRef<HTMLButtonElement>;
} & AriaAttributes;

class InnerDropdownButton extends React.Component<Props> {
	public render() {
		const {
			id,
			open,
			active,
			children,
			className,
			testSelector,
			disabled,
			grayVariant,
			onClick,
			forwardedRef,
			...ariaAttributes
		} = this.props;
		return (
			<button
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				{...ariaAttributes}
				id={id}
				className={classnames(className, classes.button, {
					[classes.primary]: open || active,
					[classes.gray]: grayVariant,
				})}
				data-test-selector={this.props.testSelector ? `${testSelector}-button` : undefined}
				data-testid={testSelector ? `${testSelector}-button` : undefined}
				onClick={onClick}
				ref={forwardedRef}
				disabled={disabled}
				type="button"
			>
				{children}

				<span className={classes.icon} />
			</button>
		);
	}
}

export const DropdownButton = forwardRef(
	(props: Omit<Props, 'forwardedRef'>, ref: ForwardedRef<HTMLButtonElement>) => (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<InnerDropdownButton {...props} forwardedRef={ref} />
	)
);
