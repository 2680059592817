import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { DisabledContext, isDisabled } from '../../contexts/disabledContext';

type BaseLinkProps = {
	children: React.ReactNode;
	/**
	 * Setze diese Prop nur, wenn das automatische Resizing bei Touch Devices dein Interface zerstört.
	 * Kümmere dich dann darum, dass die Touchfläche groß genug ist.
	 */
	deprecated?: boolean;
	disabled?: boolean;
	fileName?: string;
	secure: boolean;
	button: boolean;
	size: 'small' | 'medium' | 'large' | 'xlarge';
	target?: 'blank';
	type: 'internal' | 'external' | 'download' | 'mail' | 'phone';
	url: string;
	variant: 'silent' | 'quiet' | 'normal' | 'loud' | 'screaming';
	width: 'content' | 'max' | 'max-on-touch-device';
	dataFocusId?: string;
	iconPosition?: 'before' | 'after';
	iconOnly?: boolean;
};

type TrackingProps = {
	type: 'download';
	tracking?: () => void;
};

type WithoutTrackingProps = {
	type: 'internal' | 'external' | 'mail' | 'phone';
	tracking?: undefined;
};

type Props = BaseLinkProps & (TrackingProps | WithoutTrackingProps);

const styles = {
	link: (
		variant: 'silent' | 'quiet' | 'normal' | 'loud' | 'screaming',
		size: 'small' | 'medium' | 'large' | 'xlarge',
		width: 'content' | 'max' | 'max-on-touch-device',
		disabled: boolean,
		button: boolean,
		deprecated: boolean,
		type: 'internal' | 'external' | 'download' | 'mail' | 'phone',
		iconPosition: 'before' | 'after',
		iconOnly: boolean
	) => {
		if (!button) {
			switch (variant) {
				case 'normal':
					return classnames(
						'font-bold',
						'inline',
						type !== 'internal' && 'whitespace-nowrap',
						!disabled
							? [
									'text-neo-color-global-content-link-default',
									'transition',
									'duration-150',
									'ease-in-out',
									'no-underline',
									'hover:underline',
									'hover:text-neo-color-global-content-link-hover',
									'focus:outline-none',
									'focus-visible:outline-none',
									'focus-visible:underline',
									'focus-visible:decoration-3',
									'focus-visible:decoration-color-focus',
									'active:text-neo-color-global-content-link-active',
									'active:underline',
								]
							: ['cursor-not-allowed', 'text-neo-color-global-content-link-disabled', 'underline']
					);
				case 'quiet':
					return classnames(
						'font-normal',
						'underline',
						'inline',
						type !== 'internal' && 'whitespace-nowrap',
						!disabled
							? [
									'text-current',
									'transition',
									'duration-150',
									'ease-in-out',
									'hover:text-neo-color-global-content-link-hover',
									'focus:outline-none',
									'focus-visible:outline-none',
									'focus-visible:decoration-3',
									'focus-visible:decoration-color-focus',
									'active:text-neo-color-global-content-link-active',
								]
							: ['cursor-not-allowed', 'text-neo-color-global-content-link-disabled']
					);
				case 'silent':
					return classnames(
						'font-normal',
						'no-underline',
						'inline',
						type !== 'internal' && 'whitespace-nowrap',
						!disabled
							? [
									'text-current',
									'transition',
									'duration-150',
									'ease-in-out',
									'hover:text-neo-color-global-content-link-hover',
									'hover:underline',
									'focus:outline-none',
									'focus-visible:outline-none',
									'focus-visible:underline',
									'focus-visible:decoration-3',
									'focus-visible:decoration-color-focus',
									'active:text-neo-color-global-content-link-active',
								]
							: ['cursor-not-allowed', 'text-neo-color-global-content-link-disabled']
					);
				default:
					return '';
			}
		} else {
			return classnames(
				'group',
				'select-none',
				'inline-flex',
				'flex-row',
				'font-bold',
				'text-left',
				'transition',
				'duration-150',
				'ease-in-out',
				'justify-center',
				'no-underline',
				'rounded',
				'focus:outline-none',
				'focus-visible:outline-none',
				!disabled
					? ['cursor-pointer', 'focus-visible:ring']
					: [
							'bg-neo-color-global-background-primary-intense-disabled',
							'text-neo-color-global-content-primary-disabled',
							'cursor-not-allowed',
						],

				size === 'small' && ['py-4', 'text-xs/16'],
				size === 'medium' && ['py-8', 'text-sm/16'],
				size === 'large' && ['py-8', 'text-base/24'],
				size === 'xlarge' && ['py-12', 'text-base/24'],

				!iconOnly && [
					iconPosition === 'after' && [
						size === 'small' && ['pl-12', 'pr-8'],
						size === 'medium' && ['pl-16', 'pr-12'],
						size === 'large' && ['pl-20', 'pr-12'],
						size === 'xlarge' && ['pl-24', 'pr-16'],
					],

					iconPosition === 'before' && [
						size === 'small' && ['pl-8', 'pr-12'],
						size === 'medium' && ['pl-12', 'pr-16'],
						size === 'large' && ['pl-12', 'pr-20'],
						size === 'xlarge' && ['pl-16', 'pr-24'],
					],
				],

				iconOnly && [
					size === 'small' && ['p-4'],
					size === 'medium' && ['p-8'],
					size === 'large' && ['p-8'],
					size === 'xlarge' && ['p-12'],
				],

				!deprecated && [
					'pointer-coarse:py-12',
					'pointer-coarse:pr-16',
					'pointer-coarse:pl-24',
					'pointer-coarse:text-base/24',
				],
				width === 'content' && ['w-auto'],
				width === 'max' && ['w-full', 'max-w-screen-xs'],
				width === 'max-on-touch-device' && [
					'pointer-fine:w-auto',
					'pointer-coarse:w-full',
					'pointer-coarse:max-w-screen-xs',
				],
				!disabled &&
					variant === 'loud' && [
						'bg-neo-color-global-background-primary-intense-default',
						'text-neo-color-global-content-primary-on-intense',
						'hover:bg-neo-color-global-background-primary-intense-hover',
						'hover:text-neo-color-global-content-primary-on-intense',
						'focus-visible:bg-neo-color-global-background-primary-intense-default',
						'focus-visible:ring-color-focus',
						'active:bg-neo-color-global-background-primary-intense-active',
					],
				!disabled &&
					variant === 'screaming' && [
						'bg-neo-color-global-background-electric-intense-default',
						'text-neo-color-global-content-electric-on-intense',
						'hover:bg-neo-color-global-background-electric-intense-hover',
						'hover:text-neo-color-global-content-electric-on-intense',
						'focus-visible:bg-neo-color-global-background-primary-intense-default',
						'focus-visible:ring-color-focus',
						'active:bg-neo-color-global-background-electric-intense-active',
					],
				!disabled &&
					variant === 'normal' && [
						'bg-neo-color-global-background-primary-soft-default',
						'text-neo-color-global-content-primary-intense',
						'hover:bg-neo-color-global-background-primary-soft-hover',
						'hover:text-neo-color-global-content-primary-intense',
						'focus-visible:ring-color-focus',
						'active:bg-neo-color-global-background-primary-soft-active',
						'active:text-neo-color-global-content-primary-intense',
					]
			);
		}
	},
};

const BaseLink = ({
	button,
	children,
	deprecated = false,
	disabled,
	fileName,
	secure,
	size,
	width,
	target,
	type,
	url,
	variant,
	dataFocusId,
	tracking,
	iconPosition = 'after',
	iconOnly = false,
}: Props): JSX.Element => {
	const disabledContextValue = React.useContext(DisabledContext);

	const getTarget = (): string | undefined => (target === 'blank' ? '_blank' : undefined);
	const getSecure = (): string => {
		if (secure) {
			return 'noreferrer noopener nofollow';
		}

		if (target === 'blank') {
			return 'noreferrer';
		}

		return '';
	};

	const renderDisabledLink = (): JSX.Element => {
		return (
			// eslint-disable-next-line jsx-a11y/anchor-is-valid
			<a
				role="link"
				aria-disabled
				data-focus-id={dataFocusId}
				className={styles.link(
					variant,
					size,
					width,
					isDisabled(disabled, disabledContextValue),
					button,
					deprecated,
					type,
					iconPosition,
					iconOnly
				)}
			>
				{children}
			</a>
		);
	};

	const renderDownloadAnchor = (): JSX.Element => {
		return (
			<a
				className={styles.link(
					variant,
					size,
					width,
					isDisabled(disabled, disabledContextValue),
					button,
					deprecated,
					type,
					iconPosition,
					iconOnly
				)}
				onClick={tracking}
				href={url}
				download={fileName || true}
				rel={getSecure() || undefined}
				target={getTarget()}
				data-focus-id={dataFocusId}
			>
				{children}
			</a>
		);
	};

	const renderExternalAnchor = (): JSX.Element => {
		return (
			<a
				className={styles.link(
					variant,
					size,
					width,
					isDisabled(disabled, disabledContextValue),
					button,
					deprecated,
					type,
					iconPosition,
					iconOnly
				)}
				href={url}
				rel={getSecure() || undefined}
				target={getTarget()}
				data-focus-id={dataFocusId}
			>
				{children}
			</a>
		);
	};

	const renderMailAnchor = (): JSX.Element => {
		return (
			<a
				className={styles.link(
					variant,
					size,
					width,
					isDisabled(disabled, disabledContextValue),
					button,
					deprecated,
					type,
					iconPosition,
					iconOnly
				)}
				href={url}
				rel={getSecure() || undefined}
				target={getTarget()}
				data-focus-id={dataFocusId}
			>
				{children}
			</a>
		);
	};

	const renderPhoneAnchor = (): JSX.Element => {
		return (
			<a
				className={styles.link(
					variant,
					size,
					width,
					isDisabled(disabled, disabledContextValue),
					button,
					deprecated,
					type,
					iconPosition,
					iconOnly
				)}
				href={url}
				rel={getSecure() || undefined}
				target={getTarget()}
				data-focus-id={dataFocusId}
			>
				{children}
			</a>
		);
	};

	const renderInternalAnchor = (): JSX.Element => {
		if (url.startsWith('/')) {
			return (
				<Link
					className={styles.link(
						variant,
						size,
						width,
						isDisabled(disabled, disabledContextValue),
						button,
						deprecated,
						type,
						iconPosition,
						iconOnly
					)}
					to={url}
					data-focus-id={dataFocusId}
				>
					{children}
				</Link>
			);
		}

		return (
			<a
				className={styles.link(
					variant,
					size,
					width,
					isDisabled(disabled, disabledContextValue),
					button,
					deprecated,
					type,
					iconPosition,
					iconOnly
				)}
				href={url}
				data-focus-id={dataFocusId}
			>
				{children}
			</a>
		);
	};

	if (isDisabled(disabled, disabledContextValue)) {
		return renderDisabledLink();
	}

	switch (type) {
		case 'download':
			return renderDownloadAnchor();
		case 'external':
			return renderExternalAnchor();
		case 'mail':
			return renderMailAnchor();
		case 'phone':
			return renderPhoneAnchor();
		case 'internal':
		default:
			return renderInternalAnchor();
	}
};

BaseLink.defaultProps = {
	secure: false,
};

export { BaseLink };
