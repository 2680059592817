import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';

export const fetchLpaData = createAsyncThunk(
	'lpaUris/fetch',
	async (args: { deviceId: string; onFetched?: (iccid: string) => void }) => {
		const result = await api.getLpaData(args.deviceId);
		if (args.onFetched) {
			args.onFetched(result.lpaData.iccid);
		}
		return result;
	}
);
