import { handleActions } from '../..';

import { FaxlineState } from './types';

import * as actions from './actions';
import { setGroupAlias } from '../groups';
import { setUserName } from '../users';

const initialState: FaxlineState = {
	items: [],
	fetched: false,
	fetching: false,
};

export default handleActions<
	FaxlineState,
	PossibleActions<typeof actions | typeof setGroupAlias | typeof setUserName>
>(
	{
		FAXLINES_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),
		FAXLINES_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetching: false,
				fetched: true,
				items: payload.items,
			};
		},
		FAXLINE_SET_ALIAS_PENDING: (state, { data }) => {
			const items = state.items.map(faxline => {
				if (faxline.id === data.faxlineId) {
					return { ...faxline, alias: data.alias };
				}

				return faxline;
			});
			return {
				...state,
				items,
			};
		},
		FAXLINE_SET_TAGLINE_PENDING: (state, { data }) => {
			const items = state.items.map(faxline => {
				if (faxline.id === data.faxlineId) {
					return { ...faxline, tagline: data.tagline };
				}

				return faxline;
			});
			return {
				...state,
				items,
			};
		},
		FAXLINES_SET_CALLERID_PENDING: (state, { data }) => {
			const items = state.items.map(faxline => {
				if (faxline.id === data.faxlineId) {
					return { ...faxline, callerId: data.anonymous ? 'anonymous' : data.callerId };
				}

				return faxline;
			});
			return {
				...state,
				items,
			};
		},
		FAXLINES_CREATE_SUCCESS: (state, { payload }) => ({
			...state,
			items: [...state.items, payload],
		}),
		FAXLINE_DELETE_PENDING: (state, { data }) => {
			const remainingFaxlines = state.items.filter(item => item.id !== data.faxlineId);

			return {
				...state,
				items: remainingFaxlines,
			};
		},
		GROUP_ALIAS_SET_SUCCESS: (state, { data }) => {
			return {
				...state,
				items: state.items.map(faxline => {
					if (faxline.ownerId === data.groupId) {
						return {
							...faxline,
							alias: faxline.alias.replace(data.oldAlias, data.alias),
						};
					}

					return faxline;
				}),
			};
		},
	},
	initialState
);
