import { handleActions } from '../..';
import * as actions from './actions';
import { PersonalAccessTokenLoadingState, PersonalAccessTokenState } from './types';

const initialState: PersonalAccessTokenState = {
	items: [],
	fetchingForUser: [],
	fetchedForUser: [],
	creatingToken: PersonalAccessTokenLoadingState.SUCCESS,
};

export default handleActions<PersonalAccessTokenState, PossibleActions<typeof actions>>(
	{
		PERSONAL_ACCESS_TOKEN_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForUser: [...state.fetchingForUser, data.webuserId],
		}),
		PERSONAL_ACCESS_TOKEN_FETCH_SUCCESS: (state, { payload, data }) => {
			const oldItems = state.items.filter(item => item.webuserId !== data.webuserId);

			return {
				...state,
				items: [...oldItems, ...payload.items],
				fetchingForUser: state.fetchingForUser.filter(webuserId => webuserId !== data.webuserId),
				fetchedForUser: [...state.fetchedForUser, data.webuserId],
			};
		},
		PERSONAL_ACCESS_TOKEN_DELETE_PENDING: (state, { data }) => {
			const newItems = state.items.filter(item => item.tokenId !== data.tokenId);
			return {
				...state,
				items: [...newItems],
			};
		},
		PERSONAL_ACCESS_TOKEN_CREATE_PENDING: state => ({
			...state,
			creatingToken: PersonalAccessTokenLoadingState.LOADING,
		}),
		PERSONAL_ACCESS_TOKEN_CREATE_FAILURE: state => {
			return {
				...state,
				creatingToken: PersonalAccessTokenLoadingState.ERROR,
			};
		},
		PERSONAL_ACCESS_TOKEN_CREATE_SUCCESS: (state, { payload }) => {
			return {
				...state,
				creatingToken: PersonalAccessTokenLoadingState.SUCCESS,
				items: [
					...state.items,
					{
						tokenId: payload.tokenId,
						token: payload.token,
						scopes: payload.scopes,
						created: payload.created,
						tokenName: payload.tokenName,
						webuserId: payload.webuserId,
					},
				],
			};
		},
	},
	initialState
);
