import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';
import { forceFetchRestrictions } from '../../modules/restrictions';

export const fetchBaseContract = createAsyncThunk(
	'baseContract/fetch',
	() => api.getBaseContract(),
	{
		condition: (_, { getState }) => !getState().baseContract.fetched,
	}
);

export const bookBaseContract = createAsyncThunk(
	'baseContract/book',
	async (args: { optionId: string }, { dispatch }) => {
		const result = await api.bookBaseContract(args.optionId);

		await dispatch(forceFetchRestrictions(['CAN_MODIFY_BASE_CONTRACT'])).payload.promise;

		return result;
	}
);

export const changeBaseContract = createAsyncThunk(
	'baseContract/change',
	async (args: { optionId: string }, { dispatch }) => {
		const result = await api.changeBaseContract(args.optionId);

		await dispatch(forceFetchRestrictions(['CAN_MODIFY_BASE_CONTRACT'])).payload.promise;

		return result;
	}
);

export const revokeBaseContractChange = createAsyncThunk(
	'baseContract/revokeChange',
	async (_, { dispatch }) => {
		const result = await api.revokeBaseContractChange();

		await dispatch(forceFetchRestrictions(['CAN_MODIFY_BASE_CONTRACT'])).payload.promise;

		return result;
	}
);

export const cancelBaseContract = createAsyncThunk(
	'baseContract/cancel',
	async (args: { cancelDependentPhoneNumberContracts: boolean }, { dispatch }) => {
		const result = await api.cancelBaseContract(args.cancelDependentPhoneNumberContracts);

		await dispatch(forceFetchRestrictions(['CAN_MODIFY_BASE_CONTRACT'])).payload.promise;

		return result;
	}
);

export const revokeBaseContractCancellation = createAsyncThunk(
	'baseContract/revokeCancellation',
	async (_, { dispatch }) => {
		const result = await api.revokeBaseContractCancellation();

		await dispatch(forceFetchRestrictions(['CAN_MODIFY_BASE_CONTRACT'])).payload.promise;

		return result;
	}
);
