let id = 0;

/** @deprecated Use React.useId instead. More information about [React.useId](https://react.dev/reference/react/useId) */
export function ariaId() {
	id += 1;

	return `aria-${id - 1}`;
}

/** @deprecated Only ever call this from our test harness!!! */
export function resetAriaId() {
	id = 0;
}
