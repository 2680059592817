import React from 'react';
import CenterContainer from '../../helpers/CenterContainer';
import { LogoSpinner } from './LogoSpinner';

export default class CenteredLogoSpinner extends React.Component {
	private getStyle = () => ({
		container: {
			width: '100%',
			height: '100%',
		},
		spinnerContainer: {
			height: '45px',
			width: '45px',
		},
	});

	public render() {
		return (
			<CenterContainer style={this.getStyle().container}>
				<LogoSpinner />
			</CenterContainer>
		);
	}
}
