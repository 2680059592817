import { Store } from '@reduxjs/toolkit';
import { MarkUnreadPendingAction } from './types';
import api from '../../../../../api';
import { State } from '../../state';

export const markUnread = (store: Store<State>) => async (eventIds: string[]) => {
	store.dispatch<MarkUnreadPendingAction>({ type: 'MARK_UNREAD_PENDING', eventIds });

	await api.updateHistoryEntries(eventIds.map(id => ({ id, read: false })));
};
