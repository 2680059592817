import classnames from 'classnames';
import React from 'react';
import { useTableContext } from './contexts/tableContext';

type Props = {
	children: string;
	rightAligned?: boolean;
};

const styles = {
	th: (isSmallView: boolean, rightAligned = false) =>
		classnames(
			'px-8',
			'py-10',
			'font-brand',
			'font-normal',
			'text-xs/14',
			'text-left',
			!isSmallView && ['last:pr-0'],
			!isSmallView && rightAligned && 'text-right'
		),
};

export const TableHeaderCell = ({ children, rightAligned }: Props): JSX.Element => {
	const { isSmallView } = useTableContext();

	return <th className={styles.th(isSmallView, rightAligned)}>{children}</th>;
};
