import { AvatarSize, AvatarSizeUrls } from '../redux/slices/avatar';

export const avatarScrSet = (avatars: AvatarSizeUrls | undefined): string | undefined => {
	if (!avatars) {
		return undefined;
	}

	return Object.keys(avatars)
		.map(key => {
			const value = avatars[key as AvatarSize];
			if (key.includes('_')) {
				return `${value} ${key.split('_')[1].toLowerCase()}`;
			}
			return value;
		})
		.join(', ');
};
