import { Store } from '@reduxjs/toolkit';
import { State } from '../state';
import { BlocklistEntry } from '../../../../redux/slices/blocklist';
import { UpdateBlacklistAction } from './types';

export const updateBlacklist = (store: Store<State>) => (blocklist: BlocklistEntry[]) => {
	store.dispatch<UpdateBlacklistAction>({
		type: 'UPDATE_BLACKLIST',
		blocklist,
	});
};
