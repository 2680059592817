import { Faxline } from '../../../api/types/faxlines';

export function selectFaxline(faxlines: Faxline[], faxlineId: string) {
	return faxlines.find(fax => fax.id === faxlineId);
}

export function selectFaxlinesForOwner(faxlines: Faxline[], ownerId: string) {
	return faxlines.filter(fax => fax.ownerId === ownerId);
}

export function selectFaxlinesForOwners(faxlines: Faxline[], ownerIds: string[]) {
	return faxlines.filter(fax => ownerIds.includes(fax.ownerId));
}

export function selectFirstFaxline(faxlines: Faxline[]) {
	return faxlines.length > 0 ? faxlines[0] : null;
}
