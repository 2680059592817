import React, { useContext, useEffect } from 'react';

import { useViewStack } from './view-stack';

const Context = React.createContext<{
	breadcrumbs: Breadcrumb[][];
	addBreadcrumbs: (crumbs: Breadcrumb[]) => () => void;
}>({
	breadcrumbs: [],
	addBreadcrumbs: () => () => {},
});

interface Breadcrumb {
	path: `/${string}`;
	name: string;
}

export const useBreadcrumbs = () =>
	// We always return the longest breadcrumb chain currently available. This is probably not perfect,
	// but the only time this breaks is if you have two competing leaf views mounted. So don't do that.
	useContext(Context).breadcrumbs.reduce((acc, a) => (a.length > acc.length ? a : acc), []);

/** Only use this if you know what you are doing pls. */
export const useBreadcrumbTrigger = () => {
	const { addBreadcrumbs } = useContext(Context);
	const viewStack = useViewStack();

	useEffect(() => {
		return addBreadcrumbs(
			viewStack.viewStack
				.filter((view): view is { title: string; path: `/${string}` } => !!view.title)
				.map(view => ({ name: view.title, path: view.path }))
		);
	}, [viewStack.viewStack, addBreadcrumbs]);
};

export class BreadcrumbProvider extends React.Component<
	{ children: React.ReactNode },
	{ breadcrumbs: Breadcrumb[][] }
> {
	public state: { breadcrumbs: Breadcrumb[][] } = { breadcrumbs: [] };

	private addBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
		this.setState(state => ({ breadcrumbs: [...state.breadcrumbs, breadcrumbs] }));

		return () =>
			this.setState(state => ({ breadcrumbs: state.breadcrumbs.filter(b => b !== breadcrumbs) }));
	};

	public render() {
		return (
			<Context.Provider
				value={{
					breadcrumbs: this.state.breadcrumbs,
					addBreadcrumbs: this.addBreadcrumbs,
				}}
			>
				{this.props.children}
			</Context.Provider>
		);
	}
}
