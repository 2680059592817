import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { closeErrorSnackbar } from '../../redux/slices/snackbar';
import Snackbar from './Snackbar';
import { ReduxState } from '../../redux/types';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';

const mapStateToProps = (state: ReduxState) => ({
	open: state.dialogs.errorSnackbarOpen,
	message: state.dialogs.errorSnackbarMessage,
	translationsFetched: state.translations.fetched,
});

const mapDispatchToProps = {
	closeErrorSnackbar,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & TranslateProps;

class ErrorSnackbar extends React.PureComponent<Props> {
	private timeout?: number;

	public componentDidUpdate(prevProps: Props) {
		if (prevProps.open !== this.props.open) {
			window.clearTimeout(this.timeout);
		}

		if (!prevProps.open && this.props.open) {
			this.timeout = window.setTimeout(this.props.closeErrorSnackbar, 3500);
		}
	}

	public render() {
		if (!this.props.translationsFetched) {
			return null;
		}

		return (
			<Snackbar
				key="error-snackbar"
				snackbarOpen={this.props.open}
				closeSnackbar={this.props.closeErrorSnackbar}
				severity="error"
				autoHideDuration={3500}
			>
				{this.props.message ? this.props.message : this.props.translate('SNACKBAR_ERROR_TEXT')}
			</Snackbar>
		);
	}
}

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(ErrorSnackbar));
