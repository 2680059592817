import { createAction } from '../..';

import api from '../../../api';
import { VoicemailState } from './types';

export const fetchVoicemails = createAction('VOICEMAILS_FETCH', (userId: string) => ({
	promise: () => api.getVoicemails(userId),
	data: { userId },
	shouldFetch: ({ voicemails }: { voicemails: VoicemailState }) =>
		!voicemails.fetchingForUser.includes(userId) && !voicemails.fetchedForUser.includes(userId),
}));

export const forceFetchVoicemails = createAction('VOICEMAILS_FETCH', (userId: string) => ({
	promise: () => api.getVoicemails(userId),
	data: { userId },
}));

export const fetchVoicemailsForAccount = createAction('VOICEMAILS_FOR_ACCOUNT_FETCH', () => ({
	promise: () => api.getVoicemails(),
	shouldFetch: ({ voicemails }: { voicemails: VoicemailState }) =>
		!voicemails.fetchingForAccount && !voicemails.fetchedForAccount,
}));

export const forceFetchVoicemailsForAccount = createAction('VOICEMAILS_FOR_ACCOUNT_FETCH', () => ({
	promise: () => api.getVoicemails(),
}));

export const resetVoicemails = createAction('VOICEMAILS_RESET');

export const createVoicemail = createAction('VOICEMAIL_CREATE', (userId: string) => ({
	promise: () => api.createVoicemail(userId),
	data: { userId },
}));

export const updateTranscriptionSettingForVoicemail = createAction(
	'VOICEMAIL_UPDATE_TRANSCRIPTION',
	(voicemailId: string, active: boolean) => ({
		promise: api.setTranscription(voicemailId, active),
		data: {
			voicemailId,
			active,
		},
	})
);

export const setVoicemailAlias = createAction(
	'VOICEMAIL_SET_ALIAS',
	(voicemailId: string, alias: string) => ({
		promise: () => api.setVoicemailAlias(voicemailId, alias),
		data: {
			voicemailId,
			alias,
		},
	})
);

export const setVoicemailPin = createAction(
	'VOICEMAIL_SET_PIN',
	(loggedInUserId: string, userId: string, voicemailId: string, pin: string) => ({
		promise: () => api.setVoicemailPin(userId, voicemailId, pin),
		data: {
			loggedInUserId,
			userId,
			voicemailId,
			pin,
		},
	})
);

export const deleteVoicemailPin = createAction(
	'VOICEMAIL_DELETE_PIN',
	(userId: string, voicemailId: string) => ({
		promise: () => api.deleteVoicemailPin(userId, voicemailId),
		data: {
			userId,
			voicemailId,
		},
	})
);

export const setVoicemailAuthorizedNumbers = createAction(
	'VOICEMAIL_SET_AUTHORIZED_NUMBERS',
	(userId: string, voicemailId: string, numbers: string[]) => ({
		promise: () => api.setVoicemailAuthorizedNumbers(userId, voicemailId, numbers),
		data: {
			userId,
			voicemailId,
			numbers,
		},
	})
);

export const setActiveAudioFile = createAction(
	'VOICEMAIL_SET_ACTIVE_AUDIOFILE',
	(voicemailId: string, audioFileId: string | null) => ({
		promise: () => api.setActiveAudioFileVoicemail(voicemailId, audioFileId),
		data: {
			voicemailId,
			audioFileId,
		},
	})
);
