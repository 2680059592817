import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';
import { DeviceType, SimIntendedUse } from './types';

const shouldFetchDevices = (state: ReduxState, userId: string) =>
	!state.devices.fetchedForUser.includes(userId) && !state.devices.fetchingForUser.includes(userId);

export const fetchDevices = createAction('DEVICES_FETCH', (userId: string) => ({
	promise: () => api.getDevices(userId),
	shouldFetch: (state: ReduxState) => shouldFetchDevices(state, userId),
	data: { userId },
}));

export const forceFetchDevices = createAction('DEVICES_FETCH', (userId: string) => ({
	promise: api.getDevices(userId),
	data: { userId },
}));

export const setDeviceSettings = createAction(
	'DEVICE_SETTINGS_SET',
	(deviceId: string, dnd: boolean, emergencyAddressId?: string) => ({
		promise: api.setDeviceSettings(deviceId, dnd, emergencyAddressId),
		data: {
			deviceId,
			dnd,
			emergencyAddressId,
		},
	})
);

export const setAlias = createAction('DEVICE_ALIAS_SET', (deviceId: string, alias: string) => ({
	promise: api.setDeviceAlias(deviceId, alias),
	data: {
		deviceId,
		alias,
	},
}));

export const setSmsSim = createAction(
	'DEVICE_SMS_SIM_SET',
	(containerId: string, mobileId: string) => ({
		promise: api.setSmsSim(containerId, mobileId),
		data: {
			containerId,
			mobileId,
		},
	})
);

export const setSimAlias = createAction(
	'SIM_ALIAS_SET',
	(containerId: string, mobileId: string, alias: string) => ({
		promise: api.setDeviceAlias(mobileId, alias),
		data: {
			containerId,
			mobileId,
			alias,
		},
	})
);

export const resetPassword = createAction('DEVICE_PASSWORD_RESET', (deviceId: string) => ({
	promise: api.resetDevicePassword(deviceId),
	data: { deviceId },
}));

export const activateSim = createAction(
	'DEVICE_SIM_ACTIVATE',
	(
		userId: string,
		deviceId: string,
		simId: string,
		intendedUse: SimIntendedUse,
		alias?: string
	) => ({
		promise: api.activateSim(userId, deviceId, simId, intendedUse, alias),
		data: {
			userId,
			deviceId,
			simId,
		},
	})
);

export const changeSim = createAction(
	'DEVICE_SIM_ACTIVATE',
	(
		userId: string,
		deviceId: string,
		id: string,
		simId: string,
		intendedUse: SimIntendedUse,
		alias?: string
	) => ({
		promise: api.changeSim(userId, deviceId, id, simId, intendedUse, alias),
		data: {
			userId,
			deviceId,
			simId,
		},
	})
);

export const setPendingSim = createAction(
	'DEVICE_SET_PENDING_SIM',
	(
		deviceId: string,
		iccid: string,
		intendedUse: SimIntendedUse,
		mobileExtension?: string,
		alias?: string
	) => {
		return {
			promise: api.setPendingSim(deviceId, iccid, intendedUse, mobileExtension, alias),
			data: {
				deviceId,
				iccid,
			},
		};
	}
);

export const setIntendedUse = createAction(
	'DEVICE_SIM_SET_INTENDED_USE',
	(deviceId: string, simExtensionId: string, intendedUse: SimIntendedUse) => {
		return {
			promise: api.setIntendedUse(deviceId, simExtensionId, intendedUse),
			data: {
				deviceId,
				simExtensionId,
				intendedUse,
			},
		};
	}
);

export const deactivateSim = createAction(
	'DEVICE_SIM_DEACTIVATE',
	(userId: string, deviceId: string) => {
		return {
			promise: api.deactivateSim(userId, deviceId),
		};
	}
);

export const orderSim = createAction(
	'DEVICE_ORDER_SIM',
	(userId: string, deviceId: string, addressId: string) => ({
		promise: api.orderSim(userId, deviceId, addressId),
	})
);

export const setDeviceRouting = createAction(
	'DEVICE_SET_ROUTING',
	(userId: string, phonelineId: string, deviceId: string) => ({
		promise: api.createPhonelineDevice(userId, phonelineId, deviceId),
		data: {
			userId,
			phonelineId,
			deviceId,
		},
	})
);

export const deleteDeviceRouting = createAction(
	'DEVICE_DELETE_ROUTING',
	(userId: string, phonelineId: string, deviceId: string) => ({
		promise: api.deletePhonelineDevice(userId, phonelineId, deviceId),
		data: {
			userId,
			phonelineId,
			deviceId,
		},
	})
);

export const createDevice = createAction(
	'DEVICE_CREATE',
	(userId: string, type: DeviceType, alias?: string) => ({
		promise: (async () => {
			const device = await api.createDevice(userId, type, alias);
			const allDevices = await api.getDevices(userId);

			return { allDevices, device };
		})(),
		restrictions: {
			userId,
			restrictions: [`CAN_CREATE_${type}_DEVICE`, `CAN_DELETE_${type}_DEVICE`],
		},
		data: { userId },
	})
);

export const createUnlinkedDevice = createAction(
	'DEVICE_CREATE',
	(userId: string, alias: string) => ({
		promise: (async () => {
			const device = await api.createUnlinkedDevice(alias);
			const allDevices = await api.getDevices(userId);

			return { allDevices, device };
		})(),
		restrictions: {
			userId,
			restrictions: [`CAN_CREATE_UNLINKED_DEVICE`, `CAN_DELETE_UNLINKED_DEVICE`],
		},
		data: { alias, userId },
	})
);

export const createUnlinkedMobileDevice = createAction(
	'DEVICE_CREATE',
	(userId: string, alias: string) => ({
		promise: (async () => {
			const device = await api.createUnlinkedMobileDevice(alias);
			const allDevices = await api.getDevices(userId);

			return { device, allDevices };
		})(),
		restrictions: {
			userId,
			restrictions: ['CAN_CREATE_UNLINKED_MOBILE_DEVICE'],
		},
		data: { alias, userId },
	})
);

export const deleteDevice = createAction(
	'DEVICE_DELETE',
	(userId: string, deviceId: string, type: DeviceType) => {
		const restrictions = [`CAN_CREATE_${type}_DEVICE`, `CAN_DELETE_${type}_DEVICE`];
		if (type === 'MOBILE') {
			restrictions.push('CAN_CREATE_UNLINKED_MOBILE_DEVICE');
		}

		return {
			promise: api.deleteDevice(deviceId),
			data: {
				deviceId,
			},
			restrictions: {
				userId,
				restrictions,
			},
		};
	}
);

export const deleteAdditionalSim = createAction(
	'DEVICE_ADDITIONAL_SIM_DEACTIVATE',
	(containerId: string, mobileId: string) => ({
		promise: api.deleteDevice(mobileId),
		data: {
			containerId,
			mobileId,
		},
	})
);

export const setDeviceGroupRouting = createAction(
	'DEVICE_SET_GROUP_ROUTING',
	(groupId: string, deviceId: string) => ({
		promise: api.createGroupDevice(groupId, deviceId),
		data: {
			groupId,
			deviceId,
		},
	})
);

export const deleteDeviceGroupRouting = createAction(
	'DEVICE_DELETE_GROUP_ROUTING',
	(groupId: string, deviceId: string) => ({
		promise: api.deleteGroupDevice(groupId, deviceId),
		data: {
			groupId,
			deviceId,
		},
	})
);

export const setExternalDeviceTargetNumber = createAction(
	'EXTERNAL_DEVICE_SET_TARGET_NUMBER',
	(deviceId: string, number: string) => ({
		promise: api.setTargetNumberForExternalDevice(deviceId, number),
		data: {
			deviceId,
			number,
		},
	})
);

export const setIncomingCallDisplayForExternalDevice = createAction(
	'EXTERNAL_DEVICE_SET_INCOMING_CALL_DISPLAY',
	(deviceId: string, incomingCallDisplay: 'CALLED_NUMBER' | 'CALLER_NUMBER') => ({
		promise: api.setIncomingCallDisplayForExternalDevice(deviceId, incomingCallDisplay),
		data: {
			deviceId,
			incomingCallDisplay,
		},
	})
);

export const createDeviceExternal = createAction(
	'EXTERNAL_DEVICE_CREATE',
	(userId: string, alias: string, destination: string) => ({
		promise: (async () => {
			const device = await api.createDeviceExternal(userId, alias, destination);
			const allDevices = await api.getDevices(userId);

			return { device, allDevices };
		})(),
		data: {
			userId,
			alias,
			destination,
		},
		restrictions: {
			userId,
			restrictions: [
				'CAN_CREATE_EXTERNAL_DEVICE',
				'CAN_DELETE_EXTERNAL_DEVICE',
				'CAN_CHANGE_EXTERNAL_DEVICE',
			],
		},
	})
);

export const setCallerId = createAction(
	'DEVICE_CALLERID_SET',
	(deviceId: string, callerId: string) => ({
		promise: api.setDeviceCallerId(deviceId, callerId),
		data: {
			deviceId,
			callerId,
		},
	})
);

export const setCallRecording = createAction(
	'DEVICE_CALLRECORDING_SET',
	(deviceId: string, callRecording: boolean) => ({
		promise: api.setAutoRecordingSetting(deviceId, callRecording),
		data: {
			deviceId,
			callRecording,
		},
	})
);

export const setSingleRowDisplay = createAction(
	'DEVICE_SINGLEROWDISPLAY_SET',
	(deviceId: string, singleRowDisplay: boolean) => ({
		promise: api.setSingleRowDisplay(deviceId, singleRowDisplay),
		data: {
			deviceId,
			singleRowDisplay,
		},
	})
);

export const setTariffAnnouncement = createAction(
	'DEVICE_TARIFF_ANNOUNCEMENT_SET',
	(deviceId: string, enabled: boolean) => ({
		promise: api.setTariffAnnouncement(deviceId, enabled),
		data: {
			deviceId,
			enabled,
		},
	})
);

export const setFancyCodec = createAction(
	'DEVICE_FANCYCODEC_SET',
	(deviceId: string, enabled: boolean) => ({
		promise: api.setFancyCodec(deviceId, enabled),
		data: {
			deviceId,
			enabled,
		},
	})
);
