import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchDataAutomation = (state: ReduxState, containerId: string) =>
	!state.dataAutomations.fetchingForContainer.includes(containerId) &&
	!state.dataAutomations.fetchedForContainer.includes(containerId);

export const fetchDataAutomation = createAction(
	'DATA_AUTOMATION_FETCH',
	(containerId: string, force?: boolean) => ({
		promise: () => api.getDataAutomation(containerId),
		shouldFetch: (state: ReduxState) => force || shouldFetchDataAutomation(state, containerId),
		data: { containerId },
	})
);

export const deleteDataAutomation = createAction(
	'DATA_AUTOMATION_DELETE',
	(containerId: string) => ({
		promise: () => api.deleteDataAutomation(containerId),
		data: { containerId },
	})
);

export const setDataAutomation = createAction(
	'DATA_AUTOMATION_SET',
	(containerId: string, productId: number, limit?: number) => ({
		promise: () => api.setDataAutomation(containerId, productId, limit),
		data: { containerId, productId, limit },
	})
);
