import { RefObject, useEffect, useMemo, useState } from 'react';

export const useOnScreen = (ref: RefObject<HTMLElement>, enforce = '') => {
	const [isIntersecting, setIsIntersecting] = useState(false);

	const observer = useMemo(
		() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
		[]
	);

	useEffect(() => {
		if (ref.current) observer.observe(ref.current);
		return () => observer.disconnect();
	}, [observer, ref, enforce]);

	return isIntersecting;
};
