import api from '../../../api';
import { createAction } from '../..';
import { ReduxState } from '../../types';
import { ApiAccountAudioFile } from '../../../api/types/accountAudioFiles';

const shouldFetchCallRecordingAudioFiles = (state: ReduxState) =>
	!state.callRecordingAudioFiles.fetched && !state.callRecordingAudioFiles.fetching;

export const fetchCallRecordingAudioFiles = createAction('CALLRECORDING_AUDIOFILES_FETCH', () => ({
	promise: () => api.getCallRecordingAudioFiles(),
	shouldFetch: shouldFetchCallRecordingAudioFiles,
}));

export const fetchCallRecordingAudioFileContent = createAction(
	'CALLRECORDING_AUDIOFILE_FETCH_CONTENT',
	(audioFile: ApiAccountAudioFile) => ({
		shouldFetch: () => audioFile.url.state === 'UNFETCHED',
		promise: api.getAudioFileContent(audioFile.id),
		data: {
			audioFileId: audioFile.id,
		},
	})
);

export const setCallRecordingAudioFileAlias = createAction(
	'CALLRECORDING_AUDIOFILE_SET_ALIAS',
	(audioFileId: string, alias: string) => ({
		promise: () => api.setAudioFileAlias(audioFileId, alias),
		data: {
			audioFileId,
			alias,
		},
	})
);

export const setActiveCallRecordingAudioFile = createAction(
	'CALLRECORDING_AUDIOFILE_SET_ACTIVE',
	(activeAudioFileId: string | null) => ({
		promise: api.setActiveCallRecordingAudioFile(activeAudioFileId),
		data: {
			activeAudioFileId,
		},
	})
);

export const deleteCallRecordingAudioFile = createAction(
	'CALLRECORDING_AUDIOFILE_DELETE',
	(audioFileId: string) => ({
		promise: api.deleteAudioFile(audioFileId),
		data: {
			audioFileId,
		},
	})
);

export const createCallRecordingAudioFile = createAction(
	'CALLRECORDING_AUDIOFILE_CREATE',
	(filename: string, base64Content: string) => ({
		promise: api.createCallRecordingAudioFile(filename, base64Content),
	})
);
