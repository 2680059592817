import React from 'react';
import classes from './HighlightedText.scss';

type Props = {
	text: string;
	highlight: { start: number; length: number } | null;
};

export const HighlightedText = ({ text, highlight }: Props) => {
	if (highlight == null) {
		return <>{text}</>;
	}

	return (
		<>
			{text.substring(0, highlight.start)}
			<span className={classes.highlighted}>
				{text.substring(highlight.start, highlight.length + highlight.start)}
			</span>
			{text.substring(highlight.start + highlight.length)}
		</>
	);
};
