import { createAction } from '../..';
import api from '../../../api';

import { AccountEvent } from './types';
import { ReduxState } from '../../types';
import { EVENT_TYPE_STICKY } from './event-types';

function shouldFetchEvents(state: ReduxState) {
	return state.events.items.length === 0 && !state.events.fetched && !state.events.fetching;
}

export const fetchEvents = createAction('EVENTS_FETCH', () => ({
	promise: () => api.getEvents(),
	shouldFetch: shouldFetchEvents,
}));

export const forceFetchEvents = createAction('EVENTS_FETCH', () => ({
	promise: () => api.getEvents(),
}));

export const discardEvent = createAction('EVENT_DELETE', (event: AccountEvent) => ({
	promise: () => {
		if (
			event.eventId.toUpperCase().endsWith('LOCAL') ||
			event.eventType.toUpperCase() === EVENT_TYPE_STICKY
		) {
			return Promise.resolve();
		}

		return api.deleteEvent(event.payload.commandId);
	},
	data: { eventId: event.eventId, eventType: event.eventType },
}));

export const createLocalEvent = createAction('EVENT_CREATE_LOCAL', (event: AccountEvent) => ({
	promise: () => Promise.resolve(event),
}));
