import React, { useContext, useMemo } from 'react';
import { UserInfo } from '../redux/modules/userinfo';

interface SessionData {
	userinfo: UserInfo;
}

const SessionContext = React.createContext<SessionData | null>(null);

export const useSession = () => {
	const ctx = useContext(SessionContext);

	if (!ctx) {
		throw new Error('Used useAuthenticatedContext outside of an SessionProvider');
	}

	return ctx;
};

export const SessionProvider = (props: { userinfo: UserInfo; children: React.ReactNode }) => {
	const data = useMemo(() => ({ userinfo: props.userinfo }), [props.userinfo]);

	return <SessionContext.Provider value={data}>{props.children}</SessionContext.Provider>;
};
