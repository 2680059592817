import { createSlice } from '@reduxjs/toolkit';
import { BlocklistState } from './types';
import {
	createBlocklistEntry,
	deleteBlocklistEntry,
	fetchBlocklistEntries,
	updateBlocklistEntry,
} from './actions';

const initialState: BlocklistState = {
	fetched: false,
	fetching: false,
	items: [],
};

const getItemObject = (phoneNumber: string, isBlock: boolean) => ({ phoneNumber, isBlock });

export const slice = createSlice({
	name: 'blocklist',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchBlocklistEntries.pending, state => ({ ...state, fetching: true }));
		builder.addCase(fetchBlocklistEntries.fulfilled, (state, { payload }) => ({
			...state,
			fetched: true,
			fetching: false,
			items: payload.items,
		}));

		builder.addCase(createBlocklistEntry.pending, (state, { meta }) => {
			const updatedItems = [...state.items];
			const index = updatedItems.findIndex(item => item.phoneNumber === meta.arg.phoneNumber);
			if (index > -1) {
				return state;
			}

			return {
				...state,
				items: [...state.items, getItemObject(meta.arg.phoneNumber, meta.arg.isBlock)],
			};
		});

		builder.addCase(deleteBlocklistEntry.pending, (state, { meta }) => ({
			...state,
			items: state.items.filter(item => item.phoneNumber !== meta.arg),
		}));

		builder.addCase(updateBlocklistEntry.pending, (state, { meta }) => {
			const updatedItems = [...state.items];
			const itemIndexToUpdate = updatedItems.findIndex(
				item => item.phoneNumber === meta.arg.oldPhoneNumber
			);

			updatedItems[itemIndexToUpdate] = getItemObject(meta.arg.newPhoneNumber, meta.arg.isBlock);

			return { ...state, items: updatedItems };
		});
	},
});

export const reducer = slice.reducer;
