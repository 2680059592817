// eslint-disable-next-line no-restricted-syntax
import { useCallback } from 'react';
import { Action } from '@reduxjs/toolkit';
import { createStore } from './store';
import { ReduxState } from './types';
import { ThunkAction } from './middleware/thunk-middleware';
import { useDispatch } from './utils/wrapper';

export { createAction, handleActions } from './utils/actions';
export type { Immutable } from './utils/immutable';

export { Provider, connect } from './utils/wrapper';
export type { ReduxProps } from './utils/wrapper';

// Make sure an action returned by a dispatch call (e.g. calling something connected via mapDispatchToProps)
// actually triggered an API call.
export function didFetch(action: {
	type: string;
	payload?: unknown;
}): action is { type: string; payload: { promise: Promise<unknown> } } {
	const payload: object = action.payload || {};
	if (!('promise' in payload)) {
		return false;
	}

	const payloadWithPromise = payload as { promise: unknown };
	if (typeof payloadWithPromise.promise !== 'object' || payloadWithPromise.promise === null) {
		return false;
	}

	return (
		'then' in payloadWithPromise.promise && typeof payloadWithPromise.promise.then === 'function'
	);
}

export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = AppStore['dispatch'];

export type SliceData<T> = { data: null; fetched: false } | { data: T; fetched: true };

export const useAction = <
	Args extends unknown[],
	A extends Action | ThunkAction<ReduxState, Dispatch<unknown>, unknown>,
>(
	actionCreator: (...args: Args) => A
) => {
	const dispatch = useDispatch();

	return useCallback(
		(...args: Args) => dispatch(actionCreator(...args)),
		[actionCreator, dispatch]
	);
};
