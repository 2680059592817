import { useEffect, useState } from 'react';

export const useReducedMotion = (): boolean => {
	const [hasReducedMotionActivated, setHasReducedMotionActivated] = useState(false);

	const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
	useEffect(() => {
		setHasReducedMotionActivated(prefersReducedMotion);
	}, [prefersReducedMotion]);

	return hasReducedMotionActivated;
};
