import { Store } from '@reduxjs/toolkit';

import { fetchHistory } from '../fetchHistory/action';
import { State } from '../../state';
import {
	DearchiveEntriesPendingAction,
	DearchiveEntriesSuccessAction,
	DearchiveEntriesErrorAction,
} from './types';
import api from '../../../../../api';
import { Selections } from '../../../selections/parsers';

export const dearchiveEntries =
	(store: Store<State>) => async (eventIds: string[], selections: Selections) => {
		const { result } = store.getState().entries;
		const dearchivingAll = !!result && result.totalCount === eventIds.length;
		const dearchivingPage = !!result && result.normalized.length === eventIds.length;

		store.dispatch<DearchiveEntriesPendingAction>({
			type: 'DEARCHIVE_ENTRIES_PENDING',
			eventIds,
		});

		try {
			await api.updateHistoryEntries(eventIds.map(eventId => ({ id: eventId, archived: false })));

			store.dispatch<DearchiveEntriesSuccessAction>({ type: 'DEARCHIVE_ENTRIES_SUCCESS' });
		} catch (e) {
			store.dispatch<DearchiveEntriesErrorAction>({ type: 'DEARCHIVE_ENTRIES_ERROR' });

			throw e;
		} finally {
			if (dearchivingPage && !dearchivingAll) {
				fetchHistory(store)(selections);
			}
		}
	};
