import { createAction } from '../..';

import api from '../../../api';
import { UserInfoState } from './types';

export const fetchUserInfo = createAction('USERINFO_FETCH', () => ({
	promise: () => api.getUserInfo(),
	shouldFetch: ({ userinfo }: { userinfo: UserInfoState }) =>
		!userinfo.fetched && !userinfo.fetching,
}));
