import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

export const initialState = {
	items: {},
	fetched: false,
	fetching: false,
};

export const slice = createSlice({
	name: 'lpaUris',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(actions.fetchLpaData.pending, state => ({ ...state, fetching: true }));
		builder.addCase(
			actions.fetchLpaData.fulfilled,
			(
				state,
				{
					payload,
					meta: {
						arg: { deviceId },
					},
				}
			) => ({
				...state,
				fetching: false,
				fetched: true,
				items: { ...state.items, [deviceId]: payload },
			})
		);
	},
});

export const reducer = slice.reducer;
