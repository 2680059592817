import { DateTime } from 'luxon';
import {
	BaseContract,
	CallPackageContract,
	Contingent,
	ContingentContract,
	Contract,
	DeviceContract,
} from './types';

// We don't know what this is for. If you know, please give it a proper name.
// Also, this shouldn't be done in our frontend (see also ADR 2019-03-18-product-specific-logic.md).
const isOminousUnknownSpecialThing = (contract: ContingentContract) =>
	contract.productName.startsWith('sipgate_');

export const isBaseContract = (product: Contract): product is BaseContract => {
	return product.productType === 'base';
};

export const isDataSnackContract = (contract: ContingentContract) =>
	contract.productName.startsWith('dataflat_upgrade_') ||
	contract.productName.startsWith('CA_CH_US_ROAMINGDATA_') ||
	contract.productName.startsWith('ROW_ROAMINGDATA_');

export const isCallPackageContract = (contract: DeviceContract): contract is CallPackageContract =>
	contract.contractType === 'CALLPACKAGE';

export const isContingentContract = (contract: DeviceContract): contract is ContingentContract =>
	contract.contractType === 'CONTINGENT';

export const isActiveContract = (contract: DeviceContract) =>
	contract.activationDate === null || contract.activationDate < new Date();

export const isFutureContract = (
	contract: DeviceContract,
	now: DateTime
): contract is DeviceContract & { activationDate: Date } =>
	!!contract.activationDate && DateTime.fromJSDate(contract.activationDate) > now;

export const isCancelledContract = (
	contract: DeviceContract
): contract is DeviceContract & { cancellationDate: Date } => !!contract.cancellationDate;

export const selectContractsForDevice = (items: DeviceContract[], deviceId: string) => {
	return items.filter(item => item.deviceId === deviceId);
};

export const calculateTotalContingentAmount = (contingents: Contingent[]) =>
	contingents.reduce((curr, next) => curr + next.amount, 0);

export const calculateTotalContingentUsed = (contingents: Contingent[]) =>
	contingents.reduce((curr, next) => curr + next.used, 0);

export const selectPrimaryDataContingents = (items: DeviceContract[], deviceId: string) => {
	return selectContractsForDevice(items, deviceId)
		.filter(isContingentContract)
		.filter(isOminousUnknownSpecialThing)
		.sort(
			(a, b) =>
				calculateTotalContingentAmount(b.contingents) -
				calculateTotalContingentAmount(a.contingents)
		)
		.flatMap(deviceContingents =>
			deviceContingents.contingents.filter(contingent => contingent.unit === 'KILOBYTE')
		);
};

export const selectAllDataContingents = (items: DeviceContract[], deviceId: string) => {
	return selectContractsForDevice(items, deviceId)
		.filter(isContingentContract)
		.filter(contract => isDataSnackContract(contract) || isOminousUnknownSpecialThing(contract))
		.flatMap(deviceContingent => deviceContingent.contingents)
		.filter(contingent => contingent.unit === 'KILOBYTE');
};
