import React from 'react';

import classes from './VideoIdentContent.scss';
import Spinner from '../../../../spinner/Spinner';
import { UserInfo } from '../../../../../redux/modules/userinfo';

interface ComponentProps {
	translate: (key: string) => string;
	verificationNotFound?: boolean;
	autoIdentPossible: boolean;
	userInfo: UserInfo;
}

export const VideoIdentContent = (props: ComponentProps) => {
	if (props.verificationNotFound) {
		return (
			<div className={classes.videoIdentContent}>
				<div className={classes.spinner}>
					<Spinner />
				</div>
				<div>{props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_DIALOG_SPINNER')}</div>
			</div>
		);
	}
	if (props.userInfo.sub === 'w0') {
		return (
			<div className={classes.videoIdentContent}>
				<p>{props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_DIALOG_CONTENT')}</p>
				<p>
					{props.autoIdentPossible
						? props.translate('IDENTITY_VERIFICATION_DIALOG_DESCRIPTION_WITH_AUTOIDENT')
						: props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_DIALOG_LINKS')}
				</p>
			</div>
		);
	}

	return (
		<div className={classes.videoIdentContent}>
			<p>{props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_DIALOG_CONTENT_NON_ADMIN')}</p>
		</div>
	);
};
