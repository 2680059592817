import { Store } from '@reduxjs/toolkit';
import { State } from '../state';
import { Contact } from '../../../../redux/modules/contacts';
import { UpdateContactsAction } from './types';

export const updateContacts = (store: Store<State>) => (contacts: Contact[]) => {
	store.dispatch<UpdateContactsAction>({
		type: 'UPDATE_CONTACTS',
		contacts,
	});
};
