import { handleActions } from '../../..';

import * as actions from './actions';
import { TrunkCallRestrictionState } from './types';

const initialState: TrunkCallRestrictionState = {
	fetching: false,
	fetched: false,
	items: [],
};

export default handleActions<TrunkCallRestrictionState, PossibleActions<typeof actions>>(
	{
		CALLRESTRICTIONS_TRUNK_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),

		CALLRESTRICTIONS_TRUNK_FETCH_SUCCESS: (state, { payload }) => {
			return {
				items: payload.items,
				fetching: false,
				fetched: true,
			};
		},

		CALLRESTRICTIONS_TRUNK_SET_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== data.id) {
					return item;
				}

				return {
					...item,
					[data.restriction]: data.enabled,
				};
			}),
		}),
	},
	initialState
);
