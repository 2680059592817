import { createSlice } from '@reduxjs/toolkit';
import { SmsCallerIdState } from './types';
import {
	createSmsCallerIds,
	fetchSmsCallerIds,
	setSmsCallerIdActive,
	verifySmsCallerId,
} from './actions';

const initialState: SmsCallerIdState = {};

export const reducer = createSlice({
	name: 'smsCallerIds',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchSmsCallerIds.fulfilled, (state, action) => {
				return {
					...state,
					[action.meta.arg.smsId]: { items: action.payload.items },
				};
			})
			.addCase(setSmsCallerIdActive.pending, (state, action) => {
				const smsCallerIds = state[action.meta.arg.smsId];

				if (smsCallerIds) {
					const newItems = smsCallerIds.items.map(smsCallerId => ({
						...smsCallerId,
						defaultNumber:
							smsCallerId.id === action.meta.arg.smsCallerId
								? action.meta.arg.isDefaultNumber
								: false,
					}));

					if (!action.meta.arg.isDefaultNumber) {
						newItems[0].defaultNumber = true;
					}

					return { ...state, [action.meta.arg.smsId]: { items: newItems } };
				}

				return state;
			})
			.addCase(verifySmsCallerId.fulfilled, (state, action) => {
				const smsCallerIds = state[action.meta.arg.smsId];

				if (smsCallerIds) {
					const newItems = smsCallerIds.items.map(smsCallerId => {
						if (smsCallerId.id === action.meta.arg.smsCallerId) {
							return { ...smsCallerId, verified: action.payload.verified };
						}
						return smsCallerId;
					});
					return { ...state, [action.meta.arg.smsId]: { items: newItems } };
				}

				return state;
			})
			.addCase(createSmsCallerIds.fulfilled, (state, action) => {
				return {
					...state,
					[action.meta.arg.smsId]: { items: action.payload.smsCallerIds.items },
				};
			});
	},
}).reducer;
