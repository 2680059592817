import { getCurrentCountryCode, isPotentialPhonenumber } from '@web-apps/phonenumbers-utils';
import { Contact } from '../types';

const CHUNK_SIZE = 1000;

const doOtherWorkOnEventloop = () => new Promise(res => setTimeout(res, 0));

export interface ContactSearchResult {
	number: string;
	contact: Contact;
}

export const searchContacts = async (
	contacts: Contact[],
	searchString: string,
	maxResultCount = Infinity
): Promise<Contact[]> => {
	if (searchString.length === 0) {
		return [];
	}

	const matchedByName = [];

	for (let i = 0; i < contacts.length; i += 1) {
		if (i % CHUNK_SIZE === 0) {
			await doOtherWorkOnEventloop(); // eslint-disable-line no-await-in-loop
		}

		const contact = contacts[i];
		const contactIndex = contact.name.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase());

		if (contactIndex > -1) {
			matchedByName.push({ contact, start: contactIndex === 0 });
			continue; // eslint-disable-line no-continue
		}
	}

	matchedByName.sort((a, b) => {
		if (a.start !== b.start) {
			return a.start ? -1 : 1;
		}

		return a.contact.name.localeCompare(b.contact.name);
	});

	return matchedByName.map(i => i.contact).slice(0, maxResultCount);
};

export const searchInContacts = async (
	contacts: Contact[],
	searchString: string,
	{ maxResultCount = Infinity, skipQuickdials = false } = {}
): Promise<ContactSearchResult[]> => {
	const possibleNumberMatches: string[] = [];
	const searchClean = searchString.replace(/[^\d]/g, '');

	if (searchString.length === 0) {
		return [];
	}

	if (searchString.trim().startsWith('+')) {
		possibleNumberMatches.push(`+${searchClean}`);
	} else {
		if (searchString.trim().startsWith('00')) {
			possibleNumberMatches.push(searchClean.replace(/^00/, '+'));
		} else if (searchString.startsWith('0')) {
			possibleNumberMatches.push(searchClean.replace(/^0/, `+${getCurrentCountryCode()}`));
		}

		possibleNumberMatches.push(searchClean);
	}

	const shouldSearchNumbers = isPotentialPhonenumber(searchString) && searchClean.length > 0;

	const matchedByName = [];
	const matchedByNumber = [];
	const matchedByDirectDial = [];

	for (let i = 0; i < contacts.length; i += 1) {
		if (i % CHUNK_SIZE === 0) {
			await doOtherWorkOnEventloop(); // eslint-disable-line no-await-in-loop
		}

		const contact = contacts[i];
		const contactIndex = contact.name.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase());

		if (contactIndex > -1) {
			matchedByName.push({ contact, start: contactIndex === 0 });
			continue; // eslint-disable-line no-continue
		}

		if (!shouldSearchNumbers) {
			continue; // eslint-disable-line no-continue
		}

		for (const number of contact.numbers) {
			const isDirectDial = number.number.length <= 4;

			if (isDirectDial) {
				const index = number.number.indexOf(searchString.trim());

				if (index !== -1) {
					matchedByDirectDial.push({
						number: number.number,
						start: index === 0,
						contact,
					});
				}
			} else if (possibleNumberMatches.find(possibleMatch => possibleMatch.length >= 3)) {
				for (const possibleNumberMatch of possibleNumberMatches) {
					const index = number.number.indexOf(possibleNumberMatch);

					if (index !== -1) {
						matchedByNumber.push({
							number: number.number,
							start: index === 0,
							contact,
						});

						break;
					}
				}
			}
		}
	}

	matchedByName.sort((a, b) => {
		if (a.start !== b.start) {
			return a.start ? -1 : 1;
		}

		return a.contact.name.localeCompare(b.contact.name);
	});
	matchedByNumber.sort((a, b) => {
		if (a.start !== b.start) {
			return a.start ? -1 : 1;
		}

		return a.number.localeCompare(b.number);
	});
	matchedByDirectDial.sort((a, b) => {
		if (a.start !== b.start) {
			return a.start ? -1 : 1;
		}

		return a.number.localeCompare(b.number);
	});

	const numbersMatchedByName = [];
	for (const { contact } of matchedByName) {
		numbersMatchedByName.push(
			...contact.numbers
				.filter(n => n.number.length > 4 || !skipQuickdials)
				.map(number => ({ number: number.number, contact }))
		);
	}

	return [
		...(skipQuickdials
			? []
			: matchedByDirectDial.map(match => ({ contact: match.contact, number: match.number }))),
		...numbersMatchedByName,
		...matchedByNumber.map(match => ({ contact: match.contact, number: match.number })),
	].slice(0, maxResultCount);
};
