import { useCallback, useEffect } from 'react';

import { Report } from './types';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { addReport, deleteReport, fetchReports, renameReport } from './actions';

interface UseReportsHook {
	fetched: boolean;
	fetching: boolean;
	items: Report[];
	fetchReports: () => void;
	deleteReport: (id: string) => void;
	renameReport: (id: string, name: string) => void;
	createReport: (name: string, grouping: string) => void;
}
export const useReports = (): UseReportsHook => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchReports());
	}, [dispatch]);

	return {
		items: useSelector(state => state.reports.items),
		fetched: useSelector(state => state.reports.fetched),
		fetching: useSelector(state => state.reports.fetching),
		fetchReports: useCallback(() => dispatch(fetchReports()), [dispatch]),
		deleteReport: useCallback((id: string) => dispatch(deleteReport(id)), [dispatch]),
		renameReport: useCallback(
			(id: string, name: string) => dispatch(renameReport(id, name)),
			[dispatch]
		),
		createReport: useCallback(
			(name: string, grouping: string) => dispatch(addReport({ name, grouping })),
			[dispatch]
		),
	};
};

export const useReport = (id: string): Report | undefined => {
	const reports = useSelector(state => state.reports.items);
	return reports.find(report => report.id === id);
};
