import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { closeWarningSnackbar } from '../../redux/slices/snackbar';
import Snackbar from './Snackbar';
import { ReduxState } from '../../redux/types';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';

const mapStateToProps = (state: ReduxState) => ({
	open: state.dialogs.warningSnackbarOpen,
	message: state.dialogs.warningSnackbarMessage,
	translationsFetched: state.translations.fetched,
});

const mapDispatchToProps = {
	closeWarningSnackbar,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & TranslateProps;

const WarningSnackbar = (props: Props) => {
	if (!props.translationsFetched) {
		return null;
	}

	return (
		<Snackbar
			key="warning-snackbar"
			snackbarOpen={props.open}
			closeSnackbar={props.closeWarningSnackbar}
			severity="warning"
		>
			{props.message ? props.message : props.translate('SNACKBAR_WARNING_TEXT')}
		</Snackbar>
	);
};

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(WarningSnackbar));
