import { useEffect } from 'react';
import { SliceData } from '../../index';
import { NQNupsiState } from './types';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { isNqContext } from './actions';

export const useNqNupsi = (): SliceData<NQNupsiState> => {
	const dispatch = useDispatch();
	const data = useSelector(s => s.nqNupsi);

	useEffect(() => {
		if (data.fetching === 'idle') {
			dispatch(isNqContext());
		}
	}, [dispatch, data.fetching]);

	if (data.fetching !== 'succeeded') {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data,
		fetched: true,
	};
};
