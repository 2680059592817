import React from 'react';
import { Step } from 'react-joyride';

import { TourProps } from './types';
import JoyrideTour from './JoyrideTour';

export const StatusPageTour = (props: TourProps) => {
	const steps: Step[] = [
		{
			target: 'body',
			title: props.translate('STATUS_PAGE_TOUR_TITLE'),
			content: props.translate.markdown.block('STATUS_PAGE_TOUR_DESCRIPTION'),
			disableBeacon: true,
			placement: 'center',
		},
	];

	return <JoyrideTour translate={props.translate} steps={steps} onDone={props.onDone} />;
};
