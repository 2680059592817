import { createSlice } from '@reduxjs/toolkit';
import { AppProperties } from './types';
import * as actions from './actions';

const initialState: AppProperties = {
	identityVerificationRequired: false,
	identityVerificationRequiredForMobile: false,
	showSimRelevantOptions: false,
	showNettoPrices: false,
	fetched: false,
	fetching: false,
};

const slice = createSlice({
	name: 'appProperties',
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(actions.fetchAppProperties.pending, state => ({
				...state,
				fetching: true,
			}))
			.addCase(actions.fetchAppProperties.fulfilled, (state, { payload }) => ({
				...state,
				...payload,
				fetched: true,
				fetching: false,
			})),
});

export const reducer = slice.reducer;
