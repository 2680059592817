import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

function shouldFetchTariffAnnouncement(state: ReduxState, deviceId: string) {
	return (
		!state.mobileDeviceTariffAnnouncements.fetchedForDevice.includes(deviceId) &&
		!state.mobileDeviceTariffAnnouncements.fetchingForDevice.includes(deviceId)
	);
}

export const fetchMobileDeviceTariffAnnouncement = createAction(
	'DEVICE_TARIFF_ANNOUNCEMENT_FETCH',
	(deviceId: string) => ({
		promise: () => api.getTariffAnnouncement(deviceId),
		shouldFetch: (state: ReduxState) => shouldFetchTariffAnnouncement(state, deviceId),
		data: {
			deviceId,
		},
	})
);
