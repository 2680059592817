import { handleActions } from '../..';

import * as actions from './actions';
import { setFaxlineAlias } from '../faxlines';
import { NotificationState } from './types';

const initialState: NotificationState = {
	fetchedForUser: [],
	fetchingForUser: [],

	items: {
		fax: [],
		call: [],
		sms: [],
		voicemail: [],
	},
};

export default handleActions<
	NotificationState,
	PossibleActions<typeof actions | typeof setFaxlineAlias>
>(
	{
		NOTIFICATIONS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForUser: [...state.fetchingForUser, data.userId],
		}),

		NOTIFICATIONS_FETCH_SUCCESS: (state, { data, payload }) => ({
			fetchingForUser: state.fetchingForUser.filter(user => user !== data.userId),
			fetchedForUser: [...state.fetchedForUser, data.userId],
			items: payload,
		}),

		NOTIFICATION_CREATE_SUCCESS: (state, { payload }) => ({
			...state,
			items: payload,
		}),

		NOTIFICATION_CHANGE_SUCCESS: (state, { payload }) => ({
			...state,
			items: payload,
		}),

		NOTIFICATION_DELETE_SUCCESS: (state, { payload }) => ({
			...state,
			items: payload,
		}),

		FAXLINE_SET_ALIAS_PENDING: (state, action) => ({
			...state,
			items: {
				...state.items,
				fax: state.items.fax.map(fax => {
					if (fax.endpointId !== action.data.faxlineId) {
						return fax;
					}

					return { ...fax, endpointAlias: action.data.alias };
				}),
			},
		}),
	},
	initialState
);
