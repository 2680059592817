import React, { ComponentProps } from 'react';
import classnames from 'classnames';
import { SubTabItem } from './SubTabItem';
import classes from './Tabs.module.css';

type Props = {
	/**
	 * Die onChange Funktion wird bei Click auf einen der Tabs ausgeführt und übergibt den Index des geklickten Tabs in die Funktion.
	 */
	children:
		| React.ReactElement<ComponentProps<typeof SubTabItem>>[]
		| React.ReactElement<ComponentProps<typeof SubTabItem>>;
	/**
	 * Das Label gibt der Navigation einen eindeutigen Namen, um diese von anderen Navigationen zu unterscheiden.
	 * [Siehe auch Landmarks auf MDN](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/landmark_role)
	 */
	landmarkLabel?: string;
	actionElement: React.ReactNode;
};

const styles = {
	nav: classnames(classes.border, 'overflow-x-hidden', 'flex', 'flex-row', 'align-center'),
	list: classnames(
		'overflow-x-auto',
		'flex-grow-0',
		'flex-shrink',
		'flex',
		'flex-row',
		'items-stretch',
		'justify-start',
		'm-0',
		'p-0',
		'select-none'
	),
	actionElementWrapper: classnames(
		'flex',
		'flex-shrink-0',
		'flex-grow-0',
		'self-end',
		'before:w-[1px]',
		'before:flex',
		'before:h-[2.625rem]',
		'before:self-center',
		'before:content-[""]',
		'before:bg-[var(--gray-100)]',
		'before:rounded-[1px]',
		'before:mr-4',
		'mr-4',
		'self-center'
	),
};

const SubTabs = ({ children, landmarkLabel, actionElement }: Props): JSX.Element | null => {
	const activeTabs = React.Children.toArray(children).filter(
		child => React.isValidElement(child) && child.props.isActive
	);
	if (activeTabs.length > 1) {
		throw new Error('There can only be one active <Tab> in <Tabs/>');
	}

	if (React.Children.count(children) === 0) {
		return null;
	}
	return (
		<nav className={styles.nav} aria-label={landmarkLabel}>
			<ul role="tablist" className={styles.list}>
				{children}
			</ul>
			<div className={styles.actionElementWrapper}>{actionElement}</div>
		</nav>
	);
};

export { SubTabs };
