import { createAction } from '../..';
import api from '../../../api';
import { PhoneProvisioningState } from './types';

const shouldFetchPhone = (state: PhoneProvisioningState) =>
	!state.items || state.items.length === 0;

export const createPhoneProvisioning = createAction(
	'PHONE_PROVISIONING_ADD',
	(
		macAddress: string,
		successCallback: () => void,
		webuserId?: string,
		syncContacts?: boolean
	) => ({
		promise: () =>
			api.addProvisionedPhone(macAddress, webuserId, syncContacts).then(successCallback),
	})
);

export const deletePhoneProvisioning = createAction(
	'PHONE_PROVISIONING_DELETE',
	(macAddress: string) => ({
		promise: () => api.deleteProvisionedPhone(macAddress),
		data: { macAddress },
	})
);

export const fetchPhones = createAction('PHONE_PROVISIONING_PHONES_FETCH', () => ({
	promise: () => api.getProvisionedPhones(),
	shouldFetch: (state: PhoneProvisioningState) => shouldFetchPhone(state),
}));

export const updateSnomConfig = createAction(
	'PHONE_PROVISIONING_UPDATE_CONFIG',
	(config: string, macAddress: string) => ({
		promise: () => api.updateSnomConfig(config, macAddress),
		data: {
			config,
			macAddress,
		},
	})
);

export const setSnomWebuser = createAction(
	'PHONE_PROVISIONING_SET_WEBUSER',
	(macAddress: string, webUserExtension: string | null, successCallback: () => void) => ({
		promise: async () => {
			const result = await api.setSnomWebuser(macAddress, webUserExtension);
			successCallback();
			return result;
		},
		data: {
			webUserExtension,
			macAddress,
		},
	})
);

export const setSyncContacts = createAction(
	'PHONE_PROVISIONING_SYNC_CONTACTS',
	(macAddress: string, syncContacts: boolean) => ({
		promise: () => api.setSyncContacts(macAddress, syncContacts),
		data: {
			macAddress,
		},
	})
);
