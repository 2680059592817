import React from 'react';
import { NavLinkProps, NavLink } from 'react-router-dom';

export type { NavLinkProps as SmartLinkProps } from 'react-router-dom';

const navLinkOnlyProps = new Set([
	'to',
	'replace',
	'innerRef',
	'activeClassName',
	'activeStyle',
	'exact',
	'strict',
	'isActive',
	'location',
]);

export class SmartLink extends React.Component<NavLinkProps> {
	public render() {
		const aProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {};

		for (const [key, value] of Object.entries(this.props)) {
			if (!navLinkOnlyProps.has(key)) {
				aProps[key as keyof React.AnchorHTMLAttributes<HTMLAnchorElement>] = value;
			}
		}

		if (
			typeof this.props.to === 'string' &&
			(this.props.to.startsWith('http') || this.props.to.startsWith('//'))
		) {
			return (
				// Fine for a wrapper component
				//
				// eslint-disable-next-line react/jsx-props-no-spreading
				<a {...aProps} ref={this.props.innerRef} href={this.props.to}>
					{this.props.children}
				</a>
			);
		}

		// Fine for a wrapper component
		//
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <NavLink {...this.props} />;
	}
}
