import { deviceIconTypes } from '.';
import { DeviceProperty } from '../../../api/types/DeviceProperty';
import { DeviceIconType } from './types';

export const selectDevicePropertiesByKey = (
	deviceProperties: DeviceProperty[],
	key: string,
	deviceId: string
) =>
	deviceProperties.filter(
		(property: DeviceProperty) => property.deviceId === deviceId && property.key === key
	);

function isDeviceIconType(value: string): value is DeviceIconType {
	return (deviceIconTypes as readonly string[]).includes(value);
}

export const selectDeviceIcon = (deviceProperties: DeviceProperty[], deviceId: string) => {
	const property = selectDevicePropertiesByKey(deviceProperties, 'DEVICE_ICON', deviceId).pop();

	if (!property || typeof property.value !== 'string' || !isDeviceIconType(property.value)) {
		return null;
	}

	return property.value;
};

export const selectDeviceProperties = (
	deviceProperties: DeviceProperty[],
	...deviceId: string[]
) => {
	return deviceProperties.filter(
		property => deviceId.findIndex(id => property.deviceId === id) >= 0
	);
};
