import { ManagedMultiFieldValidator } from '../ManagedForm';
import { Translate } from './types';

/**
 * Validates if at least one of the given validators succeeds.
 *
 * ```
 * oneOfValidators(
 *     this.props.translate,
 *     validateExactLength(this.props.translate, 4),
 *     validateEmpty(this.props.translate)
 * )
 * ```
 */
export const oneOfValidators =
	<Output, Values = unknown>(
		translate: Translate,
		...validators: ManagedMultiFieldValidator<string, Values, Output>[]
	): ManagedMultiFieldValidator<string, Values, Output> =>
	(input, values) => {
		const errors = [];

		for (const validator of validators) {
			const result = validator(input, values);

			if (result.valid) {
				return result;
			}

			errors.push(result.error);
		}

		return { valid: false, error: errors.join(translate('FORM_VALIDATION_COMBINING')) };
	};
