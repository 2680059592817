import { Button } from '@panda/ui';
import React from 'react';
import { Contact } from '../../../redux/modules/contacts';
import { Translate } from '../../../redux/slices/translations';
import { SipgateDomain } from '../../../redux/modules/userinfo';
import { C2DButtonDropdown } from './C2DButtonDropdown';

interface Props {
	number: string;
	domain: SipgateDomain;
	contact?: Contact;
	translate: Translate;
	isReturningCall?: boolean;
	onClick2Dial: (number: string) => void;
}

export class C2DButton extends React.PureComponent<Props> {
	private renderButton(number: string) {
		return (
			<Button onClick={() => this.props.onClick2Dial(number)} variant="loud">
				{this.props.translate(
					this.props.isReturningCall ? 'CALL_EVENT_C2D_BUTTON_CALLBACK' : 'CALL_EVENT_C2D_BUTTON'
				)}
			</Button>
		);
	}

	public render() {
		if (!this.props.contact) {
			return this.renderButton(this.props.number);
		}

		if (this.props.contact.numbers.length <= 1) {
			return this.renderButton(this.props.number);
		}

		return (
			<C2DButtonDropdown
				domain={this.props.domain}
				contact={this.props.contact}
				isReturningCall={this.props.isReturningCall}
				number={this.props.number}
				translate={this.props.translate}
				onClick2Dial={this.props.onClick2Dial}
			/>
		);
	}
}
