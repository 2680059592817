import { handleActions } from '../..';
import { PseudoState } from './types';
import * as actions from './actions';

export const initialState: PseudoState = {
	items: [],
};

export default handleActions<PseudoState, PossibleActions<typeof actions>>(
	{
		CREATE_PSEUDO: (state, { payload }) => ({
			...state,
			items: [...state.items, payload],
		}),
		DELETE_PSEUDO: (state, { payload }) => ({
			...state,
			items: state.items.filter(item => item.key !== payload.key),
		}),
	},
	initialState
);
