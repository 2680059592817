import { handleActions } from '../..';

import { PortingsState } from './types';
import * as actions from './actions';

const initialState: PortingsState = {
	items: [],
	fetched: false,
	fetching: false,
};

export default handleActions<PortingsState, PossibleActions<typeof actions>>(
	{
		PORTINGS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		PORTINGS_FETCH_SUCCESS: (state, { payload }) => ({
			items: payload.items,
			fetching: false,
			fetched: true,
		}),
		PORTINGS_REVOKE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(porting => {
				if (porting.id === data.portingId) {
					return { ...porting, pending: false };
				}

				return porting;
			}),
		}),
	},
	initialState
);
