import { createAction } from '@reduxjs/toolkit';
import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';

export const setCallRecorderStatus = createAction('callrecorder/setStatus', (active: boolean) => ({
	payload: active,
}));
export const activateCallRecorder = createAsyncThunk('callrecorder/activate', async _ =>
	api.activateCallRecorder()
);
export const deactivateCallRecorder = createAsyncThunk('callrecorder/deactivate', async _ =>
	api.deactivateCallRecorder()
);
