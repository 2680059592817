import { createSlice } from '@reduxjs/toolkit';
import { ScreenreaderAnnouncementsState } from './index';
import { openPoliteScreenreaderAnnouncement, closePoliteScreenreaderAnnouncement } from './actions';

export const initialState: ScreenreaderAnnouncementsState = {
	politeMessage: null,
};

const slice = createSlice({
	name: 'screenreaderAnnouncements',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(openPoliteScreenreaderAnnouncement, (_state, { payload }) => ({
			politeMessage: payload,
		}));
		builder.addCase(closePoliteScreenreaderAnnouncement, () => ({
			politeMessage: null,
		}));
	},
});

export const reducer = slice.reducer;
