import { PhonelineBlockAnonymousState } from './types';

export const selectBlockAnonymous = (
	phonelineBlockAnonymousState: PhonelineBlockAnonymousState,
	phonelineId: string
) =>
	phonelineBlockAnonymousState.items.find(p => p.phoneline === phonelineId) || {
		phoneline: phonelineId,
		fetched: false,
		fetching: false,
		updating: false,
	};
