import { createSlice, UnknownAction } from '@reduxjs/toolkit';
import { handleActions } from '../..';
import { NumberState } from './types';
import {
	ApiInternationalNumber,
	ApiLandlineNumber,
	ApiMobileNumber,
} from '../../../api/types/numbers';
import { sortPhoneNumbers } from './selectors';
import * as actions from './actions';
import { createAcd, deleteAcd } from '../../slices/acds';

const initialState: NumberState = {
	items: [],
	fetched: false,
	fetching: false,
};

const rtkReducer = createSlice({
	name: 'numbers',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(createAcd.fulfilled, (state, { payload }) => ({
				...state,
				items: state.items.map(number => {
					if (payload.phoneNumbers.includes(number.number)) {
						return {
							...number,
							endpointId: payload.id,
							endpointAlias: payload.name,
						};
					}
					return number;
				}),
			}))
			.addCase(deleteAcd.fulfilled, (state, action) => ({
				...state,
				items: state.items.map(number => {
					if (number.endpointId === action.meta.arg.acdId) {
						return {
							...number,
							endpointId: '',
							endpointAlias: '',
						};
					}

					return number;
				}),
			}));
	},
}).reducer;

const legacyReducer = handleActions<NumberState, PossibleActions<typeof actions>>(
	{
		NUMBERS_FETCH_ACCESSIBLE_PENDING: state => ({ ...state, fetching: true }),
		NUMBERS_FETCH_ACCESSIBLE_SUCCESS: (state, { payload }) => {
			const withoutQuickDials = payload[0].items.filter(n => n.type !== 'QUICKDIAL') as (
				| ApiMobileNumber
				| ApiLandlineNumber
				| ApiInternationalNumber
			)[];

			return {
				...state,
				fetched: true,
				fetching: false,
				items: sortPhoneNumbers(
					withoutQuickDials.map(number => {
						const acd = payload[1].items.find(acdItem =>
							acdItem.phoneNumbers.includes(number.number)
						);

						return {
							...number,
							endpointId: acd ? acd.id : number.endpointId,
							endpointAlias: acd ? acd.name : number.endpointAlias,
						};
					})
				),
			};
		},
	},
	initialState
);

export const reducer = (state: NumberState, action: UnknownAction) => {
	return rtkReducer(legacyReducer(state, action), action);
};
