import { FileEntry } from '@panda/ui';
import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';
import { getFileContent } from '../../../utils/uploads';
import { BatchUser, ExternalBatchUserProvider } from '../../../api/types/batch-user';

export const getBatchUsers = createAsyncThunk(
	'batchUserImport/getBatchUsers',
	async (jobId: string) => api.getBatchUsers(jobId),
	{
		condition: (_jobId, { getState }) => {
			const { batchUserImport } = getState();
			if (batchUserImport.users.fetching === 'pending') {
				return false;
			}
		},
	}
);

export const deleteBatchUser = createAsyncThunk(
	'batchUserImport/deleteBatchUser',
	async (args: { jobId: string; userId: string }) => {
		return api.deleteBatchSingleUser(args.jobId, args.userId);
	},
	{
		condition: (args, { getState }) => {
			if (
				getState().batchUserImport.users.deletingState.find(
					operationState => operationState.id === args.userId && operationState.state === 'pending'
				)
			) {
				return false;
			}
		},
	}
);

export const updateBatchUser = createAsyncThunk(
	'batchUserImport/updateBatchUser',
	async (args: { jobId: string; user: BatchUser }) => {
		return api.updateBatchUser(args.jobId, args.user);
	},
	{
		condition: (_args, { getState }) => {
			if (getState().batchUserImport.users.updating === 'pending') {
				return false;
			}
		},
	}
);

export const updateBatchUsers = createAsyncThunk(
	'batchUserImport/updateBatchUsers',
	async (args: { jobId: string; users: BatchUser[] }) => {
		return api.updateBatchUsers(args.jobId, args.users);
	},
	{
		condition: (_args, { getState }) => {
			if (getState().batchUserImport.users.updating === 'pending') {
				return false;
			}
		},
	}
);

export const uploadCSVFile = createAsyncThunk(
	'batchUserImport/uploadCSVFile',
	async (file: FileEntry) => {
		const fileContent = await getFileContent(file.content);
		const base64FileContent = fileContent.base64Content.replace('data:text/csv;base64,', '');
		return api.createCSVBatchUserJob(base64FileContent);
	},
	{
		condition: (_args, { getState }) => {
			const { batchUserImport } = getState();
			if (batchUserImport.uploading === 'pending') {
				return false;
			}
		},
	}
);

export const executeBatchUserJob = createAsyncThunk(
	'batchUserImport/executeBatchUserJob',
	async (jobId: string) => {
		return api.executeBatchImportJob(jobId);
	}
);

export const deleteCurrentBatchJob = createAsyncThunk(
	'batchUserImport/deleteCurrentBatchJob',
	async (jobId: string) => {
		return api.deleteCurrentBatchJob(jobId);
	}
);

export const getBatchJobs = createAsyncThunk('batchUserImport/getBatchJobs', async () => {
	return api.getCurrentBatchJob();
});

export const createBatchUserAuthRequest = createAsyncThunk(
	'batchUserImport/createBatchUserAuthRequest',
	async (provider: ExternalBatchUserProvider) => {
		return api.createBatchUserAuthRequest(provider);
	}
);

export const fetchBatchUsersFromProvider = createAsyncThunk(
	'batchUserImport/fetchBatchUsersFromProvider',
	async (args: { provider: ExternalBatchUserProvider; code: string }) => {
		return api.fetchBatchUsersFromProvider(args.provider, args.code);
	},
	{
		condition: (_args, { getState }) => {
			const { batchUserImport } = getState();
			if (batchUserImport.jobs.fetching === 'pending') {
				return false;
			}
		},
	}
);
