import { createSlice } from '@reduxjs/toolkit';
import { SipgateStatusState } from './types';
import { forceFetchSipgateStatus } from './actions';

export const initialState: SipgateStatusState = {
	data: { statusUrl: 'https://status.sipgate.de', indicator: 'none' },
	hasError: false,
	fetching: false,
	fetched: false,
};

export const sipgateStatusSlice = createSlice({
	name: 'sipgateStatus',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(forceFetchSipgateStatus.pending, state => ({
			...state,
			fetching: true,
			fetched: false,
			hasError: false,
		}));
		builder.addCase(forceFetchSipgateStatus.rejected, state => ({
			...state,
			fetching: false,
			fetched: true,
			hasError: true,
		}));
		builder.addCase(forceFetchSipgateStatus.fulfilled, (state, { payload }) => {
			// has no incidents
			if (payload.incidents.length === 0 && payload.status.indicator === 'none') {
				return {
					fetched: true,
					fetching: false,
					hasError: false,
					data: {
						...state.data,
						statusUrl: payload.page.url,
						indicator: payload.status.indicator,
						name: undefined,
						body: undefined,
						updated_at: undefined,
					},
				};
			}

			if (payload.incidents.length > 0 && payload.incidents[0].incident_updates[0]) {
				return {
					fetched: true,
					fetching: false,
					hasError: false,
					data: {
						statusUrl: payload.page.url,
						indicator: payload.status.indicator,
						name: payload.incidents[0].name,
						body: payload.incidents[0].incident_updates[0].body,
						updated_at: payload.incidents[0].incident_updates[0].updated_at,
					},
				};
			}

			if (payload.incidents.length > 0 && !payload.incidents[0].incident_updates[0]) {
				return {
					fetched: true,
					fetching: false,
					hasError: false,
					data: {
						statusUrl: payload.page.url,
						indicator: payload.status.indicator,
						name: payload.incidents[0].name,
						body: undefined,
						updated_at: undefined,
					},
				};
			}

			return {
				...state,
				fetched: true,
				fetching: false,
				hasError: false,
				data: {
					...state.data,
					statusUrl: payload.page.url,
					indicator: payload.status.indicator,
					name: payload.incidents[0].name,
					body: payload.incidents[0].incident_updates[0].body,
					updated_at: payload.incidents[0].incident_updates[0].updated_at,
				},
			};
		});
	},
});

export default sipgateStatusSlice.reducer;
