import { createSlice } from '@reduxjs/toolkit';
import { ConferenceRoomState } from './types';
import {
	createConferenceRoom,
	cancelConferenceRoom,
	revokeConferenceRoomCancellation,
	deleteGreeting,
	fetchConferenceRooms,
	renameConferenceRoom,
	setActiveAudioFileGreeting,
} from './actions';
import { ApiConferenceRoom, ApiConferenceRoomExtension } from '../../../api/types/conferenceRoom';

const initialState: ConferenceRoomState = {
	fetched: false,
	fetching: false,
	items: [],
	hasContractsWithoutExtensions: false,
};

export const reducer = createSlice({
	name: 'conferenceRoom',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchConferenceRooms.pending, state => ({ ...state, fetching: true }));

		builder.addCase(fetchConferenceRooms.fulfilled, (state, action) => {
			const withExtension: (ApiConferenceRoom & { extension: ApiConferenceRoomExtension })[] =
				action.payload.items.filter(
					(c): c is ApiConferenceRoom & { extension: ApiConferenceRoomExtension } => !!c.extension
				);

			return {
				...state,
				fetched: true,
				fetching: false,
				items: withExtension.map(c => ({
					...c.extension,
					contract: {
						...c.contract,
						cancellation: c.contract.cancellation
							? {
									...c.contract.cancellation,
									terminationDate: new Date(c.contract.cancellation.terminationDate),
								}
							: null,
						nextCancellationDate: new Date(c.contract.nextCancellationDate),
						cost: {
							amount: {
								amount: c.contract.price.amount,
								currency: c.contract.price.currency,
								fraction: 10_000,
							},
							interval: 'monthly',
							isNetto: c.contract.price.net,
						},
					},
				})),
				hasContractsWithoutExtensions: withExtension.length !== action.payload.items.length,
			};
		});

		builder.addCase(createConferenceRoom.fulfilled, (state, action) => ({
			...state,
			items: [
				...state.items,
				{
					...action.payload.extension,
					contract: {
						...action.payload.contract,
						cancellation: action.payload.contract.cancellation
							? {
									...action.payload.contract.cancellation,
									terminationDate: new Date(action.payload.contract.cancellation.terminationDate),
								}
							: null,
						nextCancellationDate: new Date(action.payload.contract.nextCancellationDate),
						cost: {
							amount: {
								amount: action.payload.contract.price.amount,
								currency: action.payload.contract.price.currency,
								fraction: 10_000,
							},
							interval: 'monthly',
							isNetto: action.payload.contract.price.net,
						},
					},
				},
			],
		}));

		builder.addCase(renameConferenceRoom.fulfilled, (state, action) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== action.meta.arg.id) {
					return item;
				}

				return {
					...item,
					alias: action.meta.arg.alias,
				};
			}),
		}));

		builder.addCase(cancelConferenceRoom.fulfilled, (state, action) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== action.meta.arg.conferenceRoomId) {
					return item;
				}

				return {
					...item,
					contract: {
						...item.contract,
						cancellation: {
							...action.payload,
							terminationDate: new Date(action.payload.terminationDate),
						},
					},
				};
			}),
		}));

		builder.addCase(revokeConferenceRoomCancellation.fulfilled, (state, action) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== action.meta.arg.conferenceRoomId) {
					return item;
				}

				return {
					...item,
					contract: {
						...item.contract,
						cancellation: null,
					},
				};
			}),
		}));

		builder.addCase(deleteGreeting.pending, (state, action) => ({
			...state,
			items: state.items.map(item => {
				if (item.id !== action.meta.arg.conferenceRoomId) {
					return item;
				}

				return { ...item, greeting: null };
			}),
		}));

		builder.addCase(setActiveAudioFileGreeting.pending, (state, action) => ({
			...state,

			items: state.items.map(conferenceRoom => {
				if (conferenceRoom.id !== action.meta.arg.conferenceRoomId) {
					return conferenceRoom;
				}

				return {
					...conferenceRoom,
					greeting: action.meta.arg.activeAudioFileId
						? {
								...conferenceRoom.greeting,
								id: action.meta.arg.conferenceRoomId,
								activeAudioFileId: action.meta.arg.activeAudioFileId,
							}
						: null,
				};
			}),
		}));
	},
}).reducer;
