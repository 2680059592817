import { ApiContact } from '../../../api/types/contacts';
import { guessImageType } from '../../../views/contacts/utils';
import {
	Contact,
	ContactAddressType,
	ContactAddressTypes,
	ContactEmailType,
	ContactEmailTypes,
	ContactNumber,
	ContactNumberType,
	ContactNumberTypes,
	ContactWebsiteType,
	ContactWebsiteTypes,
} from './types';

const normalizeContactNumberType = (types: string[]): ContactNumberType => {
	const firstType = types.filter(part => part !== 'voice' && part !== 'fax')[0];
	return (
		(ContactNumberTypes.find(type => type === firstType?.toLowerCase()) as ContactNumberType) ??
		'other'
	);
};

const normalizeContactEmailType = (types: string[]): ContactEmailType => {
	const firstType = types[0];
	return (
		(ContactEmailTypes.find(type => type === firstType?.toLowerCase()) as ContactEmailType) ??
		'other'
	);
};

const normalizeContactWebsiteType = (types: string[]): ContactWebsiteType => {
	const firstType = types[0];
	return (
		(ContactWebsiteTypes.find(type => type === firstType?.toLowerCase()) as ContactWebsiteType) ??
		'other'
	);
};

const normalizeContactAddressType = (types: string[]): ContactAddressType => {
	const firstType = types[0];
	return (
		(ContactAddressTypes.find(type => type === firstType?.toLowerCase()) as ContactAddressType) ??
		'other'
	);
};

export const normalizeContactNumber = (contactNumber: {
	number: string;
	type?: string[];
}): ContactNumber => {
	if (!contactNumber.type) {
		return {
			fax: false,
			number: contactNumber.number,
			type: 'other',
		};
	}

	return {
		fax: contactNumber.type.includes('fax'),
		type: normalizeContactNumberType(contactNumber.type),
		number: contactNumber.number,
	};
};

export const normalizeContact = (contact: ApiContact): Contact => {
	return {
		id: contact.id,
		name: contact.name,
		givenname: contact.givenname.trim(),
		familyname: contact.familyname.trim(),
		emails: contact.emails.map(email => ({
			address: email.email,
			type: normalizeContactEmailType(email.type),
		})),
		websites: contact.websites.map(website => ({
			url: website.url,
			type: normalizeContactWebsiteType(website.type),
		})),
		avatar:
			contact.picture !== null
				? {
						type: guessImageType(contact.picture),
						content: contact.picture,
					}
				: null,
		numbers: contact.numbers.map(number => normalizeContactNumber(number)),
		scope: contact.scope,
		note: contact.note,
		addresses: contact.addresses.map(address => ({
			poBox: address.poBox,
			extendedAddress: address.extendedAddress,
			streetAddress: address.streetAddress,
			locality: address.locality,
			region: address.region,
			postalCode: address.postalCode,
			country: address.country,
			type: normalizeContactAddressType(address.type || []),
		})),
		organizations: contact.organization
			? contact.organization.map(organization => ({
					company: organization[0] || '',
					unit: organization[1] || '',
				}))
			: [],
	};
};
