export const isInternalLink = (href: string): boolean => {
	const internalRelativLinkRegex = /^\/.*/;
	const internalLinkRegex =
		/^https?:\/\/app\.((dev|local)+\.)?(sipgate\.com|sipgatebasic(\.de|\.co\.uk)+|simquadrat\.de).*/;
	return internalLinkRegex.test(href) || internalRelativLinkRegex.test(href);
};

export const isExternalSipgateLink = (href: string): boolean => {
	const externalSipgateLinkRegex =
		/^https?:\/\/.*(sipgate|simquadrat|sipgateteam|sipgatebasic|sipgatetrunking)\.(de|com|co\.uk|uk)/;
	return externalSipgateLinkRegex.test(href);
};

export const isExternalLink = (href: string): boolean => {
	return !(isInternalLink(href) || isExternalSipgateLink(href));
};
