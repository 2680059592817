/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from 'react';

const styles = {
	base: {
		height: '100%',
		width: '100%',
		display: 'block',
	},
	stroke: {
		display: 'block',
		strokeWidth: '1',
		fill: 'transparent',
		strokeLinecap: 'round',
		strokeLinejoin: 'round',
		strokeMiterlimit: '10',
	},
};

interface ExternalProps {
	children: React.ReactNode;
	color: string;
	viewBox: string;
	className?: string;
	containerStyle?: React.CSSProperties;
	svgStyle?: React.CSSProperties;
}

interface UnclickableProps extends ExternalProps {
	role?: undefined;
	onClick?: undefined;
}

interface ClickableProps extends ExternalProps {
	role: string;
	onClick: () => void;
}

class Svg extends React.Component<UnclickableProps | ClickableProps> {
	public static defaultProps = {
		color: 'transparent',
		viewBox: '0 0 30 30',
	};

	public render() {
		const svgStyle = {
			fill: this.props.color,
			...styles.base,
			...this.props.svgStyle,
		};

		if (!this.props.onClick) {
			<div
				style={{ height: '100%', ...this.props.containerStyle }}
				className={this.props.className}
			>
				<svg x="0px" y="0px" viewBox={this.props.viewBox} style={svgStyle}>
					{this.props.children}
				</svg>
			</div>;
		}

		return (
			<div
				style={{ height: '100%', ...this.props.containerStyle }}
				onClick={this.props.onClick}
				role={this.props.role}
				className={this.props.className}
			>
				<svg x="0px" y="0px" viewBox={this.props.viewBox} style={svgStyle}>
					{this.props.children}
				</svg>
			</div>
		);
	}
}

export default Svg;
