import serviceUrls from '@web-apps/service-urls';
import Pusher from 'pusher-js';
import { useEffect } from 'react';
import { isProductionEnvironment } from '../utils/environment';
import auth from '../utils/authenticate/auth';

const PRODUCTION_KEY = '46afd61226fec2a19fe1';
const DEVELOPMENT_KEY = 'fbc89d084a3209df11c4';

const getPusherOptions = (token: string) => ({
	cluster: 'eu',
	encrypted: true,
	authEndpoint: `${serviceUrls.restApi}/pusher/auth`,
	auth: {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	},
});

/*
 * The reason for this class is, that we want to manage
 * subscriptions and listeners in a single location to avoid one instance of a component
 * interrupting another one
 */
class PusherWrapper {
	private pusher: Pusher.Pusher;

	public constructor(token: string) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.pusher = new Pusher(
			isProductionEnvironment() ? PRODUCTION_KEY : DEVELOPMENT_KEY,
			getPusherOptions(token)
		);
	}

	public static async build() {
		const token = await auth.getToken();
		return new PusherWrapper(token.access);
	}

	public listen(channel: string, event: string, handler: (data: string) => void) {
		const internalHandler = (data: string) => handler(data);
		const channelName = `private-${channel}`;

		let channelInstance = this.pusher.channel(channelName);
		if (!channelInstance) {
			channelInstance = this.pusher.subscribe(channelName);
		}

		channelInstance.bind(event, internalHandler);

		return () => this.pusher.channel(channelName).unbind(event, internalHandler);
	}
}

export const pusher = PusherWrapper.build();

export const usePusher = (
	masterSipId: string,
	webuserId: string,
	event: string,
	callback: (json: string) => void
) => {
	useEffect(() => {
		let channel: () => void;
		const getPusherChannel = async () => {
			const client = await pusher;
			channel = client.listen(`${masterSipId}${webuserId}`, event, callback);
		};

		getPusherChannel();

		return () => {
			if (channel) {
				channel();
			}
		};
	}, [masterSipId, webuserId, event, callback]);
};
