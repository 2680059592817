export const getFileContent = async (
	file: File
): Promise<{ filename: string; textContent: string; base64Content: string }> => {
	const [textContent, base64Content] = await Promise.all([
		new Promise<string>((resolve, reject) => {
			const reader = new FileReader();

			reader.addEventListener('error', () => reject(reader.error), false);
			reader.addEventListener('load', () => resolve(reader.result as string), false);

			reader.readAsText(file);
		}),

		new Promise<string>((resolve, reject) => {
			const reader = new FileReader();

			reader.addEventListener('error', () => reject(reader.error), false);
			reader.addEventListener('load', () => resolve(reader.result as string), false);

			reader.readAsDataURL(file);
		}),
	]);

	return {
		filename: file.name,
		textContent,
		base64Content,
	};
};

export const convertDataURIToBinaryArray = (dataURI: string) => {
	const BASE64_MARKER = ';base64,';
	const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
	const base64 = dataURI.substring(base64Index);
	const raw = window.atob(base64);
	const rawLength = raw.length;
	const array = new Uint8Array(new ArrayBuffer(rawLength));

	for (let i = 0; i < rawLength; i += 1) {
		array[i] = raw.charCodeAt(i);
	}

	return array;
};
