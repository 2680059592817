import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

export const fetchPersonalAccessTokens = createAction(
	'PERSONAL_ACCESS_TOKEN_FETCH',
	(webuserId: string) => ({
		data: { webuserId },
		promise: () => api.getPersonalAccessTokens(webuserId),
		shouldFetch: (state: ReduxState) =>
			!state.personalAccessTokens.fetchedForUser.includes(webuserId) &&
			!state.personalAccessTokens.fetchingForUser.includes(webuserId),
	})
);

export const deletePersonalAccessToken = createAction(
	'PERSONAL_ACCESS_TOKEN_DELETE',
	(tokenId: string) => ({
		promise: api.deletePersonalAccessToken(tokenId),
		data: {
			tokenId,
		},
	})
);

export const createPersonalAccessToken = createAction(
	'PERSONAL_ACCESS_TOKEN_CREATE',
	(webuserId: string, tokenName: string, scopes: string[]) => ({
		data: { webuserId, tokenName, scopes },
		promise: () => api.createPersonalAccessToken(webuserId, tokenName, scopes),
	})
);
