import { PhonelineState } from './types';
import { Phoneline } from '../../../api/types/phonelines';

export const selectPhoneline = (phonelines: Phoneline[], phonelineId: string) =>
	phonelines.find(line => line.id === phonelineId);

export const selectPhonelinesForWebuser = (phonelines: Phoneline[], webuserId: string) =>
	phonelines.filter(phoneline => phoneline.userId === webuserId);

export const selectFirstPhonelineId = (phonelines: Phoneline[], webuserId: string) => {
	const userPhonelines = selectPhonelinesForWebuser(phonelines, webuserId);

	return userPhonelines.length > 0 ? userPhonelines[0].id : null;
};

export const arePhonelinesFetchedForUser = (phonelineState: PhonelineState, webuserId: string) =>
	phonelineState.fetchedForUser.includes(webuserId);
