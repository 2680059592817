import React, { ComponentProps, PropsWithoutRef, Suspense } from 'react';
import { LogoSpinner } from '../components/spinner/LogoSpinner';
import { hasKey } from '../utils/types';

let navigating = false;

window.addEventListener('beforeunload', () => {
	navigating = true;
});

interface HandlerProps {
	children: React.ReactNode;
}

interface HandlerState {
	hasError: boolean;
}

class LazyErrorHandler extends React.Component<HandlerProps, HandlerState> {
	public state: HandlerState = { hasError: false };

	public static getDerivedStateFromError() {
		return { hasError: true };
	}

	public componentDidCatch(error: unknown) {
		if (hasKey(error, 'name') && error.name === 'ChunkLoadError') {
			/*
			 * Fix to avoid white page in long-running app-web after deploy
			 */
			if (!navigating) {
				setTimeout(() => {
					window.location.reload();
				}, 5000);
			}
		} else {
			throw error;
		}
	}

	public render() {
		if (this.state.hasError) {
			return null;
		}

		return this.props.children;
	}
}

export function lazyLoad<C extends React.ComponentType<any>>(
	loader: () => Promise<{ default: C }>
) {
	const Comp = React.lazy(loader);

	return ((props: ComponentProps<C>) => (
		<LazyErrorHandler>
			<Suspense fallback={<LogoSpinner />}>
				{/* Allowed for HOC */}
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Comp {...props} />
			</Suspense>
		</LazyErrorHandler>
	)) as C;
}

export function lazyLoadWithoutSpinner<Props>(
	loader: () => Promise<{ default: React.ComponentType<Props> }>
) {
	const Comp = React.lazy(loader);

	return (props: PropsWithoutRef<Props> & JSX.IntrinsicAttributes) => (
		<LazyErrorHandler>
			<Suspense fallback={null}>
				{/* Allowed for HOC */}
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Comp {...props} />
			</Suspense>
		</LazyErrorHandler>
	);
}
