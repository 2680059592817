import { State } from '../../state';
import { FetchHistoryPendingAction, FetchHistorySuccessAction } from './types';
import { normalizeEvents } from '../../../normalize/events';

export const fetchHistoryPending = (state: State, action: FetchHistoryPendingAction): State => ({
	...state,
	entries: {
		...state.entries,
		abortController: action.abortController,
		result: action.isUserChange ? null : state.entries.result,
		error: action.isUserChange ? false : state.entries.error,
	},
});

export const fetchHistoryError = (state: State): State => ({
	...state,
	entries: {
		abortController: null,
		error: true,
		tooManyEvents: state.entries.tooManyEvents,
		result: null,
	},
});

export const fetchHistoryTooManyEvents = (state: State): State => ({
	...state,
	entries: {
		abortController: null,
		error: false,
		tooManyEvents: true,
		result: null,
	},
});

export const fetchHistorySuccess = (state: State, action: FetchHistorySuccessAction): State => {
	const previouslySelected = new Set();

	if (state.entries.result) {
		for (const entry of state.entries.result.normalized) {
			if (entry.selected) {
				previouslySelected.add(entry.originalEvent.id);
			}
		}
	}

	action.events.forEach(event => {
		event.labels.sort((a, b) => a.name.localeCompare(b.name));
	});

	return {
		...state,
		entries: {
			abortController: null,
			error: false,
			tooManyEvents: state.entries.tooManyEvents,
			result: {
				normalized: normalizeEvents(
					action.events.map(event => ({
						originalEvent: event,
						selected: previouslySelected.has(event.id),
					})),
					state.contacts,
					state.extensions,
					state.blocklist,
					state.numbers,
					state.domain
				),

				totalCount: action.totalCount,
			},
		},
	};
};
