import { handleActions } from '../..';
import { PhonelineState } from './types';
import { Phoneline } from '../../../api/types/phonelines';
import * as actions from './actions';
import { setUserName } from '../users';

const initialState: PhonelineState = {
	items: [],
	fetchingForUser: [],
	fetchedForUser: [],
};

export default handleActions<PhonelineState, PossibleActions<typeof actions | typeof setUserName>>(
	{
		PHONELINES_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForUser: [...state.fetchingForUser.filter(item => item !== data.userId), data.userId],
		}),
		PHONELINES_FETCH_SUCCESS: (state, { payload, data }) => {
			const newItems = [
				...state.items.filter(phoneline => phoneline.userId !== data.userId),
				...payload.items.map((phoneline: Phoneline) => {
					return {
						...phoneline,
						userId: data.userId,
					};
				}),
			];

			return {
				...state,
				fetchingForUser: state.fetchingForUser.filter(item => item !== data.userId),
				fetchedForUser: [...state.fetchedForUser.filter(item => item !== data.userId), data.userId],
				items: newItems,
			};
		},
		PHONELINE_SET_ALIAS_PENDING: (state, { data }) => {
			const items = state.items.map(phoneline => {
				if (phoneline.id === data.phonelineId) {
					return { ...phoneline, alias: data.alias };
				}

				return phoneline;
			});
			return {
				...state,
				items,
			};
		},
		PHONELINE_CREATE_SUCCESS: (state, { payload, data }) => ({
			...state,
			items: [...state.items, { ...payload, userId: data.userId }],
		}),
		PHONELINE_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(item => item.id !== data.phonelineId),
		}),
	},
	initialState
);
