import { DateTime } from 'luxon';
import { HolidayPreset, HolidayPresetState } from './types';

export const getHolidayPresetById = (
	state: HolidayPresetState,
	id: string
): HolidayPreset | undefined => state.items.find(preset => preset.id === id);

export const getNextHolidayDateFromPreset = (
	preset: HolidayPreset | null,
	selectedHolidays: string[] | null
): DateTime | undefined => {
	if (
		!preset ||
		preset.upcomingHolidays.length === 0 ||
		!selectedHolidays ||
		selectedHolidays.length === 0
	) {
		return undefined;
	}

	const upcomingHolidays = preset.upcomingHolidays.filter(holiday =>
		selectedHolidays.includes(holiday.id)
	);

	return upcomingHolidays.length > 0
		? DateTime.min(...upcomingHolidays.flatMap(holiday => [holiday.date, holiday.observedDate]))
		: undefined;
};
