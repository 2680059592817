import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, ReduxProps } from '../redux';

import { ElectronLoginRedirect } from './ElectronLoginRedirect';
import { ReduxState } from '../redux/types';

import { fetchTranslations } from '../redux/slices/translations';
import PageNotFoundRedirect from '../redirects/PageNotFoundRedirect';
import { TranslateProps, withTranslate } from '../helpers/withTranslations';

interface State {
	code: string;
	showError: boolean;
	showCodeInput: boolean;
}

const mapStateToProps = (_: ReduxState) => ({});

const mapDispatchToProps = {
	fetchTranslations,
};

type Props = RouteComponentProps &
	TranslateProps &
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class SoftphoneLoginView extends React.Component<Props, State> {
	public componentDidMount() {
		this.props.fetchTranslations({
			locale: navigator.language.startsWith('en') ? 'en_GB' : 'de_DE',
		});

		/*
		 * We load translations again after navigating, to avoid the case where
		 * our softphone:// redirect kills the translations request
		 */
		window.addEventListener('beforeunload', this.loadTranslations);
	}

	public componentWillUnmount() {
		window.removeEventListener('beforeunload', this.loadTranslations);
	}

	private loadTranslations = () => {
		setTimeout(
			() =>
				this.props.fetchTranslations({
					locale: navigator.language.startsWith('en') ? 'en_GB' : 'de_DE',
				}),
			150
		);
	};

	public render() {
		const code = new URLSearchParams(this.props.location.search).get('code');

		if (code) {
			return <ElectronLoginRedirect translate={this.props.translate} code={code} />;
		}

		return <PageNotFoundRedirect />;
	}
}

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(SoftphoneLoginView));
