import { localizeNumber } from '@web-apps/phonenumbers-utils';
import { ExtensionState, resolveEndpoint } from '../../../utils/endpoints';
import { ApiEndpointType, RoutedEndpointType, RoutedEndpoint } from '../../../api/types/events';
import { SipgateDomain } from '../../../redux/modules/userinfo';

export interface NormalizedEndpoint {
	id: string;
	type: ApiEndpointType;
	routedType: RoutedEndpointType;
	name?: string;
}

export const normalizeEndpoint = (
	routedEndpoint: RoutedEndpoint,
	extensions: ExtensionState,
	domain: SipgateDomain
): NormalizedEndpoint => {
	const endpoint = resolveEndpoint(extensions, routedEndpoint.endpoint);

	if (!endpoint) {
		return {
			id: routedEndpoint.endpoint.extension,
			type: routedEndpoint.endpoint.type,
			routedType: routedEndpoint.type,
		};
	}

	if (typeof endpoint === 'string') {
		return {
			id: routedEndpoint.endpoint.extension,
			type: routedEndpoint.endpoint.type,
			routedType: routedEndpoint.type,
			name: localizeNumber(endpoint, domain),
		};
	}

	if ('alias' in endpoint) {
		return {
			id: routedEndpoint.endpoint.extension,
			type: routedEndpoint.endpoint.type,
			routedType: routedEndpoint.type,
			name: endpoint.alias,
		};
	}

	if ('name' in endpoint) {
		return {
			id: routedEndpoint.endpoint.extension,
			type: routedEndpoint.endpoint.type,
			routedType: routedEndpoint.type,
			name: endpoint.name,
		};
	}

	return {
		id: routedEndpoint.endpoint.extension,
		type: routedEndpoint.endpoint.type,
		routedType: routedEndpoint.type,
		name: `${endpoint.firstname} ${endpoint.lastname}`,
	};
};
