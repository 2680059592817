import { debounce } from 'lodash';
import React from 'react';

import api from '../api';
import { connect, ReduxProps } from '../redux';
import { fetchAccount } from '../redux/slices/account';
import { fetchAcds } from '../redux/slices/acds';
import { fetchAudioFiles } from '../redux/modules/audioFiles';
import { fetchUserAvatar } from '../redux/slices/avatar';
import { forceFetchDevices } from '../redux/modules/devices';
import { fetchDomainVerifications } from '../redux/slices/domainVerification';
import { fetchGroups } from '../redux/modules/groups';
import { fetchGroupStatistics } from '../redux/slices/groupStatistics';
import { forceFetchMobileInfo } from '../redux/modules/mobile';
import { forceFetchUsers } from '../redux/modules/users';
import { forceFetchVoicemailsForAccount } from '../redux/modules/voicemails';
import { fetchAcdLiveStatistics } from '../redux/slices/acdStatistics';
import { ReduxState } from '../redux/types';
import { WithDialogProps, withDialogs } from '../routes/paths/dialogs';
import { pusher } from '../third-party/pusher';
import { mixpanel } from '../third-party/mixpanel';
import { forceFetchUserCallRestrictions } from '../redux/modules/callRestrictions/webuser';
import { fetchTimeBasedForwardings } from '../redux/modules/timeBasedForwardings';
import { fetchDateBasedForwardings } from '../redux/slices/dateBasedForwardings';

const mapStateToProps = (state: ReduxState) => ({
	acds: state.acds,
	mobile: state.mobile,
	devices: state.devices,
	groupStatistics: state.groupStatistics,
	groups: state.groups,
	voicemails: state.voicemails,
});

const mapDispatchToProps = {
	fetchAcds,
	fetchAcdLiveStatistics,
	forceFetchDevices,
	forceFetchUserCallRestrictions,
	forceFetchMobileInfo,
	fetchAudioFiles,
	fetchGroups,
	fetchGroupStatistics,
	fetchDomainVerifications,
	forceFetchVoicemailsForAccount,
	fetchUserAvatar,
	fetchAccount,
	forceFetchUsers,
	fetchTimeBasedForwardings,
	fetchDateBasedForwardings,
};

interface ExternalProps {
	masterSipid: string;
	userId: string;
}

type Props = ExternalProps &
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
	WithDialogProps;

class Pusher extends React.Component<Props> {
	private unbinds = [() => {}];

	private eventToAction = [
		{
			event: 'reloadDevices',
			action: (userId: string) => {
				if (
					this.props.devices.fetchingForUser.includes(userId) ||
					this.props.devices.fetchedForUser.includes(userId)
				) {
					this.props.forceFetchDevices(userId);
				}
				if (this.props.mobile.fetched || this.props.mobile.fetching) {
					this.props.forceFetchMobileInfo();
					this.props.forceFetchDevices(userId);
				}
			},
		},
		{
			event: 'batchUserCreationJobFinished',
			action: (_userId: string) => {
				api.getCurrentBatchJob().then(jobs => {
					const jobWithChangedStatus = jobs.items.find(job => job.status === 'CREATED_USERS');
					if (!jobWithChangedStatus) {
						return;
					}
					api.getBatchUsers(jobWithChangedStatus.id).then(users => {
						const areAllUsersCreated = users.items.every(user => user.status === 'CREATED');
						const successfulUsers = users.items.filter(user => user.status === 'CREATED').length;
						const failedUsers = users.items.filter(user => user.status === 'FAILED').length;

						this.props.forceFetchUsers();
						this.props.forceFetchUserCallRestrictions();

						mixpanel.track('BATCH USER IMPORT FINISHED', {
							successfulUsers,
							failedUsers,
						});

						if (areAllUsersCreated) {
							this.props.dialogs.batchImportUserExecutionSucceeded.open();
						} else {
							this.props.dialogs.batchImportUserExecutionFailed.open();
						}
					});
				});
			},
		},
		{
			event: 'reloadDomains',
			action: () => {
				this.props.fetchDomainVerifications(true);
			},
		},
		{
			event: 'reloadGreetings',
			action: (_userId: string, data: string) => {
				const json: { ownerId: string } = JSON.parse(data);

				this.props.fetchAudioFiles(json.ownerId, true);

				if (json.ownerId.includes('r') && this.props.groups.fetched) {
					this.props.fetchGroups(true);
				} else if (json.ownerId.includes('v') && this.props.voicemails.fetchedForAccount) {
					this.props.forceFetchVoicemailsForAccount();
				}
			},
			shouldNotDebounce: true,
		},
		{
			event: 'reloadGroupStatistics',
			action: (_userId: string, data: string) => {
				const json: { groupId: string } = JSON.parse(data);
				if (this.props.groupStatistics.fetchedForGroup.includes(json.groupId)) {
					this.props.fetchGroupStatistics({ groupId: json.groupId, force: true });
					setTimeout(
						() => this.props.fetchGroupStatistics({ groupId: json.groupId, force: true }),
						5000
					);
				}
			},
			shouldNotDebounce: true,
		},
		{
			event: 'reloadAcdAgentStates',
			action: (_userId: string, _data: string) => {
				this.props.fetchAcds({ force: true });
			},
		},
		{
			event: 'reloadAcd',
			action: (_userId: string, _data: string) => {
				this.props.fetchAcds({ force: true });
			},
		},
		{
			event: 'reloadAcdStatistics',
			action: (_userId: string, data: string) => {
				const json: { acdId: string } = JSON.parse(data);
				this.props.fetchAcdLiveStatistics({ acdId: json.acdId, onlyWhenAlreadyFetched: true });
			},
			shouldNotDebounce: true,
		},
		{
			event: 'reloadAcdForwarding',
			action: (_userId: string, data: string) => {
				const json: { acdId: string } = JSON.parse(data);
				this.props.fetchTimeBasedForwardings(json.acdId, true);
				this.props.fetchDateBasedForwardings({ extensionId: json.acdId, force: true });
			},
		},
		{
			event: 'reloadUsers',
			action: (_userId: string, _data: string) => {
				this.props.forceFetchUsers();
			},
		},
		{
			event: 'reloadAvatars',
			action: (_userId: string, json: string) => {
				const data: { webuserId: string } = JSON.parse(json);
				this.props.fetchUserAvatar({ webuserId: `w${data.webuserId.split('w')[1]}`, force: true });
			},
			shouldNotDebounce: true,
		},
		{
			event: 'reloadCustomLogo',
			action: () => {
				this.props.fetchAccount(true);
			},
		},
	];

	public componentDidMount() {
		this.registerEventPushHandler(this.props.masterSipid, this.props.userId);
	}

	public componentWillUnmount() {
		this.unbinds.forEach(unbind => unbind());
	}

	private registerEventPushHandler = (masterSipid: string, userId: string) => {
		const channel = `${masterSipid}${userId}`;

		this.eventToAction
			.map(mapping => {
				const actionToCall = (data: string) => mapping.action(userId, data);
				const debounced = debounce(actionToCall, 150);
				return pusher.then(client => {
					return client.listen(
						channel,
						mapping.event,
						mapping.shouldNotDebounce ? actionToCall : debounced
					);
				});
			})
			.forEach(promise =>
				promise.then(unbind => {
					this.unbinds.push(unbind);
				})
			);
	};

	public render() {
		return null;
	}
}

export default withDialogs(connect(mapStateToProps, mapDispatchToProps)(Pusher));
