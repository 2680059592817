import React from 'react';

import classnames from 'classnames';
import {
	findMatchLocation,
	findNumberMatchLocation,
	formatNumberForAPI,
	isPotentialPhonenumber,
	isValidPhonenumber,
	localizeNumber,
} from '@web-apps/phonenumbers-utils';
import SearchInput from '../../../components/inputs/SearchInput';
import { Translate } from '../../../redux/slices/translations';
import { ChangeUrlParameter } from '../selections/types';
import { FilterDropdown } from './FilterDropdown';
import classes from './PhonenumberFilter.scss';

import { State as ReduxState } from '../state/state';
import { searchInContacts } from '../../../redux/modules/contacts';
import { Filter } from './Filter';
import { PhonenumberSuggestion, Suggestion } from './PhonenumberSuggestion';
import { SipgateDomain } from '../../../redux/modules/userinfo';

interface Props {
	open?: boolean;
	contacts: ReduxState['contacts'];
	translate: Translate;
	domain: SipgateDomain;
	onChange: ChangeUrlParameter;
	phonenumber: string;
	onOpenClose?: (open: boolean) => void;
}

interface State {
	value: string;
	suggestions: Suggestion[];
	selectedEntry: null | number | 'EXACT' | 'RESET';
}

const getFormattedNumber = (phonenumber: string, domain: SipgateDomain): string => {
	if (isValidPhonenumber(phonenumber, domain)) {
		return localizeNumber(phonenumber, domain);
	}

	return phonenumber;
};

export class PhonenumberFilter extends React.PureComponent<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			value: getFormattedNumber(props.phonenumber, this.props.domain),
			suggestions: [],
			selectedEntry: null,
		};
	}

	public componentDidMount() {
		this.findSuggestions();
	}

	public componentDidUpdate(prevProps: Props) {
		if (this.props.phonenumber !== prevProps.phonenumber || this.props.open !== prevProps.open) {
			/* eslint-disable-next-line react/no-did-update-set-state */
			this.setState(
				{
					value: getFormattedNumber(this.props.phonenumber, this.props.domain),
					suggestions: [],
					selectedEntry: null,
				},
				() => this.findSuggestions()
			);
		} else if (this.props.contacts !== prevProps.contacts) {
			this.findSuggestions();
		}
	}

	private async findSuggestions() {
		const { contacts, phonenumber } = this.props;

		const value = this.state.value;
		const searchResults = await searchInContacts(contacts, value, { maxResultCount: 5 });

		const suggestions = searchResults.map((suggestion, index) => {
			return {
				...suggestion,
				id: index,
				numberMatchLocation: findNumberMatchLocation(
					value,
					localizeNumber(suggestion.number, this.props.domain)
				),
				contactMatchLocation: findMatchLocation(value, suggestion.contact.name),
			};
		});

		if (value === phonenumber || !isPotentialPhonenumber(value)) {
			this.setState(state => ({
				...state,
				suggestions,
				selectedEntry: suggestions.length === 0 ? null : 0,
			}));
		} else {
			this.setState(state => ({
				...state,
				suggestions,
				selectedEntry: suggestions.length === 0 ? 'EXACT' : 0,
			}));
		}
	}

	private onChange = (value: string) => {
		if (value.length === 0) {
			this.setState({
				value,
				suggestions: [],
				selectedEntry: null,
			});
		} else {
			this.setState(
				state => ({ ...state, value }),
				() => this.findSuggestions()
			);
		}
	};

	private onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'ArrowUp') {
			e.preventDefault();
			this.selectPreviousEntry();
		}

		if (e.key === 'ArrowDown') {
			e.preventDefault();
			this.selectNextEntry();
		}

		if (e.key === 'Escape') {
			e.preventDefault();
			this.closeDropdown();
		}
	};

	private onEnter = (e: React.FormEvent) => {
		e.preventDefault();

		if (this.state.selectedEntry === null) {
			if (this.state.value.trim().length === 0) {
				this.resetFilter();
			}

			return;
		}

		this.submitEntry(this.state.selectedEntry);
	};

	private onSelectExact = () => this.selectEntry('EXACT');

	private onSubmitExact = () => this.submitEntry('EXACT');

	private onSelectReset = () => this.selectEntry('RESET');

	private onSubmitReset = () => this.submitEntry('RESET');

	private submitEntry = (entry: number | 'RESET' | 'EXACT') => {
		if (entry === 'RESET') {
			this.resetFilter();
			return;
		}

		const phonenumber =
			entry === 'EXACT'
				? formatNumberForAPI(this.state.value, this.props.domain)
				: this.state.suggestions[entry].number;

		this.setState(
			state => ({
				...state,
				value: getFormattedNumber(phonenumber, this.props.domain),
			}),
			() => {
				this.findSuggestions();
				this.props.onChange({ phonenumber, offset: 0 });
				this.closeDropdown();
			}
		);
	};

	private selectEntry = (index: number | 'EXACT' | 'RESET') => {
		if (index === 'EXACT' || index === 'RESET') {
			this.setState(state => ({ ...state, selectedEntry: index }));
		} else {
			this.setState(state => {
				if (index < 0 || index >= state.suggestions.length) {
					return state;
				}

				return {
					...state,
					selectedEntry: index,
				};
			});
		}
	};

	private selectNextEntry() {
		this.setState(state => {
			if (state.selectedEntry === 'EXACT' || state.selectedEntry === 'RESET') {
				return state;
			}

			if (state.selectedEntry === null || state.selectedEntry + 1 >= state.suggestions.length) {
				if (this.shouldShowExactSearch(state.value)) {
					return { ...state, selectedEntry: 'EXACT' };
				}

				if (this.shouldShowResetButton(state.value)) {
					return { ...state, selectedEntry: 'RESET' };
				}

				return state;
			}

			return { ...state, selectedEntry: state.selectedEntry + 1 };
		});
	}

	private selectPreviousEntry() {
		this.setState(state => {
			if (state.selectedEntry === null || state.selectedEntry === 0) {
				return state;
			}

			if (state.selectedEntry === 'EXACT' || state.selectedEntry === 'RESET') {
				if (state.suggestions.length === 0) {
					return state;
				}

				return {
					...state,
					selectedEntry: state.suggestions.length - 1,
				};
			}

			return {
				...state,
				selectedEntry: state.selectedEntry - 1,
			};
		});
	}

	private closeDropdown() {
		if (this.props.onOpenClose) {
			this.props.onOpenClose(false);
		}
	}

	private resetFilter = () => {
		this.props.onChange({ phonenumber: '', offset: 0 });
		this.closeDropdown();
	};

	private isFilterActive() {
		return this.props.phonenumber !== '';
	}

	private shouldShowResetButton(value: string) {
		return (
			this.isFilterActive() &&
			value === getFormattedNumber(this.props.phonenumber, this.props.domain)
		);
	}

	private shouldShowExactSearch(value: string) {
		return !this.shouldShowResetButton(value) && isPotentialPhonenumber(value);
	}

	private renderButtonLabel() {
		if (this.isFilterActive()) {
			return getFormattedNumber(this.props.phonenumber, this.props.domain);
		}

		return this.props.translate('FILTER_NUMBER_BUTTON');
	}

	private renderResetButton() {
		return (
			<li
				key="reset"
				className={classnames(
					{ [classes.selected]: this.state.selectedEntry === 'RESET' },
					classes.reset,
					classes.suggestion
				)}
			>
				<button
					className={classes.resetButton}
					onClick={this.onSubmitReset}
					onMouseOver={this.onSelectReset}
					onFocus={this.onSelectReset}
					type="button"
				>
					{this.props.translate('FILTER_NUMBER_RESET')}
				</button>
			</li>
		);
	}

	private renderExactSearch() {
		const nonBreakingSpace = '\u00a0';

		return (
			<li
				key="exact"
				className={classnames(
					{ [classes.selected]: this.state.selectedEntry === 'EXACT' },
					classes.exact,
					classes.suggestion
				)}
				data-index="EXACT"
			>
				<button
					type="button"
					className={classes.resetButton}
					onClick={this.onSubmitExact}
					onMouseOver={this.onSelectExact}
					onFocus={this.onSelectExact}
				>
					<span className={classes.highlighted}>{this.state.value}</span>
					<span>
						{nonBreakingSpace}– {this.props.translate('FILTER_NUMBER_EXACT')}
					</span>
				</button>
			</li>
		);
	}

	private renderSuggestions() {
		return (
			<>
				{this.state.suggestions.length > 0 ? (
					<span className={classes.suggestionhead}>
						{this.props.translate('FILTER_NUMBER_SUBHEADING')}
					</span>
				) : null}

				<ul>
					{this.state.suggestions.map(suggestion => (
						<PhonenumberSuggestion
							key={`${suggestion.contact.id}-${suggestion.number}`}
							className={classes.suggestion}
							suggestion={suggestion}
							selected={this.state.selectedEntry === suggestion.id}
							onSelect={this.selectEntry}
							onSubmit={this.submitEntry}
							domain={this.props.domain}
						/>
					))}

					{this.shouldShowExactSearch(this.state.value) ? this.renderExactSearch() : null}
					{this.shouldShowResetButton(this.state.value) ? this.renderResetButton() : null}
				</ul>
			</>
		);
	}

	private renderContent(autoFocus: boolean) {
		return (
			<>
				<form onSubmit={this.onEnter}>
					<SearchInput
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={autoFocus}
						onKeyDown={this.onKeyDown}
						value={this.state.value}
						onChange={this.onChange}
						placeholder={this.props.translate('FILTER_NUMBER_PLACEHOLDER')}
						ariaLabel={this.props.translate('FILTER_NUMBER_SEARCH_ARIA_LABEL')}
						translate={this.props.translate}
					/>
				</form>

				{this.state.value.length > 0 ? this.renderSuggestions() : null}
			</>
		);
	}

	public render() {
		if (!this.props.onOpenClose) {
			return (
				<Filter title={this.props.translate('FILTER_NUMBER_BUTTON')}>
					<div className={classnames(classes.phonenumberFilter, classes.phonenumberSlideIn)}>
						{this.renderContent(false)}
					</div>
				</Filter>
			);
		}

		return (
			<FilterDropdown
				className={classnames(classes.phonenumberFilter, classes.phonenumberDropdown)}
				open={!!this.props.open}
				active={this.isFilterActive()}
				onOpenClose={this.props.onOpenClose}
				buttonLabel={this.renderButtonLabel()}
				testSelector="phonenumber-filter"
			>
				{this.renderContent(true)}
			</FilterDropdown>
		);
	}
}
