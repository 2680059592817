import { SipgatePaymentApi } from '../SipgatePaymentApi';
import { HttpClient } from './HttpClient';
import { Restriction } from '../../redux/modules/restrictions';
import { ApiAutoCreditingSuggestion } from '../types/payment';

export class PaymentApiClient implements SipgatePaymentApi {
	private http: HttpClient;

	public constructor(http: HttpClient) {
		this.http = http;
	}

	public getRestrictions(restrictions: Restriction[] = []) {
		const params = new URLSearchParams();

		for (const restriction of restrictions) {
			params.append('restriction', restriction);
		}

		return this.http.get(`/restrictions?${params.toString()}`);
	}

	public getBalance = () => this.http.get('/balance');

	public topupBalance(paymentMethodId: string, amountInCent: number) {
		return this.http.post('/balance', {
			paymentMethodId,
			amountInCent,
		});
	}

	public getOpenPositions() {
		return this.http.get('/balance/open-positions');
	}

	public getPaymentMethods() {
		return this.http.get('/payment-methods');
	}

	public deletePaymentMethod(paymentId: string) {
		return this.http.delete(`/payment-methods/${paymentId}`);
	}

	public getAutoCreditingSettings() {
		return this.http.get('/auto-crediting');
	}

	public setAutoCreditingSettings(
		paymentMethod: string,
		options: { items: string[]; chargeInCent: number; thresholdInFullCurrency: number }
	) {
		return this.http.put(`/auto-crediting`, {
			paymentMethod,
			chargeInCent: options.chargeInCent,
			thresholdInFullCurrency: options.thresholdInFullCurrency,
			items: options.items,
		});
	}

	public async getAutoCreditingSuggestion(items: string[]) {
		const params = new URLSearchParams();

		for (const item of items) {
			params.append('item', item);
		}

		const res: ApiAutoCreditingSuggestion = await this.http.get(
			`/auto-crediting/suggestion?${params}`
		);

		return {
			charge: {
				amount: res.chargeInCent,
				fraction: 100,
				currency: res.currency,
			},
			threshold: {
				amount: res.thresholdInFullCurrency,
				fraction: 1,
				currency: res.currency,
			},
		};
	}

	public createSepaPaymentMethod(data: { iban: string; holder: string; accepted: boolean }) {
		return this.http.post('/payment-methods/debit-card', data);
	}

	public createCreditCardPaymentMethod(data: {
		cardNumber: string;
		holder: string;
		securityCode: string;
		expiryYear: string;
		expiryMonth: string;
		successUrl: string;
		failureUrl: string;
		accepted: boolean;
	}) {
		return this.http.post('/payment-methods/credit-card', data);
	}

	public completeCreditCardVerification(verificationId: string) {
		return this.http.post(
			`/payment-methods/credit-card/verification/${encodeURIComponent(verificationId)}`
		);
	}
}
