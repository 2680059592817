import { handleActions } from '../..';
import { Product, ProductsState } from './types';
import * as actions from './actions';
import { compareCosts } from '../../../components/bookables/prices/util';

const initialState: ProductsState = {
	items: [],
	fetchingForProductType: [],
	fetchedForProductType: [],

	options: [],
	fetchedOptionsForProduct: [],
	fetchingOptionsForProduct: [],

	itemsBookingIsAllowed: [],
	fetchingIsProductBookingAllowed: [],
	fetchedIsProductBookingAllowed: [],

	legacyItems: [],
	fetchingLegacyItems: [],
};

export default handleActions<ProductsState, PossibleActions<typeof actions>>(
	{
		PRODUCTS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForProductType: [
				...state.fetchingForProductType.filter(type => type !== data.type),
				data.type,
			],
		}),

		PRODUCTS_FETCH_SUCCESS: (state, { payload, data }) => {
			const orderedItems = payload.items
				.map(
					(item): Product => ({
						id: item.id,
						legacy: item.legacy,
						name: item.name,
						recurring: item.recurring,
						type: data.type,
						cost: {
							amount: {
								amount: item.recurringFee || item.nonRecurringFee,
								fraction: 10_000,
								currency: data.domain === 'sipgate.de' ? 'EUR' : 'GBP',
							},
							// Can we use item.recurring here? I kinda do not trust it as nothing uses it atm.
							interval: item.nonRecurringFee ? 'onetime' : 'monthly',
							isNetto: false,
						},
					})
				)
				.sort((a, b) => compareCosts(data.domain)(a.cost, b.cost));

			return {
				...state,
				fetchedForProductType: [
					...state.fetchedForProductType.filter(type => type !== data.type),
					data.type,
				],
				fetchingForProductType: [
					...state.fetchingForProductType.filter(type => type !== data.type),
				],
				items: [...state.items.filter(item => item.type !== data.type), ...orderedItems],
			};
		},

		SINGLE_PRODUCT_FETCH_PENDING: (state, { data: { productId } }) => ({
			...state,
			fetchingLegacyItems: [
				...state.fetchingLegacyItems.filter(type => type !== productId),
				productId,
			],
		}),

		SINGLE_PRODUCT_FETCH_SUCCESS: (state, { payload, data }) => {
			return {
				...state,
				fetchingLegacyItems: [...state.fetchingLegacyItems.filter(id => id !== data.productId)],
				legacyItems: [
					...state.legacyItems.filter(item => item.id !== data.productId),
					{
						id: payload.id,
						legacy: payload.legacy,
						recurring: payload.recurring,
						cost: {
							amount: {
								// Can we use payload.recurring here? I kinda do not trust it as nothing uses it atm.
								amount: payload.recurringFee || payload.nonRecurringFee,
								fraction: 10_000,
								currency: data.domain === 'sipgate.de' ? 'EUR' : 'GBP',
							},
							// Can we use payload.recurring here? I kinda do not trust it as nothing uses it atm.
							interval: payload.recurringFee ? 'monthly' : 'onetime',
							isNetto: false,
						},
					},
				],
			};
		},

		PRODUCT_OPTIONS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingOptionsForProduct: [...state.fetchingOptionsForProduct, data.dependantProduct],
		}),

		PRODUCT_OPTIONS_FETCH_SUCCESS: (state, { payload, data }) => {
			const options = [...state.options];

			// There is probably a more elegant way to merge these, feel free to redo this, but my
			// brain hurts.
			//
			// eslint-disable-next-line no-labels
			outer: for (const option of payload.items) {
				for (const i in options) {
					if (options[i].id === option.id) {
						options[i] = {
							id: option.id,
							legacy: option.legacy,
							name: option.name,
							recurring: option.recurring,
							cost: {
								amount: {
									// Can we use payload.recurring here? I kinda do not trust it as nothing uses it atm.
									amount: option.recurringFee || option.nonRecurringFee,
									fraction: 10_000,
									currency: data.domain === 'sipgate.de' ? 'EUR' : 'GBP',
								},
								// Can we use payload.recurring here? I kinda do not trust it as nothing uses it atm.
								interval: option.recurringFee ? 'monthly' : 'onetime',
								isNetto: false,
							},
							dependantProducts: [...options[i].dependantProducts, data.dependantProduct],
						};

						// eslint-disable-next-line no-continue, no-labels
						continue outer;
					}
				}

				options.push({
					id: option.id,
					legacy: option.legacy,
					name: option.name,
					recurring: option.recurring,
					cost: {
						amount: {
							// Can we use payload.recurring here? I kinda do not trust it as nothing uses it atm.
							amount: option.recurringFee || option.nonRecurringFee,
							fraction: 10_000,
							currency: data.domain === 'sipgate.de' ? 'EUR' : 'GBP',
						},
						// Can we use payload.recurring here? I kinda do not trust it as nothing uses it atm.
						interval: option.recurringFee ? 'monthly' : 'onetime',
						isNetto: false,
					},
					dependantProducts: [data.dependantProduct],
				});
			}

			options.sort((a, b) => compareCosts(data.domain)(a.cost, b.cost));

			return {
				...state,
				fetchingOptionsForProduct: state.fetchingOptionsForProduct.filter(
					p => p !== data.dependantProduct
				),
				fetchedOptionsForProduct: [...state.fetchedOptionsForProduct, data.dependantProduct],
				options,
			};
		},

		IS_PRODUCT_BOOKING_ALLOWED_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingIsProductBookingAllowed: [
				...state.fetchingIsProductBookingAllowed.filter(productId => productId !== data.productId),
				data.productId,
			],
		}),

		IS_PRODUCT_BOOKING_ALLOWED_FETCH_SUCCESS: (state, { data, payload }) => ({
			...state,
			fetchedIsProductBookingAllowed: [
				...state.fetchedIsProductBookingAllowed.filter(productId => productId !== data.productId),
				data.productId,
			],
			fetchingIsProductBookingAllowed: [
				...state.fetchingIsProductBookingAllowed.filter(productId => productId !== data.productId),
			],
			itemsBookingIsAllowed: [
				...state.itemsBookingIsAllowed.filter(item => item.productId !== data.productId),
				{
					productId: data.productId,
					allowed: payload,
				},
			],
		}),
	},
	initialState
);
