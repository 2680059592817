import { ToggleEntrySelectionAction } from './types';
import { State } from '../../state';

export const toggleEntrySelectionReducer = (
	state: State,
	action: ToggleEntrySelectionAction
): State => {
	if (!state.entries.result) {
		return state;
	}

	return {
		...state,
		entries: {
			...state.entries,

			result: {
				...state.entries.result,

				normalized: state.entries.result.normalized.map(entry => {
					if (entry.originalEvent.id !== action.eventId) {
						return entry;
					}

					return {
						...entry,
						selected: !entry.selected,
					};
				}),
			},
		},
	};
};
