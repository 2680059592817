import { createAction } from '../..';

import api from '../../../api';
import { ReduxState } from '../../types';
import { selectBlockAnonymous } from './selectors';
import { PhonelineBlockAnonymousTargetType } from '../../../api/types/phoneline-block-anonymous';

export const fetchBlockAnonymous = createAction(
	'PHONELINE_BLOCK_ANONYMOUS_FETCH',
	(userId: string, phonelineId: string) => ({
		promise: () => api.getPhonelineBlockAnonymous(userId, phonelineId),
		shouldFetch: (state: ReduxState) => {
			const blockAnonymous = selectBlockAnonymous(state.phonelineBlockAnonymous, phonelineId);

			return !blockAnonymous.fetched && !blockAnonymous.fetching && !blockAnonymous.updating;
		},
		data: {
			userId,
			phonelineId,
		},
	})
);

export const updateBlockAnonymous = createAction(
	'PHONELINE_BLOCK_ANONYMOUS_SET',
	(
		userId: string,
		phonelineId: string,
		enabled: boolean,
		target: PhonelineBlockAnonymousTargetType
	) => ({
		promise: api.setPhonelineBlockAnonymous(userId, phonelineId, enabled, target),
		data: {
			userId,
			phonelineId,
			enabled,
			target,
		},
	})
);
