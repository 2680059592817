import React, { useId } from 'react';
import classnames from 'classnames';

import classes from './RadioCardItem.module.css';
import { circle } from './utils';

type Props = {
	checked: boolean;
	name: string;
	value: string;
	label: string;
	disabled?: boolean;
	description?: string;
	additional: React.ReactNode;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const styles = {
	cardContainer: classnames(
		'group',
		'flex',
		'w-full',
		'p-16',
		'mb-8',
		'last:mb-0',
		'bg-neo-color-web-app-component-card-background-default',
		'hover:bg-neo-color-web-app-component-card-background-hover',
		'active:bg-neo-color-web-app-component-card-background-active',
		'min-h-72',
		'justify-start',
		'items-center',
		'gap-12',
		'rounded-lg',
		'ring-inset',
		'font-brand',
		'cursor-pointer',
		'ring-1',
		'ring-neo-color-global-border-neutral-moderate-default',
		'hover:ring-neo-color-global-border-neutral-moderate-hover',
		'active:ring-neo-color-global-border-neutral-moderate-active',
		'has-[:focus-visible]:ring',
		'has-[:focus-visible]:ring-color-focus',
		'has-[:checked]:ring-2',
		'has-[:checked]:ring-neo-color-global-border-primary-intense-default',
		'has-[:checked]:hover:ring-neo-color-global-border-primary-intense-hover',
		'has-[:checked]:active:ring-neo-color-global-border-primary-intense-active',
		'has-[:disabled]:cursor-not-allowed',
		'has-[:disabled]:ring-0',
		'has-[:disabled]:bg-neo-color-global-background-neutral-intense-disabled'
	),
	touchTarget: classnames(
		'relative',
		'inline-flex',
		'shrink-0',
		'grow-0',
		'items-center',
		'justify-center',
		'appearance-none',
		'pointer-fine:w-16',
		'pointer-coarse:w-40',
		'pointer-coarse:-mx-12',
		'rounded-none'
	),
	content: classnames('grow', 'select-none'),
	label: classnames(
		'text-base/20',
		'font-bold',
		'text-neo-color-global-content-neutral-intense',
		'group-has-[:disabled]:text-neo-color-global-content-neutral-disabled'
	),
	description: classnames(
		'text-xs/16',
		'text-neo-color-global-content-neutral-moderate',
		'group-has-[:disabled]:text-neo-color-global-content-neutral-disabled'
	),
	additional: classnames(
		'text-neo-color-global-content-neutral-moderate',
		'group-has-[:disabled]:text-neo-color-global-content-neutral-disabled',
		'font-normal',
		'w-40',
		'h-40',
		'flex',
		'select-none',
		classes.additional
	),
};

const RadioCardItem = ({
	checked = false,
	disabled = false,
	name,
	label,
	value,
	onChange,
	description,
	additional,
}: Props): JSX.Element => {
	const id = useId();

	return (
		<label className={classnames(styles.cardContainer)}>
			<div className={styles.touchTarget}>
				<input
					className={circle(disabled, checked, classes.backgroundActive, classes.backgroundHover)}
					type="radio"
					id={id}
					name={name}
					value={value}
					checked={checked}
					disabled={disabled}
					onChange={onChange}
				/>
			</div>
			<div className={styles.content}>
				<span className={styles.label}>{label}</span>
				<div className={styles.description}>{description}</div>
			</div>

			{additional &&
				(description ? (
					<div className={`${styles.additional} justify-end items-start`}>{additional}</div>
				) : (
					<div className={`${styles.additional} justify-center items-center`}>{additional}</div>
				))}
		</label>
	);
};

export { RadioCardItem };
