import { AudioFile } from '.';
import api from '../../../api';
import { ReduxState } from '../../types';
import { createAction } from '../..';

function shouldFetchAudioFiles(state: ReduxState, voicemailId: string) {
	return (
		!state.audioFiles.fetchedForOwner.find(audioFile => audioFile.ownerId === voicemailId) &&
		!state.audioFiles.fetchingForOwner.includes(voicemailId)
	);
}

export const fetchAudioFiles = createAction(
	'AUDIO_FILES_FETCH',
	(ownerId: string, force?: boolean) => ({
		promise: () => api.getAudioFiles(ownerId),
		shouldFetch: (state: ReduxState) => force || shouldFetchAudioFiles(state, ownerId),
		data: {
			ownerId,
		},
	})
);

export const createAudioFile = createAction(
	'AUDIO_FILE_CREATE',
	(voicemailId: string, filename: string, base64Content: string) => ({
		promise: api.createAudioFile(voicemailId, filename, base64Content),
		data: {
			voicemailId,
		},
	})
);

export const deleteAudioFile = createAction(
	'AUDIO_FILE_DELETE',
	(audioFileId: string, ownerId: string) => ({
		promise: api.deleteAudioFile(audioFileId),
		data: {
			audioFileId,
			ownerId,
		},
	})
);

export const setAudioFileAlias = createAction(
	'AUDIO_FILE_SET_ALIAS',
	(audioFileId: string, alias: string) => ({
		promise: () => api.setAudioFileAlias(audioFileId, alias),
		data: {
			audioFileId,
			alias,
		},
	})
);

export const setAudioFileRecordingState = createAction(
	'AUDIO_FILE_SET_RECORDING',
	(audioFileId: string, recordingEnabled: boolean) => ({
		promise: () => api.setAudioFileRecording(audioFileId, recordingEnabled),
		data: {
			audioFileId,
			recordingEnabled,
		},
	})
);

export const fetchAudioFileContent = createAction(
	'AUDIO_FILE_FETCH_CONTENT',
	(audioFile: AudioFile, onlyForceOnError?: boolean) => ({
		shouldFetch: () =>
			audioFile.url.state === 'UNFETCHED' || (onlyForceOnError && audioFile.url.state === 'ERROR'),
		promise: () => api.getAudioFileContent(audioFile.id),
		data: {
			audioFileId: audioFile.id,
		},
	})
);
