import { handleActions } from '../..';

import { PhonelineBlockAnonymousState } from './types';
import * as actions from './actions';

const initialState: PhonelineBlockAnonymousState = {
	items: [],
};

export default handleActions<PhonelineBlockAnonymousState, PossibleActions<typeof actions>>(
	{
		PHONELINE_BLOCK_ANONYMOUS_FETCH_PENDING: (state, { data }) => {
			if (state.items.some(p => p.phoneline === data.phonelineId)) {
				return {
					items: state.items.map(item => {
						if (item.phoneline !== data.phonelineId) {
							return item;
						}

						return {
							...item,
							fetching: true,
						};
					}),
				};
			}

			return {
				items: [
					...state.items,
					{
						phoneline: data.phonelineId,
						fetched: false as const,
						fetching: true,
						updating: false as const,
					},
				],
			};
		},

		PHONELINE_BLOCK_ANONYMOUS_FETCH_SUCCESS: (state, { payload, data }) => ({
			items: state.items.map(item => {
				if (item.phoneline !== data.phonelineId) {
					return item;
				}

				return {
					...item,
					fetched: true,
					fetching: false,
					data: payload,
				};
			}),
		}),

		PHONELINE_BLOCK_ANONYMOUS_SET_PENDING: (state, { data }) => {
			if (state.items.some(p => p.phoneline === data.phonelineId)) {
				return {
					items: state.items.map(item => {
						if (item.phoneline !== data.phonelineId) {
							return item;
						}

						return {
							...item,
							updating: true,
							data: {
								enabled: data.enabled,
								target: data.target,
							},
						};
					}),
				};
			}

			return {
				items: [
					...state.items,
					{
						phoneline: data.phonelineId,
						fetched: false,
						fetching: true,
						updating: true,
						data: {
							enabled: data.enabled,
							target: data.target,
						},
					},
				],
			};
		},

		PHONELINE_BLOCK_ANONYMOUS_SET_SUCCESS: (state, { data }) => ({
			items: state.items.map(item => {
				if (item.phoneline !== data.phonelineId) {
					return item;
				}

				return {
					...item,
					updating: false as const,
				};
			}),
		}),
	},

	initialState
);
