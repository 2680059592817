import { handleActions } from '../..';

import * as actions from './actions';
import { UserNotificationState } from './types';

export const initialState: UserNotificationState = {
	items: [],
	fetched: false,
	fetching: false,
	creatingUserNotification: [],
};

export default handleActions<UserNotificationState, PossibleActions<typeof actions>>(
	{
		USERNOTIFICATIONS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		USERNOTIFICATIONS_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetching: false,
				fetched: true,
				items: payload,
			};
		},
		USERNOTIFICATION_CREATE_PENDING: state => ({
			...state,
			creatingUserNotification: [...state.creatingUserNotification],
		}),

		USERNOTIFICATION_CREATE_SUCCESS: state => ({
			...state,

			creatingUserNotification: state.creatingUserNotification,
		}),

		USERNOTIFICATION_REPLACE_PENDING: state => ({
			...state,
			creatingUserNotification: [...state.creatingUserNotification],
		}),

		USERNOTIFICATION_REPLACE_SUCCESS: state => ({
			...state,
			creatingUserNotification: state.creatingUserNotification,
		}),
		USERNOTIFICATION_DELETE_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.filter(
					notification => notification.notificationId !== data.notificationId
				),
			};
		},
	},
	initialState as UserNotificationState
);
