import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';

import {
	SsoConfigurationCreateReduxAction,
	SsoConfigurationUpdateReduxAction,
} from '../../redux/modules/ssoConfiguration';
import {
	ApiAccountStatement,
	ApiBillingFlags,
	ApiItemizedBillingSettings,
	ApiItemizedBillResult,
	BillingAddress,
	Invoice,
	InvoiceType,
	ItemizedBillEntryTypeOption,
} from '../../redux/slices/accounting';
import { AcdAudioFileType } from '../../redux/slices/acdAudioFiles';
import { ApiError } from '../ApiError';
import { ListResponse, SipgateApi } from '../SipgateApi';
import { ApiEventLifetime } from '../types/account';
import { ApiRingingOrderSettings } from '../types/acd';
import {
	ApiCreateAddressRequest,
	ApiDeleteAddressRequest,
	ApiEditAddressRequest,
	ApiMoveAddressRequest,
} from '../types/addresses';
import { AppBookingStatus } from '../types/AppBookingStatus';
import { BatchJob, BatchUser } from '../types/batch-user';
import { ApiContact, ContactScope, CreateApiContact, UpdateApiContact } from '../types/contacts';
import { ApiDateBasedSet } from '../types/dateBasedSets';
import { DeviceProperty } from '../types/DeviceProperty';
import { ApiDomainState } from '../types/domainVerification';
import { VoicemailDestination } from '../types/forwardings';
import {
	ApiBase64Upload,
	ApiBusinessType,
	ApiBusinessVerification,
} from '../types/identityVerification';
import { ApiIvr, ApiIvrForwarding, ApiIvrKeyForwadingTargetType } from '../types/ivr';
import { ApiCreatePhonebookRequest } from '../types/phonebook';
import { ApiPhoneNumberSuggestionNumbers } from '../types/phonenumbers';
import { ApiPresenceSubscription } from '../types/presence';
import { ApiProductType } from '../types/products';
import { ApiSimIntendedUse } from '../types/sim';
import { ApiSsoConfiguration } from '../types/ssoConfiguration';
import { ApiTimeBasedForwardings } from '../types/timeBasedForwardings';
import { ApiTimeBasedSet } from '../types/timeBasedSets';
import { ApiLocale } from '../types/userinfo';
import {
	CreateUserNotificationRequest,
	ReplaceUserNotificationRequest,
} from '../types/userNotification';
import { ApiUser } from '../types/users';
import { HttpClient } from './HttpClient';

type Version = '/v2' | '/v3';

export class RestApiClient implements SipgateApi {
	private http: HttpClient;

	public constructor(http: HttpClient) {
		this.http = http;
	}

	public cancellable = (abortSignal: AbortSignal) =>
		new RestApiClient(this.http.cancellable(abortSignal));

	public createAffiliateLoginUrl(masterSipId: string, domain: string): Promise<{ url: string }> {
		return this.post('/v3', `/affiliate/impersonation/login`, { masterSipId, domain });
	}

	public revokeAffiliateLogin(): Promise<void> {
		return this.delete('/v3', `/affiliate/login-consent`);
	}

	public fetchAffiliateInfo(): Promise<{ companyName: string; isCertified: boolean }> {
		return this.get('/v3', `/affiliate/info`);
	}

	public consentAffiliateLogin(): Promise<void> {
		return this.post('/v3', `/affiliate/login-consent`);
	}

	public getAffiliateLoginConsentStatus(): Promise<void> {
		return this.get('/v3', `/affiliate/login-consent`);
	}

	public fetchCommissionPayoutAccount(): Promise<{ iban: string; accountHolder: string }> {
		return this.get('/v3', `/affiliate/commission/account`);
	}

	public createCommissionPayoutAccount(
		iban: string,
		accountHolder: string
	): Promise<{
		iban: string;
		accountHolder: string;
	}> {
		return this.post('/v3', `/affiliate/commission/account`, { iban, accountHolder });
	}

	public deleteCommissionPayoutAccount(): Promise<void> {
		return this.delete('/v3', `/affiliate/commission/account`);
	}

	public getBillingAddress(): Promise<BillingAddress> {
		return this.get('/v3', `/accounting/billing/address`);
	}

	public getInvoices(typesFilter: InvoiceType[] = []): Promise<{ invoices: Invoice[] }> {
		return this.get('/v3', `/accounting/invoices?typesFilter=${typesFilter.join(',')}`);
	}

	public downloadInvoice(documentId: string): Promise<Blob> {
		return this.get('/v3', `/accounting/invoices/${documentId}`, {
			mimeType: 'application/pdf',
		});
	}

	public downloadInvoices(documentIds: string[]): Promise<Blob> {
		return this.get('/v3', `/accounting/invoices?documentIds=${documentIds.join(',')}`, {
			mimeType: 'application/zip',
		});
	}

	public downloadHighTrafficEVN(periodStart: DateTime, periodEnd: DateTime): Promise<Blob> {
		const params = new URLSearchParams();
		params.append('periodStart', periodStart.toUTC().toString());
		params.append('periodEnd', periodEnd.toUTC().toString());
		return this.get('/v3', `/accounting/itemized-billing/high-traffic?${params.toString()}`, {
			mimeType: 'text/csv',
		});
	}

	public patchBillingAddress(
		partialBillingAddress: Partial<BillingAddress>
	): Promise<BillingAddress> {
		return this.patch('/v3', `/accounting/billing/address`, partialBillingAddress);
	}

	public recreateInvoice(invoiceId: string): Promise<void> {
		return this.post('/v3', `/accounting/invoices/${invoiceId}/recreate`);
	}

	public getReferallCommissions = (startDate: string, endDate?: string) => {
		const params = new URLSearchParams();

		params.append('periodStart', startDate);
		if (endDate) {
			params.append('periodEnd', endDate);
		}

		return this.get('/v3', `/affiliate/commission/statements?${params.toString()}`);
	};

	public getCommissionBalance = () => this.get('/v3', '/affiliate/commission/balance');

	public payoutCommissionBalance = (iban: string, accountHolder: string) =>
		this.post('/v3', '/affiliate/commission/payout', {
			iban,
			accountHolder,
		});

	public getAvailablePresenceSubscriptions = () =>
		this.get('/v3', '/presence/availableSubscriptions');

	public getSubscriptionKeyForPresenceSubscriptions = (subscriptions: ApiPresenceSubscription[]) =>
		this.post('/v3', '/presence/subscribe', {
			callthroughs: subscriptions.map(s => s.extensionSipid),
		});

	public getMicrosoftTeamsFqdn = () => this.get('/v3', '/microsoft-teams/fqdn');

	public getMicrosoftTeamsRoutingConfiguration = (extensionId: string) =>
		this.get('/v3', `/microsoft-teams/routing-configuration/${extensionId}`);

	public setMicrosoftTeamsRoutingConfiguration = (
		extensionId: string,
		phoneNumbers: string[],
		sipDomain: string,
		sipPassword: string
	) =>
		this.post('/v3', `/microsoft-teams/routing-configuration/`, {
			extension: extensionId,
			phoneNumbers,
			sipDomain,
			sipPassword,
		});

	public syncMicrosoftTeamsRoutingConfiguration = (
		extensionId: string,
		phoneNumbers: string[],
		sipDomain: string,
		sipPassword: string
	) =>
		this.put('/v3', `/microsoft-teams/routing-configuration/`, {
			extension: extensionId,
			phoneNumbers,
			sipDomain,
			sipPassword,
		});

	public deleteMicrosoftTeamsRoutingConfiguration = (extensionId: string) =>
		this.delete('/v3', `/microsoft-teams/routing-configuration/${extensionId}`);

	public createMicrosoftTeamsFqdn = () => this.post('/v3', '/microsoft-teams/fqdn');

	public createUnlinkedDevice = (alias: string) =>
		this.post('/v3', '/devices/register', { alias, owner: null });

	public createBatchUserAuthRequest = (provider: string) =>
		this.get('/v3', `/batch-users/auth/${provider}`);

	public fetchBatchUsersFromProvider = (provider: string, code: string) =>
		this.post('/v3', `/batch-users/fetch/${provider}`, { code });

	public getCurrentBatchJob(): Promise<ListResponse<BatchJob>> {
		return this.get('/v3', `/batch-users/job`);
	}

	public deleteCurrentBatchJob(jobId: string): Promise<void> {
		return this.delete('/v3', `/batch-users/${jobId}`);
	}

	public executeBatchImportJob(jobId: string): Promise<void> {
		return this.post('/v3', `/batch-users/${jobId}/execute`);
	}

	public updateBatchUser(jobId: string, batchUser: BatchUser): Promise<BatchUser> {
		return this.put('/v3', `/batch-users/${jobId}`, batchUser);
	}

	public deleteBatchSingleUser(jobId: string, userId: string): Promise<void> {
		return this.delete('/v3', `/batch-users/${jobId}/${userId}`);
	}

	public getBatchUsers = (jobId: string) => this.get('/v3', `/batch-users/${jobId}`);

	public updateBatchUsers = (jobId: string, batchUsers: BatchUser[]) =>
		this.put('/v3', `/batch-users/${jobId}/batch-update`, { items: batchUsers });

	public createCSVBatchUserJob = (
		base64FileContent: string
	): Promise<{
		jobId: string;
	}> => this.post('/v3', '/batch-users/csv', { base64FileContent });

	public getContracts = () => this.get('/v3', '/contracts');

	public getReferredCustomers = () => this.get('/v3', '/affiliate/referrals');

	public createUnlinkedMobileDevice = (alias: string) =>
		this.post('/v3', '/devices/mobile', { alias, owner: null });

	public setMicrosoftTeamsFqdnTxtRecord = (fqdn: string, txtRecord: string) =>
		this.post('/v3', '/microsoft-teams/fqdn/txt', {
			fqdn,
			txtRecord,
		});

	public updateMicrosoftTeamsFqdnTxtRecord = (fqdn: string, txtRecord: string) =>
		this.put('/v3', '/microsoft-teams/fqdn/txt', {
			fqdn,
			txtRecord,
		});

	public deleteMicrosoftTeamsFqdnTxtRecord = () => this.delete('/v3', '/microsoft-teams/fqdn/txt');

	public getMicrosoftTeamsFqdnTxtRecord = () => this.get('/v3', '/microsoft-teams/fqdn/txt');

	public getTranslations = (locale: ApiLocale) =>
		this.get('/v2', `/translations/${locale}`, { unauthenticated: true });

	public getAddresses = () => this.get('/v3', '/addresses');

	public lookupGbAddresses = (zip: string) => {
		const params = new URLSearchParams();

		params.append('zip', zip);

		return this.get('/v3', `/address-lookup/gb?${params.toString()}`);
	};

	public createAddress = (request: ApiCreateAddressRequest) =>
		this.post('/v3', '/addresses', request);

	public moveAddress = (id: number, request: ApiMoveAddressRequest) => {
		if (request.countryCode === 'DE') {
			return this.post('/v3', '/addresses', {
				countryCode: request.countryCode,
				street: request.street,
				number: request.number,
				additional: request.additional,
				city: request.city,
				zip: request.zip,
				relocation: {
					sourceAddress: id,
					date: request.date.toISODate(),
				},
			});
		}

		if (request.countryCode === 'US') {
			return this.post('/v3', '/addresses', {
				countryCode: request.countryCode,
				additional: request.additional,
				address: request.address,
				city: request.city,
				zip: request.zip,
				state: request.state,
				relocation: {
					sourceAddress: id,
					date: request.date.toISODate(),
				},
			});
		}

		return this.post('/v3', '/addresses', {
			countryCode: request.countryCode,
			additional: request.additional,
			address: request.address,
			city: request.city,
			zip: request.zip,
			relocation: {
				sourceAddress: id,
				date: request.date.toISODate(),
			},
		});
	};

	public createIvr(alias: string): Promise<ApiIvr> {
		return this.post('/v3', '/ivr', {
			alias,
		});
	}

	public assignPhoneNumbersToIvr(ivrId: string, phoneNumbers: string[]): Promise<void> {
		return this.post('/v3', `/ivr/${ivrId}/routing`, { phoneNumbers });
	}

	public assignAudioFileToIvr(ivrId: string, audioFileId: string): Promise<void> {
		return this.post('/v3', `/ivr/${ivrId}/announcement/${audioFileId}`, {});
	}

	public renameIvr = (ivrId: string, alias: string): Promise<void> =>
		this.put('/v3', `/ivr/${ivrId}/alias`, { alias });

	public deleteIvr = (ivrId: string): Promise<void> => this.delete('/v3', `/ivr/${ivrId}`);

	public updateIvrKeyForwardingMapping = (
		ivrId: string,
		forwardingKey: ApiIvrForwarding,
		value: string,
		type: ApiIvrKeyForwadingTargetType,
		loopCount?: number
	) =>
		this.post('/v3', `/ivr/${ivrId}/key-forwarding/${forwardingKey}`, {
			target: {
				type,
				value,
			},
			loopCount,
		});

	public updateIvrFallbackMapping = (
		ivrId: string,
		value: string,
		type: ApiIvrKeyForwadingTargetType,
		loopCount: number
	) =>
		this.put('/v3', `/ivr/${ivrId}/fallback`, {
			target: {
				type,
				value,
			},
			loopCount,
		});

	public deleteIvrKeyForwardingMapping = (ivrId: string, forwardingKey: string) =>
		this.delete('/v3', `/ivr/${ivrId}/key-forwarding/${forwardingKey}`);

	public editAddress = (id: number, request: ApiEditAddressRequest) =>
		this.put('/v3', `/addresses/${id}`, request);

	public deleteAddress = (id: number, request: ApiDeleteAddressRequest) =>
		this.delete('/v3', `/addresses/${id}`, request);

	public getPhonebookEntry = (addressId: number) =>
		this.get('/v3', `/addresses/${encodeURIComponent(addressId)}/phonebook`);

	public createPhonebookEntry = (addressId: number, request: ApiCreatePhonebookRequest) =>
		this.post('/v3', `/addresses/${encodeURIComponent(addressId)}/phonebook`, request);

	public updatePhonebookEntry = (addressId: number, request: ApiCreatePhonebookRequest) =>
		this.put('/v3', `/addresses/${encodeURIComponent(addressId)}/phonebook`, request);

	public deletePhonebookEntry = (addressId: number) =>
		this.delete('/v3', `/addresses/${encodeURIComponent(addressId)}/phonebook`);

	public getBusinessTypes = () => this.get('/v3', '/business-types');

	public getBusinessSuffixes = () => this.get('/v3', '/business-suffixes');

	public getGermanCitiesByZip = (zip: string) => {
		const params = new URLSearchParams();

		params.append('zip', zip);

		return this.get('/v3', `/address-lookup/de/cities?${params.toString()}`);
	};

	public getGermanStreetsByZipAndCity = (zip: string, city: string) => {
		const params = new URLSearchParams();

		params.append('zip', zip);
		params.append('city', city);

		return this.get('/v3', `/address-lookup/de/streets?${params.toString()}`);
	};

	public getGermanAreaCode = (zip: string, city: string, street: string, number: string) => {
		const params = new URLSearchParams();

		params.append('zip', zip);
		params.append('city', city);
		params.append('street', street);
		params.append('number', number);

		return this.get('/v3', `/address-lookup/de/area-code?${params.toString()}`).then(
			response => response.areaCode
		);
	};

	public getFaxlines = () => this.get('/v3', `/fax`);

	public getCallRestrictions = () => this.get('/v2', `/callrestrictions`);

	public setCallRestrictions = (userId: string, restriction: string, enabled: boolean) =>
		this.post('/v2', `/${userId}/callrestrictions/${restriction}`, { enabled });

	public getTrunkCallRestrictions = () => {
		return this.get('/v3', '/callrestrictions/trunks');
	};

	public setTrunkCallRestrictions = (trunkId: string, restriction: string, enabled: boolean) =>
		this.put('/v3', `/callrestrictions/trunk/${trunkId}/${restriction}`, { enabled });

	public setFaxlineAlias = (faxlineId: string, alias: string) =>
		this.put('/v3', `/fax/${faxlineId}/alias`, { alias });

	public setFaxlineTagline = (faxlineId: string, tagline: string) =>
		this.put('/v3', `/fax/${faxlineId}/tagline`, {
			tagline,
		});

	public createFaxline = (ownerId: string, alias: string) =>
		this.post('/v3', `/fax`, { ownerId, alias });

	public deleteFaxline = (faxlineId: string) => this.delete('/v3', `/fax/${faxlineId}`);

	public setFaxlineCallerId = (faxlineId: string, callerId: string, anonymous: boolean) =>
		this.put('/v3', `/fax/${faxlineId}/callerId`, {
			callerId,
			anonymous,
		});

	public getPhonelines = (userId: string) => this.get('/v2', `/${userId}/phonelines`);

	public getPhoneline = (userId: string, phonelineId: string) =>
		this.get('/v2', `/${userId}/phonelines/${phonelineId}`);

	public createPhoneline = (userId: string) => this.post('/v2', `/${userId}/phonelines`);

	public deletePhoneline = (userId: string, phonelineId: string) =>
		this.delete('/v2', `/${userId}/phonelines/${phonelineId}`);

	public setPhonelineAlias = (userId: string, phonelineId: string, alias: string) =>
		this.put('/v2', `/${userId}/phonelines/${phonelineId}`, { alias });

	public createPhonelineDevice = (userId: string, phonelineId: string, deviceId: string) =>
		this.post('/v2', `/${userId}/phonelines/${phonelineId}/devices`, {
			deviceId,
		});

	public deletePhonelineDevice = (userId: string, phonelineId: string, deviceId: string) =>
		this.delete('/v2', `/${userId}/phonelines/${phonelineId}/devices/${deviceId}`);

	public setTranscription = (voicemailId: string, enabled: boolean) =>
		this.put('/v3', `/voicemails/${voicemailId}/transcription`, {
			enabled,
		});

	public getUser = (userId: string) => this.get('/v2', `/users/${userId}`);

	public deleteUser = (userId: string) => this.delete('/v2', `/users/${userId}`);

	public createUser(
		firstName: string,
		lastName: string,
		email: string,
		isAdmin: boolean,
		locationId: number
	): Promise<ApiUser> {
		return this.post('/v3', '/users', {
			firstName,
			lastName,
			email,
			isAdmin,
			locationId,
		});
	}

	public setDefaultDevice = (userId: string, deviceId: string) =>
		this.put('/v2', `/users/${userId}/defaultdevice`, { deviceId });

	public getUsers = () => this.get('/v2', '/users/');

	public setUserRole = (userId: string, isAdmin: boolean) =>
		this.put('/v2', `/users/${userId}/role`, { admin: isAdmin });

	public setUserName = (userId: string, firstName: string, lastName: string) =>
		this.put('/v3', `/users/${encodeURIComponent(userId)}/name`, {
			firstName,
			lastName,
		});

	public setUserEmail = (userId: string, email: string) =>
		this.post('/v3', `/users/${encodeURIComponent(userId)}/settings/email/change`, {
			newEmail: email,
		});

	public getUserEmailChangeRequest = (userId: string) =>
		this.get('/v3', `/users/${encodeURIComponent(userId)}/settings/email/change`);

	public cancelUserEmailChangeRequest = (userId: string) =>
		this.delete('/v3', `/users/${encodeURIComponent(userId)}/settings/email/change`);

	public setUserLanguage = (userId: string, language: string) =>
		this.put('/v3', `/users/${encodeURIComponent(userId)}/settings/language`, { language });

	public getUserLanguage = (userId: string) =>
		this.get('/v3', `/users/${encodeURIComponent(userId)}/settings/language`);

	public setUserLocation = (userId: string, addressId: string) =>
		this.put('/v2', `/users/${userId}/location`, { addressId });

	public setUserBusyOnBusy(userId: string, enabled: boolean): Promise<void> {
		return this.put('/v2', `/users/${userId}/busyonbusy`, { enabled });
	}

	public setSmsSim = (deviceId: string, simExtension: string) =>
		this.put('/v3', `/devices/${deviceId}/smsSim`, { simExtension });

	public getAudioFiles = (ownerId: string) => {
		return this.get('/v3', `/audioFiles?ownerId=${ownerId}`);
	};

	public activateAudioFile = (
		userId: string,
		phonelineId: string,
		voicemailId: string,
		audioFileId: string
	) =>
		this.put(
			'/v2',
			`/${userId}/phonelines/${phonelineId}/voicemails/${voicemailId}/greetings/${audioFileId}`,
			{
				active: true,
			}
		);

	public createAudioFile = (ownerId: string, fileName: string, base64Content: string) =>
		this.post('/v3', `/audioFiles`, {
			fileName,
			base64Content,
			ownerId,
		});

	public getAudioFileContent = (audioFileId: string) =>
		this.get('/v3', `/audioFiles/${audioFileId}`, { mimeType: 'audio/mpeg' });

	public deleteAudioFile = (audioFileId: string) =>
		this.delete('/v3', `/audioFiles/${encodeURIComponent(audioFileId)}`);

	public setAudioFileAlias = (audioFileId: string, alias: string) =>
		this.put('/v3', `/audioFiles/${encodeURIComponent(audioFileId)}/alias`, { alias });

	public setActiveAudioFileVoicemail = (voicemailId: string, audioFileId: string | null) =>
		this.put('/v3', `/voicemails/${voicemailId}/activeGreeting`, { greetingId: audioFileId });

	public setActiveAudioFileGreetingForGroup = (groupId: string, audioFileId: string | null) =>
		this.put('/v3', `/groups/${groupId}/greeting/activeAudioFile`, { audioFileId });

	public setActiveAudioFileCallQueue = (groupId: string, audioFileId: string | null) =>
		this.put('/v3', `/groups/${groupId}/callqueue/activeAudioFile`, { audioFileId });

	public setAudioFileRecording = (audioFileId: string, active: boolean) =>
		this.put('/v3', `/audioFiles/${encodeURIComponent(audioFileId)}/recording`, { active });

	public getDeviceContracts = (deviceId: string) =>
		this.get('/v3', `/devices/${deviceId}/contracts`);

	public createDeviceContract = (deviceId: string, productId: string, acceptedTacId?: string) =>
		this.post('/v3', `/devices/${deviceId}/contracts`, {
			productId,
			acceptedTacId,
		});

	public revokeDeviceContractCancellation = (deviceId: string, contractId: string) => {
		return this.delete('/v3', `/devices/${deviceId}/contracts/${contractId}/cancellation`);
	};

	public cancelDeviceContract = (
		deviceId: string,
		contractId: string,
		cancellationDate: Date | null
	) =>
		this.post('/v3', `/devices/${deviceId}/contracts/${contractId}/cancellation`, {
			cancellationDate: cancellationDate?.toISOString(),
		});

	public setDeviceSettings = (deviceId: string, dnd: boolean, emergencyAddressId?: string) =>
		this.put('/v2', `/devices/${deviceId}`, {
			dnd,
			emergencyAddressId,
		});

	public setDeviceAlias = (deviceId: string, alias: string) =>
		this.put('/v3', `/devices/${deviceId}/alias`, { value: alias });

	public resetDevicePassword = (deviceId: string) =>
		this.post('/v2', `/devices/${deviceId}/credentials/password`);

	public getDevices = (userId: string) => {
		let path = '/devices';

		if (userId) {
			path += `?userId=${userId}`;
		}

		return this.get('/v3', path);
	};

	public createDevice = (userId: string, type: string, alias?: string) =>
		this.post('/v2', `/${userId}/devices`, {
			type,
			alias,
		});

	public createDeviceExternal = (userId: string, alias: string, number: string) =>
		this.post('/v2', `/${userId}/devices/external`, {
			alias,
			number,
		});

	public deleteDevice = (deviceId: string) => this.delete('/v2', `/devices/${deviceId}`);

	public createTrunkContract = (productId: number, alias: string, addressId?: number) => {
		return this.post('/v3', `/trunks/contracts/`, { productId, alias, addressId });
	};

	public changeTrunkContract = (productId: number, replacesCustomerProductId: number) => {
		return this.put('/v3', `/trunks/contracts/`, { productId, replacesCustomerProductId });
	};

	public getTrunkContracts = () => this.get('/v3', `/trunks/contracts`);

	public getTrunks = () => this.get('/v3', `/trunks`);

	public getTrunkContingentContracts = (trunkId: string) =>
		this.get('/v3', `/trunks/${trunkId}/contracts`);

	public cancelTrunkContingentContract = (
		deviceId: string,
		contractId: string,
		cancellationDate: Date | null
	) =>
		this.post('/v3', `/devices/${deviceId}/contracts/${contractId}/cancellation`, {
			cancellationDate: cancellationDate?.toISOString(),
		});

	public revokeDeviceOrTrunkContingentContractCancellation = (
		deviceId: string,
		contractId: string
	) => {
		return this.delete('/v3', `/devices/${deviceId}/contracts/${contractId}/cancellation`);
	};

	public cancelTrunkContract = (contractId: number) => {
		return this.post('/v3', `/trunks/contracts/cancellation`, {
			customerProductId: contractId,
		});
	};

	public revokeTrunkContractCancellation = (contractId: number) => {
		return this.delete('/v3', `/trunks/contracts/cancellation`, {
			customerProductId: contractId,
		});
	};

	public getTrunkDevices = (trunkId: string) =>
		this.get('/v3', `/trunks/${encodeURIComponent(trunkId)}/devices`);

	public setTrunkCallerId = (trunkId: string, callerE164Number: string) =>
		this.put('/v3', `/trunks/${encodeURIComponent(trunkId)}/fallbackCallerId`, {
			callerE164Number,
		});

	public setTrunkEmergencyLocation = (trunkId: string, addressId: string) =>
		this.put('/v3', `/trunks/${encodeURIComponent(trunkId)}/emergencyAddressId`, {
			addressId,
		});

	public renameTrunk = (trunkId: string, alias: string) =>
		this.put('/v3', `/trunks/${trunkId}/alias`, {
			value: alias,
		});

	public generateTrunkCredentialsPassword = (trunkId: string) =>
		this.post('/v3', `/trunks/${trunkId}/credentialsPassword`);

	public deleteTrunk = (trunkId: string) => this.delete('/v3', `/trunks/${trunkId}`);

	public createTrunkContingentContract = (trunkSipId: string, productId: string) =>
		this.post('/v3', `/trunks/bookMinutes`, {
			productId,
			trunkSipId,
		});

	public getTacs = () => this.get('/v2', '/app/tacs');

	public getTacsForProduct = (productId: string) => this.get('/v3', `/tacs?productId=${productId}`);

	public fetchLinks = () => this.get('/v2', '/app/links');

	public getHistory = (args: {
		connectionIds?: string[];
		types?: string[];
		directions?: string[];
		status?: string[];
		limit?: number;
		directory?: string[];
		offset?: number;
		starred?: string[];
		from?: Moment | null;
		to?: Moment | null;
		labelIds?: number[];
		read?: string[];
		phonenumber?: string;
	}) => {
		const {
			connectionIds = [],
			types = [],
			directions = [],
			status = [],
			limit,
			directory = [],
			offset,
			starred = [],
			from,
			to,
			labelIds = [],
			read = [],
			phonenumber,
		} = args;

		let url = `/events`;
		if (typeof limit !== 'undefined') {
			url += `?limit=${encodeURIComponent(limit.toString(10))}`;
		}
		if (typeof offset !== 'undefined') {
			url += `&offset=${encodeURIComponent(offset.toString(10))}`;
		}
		url += this.getDataDStartAndEndDateParameterString(from, to);
		if (typeof phonenumber !== 'undefined') {
			url += `&phonenumber=${encodeURIComponent(phonenumber)}`;
		}

		url += types.reduce((joined, type) => `${joined}&types=${encodeURIComponent(type)}`, '');
		url += directions.reduce(
			(joined, direction) => `${joined}&directions=${encodeURIComponent(direction)}`,
			''
		);
		url += status.reduce(
			(joined, statusEntity) => `${joined}&status=${encodeURIComponent(statusEntity)}`,
			''
		);
		url += connectionIds.reduce(
			(joined, connectionId) => `${joined}&connectionIds=${encodeURIComponent(connectionId)}`,
			''
		);
		url += starred.reduce(
			(joined, starredEntity) => `${joined}&starred=${encodeURIComponent(starredEntity)}`,
			''
		);
		url += directory.reduce(
			(joined, directoryEntity) => `${joined}&directories=${encodeURIComponent(directoryEntity)}`,
			''
		);

		url += read.reduce(
			(joined, readState) => `${joined}&read=${encodeURIComponent(readState)}`,
			''
		);

		url += labelIds.reduce(
			(joined, labelId) => `${joined}&labelIds=${encodeURIComponent(labelId.toString(10))}`,
			''
		);

		return this.get('/v3', url);
	};

	public exportFilteredHistory = (args: {
		connectionIds?: string[];
		types?: string[];
		directions?: string[];
		status?: string[];
		directory?: string[];
		starred?: string[];
		from?: Moment | null;
		to?: Moment | null;
		labelIds?: number[];
		read?: string[];
		phonenumber?: string;
	}) => {
		const {
			connectionIds = [],
			types = [],
			directions = [],
			status = [],
			directory = [],
			starred = [],
			from,
			to,
			labelIds = [],
			read = [],
			phonenumber,
		} = args;

		let url = `/events?limit=${encodeURIComponent(1000)}`;

		url += this.getDataDStartAndEndDateParameterString(from, to);
		if (typeof phonenumber !== 'undefined') {
			url += `&phonenumber=${encodeURIComponent(phonenumber)}`;
		}

		url += types.reduce((joined, type) => `${joined}&types=${encodeURIComponent(type)}`, '');
		url += directions.reduce(
			(joined, direction) => `${joined}&directions=${encodeURIComponent(direction)}`,
			''
		);
		url += status.reduce(
			(joined, statusEntity) => `${joined}&status=${encodeURIComponent(statusEntity)}`,
			''
		);
		url += connectionIds.reduce(
			(joined, connectionId) => `${joined}&connectionIds=${encodeURIComponent(connectionId)}`,
			''
		);
		url += starred.reduce(
			(joined, starredEntity) => `${joined}&starred=${encodeURIComponent(starredEntity)}`,
			''
		);
		url += directory.reduce(
			(joined, directoryEntity) => `${joined}&directories=${encodeURIComponent(directoryEntity)}`,
			''
		);

		url += read.reduce(
			(joined, readState) => `${joined}&read=${encodeURIComponent(readState)}`,
			''
		);

		url += labelIds.reduce(
			(joined, labelId) => `${joined}&labelIds=${encodeURIComponent(labelId.toString(10))}`,
			''
		);

		return this.get('/v3', url, {
			mimeType: 'text/csv',
		});
	};

	public updateHistoryEntries = (
		entries: {
			id: string;
			archived?: boolean;
			read?: boolean;
			starred?: boolean;
		}[]
	) => this.put('/v2', `/history`, entries);

	public updateHistoryEntry = (
		entryId: string,
		options: {
			archived?: boolean;
			read?: boolean;
			note?: string;
			starred?: boolean;
		}
	) => this.put('/v2', `/history/${entryId}`, options);

	public deleteHistoryEntries = (ids: string[]) =>
		this.delete(
			'/v2',
			`/history?${ids
				.map(encodeURIComponent)
				.map(encodedId => `id=${encodedId}`)
				.join('&')}`
		);

	public transcribeVoicemail = (eventId: string) =>
		this.post('/v3', `/events/${eventId}/transcribe`);

	public getEvents = () => this.get('/v2', '/app/events');

	public deleteEvent = (id: string) => this.delete('/v2', `/app/events/${id}`);

	public getAppProperties = () => this.get('/v2', '/app/properties');

	public setDeviceCallerId = (deviceId: string, callerId: string) =>
		this.put('/v2', `/devices/${deviceId}/callerid`, {
			value: callerId,
		});

	public getTariffAnnouncement = (deviceId: string) =>
		this.get('/v2', `/devices/${deviceId}/tariffannouncement`);

	public setTariffAnnouncement = (deviceId: string, enabled: boolean) =>
		this.put('/v2', `/devices/${deviceId}/tariffannouncement`, { enabled });

	public getAllNumbers = () => this.get('/v2', `/numbers`);

	public fetchSsoConfiguration(): Promise<ApiSsoConfiguration> {
		return this.get('/v3', '/sso-configuration/config');
	}

	public createSsoConfiguration(
		ssoConfiguration: SsoConfigurationCreateReduxAction
	): Promise<ApiSsoConfiguration> {
		return this.post('/v3', '/sso-configuration/config', ssoConfiguration);
	}

	public updateSsoConfiguration(
		ssoConfiguration: SsoConfigurationUpdateReduxAction
	): Promise<ApiSsoConfiguration> {
		return this.patch('/v3', '/sso-configuration/config', ssoConfiguration);
	}

	public getPortings = () => this.get('/v2', '/portings');

	public revokePorting = (portingId: string) => this.delete('/v2', `/portings/${portingId}`);

	public hangupCall = (sessionId: string) => this.delete('/v2', `/calls/${sessionId}`);

	public initiateClickToDial = (caller: string, callee: string) =>
		this.post('/v2', '/sessions/calls', {
			caller,
			callee,
		});

	public initiateClickToRecord = (deviceId: string, targetId: string) =>
		this.post('/v3', '/sessions/voicemail/recording', {
			deviceId,
			targetId,
		});

	public initiateClickToRecordIvr = (deviceId: string, targetId: string, endpoint: string) =>
		this.post('/v3', '/sessions/ivr/recording', {
			deviceId,
			targetId,
			endpoint,
		});

	public initiateClickToRecordCallthrough = (deviceId: string, targetId: string) =>
		this.post('/v3', '/sessions/callthrough/recording', {
			deviceId,
			targetId,
		});

	public initiateClickToPlay = (deviceId: string, datadId: string) =>
		this.post('/v2', '/sessions/voicemail/play', {
			deviceId,
			datadId,
		});

	public getPhonelineBlockAnonymous = (userId: string, phonelineId: string) =>
		this.get('/v2', `/${userId}/phonelines/${phonelineId}/blockanonymous`);

	public setPhonelineBlockAnonymous = (
		userId: string,
		phonelineId: string,
		enabled: boolean,
		target: string
	) =>
		this.put('/v2', `/${userId}/phonelines/${phonelineId}/blockanonymous`, {
			enabled,
			target,
		});

	public getContacts = (options?: { limit: number; offset: number }) => {
		const params = new URLSearchParams();

		if (options) {
			params.append('limit', options.limit.toString(10));
			params.append('offset', options.offset.toString(10));
		}

		return this.get('/v2', `/contacts?${params.toString()}`);
	};

	public getContactsByPhonenumbers = (phonenumbers: string[]) => {
		let path = '/contacts';

		if (Array.isArray(phonenumbers)) {
			path += `?${phonenumbers.map(phonenumber => `phonenumbers=${phonenumber}`).join('&')}`;
		}

		return this.get('/v2', path);
	};

	public deleteContact = (contactId: string) => this.delete('/v2', `/contacts/${contactId}`);

	public deleteContacts = (scopes: ContactScope[], contactIds: string[]) =>
		this.delete('/v2', '/contacts', {
			scope: scopes,
			source: 'web',
			contactIds,
		});

	public importContactsFromCSV = (base64Content: string) =>
		this.post('/v2', '/contacts/import/csv', { base64Content });

	public importContactsFromGoogle = (
		token: string,
		scope: ContactScope
	): Promise<{
		totalCount: number;
	}> => this.post('/v2', '/contacts/import/google', { token, scope });

	public getContact(contactId: string): Promise<ApiContact> {
		return this.get('/v2', `/contacts/${contactId}`);
	}

	public exportContactsToCSV = (scopes: ContactScope[]) => {
		const query = new URLSearchParams();
		scopes.forEach(s => query.append('scope', s));
		return this.get('/v2', `/contacts/csv?${query.toString()}`, { mimeType: 'text/csv' });
	};

	public exportContactsToVCard(scopes: ContactScope[], zipped: boolean) {
		const query = new URLSearchParams();
		scopes.forEach(s => query.append('scope', s));
		query.append('zipped', `${zipped}`);
		return this.get('/v3', `/contacts/vcard?${query.toString()}`, {
			mimeType: zipped ? 'application/zip' : 'text/vcard',
		});
	}

	public fetchSms = (userId: string) => this.get('/v2', `/${userId}/sms`);

	public createSms = (userId: string) => this.post('/v3', `/sms`, { userId });

	public deleteSms = (smsId: string) => this.delete('/v3', `/sms/${smsId}`);

	public setSmsAlias = (userId: string, smsId: string, alias: string) =>
		this.put('/v2', `/${userId}/sms/${smsId}`, { alias });

	public fetchSmsCallerIds = (userId: string, smsId: string) =>
		this.get('/v2', `/${userId}/sms/${smsId}/callerids`);

	public createSmsCallerId = (userId: string, smsId: string, phonenumber: string) =>
		this.post('/v2', `/${userId}/sms/${smsId}/callerids`, { phonenumber });

	public verifySmsCallerId = (
		userId: string,
		smsId: string,
		callerId: number,
		verificationCode: string
	) =>
		this.post('/v2', `/${userId}/sms/${smsId}/callerids/${callerId}/verification`, {
			verificationCode,
		});

	public setActiveSmsCallerId = (
		userId: string,
		smsId: string,
		callerId: number,
		defaultNumber: boolean
	) =>
		this.put('/v2', `/${userId}/sms/${smsId}/callerids/${callerId}`, {
			defaultNumber,
		});

	public sendFax = (
		faxlineId: string,
		recipient: string,
		filename: string,
		base64Content: string
	) =>
		this.post('/v2', '/sessions/fax', {
			faxlineId,
			recipient,
			filename,
			base64Content,
		});

	public resendFax = (faxlineId: string | undefined, faxId: string) =>
		this.post('/v2', '/sessions/fax/resend', {
			faxlineId,
			faxId,
		});

	public sendSms = (smsId: string, recipient: string, message: string, sendAt?: number) => {
		let options: { smsId: string; recipient: string; message: string; sendAt?: number } = {
			smsId,
			recipient,
			message,
		};

		if (typeof sendAt !== 'undefined') {
			options = { ...options, sendAt };
		}

		return this.post('/v2', '/sessions/sms', options);
	};

	public getIdentityVerification = (allowStart: boolean) => {
		let url = '/identityVerification';

		if (allowStart) {
			url += `?allowStart=true`;
		}

		return this.get('/v2', url);
	};

	public getIdentityVerificationBusinessTypes = (): Promise<ListResponse<ApiBusinessType>> => {
		return this.get('/v3', '/identity-verification/businesstype');
	};

	public startIdentityVerificationUpload = (
		businessTypeId: string,
		addressId: number,
		files: ApiBase64Upload[]
	): Promise<void> => {
		const request = { businessTypeId, addressId, files };
		return this.post('/v3', '/identity-verification/identity/verify', request);
	};

	public getIdentityVerificationForAddress = (
		addressId: string,
		limit = 10
	): Promise<ListResponse<ApiBusinessVerification>> =>
		this.get(
			'/v3',
			`/identity-verification/verification?filter=address.addressId:${addressId}&sort=created:DESC&limit=${limit}`
		);

	public getAccount = () => this.get('/v2', '/account');

	public getAccountStatement(
		periodStart: DateTime,
		periodEnd: DateTime,
		creditingTypeFilters: string[]
	): Promise<ApiAccountStatement> {
		const params = new URLSearchParams();
		params.append('periodStart', periodStart.toUTC().toString());
		params.append('periodEnd', periodEnd.toUTC().toString());
		creditingTypeFilters.forEach(type => params.append('creditingTypeFilter', type));
		return this.get('/v3', `/accounting/statements?${params.toString()}`);
	}

	public downloadAccountStatementEntries(
		periodStart: DateTime,
		periodEnd: DateTime,
		creditingTypeFilter: string[]
	): Promise<Blob> {
		const params = new URLSearchParams();
		params.append('periodStart', periodStart.toUTC().toString());
		params.append('periodEnd', periodEnd.toUTC().toString());
		creditingTypeFilter.forEach(type => params.append('creditingTypeFilter', type));
		return this.get('/v3', `/accounting/statements?${params.toString()}`, { mimeType: 'text/csv' });
	}

	public uploadLogo = (request: ApiBase64Upload): Promise<void> => {
		return this.post('/v3', `/account/logo`, { logo: request });
	};

	public getEventLifetime = (): Promise<ApiEventLifetime> => {
		return this.get('/v3', '/account/event-lifetime');
	};

	public setEventLifetime = (apiEventLifetime: ApiEventLifetime): Promise<void> => {
		return this.put('/v3', '/account/event-lifetime', apiEventLifetime);
	};

	public deleteLogo = () => this.delete('/v3', '/account/logo');

	public verifyAccount = (verificationCode: string) =>
		this.put('/v2', '/account/verified', { verificationCode });

	public getNotifications = (userId: string) => this.get('/v2', `/${userId}/notifications`);

	public deleteNotification = (userId: string, notificationId: string) =>
		this.delete('/v2', `/${userId}/notifications/${notificationId}`);

	public createVoicemailEmailNotification = (userId: string, voicemailId: string, email: string) =>
		this.post('/v2', `/${userId}/notifications/voicemail/email`, {
			voicemailId,
			email,
		});

	public createVoicemailSmsNotification = (userId: string, voicemailId: string, number: string) =>
		this.post('/v2', `/${userId}/notifications/voicemail/sms`, {
			voicemailId,
			number,
		});

	public createFaxEmailNotification = (
		userId: string,
		faxlineId: string,
		email: string,
		direction: string
	) =>
		this.post('/v2', `/${userId}/notifications/fax/email`, {
			faxlineId,
			email,
			direction,
		});

	public createFaxSmsNotification = (
		userId: string,
		faxlineId: string,
		number: string,
		direction: string
	) =>
		this.post('/v2', `/${userId}/notifications/fax/sms`, {
			faxlineId,
			number,
			direction,
		});

	public createCallEmailNotification = (
		userId: string,
		endpointId: string,
		email: string,
		direction: string,
		cause: string
	) =>
		this.post('/v2', `/${userId}/notifications/call/email`, {
			endpointId,
			email,
			direction,
			cause,
		});

	public createCallSmsNotification = (
		userId: string,
		endpointId: string,
		number: string,
		direction: string,
		cause: string
	) =>
		this.post('/v2', `/${userId}/notifications/call/sms`, {
			endpointId,
			number,
			direction,
			cause,
		});

	public createFaxReportNotification = (userId: string, faxlineId: string, email: string) =>
		this.post('/v2', `/${userId}/notifications/fax/report`, {
			faxlineId,
			email,
		});

	public createSmsEmailNotification = (userId: string, endpointId: string, email: string) =>
		this.post('/v2', `/${userId}/notifications/sms/email`, {
			endpointId,
			email,
		});

	public getUserNotifications = () => this.get('/v3', '/user-notifications');

	public fetchRestrictions = (userId?: string, restrictions?: string[]) => {
		const params = new URLSearchParams();

		if (typeof userId === 'string') {
			params.append('userId', userId);
		}

		if (Array.isArray(restrictions)) {
			for (const restriction of restrictions) {
				params.append('restriction', restriction);
			}
		}

		return this.get('/v2', `/restrictions?${params.toString()}`);
	};

	public getUserInfo = () => this.get('/v2', '/authorization/userinfo');

	public resetUserPassword = (username: string) => this.post('/v2', '/passwordreset', { username });

	public getTwoFactorAuthenticationSetting = async (webuserId: string) =>
		this.get(
			'/v3',
			`/authorization/two-factor-authentication/settings/${encodeURIComponent(webuserId)}`
		);

	public getLocalprefix = (deviceId: string) => this.get('/v2', `/devices/${deviceId}/localprefix`);

	public setLocalprefix = (deviceId: string, localprefix: string, active: boolean) =>
		this.put('/v2', `/devices/${deviceId}/localprefix`, {
			value: localprefix,
			active,
		});

	public isSimAllowed = (deviceId: string) => this.get('/v3', `/devices/${deviceId}/sim/allowed`);

	public activateSim = (
		userId: string,
		deviceId: string,
		simId: string,
		intendedUse: ApiSimIntendedUse,
		alias: string
	) =>
		this.post('/v3', `/devices/${deviceId}/sim`, {
			simId,
			intendedUse,
			alias,
		});

	public changeSim = (
		userId: string,
		deviceId: string,
		id: string,
		simId: string,
		intendedUse: ApiSimIntendedUse,
		alias: string
	) =>
		this.put('/v3', `/devices/${deviceId}/sim/${id}`, {
			simId,
			intendedUse,
			alias,
		});

	public setPendingSim = (
		deviceId: string,
		iccid: string,
		intendedUse: ApiSimIntendedUse,
		mobileExtension?: string,
		alias?: string
	) =>
		this.put('/v3', `/devices/${deviceId}/pending-sim`, {
			iccid,
			intendedUse,
			alias,
			mobileExtension,
		});

	public setIntendedUse = (
		deviceId: string,
		simExtensionId: string,
		intendedUse: ApiSimIntendedUse
	) =>
		this.put('/v3', `/devices/${deviceId}/sim/${simExtensionId}/intendedUse`, {
			value: intendedUse,
		});

	public deactivateSim = (userId: string, deviceId: string) =>
		this.delete('/v2', `/${userId}/devices/${deviceId}/sim`);

	public getContingents = (userId: string, deviceId: string) =>
		this.get('/v2', `/${userId}/devices/${deviceId}/contingents`);

	public getDataUsage = (
		containerId: string,
		dateStart: string,
		dateStop: string,
		intervalMinutes: string
	) =>
		this.get(
			'/v3',
			`/devices/${containerId}/contingents/usage?dateStart=${dateStart}&dateStop=${dateStop}&intervalMinutes=${intervalMinutes}`
		);

	public getDataUsageAggregation = (containerId: string, months: string[]) =>
		this.get(
			'/v3',
			`/devices/${containerId}/contingents/usage/aggregation?${months
				.map(month => `months=${month}`)
				.join('&')}`
		);

	public getDataUsageSettings = (containerId: string) =>
		this.get('/v3', `/devices/${containerId}/contingents/usage/settings`);

	public setDataUsageSettings = (containerId: string, persistenceLevel: string) =>
		this.put('/v3', `/devices/${containerId}/contingents/usage/settings`, {
			persistenceLevel,
		});

	public orderSim = (userId: string, deviceId: string, addressId: string) =>
		this.post('/v2', `/${userId}/devices/${deviceId}/sim/orders`, { addressId });

	public setSingleRowDisplay = (deviceId: string, enabled: boolean) =>
		this.put('/v2', `/devices/${deviceId}/singlerowdisplay`, { enabled });

	public setTargetNumberForExternalDevice = (deviceId: string, number: string) =>
		this.put('/v2', `/devices/${deviceId}/external/targetnumber`, { number });

	public setIncomingCallDisplayForExternalDevice = (
		deviceId: string,
		incomingCallDisplay: string
	) =>
		this.put('/v2', `/devices/${deviceId}/external/incomingcalldisplay`, {
			incomingCallDisplay,
		});

	public getGroups = () => {
		const url = '/groups';
		return this.get('/v3', url);
	};

	public getDomainVerifications = () => {
		const url = '/domain-verification/domains';
		return this.get('/v3', url);
	};

	public createDomainVerification(domain: string): Promise<ApiDomainState> {
		const url = '/domain-verification/domains';
		return this.post('/v3', url, { domain });
	}

	public getDomainVerification(domainId: string): Promise<ApiDomainState> {
		const url = `/domain-verification/domains/${domainId}`;
		return this.get('/v3', url);
	}

	public deleteDomainVerification(domainId: string): Promise<void> {
		const url = `/domain-verification/domains/${domainId}`;
		return this.delete('/v3', url);
	}

	public resetDomainVerification(domainId: string): Promise<void> {
		const url = `/domain-verification/domains/${domainId}`;
		return this.patch('/v3', url, { status: 'pending' });
	}

	public createGroup = (alias: string) => this.post('/v3', '/groups', { alias });

	public deleteGroup = (groupId: string) => this.delete('/v3', `/groups/${groupId}`);

	public setGroupAlias = (groupId: string, alias: string) =>
		this.put('/v3', `/groups/${groupId}/alias`, { value: alias });

	public setGroupMembers = (groupId: string, memberIds: string[]) =>
		this.put('/v3', `/groups/${groupId}/members`, { memberIds });

	public createGroupDevice = (groupId: string, deviceId: string) =>
		this.post('/v2', `/groups/${groupId}/devices`, { deviceId });

	public deleteGroupDevice = (groupId: string, deviceId: string) =>
		this.delete('/v2', `/groups/${groupId}/devices/${deviceId}`);

	public getGroupStatistics = (groupId: string) => this.get('/v3', `/groups/${groupId}/statistics`);

	public getGroupStatisticsHistory = (groupId: string) =>
		this.get('/v3', `/groups/${groupId}/statistics/history`);

	public getGroupStatisticsCsv = (groupId: string, month?: string) =>
		this.get('/v3', `/groups/${groupId}/statistics/export${month ? `?month=${month}` : ``}`, {
			mimeType: 'text/csv',
		});

	public bookGroupStatisticsContract = (groupId: string) =>
		this.post('/v3', `/groups/${groupId}/contracts/statistics`, { tacAccepted: true });

	public createGroupGreeting = (groupId: string) => this.post('/v3', `/groups/${groupId}/greeting`);

	public deleteGroupGreeting = (groupId: string) =>
		this.delete('/v3', `/groups/${groupId}/greeting`);

	public createCallQueue = (groupId: string) => this.post('/v3', `/groups/${groupId}/callqueue`);

	public deleteCallQueue = (groupId: string) => this.delete('/v3', `/groups/${groupId}/callqueue`);

	public validateQuickDialNumbers = (quickDialNumber: string) =>
		this.get('/v2', `/numbers/quickdial/validation/${quickDialNumber}`);

	public createQuickDialNumber = (userId: string, number: string) =>
		this.post('/v2', '/numbers/quickdial', {
			userId,
			number,
		});

	public setQuickDialNumber = (userId: string, numberId: string, number: string) =>
		this.put('/v2', `/numbers/quickdial/${numberId}`, {
			userId,
			number,
		});

	public deleteQuickDialNumber = (numberId: string) =>
		this.delete('/v2', `/numbers/quickdial/${numberId}`);

	public routeQuickDial = (numberId: string, endpointId: string | null) =>
		this.put('/v2', `/numbers/${numberId}`, {
			endpointId,
			releaseForMnp: false,
			quickDial: true,
		});

	public getIncomingBlacklistEntries = () => this.get('/v2', '/blacklist/incoming');

	public createIncomingBlacklistEntry = (phoneNumber: string, isBlock?: boolean) =>
		this.post('/v2', '/blacklist/incoming', {
			phoneNumber,
			isBlock: typeof isBlock === 'undefined' ? false : isBlock,
		});

	public deleteIncomingBlacklistEntry = (phoneNumber: string) =>
		this.delete('/v2', `/blacklist/incoming/${phoneNumber}`);

	public addProvisionedPhone = (macAddress: string, webuserId?: string, syncContacts?: boolean) =>
		this.post('/v2', '/phoneprovisioning', {
			macAddress,
			webuserId,
			syncContacts,
		});

	public deleteProvisionedPhone = (macAddress: string) =>
		this.delete('/v2', `/phoneprovisioning/${macAddress}`);

	public updateSnomConfig = (configuration: string, macAddress: string) =>
		this.put('/v2', '/phoneprovisioning/configuration', {
			configuration,
			macAddress,
		});

	public setSnomWebuser = (macAddress: string, webuserExtension: string | null) =>
		this.put('/v2', '/phoneprovisioning/webuser', {
			macAddress,
			webuserExtension,
		});

	public getProvisionedPhones = () => this.get('/v2', '/phoneprovisioning');

	public setSyncContacts = (macAddress: string, syncContacts: boolean) =>
		this.put('/v2', '/phoneprovisioning/sync-contacts', {
			macAddress,
			syncContacts,
		});

	public setAutoRecordingSetting = (deviceId: string, active: boolean) =>
		this.put('/v2', `/autorecordings/${deviceId}/settings`, { active });

	public createContact = (contact: CreateApiContact) => this.post('/v2', `/contacts`, contact);

	public prepareCSVContactImport = (withHeader: boolean, base64Content: string) =>
		this.post('/v3', '/contacts/csv/import/prepare', {
			withHeader,
			csvData: base64Content,
		});

	public importFromCachedCSV = (
		cacheKey: string,
		columns: string[],
		scope: string,
		withHeader: boolean
	): Promise<{
		contactsImported: number;
	}> =>
		this.post('/v3', '/contacts/csv/import', {
			cacheKey,
			columns,
			scope,
			withHeader,
		});

	public importContactsFromVCard = (
		scope: ContactScope,
		base64FileContent: string
	): Promise<{
		contactsImported: number;
	}> => {
		return this.post('/v3', '/contacts/vcard/import/file', {
			scope,
			base64FileContent,
		});
	};

	public updateContact = (contact: UpdateApiContact) =>
		this.put('/v2', `/contacts/${contact.id}`, contact);

	public createVoicemail = (ownerId: string) =>
		this.post('/v3', `/voicemails?ownerId=${encodeURIComponent(ownerId)}`);

	public getVoicemails = (userId?: string) => {
		const path = userId ? `/voicemails?userId=${encodeURIComponent(userId)}` : '/voicemails';

		return this.get('/v3', path);
	};

	public setVoicemailAlias = (voicemailId: string, alias: string) =>
		this.put('/v3', `/voicemails/${encodeURIComponent(voicemailId)}/alias`, { alias });

	public setVoicemailPin = (userId: string, voicemailId: string, pin: string) =>
		this.put('/v3', `/voicemails/${encodeURIComponent(voicemailId)}/pin`, {
			pin,
			userId,
		});

	public deleteVoicemailPin = (userId: string, voicemailId: string) =>
		this.delete('/v3', `/voicemails/${encodeURIComponent(voicemailId)}/pin`, { userId });

	public setVoicemailAuthorizedNumbers = (
		userId: string,
		voicemailId: string,
		authorizedNumbers: string[]
	) =>
		this.put('/v3', `/voicemails/${encodeURIComponent(voicemailId)}/authorizedNumbers`, {
			authorizedNumbers,
			userId,
		});

	public fetchLabels = () => this.get('/v3', '/labels');

	public attachLabel = (labelId: string, eventIds: string[]) =>
		this.post('/v3', '/events/batch-attach-label', {
			labelId,
			eventIds,
		});

	public detachLabel = (labelId: string, eventIds: string[]) =>
		this.post('/v3', '/events/batch-detach-label', {
			labelId,
			eventIds,
		});

	public createLabel = (name: string) => this.post('/v3', '/labels', { name });

	public editLabel = (id: number, newName: string) =>
		this.put('/v3', `/labels/${id}`, { name: newName });

	public deleteLabel = (id: number) => this.delete('/v3', `/labels/${id}`);

	public getLpaData = (deviceId: string) => {
		return this.get('/v3', `/devices/${deviceId}/esim`);
	};

	public getHolidayPresets = () => this.get('/v3', '/routing/date-based/presets');

	public getDateBasedSets = (extensionId: string) =>
		this.get('/v3', `/routing/${extensionId}/date-sets`);

	public getDateBasedForwarding = (extensionId: string, dateSetId: string) =>
		this.get('/v3', `/routing/${extensionId}/date-sets/${dateSetId}/forwarding`);

	public saveDateBasedSets = (extensionId: string, dateBasedSets: ApiDateBasedSet[]) =>
		this.put('/v3', `/routing/${extensionId}/date-sets`, {
			items: dateBasedSets,
		});

	public saveDateBasedForwarding = (
		extensionId: string,
		dateSetId: string,
		destination: VoicemailDestination
	) =>
		this.put('/v3', `/routing/${extensionId}/date-sets/${dateSetId}/forwarding`, {
			...destination,
		});

	public getTimeBasedSets = (extensionId: string) =>
		this.get('/v3', `/routing/${extensionId}/time-sets`);

	public getTimeBasedForwardings = (extensionId: string, timeSetId: string) =>
		this.get('/v3', `/routing/${extensionId}/time-sets/${timeSetId}/forwardings`);

	public saveTimeBasedSets = (extensionId: string, timeBasedSets: ApiTimeBasedSet[]) =>
		this.put('/v3', `/routing/${extensionId}/time-sets`, { items: timeBasedSets });

	public saveTimeBasedForwardings = (
		extensionId: string,
		timeSetId: string,
		forwardings: ApiTimeBasedForwardings
	) => this.put('/v3', `/routing/${extensionId}/time-sets/${timeSetId}/forwardings`, forwardings);

	public getProducts = (type?: ApiProductType) => this.get('/v3', `/products?type=${type}`);

	public getProduct = (productId: string) => this.get('/v3', `/products/${productId}`);

	public isProductAllowed = (productId: string) =>
		this.get('/v3', `/products/${productId}/allowed`)
			.then(() => true)
			.catch(e => {
				if (e instanceof ApiError && e.status === 402) {
					return false;
				}

				throw e;
			});

	public getProductOptions = (productId: string) =>
		this.get('/v3', `/products/${productId}/options`);

	/** DeviceProperties * */

	public getDeviceProperties = (deviceId: string) =>
		this.get('/v3', `/devices/${deviceId}/properties`);

	public getDeviceProperty = (deviceId: string, key: string) =>
		this.get('/v3', `/devices/${deviceId}/properties/${key}`);

	public setDeviceProperty = (property: DeviceProperty) =>
		this.put('/v3', `/devices/${property.deviceId}/properties`, {
			key: property.key,
			value: property.value,
		});

	public getPersonalAccessTokens = (webuserId: string) =>
		this.get('/v3', `/authorization/personal-access-token/${webuserId}`);

	public deletePersonalAccessToken = (tokenId: string) =>
		this.delete('/v3', `/authorization/personal-access-token/${tokenId}`);

	public createPersonalAccessToken = (webuserId: string, tokenName: string, scopes: string[]) =>
		this.post('/v3', `/authorization/personal-access-token/${webuserId}`, {
			tokenName,
			scopes,
		});

	public getScopes = () => this.get('/v3', `/authorization/scopes/`);

	public getDataAutomation = (containerId: string) =>
		this.get('/v3', `/devices/${containerId}/contingents/automatic`);

	public getDataAutomationHistory = (containerId: string) =>
		this.get('/v3', `/devices/${containerId}/contingents/automatic/history`);

	public setDataAutomation = (containerId: string, productId: number, limit?: number) =>
		this.put('/v3', `/devices/${containerId}/contingents/automatic`, {
			productId,
			limit,
		});

	public deleteDataAutomation = (containerId: string) =>
		this.delete('/v3', `/devices/${containerId}/contingents/automatic`);

	public getAccountCancellationStatus = () => this.get('/v3', '/account/cancellation');

	public cancelAccount = (holder: string, iban: string) =>
		this.post('/v3', '/account/cancellation', {
			holder,
			iban,
		});

	public revokeAccountCancellation = () => this.delete('/v3', '/account/cancellation');

	public getConferenceRoomProduct = () => this.get('/v3', '/conference-rooms/option');

	public getConferenceRooms = () => this.get('/v3', '/conference-rooms');

	public createConferenceRoom = (productId: string) =>
		this.post('/v3', '/conference-rooms', { productId });

	public cancelConferenceRoom = (conferenceRoomId: string) =>
		this.post('/v3', `/conference-rooms/${conferenceRoomId}/cancellation`);

	public revokeConferenceRoomCancellation = (conferenceRoomId: string) =>
		this.delete('/v3', `/conference-rooms/${conferenceRoomId}/cancellation`);

	public renameConferenceRoom = (conferenceRoomId: string, alias: string) =>
		this.put('/v3', `/conference-rooms/${conferenceRoomId}/alias`, {
			alias,
		});

	public deleteConferenceRoomGreeting = (conferenceRoomId: string) =>
		this.delete('/v3', `/conference-rooms/${conferenceRoomId}/greeting`);

	public setActiveAudioFileGreetingForConferenceRoom = (
		conferenceRoomId: string,
		audioFileId: string | null
	) => this.put('/v3', `/conference-rooms/${conferenceRoomId}/greeting`, { audioFileId });

	public getMobileInfo = () => this.get('/v3', '/mobile');

	public getMobileInfoMessages = (containerId: string) =>
		this.get('/v3', `/mobile/messages/${encodeURIComponent(containerId)}`);

	public getIvrs = () => this.get('/v3', '/ivr');

	public updatePassword = (webuserId: string, password: string) =>
		this.post('/v3', `/users/${webuserId}/password`, { password });

	public disablePassword = (webuserId: string, reason: string) =>
		this.delete('/v3', `/users/${webuserId}/password?reason=${reason}`);

	public getUsersAvatars = () => this.get('/v3', '/users/avatars');

	public getUserAvatarForWebuser = (webuserId: string) =>
		this.get('/v3', `/users/${webuserId}/avatars`);

	public getSipgateApps = () => this.get('/v3', `/apps`);

	public getSipgateApp = (webuserId: string) => this.get('/v3', `/apps/${webuserId}`);

	public getAppBookings = (bookingStatus: AppBookingStatus, webuserId?: string) => {
		if (webuserId) {
			const queryString = bookingStatus ? `?bookingStatus=${bookingStatus.toUpperCase()}` : '';
			return this.get('/v3', `/app/${webuserId}/bookings${queryString}`);
		}

		const queryString = bookingStatus ? `?bookingStatus=${bookingStatus.toUpperCase()}` : '';
		return this.get('/v3', `/app/bookings${queryString}`);
	};

	public createAppBookings = (webuserIds: Set<string>, subscriptionName: string) => {
		const bookingOrders = [...webuserIds].map(webuserId => ({
			userId: webuserId,
			subscriptionName,
		}));

		return this.post('/v3', `/app/bookings`, {
			tacAccepted: true,
			bookingOrders,
		});
	};

	public cancelAppBooking = (
		webuserId: string,
		bookingId: string,
		terminationDate: moment.Moment
	) => {
		return this.put('/v3', `/app/${webuserId}/bookings/${bookingId}`, {
			terminationDate: terminationDate.format('YYYY-MM-DD'),
		});
	};

	public cancelAppTrial = (webuserId: string, trialId: string, isTrialCancelled: boolean) => {
		return this.put('/v3', `/app/${webuserId}/bookings/${trialId}`, {
			isTrialCancelled,
		});
	};

	public revokeCancellation = (webuserId: string, bookingId: string) => {
		return this.put('/v3', `/app/${webuserId}/bookings/${bookingId}`, {
			terminationDate: null,
		});
	};

	public revokeTrialCancellation = (webuserId: string, bookingId: string) => {
		return this.put('/v3', `/app/${webuserId}/bookings/${bookingId}`, {
			isTrialCancelled: false,
		});
	};

	public upgradeAppSubscription = (webuserId: string, bookingId: string) => {
		return this.put('/v3', `/app/${webuserId}/bookings/${bookingId}`, {
			subscriptionName: 'PREMIUM',
		});
	};

	public getAppSubscriptionTypes = () => {
		return this.get('/v3', '/app/subscriptionTypes');
	};

	public getAppIntegrations = () => {
		return this.get('/v3', '/app/integrations');
	};

	public getConnectedIntegrations = () => {
		return this.get('/v3', '/app/connectedIntegrations');
	};

	public fetchAppNotification = () => {
		return this.get('/v3', `/app/notifications`);
	};

	public createAppNotification = () => {
		return this.post('/v3', `/app/notifications`);
	};

	public fetchJoyrides = () => {
		return this.get('/v3', `/joyrides`);
	};

	public createJoyride = (name: string) => {
		return this.post('/v3', `/joyrides`, { name });
	};

	public setFancyCodec = (deviceId: string, enabled: boolean) =>
		this.put('/v3', `/devices/${encodeURIComponent(deviceId)}/fancyCodec`, { enabled });

	public getAcds = () => this.get('/v3', '/acds');

	public createAcd = (name: string, phoneNumbers: string[], agentIds: string[]) =>
		this.post('/v3', '/acds', {
			name,
			phoneNumbers,
			agentIds,
		});

	public exportAcdStatistics = (acdId: string, startDate: DateTime, endDate: DateTime) =>
		this.get(
			'/v3',
			`/acds/${acdId}/statistics/history?startDate=${startDate.toISODate()}&endDate=${endDate.toISODate()}`,
			{
				mimeType: 'text/csv',
			}
		);

	public setAcdAgents = (acdId: string, agentIds: string[]) =>
		this.put('/v3', `/acds/${acdId}/agents`, { agentIds });

	public setAcdAgentDevices = (acdId: string, agentId: string, deviceIds: string[]) =>
		this.put('/v3', `/acds/${acdId}/agents/${agentId}/devices`, { deviceIds });

	public setAcdName = (acdId: string, name: string) =>
		this.put('/v3', `/acds/${acdId}/name`, { name });

	public deleteAcd = (acdId: string) => this.delete('/v3', `/acds/${acdId}`);

	public setAcdQueueWaitingAudioFile = (acdId: string, audioFileId: string | null) =>
		this.put('/v3', `/acds/${acdId}/settings/queue/waitingAudioFile`, { audioFileId });

	public setAcdGreetingAudioFile = (acdId: string, audioFileId: string | null) =>
		this.put('/v3', `/acds/${acdId}/settings/greetingAudioFile`, { audioFileId });

	public setAcdFollowUpTime = (acdId: string, followUpTime: number) =>
		this.put('/v3', `/acds/${acdId}/settings/agents/followUpTime`, { followUpTime });

	public setAcdRingTime = (acdId: string, ringTime: number) =>
		this.put('/v3', `/acds/${acdId}/settings/agents/ringTime`, { ringTime });

	public setAcdRingingOrder = (acdId: string, ringingOrder: ApiRingingOrderSettings) =>
		this.put('/v3', `/acds/${acdId}/settings/ringingOrder`, ringingOrder);

	public getAcdAudioFiles = (acdId: string) => this.get('/v3', `/acds/${acdId}/audioFiles`);

	public createAcdAudioFile = (
		acdId: string,
		fileName: string,
		base64Content: string,
		type: AcdAudioFileType
	) =>
		this.post('/v3', `/acds/${acdId}/audioFiles`, {
			fileName,
			base64Content,
			type,
		});

	public setAcdAudioFileAlias = (acdId: string, audioFileId: string, alias: string) =>
		this.put('/v3', `/acds/${acdId}/audioFiles/${encodeURIComponent(audioFileId)}/alias`, {
			alias,
		});

	public deleteAcdAudioFile = (acdId: string, audioFileId: string) =>
		this.delete('/v3', `/acds/${acdId}/audioFiles/${encodeURIComponent(audioFileId)}`);

	public getAcdAudioFileContent = (acdId: string, audioFileId: string) =>
		this.get('/v3', `/acds/${acdId}/audioFiles/${audioFileId}`, { mimeType: 'audio/mpeg' });

	public getAcdLiveStatistics = (acdId: string) => this.get('/v3', `/acds/${acdId}/statistics`);

	public setAcdStatisticsAvailabilityTarget = (acdId: string, target: number) =>
		this.put('/v3', `/acds/${acdId}/statistics/targets/availability`, { target });

	public setAcdStatisticsCallPercentageInTimeLimitTarget = (
		acdId: string,
		timeLimit: number,
		target: number
	) =>
		this.put('/v3', `/acds/${acdId}/statistics/targets/callPercentageInTimeLimit`, {
			timeLimit,
			target,
		});

	public setAcdStatisticsAverageHandlingTimeTarget = (acdId: string, target: number) =>
		this.put('/v3', `/acds/${acdId}/statistics/targets/averageHandlingTime`, { target });

	public toggleAgentStatistics = (acdId: string, enabled: boolean) =>
		this.put('/v3', `/acds/${acdId}/statistics/settings/agentStatistics`, { enabled });

	public setAcdTeamLeads = (acdId: string, teamLeadIds: string[]) =>
		this.put('/v3', `/acds/${acdId}/teamLeads`, { teamLeadIds });

	public activateCallRecorder = () => this.post('/v3', '/call-recorder');

	public deactivateCallRecorder = () => this.delete('/v3', '/call-recorder');

	public uploadUserAvatar = (webuserId: string, request: ApiBase64Upload): Promise<void> => {
		return this.post('/v3', `/users/${webuserId}/avatars`, { avatar: request });
	};

	public deleteUserAvatar = (webuserId: string): Promise<void> => {
		return this.delete('/v3', `/users/${webuserId}/avatars`);
	};

	public getTransferAudioFiles = () => {
		return this.get('/v3', `/audioFiles/account/transfer`);
	};

	public createTransferAudioFile = (fileName: string, base64Content: string) =>
		this.post('/v3', `/audioFiles/account/transfer`, {
			fileName,
			base64Content,
		});

	public setActiveTransferAudioFile = (audioFileId: string | null) =>
		this.put('/v3', `/audioFiles/account/transfer`, { audioFileId });

	public getCallRecordingAudioFiles = () => {
		return this.get('/v3', `/audioFiles/account/callRecording`);
	};

	public setActiveCallRecordingAudioFile = (audioFileId: string | null) =>
		this.put('/v3', `/audioFiles/account/callRecording`, { audioFileId });

	public createCallRecordingAudioFile = (fileName: string, base64Content: string) =>
		this.post('/v3', `/audioFiles/account/callRecording`, {
			fileName,
			base64Content,
		});

	public orderMultipleSim = (
		amount: number,
		shippingAddress: {
			salutation: string;
			firstname: string;
			lastname: string;
			company: string;
			areaCode: string;
			street: string;
			number: string;
			zip: string;
			city: string;
			countryCode: string;
		}
	) =>
		this.post('/v3', '/simcards', {
			amount,
			shippingAddress,
		});

	public createUserNotification = (createUserNotificationRequest: CreateUserNotificationRequest) =>
		this.post('/v3', `/user-notifications`, createUserNotificationRequest);

	public replaceUserNotification = (
		replaceUserNotificationRequest: ReplaceUserNotificationRequest
	) => this.put('/v3', `/user-notifications`, replaceUserNotificationRequest);

	public deleteUserNotification = (notificationId: string) =>
		this.delete('/v3', `/user-notifications/${notificationId}`);

	public getBaseContractOptions = () => {
		return this.get('/v3', '/base-contract/options');
	};

	public getBaseContractCostSummary = (optionId: string) => {
		return this.get('/v3', `/base-contract/cost-summary/${optionId}`);
	};

	public getBaseContract = () => {
		return this.get('/v3', '/base-contract');
	};

	public bookBaseContract = (optionId: string) => {
		return this.post('/v3', '/base-contract', { optionId });
	};

	public changeBaseContract = (optionId: string) => {
		return this.post('/v3', '/base-contract/change', { optionId });
	};

	public revokeBaseContractChange = () => {
		return this.delete('/v3', '/base-contract/change');
	};

	public revokeBaseContractCancellation = () => {
		return this.delete('/v3', '/base-contract/cancellation');
	};

	public cancelBaseContract = (cancelDependentPhoneNumberContracts?: boolean) => {
		return this.post('/v3', '/base-contract/cancellation', { cancelDependentPhoneNumberContracts });
	};

	public setSipgateIoSettings = (settings: {
		incomingUrl: string;
		outgoingUrl: string;
		log: boolean;
		whitelist: null | string[];
	}) => this.put('/v2', '/settings/sipgateio', settings);

	public getSipgateIoLogs = () => this.get('/v2', '/log/webhooks');

	public getSipgateIo = () => this.get('/v3', '/sipgateio');

	public getSipgateIoOptions = () => this.get('/v3', '/sipgateio/options');

	public bookSipgateIo = (optionId: string) =>
		this.post('/v3', '/sipgateio', {
			optionId,
		});

	public cancelSipgateIo = () => this.post('/v3', '/sipgateio/cancellation');

	public revokeSipgateIoCancellation = () => this.delete('/v3', '/sipgateio/cancellation');

	public getPhonenumbers = () => this.get('/v3', `/phone-numbers`);

	public releaseMobilePhonenumber = (e164Numbers: string[]) =>
		this.put('/v3', '/phone-numbers/mobile/opt-in', { e164Numbers });

	public getPhonenumberProducts = () => this.get('/v3', '/phone-number-bookings/options');

	public getPhonenumberCountryAreaCodes = (countryCodeA3: 'GBR') =>
		this.get('/v3', `/phone-number-bookings/area-codes/${encodeURIComponent(countryCodeA3)}`);

	public getGermanLandlinePhonenumberSuggestions = (productId: string, addressId: string) =>
		this.get(
			'/v3',
			`/phone-number-bookings/suggestions/landline/de/${encodeURIComponent(productId)}/${encodeURIComponent(addressId)}`
		);

	public getUKLandlinePhonenumberSuggestions = (
		productId: string,
		addressId: string,
		areaCode: string
	) =>
		this.get(
			'/v3',
			`/phone-number-bookings/suggestions/landline/uk/${encodeURIComponent(productId)}/${encodeURIComponent(addressId)}/${encodeURIComponent(areaCode)}`
		);

	public getGermanMobilePhonenumberSuggestions = (productId: string) =>
		this.get(
			'/v3',
			`/phone-number-bookings/suggestions/mobile/de/${encodeURIComponent(productId)}`
		);

	public getProlongablePhonenumbers = () =>
		this.get('/v3', '/phone-number-bookings/prolongable-numbers');

	public bookGermanLandlinePhonenumbers = (
		productId: string,
		addressId: string,
		numbers: ApiPhoneNumberSuggestionNumbers[]
	) =>
		this.post('/v3', '/phone-number-bookings/contracts/landline/de', {
			productId,
			addressId,
			numbers,
		});

	public bookUKLandlinePhonenumbers = (
		productId: string,
		addressId: string,
		areaCode: string,
		number: ApiPhoneNumberSuggestionNumbers
	) =>
		this.post('/v3', '/phone-number-bookings/contracts/landline/uk', {
			productId,
			addressId,
			areaCode,
			number,
		});

	public bookProlongation = (productId: string, e164Number: string) =>
		this.post('/v3', '/phone-number-bookings/contracts/prolongation', {
			productId,
			e164Number,
		});

	public bookGermanMobilePhonenumbers = (
		productId: string,
		numbers: ApiPhoneNumberSuggestionNumbers[]
	) =>
		this.post('/v3', '/phone-number-bookings/contracts/mobile/de', {
			productId,
			numbers,
		});

	public getCountriesForInternationalPhonenumbers = () =>
		this.get('/v3', '/phone-number-bookings/international/countries');

	public getAreasForInternationalPhonenumbers = (countryId: string) =>
		this.get(
			'/v3',
			`/phone-number-bookings/international/countries/${encodeURIComponent(countryId)}`
		);

	public getAreasForInternationalPhonenumbersByCountryAndState = (
		countryId: string,
		stateId: string
	) =>
		this.get(
			'/v3',
			`/phone-number-bookings/international/countries/${encodeURIComponent(countryId)}/${encodeURIComponent(stateId)}`
		);

	public bookInternationalPhonenumber = (
		productId: string,
		countryCodeA3: string,
		didGroupId: string
	) =>
		this.post('/v3', '/phone-number-bookings/contracts/international', {
			productId,
			countryCodeA3,
			didGroupId,
		});

	public setRoutings = (e164Numbers: string[], extensionId: string) =>
		this.put('/v3', '/routings', {
			e164Numbers,
			extension: extensionId,
		});

	public setChannelRoutings = (e164Numbers: string[], channelId: string) =>
		this.put('/v3', '/routings/channel', {
			e164Numbers,
			channelId,
		});

	public deleteRoutings = (e164Numbers: string[]) =>
		this.delete('/v3', '/routings', {
			e164Numbers,
		});

	public cancelPhonenumberContract = (contractId: string) =>
		this.post(
			'/v3',
			`/phone-number-bookings/contracts/${encodeURIComponent(contractId)}/cancellation`
		);

	public revokePhonenumberContractCancellation = (contractId: string) =>
		this.delete(
			'/v3',
			`/phone-number-bookings/contracts/${encodeURIComponent(contractId)}/cancellation`
		);

	public fetchItemizedBillingEntries = (
		periodStart: string,
		periodEnd: string,
		direction: 'OUTBOUND' | 'INBOUND' = 'OUTBOUND',
		types?: ItemizedBillEntryTypeOption[],
		owners?: string[]
	): Promise<ApiItemizedBillResult> =>
		this.get(
			'/v3',
			`/accounting/itemized-billing?direction=${direction}&periodStart=${periodStart}&periodEnd=${periodEnd}${types ? `&types=${types.join(',')}` : ''}${owners ? `&owners=${owners.join(',')}` : ''}`
		);

	public downloadItemizedBillingEntries = (
		periodStart: string,
		periodEnd: string,
		direction: 'OUTBOUND' | 'INBOUND' = 'OUTBOUND',
		types?: ItemizedBillEntryTypeOption[],
		owners?: string[]
	): Promise<Blob> => {
		return this.get(
			'/v3',
			`/accounting/itemized-billing?direction=${direction}&periodStart=${periodStart}&periodEnd=${periodEnd}${types ? `&types=${types.join(',')}` : ''}${owners ? `&owners=${owners.join(',')}` : ''}`,
			{ mimeType: 'text/csv' }
		);
	};

	public getItemizedBillingSettings(): Promise<ApiItemizedBillingSettings> {
		return this.get('/v3', '/accounting/itemized-billing/settings');
	}

	public setItemizedBillingSettings(settings: ApiItemizedBillingSettings): Promise<void> {
		return this.post('/v3', '/accounting/itemized-billing/settings', settings);
	}

	public getBillingFlags(): Promise<ApiBillingFlags> {
		return this.get('/v3', '/accounting/billing/flags');
	}

	public getApiClients = () => this.get('/v2', `/authorization/oauth2/clients`);

	public createApiClient = (apiClient: {
		name: string;
		description: string;
		privacyUrl: string;
		termsUrl: string;
	}) => this.post('/v2', `/authorization/oauth2/clients`, apiClient);

	public updateApiClient = (
		apiClientId: string,
		apiClient: {
			name: string;
			description: string;
			privacyUrl: string;
			termsUrl: string;
			redirectUris: string[];
			webOrigins: string[];
		}
	) =>
		this.put('/v2', `/authorization/oauth2/clients/${encodeURIComponent(apiClientId)}`, apiClient);

	public deleteApiClient = (apiClientId: string) =>
		this.delete('/v2', `/authorization/oauth2/clients/${encodeURIComponent(apiClientId)}`);

	private get(
		version: Version,
		path: string,
		options: {
			mimeType?: string;
			unauthenticated?: boolean;
		} = {}
	) {
		return this.http.get(version + path, options);
	}

	private delete(version: Version, path: string, data?: unknown) {
		return this.http.delete(version + path, data);
	}

	private post(version: Version, path: string, data?: unknown) {
		return this.http.post(version + path, data);
	}

	private put(version: Version, path: string, data?: unknown) {
		return this.http.put(version + path, data);
	}

	private patch(version: Version, path: string, data?: unknown) {
		return this.http.patch(version + path, data);
	}

	private getDataDStartAndEndDateParameterString = (
		startDate?: Moment | null,
		endDate?: Moment | null
	) => {
		const formattedDates = this.formatStartAndEndDateForDataD(startDate, endDate);
		let url = '';

		if (typeof formattedDates.from !== 'undefined') {
			url += `&from=${encodeURIComponent(formattedDates.from)}`;
		}
		if (typeof formattedDates.to !== 'undefined') {
			url += `&to=${encodeURIComponent(formattedDates.to)}`;
		}

		return url;
	};

	private formatStartAndEndDateForDataD = (from?: Moment | null, to?: Moment | null) => {
		let beginningOfStartDay;
		let endOfEndDay;

		if (from && typeof from !== 'undefined') {
			beginningOfStartDay = from
				.clone()
				/* datepicker defaults to noon (12:00) */
				.hours(0)
				.format('YYYY-MM-DDTHH:mm:ssZ');

			if (to && typeof to !== 'undefined') {
				endOfEndDay = to
					.clone()
					/* datepicker defaults to noon (12:00) */
					.hours(23)
					.minutes(59)
					.seconds(59)
					.format('YYYY-MM-DDTHH:mm:ssZ');
			} else {
				/* User picked a single day so we use the end of that day */
				endOfEndDay = from
					.clone()
					/* datepicker defaults to noon (12:00) */
					.hours(0)
					.add(1, 'day')
					.format('YYYY-MM-DDTHH:mm:ssZ');
			}
		}

		return {
			from: beginningOfStartDay,
			to: endOfEndDay,
		};
	};
}
