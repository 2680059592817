import { createSlice } from '@reduxjs/toolkit';
import { CallRecorderState } from './types';
import { activateCallRecorder, deactivateCallRecorder, setCallRecorderStatus } from './actions';

export const initialState: CallRecorderState = {
	active: false,
};

const slice = createSlice({
	name: 'callRecorder',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(setCallRecorderStatus, (_, { payload }) => ({
			active: payload,
		}));
		builder.addCase(activateCallRecorder.pending, _ => ({
			active: true,
		}));
		builder.addCase(deactivateCallRecorder.pending, _ => ({
			active: false,
		}));
	},
});

export const reducer = slice.reducer;
