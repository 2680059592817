import {
	ManagedFieldValidator,
	chainValidators,
	validateMaxLength,
	validateNonEmpty,
} from '@web-apps/forms';
import { Label } from './types';
import { Translate } from '../../slices/translations';

const systemLabels = [
	'inbox',
	'archive',
	'starred',
	'sent',
	'spam',
	'pending',
	'sending',
	'trash',
	'failed',
	'scheduled',
];

export const labelIsSystemLabel = (name: string) => systemLabels.includes(name.toLowerCase());

export const labelContainsInvalidCharacters = (name: string) =>
	name !== '' &&
	(name.trim() === '' ||
		name.includes('^') ||
		name.includes('$') ||
		/^[\p{L}\p{Z}\p{N}]+$/gu.exec(name) === null);

export const validateLabel = (translate: Translate, existingLabels: Label[]) =>
	chainValidators(
		validateNonEmpty(translate),
		validateMaxLength(translate, 24),
		(value: string) => {
			if (labelContainsInvalidCharacters(value)) {
				return { valid: false, error: translate('LABEL_CREATE_INVALID_CHARACTER_ERROR') };
			}

			if (existingLabels.find(label => label.name.toLowerCase() === value.toLowerCase())) {
				return { valid: false, error: translate('LABEL_CREATE_EXISTS_ERROR') };
			}

			if (labelIsSystemLabel(value)) {
				return { valid: false, error: translate('LABEL_CREATE_SYSTEM_LABEL_ERROR') };
			}

			return { valid: true, value };
		}
	);

export const validateCommaSeparatedLabels =
	(translate: Translate, existingLabels: Label[]): ManagedFieldValidator<string, string[]> =>
	(input: string) => {
		if (input.length === 0) {
			return {
				valid: true,
				value: [],
			};
		}

		const labels = input
			.split(',')
			.map(f => f.trim())
			.filter(f => f.length > 0);

		for (const label of labels) {
			const validation = validateLabel(translate, existingLabels)(label, {});

			if (!validation.valid) {
				return validation;
			}
		}

		return {
			valid: true,
			value: labels,
		};
	};
