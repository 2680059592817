import { useEffect, useMemo, useState } from 'react';
import { SliceData, useAction } from '../..';
import api from '../../../api';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { useAutoCreditingSettings } from '../payment';
import {
	fetchConferenceRooms,
	createConferenceRoom,
	forceFetchConferenceRooms,
	renameConferenceRoom,
	cancelConferenceRoom,
	revokeConferenceRoomCancellation,
	deleteGreeting,
	setActiveAudioFileGreeting,
} from './actions';
import { ConferenceRoom, ConferenceRoomProduct } from './types';
import { hasRestriction, useRestrictions } from '../../modules/restrictions';

export const useConferenceRoomProduct = (): SliceData<{
	product: ConferenceRoomProduct;
	isAllowed: boolean;
}> => {
	const [product, setProduct] = useState<{
		product: ConferenceRoomProduct;
		isAllowed: boolean;
	} | null>(null);

	const autoCreditingSettings = useAutoCreditingSettings();

	useEffect(() => {
		if (!autoCreditingSettings.data) {
			setProduct(null);
		}

		api.getConferenceRoomProduct().then(async option =>
			setProduct(
				option
					? {
							product: {
								id: option.id,
								cost: {
									amount: {
										amount: option.price.amount,
										currency: option.price.currency,
										fraction: 10_000,
									},
									interval: 'monthly',
									isNetto: option.price.net,
								},
							},
							isAllowed: await api.isProductAllowed(option.id),
						}
					: null
			)
		);
		// Kinda ugly, but we need to refetch isProductAllowed() in case our auto crediting got changed
	}, [autoCreditingSettings.data]);

	return useMemo(() => {
		if (!autoCreditingSettings.fetched || !product) {
			return { fetched: false, data: null };
		}

		return { fetched: true, data: product };
	}, [product, autoCreditingSettings.fetched]);
};

export const useConferenceRooms = (): SliceData<ConferenceRoom[]> => {
	const restrictions = useRestrictions(['CAN_USE_CONFERENCE_ROOMS']);
	const canUseConferenceRooms = hasRestriction(restrictions.data, 'CAN_USE_CONFERENCE_ROOMS');
	const dispatch = useDispatch();
	const conferenceRooms = useSelector(state => state.conferenceRoom);

	useEffect(() => {
		if (canUseConferenceRooms) {
			dispatch(fetchConferenceRooms());
		}
	}, [canUseConferenceRooms, dispatch]);

	return useMemo(() => {
		if (!restrictions.fetched) {
			return {
				fetched: false,
				data: null,
			};
		}

		if (!canUseConferenceRooms) {
			return {
				fetched: true,
				data: [],
			};
		}

		return conferenceRooms.fetched
			? {
					fetched: true,
					data: conferenceRooms.items,
				}
			: {
					fetched: false,
					data: null,
				};
	}, [restrictions, canUseConferenceRooms, conferenceRooms]);
};

export const useConferenceRoomActions = () => ({
	createConferenceRoom: useAction(createConferenceRoom),
	forceFetchConferenceRooms: useAction(forceFetchConferenceRooms),
	renameConferenceRoom: useAction(renameConferenceRoom),
	cancelConferenceRoom: useAction(cancelConferenceRoom),
	revokeConferenceRoomCancellation: useAction(revokeConferenceRoomCancellation),
	deleteGreeting: useAction(deleteGreeting),
	setActiveAudioFileGreeting: useAction(setActiveAudioFileGreeting),
});
