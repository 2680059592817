import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { FocusTrapProvider } from '@web-apps/focus-trap';
import { Provider as StoreProvider } from './redux';
import { Root } from './routes/Root';
import { createStore } from './redux/store';
import { startPollingService } from './poll-service';
import { ErrorHandler } from './helpers/ErrorHandler';
import { prepareGlobalInstanaFunction } from './third-party/instana';
import PandaProvider from './helpers/PandaProvider';

// Make sure styles are loaded
import './styles/app.scss';

import '@panda/ui';
import { BotifyProvider } from './botify/BotifyContext';

startPollingService();
prepareGlobalInstanaFunction();

const store = createStore();

createRoot(document.getElementById('root')!).render(
	<ErrorHandler>
		<div style={{ height: '100%' }}>
			<StoreProvider store={store}>
				<BotifyProvider>
					<FocusTrapProvider>
						<PandaProvider>
							<BrowserRouter>
								<Root />
							</BrowserRouter>
						</PandaProvider>
					</FocusTrapProvider>
				</BotifyProvider>
			</StoreProvider>
		</div>
	</ErrorHandler>
);
