import { State } from '../state';
import { UpdateContactsAction } from './types';
import { normalizeEvents } from '../../normalize/events';

export const updateContactsReducer = (state: State, action: UpdateContactsAction): State => {
	if (!state.entries.result) {
		return {
			...state,
			contacts: action.contacts,
		};
	}

	return {
		...state,
		contacts: action.contacts,

		entries: {
			...state.entries,

			result: {
				...state.entries.result,

				normalized: normalizeEvents(
					state.entries.result.normalized.map(event => ({
						originalEvent: event.originalEvent,
						selected: event.selected,
					})),
					action.contacts,
					state.extensions,
					state.blocklist,
					state.numbers,
					state.domain
				),
			},
		},
	};
};
