import { isPending, isFulfilled, isRejected } from '@reduxjs/toolkit';
import { BEGIN, COMMIT, REVERT } from 'redux-optimist';

// eslint-disable-next-line import/no-unresolved
import { UnknownAsyncThunkAction } from '@reduxjs/toolkit/dist/matchers';

export type OptimistAction<Action> = Action & { optimist: { type: string; id: string } };

export type RtkOptimistMiddlewareNext<Action> = Action extends UnknownAsyncThunkAction
	? OptimistAction<Action>
	: Action;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type RtkOptimistMiddlewareResult<Action, NextResult> = NextResult;

export type RtkOptimistMiddlewareEmits = never;

export default () => {
	return <Action, NextResult>(
			next: (action: RtkOptimistMiddlewareNext<Action> | Action) => NextResult
		) =>
		(action: Action): RtkOptimistMiddlewareResult<Action, NextResult> => {
			if (isPending(action)) {
				return next({
					...action,
					optimist: {
						type: BEGIN,
						id: `rtk-${action.meta.requestId}`,
					},
				});
			}

			if (isFulfilled(action)) {
				return next({
					...action,
					optimist: {
						type: COMMIT,
						id: `rtk-${action.meta.requestId}`,
					},
				});
			}

			if (isRejected(action)) {
				return next({
					...action,
					optimist: {
						type: REVERT,
						id: `rtk-${action.meta.requestId}`,
					},
				});
			}

			return next(action);
		};
};
