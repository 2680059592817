import { createAction } from '../..';
import api from '../../../api';
import {
	ApiIvrForwarding,
	ApiIvrForwardingFallback,
	ApiIvrForwardingKey,
	ApiIvrForwardingMain,
	ApiIvrKeyForwadingTargetType,
} from '../../../api/types/ivr';
import { ReduxState } from '../../types';

const shouldFetchIvrs = (state: ReduxState) => !state.ivrs.fetched && !state.ivrs.fetching;

export const fetchIvrs = createAction('IVRS_FETCH', () => ({
	promise: () => api.getIvrs(),
	shouldFetch: shouldFetchIvrs,
}));
createAction('IVRS_FETCH_ANNOUNCEMENTS', () => ({
	promise: () => api.getIvrs(),
	shouldFetch: shouldFetchIvrs,
}));
export const forceFetchIvrs = createAction('IVRS_FETCH', () => ({
	promise: () => api.getIvrs(),
}));

export const createIvr = createAction('IVR_CREATE', ({ alias }: { alias: string }) => ({
	promise: () => api.createIvr(alias),
	restrictions: {
		restrictions: ['CAN_CREATE_IVR'],
	},
}));

export const assignPhoneNumbersToIvr = createAction(
	'IVR_ASSIGN_PHONE_NUMBERS',
	({ ivrId, phoneNumbers }: { ivrId: string; phoneNumbers: string[] }) => ({
		promise: () => api.assignPhoneNumbersToIvr(ivrId, phoneNumbers),

		data: {
			id: ivrId,
			phoneNumbers,
		},
	})
);

export const assignAudioFileToIvr = createAction(
	'IVR_ASSIGN_AUDIO_FILE',
	({
		ivrId,
		audioFileId,
		endpoint,
	}: {
		ivrId: string;
		audioFileId: string;
		endpoint: ApiIvrForwarding;
	}) => ({
		promise: () => api.assignAudioFileToIvr(ivrId, audioFileId, endpoint),

		data: {
			id: ivrId,
			audioFileId,
			endpoint,
		},
	})
);

export const renameIVR = createAction(
	'IVR_RENAME',
	({ ivrId, alias }: { ivrId: string; alias: string }) => ({
		promise: () => api.renameIvr(ivrId, alias),

		data: {
			id: ivrId,
			alias,
		},
	})
);

export const deleteIVR = createAction('IVR_DELETE', ({ ivrId }: { ivrId: string }) => ({
	promise: () => api.deleteIvr(ivrId),
	data: {
		id: ivrId,
	},
}));

export const setIvrKeyMapping = createAction(
	'IVR_UPDATE_KEY_MAPPING',
	(
		props:
			| {
					ivrId: string;
					forwardingKey: ApiIvrForwardingKey | ApiIvrForwardingMain;
					value: string;
					type: ApiIvrKeyForwadingTargetType;
			  }
			| {
					ivrId: string;
					forwardingKey: ApiIvrForwardingFallback;
					value: string;
					type: ApiIvrKeyForwadingTargetType;
					loopCount: number;
			  }
	) => ({
		promise: () => {
			const { ivrId, forwardingKey, value, type } = props;
			if (forwardingKey === 'FALLBACK') {
				return api.updateIvrKeyForwardingMapping(
					ivrId,
					forwardingKey,
					value,
					type,
					props.loopCount
				);
			}
			return api.updateIvrKeyForwardingMapping(ivrId, forwardingKey, value, type);
		},
		data: {
			ivrId: props.ivrId,
			forwardingKey: props.forwardingKey,
			value: props.value,
			type: props.type,
			loopCount: props.forwardingKey === 'FALLBACK' ? props.loopCount : undefined,
		},
	})
);

export const setIvrFallbackMapping = createAction(
	'IVR_UPDATE_FALLBACK_MAPPING',
	({
		ivrId,
		value,
		type,
		loopCount,
	}: {
		ivrId: string;
		value: string;
		type: ApiIvrKeyForwadingTargetType;
		loopCount: number;
	}) => ({
		promise: () => api.updateIvrFallbackMapping(ivrId, value, type, loopCount),
		data: {
			ivrId,
			value,
			type,
			loopCount,
		},
	})
);

export const deleteIvrKeyMapping = createAction(
	'IVR_DELETE_KEY_MAPPING',
	({ ivrId, forwardingKey }: { ivrId: string; forwardingKey: ApiIvrForwarding }) => ({
		promise: () => api.deleteIvrKeyForwardingMapping(ivrId, forwardingKey),
		data: {
			ivrId,
			forwardingKey,
		},
	})
);
