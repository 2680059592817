import { createAction } from '../..';

import api from '../../../api';
import { ReduxState } from '../../types';

export const fetchFaxlines = createAction('FAXLINES_FETCH', () => ({
	promise: () => api.getFaxlines(),
	shouldFetch: (state: ReduxState) => !state.faxlines.fetched && !state.faxlines.fetching,
}));

export const forceFetchFaxlines = createAction('FAXLINES_FETCH', () => ({
	promise: () => api.getFaxlines(),
}));

export const setFaxlineAlias = createAction(
	'FAXLINE_SET_ALIAS',
	(faxlineId: string, alias: string) => ({
		promise: () => api.setFaxlineAlias(faxlineId, alias),
		data: {
			faxlineId,
			alias,
		},
	})
);

export const setFaxlineTagline = createAction(
	'FAXLINE_SET_TAGLINE',
	(faxlineId: string, tagline: string) => ({
		promise: () => api.setFaxlineTagline(faxlineId, tagline),
		data: {
			faxlineId,
			tagline,
		},
	})
);

export const createFaxline = createAction('FAXLINES_CREATE', (ownerId: string, alias: string) => ({
	promise: api.createFaxline(ownerId, alias),
	restrictions: {
		ownerId,
		restrictions: ['CAN_CREATE_FAX_EXTENSION', 'CAN_DELETE_FAX_EXTENSION'],
	},
}));

export const deleteFaxline = createAction('FAXLINE_DELETE', (faxlineId: string) => ({
	promise: api.deleteFaxline(faxlineId),
	data: {
		faxlineId,
	},
	restrictions: {
		// TODO
		restrictions: ['CAN_CREATE_FAX_EXTENSION', 'CAN_DELETE_FAX_EXTENSION'],
	},
}));

export const setFaxlineCallerId = createAction(
	'FAXLINES_SET_CALLERID',
	(faxlineId: string, callerId: string, anonymous: boolean) => ({
		promise: api.setFaxlineCallerId(faxlineId, callerId, anonymous),
		data: {
			faxlineId,
			callerId,
			anonymous,
		},
	})
);
