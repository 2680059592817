import { useDispatch, useSelector } from '../../utils/wrapper';
import { createPseudo } from './actions';
import { PseudoItem } from './types';

export const usePseudo = () => {
	const dispatch = useDispatch();
	const pseudoState = useSelector(state => state.pseudo);

	return {
		createPseudo: (pseudo: string): PseudoItem => dispatch(createPseudo(pseudo)).payload,
		getPseudo: (key: string): PseudoItem | undefined =>
			pseudoState.items.find(item => item.key === key),
	};
};
