import { handleActions } from '../..';
import { ScopeState } from './types';
import * as actions from './actions';

const initialState: ScopeState = {
	fetching: false,
	fetched: false,
	items: [],
};

export default handleActions<ScopeState, PossibleActions<typeof actions>>(
	{
		SCOPES_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetching: false,
			fetched: true,
			items: payload.items,
		}),
	},
	initialState
);
