// eslint-disable-next-line max-classes-per-file
import React from 'react';
import { BotifyProps, withBotify } from './BotifyContext';
import PageNotFoundRedirect from '../redirects/PageNotFoundRedirect';

interface Props {
	deeplink: string;
}

class BotifyLinkHandler extends React.Component<Props & BotifyProps> {
	public componentDidMount() {
		this.props.botify.openDeeplink(this.props.deeplink);
	}

	public componentDidUpdate(prevProps: Props) {
		if (this.props.deeplink !== prevProps.deeplink) {
			this.props.botify.openDeeplink(this.props.deeplink);
		}
	}

	public render() {
		return <PageNotFoundRedirect />;
	}
}

export default withBotify(BotifyLinkHandler);
