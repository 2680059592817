import React from 'react';
import classnames from 'classnames';
import Svg from '../../Svg';
import classes from './SearchIcon.scss';

interface Props {
	className?: string;
	variant?: string;
}

export const Variants = {
	DEFAULT: 'DEFAULT',
	ACTIVE: 'ACTIVE',
};

const SearchIcon = (props: Props) => {
	const getClassNames = () => {
		const iconClasses = [props.className, classes.icon];

		switch (props.variant) {
			case Variants.ACTIVE:
				iconClasses.push(classes.active);
				break;
			default:
				iconClasses.push(classes.default);
				break;
		}
		return classnames(...iconClasses);
	};

	return (
		<Svg className={getClassNames()}>
			<g>
				<circle cx="11" cy="12.2" r="9" />
				<line x1="28" y1="26.8" x2="17.9" y2="18" />
			</g>
		</Svg>
	);
};

export default SearchIcon;
