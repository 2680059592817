import { handleActions } from '../..';
import Constants from './constants';
import { GoogleContactsState } from './types';
import actions from './actions';

const initialState: GoogleContactsState = {
	items: [],
	fetched: false,
};

export default handleActions<GoogleContactsState, PossibleActions<typeof actions>>(
	{
		[Constants.GOOGLE_CONTACTS_SET]: (state, { payload }) => ({
			...state,
			items: payload,
			fetched: true,
		}),
	},
	initialState
);
