import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

export const fetchGroups = createAction('GROUPS_FETCH', (force?: boolean) => ({
	promise: () => api.getGroups(),
	shouldFetch: (state: ReduxState) => (!state.groups.fetched && !state.groups.fetching) || force,
}));

export const createGroup = createAction('GROUP_CREATE', (alias: string) => ({
	promise: () => api.createGroup(alias),
	data: {
		alias,
	},
	restrictions: {
		restrictions: [`CAN_CREATE_GROUP`],
	},
}));

export const deleteGroup = createAction('GROUP_DELETE', (groupId: string) => ({
	promise: () => api.deleteGroup(groupId),
	data: {
		groupId,
	},
	restrictions: {
		restrictions: [`CAN_CREATE_GROUP`],
	},
}));

export const createGreeting = createAction('GROUP_GREETING_CREATE', (groupId: string) => ({
	promise: () => api.createGroupGreeting(groupId),
	data: {
		groupId,
	},
}));

export const deleteGreeting = createAction('GROUP_GREETING_DELETE', (groupId: string) => ({
	promise: () => api.deleteGroupGreeting(groupId),
	data: {
		groupId,
	},
}));

export const createCallQueue = createAction('GROUP_CALL_QUEUE_CREATE', (groupId: string) => ({
	promise: () => api.createCallQueue(groupId),
	data: {
		groupId,
	},
}));

export const deleteCallQueue = createAction('GROUP_CALL_QUEUE_DELETE', (groupId: string) => ({
	promise: () => api.deleteCallQueue(groupId),
	data: {
		groupId,
	},
}));

export const setGroupAlias = createAction(
	'GROUP_ALIAS_SET',
	(groupId: string, alias: string, oldAlias: string) => ({
		promise: api.setGroupAlias(groupId, alias),
		data: {
			groupId,
			alias,
			oldAlias,
		},
	})
);

export const setGroupMembers = createAction(
	'GROUP_MEMBERS_SET',
	(groupId: string, memberIds: string[]) => ({
		promise: api.setGroupMembers(groupId, memberIds),
		data: {
			groupId,
			memberIds,
		},
	})
);

export const setActiveAudioFileGreeting = createAction(
	'ACTIVE_AUDIOFILE_GREETING_SET',
	(groupId: string, activeAudioFileId: string | null) => ({
		promise: api.setActiveAudioFileGreetingForGroup(groupId, activeAudioFileId),
		data: {
			groupId,
			activeAudioFileId,
		},
	})
);

export const setActiveAudioFileCallQueue = createAction(
	'ACTIVE_AUDIOFILE_CALL_QUEUE_SET',
	(groupId: string, activeAudioFileId: string | null) => ({
		promise: api.setActiveAudioFileCallQueue(groupId, activeAudioFileId),
		data: {
			groupId,
			activeAudioFileId,
		},
	})
);

export const bookGroupStatisticsContract = createAction(
	'GROUP_STATISTICS_CONTRACT_BOOK',
	(groupId: string) => ({
		promise: api.bookGroupStatisticsContract(groupId),
		data: {
			groupId,
		},
	})
);
