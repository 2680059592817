import { AcdAudioFile, AcdAudioFileType } from './index';
import api from '../../../api';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';

function shouldFetchAudioFiles(state: ReduxState, acdId: string) {
	return (
		!state.acdAudioFiles.fetchedForAcd.find(audioFile => audioFile.acdId === acdId) &&
		!state.acdAudioFiles.fetchingForAcd.includes(acdId)
	);
}

export const fetchAcdAudioFiles = createAsyncThunk(
	'acdAudioFiles/fetch',
	async (data: { acdId: string; force?: boolean }) => api.getAcdAudioFiles(data.acdId),
	{
		condition: (_arg, { getState }) => _arg.force || shouldFetchAudioFiles(getState(), _arg.acdId),
	}
);

export const createAcdAudioFile = createAsyncThunk(
	'acdAudioFiles/create',
	async (data: {
		acdId: string;
		filename: string;
		base64Content: string;
		type: AcdAudioFileType;
	}) => api.createAcdAudioFile(data.acdId, data.filename, data.base64Content, data.type)
);

export const deleteAcdAudioFile = createAsyncThunk(
	'acdAudioFiles/delete',
	(data: {
		acdId: string;
		audioFileId: string;
		audioFileType: AcdAudioFileType;
		defaultAudioFileId?: string;
	}) => api.deleteAcdAudioFile(data.acdId, data.audioFileId)
);

export const setAcdAudioFileAlias = createAsyncThunk(
	'acdAudioFiles/setAlias',
	async (data: { acdId: string; audioFileId: string; alias: string }) =>
		api.setAcdAudioFileAlias(data.acdId, data.audioFileId, data.alias)
);

export const fetchAcdAudioFileContent = createAsyncThunk(
	'acdAudioFiles/fetchContent',
	async (data: { acdId: string; audioFile: AcdAudioFile; onlyForceOnError?: boolean }) =>
		api.getAcdAudioFileContent(data.acdId, data.audioFile.id),
	{
		condition: _arg =>
			_arg.audioFile.url.state === 'UNFETCHED' ||
			(_arg.onlyForceOnError && _arg.audioFile.url.state === 'ERROR'),
	}
);
