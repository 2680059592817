import React, { useEffect, useRef } from 'react';
import { Search } from 'history';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { WithDialogProps, withDialogs } from '../routes/paths/dialogs';
import { useUserInfo } from '../redux/modules/userinfo';
import { hasRestriction, useUserRestrictions } from '../redux/modules/restrictions';
import { mixpanel } from '../third-party/mixpanel';
import { eventlistPath } from '../routes/paths';

interface ExternalProps {
	webuserId: string;
	isBulk?: boolean;
	requestParams: Search;
}

type Props = WithDialogProps & ExternalProps & RouteComponentProps;

const SipgateAppRedirect = (props: Props) => {
	const userInfo = useUserInfo();
	const userRestrictions = useUserRestrictions(props.webuserId, ['CAN_USE_SIPGATE_APP']);
	const canUseSipgateApp = hasRestriction(userRestrictions, 'CAN_USE_SIPGATE_APP', props.webuserId);
	const currentRef = useRef<string | null>();

	useEffect(() => {
		const ref = new URLSearchParams(props.requestParams).get('ref');
		if (!currentRef.current && ref) {
			const CTA_ID = `${ref.toUpperCase()}_BOOK_APP`;
			mixpanel.track('CTA clicked', { CTA_ID });
			currentRef.current = ref;
		}
	}, [props.requestParams]);

	if (userRestrictions.length === 0) {
		return null;
	}

	const getBookAppDialogRoute = () => {
		if (!canUseSipgateApp) {
			return eventlistPath.build();
		}
		if (props.isBulk && userInfo.isAdmin) {
			return props.dialogs.bookAppBulk.at(`/users`).build();
		}

		if (userInfo.isAdmin) {
			return props.dialogs.bookApp
				.at(`/users/${props.webuserId}/routing`)
				.build({ webuserId: props.webuserId });
		}

		return props.dialogs.bookApp.at('/history').build({ webuserId: props.webuserId });
	};

	return <Redirect to={getBookAppDialogRoute()} />;
};

export default withDialogs(withRouter(SipgateAppRedirect));
