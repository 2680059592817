import React from 'react';
import classnames from 'classnames';
import classes from './Tabs.module.css';

type Props = {
	label: string;
	sublabel: string;
	isActive: boolean;
	isSelected: boolean;
	handleOnClick?: VoidFunction;
	disabled: boolean;
};

const styles = {
	listItem: classnames('flex', 'items-center', 'self-stretch'),
	outerButton: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'group',
			'cursor-pointer',
			'focus:outline-none',
			'focus-visible:outline-none',
			'justify-center',
			'p-8',
			'whitespace-nowrap',
			'duration-150',
			'ease-in-out',
			'transition',
			'bg-transparent',
			!isDisabled && isSelected && ['bg-transparent', classes.borderSelected],
			!isDisabled && !isSelected && [classes.border, classes.borderHover, 'bg-transparent'],
			isDisabled && ['bg-transparent', 'disabled:cursor-not-allowed'],
			isDisabled && isSelected && [classes.borderDisabledSelected],
			isDisabled && !isSelected && [classes.border]
		),
	innerButton: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'font-brand',
			'group-focus-visible:ring-focus-inset',
			'px-16',
			'py-8',
			'rounded',
			'block',
			'duration-150',
			'ease-in-out',
			'transition',
			'min-w-[9rem]',
			'text-start',
			!isDisabled &&
				isSelected && [
					'bg-blue-50',
					'group-focus-visible:ring-color-focus',
					'group-active:bg-blue-100',
				],
			!isDisabled &&
				!isSelected && [
					'group-hover:bg-blue-25',
					'group-focus-visible:ring-color-focus',
					'group-active:bg-blue-50',
				],
			isDisabled && isSelected && ['bg-gray-50']
		),
	label: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'text-sm/16',
			'font-bold',
			!isDisabled &&
				!isSelected && ['text-gray-800', 'group-hover:text-blue-800', 'group-active:text-blue-900'],
			!isDisabled && isSelected && ['text-blue-900', 'group-active:text-black'],
			isDisabled && !isSelected && ['text-gray-400'],
			isDisabled && isSelected && ['text-gray-400']
		),

	sublabel: (isSelected: boolean, isDisabled: boolean) =>
		classnames(
			'text-xs',
			isDisabled && ['text-gray-400'],
			!isDisabled &&
				!isSelected && ['text-gray-700', 'group-hover:text-gray-800', 'group-active:text-gray-900'],
			!isDisabled && isSelected && ['text-blue-600', 'font-bold']
		),
};

const MainTabItem = ({
	label,
	sublabel,
	isSelected,
	isActive,
	disabled,
	handleOnClick,
}: Props): JSX.Element => (
	<li className={styles.listItem} role="presentation">
		<button
			role="tab"
			aria-selected={isSelected}
			type="button"
			className={styles.outerButton(isSelected, disabled)}
			disabled={disabled}
			onClick={!disabled ? handleOnClick : undefined}
			aria-label={`${label} (${sublabel})`}
		>
			<div className={styles.innerButton(isSelected, disabled)}>
				<div className={styles.label(isSelected, disabled)}>{label}</div>
				<div className={styles.sublabel(isActive, disabled)}>{sublabel}</div>
			</div>
		</button>
	</li>
);

export { MainTabItem };
