import { createSlice } from '@reduxjs/toolkit';
import { BalanceState } from './types';
import { fetchBalance, topupBalance } from './actions';

export const initialState = {
	fetching: false,
	fetched: false,
	data: null,
} as BalanceState;

export const reducer = createSlice({
	name: 'balance',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchBalance.pending, state => {
			return { ...state, fetching: true };
		});

		builder.addCase(fetchBalance.fulfilled, (state, action) => {
			return {
				...state,
				fetched: true,
				fetching: false,
				data: {
					fraction: 10_000,
					amount: action.payload.amountInTenThousandths,
					currency: action.payload.currency,
				},
			};
		});

		builder.addCase(topupBalance.pending, state => {
			return { ...state, fetching: true };
		});

		builder.addCase(topupBalance.fulfilled, (state, action) => {
			return {
				...state,
				fetched: true,
				fetching: false,
				data: {
					fraction: 10_000,
					amount: action.payload.amountInTenThousandths,
					currency: action.payload.currency,
				},
			};
		});
	},
}).reducer;
