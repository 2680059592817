import { ApiSipgateStatus, isApiSipgateStatus } from '../types/sipgateStatus';

export const SIPGATE_STATUS_URL = 'https://status.sipgate.de/api/v2/summary.json';
class SipgateStatusClient {
	public async getStatus(): Promise<ApiSipgateStatus> {
		const response = await fetch(SIPGATE_STATUS_URL);

		const body = await response.json();

		if (!isApiSipgateStatus(body)) {
			return Promise.reject(new Error('No correct ApiSipgateStatus was found'));
		}

		return body as ApiSipgateStatus;
	}
}

export default new SipgateStatusClient();
