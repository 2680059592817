import { useCallback, useEffect, useState } from 'react';
import api, { ApiError } from '../../../api';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	CreateRequest,
	EditRequest,
	MoveRequest,
	createAddress,
	editAddress,
	fetchAddresses,
	moveAddress,
	deleteAddress,
	fetchBusinessTypes,
} from './actions';
import { PhoneBookEntry } from './types';

export const useAddresses = () => {
	const dispatch = useDispatch();
	const addresses = useSelector(state => state.addresses);

	useEffect(() => {
		dispatch(fetchAddresses());
	}, [dispatch]);

	return addresses.fetched ? addresses.items : null;
};

export const useBrokenAddresses = () => {
	const dispatch = useDispatch();
	const hasBrokenAddresses = useSelector(state => state.addresses.hasBrokenAddresses);

	useEffect(() => {
		dispatch(fetchAddresses());
	}, [dispatch]);

	return hasBrokenAddresses;
};

export const usePhoneBookEntry = (addressId: number) => {
	const [state, setState] = useState<{
		fetched: boolean;
		error: ApiError | undefined;
		entry: PhoneBookEntry | undefined;
	}>({
		fetched: false,
		error: undefined,
		entry: undefined,
	});

	useEffect(() => {
		const abort = new AbortController();

		api
			.cancellable(abort.signal)
			.getPhonebookEntry(addressId)
			.then(entry => setState({ fetched: true, error: undefined, entry }))
			.catch(e => {
				if (abort.signal.aborted) {
					return;
				}

				if (!(e instanceof ApiError)) {
					throw e;
				}

				setState({
					fetched: true,
					error: e,
					entry: undefined,
				});
			});

		return () => abort.abort();
	}, [addressId]);

	return state;
};

export const useBusinessTypes = () => {
	const dispatch = useDispatch();
	const addresses = useSelector(state => state.addresses);

	useEffect(() => {
		dispatch(fetchBusinessTypes());
	}, [dispatch]);

	return addresses.fetchedBusinessTypes ? addresses.businessTypes : null;
};

export const useCreateAddress = () => {
	const dispatch = useDispatch();

	return useCallback(
		async (createApiAddressRequest: CreateRequest) => {
			const result = await dispatch(createAddress(createApiAddressRequest)).payload.promise;

			if (result.type === 'NEW_ADDRESS_CREATE_SUCCESS') {
				return result.payload;
			}

			throw result.payload;
		},
		[dispatch]
	);
};

export const useEditAddress = () => {
	const dispatch = useDispatch();

	return useCallback(
		async (id: number, request: EditRequest) => {
			const result = await dispatch(editAddress(id, request)).payload.promise;

			if (result.type === 'NEW_ADDRESS_EDIT_SUCCESS') {
				return result.payload;
			}

			throw result.payload;
		},
		[dispatch]
	);
};

export const useMoveAddress = () => {
	const dispatch = useDispatch();

	return useCallback(
		async (id: number, request: MoveRequest) => {
			const result = await dispatch(moveAddress(id, request)).payload.promise;

			if (result.type === 'NEW_ADDRESS_MOVE_SUCCESS') {
				return result.payload;
			}

			throw result.payload;
		},
		[dispatch]
	);
};

export const useDeleteAddress = () => {
	const dispatch = useDispatch();

	return useCallback(
		async (id: number, emergencyAddressId?: number) => {
			const result = await dispatch(deleteAddress(id, emergencyAddressId)).payload.promise;

			if (result.type === 'NEW_ADDRESS_DELETE_SUCCESS') {
				return result.payload;
			}

			throw result.payload;
		},
		[dispatch]
	);
};
