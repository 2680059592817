import { useEffect } from 'react';
import { ApiLinks } from '../../../api/types/links';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchLinks } from './actions';

interface LinksHook {
	fetched: boolean;
	fetching: boolean;
	links: ApiLinks;
}

const useLinks = (): LinksHook => {
	const dispatch = useDispatch();
	const { fetching, fetched, items } = useSelector(state => state.links);

	useEffect(() => {
		if (!fetched && !fetching) {
			dispatch(fetchLinks());
		}
	}, [fetching, fetched, dispatch]);

	return { fetched, fetching, links: items };
};

export default useLinks;
