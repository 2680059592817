import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect, ReduxProps } from '../../../redux';
import CageDialog from '../cage/CageDialog';
import IdentityVerificationDialogContent from './IdentityVerificationDialogContent';
import { ReduxState } from '../../../redux/types';

const mapStateToProps = (state: ReduxState, ownProps: RouteComponentProps) => ({
	isViaDirectRoute: ownProps.match.path.match(/videoident$/),
	identityVerification: state.identityVerification,
});

type Props = ReduxProps<typeof mapStateToProps> & RouteComponentProps;

class IdentityVerificationCageDialog extends React.Component<Props> {
	private shouldOpenCage() {
		if (!this.props.identityVerification.fetched) {
			return false;
		}

		if (this.props.identityVerification.data.shouldBeCaged) {
			return true;
		}

		return (
			this.props.identityVerification.videoIdentCompletedWithinSession ||
			this.props.isViaDirectRoute
		);
	}

	public render() {
		if (!this.shouldOpenCage()) {
			return null;
		}

		return (
			<CageDialog>
				<IdentityVerificationDialogContent />
			</CageDialog>
		);
	}
}

export default withRouter(connect(mapStateToProps)(IdentityVerificationCageDialog));
