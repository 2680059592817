import { createAction } from '../../utils/actions';
import ReportsResponse from './data/reports.json';
import { ApiReport, ApiReports, Report } from './types';
import { ReduxState } from '../../types';

export const fetchReports = createAction('REPORTS_FETCH', () => ({
	promise: (): Promise<ApiReports> => {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(ReportsResponse as unknown as ApiReports);
			}, 300);
		});
	},
	shouldFetch: (state: ReduxState) => !state.reports.fetched,
}));

export const deleteReport = createAction('REPORT_DELETE', (id: string) => ({
	promise: () => {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(id);
			}, 300);
		});
	},
	data: { id },
}));

export const renameReport = createAction('REPORT_RENAME', (id: string, name: string) => ({
	promise: () => {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve({ id, name });
			}, 300);
		});
	},
	data: { id, name },
}));

export const addReport = createAction(
	'REPORT_ADD',
	(report: Pick<Report, 'name' | 'grouping'>) => ({
		promise: (): Promise<ApiReport> => {
			return new Promise(resolve => {
				setTimeout(() => {
					const reports = ReportsResponse as unknown as ApiReports;
					const newReport = { ...reports.reports[0], name: report.name };

					switch (report.grouping) {
						case 'groups':
							newReport.grouping = 'REPORT_GROUPING_GROUPS';
							break;
						case 'location':
							newReport.grouping = 'REPORT_GROUPING_LOCATION';
							break;
						case 'account':
							newReport.grouping = 'REPORT_GROUPING_ACCOUNT';
							break;
						case 'user':
						default:
							newReport.grouping = 'REPORT_GROUPING_WEBUSER';
					}
					resolve(newReport);
				}, 300);
			});
		},
	})
);
