import { Group, selectGroupsByUserId } from '../groups';
import { Voicemail, VoicemailState } from './types';

export const getVoicemailsForOwner = (voicemails: Voicemail[], ownerId: string) =>
	voicemails.filter(voicemail => voicemail.parentExtension.extension === ownerId);

export const selectVoicemail = (voicemails: VoicemailState, voicemailId: string) =>
	voicemails.items.find(voicemail => voicemail.id === voicemailId);

export function selectValidVoicemailForwardingTargetsForUser(
	voicemails: Voicemail[],
	groups: Group[],
	webuserId: string
) {
	const userVoicemails = getVoicemailsForOwner(voicemails, webuserId);

	const groupVoicemails = selectGroupsByUserId(groups, webuserId).flatMap(group =>
		getVoicemailsForOwner(voicemails, group.id)
	);

	return [...userVoicemails, ...groupVoicemails];
}
