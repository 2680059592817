import { ApiChangelogItem } from '../types/changelog';

const SHEET_ID = '1pdHHPr4G5JNA8jh_vGZTW0JYr7-k2LhGpBjmdhVuS0o';
const URL = `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:json&tq&gid=0`;

class SipgateChangelogClient {
	public async getChangelog(): Promise<ApiChangelogItem[]> {
		const response = await fetch(URL);
		const data = await response.text();
		const json = JSON.parse(data.substring(47, data.length - 2));

		const rows = json.table.rows;
		const items: ApiChangelogItem[] = [];

		// skip header row
		for (let i = 1; i < rows.length; i += 1) {
			const row = rows[i].c;

			const item: ApiChangelogItem = {
				date: row[0].v,
				headline: row[1].v,
				description: row[2].v,
			};

			items.push(item);
		}

		return items;
	}
}

export default new SipgateChangelogClient();
