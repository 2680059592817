import {
	sanitizeNumber,
	isValidPhonenumber,
	getE164Number,
	isDirectDialNumber,
} from '@web-apps/phonenumbers-utils';
import { handleActions } from '../../..';

import * as actions from './actions';
import { WebphonePhoneNumbersState } from './types';
import { SipgateDomain } from '../../userinfo';

const initialState = {
	phoneNumber: {
		e164: '',
		raw: '',
		valid: true,
	},
	transferPhoneNumber: {
		e164: '',
		raw: '',
		valid: true,
	},
};

const prepareNumberState = (phoneNumber: string, domain: SipgateDomain) => {
	const sanitizedNumber = sanitizeNumber(phoneNumber);
	const isDirectDial = isDirectDialNumber(sanitizedNumber);
	const callableNumber = isDirectDial ? sanitizedNumber : getE164Number(sanitizedNumber, domain);
	const valid =
		(sanitizedNumber.match(/^[0-9*#]*$/) && sanitizedNumber.length > -1 && isDirectDial) ||
		isValidPhonenumber(callableNumber, domain);
	return {
		raw: phoneNumber,
		e164: callableNumber,
		valid,
	};
};

export default handleActions<WebphonePhoneNumbersState, PossibleActions<typeof actions>>(
	{
		WEBPHONE_PHONE_NUMBERS_SET: (state, { payload }) => ({
			...state,
			phoneNumber: prepareNumberState(payload.phoneNumber, payload.domain),
		}),
		WEBPHONE_PHONE_NUMBERS_TRANSFER_SET: (state, { payload }) => ({
			...state,
			transferPhoneNumber: prepareNumberState(payload.transferPhoneNumber, payload.domain),
		}),
	},
	initialState
);
