import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { connect, ReduxProps } from '../../redux';
import { createPseudo } from '../../redux/modules/pseudo';
import { fetchUserInfo } from '../../redux/modules/userinfo';
import { fetchLinks } from '../../redux/slices/links';
import { ReduxState } from '../../redux/types';
import * as paths from '../../routes/paths';
import { WithDialogProps, withDialogs } from '../../routes/paths/dialogs';
import ConnectedEventList from './ConnectedEventList';

const mapStateToProps = (state: ReduxState) => ({
	links: state.links,
	userInfo: state.userinfo,
});

const mapDispatchToProps = {
	createPseudo,
	fetchLinks,
	fetchUserInfo,
};

interface ExternalProps {
	webuserId: string;
}

type Props = ExternalProps &
	WithDialogProps &
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
	RouteComponentProps;

class HistoryDetailView extends React.Component<Props> {
	public constructor(props: Props) {
		super(props);

		this.props.fetchLinks();
		this.props.fetchUserInfo();
	}

	private openBlocklist = () => {
		this.props.history.push({
			pathname: paths.blocklistPath.build(),
		});
	};

	private onNewContact = (number: string) => {
		const pseudo = this.props.createPseudo(number);

		this.props.dialogs.createContact.open({
			numberPseudo: pseudo.payload.key,
		});
	};

	private onAddNumberToContact = (number: string) => {
		const pseudo = this.props.createPseudo(number);

		this.props.dialogs.addNumberToContact.open({
			pseudoKey: pseudo.payload.key,
		});
	};

	private onClick2Dial = (number: string) => {
		const pseudo = this.props.createPseudo(number);

		this.props.dialogs.clickToDial.open({
			webuserId: this.props.webuserId,
			pseudoKey: pseudo.payload.key,
		});
	};

	private onClick2Play = (eventId: string) => {
		this.props.dialogs.clickToPlay.open({
			webuserId: this.props.webuserId,
			datadId: eventId,
		});
	};

	private onAnswerWithSms = (number: string) => {
		const pseudo = this.props.createPseudo(number);

		this.props.dialogs.smsSend.open({
			pseudoKey: pseudo.payload.key,
		});
	};

	public render() {
		if (!this.props.userInfo.fetched) {
			return null;
		}

		return (
			<ConnectedEventList
				webuserId={this.props.webuserId}
				onNewContact={this.onNewContact}
				onAddNumberToContact={this.onAddNumberToContact}
				onClick2Dial={this.onClick2Dial}
				onAnswerWithSms={this.onAnswerWithSms}
				onClick2Play={this.onClick2Play}
				onOpenBlacklist={this.openBlocklist}
				links={this.props.links}
				userinfo={this.props.userInfo.data}
			/>
		);
	}
}

export default withDialogs(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryDetailView))
);
