import {
	chainValidators,
	ManagedMultiFieldValidator,
	ManagedValidatorResult,
	validateEmail,
	validateMaxLength,
	validateNonEmpty,
	validateQuickdial,
} from '@web-apps/forms';
import { renderError } from '@web-apps/forms/src/validators/translate';

import { BatchUser } from '../../api/types/batch-user';
import { Translate } from '../../redux/slices/translations/types';

export type ValidatedBatchUser = BatchUser & {
	validation: Partial<Record<keyof BatchUser, ManagedValidatorResult<string>>>;
};

const validateNotDuplicateEmail =
	(translate: Translate | string): ManagedMultiFieldValidator<string, BatchUser[], string> =>
	(value, values) => {
		const hasMoreThanOne =
			values
				.filter(user => user.email === undefined)
				.filter(user => user.email.toLowerCase() === value.toLowerCase()).length > 1;
		if (hasMoreThanOne) {
			return {
				valid: false,
				error: renderError(translate, 'FORM_VALIDATION_GENERAL_DUPLICATE'),
			};
		}

		return {
			valid: true,
			value,
		};
	};

const validateNotDuplicateQuickdial =
	(translate: Translate | string): ManagedMultiFieldValidator<string, BatchUser[], string> =>
	(value, values) => {
		const hasDuplicateQuickdial = values.filter(user => user.quickdial === value).length > 1;

		if (hasDuplicateQuickdial) {
			return {
				valid: false,
				error: renderError(translate, 'FORM_VALIDATION_GENERAL_DUPLICATE'),
			};
		}

		return {
			valid: true,
			value,
		};
	};

const validateNoDuplicatePhonenumbers =
	(translate: Translate | string): ManagedMultiFieldValidator<string, BatchUser[], string> =>
	(value, values) => {
		const hasDuplicatePhoneNumberId =
			values.filter(user => `${user.phoneNumber}` === value).length > 1;

		if (hasDuplicatePhoneNumberId) {
			return {
				valid: false,
				error: renderError(translate, 'FORM_VALIDATION_GENERAL_DUPLICATE'),
			};
		}

		return {
			valid: true,
			value,
		};
	};

const validateFirstname = (translate: Translate | string) => (firstname: string) => {
	const result = validateNonEmpty(translate)(firstname);
	return !result.valid
		? { ...result, error: renderError(translate, 'BATCH_USER_IMPORT_ERRORHINT_INVALID_FIRSTNAME') }
		: result;
};

const validateLastname = (translate: Translate | string) => (lastname: string) => {
	const result = validateNonEmpty(translate)(lastname);
	return !result.valid
		? { ...result, error: renderError(translate, 'BATCH_USER_IMPORT_ERRORHINT_INVALID_LASTNAME') }
		: result;
};

const validateBatchUserEmail = (translate: Translate | string) => (email: string) => {
	const result = validateEmail(translate)(email);
	return !result.valid
		? { ...result, error: renderError(translate, 'BATCH_USER_IMPORT_ERRORHINT_INVALID_EMAIL') }
		: result;
};

export const getValidatorsByField = (translate: Translate) => {
	const validatorsByField: Partial<
		Record<keyof BatchUser, ManagedMultiFieldValidator<string, BatchUser[], string>>
	> = {
		firstname: chainValidators(validateFirstname(translate), validateMaxLength(translate, 100)),
		lastname: chainValidators(validateLastname(translate), validateMaxLength(translate, 100)),
		email: chainValidators(
			validateNonEmpty(translate),
			validateBatchUserEmail(translate),
			validateNotDuplicateEmail(translate)
		),
		locationId: chainValidators(value => {
			const locationIdNotSet =
				value === undefined || value === null || value === 'null' || value === '' || value === '0';
			if (locationIdNotSet) {
				return {
					valid: false,
					error: renderError(translate, 'FORM_VALIDATION_GENERAL_ENUM'),
				};
			}
			return validateNonEmpty(translate)(value);
		}),
		phoneNumber: chainValidators((value, values) => {
			const phoneNumberNotSet =
				value === undefined || value === null || value === '' || value === 'null';
			if (phoneNumberNotSet) {
				return {
					valid: true,
					value,
				};
			}

			return validateNoDuplicatePhonenumbers(translate)(value, values);
		}),
		quickdial: chainValidators(
			validateQuickdial(translate),
			validateNotDuplicateQuickdial(translate)
		),
	};
	return validatorsByField;
};
