import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	fetchTransferAudioFileContent,
	fetchTransferAudioFiles,
	setTransferAudioFileAlias as setTransferAudioFileAliasAction,
	deleteTransferAudioFile as deleteTransferAudioFileAction,
	createTransferAudioFile as createTransferAudioFileAction,
	setActiveTransferAudioFile as setActiveTransferAudioFileAction,
} from './actions';
import { TransferAudioFile } from './types';

export const useTransferAudioFiles = () => {
	const dispatch = useDispatch();
	const transferAudioFiles = useSelector(state => state.transferAudioFiles);

	useEffect(() => {
		dispatch(fetchTransferAudioFiles());
	}, [dispatch]);

	const setAudioFileAlias = useCallback(
		async (audioFileId: string, alias: string) => {
			await dispatch(setTransferAudioFileAliasAction(audioFileId, alias)).payload.promise;
		},
		[dispatch]
	);

	const deleteAudioFile = useCallback(
		async (audioFileId: string) => {
			await dispatch(deleteTransferAudioFileAction(audioFileId)).payload.promise;
		},
		[dispatch]
	);

	const createAudioFile = useCallback(
		async (name: string, base64Content: string) => {
			await dispatch(createTransferAudioFileAction(name, base64Content)).payload.promise;
		},
		[dispatch]
	);

	const setActiveTransferAudioFile = useCallback(
		async (audioFileId: string | null) => {
			await dispatch(setActiveTransferAudioFileAction(audioFileId)).payload.promise;
		},
		[dispatch]
	);

	return {
		transferAudioFiles,
		fetchAudioFileContent: (audioFile: TransferAudioFile) => {
			return dispatch(fetchTransferAudioFileContent(audioFile));
		},
		setAudioFileAlias,
		deleteAudioFile,
		createAudioFile,
		setActiveTransferAudioFile,
	};
};
