import { Store } from '@reduxjs/toolkit';
import { MarkReadPendingAction } from './types';
import api from '../../../../../api';
import { State } from '../../state';

export const markRead = (store: Store<State>) => async (eventIds: string[]) => {
	store.dispatch<MarkReadPendingAction>({ type: 'MARK_READ_PENDING', eventIds });

	await api.updateHistoryEntries(eventIds.map(id => ({ id, read: true })));
};
