import { handleActions } from '../..';
import { MicrosoftTeamsState } from './types';
import * as actions from './actions';

const initialState: MicrosoftTeamsState = {
	fqdns: [],
	fetched: false,
	fetching: false,
};

export default handleActions<MicrosoftTeamsState, PossibleActions<typeof actions>>(
	{
		FQDNS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		FQDNS_FETCH_SUCCESS: (state, { payload }) => ({
			fqdns: payload.items,
			fetched: true,
			fetching: false,
		}),
		FQDNS_CREATE_PENDING: state => ({ ...state, fetching: true }),
		FQDNS_CREATE_SUCCESS: (state, { payload }) => ({
			fqdns: payload.items,
			fetched: true,
			fetching: false,
		}),
	},
	initialState
);
