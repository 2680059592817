import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

export const fetchContracts = createAction('CONTRACTS_FETCH', () => ({
	promise: () => api.getContracts(),
	shouldFetch: (s: ReduxState) => !s.contracts.fetched && !s.contracts.fetching,
}));

export const forceFetchContracts = createAction('CONTRACTS_FETCH', () => ({
	promise: () => api.getContracts(),
}));

export const fetchDeviceContracts = createAction('DEVICE_CONTRACTS_FETCH', (deviceId: string) => ({
	promise: () => api.getDeviceContracts(deviceId),
	shouldFetch: (state: ReduxState) =>
		!state.contracts.fetchedForDevice.includes(deviceId) &&
		!state.contracts.fetchingForDevice.includes(deviceId),
	data: { deviceId },
}));

export const forceFetchDeviceContracts = createAction(
	'DEVICE_CONTRACTS_FETCH',
	(deviceId: string) => ({
		promise: api.getDeviceContracts(deviceId),
		data: { deviceId },
	})
);

export const createDeviceContract = createAction(
	'CONTRACTS_CREATE',
	(deviceId: string, productId: string, acceptedTacId?: string) => ({
		promise: api.createDeviceContract(deviceId, productId, acceptedTacId),
		data: { deviceId },
	})
);

export const cancelDeviceContract = createAction(
	'CONTRACTS_CANCEL',
	(deviceId: string, contractId: string, cancellationDate: Date | null) => ({
		promise: () => api.cancelDeviceContract(deviceId, contractId, cancellationDate),
		data: { contractId },
	})
);

export const revokeDeviceContractCancellation = createAction(
	'CONTRACTS_REVOKE_CANCELLATION',
	(deviceId: string, contractId: string) => ({
		promise: () => api.revokeDeviceContractCancellation(deviceId, contractId),
		data: { deviceId, contractId },
	})
);

export const revokeDeviceContractDowngrade = createAction(
	'CONTRACTS_REVOKE_DOWNGRADE',
	(deviceId: string, activeContractId: string, futureContractId: string) => ({
		promise: () => api.cancelDeviceContract(deviceId, futureContractId, null),
		data: { activeContractId, futureContractId },
	})
);
