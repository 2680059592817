import { State } from '../../state';
import { SaveNotePendingAction } from './types';

export const saveNotePending = (state: State, action: SaveNotePendingAction): State => {
	if (!state.entries.result) {
		return state;
	}

	return {
		...state,

		entries: {
			...state.entries,

			result: {
				...state.entries.result,

				normalized: state.entries.result.normalized.map(entry => {
					if (entry.originalEvent.id !== action.eventId) {
						return entry;
					}

					return {
						...entry,

						originalEvent: {
							...entry.originalEvent,
							note: action.note,
						},
					};
				}),
			},
		},
	};
};
