import { ApiError } from '../../api';
import { isApiError } from '../../api/ApiError';
import { instanaLog } from '../../third-party/instana';
import { hasKey } from '../../utils/types';
import { openInsufficientFundsSnackbar, openInvalidAccountStateSnackbar } from '../slices/snackbar';
import { isPromiseAction } from './redux-promise-middleware';

function isInsufficientFundsFailure(error: ApiError) {
	return error.status === 402;
}

function isInternalServerError(error: ApiError) {
	return error.status === 500;
}

function isInvalidAccountStateError(json: unknown) {
	return hasKey(json, 'code') && json.code === 550;
}

export type ErrorMiddlewareNext<Action> = Action;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ErrorMiddlewareResult<Action, NextResult> = NextResult;

export type ErrorMiddlewareEmits =
	| ReturnType<typeof openInvalidAccountStateSnackbar>
	| ReturnType<typeof openInsufficientFundsSnackbar>;

export const errorMiddleware = ({
	dispatch,
}: {
	dispatch: (action: ErrorMiddlewareEmits) => void;
}) => {
	return <Action, NextResult>(next: (action: ErrorMiddlewareNext<Action>) => NextResult) =>
		(action: Action): ErrorMiddlewareResult<Action, NextResult> => {
			if (!isPromiseAction(action)) {
				return next(action);
			}

			action.payload.promise.catch(error => {
				if (!isApiError(error)) {
					// Anything triggered inside our api calls which is not an actual API error is swallowed
					// if we dont manually log here.
					instanaLog.error(error);
					return;
				}

				if (isInsufficientFundsFailure(error)) {
					dispatch(openInsufficientFundsSnackbar());
				}

				if (isInternalServerError(error)) {
					if (isInvalidAccountStateError(error.json)) {
						dispatch(openInvalidAccountStateSnackbar());
					}
				}
			});

			return next(action);
		};
};
