import React from 'react';

interface TableRowContextType {
	headerTitle: string;
	isFirstColumn: boolean;
}

export const TableRowContext = React.createContext<TableRowContextType | undefined>(undefined);

export const useTableRowContext = () => {
	const context = React.useContext(TableRowContext);

	if (context === undefined) {
		throw new Error('useTableRowContext needs to be used inside a TableRowContext.Provider');
	}

	return context;
};
