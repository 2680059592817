import { createAction } from '../..';
import { ReduxState } from '../../types';
import api from '../../../api';
import { DeviceProperty } from '../../../api/types/DeviceProperty';

const shouldFetchDeviceProperties = (state: ReduxState, deviceId: string) =>
	!state.deviceProperties.fetchedForDevice.includes(deviceId) &&
	!state.deviceProperties.fetchingForDevice.includes(deviceId);

export const fetchDeviceProperties = createAction(
	'DEVICE_PROPERTIES_FETCH',
	(deviceId: string, force?: boolean) => ({
		promise: () => api.getDeviceProperties(deviceId),
		shouldFetch: (state: ReduxState) => force || shouldFetchDeviceProperties(state, deviceId),
		data: { deviceId },
	})
);

export const setDeviceProperty = createAction(
	'DEVICE_PROPERTY_SET',
	(property: DeviceProperty) => ({
		promise: () => api.setDeviceProperty(property),
		data: property,
	})
);
