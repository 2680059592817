import { DeviceLocalPrefixState } from './types';

export function selectLocalprefix(localprefix: DeviceLocalPrefixState, deviceId: string) {
	return localprefix.items.find(item => item.deviceId === deviceId) || null;
}

export function isLocalprefixEnabled(localprefix: DeviceLocalPrefixState, deviceId: string) {
	const prefix = selectLocalprefix(localprefix, deviceId);

	if (!prefix) {
		return false;
	}

	return prefix.active;
}

export function selectLocalprefixValue(localprefix: DeviceLocalPrefixState, deviceId: string) {
	const prefix = selectLocalprefix(localprefix, deviceId);

	if (!prefix) {
		return '';
	}

	return prefix.value;
}
