import { Store } from '@reduxjs/toolkit';
import { State } from '../../state';
import { fetchHistory } from '../../history/fetchHistory/action';
import { Selections } from '../../../selections/parsers';
import { DetachLabelPendingAction } from './types';
import api from '../../../../../api';

export const detachLabel =
	(store: Store<State>) => async (labelId: number, eventIds: string[], selections: Selections) => {
		store.dispatch<DetachLabelPendingAction>({
			type: 'DETACH_LABEL_PENDING',
			labelId,
		});

		try {
			await api.detachLabel(labelId.toString(), eventIds);
		} catch (e) {
			fetchHistory(store)(selections, false);
			throw e;
		}
	};
