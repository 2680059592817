import { useEffect, useLayoutEffect } from 'react';

export function useResize(cb: () => void) {
	// Seems to be important for first-render in chrome?
	useEffect(() => cb(), [cb]);

	useLayoutEffect(() => {
		let nextAnimationFrame: number;
		const onResize = () => {
			if (nextAnimationFrame) {
				window.cancelAnimationFrame(nextAnimationFrame);
			}

			nextAnimationFrame = window.requestAnimationFrame(() => cb());
		};

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [cb]);
}
