import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { DomainState, DomainVerificationState } from './types';
import * as actions from './actions';
import { ApiDomainState } from '../../../api/types/domainVerification';

const initialState: DomainVerificationState = {
	items: [],
	fetched: false,
	fetching: false,
};

const mapToDomainVerification = (apiDomainVerification: ApiDomainState): DomainState => {
	const { verificationCode: _, ...rest } = apiDomainVerification;

	return {
		...rest,
		txtRecord: `sipgate_domain_verification=${apiDomainVerification.verificationCode}`,
	};
};

export const slice = createSlice({
	name: 'domainVerification',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(actions.fetchDomainVerifications.fulfilled, (state, { payload }) => {
			const newItems = [...payload.items.map(mapToDomainVerification)];
			newItems.sort((a, b) => a.domain.localeCompare(b.domain));

			return {
				...state,
				fetched: true,
				fetching: false,
				items: newItems,
			};
		});

		builder.addCase(actions.createDomainVerification.fulfilled, (state, { payload }) => ({
			...state,
			fetching: false,
			items: [...state.items, mapToDomainVerification(payload)],
		}));

		builder.addCase(actions.deleteDomainVerification.fulfilled, (state, { meta }) => ({
			...state,
			fetching: false,
			items: state.items.filter(i => i.id !== meta.arg),
		}));

		builder.addCase(actions.getDomainVerification.fulfilled, (state, { payload }) => {
			if (state.items.find(s => s.id === payload.id) !== undefined) {
				return state;
			}
			return {
				...state,
				fetching: false,
				items: [...state.items, mapToDomainVerification(payload)],
			};
		});

		builder.addCase(actions.resetDomainVerification.fulfilled, (state, { meta }) => {
			const items = state.items.map(i => {
				if (i.id === meta.arg) {
					return {
						...i,
						status: 'PENDING',
					};
				}
				return i;
			});
			return { ...state, fetching: true, items };
		});

		builder.addMatcher(
			isAnyOf(
				actions.fetchDomainVerifications.pending,
				actions.createDomainVerification.pending,
				actions.deleteDomainVerification.pending,
				actions.getDomainVerification.pending,
				actions.resetDomainVerification.pending
			),
			state => ({ ...state, fetching: true })
		);
	},
});

export const reducer = slice.reducer;
