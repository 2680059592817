import React from 'react';

import classnames from 'classnames';
import classes from './EmptyState.scss';

interface Props {
	title: string | React.ReactNode;
	children: React.ReactNode;
	illustration: React.ReactNode;
	testSelector?: string;
	isStandalone?: boolean;
	className?: string;
}

export class EmptyState extends React.PureComponent<Props> {
	public static defaultProps = {
		isStandalone: true,
	};

	public render() {
		const sectionClassName = this.props.isStandalone
			? classes.emptyStateStandalone
			: classes.emptyState;

		return (
			<section
				className={classnames(sectionClassName, this.props.className)}
				data-test-selector={this.props.testSelector}
			>
				<div className={classes.illustration}>{this.props.illustration}</div>
				<div className={classes.content}>
					<p className={classes.title}>{this.props.title}</p>
					{this.props.children}
				</div>
			</section>
		);
	}
}
