import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';

const shouldFetchConnectedIntegrations = (state: ReduxState) => {
	return !state.appConnectedIntegrations.fetched && !state.appConnectedIntegrations.fetching;
};

export const fetchConnectedAppIntegrations = createAsyncThunk(
	'connectedAppIntegrations/fetch',
	async () => {
		return api.getConnectedIntegrations();
	},
	{
		condition: (_, { getState }) => shouldFetchConnectedIntegrations(getState()),
	}
);

export const forceFetchConnectedAppIntegrations = createAsyncThunk(
	'connectedAppIntegrations/forceFetch',
	async () => api.getConnectedIntegrations()
);
