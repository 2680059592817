import { ManagedFieldValidator } from '../ManagedForm';
import { renderError } from './translate';
import { Translate } from './types';

/**
 * Ensures a number is a valid integer and converts
 * it to type `number`.
 */
export const validateInteger =
	(translate: Translate | string): ManagedFieldValidator<string, number> =>
	input => {
		const result = parseInt(input, 10);

		if (!Number.isNaN(result)) {
			return {
				valid: true,
				value: result,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_INTEGER_INVALID'),
		};
	};

/**
 * Ensure a string is comprised only of digits.
 *
 * ```
 * validateNumeric(translate)
 * ```
 */
export const validateNumeric =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	input => {
		if (/^\d*$/.test(input)) {
			return {
				valid: true,
				value: input,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_TYPES_NOT_NUMERIC'),
		};
	};

export const validateRange =
	(
		min: number,
		max: number,
		translate: Translate | string
	): ManagedFieldValidator<string, number> =>
	input => {
		const inputNumber = parseInt(input, 10);

		if (inputNumber >= min && inputNumber <= max) {
			return {
				valid: true,
				value: inputNumber,
			};
		}

		return {
			valid: false,
			error: renderError(
				translate,
				'FORM_VALIDATION_RANGE_INVALID',
				min.toString(),
				max.toString()
			),
		};
	};
