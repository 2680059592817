import { SmsCallerIdState, SmsCallerId } from './types';

export const selectSmsCallerIds = (smsCallerIds: SmsCallerIdState, smsId: string) => {
	if (smsId in smsCallerIds) {
		return smsCallerIds[smsId].items;
	}

	return [];
};

export function selectSmsCallerIdByNumber(smsCallerIds: SmsCallerId[], phonenumber: string) {
	return smsCallerIds.find(id => id.phonenumber === phonenumber);
}

export function selectSmsCallerId(smsCallerIds: SmsCallerId[], id: string | number) {
	return smsCallerIds.find(callerId => callerId.id === parseInt(id.toString(10), 10));
}

export function selectDefaultNumber(smsCallerIds: SmsCallerId[]) {
	return smsCallerIds.find(id => id.defaultNumber);
}
