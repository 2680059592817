import { MicrosoftTeamsRoutingConfigurationState } from './types';
import * as actions from './actions';
import { handleActions } from '../..';

const initialState: MicrosoftTeamsRoutingConfigurationState = {
	fetching: {},
	fetched: {},
	updating: {},
	items: {},
};

export default handleActions<
	MicrosoftTeamsRoutingConfigurationState,
	PossibleActions<typeof actions>
>(
	{
		ROUTING_CONFIGURATIONS_FETCH_PENDING: (state, { data }) => {
			return {
				...state,
				fetching: {
					...state.fetching,
					[data.extensionId]: true,
				},
			};
		},

		ROUTING_CONFIGURATIONS_FETCH_SUCCESS: (state, { payload, data }) => {
			return {
				...state,
				fetching: {
					...state.fetching,
					[data.extensionId]: false,
				},
				fetched: {
					...state.fetched,
					[data.extensionId]: true,
				},
				items: {
					...state.items,
					[data.extensionId]: { status: payload },
				},
			};
		},

		ROUTING_CONFIGURATIONS_SET_PENDING: (state, { data }) => {
			return {
				...state,
				updating: {
					...state.updating,
					[data.extensionId]: true,
				},
			};
		},

		ROUTING_CONFIGURATIONS_SET_SUCCESS: (state, { data }) => {
			return {
				...state,
				updating: {
					...state.updating,
					[data.extensionId]: false,
				},
				items: {
					...state.items,
					[data.extensionId]: { status: true },
				},
			};
		},

		ROUTING_CONFIGURATIONS_SYNC_PENDING: (state, { data }) => {
			return {
				...state,
				updating: {
					...state.updating,
					[data.extensionId]: true,
				},
			};
		},

		ROUTING_CONFIGURATIONS_SYNC_SUCCESS: (state, { data }) => {
			return {
				...state,
				updating: {
					...state.updating,
					[data.extensionId]: false,
				},
				items: {
					...state.items,
					[data.extensionId]: { status: true },
				},
			};
		},

		ROUTING_CONFIGURATIONS_DELETE_PENDING: (state, { data }) => {
			return {
				...state,
				updating: {
					...state.updating,
					[data.extensionId]: true,
				},
			};
		},

		ROUTING_CONFIGURATIONS_DELETE_SUCCESS: (state, { data }) => {
			return {
				...state,
				updating: {
					...state.updating,
					[data.extensionId]: false,
				},
				items: {
					...state.items,
					[data.extensionId]: { status: false },
				},
			};
		},
	},

	initialState
);
