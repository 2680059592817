import { DateTime, Duration, Interval } from 'luxon';

import { Immutable } from '../..';
import {
	ActiveTime,
	AnnouncementDestination,
	DayOfWeek,
	ForwardingDestination,
	ForwardingStep,
	PhonenumberDestination,
} from '../../../api/types/forwardings';
import {
	defaultAcdAnnouncementDestination,
	defaultAcdAnnouncementHolidayDestination,
} from './hooks';
import { canForwardingDelayBeChanged, isFullWeek, selectForwardingStepsForType } from './selectors';
import {
	ConditionalForwardingSet,
	ForwardingSet,
	ForwardingType,
	TimeBasedForwardingSet,
	UnconditionalForwardingSet,
} from './types';

const MAX_FORWARDING_STEPS = 4;
const WEEKDAYS: Immutable<DayOfWeek[]> = [
	'MONDAY',
	'TUESDAY',
	'WEDNESDAY',
	'THURSDAY',
	'FRIDAY',
	'SATURDAY',
	'SUNDAY',
];

export const getWeekDays = () => [...WEEKDAYS];

export const fullWeek = (timezone: string): ActiveTime => ({
	days: [...WEEKDAYS],
	startTime: '00:00:00',
	endTime: '23:59:59',
	timeZone: timezone,
});

const buildForwardingStepsWithFirstActiveStepDelayZero = (
	forwardingSteps: ForwardingStep[]
): ForwardingStep[] => {
	const newSteps: ForwardingStep[] = [];

	let didVisitFirstActiveStep = false;
	for (const step of forwardingSteps) {
		if (!didVisitFirstActiveStep) {
			didVisitFirstActiveStep = true;

			newSteps.push({ ...step, delay: 0 });
		} else {
			newSteps.push(step);
		}
	}

	return newSteps;
};

export function removeForwardingStepFromSet(
	set: TimeBasedForwardingSet,
	type: ForwardingType,
	stepIndex: number
) {
	const steps = selectForwardingStepsForType(type, set);
	if (!steps) {
		return set;
	}

	const filteredSteps = steps.filter((_, i) => i !== stepIndex);

	return {
		...set,
		[type]:
			type === 'online' || type === 'unconditional'
				? filteredSteps
				: buildForwardingStepsWithFirstActiveStepDelayZero(filteredSteps),
	};
}

export function removeForwardingStepFromSets(
	sets: TimeBasedForwardingSet[],
	setId: string,
	type: ForwardingType,
	stepIndex: number
) {
	return sets.map(set => {
		if (set.id !== setId) {
			return set;
		}

		const steps = selectForwardingStepsForType(type, set);
		if (!steps) {
			return set;
		}

		const filteredSteps = steps.filter((_, i) => i !== stepIndex);

		return {
			...set,
			[type]:
				type === 'online' || type === 'unconditional'
					? filteredSteps
					: buildForwardingStepsWithFirstActiveStepDelayZero(filteredSteps),
		};
	});
}

export function addForwardingStepToSteps(
	step: ForwardingStep,
	type: ForwardingType,
	stepIndex: number,
	steps: ForwardingStep[]
) {
	const newSteps = [...steps];
	newSteps.splice(stepIndex, 0, step);

	const followingStep = newSteps[stepIndex + 1];
	if (followingStep && followingStep.delay === 0) {
		newSteps[stepIndex + 1] = { ...followingStep, delay: 20 };
	}

	return type === 'online' || type === 'unconditional'
		? newSteps
		: buildForwardingStepsWithFirstActiveStepDelayZero(newSteps);
}

export function findDelayAndPositionForNewForwardingStep(
	steps: ForwardingStep[],
	type: ForwardingType
) {
	const activeStepCount = steps.length;
	if (activeStepCount >= MAX_FORWARDING_STEPS) {
		return null;
	}

	const position = steps.filter(step => step.destination.type !== 'VOICEMAIL').length;
	const delay = canForwardingDelayBeChanged(steps, type, position) ? 10 : 0;

	const canBeVoicemail = position === activeStepCount;
	const canBeOther = position + 1 < MAX_FORWARDING_STEPS;

	if (canBeOther && canBeVoicemail) {
		return { position, delay, type: 'both' };
	}

	if (canBeVoicemail) {
		return { position, delay, type: 'voicemail' };
	}

	return { position, delay, type: 'other' };
}

export function parseTime(time: string): number {
	const parts = time.split(':');

	return parseInt(parts[0], 10) * 60 * 60 + parseInt(parts[1], 10) * 60 + parseInt(parts[2], 10);
}

function serializeTime(time: number): string {
	const hours = Math.floor(time / (60 * 60));
	const minutes = Math.floor((time / 60) % 60);
	const seconds = time % 60;

	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
		.toString()
		.padStart(2, '0')}`;
}

function groupTimesByDay(times: ActiveTime[]) {
	const timesByDay: Record<DayOfWeek, { startTime: number; endTime: number }[]> = {
		MONDAY: [],
		TUESDAY: [],
		WEDNESDAY: [],
		THURSDAY: [],
		FRIDAY: [],
		SATURDAY: [],
		SUNDAY: [],
	};

	for (const time of times) {
		for (const day of time.days) {
			timesByDay[day].push({
				startTime: parseTime(time.startTime),
				endTime: parseTime(time.endTime),
			});
		}
	}

	return timesByDay;
}

function groupDaysByTimes(times: { day: DayOfWeek; startTime: number; endTime: number }[]) {
	const daysByTimes: Record<number, Record<number, DayOfWeek[]>> = {};

	for (const time of times) {
		daysByTimes[time.startTime] = daysByTimes[time.startTime] || {};
		daysByTimes[time.startTime][time.endTime] = daysByTimes[time.startTime][time.endTime] || [];
		daysByTimes[time.startTime][time.endTime].push(time.day);
	}

	return daysByTimes;
}

export function invertActiveTimes(times: ActiveTime[], timezone: string): ActiveTime[] {
	const simpleTimes: { day: DayOfWeek; startTime: number; endTime: number }[] = [];

	for (const [day, timesOfDay] of Object.entries(groupTimesByDay(times))) {
		timesOfDay.sort((a, b) => a.startTime - b.startTime);

		let previousEndTime = -1;
		for (const time of timesOfDay) {
			const difference = time.startTime - previousEndTime;

			if (difference > 1) {
				simpleTimes.push({
					day: day as DayOfWeek,
					startTime: previousEndTime + 1,
					endTime: time.startTime - 1,
				});
			}

			previousEndTime = time.endTime;
		}

		if (previousEndTime < 23 * 60 * 60 + 59 * 60 + 59) {
			simpleTimes.push({
				day: day as DayOfWeek,
				startTime: previousEndTime + 1,
				endTime: 23 * 60 * 60 + 59 * 60 + 59,
			});
		}
	}

	const invertedTimes = [];
	for (const [startTime, endTimes] of Object.entries(groupDaysByTimes(simpleTimes))) {
		for (const [endTime, days] of Object.entries(endTimes)) {
			invertedTimes.push({
				timeZone: times[0]?.timeZone || timezone,
				startTime: serializeTime(parseInt(startTime, 10)),
				endTime: serializeTime(parseInt(endTime, 10)),
				days: days.sort((a, b) => WEEKDAYS.indexOf(a) - WEEKDAYS.indexOf(b)),
			});
		}
	}

	return invertedTimes.sort((a, b) => WEEKDAYS.indexOf(a.days[0]) - WEEKDAYS.indexOf(b.days[0]));
}

export function getFreeActiveTimes(sets: ForwardingSet[], timezone: string) {
	return invertActiveTimes(
		sets.flatMap(set => set.activeTimes),
		timezone
	);
}

export type ActiveTimeValidationError =
	| 'OVERLAPPING_TIMEFRAMES'
	| 'INVALID_TIME'
	| 'STARTTIME_AFTER_ENDTIME'
	| 'DAYS_MISSING'
	| 'NO_ACTIVE_TIMES';

export type ActiveTimeValidationResult =
	| {
			valid: true;
	  }
	| {
			valid: false;
			error: ActiveTimeValidationError;
			invalidDaysWithTime?: { [d in DayOfWeek]?: { startTime: number; endTime: number }[] };
	  };

export function findActiveTimesOverlap(times: ActiveTime[]) {
	const timesByDay = groupTimesByDay(times);
	const invalidDays: { [d in DayOfWeek]?: { startTime: number; endTime: number }[] } = {};

	for (const [day, timesOfDay] of Object.entries(timesByDay)) {
		const dayOfWeek: DayOfWeek = day as DayOfWeek;
		timesOfDay.sort((a, b) => a.startTime - b.startTime);

		let previousEndTime = -1;
		for (const time of timesOfDay) {
			if (time.startTime <= previousEndTime) {
				if (!invalidDays[dayOfWeek]) {
					invalidDays[dayOfWeek] = [];
				}
				invalidDays[dayOfWeek]!.push(time);
			}

			previousEndTime = time.endTime;
		}
	}

	return invalidDays;
}

export function validateActiveTimes(times: ActiveTime[]): ActiveTimeValidationResult {
	const timeRegexp = /^([01]?\d|2[0-4]):[0-5]\d:[0-5]\d$/;

	if (times.length === 0) {
		return { valid: false, error: 'NO_ACTIVE_TIMES' };
	}

	for (const time of times) {
		if (!timeRegexp.test(time.startTime) || !timeRegexp.test(time.endTime)) {
			return { valid: false, error: 'INVALID_TIME' };
		}

		if (parseTime(time.startTime) >= parseTime(time.endTime)) {
			return { valid: false, error: 'STARTTIME_AFTER_ENDTIME' };
		}

		if (time.days.length === 0) {
			return { valid: false, error: 'DAYS_MISSING' };
		}
	}

	const daysWithOverlappingTimes = findActiveTimesOverlap(times);
	if (Object.keys(daysWithOverlappingTimes).length > 0) {
		return {
			valid: false,
			error: 'OVERLAPPING_TIMEFRAMES',
			invalidDaysWithTime: daysWithOverlappingTimes,
		};
	}

	return { valid: true };
}

export function makeSetConditional(
	forwarding: UnconditionalForwardingSet
): ConditionalForwardingSet {
	return {
		id: forwarding.id,
		name: forwarding.name,
		activeTimes: forwarding.activeTimes,
		isUserDefined: forwarding.isUserDefined,
		online: forwarding.unconditional,
		offline: [],
		busy: [],
		type: 'conditional',
		priority: forwarding.priority,
	};
}

export function makeSetUnconditional(
	forwarding: ConditionalForwardingSet,
	type: 'online' | 'busy' | 'offline'
): UnconditionalForwardingSet {
	return {
		id: forwarding.id,
		name: forwarding.name,
		activeTimes: forwarding.activeTimes,
		isUserDefined: forwarding.isUserDefined,
		unconditional: forwarding[type],
		type: 'unconditional',
		priority: forwarding.priority,
	};
}

export function addStepToConditionalSet(
	set: ConditionalForwardingSet,
	type: 'online' | 'busy' | 'offline',
	delay: number,
	destination: ForwardingDestination
): TimeBasedForwardingSet {
	const newStep = findDelayAndPositionForNewForwardingStep(set[type], type);

	if (!newStep) {
		return set;
	}

	return {
		...set,
		[type]: addForwardingStepToSteps(
			{
				delay,
				destination,
			},
			type,
			newStep.position,
			set[type]
		),
	};
}

export function addStepToUnconditionalSet(
	set: UnconditionalForwardingSet,
	delay: number,
	destination: ForwardingDestination
): TimeBasedForwardingSet {
	const newStep = findDelayAndPositionForNewForwardingStep(set.unconditional, 'unconditional');

	if (!newStep) {
		return set;
	}

	return {
		...set,
		unconditional: addForwardingStepToSteps(
			{
				delay,
				destination,
			},
			'unconditional',
			newStep.position,
			set.unconditional
		),
	};
}

export function replaceStepInUnconditionalSet(
	set: UnconditionalForwardingSet,
	delay: number,
	destination: ForwardingDestination,
	stepIndex: number
): TimeBasedForwardingSet {
	return {
		...set,
		unconditional: set.unconditional.map((step, i) =>
			i === stepIndex ? { delay, destination } : step
		),
	};
}

export function replaceStepInConditionalSet(
	set: ConditionalForwardingSet,
	type: 'online' | 'busy' | 'offline',
	delay: number,
	destination: ForwardingDestination,
	stepIndex: number
): TimeBasedForwardingSet {
	return {
		...set,
		[type]: set[type].map((step, i) => (i === stepIndex ? { delay, destination } : step)),
	};
}

function generateIntervalFromActiveTime(activeTime: ActiveTime) {
	const [startHour, startMinute] = activeTime.startTime.split(':').map(str => parseInt(str, 10));
	const [endHour, endMinute] = activeTime.endTime.split(':').map(str => parseInt(str, 10));
	const activeStart = DateTime.fromObject(
		{ hour: startHour, minute: startMinute },
		{ zone: activeTime.timeZone }
	);
	const activeEnd = DateTime.fromObject(
		{ hour: endHour, minute: endMinute },
		{ zone: activeTime.timeZone }
	);

	return Interval.fromDateTimes(activeStart, activeEnd);
}

function isFullDay(dayWithTime: { startTime: string; endTime: string }[]) {
	if (dayWithTime.length > 1) {
		return false;
	}
	return dayWithTime[0].startTime === '00:00:00' && dayWithTime[0].endTime === '23:59:59';
}

function startsMidnight(dayWithTime: { startTime: string; endTime: string }[]) {
	return dayWithTime.find(time => time.startTime === '00:00:00');
}

function getDurationBeyondToday(
	currentWeekDayIndex: number,
	dayWithTimes: Record<number, { startTime: string; endTime: string }[]>,
	now: DateTime
) {
	let nextDayIndex = currentWeekDayIndex + 1;
	let additionalDays = 0;
	while (additionalDays < 7) {
		if (dayWithTimes[nextDayIndex] && isFullDay(dayWithTimes[nextDayIndex])) {
			nextDayIndex = (nextDayIndex + 1) % 7;
			additionalDays += 1;
		} else {
			if (!dayWithTimes[nextDayIndex] || !startsMidnight(dayWithTimes[nextDayIndex])) {
				return now
					.endOf('day')
					.plus(Duration.fromObject({ days: additionalDays }))
					.diff(now, ['day', 'hour', 'minute', 'seconds'])
					.toObject();
			}
			const time = dayWithTimes[nextDayIndex].find(value => value.startTime === '00:00:00');
			return now
				.endOf('day')
				.plus(Duration.fromObject({ days: additionalDays }))
				.plus(
					Duration.fromObject({
						hours: Number.parseInt(time?.endTime?.split(':')[0] ?? '', 10),
						// add one minute to the end time to make sure the end time is included
						minutes: Number.parseInt(time?.endTime?.split(':')[1] ?? '', 10) + 1,
					})
				)
				.diff(now, ['day', 'hour', 'minute', 'seconds'])
				.toObject();
		}
	}
	return now.endOf('day').diff(now, ['day', 'hour', 'minute', 'seconds']).toObject();
}

function getDurationUntilNextTimeProfile(activeTimes: ActiveTime[], now: DateTime) {
	const week: DayOfWeek[] = [
		'MONDAY',
		'TUESDAY',
		'WEDNESDAY',
		'THURSDAY',
		'FRIDAY',
		'SATURDAY',
		'SUNDAY',
	];
	const dayWithTimes: Record<number, { startTime: string; endTime: string }[]> = {};
	const currentWeekDayIndex = now.weekday - 1;

	week.forEach((day, index) => {
		activeTimes
			.filter(time => time.days.includes(day))
			.forEach(activeTime => {
				if (!dayWithTimes[index]) {
					dayWithTimes[index] = [];
				}
				dayWithTimes[index].push({
					startTime: activeTime.startTime,
					endTime: activeTime.endTime,
				});
			});
	});

	if (!dayWithTimes[currentWeekDayIndex]) {
		return null;
	}

	const today = dayWithTimes[currentWeekDayIndex];
	const currentActiveTime = today.find(
		time => time.startTime <= now.toFormat('HH:mm:ss') && time.endTime >= now.toFormat('HH:mm:ss')
	);

	if (!currentActiveTime) {
		return null;
	}

	if (currentActiveTime.endTime === '23:59:59') {
		return getDurationBeyondToday(currentWeekDayIndex, dayWithTimes, now);
	}

	const endtime = DateTime.fromObject(
		{
			year: now.year,
			month: now.month,
			day: now.day,
			hour: Number.parseInt(currentActiveTime?.endTime.split(':')[0] ?? '', 10),
			minute: Number.parseInt(currentActiveTime?.endTime.split(':')[1] ?? '', 10),
			second: Number.parseInt(currentActiveTime?.endTime.split(':')[2] ?? '', 10),
		},
		{ zone: now.zone }
	);

	return endtime.diff(now, ['day', 'hour', 'minute', 'seconds']).toObject();
}

export function isForwardingSetActive(now: DateTime, activeTimes: ActiveTime[]) {
	const week: DayOfWeek[] = [
		'MONDAY',
		'TUESDAY',
		'WEDNESDAY',
		'THURSDAY',
		'FRIDAY',
		'SATURDAY',
		'SUNDAY',
	];
	const weekIndex = now.weekday - 1;

	return activeTimes.some(
		activeTime =>
			activeTime.days.includes(week[weekIndex]) &&
			generateIntervalFromActiveTime(activeTime).contains(now)
	);
}

export const getDefaultAnnouncementDestination = (
	forwardingSetType: 'dateBased' | 'timeBased',
	dateBasedType?: 'HOLIDAY' | 'CUSTOM'
): AnnouncementDestination | PhonenumberDestination => {
	if (forwardingSetType === 'dateBased' && dateBasedType === 'HOLIDAY') {
		return defaultAcdAnnouncementHolidayDestination;
	}
	return defaultAcdAnnouncementDestination;
};

export function getDurationToEndTime(
	now: DateTime,
	activeTimes: ActiveTime[],
	nextDefinedHoliday: DateTime | null
) {
	if (isFullWeek(activeTimes)) {
		if (!nextDefinedHoliday) {
			return null;
		}
		return nextDefinedHoliday.diff(now, ['day', 'hour', 'minute', 'seconds']).toObject();
	}

	const durationTilNextTimeProfile = getDurationUntilNextTimeProfile(activeTimes, now);

	if (!durationTilNextTimeProfile) {
		return null;
	}

	if (!nextDefinedHoliday) {
		return durationTilNextTimeProfile;
	}

	const durationTilNextHoliday = nextDefinedHoliday.diff(now, ['day', 'hour', 'minute', 'seconds']);

	if (durationTilNextHoliday < Duration.fromObject(durationTilNextTimeProfile)) {
		return durationTilNextHoliday.toObject();
	}

	return durationTilNextTimeProfile;
}
