import React from 'react';
import { Redirect } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, ReduxProps } from '../redux';

import { fetchLinks } from '../redux/slices/links';
import { ReduxState } from '../redux/types';
import { ViewStackConsumer } from '../foundation/view-stack';

const mapDispatchToProps = {
	fetchLinks,
};

const mapStateToProps = (state: ReduxState) => ({
	links: state.links,
});

interface ExternalProps {
	postFix?: string;
}

type Props = ExternalProps &
	RouteComponentProps &
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class PageNotFoundRedirect extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchLinks();
	}

	private renderRedirects(parent: string | null) {
		if (parent && parent !== this.props.location.pathname) {
			return <Redirect to={`${parent}${this.props.postFix ?? ''}`} />;
		}

		if (!this.props.links.fetched) {
			return null;
		}

		return (
			<Redirect
				to={`${new URL(this.props.links.items.startUpPageUrl).pathname}${this.props.postFix ?? ''}`}
			/>
		);
	}

	public render() {
		/*
		 * We need to set the key here to make sure the redirect is rendered on every location update. Otherwise if
		 * multiple instances of the component are mounted (e.g. when a dialog is open), the redirect
		 * might not trigger.
		 */
		return (
			<ViewStackConsumer key={this.props.location.key}>
				{({ mountPoint, viewStack }) => {
					return this.renderRedirects(viewStack[viewStack.length - 2]?.path || mountPoint || null);
				}}
			</ViewStackConsumer>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageNotFoundRedirect));
