import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { createApiClient, deleteApiClient, fetchApiClients, updateApiClient } from './actions';
import { SliceData, useAction } from '../../index';
import { ApiClient } from './types';

export const useApiClients = (): SliceData<ApiClient[]> => {
	const dispatch = useDispatch();
	const state = useSelector(s => s.apiClients);

	useEffect(() => {
		dispatch(fetchApiClients());
	}, [dispatch]);

	if (!state.fetched) {
		return {
			data: null,
			fetched: false,
		};
	}

	return { data: state.apiClients, fetched: state.fetched };
};

export const useApiClientsActions = () => ({
	createApiClient: useAction(createApiClient),
	deleteApiClient: useAction(deleteApiClient),
	updateApiClient: useAction(updateApiClient),
});
