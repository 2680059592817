import { handleActions } from '../..';
import { DataAutomationHistoryState } from './types';
import * as actions from './actions';
import { ApiError } from '../../../api';

const initialState: DataAutomationHistoryState = {
	items: [],
	fetchingForContainer: [],
	fetchedForContainer: [],
};

export default handleActions<DataAutomationHistoryState, PossibleActions<typeof actions>>(
	{
		DATA_AUTOMATION_HISTORY_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForContainer: [
				...state.fetchingForContainer.filter(containerId => containerId !== data.containerId),
				data.containerId,
			],
		}),
		DATA_AUTOMATION_HISTORY_FETCH_SUCCESS: (state, { data, payload }) => {
			const fetchedItem = {
				containerId: data.containerId,
				count: payload.items.length,
			};

			const itemsAfterUpdate = [
				...state.items.filter(item => item.containerId !== data.containerId),
				fetchedItem,
			];

			return {
				items: itemsAfterUpdate,
				fetchingForContainer: state.fetchingForContainer.filter(
					containerId => containerId !== data.containerId
				),
				fetchedForContainer: [
					...state.fetchedForContainer.filter(containerId => containerId !== data.containerId),
					data.containerId,
				],
			};
		},
		DATA_AUTOMATION_HISTORY_FETCH_FAILURE: (state, { data, payload }) => {
			if (payload instanceof ApiError && payload.status !== 404) {
				return state;
			}

			return {
				...state,
				fetchingForContainer: state.fetchingForContainer.filter(
					containerId => containerId !== data.containerId
				),
				fetchedForContainer: [
					...state.fetchedForContainer.filter(containerId => containerId !== data.containerId),
					data.containerId,
				],
			};
		},
	},
	initialState
);
