import { createSlice } from '@reduxjs/toolkit';
import { Tacs } from './types';

import * as actions from './actions';

export const initialState: Tacs = {
	accepted: false,
	fetching: false,
	fetched: false,
};

const slice = createSlice({
	name: 'tacs',
	reducers: {},
	initialState,
	extraReducers: builder => {
		builder.addCase(actions.fetchTacs.pending, state => ({
			...state,
			fetching: true,
		}));
		builder.addCase(actions.fetchTacs.fulfilled, (state, { payload }) => ({
			fetching: false,
			fetched: true,
			accepted: payload.accepted,
		}));
	},
});

export const reducer = slice.reducer;
