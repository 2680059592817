import { State } from '../../state';
import { AttachLabelPendingAction } from './types';

export const attachLabelPending = (state: State, action: AttachLabelPendingAction): State => {
	if (!state.entries.result || !state.labels) {
		return state;
	}

	const label = state.labels.find(stateLabel => stateLabel.id === action.labelId);
	if (!label) {
		return state;
	}

	return {
		...state,

		entries: {
			...state.entries,

			result: {
				...state.entries.result,

				normalized: state.entries.result.normalized.map(entry => {
					if (!entry.selected) {
						return entry;
					}

					return {
						...entry,

						originalEvent: {
							...entry.originalEvent,
							labels: entry.originalEvent.labels.find(entryLabel => entryLabel.id === label.id)
								? entry.originalEvent.labels
								: [...entry.originalEvent.labels, label].sort((a, b) =>
										a.name.localeCompare(b.name)
									),
						},
					};
				}),
			},
		},
	};
};
