/* eslint-disable camelcase */
import originalMixpanel, { Mixpanel, Property } from 'mixpanel-browser';
import { UserInfo } from '../redux/modules/userinfo';
import { environment, normalizePath, opaqueAccountId, opaqueUserId } from './utils';

function getToken() {
	if (environment() === 'live') {
		return 'ba6490d191f97adf977a1293a1084b53';
	}

	return '5e2eedfb2db9a53766edab1380f6017e';
}

/**
 * We create a fake mixpanel object because the default mixpanel object is not complete before being initialized.
 * We want to avoid crashing our application if something calls mixpanel before initialization.
 */
// eslint-disable-next-line import/no-mutable-exports
export let mixpanel: Mixpanel = {
	add_group: () => {},
	alias: () => {},
	clear_opt_in_out_tracking: () => {},
	disable: () => {},
	get_distinct_id: () => '',
	has_opted_in_tracking: () => false,
	has_opted_out_tracking: () => false,
	identify: () => {},
	init: () => {},
	opt_in_tracking: () => {},
	opt_out_tracking: () => {},
	push: () => {},
	register: () => {},
	register_once: () => {},
	remove_group: () => {},
	reset: () => {},
	set_config: () => {},
	set_group: () => {},
	time_event: () => {},
	track: () => {},
	track_with_group: () => {},
	unregister: () => {},
	people: {
		append: () => {},
		clear_charges: () => {},
		delete_user: () => {},
		increment: () => {},
		remove: () => {},
		set: () => {},
		set_once: () => {},
		track_charge: () => {},
		union: () => {},
		unset: () => {},
	},
};

export function trackPageView(isRedirect: boolean) {
	mixpanel.register({
		'Page Hostname': window.location.hostname,
		'Page Path': normalizePath(window.location.pathname),
		'Page Hash': normalizePath(window.location.hash) || null,
		'Page Query': window.location.search || null,
		'Page Level': window.location.pathname.split('/').length - 1,
		'Page Redirected': isRedirect,
	});
	mixpanel.track('View Page');

	mixpanel.people.increment('Lifetime Views on Pages', 1);
}

export function trackClick(target: string, meta?: Property) {
	mixpanel.track('Click', { 'Click Target': target, 'Click Meta': meta || {} });
}

export const isABTestingGroupA = (masterSipId: string) => {
	return parseInt(masterSipId, 10) % 2 !== 0;
};

export function init(userInfo: UserInfo, isPreviewBaseContract: boolean) {
	if (mixpanel === originalMixpanel) {
		return;
	}

	try {
		originalMixpanel.init(getToken(), {
			api_host: 'https://api-eu.mixpanel.com',
			autotrack: false,
			persistence: 'localStorage',
			track_pageview: false,
			disable_cookie: true,
			ignore_dnt: true,
		});

		mixpanel = originalMixpanel as Mixpanel;

		mixpanel.identify(opaqueUserId(userInfo));
		mixpanel.set_group('account_id', opaqueAccountId(userInfo));

		mixpanel.register({
			Product: userInfo.product,
			'Product Domain': userInfo.domain,
			Admin: userInfo.isAdmin,
			Testaccount: userInfo.isTestAccount,
			Client: 'app-web',
			PreviewBaseContract: isPreviewBaseContract,
			ABTestingGroup: isABTestingGroupA(userInfo.masterSipId) ? 'A' : 'B',
		});

		mixpanel.people.set({
			Product: userInfo.product,
			'Product Domain': userInfo.domain,
			Admin: userInfo.isAdmin,
			Testaccount: userInfo.isTestAccount,
			PreviewBaseContract: isPreviewBaseContract,
			ABTestingGroup: isABTestingGroupA(userInfo.masterSipId) ? 'A' : 'B',
		});
	} catch (e) {
		// ignore errors
	}
}
