import { handleActions } from '../..';
import { DataAutomationState } from './types';
import * as actions from './actions';
import { ApiError } from '../../../api';

const initialState: DataAutomationState = {
	items: [],
	fetchingForContainer: [],
	fetchedForContainer: [],
};

export default handleActions<DataAutomationState, PossibleActions<typeof actions>>(
	{
		DATA_AUTOMATION_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForContainer: [
				...state.fetchingForContainer.filter(containerId => containerId !== data.containerId),
				data.containerId,
			],
		}),
		DATA_AUTOMATION_FETCH_SUCCESS: (state, { data, payload }) => {
			const fetchedItem = {
				containerId: data.containerId,
				productId: payload.productId,
				limit: payload.limit,
			};

			const itemsAfterUpdate = [
				...state.items.filter(item => item.containerId !== data.containerId),
			];
			if (payload.productId) {
				itemsAfterUpdate.push(fetchedItem);
			}

			return {
				items: itemsAfterUpdate,
				fetchingForContainer: state.fetchingForContainer.filter(
					containerId => containerId !== data.containerId
				),
				fetchedForContainer: [
					...state.fetchedForContainer.filter(containerId => containerId !== data.containerId),
					data.containerId,
				],
			};
		},
		DATA_AUTOMATION_FETCH_FAILURE: (state, { data, payload }) => {
			if (!(payload instanceof ApiError) || payload.status !== 404) {
				return state;
			}

			return {
				...state,
				fetchingForContainer: state.fetchingForContainer.filter(
					containerId => containerId !== data.containerId
				),
				fetchedForContainer: [
					...state.fetchedForContainer.filter(containerId => containerId !== data.containerId),
					data.containerId,
				],
			};
		},
		DATA_AUTOMATION_DELETE_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.filter(item => item.containerId !== data.containerId),
			};
		},
		DATA_AUTOMATION_SET_PENDING: (state, { data }) => {
			const itemsAfterUpdate = [
				...state.items.filter(item => item.containerId !== data.containerId),
			];

			itemsAfterUpdate.push({
				containerId: data.containerId,
				productId: data.productId,
				limit: data.limit,
			});

			return {
				...state,
				items: itemsAfterUpdate,
			};
		},
	},
	initialState
);
