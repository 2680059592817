import { Notifications, BaseNotification } from '../../../api/types/notifications';

export function selectMissedNotifications<T extends BaseNotification>(notifications: T[]) {
	return notifications.filter(notification => notification.endpointId.startsWith('p'));
}

export function selectNotifications(notificationState: Notifications, notificationId: string) {
	const notifications = [
		...notificationState.call,
		...notificationState.fax,
		...notificationState.voicemail,
	];

	for (const notification of notifications) {
		for (const email of notification.emails) {
			if (email.id === notificationId) {
				return email;
			}
		}

		for (const sms of notification.sms) {
			if (sms.id === notificationId) {
				return sms;
			}
		}
	}

	for (const notification of notificationState.sms) {
		for (const email of notification.emails) {
			if (email.id === notificationId) {
				return email;
			}
		}
	}

	return null;
}

export function selectFaxReportEmail(notificationState: Notifications, faxlineId: string) {
	const faxlineNotifications = notificationState.fax.find(
		notification => notification.endpointId === faxlineId
	);

	if (!faxlineNotifications) {
		return null;
	}

	return faxlineNotifications.reports[0] || null;
}
