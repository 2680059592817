import api from '../../../api';
import { ReduxState } from '../../types';
import { ForwardingDestination } from '../../../api/types/forwardings';
import {
	CustomDateBasedForwarding,
	DateBasedForwarding,
	HolidayDateBasedForwarding,
} from './types';
import { ApiDateBasedSet } from '../../../api/types/dateBasedSets';
import { createAsyncThunk } from '../../utils/wrapper';

const shouldFetchDateBasedForwardings = (state: ReduxState, extensionId: string) =>
	!state.dateBasedForwardings.fetchingForExtension.includes(extensionId) &&
	!state.dateBasedForwardings.fetchedForExtension.includes(extensionId);

export const fetchDateBasedForwardings = createAsyncThunk(
	'dateBasedForwardings/fetch',
	async (data: { extensionId: string; force?: boolean }) => {
		const dateBasedSets = await api.getDateBasedSets(data.extensionId);
		if (!dateBasedSets || dateBasedSets.items.length < 1) {
			return Promise.resolve([]);
		}

		return Promise.all(
			dateBasedSets.items.map(async dateBasedSet => {
				const destination = await api
					.getDateBasedForwarding(data.extensionId, dateBasedSet.id)
					.catch(() => {
						return null;
					});

				if (dateBasedSet.type === 'HOLIDAY') {
					return {
						id: dateBasedSet.id,
						type: dateBasedSet.type,
						holidayPresetId: dateBasedSet.preset,
						selectedHolidayIds: dateBasedSet.selectedHolidays,
						name: dateBasedSet.name,
						extensionId: data.extensionId,
						destination,
					};
				}

				return {
					id: dateBasedSet.id,
					type: dateBasedSet.type,
					name: dateBasedSet.name,
					customDates: dateBasedSet.customDates,
					extensionId: data.extensionId,
					destination,
				};
			})
		);
	},
	{
		condition: (arg, { getState }) =>
			arg.force || shouldFetchDateBasedForwardings(getState(), arg.extensionId),
	}
);

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

const convertToApiDateBasedForwarding = (
	dateBasedForwarding: Optional<DateBasedForwarding, 'id'>
): Optional<ApiDateBasedSet, 'id'> => {
	switch (dateBasedForwarding.type) {
		case 'HOLIDAY':
			return {
				id: dateBasedForwarding.id,
				name: dateBasedForwarding.name || '',
				type: 'HOLIDAY',
				preset: dateBasedForwarding.holidayPresetId,
				selectedHolidays: dateBasedForwarding.selectedHolidayIds,
			};
		case 'CUSTOM':
			return {
				id: dateBasedForwarding.id,
				name: dateBasedForwarding.name,
				type: 'CUSTOM',
				customDates: dateBasedForwarding.customDates,
			};
	}
	throw new Error(`Invalid type: ${dateBasedForwarding}`);
};

export const saveDateBasedForwardings = createAsyncThunk(
	'dateBasedForwardings/save',
	async (data: {
		extensionId: string;
		currentDateBasedForwardings: DateBasedForwarding[];
		newDateBasedForwarding?:
			| Omit<HolidayDateBasedForwarding, 'id'>
			| Omit<CustomDateBasedForwarding, 'id'>;
	}) => {
		const dateBasedForwardings: (
			| Optional<HolidayDateBasedForwarding, 'id'>
			| Optional<CustomDateBasedForwarding, 'id'>
		)[] = [];

		if (data.currentDateBasedForwardings.length > 0) {
			dateBasedForwardings.push(...data.currentDateBasedForwardings);
		}

		if (data.newDateBasedForwarding) {
			dateBasedForwardings.push(data.newDateBasedForwarding);
		}

		const request: Optional<ApiDateBasedSet, 'id'>[] = dateBasedForwardings.map(
			dateBasedForwarding => convertToApiDateBasedForwarding(dateBasedForwarding)
		);

		return api.saveDateBasedSets(data.extensionId, request);
	}
);

export const deleteDateBasedForwarding = createAsyncThunk(
	'dateBasedForwardings/save',
	async (data: {
		extensionId: string;
		currentDateBasedForwardings: DateBasedForwarding[];
		dateBasedSetId: string;
	}) =>
		api.saveDateBasedSets(
			data.extensionId,
			data.currentDateBasedForwardings
				.filter(dateBasedForwarding => dateBasedForwarding.id !== data.dateBasedSetId)
				.map(convertToApiDateBasedForwarding)
		)
);

export const saveDateBasedForwardingDestination = createAsyncThunk(
	'dateBasedForwardings/saveDestination',
	async (data: {
		extensionId: string;
		dateBasedSetId: string;
		destination: ForwardingDestination;
	}) => api.saveDateBasedForwarding(data.extensionId, data.dateBasedSetId, data.destination)
);
