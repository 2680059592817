import React, { AriaAttributes } from 'react';
import { VisualSearch } from './VisualSearch';

type Props = {
	/**
	 * Setzt den aktuellen Eingabewert
	 */
	value: string;

	/**
	 * Falls onChange eine länger laufende Aktion (z.Bsp. einen API-Request) triggert,
	 * achte darauf, den Handler zu [debouncen](https://lodash.com/docs/4.17.15#debounce) und
	 * jeweils die vorherige evtl. noch laufende Aktion abzubrechen.
	 *
	 * [AbortController](https://developer.mozilla.org/en-US/docs/Web/API/AbortController) sind hier nützlich.
	 *
	 * Ansonsten kann es sein, das Ergebnisse springen und sich unter dem Cursor der Nutzer:in bewegen können.
	 */
	onChange: (value: string) => void;

	/**
	 * Der `placeholder` ist sichtbar, wenn das Eingabefeld leer ist.
	 */
	placeholder: string;

	/**
	 * `disabled` verhindert die Eingabe in das Feld und entfernt es
	 * aus dem Accessibility-Tree (s. auch `readOnly`).
	 */
	disabled?: boolean;

	/**
	 * Der `resultCount` gibt die Anzahl der zutreffenden Ergebnisse der Suche an.
	 */
	resultCount: number;
} & (
	| ({
			landmark?: undefined;
			ariaLabel: string;
			role?: 'combobox';
	  } & AriaAttributes)
	| {
			/**
			 * `landmark` entscheidet, ob `role="search"` gesetzt wird.
			 * Setze diese Prop, wenn es sich um die "Haupt-"Suche der Seite
			 * handelt.
			 */
			landmark: true;
			ariaLabel?: string;
	  }
);

export const Search = ({
	value,
	onChange,
	disabled,
	placeholder,
	landmark,
	resultCount,
	ariaLabel,
	...ariaProps
}: Props): JSX.Element => {
	return (
		<VisualSearch
			disabled={disabled}
			landmark={!!landmark}
			onChange={onChange}
			placeholder={placeholder}
			resultCount={resultCount}
			value={value}
			ariaLabel={ariaLabel}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...ariaProps}
		/>
	);
};
