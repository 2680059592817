import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

export const saferPayCallbackChannel = new BroadcastChannel('saferpay-callback');

export const SaferPayCallback = (props: RouteComponentProps<{ result: string }>) => {
	useEffect(() => {
		saferPayCallbackChannel.postMessage({ success: props.match.params.result === 'success' });
		window.close();
	}, [props.match.params.result]);

	return null;
};
