import React from 'react';

import * as instana from '../third-party/instana';

interface Props {
	children?: React.ReactNode;
}
interface State {
	error: Error | null;
}

export class ErrorHandler extends React.Component<Props, State> {
	public state: State = {
		error: null,
	};

	public componentDidCatch(error: Error, info: React.ErrorInfo) {
		// eslint-disable-next-line no-restricted-syntax
		console.error('caught error in ErrorHandler', error);
		instana.reportError(error, info.componentStack);

		this.setState({
			error,
		});
	}

	public render() {
		const { error } = this.state;

		if (error) {
			return <div className="error-handler smoke-test-canary" />;
		}

		return this.props.children;
	}
}
