/* eslint-disable react/no-unused-prop-types */
import React from 'react';

type Props = {
	/**
	 * Der Wert der zum Weiterarbeiten übergeben wird.
	 */
	value: string;
	/**
	 * Gibt an, ob die Card disabled ist oder nicht.
	 */
	disabled?: boolean;
	/**
	 * Das Label des Radio.
	 */
	label: string;
	/**
	 * Optionale Beschreibung zur Card hinzufügen. Bspw. eine IBAN bei einem Konto.
	 */
	description?: string;
	/**
	 * Fügt bspw. ein Bild rechts in der Card Komponente hinzu.
	 */
	additional?: React.ReactNode;
};

/**
 * This components exist so the usage of <RadioGroup/> has a pretty API
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RadioCard = (props: Props) => null;
