import { useEffect } from 'react';
import { SipgateStatus } from './types';
import { fetchSipgateStatus, forceFetchSipgateStatus } from './actions';
import { SliceData } from '../../index';
import { useDispatch, useSelector } from '../../utils/wrapper';

export const useSipgateStatus = (interval = 60000, autoUpdate = true): SliceData<SipgateStatus> => {
	const dispatch = useDispatch();

	const sipgateStatus = useSelector(state => state.sipgateStatus);

	useEffect(() => {
		dispatch(fetchSipgateStatus());
	}, [dispatch]);

	useEffect(() => {
		if (!autoUpdate) {
			return;
		}

		const id = setInterval(() => dispatch(forceFetchSipgateStatus()), interval);
		return () => {
			clearInterval(id);
		};
	}, [interval, autoUpdate, dispatch]);

	if (sipgateStatus.fetched) {
		return {
			fetched: true,
			data: sipgateStatus.data,
		};
	}

	return {
		fetched: false,
		data: null,
	};
};
