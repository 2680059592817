import { handleActions } from '../..';
import { DevicePropertiesState } from './types';
import { DeviceProperty } from '../../../api/types/DeviceProperty';
import * as actions from './actions';

const initialState: DevicePropertiesState = {
	items: [],
	fetchingForDevice: [],
	fetchedForDevice: [],
};

export default handleActions<DevicePropertiesState, PossibleActions<typeof actions>>(
	{
		DEVICE_PROPERTIES_FETCH_PENDING: (state, { data }) => {
			return {
				...state,
				fetchingForDevice: [
					...state.fetchingForDevice.filter(item => item !== data.deviceId),
					data.deviceId,
				],
				fetchedForDevice: state.fetchedForDevice.filter(item => item !== data.deviceId),
			};
		},
		DEVICE_PROPERTIES_FETCH_SUCCESS: (state, { payload, data }) => {
			const newItems: DeviceProperty[] = payload.properties.map((item: DeviceProperty) => ({
				...item,
				deviceId: data.deviceId,
			}));

			const itemsWithoutNew = state.items.filter(item => {
				return !newItems.find(
					newItem => newItem.deviceId === item.deviceId && newItem.key === item.key
				);
			});

			return {
				...state,
				fetchingForDevice: state.fetchingForDevice.filter(item => item !== data.deviceId),
				fetchedForDevice: [
					...state.fetchedForDevice.filter(item => item !== data.deviceId),
					data.deviceId,
				],
				items: [...newItems, ...itemsWithoutNew],
			};
		},
		DEVICE_PROPERTY_SET_SUCCESS: (state, { data }) => {
			const itemsWithoutNew = state.items.filter(item => {
				return !(data.deviceId === item.deviceId && data.key === item.key);
			});

			return {
				...state,
				fetchingForDevice: state.fetchingForDevice.filter(item => item !== data.deviceId),
				fetchedForDevice: [
					...state.fetchedForDevice.filter(item => item !== data.deviceId),
					data.deviceId,
				],
				items: [data, ...itemsWithoutNew],
			};
		},
	},
	initialState
);
