import classnames from 'classnames';

export const circle = (
	isDisabled: boolean,
	isChecked: boolean,
	classesActive: string,
	classesHover: string
) =>
	classnames(
		'w-16',
		'h-16',
		'm-0',
		'rounded-full',
		/* An outline-none creates a glitch on click in the App-Web */
		'appearance-none',
		'transition-all',
		'duration-75',
		'ease-linear',
		isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
		isChecked &&
			!isDisabled && [
				'bg-neo-color-global-content-primary-on-intense',
				'border-5',
				'border-neo-color-global-border-primary-intense-default',
				'group-hover:border-neo-color-global-border-primary-intense-hover',
				'group-active:border-neo-color-global-border-primary-intense-active',
				'focus-visible:ring-focus',
			],
		isChecked &&
			isDisabled && [
				'border-5',
				'bg-neo-color-global-content-neutral-disabled',
				'border-neo-color-global-border-primary-intense-disabled',
			],
		!isChecked &&
			!isDisabled && [
				'bg-neo-color-global-background-neutral-soft-default',
				'border-2',
				'border-neo-color-global-border-neutral-intense-default',
				'focus-visible:ring-focus',
				'group-hover:border-neo-color-global-border-primary-intense-default',
				'group-active:border-neo-color-global-border-primary-intense-default',
				classesActive,
				classesHover,
			],
		!isChecked &&
			isDisabled && ['border-0', 'bg-neo-color-global-background-neutral-intense-disabled']
	);
