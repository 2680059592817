import React from 'react';
import { throttle } from 'lodash';
import { createPopper, Instance as Popper } from '@popperjs/core';
import type { StrictModifiers } from '@popperjs/core';
import classnames from 'classnames';
import classes from './Tooltip.scss';

interface ExternalProps {
	children: React.ReactElement<{
		ref?: React.Ref<HTMLElement>;
		onClick: React.MouseEventHandler;
		onMouseOver: React.MouseEventHandler;
		onMouseOut: React.MouseEventHandler;
	}>;
	overlay: React.ReactNode;
	light?: boolean;
	placement?: 'bottom' | 'top' | 'left' | 'right';
}

interface State {
	isOpen: boolean;
}

export class Tooltip extends React.PureComponent<ExternalProps, State> {
	public state: State = {
		isOpen: false,
	};

	private popper: Popper | null = null;

	private dropdownHandlerRef: React.RefObject<HTMLElement> = React.createRef();

	private dropdownRef: React.RefObject<HTMLDivElement> = React.createRef();

	public componentDidMount() {
		window.addEventListener('resize', this.close);
		window.addEventListener('wheel', this.close);
		window.addEventListener('touchmove', this.close);

		if (this.dropdownHandlerRef.current && this.dropdownRef.current) {
			this.popper = createPopper<StrictModifiers>(
				this.dropdownHandlerRef.current,
				this.dropdownRef.current,
				{
					placement: this.props.placement || 'bottom',
					// If we don't set this to fixed, we mess up scrolling on parent containers (like the event-list)
					// as we do not unmount a hidden tooltip.
					strategy: 'fixed',
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 8],
							},
						},
					],
				}
			);
		}
	}

	public componentWillUnmount() {
		window.removeEventListener('resize', this.close);
		window.removeEventListener('wheel', this.close);
		window.removeEventListener('touchmove', this.close);
		this.popper?.destroy();
	}

	public componentDidUpdate() {
		this.popper?.update();
	}

	private onMouseOver = () => {
		this.setState({
			isOpen: true,
		});
	};

	private close = throttle(
		() => {
			this.setState({
				isOpen: false,
			});
		},
		100,
		{ leading: true }
	);

	public render() {
		const child = this.props.children;

		/*
		 * Slight hack to ensure that our hover area is always the same as
		 * the childrens.
		 */
		const childWithEventHandlers = React.cloneElement(child, {
			ref: this.dropdownHandlerRef,

			onMouseOver: (e: React.MouseEvent) => {
				const returnValue = child.props.onMouseOver ? child.props.onMouseOver(e) : undefined;

				if (!e.isPropagationStopped() && !e.isDefaultPrevented()) {
					this.onMouseOver();
				}

				return returnValue;
			},

			onMouseOut: (e: React.MouseEvent) => {
				const returnValue = child.props.onMouseOut ? child.props.onMouseOut(e) : undefined;

				if (!e.isPropagationStopped() && !e.isDefaultPrevented()) {
					this.close();
				}

				return returnValue;
			},
		});

		return (
			<>
				{childWithEventHandlers}
				<div
					className={classnames(
						classes.tooltip,
						this.state.isOpen && classes.isOpen,
						this.props.light && classes.light
					)}
					ref={this.dropdownRef}
				>
					<div className={classes.tooltipBubble}>{this.props.overlay}</div>
					<div
						data-popper-arrow
						className={classnames(classes.arrow, {
							[classes.bottom]: !this.props.placement || this.props.placement === 'bottom',
							[classes.top]: this.props.placement === 'top',
							[classes.left]: this.props.placement === 'left',
							[classes.right]: this.props.placement === 'right',
						})}
					/>
				</div>
			</>
		);
	}
}
