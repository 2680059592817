import React from 'react';
import classnames from 'classnames';
import { DisabledContext, isDisabled } from '../../contexts/disabledContext';
import { BaseLink } from './BaseLink';
import { SetIcon } from '../../assets/icons/icon-set/SetIcon';

type GeneralProps = {
	children: string;
	/**
	 * Setze diese Prop nur, wenn das automatische Resizing bei Touch Devices dein Interface zerstört.
	 * Kümmere dich dann darum, dass die Touchfläche groß genug ist.
	 */
	deprecated?: boolean;
	/** Der disabled State kann auch über den Context gesteuert werden. [Info zu Disabled Context](../?path=/docs/utilities-disabledcontext--docs) */
	disabled?: boolean;
	/** Die Empfänger E-Mail Adresse */
	mail: string;
	/** Der Betreff der E-Mail */
	subject?: string;
	/** Der Text der E-Mail */
	body?: string;
	/** Weitere Empfänger:innen der E-Mail */
	cc?: string;
	/** Weitere geheime Empfänger:innen der E-Mail */
	bcc?: string;
};

type AsButtonProps = {
	button: true;
	/** Die Größen "small" und "xlarge" können nur in der variant="loud" gesetzt werden. */
	size?: 'small' | 'medium' | 'large' | 'xlarge';
	width?: 'content' | 'max' | 'max-on-touch-device';
	variant?: 'normal' | 'loud' | 'screaming';
};

type LinkProps = {
	button?: false;
	size?: never;
	width?: never;
	variant?: 'silent' | 'quiet' | 'normal';
};
const styles = {
	icon: (size: 'small' | 'medium' | 'large' | 'xlarge', disabled: boolean, button: boolean) =>
		classnames(
			'self-center',
			disabled && 'bg-neo-color-global-background-primary-intense-disabled',
			button && size === 'small' && 'mr-6',
			button && size === 'medium' && 'mr-8',
			button && size === 'large' && 'mr-10',
			button && size === 'xlarge' && 'mr-12',
			!button && ['mr-quarter-text']
		),
	text: classnames('whitespace-normal'),
};

type Props = GeneralProps & (AsButtonProps | LinkProps);

const MailLink = ({
	button = false,
	deprecated = false,
	children,
	disabled,
	mail,
	subject = '',
	body = '',
	cc = '',
	bcc = '',
	size = 'medium',
	width = 'content',
	variant = 'normal',
}: Props): JSX.Element => {
	const disabledContextValue = React.useContext(DisabledContext);

	const getIconSize = () => {
		switch (size) {
			case 'small':
				return '12';
			case 'medium':
				return '16';
			case 'large':
			case 'xlarge':
				return '24';
		}
	};

	const mailtoUrl = `mailto:${mail}?${subject && `subject=${encodeURIComponent(subject)}`}${
		body && `&body=${encodeURIComponent(body)}`
	}${cc && `&cc=${encodeURIComponent(cc)}`}${bcc && `&bcc=${encodeURIComponent(bcc)}`}`;

	return (
		<BaseLink
			button={button}
			deprecated={deprecated}
			disabled={disabled}
			size={size}
			width={width}
			type="mail"
			url={mailtoUrl}
			variant={variant}
			iconPosition="before"
		>
			{button ? (
				<SetIcon
					icon="mail"
					size={getIconSize()}
					touchSize={!deprecated ? '24' : undefined}
					className={styles.icon(size, isDisabled(disabled, disabledContextValue), button)}
				/>
			) : (
				<SetIcon
					icon="mail"
					inline
					size="text"
					className={styles.icon(size, isDisabled(disabled, disabledContextValue), button)}
				/>
			)}
			{'\uFEFF'}
			<span className={styles.text}>{children}</span>
		</BaseLink>
	);
};

export { MailLink };
