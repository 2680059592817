import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';
import { forceFetchRestrictions } from '../../modules/restrictions';

export const fetchConferenceRooms = createAsyncThunk(
	'conferenceRooms/fetch',
	() => api.getConferenceRooms(),
	{
		condition: (_, { getState }) =>
			!getState().conferenceRoom.fetched && !getState().conferenceRoom.fetching,
	}
);

export const forceFetchConferenceRooms = createAsyncThunk('conferenceRooms/forceFetch', async () =>
	api.getConferenceRooms()
);

export const createConferenceRoom = createAsyncThunk(
	'conferenceRooms/create',
	async (options: { productId: string }, { dispatch }) => {
		const result = await api.createConferenceRoom(options.productId);

		dispatch(forceFetchRestrictions(['CAN_CREATE_CONFERENCE_ROOM']));

		return result;
	}
);

export const renameConferenceRoom = createAsyncThunk(
	'conferenceRooms/rename',
	(options: { id: string; alias: string }) => api.renameConferenceRoom(options.id, options.alias)
);

export const cancelConferenceRoom = createAsyncThunk(
	'conferenceRooms/delete',
	async (options: { conferenceRoomId: string }, { dispatch }) => {
		const result = await api.cancelConferenceRoom(options.conferenceRoomId);

		dispatch(forceFetchRestrictions(['CAN_CREATE_CONFERENCE_ROOM']));

		return result;
	}
);

export const revokeConferenceRoomCancellation = createAsyncThunk(
	'conferenceRooms/revokeCancellation',
	async (options: { conferenceRoomId: string }, { dispatch }) => {
		const result = await api.revokeConferenceRoomCancellation(options.conferenceRoomId);

		dispatch(forceFetchRestrictions(['CAN_CREATE_CONFERENCE_ROOM']));

		return result;
	}
);

export const deleteGreeting = createAsyncThunk(
	'conferenceRooms/deleteGreeting',
	(options: { conferenceRoomId: string }) =>
		api.deleteConferenceRoomGreeting(options.conferenceRoomId)
);

export const setActiveAudioFileGreeting = createAsyncThunk(
	'conferenceRooms/setActiveAudioFileGreeting',
	(options: { conferenceRoomId: string; activeAudioFileId: string | null }) =>
		api.setActiveAudioFileGreetingForConferenceRoom(
			options.conferenceRoomId,
			options.activeAudioFileId
		)
);
