import React from 'react';
import { useTranslate } from '../../redux/slices/translations';
import { useUserInfo } from '../../redux/modules/userinfo';

import classes from './AccountNumber.scss';

export const AccountNumber = (props: { className?: string }) => {
	const userInfo = useUserInfo();
	const translate = useTranslate();

	return (
		<span className={props.className} data-test-selector="customer-id">
			{translate('CUSTOMER_ID')}{' '}
			<span
				aria-label={userInfo.masterSipId
					.split('')
					.map(i => `${i} `)
					.join('')}
				className={classes.copiable}
			>
				{userInfo.masterSipId}
			</span>
		</span>
	);
};
