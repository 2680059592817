import { createAction } from '@reduxjs/toolkit';

export const openDialog = createAction('snackbar/openDialog');

export const closeDialog = createAction('snackbar/closeDialog');

export const closeWarningSnackbar = createAction('snackbar/closeWarningDialog');

export const openWarningSnackbar = createAction(
	'snackbar/openWarningSnackbar',
	(warningSnackbarMessage: string) => ({
		payload: warningSnackbarMessage,
	})
);

export const closeErrorSnackbar = createAction('snackbar/closeErrorSnackbar');

export const openErrorSnackbar = createAction(
	'snackbar/openErrorSnackbar',
	(errorSnackbarMessage: string) => ({
		payload: errorSnackbarMessage,
	})
);

export const closeInvalidAccountStateSnackbar = createAction(
	'snacbkar/closeInvalidAccountStateSnackbar'
);

export const openInvalidAccountStateSnackbar = createAction(
	'snackbar/openInvalidAccountStateSnackbar'
);

export const closeInsufficientFundsSnackbar = createAction(
	'snackbar/closeInsufficientFundsSnackbar'
);

export const openInsufficientFundsSnackbar = createAction('snackbar/openInsufficientFundSnackbar');

export const openAutoCloseInfoSnackbar = createAction(
	'snackbar/openAutoCloseInfoSnackbar',
	(autoCloseInfoSnackbarMessage: string) => ({ payload: autoCloseInfoSnackbarMessage })
);

export const closeAutoCloseInfoSnackbar = createAction('snackbar/closeAutoCloseInfoSnackbar');

export const openAutoCloseSuccessSnackbar = createAction(
	'snackbar/openAutoCloseSuccessSnackbar',
	(autoCloseSuccessSnackbarMessage: string) => ({ payload: autoCloseSuccessSnackbarMessage })
);

export const closeAutoCloseSuccessSnackbar = createAction('snackbar/closeAutoCloseSuccessSnackbar');

export const openClickToDialSnackbar = createAction(
	'snackbar/openClickToDialSnackbar',
	(sessionId: string, alias: string) => ({ payload: { sessionId, alias } })
);

export const closeClickToDialSnackbar = createAction('snackbar/closeClickToDailSnackbar');
