import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router';
import { useTheme } from '@web-apps/theming';

import AutoCloseInfoSnackbar from '../components/messages/AutoCloseInfoSnackbar';
import AutoCloseSuccessSnackbar from '../components/messages/AutoCloseSuccessSnackbar';
import ErrorSnackbar from '../components/messages/ErrorSnackbar';
import PoliteLiveRegion from '../components/messages/PoliteLiveRegion';
import WarningSnackbar from '../components/messages/WarningSnackbar';
import { ImplicitAuthenticator } from '../login/ImplicitAuthenticator';
import { TokenAuthenticator } from '../login/TokenAuthenticator';
import AuthenticatedRoot from './AuthenticatedRoot';
import FaxdruckerLoginView from './FaxdruckerLoginView';
import SoftphoneLoginView from './SoftphoneLoginView';

export const Root = () => {
	useTheme();

	useEffect(() => {
		const animation = document.getElementById('loading-animation');

		if (animation) {
			setTimeout(() => {
				animation.remove();
			}, 600);

			animation.classList.add('exit');
		}
	}, []);

	return (
		<div style={{ height: '100%' }}>
			<Helmet>
				<title>sipgate | App</title>
			</Helmet>

			<Switch>
				{/* Not sure where this is used */}
				<Route path="/authenticate" component={TokenAuthenticator} />

				{/* Used during normal login */}
				<Route path="/implicit-auth-redirect" component={ImplicitAuthenticator} />

				{/* Used during login from softphone app */}
				<Route path="/app-login" component={SoftphoneLoginView} />

				{/* Used during login for faxdrucker */}
				<Route path="/faxdrucker-login" component={FaxdruckerLoginView} />

				<Route component={AuthenticatedRoot} />
			</Switch>

			<AutoCloseInfoSnackbar />
			<AutoCloseSuccessSnackbar />
			<WarningSnackbar />
			<ErrorSnackbar />
			<PoliteLiveRegion />
		</div>
	);
};
