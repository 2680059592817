import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Switch } from 'react-router';
import { Location } from 'history';
import parsePath from 'path-to-regexp';

interface Props {
	basepath: string;
	location: Location;
	children: React.ReactNode;
	transitionClasses: {
		enter?: string;
		enterActive?: string;
		exit?: string;
		exitActive?: string;
	};
	timeout: number;
}

export class OverlayTransition extends React.Component<Props> {
	public render() {
		const regexp = parsePath(`${this.props.basepath}/([^/]*)`, { end: false });
		const overlayIdMatch = this.props.location.pathname.match(regexp);

		const overlayId = overlayIdMatch ? overlayIdMatch[1] : undefined;

		return (
			<TransitionGroup>
				<CSSTransition
					key={overlayId}
					classNames={{
						enter: this.props.transitionClasses.enter,
						enterActive: this.props.transitionClasses.enterActive,
						exit: this.props.transitionClasses.exit,
						exitActive: this.props.transitionClasses.exitActive,
					}}
					timeout={this.props.timeout}
				>
					<Switch location={this.props.location}>{this.props.children}</Switch>
				</CSSTransition>
			</TransitionGroup>
		);
	}
}
