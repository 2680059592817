import { createSlice } from '@reduxjs/toolkit';
import { fetchGroupStatistics, fetchGroupStatisticsHistory } from './actions';
import { GroupStatisticsState } from './types';

const initialState: GroupStatisticsState = {
	items: {},
	fetchingForGroup: [],
	fetchedForGroup: [],
	fetchingHistoryForGroup: [],
	fetchedHistoryForGroup: [],
};

export const reducer = createSlice({
	name: 'groupStatistics',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchGroupStatistics.pending, (state, { meta }) => ({
				...state,
				fetchingForGroup: [...state.fetchingForGroup, meta.arg.groupId],
			}))
			.addCase(fetchGroupStatistics.fulfilled, (state, { payload, meta }) => ({
				...state,
				fetchingForGroup: [
					...state.fetchingForGroup.filter(groupId => groupId !== meta.arg.groupId),
				],
				fetchedForGroup: [
					...state.fetchedForGroup.filter(groupId => groupId !== meta.arg.groupId),
					meta.arg.groupId,
				],
				items: {
					...state.items,
					[meta.arg.groupId]: {
						data: {
							...state.items[meta.arg.groupId]?.data,
							last30Days: { ...payload, queueCurrentlyWaiting: undefined },
						},
						queueCurrentlyWaiting: payload.queueCurrentlyWaiting,
					},
				},
			}))
			.addCase(fetchGroupStatisticsHistory.pending, (state, { meta }) => ({
				...state,
				fetchingHistoryForGroup: [...state.fetchingHistoryForGroup, meta.arg.groupId],
			}))
			.addCase(fetchGroupStatisticsHistory.fulfilled, (state, { payload, meta }) => ({
				...state,
				fetchingHistoryForGroup: [
					...state.fetchingHistoryForGroup.filter(groupId => groupId !== meta.arg.groupId),
				],
				fetchedHistoryForGroup: [...state.fetchedHistoryForGroup, meta.arg.groupId],
				items: {
					...state.items,
					[meta.arg.groupId]: {
						queueCurrentlyWaiting: state.items[meta.arg.groupId]?.queueCurrentlyWaiting,
						data: {
							...(state.items[meta.arg.groupId]
								? state.items[meta.arg.groupId].data
								: {
										last30Days: {
											days: [],
										},
									}),
							...payload,
						},
					},
				},
			}));
	},
}).reducer;
