import { useEffect, useState } from 'react';

export type Theme = 'classic-light' | 'neo-dark' | 'neo-light';

let globalTheme: Theme | null = null;
const listeners = new Set<(theme: Theme) => void>();

const getTheme = (prefersDark: boolean) => {
	const savedTheme = localStorage.getItem('theme');

	if (savedTheme) {
		return savedTheme as Theme;
	}

	if (prefersDark) {
		// Support only lightmode until our redesign is done
		return 'classic-light';
	}

	return 'classic-light';
};

export const useTheme = () => {
	const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
	const [theme, setTheme] = useState<Theme>(() => {
		if (globalTheme === null) {
			globalTheme = getTheme(prefersDark.matches);
		}
		return globalTheme;
	});

	useEffect(() => {
		const handleThemeUpdate = (newTheme: Theme) => {
			setTheme(newTheme);
		};

		listeners.add(handleThemeUpdate);

		return () => {
			listeners.delete(handleThemeUpdate);
		};
	}, []);

	useEffect(() => {
		const handleThemeChange = (e: MediaQueryListEvent) => {
			const newTheme = getTheme(e.matches);
			globalTheme = newTheme;
			listeners.forEach(listener => listener(newTheme));
		};

		prefersDark.addEventListener('change', handleThemeChange);

		return () => {
			prefersDark.removeEventListener('change', handleThemeChange);
		};
	}, [prefersDark]);

	useEffect(() => {
		document.documentElement.classList.remove('classic-light', 'neo-dark', 'neo-light');
		document.documentElement.classList.add(theme);
	}, [theme]);

	const resetTheme = () => {
		localStorage.removeItem('theme');
		const newTheme = getTheme(prefersDark.matches);
		globalTheme = newTheme;
		listeners.forEach(listener => listener(newTheme));
	};

	const changeTheme = (newTheme: Theme) => {
		globalTheme = newTheme;
		localStorage.setItem('theme', newTheme);
		listeners.forEach(listener => listener(newTheme));
	};

	return {
		resetTheme,
		changeTheme,
		theme,
		isDark: theme === 'neo-dark',
	};
};
