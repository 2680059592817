import api from '../../../api';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';

const shouldFetchGroupStatistics = (state: ReduxState, groupId: string) =>
	!state.groupStatistics.fetchingForGroup.includes(groupId) &&
	!state.groupStatistics.fetchedForGroup.includes(groupId);

const shouldFetchGroupStatisticsHistory = (state: ReduxState, groupId: string) =>
	!state.groupStatistics.fetchingHistoryForGroup.includes(groupId) &&
	!state.groupStatistics.fetchedHistoryForGroup.includes(groupId);

export const fetchGroupStatistics = createAsyncThunk(
	'groupStatistics/fetch',
	async (data: { groupId: string; force?: boolean }) => api.getGroupStatistics(data.groupId),
	{
		condition: (arg, { getState }) =>
			arg.force || shouldFetchGroupStatistics(getState(), arg.groupId),
	}
);

export const fetchGroupStatisticsHistory = createAsyncThunk(
	'groupStatistics/fetchHistory',
	async (data: { groupId: string; force?: boolean }) => api.getGroupStatisticsHistory(data.groupId),
	{
		condition: (arg, { getState }) =>
			arg.force || shouldFetchGroupStatisticsHistory(getState(), arg.groupId),
	}
);
