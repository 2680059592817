import { createSlice } from '@reduxjs/toolkit';
import { Avatar, AvatarsState } from './types';
import { deleteUserAvatar, fetchUserAvatar, fetchUsersAvatars, uploadUserAvatar } from './actions';

export const initialState: AvatarsState = {
	uploading: false,
	uploaded: false,
	uploadError: false,
	fetchedForUser: [],
	fetchingForUser: [],
	items: [],
};

const slice = createSlice({
	name: 'avatars',
	initialState,
	reducers: {
		resetUploadError: state => ({ ...state, uploadError: false }),
		resetUploadSuccess: state => ({ ...state, uploaded: false }),
	},
	extraReducers: builder => {
		builder.addCase(fetchUsersAvatars.pending, state => ({
			...state,
			fetchingForUser: ['*'],
		}));
		builder.addCase(fetchUsersAvatars.fulfilled, (state, { payload }) => ({
			...state,
			items: payload.userAvatars,
			fetchedForUser: payload.userAvatars.map(avatar => avatar.webuserId),
			fetchingForUser: [],
		}));
		builder.addCase(fetchUsersAvatars.rejected, state => ({
			...state,
			fetchingForUser: [],
		}));

		builder.addCase(fetchUserAvatar.pending, (state, { meta }) => {
			const isAlreadyFetchingForUser =
				state.fetchingForUser.findIndex(webuserId => meta.arg.webuserId.endsWith(webuserId)) > -1;
			return {
				...state,
				fetchingForUser: isAlreadyFetchingForUser
					? state.fetchingForUser
					: [...state.fetchingForUser, meta.arg.webuserId],
			};
		});
		builder.addCase(fetchUserAvatar.fulfilled, (state, { meta, payload }) => {
			const index = state.items.findIndex(avatar => avatar.webuserId === meta.arg.webuserId);
			const hasAlreadyFetchedForUser =
				state.fetchedForUser.findIndex(webuserId => webuserId === meta.arg.webuserId) > -1;
			const fetchingForUserIndex = state.fetchingForUser.findIndex(
				webuserId => webuserId === meta.arg.webuserId
			);

			return {
				...state,
				items: [...state.items.slice(0, index), payload, ...state.items.slice(index + 1)],
				fetchingForUser: [
					...state.fetchingForUser.slice(0, fetchingForUserIndex),
					...state.fetchingForUser.slice(fetchingForUserIndex + 1),
				],
				fetchedForUser: hasAlreadyFetchedForUser
					? state.fetchedForUser
					: [...state.fetchedForUser, meta.arg.webuserId],
			};
		});
		builder.addCase(fetchUserAvatar.rejected, (state, { meta }) => {
			const index = state.items.findIndex(avatar => meta.arg.webuserId.endsWith(avatar.webuserId));
			return {
				...state,
				items: [...state.items.slice(0, index), ...state.items.slice(index + 1)],
			};
		});

		builder.addCase(uploadUserAvatar.pending, state => ({
			...state,
			uploading: true,
			uploaded: false,
			uploadError: false,
		}));
		builder.addCase(uploadUserAvatar.fulfilled, (state, { meta }) => {
			const index = state.items.findIndex(avatar => meta.arg.webuserId.endsWith(avatar.webuserId));

			const updatedItem = {
				webuserId: meta.arg.webuserId,
				group: 'UPLOADS',
				urls: {
					SMALL: meta.arg.base64Content,
					SMALL_2X: meta.arg.base64Content,
					SMALL_3X: meta.arg.base64Content,
					MEDIUM: meta.arg.base64Content,
					MEDIUM_2X: meta.arg.base64Content,
					MEDIUM_3X: meta.arg.base64Content,
					LARGE: meta.arg.base64Content,
					LARGE_2X: meta.arg.base64Content,
					LARGE_3X: meta.arg.base64Content,
					XLARGE: meta.arg.base64Content,
					XLARGE_2X: meta.arg.base64Content,
					XLARGE_3X: meta.arg.base64Content,
					BIGGEST: meta.arg.base64Content,
					BIGGEST_2X: meta.arg.base64Content,
					BIGGEST_3X: meta.arg.base64Content,
				},
			} as Avatar;

			return {
				...state,
				items: [...state.items.slice(0, index), updatedItem, ...state.items.slice(index + 1)],
				uploading: false,
				uploaded: true,
				uploadError: false,
			};
		});
		builder.addCase(uploadUserAvatar.rejected, state => ({
			...state,
			uploading: false,
			uploaded: false,
			uploadError: true,
		}));
		builder.addCase(deleteUserAvatar.pending, state => ({ ...state }));
		builder.addCase(deleteUserAvatar.fulfilled, (state, { meta }) => {
			const index = state.items.findIndex(avatar => meta.arg.endsWith(avatar.webuserId));
			return {
				...state,
				items: [...state.items.slice(0, index), ...state.items.slice(index + 1)],
			};
		});
		builder.addCase(deleteUserAvatar.rejected, (state, { meta }) => {
			const index = state.items.findIndex(avatar => meta.arg.endsWith(avatar.webuserId));
			return {
				...state,
				items: [...state.items.slice(0, index), ...state.items.slice(index + 1)],
			};
		});
	},
});

export const { resetUploadSuccess, resetUploadError } = slice.actions;
export const reducer = slice.reducer;
