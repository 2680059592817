import React, { useCallback, useEffect } from 'react';
import { Button, Headline, ManagedInput } from '@panda/ui';
import { chainValidators, validateMinLength, validateNonEmpty } from '@web-apps/forms';
import classnames from 'classnames';
import classes from './ChangePasswordView.scss';
import { ManagedForm } from '../../../components/forms/ManagedForm';
import auth from '../../../utils/authenticate/auth';
import { useTranslate } from '../../../redux/slices/translations';
import api from '../../../api';
import { useUserInfo } from '../../../redux/modules/userinfo';
import useLinks from '../../../redux/slices/links/hooks';
import { LogoSpinner } from '../../../components/spinner/LogoSpinner';
import SipgateBlackLogo from './img/sipgate.svg';
import { useCurrentUser } from '../../../redux/modules/users';

export const ChangePasswordView = () => {
	const translate = useTranslate();
	const userInfo = useUserInfo();
	const currentUser = useCurrentUser();
	const { links } = useLinks();

	const [showPasswordChangePage, setShowPasswordChangePage] = React.useState(false);
	const [posting, setPosting] = React.useState(false);
	const [showSpinner, setShowSpinner] = React.useState(true);

	const asyncPasswordCall = useCallback(
		async (asyncCall: () => Promise<void>) => {
			if (!posting) {
				setPosting(true);
				try {
					await asyncCall();
					window.location.href = links.startUpPageUrl;
				} catch (e) {
					window.location.reload();
				}
			}
		},
		[posting, links.startUpPageUrl]
	);

	const disablePassword = useCallback(async () => {
		await asyncPasswordCall(() => api.disablePassword(userInfo.sub, 'social-login'));
	}, [asyncPasswordCall, userInfo.sub]);

	const changePassword = async (password: string) => {
		await asyncPasswordCall(() => api.updatePassword(userInfo.sub, password));
	};

	useEffect(() => {
		if (window.localStorage.getItem('from_idp') === 'true') {
			const shouldDisablePassword = async () => {
				await disablePassword();
				window.localStorage.removeItem('from_idp');
			};

			shouldDisablePassword();
		} else {
			setShowSpinner(false);
		}
	}, [disablePassword]);

	if (showSpinner || !currentUser) {
		return <LogoSpinner />;
	}

	return (
		<div className={classes.wrapper}>
			<div className={classes.backdrop} />
			<img className={classes.logo} src={SipgateBlackLogo} alt="sipgate logo" />
			<div className={classes.center}>
				{!showPasswordChangePage ? (
					<div className={classes.header}>
						{translate.markdown.inline(
							'CHANGEPASSWORD_GREETING',
							`${currentUser.firstname} ${currentUser.lastname}`
						)}
					</div>
				) : (
					<div className={classes.backButton}>
						<Button
							icon="back"
							iconOnly
							onClick={() => setShowPasswordChangePage(false)}
							size="medium"
							variant="quiet"
						/>
					</div>
				)}
				<div className={classes.content}>
					{!showPasswordChangePage ? (
						<>
							<div className={classes.email}>
								<div className={classes.emailHint}>
									{translate.markdown.inline('CHANGEPASSWORD_EMAIL_HINT', auth.getMailFromToken())}
								</div>
								<div className={classes.loginHint}>{translate('CHANGEPASSWORD_LOGIN_HINT')}</div>
								<Button
									onClick={() => {
										setShowPasswordChangePage(true);
									}}
									variant="loud"
									size="large"
									width="max"
								>
									{translate('CHANGEPASSWORD_CONTINUE_WITH_EMAIL')}
								</Button>
							</div>
							<hr className={classes.hr} />
							<div className={classes.ssoHint}>{translate('CHANGEPASSWORD_SSO_HINT')}</div>
							<button
								type="button"
								className={classnames(classes.idp, classes.first)}
								onClick={() => {
									auth.logoutAndRedirectToLogoutPage(links, links.googleSignInUrl, true);
								}}
							>
								<span className={classes.google}>
									{translate('CHANGEPASSWORD_CONTINUE_WITH_GOOGLE')}
								</span>
							</button>
							<button
								type="button"
								className={classnames(classes.idp)}
								onClick={() => {
									auth.logoutAndRedirectToLogoutPage(links, links.appleSignInUrl, true);
								}}
							>
								<span className={classes.apple}>
									{translate('CHANGEPASSWORD_CONTINUE_WITH_APPLE')}
								</span>
							</button>
						</>
					) : (
						<div className={classes.setPassword}>
							<Headline className={classes.title}>
								{translate('CHANGEPASSWORD_COMMAND_TITLE')}
							</Headline>

							<p>{translate('CHANGEPASSWORD_COMMAND_HINT')}</p>
							<ManagedForm
								initialValues={{
									password: '',
									passwordConfirm: '',
								}}
								onSubmit={async data => {
									await changePassword(data.password);
								}}
								validators={{
									password: chainValidators(
										validateNonEmpty(translate),
										validateMinLength(translate, 12)
									),
								}}
							>
								{({ fields, form }) => (
									<>
										<input
											type="text"
											name="username"
											value={auth.getMailFromToken()}
											className={classes.hidden}
										/>
										<ManagedInput
											label={translate('CHANGEPASSWORD_COMMAND_PASSWORD')}
											managedField={fields.password}
											placeholder={translate('CHANGEPASSWORD_COMMAND_PASSWORD_PLACEHOLDER')}
											type="password"
										/>
										<Button
											type="submit"
											action="confirm"
											disabled={!form.canBeSubmitted}
											size="xlarge"
											width="max"
										>
											{translate('CHANGEPASSWORD_COMMAND_SUBMIT')}
										</Button>
									</>
								)}
							</ManagedForm>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
