import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchBalance, topupBalance } from './actions';
import { SliceData, useAction } from '../../index';
import { MoneyAmount } from '../../../components/bookables/prices/util';

export const useBalance = (): SliceData<MoneyAmount> => {
	const dispatch = useDispatch();
	const balance = useSelector(state => state.balance);

	useEffect(() => {
		dispatch(fetchBalance());
	}, [dispatch]);

	return useMemo(() => {
		if (!balance.fetched || !balance.data) {
			return {
				fetched: false,
				data: null,
			};
		}

		return {
			fetched: true,
			data: balance.data,
		};
	}, [balance]);
};

export const useBalanceActions = () => ({
	topupBalance: useAction(topupBalance),
	forceFetch: useAction(useCallback(() => fetchBalance(true), [])),
});
