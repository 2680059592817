import { useEffect } from 'react';

export const googleContactCallbackChannel = new BroadcastChannel('google-contact-callback');

export const GoogleContactCallback = () => {
	useEffect(() => {
		const entries = new URLSearchParams(window.location.hash.replace('#', '?'));
		const accessToken = entries.get('access_token');
		const errorMessage = entries.get('error');

		if (accessToken) {
			googleContactCallbackChannel.postMessage({ accessToken });
		}
		if (errorMessage) {
			googleContactCallbackChannel.postMessage({ errorMessage });
		}
		window.close();
	}, []);

	return null;
};
