import { memoize } from 'lodash';
import React, { useContext, useId, useMemo, useState } from 'react';
import {
	Match,
	Route,
	useRouteMatch,
	Switch,
	useLocation,
	matchPath,
	useHistory,
	ExtractRouteParams,
} from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Subtract } from './types';

interface TransitionClasses {
	enter?: string;
	enterActive?: string;
	exit?: string;
	exitActive?: string;
}

export interface View {
	/** The matched url (with interpolated parameters etc.) of this view. */
	path: `/${string}`;
	/**
	 *  The title of this view, either passed from the outside or overriden
	 * using a `<TitleOverride />`.
	 */
	title?: string;
}

/**
 * A context providing a foundational view-stack abstraction which is
 * used to simplify routing in this codebase.
 *
 * The basic gist ist, that views form a tree and the path from the trees
 * root to any given, currently mounted component is its "stack".
 *
 * This allows us to, among other things, answer questions like
 * "what is my ancestor view" if we want to implement generic back-button
 * functionality for views.
 */
interface ViewStackContext {
	/**
	 * An id which may be used as the id of a headline element as well
	 * as in an aria-labelled-by prop of a wrapper component (e.g. Dialog).
	 */
	titleId: string;
	/**
	 * The viewstack can be used to wire up back buttons and contains
	 * a list of all ancestor views.
	 */
	viewStack: View[];
	/**
	 * The mountpoint is the route outside of our top-level view.
	 * It is used as the target of the close button and is set to the parent URL
	 * of the outermost-dialog.
	 */
	mountPoint: `/${string}` | null;
	/**
	 * Should this view be affected by transitions?
	 * Is true exactly for the outermost view.
	 */
	transitionTarget: boolean;
	/**
	 * A method to override the title from within the view itself
	 */
	setTitle: (name: string) => void;
}

const context = React.createContext<ViewStackContext>({
	titleId: '',
	mountPoint: null,
	viewStack: [],
	transitionTarget: true,
	setTitle: () => {},
});
context.displayName = 'ViewStackContext';

/**
 * Please only use this in other abstractions and never directly.
 * The view-stack should be a foundational abstraction, used by other, easier
 * to use wrappers like `withDialogPage` or `withView`.
 */
export const useViewStack = () => useContext(context);

export const ViewStackConsumer = context.Consumer;
export const ViewStackProvider = context.Provider;

/**
 * Please only use this in other abstractions and never directly.
 * The view-stack should be a foundational abstraction, used by other, easier
 * to use wrappers like `withDialogPage` or `withView`.
 */
export function withViewStack<Props>(
	Component: React.ComponentType<Props>,
	{
		transparent,
		transition,
	}: {
		transparent: boolean;
		transition?: { classes: TransitionClasses; timeout: number };
	}
) {
	return function WithViewStack<Path extends `/${string}`>(
		/*
		 * This "beautiful" construct allows us to pass in a path which
		 * provides any props of the wrapped component. Which means you
		 * can later do either of the following:
		 *
		 * ```
		 * <MyView path="/:deviceId" />
		 * <MyView path="/" deviceId="x7" />
		 * ```
		 */
		props: { path: Path; name?: string } & Subtract<
			ExtractRouteParams<Path> & {
				onClose: () => void;
			},
			Props
		>
	) {
		const ownTitleId = useId();
		const [title, setTitle] = useState<string | null>(null);

		const location = useLocation();
		const history = useHistory();
		const outerMatch = useRouteMatch();

		const stackBuilder = useMemo(
			() =>
				memoize((path: `/${string}`) =>
					memoize((parent: View[]) => [...parent, { path, title: title || props.name }])
				),
			[title, props.name]
		);

		const renderWithContext = (ctx: ViewStackContext, match: Match<ExtractRouteParams<Path>>) => {
			const innerCtx = {
				titleId: ctx.titleId === '' ? ownTitleId : ctx.titleId,
				mountPoint: ctx.mountPoint !== null ? ctx.mountPoint : outerMatch.url,
				viewStack: transparent ? ctx.viewStack : stackBuilder(match.url)(ctx.viewStack),
				transitionTarget: false,
				setTitle,
			};

			/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
			const innerProps: any = {
				...props,
				onClose: () => history.push({ pathname: innerCtx.mountPoint, hash: location.hash }),
			};

			for (const [name, param] of Object.entries(match.params)) {
				// We explicitly decode url parameters, because react-router made the extremely clever
				// decision, to encode parameters when interpolating them into paths, but not decoding
				// them when parsing them out of it.
				innerProps[name] = param ? decodeURIComponent(param) : param;
			}

			delete innerProps.path;

			return (
				<ViewStackProvider value={innerCtx}>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<Component {...innerProps} />
				</ViewStackProvider>
			);
		};

		return (
			<ViewStackConsumer>
				{ctx => {
					// Allow transitioning only the outermost view.
					//
					// These transitions only work for transitioning a view independent from its siblings.
					// If you want to switch multiple views, you need an external SwitchTransition which
					// can "see" both things it transitions.
					//
					// You can take a look at `ViewTransition` for this.
					if (transition && ctx.transitionTarget) {
						return (
							// This entire construct is pretty much taken directly from the react-router docs.
							// The basic gist is, we need a CSSTransition which gets mounted/unmounted
							// whenever our dialog opens or closes to animate the dialog and overlay.
							<TransitionGroup>
								<CSSTransition
									classNames={transition.classes}
									timeout={transition.timeout}
									key={matchPath(location.pathname, props.path) ? 'in' : 'out'}
								>
									<Switch location={location}>
										<Route
											path={props.path}
											render={({ match }) => renderWithContext(ctx, match)}
											sensitive
										/>
									</Switch>
								</CSSTransition>
							</TransitionGroup>
						);
					}

					return (
						<Route
							path={props.path}
							render={({ match }) => renderWithContext(ctx, match)}
							sensitive
						/>
					);
				}}
			</ViewStackConsumer>
		);
	};
}
