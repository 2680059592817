import { createAction } from '../..';

import api from '../../../api';
import { ScopeState } from './types';
import { ReduxState } from '../../types';

const shouldFetchScopes = (state: ScopeState) => !state.fetching && !state.fetched;

export const fetchScopes = createAction('SCOPES_FETCH', () => ({
	promise: () => api.getScopes(),
	shouldFetch: (state: ReduxState) => shouldFetchScopes(state.scopes),
}));
