import {
	Restriction,
	RestrictionValue,
	SpecificRestriction,
	TargetedRestriction,
	UntargetedRestriction,
} from './types';

export const isRestriction =
	<R extends Restriction>(...names: R[]) =>
	(restriction: RestrictionValue): restriction is SpecificRestriction<R> => {
		return (names as string[]).includes(restriction.restriction);
	};

export function getRestriction<R extends TargetedRestriction>(
	restrictions: RestrictionValue[],
	restriction: R,
	target: string
): SpecificRestriction<R>;
export function getRestriction<R extends UntargetedRestriction>(
	restrictions: RestrictionValue[],
	restriction: R
): SpecificRestriction<R>;
export function getRestriction<R extends Restriction>(
	restrictions: RestrictionValue[],
	restriction: R,
	target: string | null = null
) {
	const res = restrictions.filter(isRestriction(restriction)).find(r => r.target === target);

	if (res) {
		return res;
	}

	return {
		restriction,
		target,
		reason: null,
		value: false,
	};
}

export function hasRestriction<R extends TargetedRestriction>(
	restrictions: RestrictionValue[],
	restriction: R,
	target: string
): boolean;
export function hasRestriction<R extends UntargetedRestriction>(
	restrictions: RestrictionValue[],
	restriction: R
): boolean;
export function hasRestriction<R extends Restriction>(
	restrictions: RestrictionValue[],
	restriction: R,
	target: string | null = null
) {
	const res = restrictions.filter(isRestriction(restriction)).find(r => r.target === target);

	return res?.value || false;
}

export function hasAnyTargetedRestriction<R extends TargetedRestriction>(
	restrictions: RestrictionValue[],
	restriction: R
): boolean {
	return restrictions.some(isRestriction(restriction));
}
