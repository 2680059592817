import React from 'react';
import { Button } from '@panda/ui';
import MediaQueryDecorator, { MediaQueryProps } from '../../../../utils/MediaQueryDecorator';
import classes from './VideoIdentSuccessDialog.scss';
import { DeprecatedDialogContent } from '../../DeprecatedDialogContent';
import { Translate } from '../../../../redux/slices/translations';
import IdentityVerificationSuccessIcon from '../../../../media/icons/icon-verify-dark-line-default-64.svg';

interface ExternalProps {
	translate: Translate;
	onClose: () => void;
}

type Props = ExternalProps & MediaQueryProps;

class VideoIdentSuccessDialog extends React.Component<Props> {
	private renderButton() {
		return (
			<Button
				action="confirm"
				size="xlarge"
				width="max-on-touch-device"
				onClick={this.props.onClose}
			>
				{this.props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_SUCCESS_DIALOG_BUTTON')}
			</Button>
		);
	}

	public render() {
		return (
			<DeprecatedDialogContent
				icon={<img src={IdentityVerificationSuccessIcon} alt="" />}
				headline={this.props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_SUCCESS_DIALOG_HEADLINE')}
				button={this.renderButton()}
			>
				<p className={classes.content}>
					{this.props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_SUCCESS_DIALOG_TEXT')}
				</p>
			</DeprecatedDialogContent>
		);
	}
}

export default MediaQueryDecorator(VideoIdentSuccessDialog);
