import React, { Component } from 'react';
import { connect, ReduxProps } from '../../redux';
import { ReduxState } from '../../redux/types';

import {
	discardEvent,
	EVENT_TYPE_TOUR,
	fetchEvents,
	selectEventsByType,
} from '../../redux/modules/events';

import AdminDashboardTour, { TOUR_NAME as ADMIN_DASHBOARD_TOUR } from './AdminDashboardTour';
import { fetchUserInfo } from '../../redux/modules/userinfo';
import { StatusPageTour } from './StatusPageTour';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';
import ChannelReleaseTour from './ChannelReleaseTour';

const mapStateToProps = (state: ReduxState) => ({
	events: state.events,
	dialogs: state.dialogs,
	userinfo: state.userinfo.fetched ? state.userinfo.data : null,
});

const mapDispatchToProps = {
	fetchEvents,
	discardEvent,
	fetchUserInfo,
};

type Props = TranslateProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class TourGuide extends Component<Props> {
	public componentDidMount(): void {
		this.props.fetchEvents();
		this.props.fetchUserInfo();
	}

	public render() {
		if (this.props.dialogs.dialogCount > 0 || !this.props.userinfo) {
			return <></>;
		}

		const shouldShowChannelReleaseTour = !localStorage.getItem('hasSeenChannelReleaseTour');

		const displayTour = selectEventsByType(this.props.events, EVENT_TYPE_TOUR);

		const adminDashboardTour = displayTour.find(
			t => t.eventName.toUpperCase() === ADMIN_DASHBOARD_TOUR
		);

		if (
			this.props.translate('STATUS_PAGE_TOUR_DESCRIPTION') !== '' &&
			this.props.translate('STATUS_PAGE_TOUR_TITLE') !== ''
		) {
			return <StatusPageTour onDone={() => {}} translate={this.props.translate} />;
		}

		if (shouldShowChannelReleaseTour) {
			return (
				<ChannelReleaseTour
					onDone={() => {
						localStorage.setItem('hasSeenChannelReleaseTour', 'true');
					}}
					translate={this.props.translate}
				/>
			);
		}

		if (adminDashboardTour) {
			return (
				<AdminDashboardTour
					onDone={() => {
						this.props.discardEvent(adminDashboardTour);
					}}
					showStatusStep={this.props.userinfo.domain === 'sipgate.de'}
					translate={this.props.translate}
				/>
			);
		}

		return <></>;
	}
}

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(TourGuide));
