import React from 'react';
import { Checkbox } from '@panda/ui';

import classes from './StatusFilter.scss';
import { Translate } from '../../../redux/slices/translations';
import { ChangeUrlParameter } from '../selections/types';
import { StatusType } from '../selections/parsers';
import { FilterDropdown } from './FilterDropdown';
import { Filter } from './Filter';

interface Props {
	open?: boolean;
	translate: Translate;
	onOpenClose?: (open: boolean) => void;

	selection: StatusType[];
	onChange: ChangeUrlParameter;
}

export class StatusFilter extends React.PureComponent<Props> {
	private onChangedStatus = (value: StatusType) => {
		const changed = Array.from(this.props.selection);
		if (this.hasSelected(value)) {
			changed.splice(changed.indexOf(value), 1);
			this.props.onChange({ status: changed, offset: 0 });
		} else {
			changed.push(value);
			this.props.onChange({ status: changed, offset: 0 });
		}
	};

	private hasSelected = (value: StatusType) => this.props.selection.includes(value);

	private isActive() {
		return this.props.selection.length !== 0;
	}

	private renderButtonLabel() {
		if (this.hasSelected('success') && this.hasSelected('failure')) {
			return `${this.props.translate('FILTER_STATUS')} (2)`;
		}

		if (this.hasSelected('success')) {
			return this.props.translate('FILTER_STATUS_SUCCESS');
		}

		if (this.hasSelected('failure')) {
			return this.props.translate('FILTER_STATUS_MISSED');
		}

		return this.props.translate('FILTER_STATUS');
	}

	private renderContent() {
		return (
			<ul className={classes.filterlist}>
				<li>
					<Checkbox
						checked={this.hasSelected('success')}
						onChange={() => this.onChangedStatus('success')}
					>
						{this.props.translate('FILTER_STATUS_SUCCESS')}
					</Checkbox>
				</li>
				<li>
					<Checkbox
						checked={this.hasSelected('failure')}
						onChange={() => this.onChangedStatus('failure')}
					>
						{this.props.translate('FILTER_STATUS_MISSED')}
					</Checkbox>
				</li>
			</ul>
		);
	}

	private renderDropdown() {
		if (!this.props.onOpenClose) {
			return null;
		}

		return (
			<FilterDropdown
				buttonLabel={this.renderButtonLabel()}
				active={this.isActive()}
				onOpenClose={this.props.onOpenClose}
				open={!!this.props.open}
			>
				{this.renderContent()}
			</FilterDropdown>
		);
	}

	public render() {
		if (this.props.onOpenClose) {
			return this.renderDropdown();
		}

		return (
			<Filter title={this.props.translate('FILTER_STATUS')}>
				<div className={classes.twoColumns}>{this.renderContent()}</div>
			</Filter>
		);
	}
}
