import { createAsyncThunk } from '../../utils/wrapper';
import sipgateChangelogClient from '../../../api/client/SipgateChangelogClient';

export const fetchChangelog = createAsyncThunk(
	'changelog/fetchChangelog',
	async () => sipgateChangelogClient.getChangelog(),
	{
		condition: (_, { getState }) => {
			const { changelog } = getState();
			return changelog.fetching !== 'pending';
		},
	}
);
