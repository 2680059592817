import { useEffect, useMemo } from 'react';
import { SliceData } from '../..';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchDataUsageSettings } from './actions';
import { DataUsageSettings } from './types';

export const useDataUsageSettings = (containerId: string): SliceData<DataUsageSettings> => {
	const state = useSelector(s => s.dataUsage);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchDataUsageSettings(containerId));
	}, [containerId, dispatch]);

	return useMemo(() => {
		if (!state.fetchedSettingsForDevice.includes(containerId)) {
			return {
				fetched: false,
				data: null,
			};
		}

		return {
			fetched: true,
			data: state.itemsSettings[containerId],
		};
	}, [state, containerId]);
};
