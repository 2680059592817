import { HeadingBoundary, Headline, InternalLink } from '@panda/ui';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FocusTrap } from '@web-apps/focus-trap';
import { useLocation } from 'react-router';
import classes from './Preview.scss';
import { MarkdownTranslate } from '../../../helpers/Markdown';
import RoundProgressIndicator from './RoundProgressIndicator';
import useOutsideClick from '../../hooks/useOutsideClick';
import useKeyboardEvents from '../../hooks/useKeyboardEvents';
import { useTranslate } from '../../../redux/slices/translations';
import { isBaseContract, useContracts } from '../../../redux/modules/contracts';
import { baseContractPath } from '../../../routes/paths';

interface Props {
	variant?: 'small' | 'medium' | 'large';
	previewDuration?: number;
}

export const Preview = ({ variant = 'large', previewDuration = 31 }: Props) => {
	const { key: locationKey } = useLocation();
	const ref = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);

	const translate = useTranslate();
	const contracts = useContracts();

	useOutsideClick(ref, () => setOpen(!open), open);
	useKeyboardEvents('Escape', () => setOpen(!open), open);

	useEffect(() => {
		setOpen(false);
	}, [locationKey]);

	if (!contracts.fetched) {
		return null;
	}

	const baseContract = contracts.data.find(isBaseContract);
	if (!baseContract?.preview) {
		return null;
	}

	const endDate = DateTime.fromISO(baseContract.cancellationDate);
	const daysLeft = Math.floor(endDate.plus({ days: 1 }).diffNow('days').days) + 1;

	const id = `preview_info`;
	const popoutId = `${id}_popout`;

	const getContent = () => {
		if (variant === 'small') {
			return (
				<FocusTrap initialFocus={`#${popoutId}`} active={open} allowOutsideClick>
					<div
						id={popoutId}
						className={classnames(classes.content, { [classes.open]: open })}
						role="dialog"
						aria-modal="true"
						aria-labelledby="preview-button"
						tabIndex={-1}
					>
						<HeadingBoundary>
							<Headline className={classes.headline}>
								{translate('PREVIEW_INFO_HEADLINE_NAVIGATION')}
							</Headline>
						</HeadingBoundary>
						<p>
							<MarkdownTranslate
								translate={translate}
								translationKey="PREVIEW_INFO_DEADLINE_DESC"
								placeholder={[daysLeft.toString(10)]}
							/>
						</p>
						<InternalLink to={baseContractPath.build()} button variant="loud" width="max">
							{translate('PREVIEW_INFO_BUTTON_TEXT_NAVIGATION')}
						</InternalLink>
					</div>
				</FocusTrap>
			);
		}

		return (
			<>
				<div className={classes.content}>
					<p>
						<MarkdownTranslate
							translate={translate}
							translationKey="PREVIEW_INFO_DEADLINE_DESC"
							placeholder={[daysLeft.toString(10)]}
						/>
					</p>
					<InternalLink to={baseContractPath.build()} button variant="loud" width="max">
						{translate('PREVIEW_INFO_BUTTON_TEXT_NAVIGATION')}
					</InternalLink>
				</div>
				<p>
					<MarkdownTranslate
						translate={translate}
						translationKey="PREVIEW_INFO_DEADLINE_DESC"
						placeholder={[daysLeft.toString(10)]}
					/>
				</p>
				<InternalLink to={baseContractPath.build()} button variant="loud" width="max">
					{translate('PREVIEW_INFO_BUTTON_TEXT_NAVIGATION')}
				</InternalLink>
			</>
		);
	};

	return (
		<div data-testid="preview" className={classnames(classes.preview, classes[variant])} ref={ref}>
			<HeadingBoundary>
				<Headline className={classes.headline}>
					{translate('PREVIEW_INFO_HEADLINE_NAVIGATION')}
				</Headline>
			</HeadingBoundary>
			<div className={classes.previewContent}>
				<div className={classes.indicator}>
					<button
						onClick={() => {
							if (variant === 'small') {
								setOpen(!open);
							}
						}}
						type="button"
						aria-label={translate('PREVIEW_INFO_HEADLINE_NAVIGATION')}
						aria-expanded={open}
						aria-haspopup="true"
						aria-controls={popoutId}
						aria-disabled={variant === 'large'}
						id="preview-button"
					>
						<RoundProgressIndicator
							value={daysLeft}
							max={previewDuration}
							unit={translate('PREVIEW_INFO_REMAINING_DAYS_UNIT')}
							variant={variant === 'small' ? 'small' : 'large'}
						/>
					</button>
				</div>
				{getContent()}
			</div>
		</div>
	);
};
