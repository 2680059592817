import api from '../../../api';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';

const shouldFetchTacs = (state: ReduxState) => !state.tacs.fetched && !state.tacs.fetching;

export const fetchTacs = createAsyncThunk(
	'tacs/fetch',
	async () => {
		return api.getTacs();
	},
	{
		condition: (_args, { getState }) => {
			return shouldFetchTacs(getState());
		},
	}
);
