import { createAction } from '../..';

import api from '../../../api';
import { ReduxState } from '../../types';
import { NotificationDirection, NotificationCause } from '../../../api/types/notifications';

function shouldFetchNotifications(state: ReduxState, userId: string) {
	return (
		!state.notifications.fetchedForUser.includes(userId) &&
		!state.notifications.fetchingForUser.includes(userId)
	);
}

export const fetchNotifications = createAction(
	'NOTIFICATIONS_FETCH',
	(userId: string, force?: boolean) => ({
		data: { userId },
		promise: () => api.getNotifications(userId),
		shouldFetch: (state: ReduxState) => force || shouldFetchNotifications(state, userId),
	})
);

export const createCallEmailNotification = createAction(
	'NOTIFICATION_CREATE',
	(
		userId: string,
		endpointId: string,
		email: string,
		direction: NotificationDirection = 'INCOMING',
		cause: NotificationCause = 'MISSED'
	) => ({
		promise: api
			.createCallEmailNotification(userId, endpointId, email, direction, cause)
			.then(() => api.getNotifications(userId)),
	})
);

export const createCallSmsNotification = createAction(
	'NOTIFICATION_CREATE',
	(
		userId: string,
		endpointId: string,
		email: string,
		direction: NotificationDirection = 'INCOMING',
		cause: NotificationCause = 'MISSED'
	) => ({
		promise: api
			.createCallSmsNotification(userId, endpointId, email, direction, cause)
			.then(() => api.getNotifications(userId)),
	})
);

export const createFaxEmailNotification = createAction(
	'NOTIFICATION_CREATE',
	(
		userId: string,
		faxlineId: string,
		email: string,
		direction: NotificationDirection = 'INCOMING'
	) => ({
		promise: api
			.createFaxEmailNotification(userId, faxlineId, email, direction)
			.then(() => api.getNotifications(userId)),
	})
);

export const createFaxSmsNotification = createAction(
	'NOTIFICATION_CREATE',
	(
		userId: string,
		faxlineId: string,
		number: string,
		direction: NotificationDirection = 'INCOMING'
	) => ({
		promise: api
			.createFaxSmsNotification(userId, faxlineId, number, direction)
			.then(() => api.getNotifications(userId)),
	})
);

export const createFaxReportNotification = createAction(
	'NOTIFICATION_CREATE',
	(userId: string, faxlineId: string, email: string) => ({
		promise: api
			.createFaxReportNotification(userId, faxlineId, email)
			.then(() => api.getNotifications(userId)),
	})
);

export const createVoicemailEmailNotification = createAction(
	'NOTIFICATION_CREATE',
	(userId: string, voicemailId: string, email: string) => ({
		promise: api
			.createVoicemailEmailNotification(userId, voicemailId, email)
			.then(() => api.getNotifications(userId)),
	})
);

export const createVoicemailSmsNotification = createAction(
	'NOTIFICATION_CREATE',
	(userId: string, voicemailId: string, number: string) => ({
		promise: api
			.createVoicemailSmsNotification(userId, voicemailId, number)
			.then(() => api.getNotifications(userId)),
	})
);

export const createSmsEmailNotification = createAction(
	'NOTIFICATION_CREATE',
	(userId: string, endpointId: string, email: string) => ({
		promise: api
			.createSmsEmailNotification(userId, endpointId, email)
			.then(() => api.getNotifications(userId)),
	})
);

export const changeNotification = createAction(
	'NOTIFICATION_CHANGE',
	<T>(userId: string, notificationId: string, createMethod: () => Promise<T>) => ({
		promise: api
			.deleteNotification(userId, notificationId)
			.then(createMethod)
			.then(() => api.getNotifications(userId)),
	})
);

export const changeCallEmailNotification = (
	userId: string,
	notificationId: string,
	endpointId: string,
	email: string,
	direction: NotificationDirection = 'INCOMING',
	cause: NotificationCause = 'MISSED'
) =>
	changeNotification(userId, notificationId, () =>
		api.createCallEmailNotification(userId, endpointId, email, direction, cause)
	);

export const changeCallSmsNotification = (
	userId: string,
	notificationId: string,
	endpointId: string,
	number: string,
	direction: NotificationDirection = 'INCOMING',
	cause: NotificationCause = 'MISSED'
) =>
	changeNotification(userId, notificationId, () =>
		api.createCallSmsNotification(userId, endpointId, number, direction, cause)
	);

export const changeFaxEmailNotification = (
	userId: string,
	notificationId: string,
	faxlineId: string,
	email: string,
	direction: NotificationDirection = 'INCOMING'
) =>
	changeNotification(userId, notificationId, () =>
		api.createFaxEmailNotification(userId, faxlineId, email, direction)
	);

export const changeFaxSmsNotification = (
	userId: string,
	notificationId: string,
	faxlineId: string,
	number: string,
	direction: NotificationDirection = 'INCOMING'
) =>
	changeNotification(userId, notificationId, () =>
		api.createFaxSmsNotification(userId, faxlineId, number, direction)
	);

export const changeFaxReportNotification = (
	userId: string,
	notificationId: string,
	faxlineId: string,
	email: string
) =>
	changeNotification(userId, notificationId, () =>
		api.createFaxReportNotification(userId, faxlineId, email)
	);

export const changeVoicemailEmailNotification = (
	userId: string,
	notificationId: string,
	voicemailId: string,
	email: string
) =>
	changeNotification(userId, notificationId, () =>
		api.createVoicemailEmailNotification(userId, voicemailId, email)
	);

export const changeVoicemailSmsNotification = (
	userId: string,
	notificationId: string,
	voicemailId: string,
	number: string
) =>
	changeNotification(userId, notificationId, () =>
		api.createVoicemailSmsNotification(userId, voicemailId, number)
	);

export const changeSmsEmailNotification = (
	userId: string,
	notificationId: string,
	endpointId: string,
	email: string
) =>
	changeNotification(userId, notificationId, () =>
		api.createSmsEmailNotification(userId, endpointId, email)
	);

export const deleteNotification = createAction(
	'NOTIFICATION_DELETE',
	(userId: string, notificationId: string) => ({
		promise: api
			.deleteNotification(userId, notificationId)
			.then(() => api.getNotifications(userId)),
	})
);
