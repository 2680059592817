import { createAsyncThunk } from '../../utils/wrapper';
import { paymentApi } from '../../../api';
import { PaymentMethod } from './types';
import { forceFetchRestrictions } from '../../modules/restrictions';
import { forceFetchIsProductBookingAllowed } from '../../modules/products';
import { MoneyAmount } from '../../../components/bookables/prices/util';

export const fetchPaymentMethods = createAsyncThunk(
	'payment/fetchPaymentMethods',
	async () => paymentApi.getPaymentMethods(),
	{
		condition: (_arg, { getState }) =>
			!getState().payment.fetchedMethods && !getState().payment.fetchingMethods,
	}
);

export const deletePaymentMethod = createAsyncThunk(
	'payment/deletePaymentMethod',
	async (data: { paymentId: string }) => paymentApi.deletePaymentMethod(data.paymentId)
);

export const fetchAutoCreditingSettings = createAsyncThunk(
	'payment/fetchAutoCreditingSettings',
	async () => paymentApi.getAutoCreditingSettings(),
	{
		condition: (_, { getState }) =>
			!getState().payment.fetchedAutoCrediting && !getState().payment.fetchingAutoCrediting,
	}
);

export const fetchOpenPositions = createAsyncThunk(
	'payment/fetchOpenPositions',
	async (_?: { force: boolean }) => paymentApi.getOpenPositions(),
	{
		condition: (option, { getState }) =>
			option?.force ||
			(!getState().payment.fetchedOpenPositions && !getState().payment.fetchingOpenPositions),
	}
);

export const setAutoCreditingSettings = createAsyncThunk(
	'payment/setAutoCreditingSettings',
	async (
		arg: { method: PaymentMethod; items: string[]; charge: MoneyAmount; threshold: MoneyAmount },
		{ dispatch, getState }
	) => {
		const result = await paymentApi.setAutoCreditingSettings(arg.method.id, {
			items: arg.items,
			chargeInCent: (arg.charge.amount * arg.charge.fraction) / 100,
			thresholdInFullCurrency: arg.threshold.amount * arg.threshold.fraction,
		});

		await Promise.all([
			dispatch(
				forceFetchRestrictions([
					'CAN_DELETE_PAYMENT_METHOD',
					'CAN_BOOK_SIPGATE_IO',
					'CAN_BOOK_PHONE_NUMBERS',
				])
			).payload.promise,

			...getState().products.itemsBookingIsAllowed.map(({ productId }) =>
				dispatch(forceFetchIsProductBookingAllowed(productId))
			),
		]);

		return result;
	}
);

export const createSepaPaymentMethod = createAsyncThunk(
	'payment/createSepaPaymentMethod',
	async (arg: { iban: string; holder: string; accepted: boolean }, { dispatch }) => {
		const result = await paymentApi.createSepaPaymentMethod(arg);

		await dispatch(forceFetchRestrictions(['CAN_DELETE_PAYMENT_METHOD'])).payload.promise;

		return result;
	}
);

export const completeCreditCardVerification = createAsyncThunk(
	'payment/completeCreditCardVerification',
	async (verificationId: string, { dispatch }) => {
		const result = await paymentApi.completeCreditCardVerification(verificationId);

		await dispatch(forceFetchRestrictions(['CAN_DELETE_PAYMENT_METHOD'])).payload.promise;

		return result;
	}
);
