import { createSlice } from '@reduxjs/toolkit';
import { BaseContractState } from './types';
import {
	bookBaseContract,
	cancelBaseContract,
	changeBaseContract,
	fetchBaseContract,
	revokeBaseContractCancellation,
	revokeBaseContractChange,
} from './action';

export const reducer = createSlice({
	name: 'baseContract',
	initialState: {
		data: null,
		fetched: false,
		fetching: true,
	} as BaseContractState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchBaseContract.pending, state => ({
			...state,
			pending: true,
		}));
		builder.addCase(fetchBaseContract.fulfilled, (state, action) => ({
			...state,
			fetched: true,
			fetching: false,
			data: action.payload
				? {
						optionId: action.payload.optionId,
						isPreview: action.payload.isPreview,
						nextCancellationDate: new Date(action.payload.nextCancellationDate),
						cancellation: action.payload.cancellation
							? {
									terminationDate: new Date(action.payload.cancellation.terminationDate),
								}
							: null,
						contractChange: action.payload.contractChange
							? {
									activeFrom: new Date(action.payload.contractChange.activeFrom),
									futureOptionId: action.payload.contractChange.futureOptionId,
								}
							: null,
					}
				: null,
		}));
		builder.addCase(bookBaseContract.fulfilled, (state, action) => ({
			...state,
			data: action.payload
				? {
						optionId: action.payload.optionId,
						isPreview: action.payload.isPreview,
						nextCancellationDate: new Date(action.payload.nextCancellationDate),
						cancellation: action.payload.cancellation
							? {
									terminationDate: new Date(action.payload.cancellation.terminationDate),
								}
							: null,
						contractChange: action.payload.contractChange
							? {
									activeFrom: new Date(action.payload.contractChange.activeFrom),
									futureOptionId: action.payload.contractChange.futureOptionId,
								}
							: null,
					}
				: null,
		}));
		builder.addCase(changeBaseContract.fulfilled, (state, action) => ({
			...state,
			data: action.payload
				? {
						optionId: action.payload.optionId,
						isPreview: action.payload.isPreview,
						nextCancellationDate: new Date(action.payload.nextCancellationDate),
						cancellation: action.payload.cancellation
							? {
									terminationDate: new Date(action.payload.cancellation.terminationDate),
								}
							: null,
						contractChange: action.payload.contractChange
							? {
									activeFrom: new Date(action.payload.contractChange.activeFrom),
									futureOptionId: action.payload.contractChange.futureOptionId,
								}
							: null,
					}
				: null,
		}));
		builder.addCase(revokeBaseContractChange.pending, state => ({
			...state,
			data: state.data
				? {
						...state.data,
						contractChange: null,
					}
				: null,
		}));
		builder.addCase(cancelBaseContract.fulfilled, (state, action) => ({
			...state,
			data: state.data
				? {
						...state.data,
						cancellation: {
							terminationDate: new Date(action.payload.terminationDate),
						},
					}
				: null,
		}));
		builder.addCase(revokeBaseContractCancellation.pending, state => ({
			...state,
			data: state.data
				? {
						...state.data,
						cancellation: null,
					}
				: null,
		}));
	},
}).reducer;
