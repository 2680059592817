import { handleActions } from '../..';

import * as actions from './actions';
import { RestrictionState, RestrictionValue } from './types';

const initialState: RestrictionState = {
	items: [],

	fetchedFull: [],
	fetchingFull: [],

	fetchedSingle: [],
	fetchingSingle: [],
};

export default handleActions<RestrictionState, PossibleActions<typeof actions>>(
	{
		RESTRICTIONS_FETCH_PENDING: (state, action) => ({
			...state,
			fetchingFull: action.data.restrictions
				? state.fetchingFull
				: [
						...state.fetchingFull,
						{
							target: action.data.target,
							restriction: null,
						},
					],
			fetchingSingle: action.data.restrictions
				? [
						...state.fetchingSingle,
						...action.data.restrictions.map(restriction => ({
							target: action.data.target,
							restriction,
						})),
					]
				: state.fetchingSingle,
		}),
		RESTRICTIONS_FETCH_SUCCESS: (state, action) => {
			const map: Map<string, RestrictionValue[]> = state.items.reduce((acc, res) => {
				const list = acc.get(res.restriction) || [];

				list.push(res);
				acc.set(res.restriction, list);

				return acc;
			}, new Map<string, RestrictionValue[]>());

			for (const item of action.payload) {
				const old = map.get(item.restriction) || [];
				const fresh = old.filter(res => res.target !== item.target);

				fresh.push(item);

				map.set(item.restriction, fresh);
			}

			const restrictions = Array.from(map.entries())
				// We sort these, to ensure our tests work
				.sort(([a], [b]) => a.localeCompare(b))
				.map(([_, e]) => e)
				.flat();

			return {
				items: restrictions,

				fetchedFull: action.data.restrictions
					? state.fetchedFull
					: [
							...state.fetchedFull,
							{
								target: action.data.target,
							},
						],
				fetchingFull: action.data.restrictions
					? state.fetchingFull
					: state.fetchingFull.filter(e => e.target !== action.data.target),

				fetchedSingle: action.data.restrictions
					? [
							...state.fetchedSingle,
							...action.data.restrictions.map(restriction => ({
								target: action.data.target,
								restriction,
							})),
						]
					: state.fetchedSingle,
				fetchingSingle: action.data.restrictions
					? state.fetchingSingle.filter(
							e =>
								e.target !== action.data.target &&
								!action.data.restrictions!.includes(e.restriction)
						)
					: state.fetchingSingle,
			};
		},
	},
	initialState
);

export const initialRestrictionState = { ...initialState };
