import { ApiPersonalAccessToken } from '../../../api/types/personal-access-token';

export type PersonalAccessToken = ApiPersonalAccessToken;

export enum PersonalAccessTokenLoadingState {
	LOADING,
	SUCCESS,
	ERROR,
}

export interface PersonalAccessTokenState {
	items: PersonalAccessToken[];
	fetchingForUser: string[];
	fetchedForUser: string[];
	creatingToken: PersonalAccessTokenLoadingState;
}
