import React from 'react';
import classnames from 'classnames';

import classes from './SearchInput.scss';
import SearchIcon, { Variants as SearchIconVariants } from '../images/SearchIcon';
import { Translate } from '../../redux/slices/translations';
import { ariaId } from '../../utils/a11y/aria-id';

interface Props {
	value: string;
	disabled?: boolean;
	onChange: (value: string) => void;
	placeholder?: string;
	autoFocus?: boolean;
	className?: string;
	maxLength?: number;
	onKeyDown?: React.KeyboardEventHandler;
	inputRef?: React.RefObject<HTMLInputElement>;
	ariaId?: string;
	ariaLabel?: string;
	ariaLabelledby?: string;
	ariaControls?: string;
	ariaAutocomplete?: 'none' | 'list' | 'inline' | 'both';
	ariaActivedescendant?: string;
	translate: Translate;
}

export default class SearchInput extends React.Component<Props> {
	private readonly inputRef: React.RefObject<HTMLInputElement>;

	public constructor(props: Props) {
		super(props);
		this.inputRef = this.props.inputRef ? this.props.inputRef : React.createRef<HTMLInputElement>();
	}

	private onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		this.props.onChange(e.target.value);

	private getIcon = () => {
		let variant = SearchIconVariants.DEFAULT;

		if (this.props.value !== '') {
			variant = SearchIconVariants.ACTIVE;
		}
		return <SearchIcon className={classes.icon} variant={variant} />;
	};

	public render() {
		const inputAriaId = this.props.ariaId || ariaId();

		return (
			<div className={classnames(classes.searchInput, this.props.className)}>
				<label
					htmlFor={inputAriaId}
					aria-label={this.props.ariaLabel || this.props.translate('SEARCH_INPUT_ARIA_LABEL')}
				>
					{this.getIcon()}
				</label>
				<input
					id={inputAriaId}
					aria-autocomplete={this.props.ariaAutocomplete}
					aria-controls={this.props.ariaControls}
					aria-labelledby={this.props.ariaLabelledby}
					disabled={this.props.disabled}
					ref={this.inputRef}
					onKeyDown={this.props.onKeyDown}
					type="search"
					value={this.props.value}
					onChange={this.onChange}
					placeholder={this.props.placeholder}
					autoFocus={this.props.autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
					maxLength={this.props.maxLength}
					aria-activedescendant={this.props.ariaActivedescendant}
				/>
			</div>
		);
	}
}
