import { State } from '../../state';
import { ToggleStarPendingAction } from './types';

export const toggleStarPending = (state: State, action: ToggleStarPendingAction): State => {
	if (!state.entries.result) {
		return state;
	}

	return {
		...state,
		entries: {
			...state.entries,
			result: {
				...state.entries.result,
				normalized: state.entries.result.normalized.map(entry => {
					if (action.eventId === entry.originalEvent.id) {
						return {
							...entry,
							originalEvent: {
								...entry.originalEvent,
								starred: action.starred,
							},
						};
					}

					return entry;
				}),
			},
		},
	};
};

export const toggleStarSuccess = (state: State): State => state;

export const toggleStarError = (state: State): State => state;
