import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchSms } from './actions';

export const useSMSforUser = (userId: string) => {
	const dispatch = useDispatch();
	const sms = useSelector(state => state.sms);

	useEffect(() => {
		dispatch(fetchSms({ userId }));
	}, [dispatch, userId]);

	const isFetchedForUser = sms.fetchedForUser.includes(userId);
	const items = sms.items.filter(item => item.owner === userId);
	return !isFetchedForUser ? null : items;
};
