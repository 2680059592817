import { useSession } from '../../../routes/SessionContext';

export const useUserInfo = () => {
	return useSession().userinfo;
};

export const useFeatureFlag = (featureFlag: string) => {
	return useUserInfo().flags.includes(featureFlag);
};

export const useSipgateDomain = () => {
	return useUserInfo().domain;
};
