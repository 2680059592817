import { createSlice } from '@reduxjs/toolkit';
import { createSipgateAppNotification, fetchSipgateAppNotification } from './actions';
import { SipgateAppNotificationState } from './types';

export const initialState = {
	canNotify: false,
	fetching: false,
	fetched: false,
} as SipgateAppNotificationState;

const slice = createSlice({
	name: 'sipgateAppNotification',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchSipgateAppNotification.pending, state => ({
			...state,
			fetching: true,
			fetched: false,
		}));

		builder.addCase(fetchSipgateAppNotification.fulfilled, (state, { payload }) => ({
			canNotify: payload.canNotify,
			fetching: false,
			fetched: true,
		}));

		builder.addCase(createSipgateAppNotification.fulfilled, (state, { payload }) => ({
			canNotify: payload.canNotify,
			fetching: false,
			fetched: true,
		}));
	},
});

export const reducer = slice.reducer;
