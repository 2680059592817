import { createAction } from '../../index';

import api from '../../../api';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';

function shouldFetchSms(state: ReduxState, userId: string) {
	return !state.sms.fetchedForUser.includes(userId) && !state.sms.fetchingForUser.includes(userId);
}

export const fetchSms = createAsyncThunk(
	'sms/fetch',
	(arg: { userId: string; force?: boolean }) => api.fetchSms(arg.userId),
	{
		condition: (arg, { getState }) => arg.force === true || shouldFetchSms(getState(), arg.userId),
	}
);

export const forceFetchSms = (userId: string) => fetchSms({ userId, force: true });

export const setSmsAlias = createAction(
	'SMS_SET_ALIAS',
	(userId: string, smsId: string, alias: string) => ({
		promise: () => api.setSmsAlias(userId, smsId, alias),
		data: {
			userId,
			smsId,
			alias,
		},
	})
);

export const createSms = createAsyncThunk('sms/create', (userId: string) => api.createSms(userId));

export const deleteSms = createAsyncThunk(
	'sms/delete',
	({ smsId }: { userId: string; smsId: string }) => api.deleteSms(smsId)
);
