import { State } from '../state';
import { ChangeActiveAudioPlayerAction } from './types';

export const changeActiveAudioPlayerReducer = (
	state: State,
	action: ChangeActiveAudioPlayerAction
): State => {
	const isSameAsRunning =
		state.runningAudioPlayer &&
		state.runningAudioPlayer.eventId === action.eventId &&
		state.runningAudioPlayer.playerId === action.playerId;

	if (!isSameAsRunning && action.playing) {
		return {
			...state,
			runningAudioPlayer: {
				eventId: action.eventId,
				playerId: action.playerId,
			},
		};
	}

	return { ...state, runningAudioPlayer: null };
};
