import { createAction } from '../..';
import Constants from './constants';
import { GoogleContacts } from './types';

export default {
	setGoogleContacts: createAction(
		Constants.GOOGLE_CONTACTS_SET,
		(contacts: GoogleContacts[]) => contacts
	),
};
