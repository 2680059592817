import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';
import { selectLocalprefix } from './selectors';

function shouldFetchLocalprefix(state: ReduxState, deviceId: string) {
	return (
		deviceId &&
		deviceId.indexOf('e') !== -1 &&
		!selectLocalprefix(state.deviceLocalprefix, deviceId)
	);
}

export const fetchLocalprefix = createAction('DEVICE_LOCALPREFIX_FETCH', (deviceId: string) => ({
	promise: () => api.getLocalprefix(deviceId),
	shouldFetch: (state: ReduxState) => shouldFetchLocalprefix(state, deviceId),
	data: {
		deviceId,
	},
}));

export const setLocalprefix = createAction(
	'DEVICE_LOCALPREFIX_SET',
	(deviceId: string, localprefix: string, active: boolean) => ({
		promise: api.setLocalprefix(deviceId, localprefix, active),
		data: {
			deviceId,
			localprefix,
			active,
		},
	})
);
