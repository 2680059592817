import { createSlice } from '@reduxjs/toolkit';
import {
	fetchDateBasedForwardings,
	saveDateBasedForwardingDestination,
	saveDateBasedForwardings,
} from './actions';
import { DateBasedForwarding, DateBasedForwardingState } from './types';
import { ApiDateBasedSet } from '../../../api/types/dateBasedSets';

const initialState: DateBasedForwardingState = {
	items: {},
	fetchingForExtension: [],
	fetchedForExtension: [],
};

export const reducer = createSlice({
	name: 'dateBasedForwardings',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchDateBasedForwardings.pending, (state, { meta }) => ({
			...state,
			fetchingForExtension: [...state.fetchingForExtension, meta.arg.extensionId],
		}));

		builder.addCase(fetchDateBasedForwardings.fulfilled, (state, { payload, meta }) => ({
			...state,
			fetchingForExtension: [
				...state.fetchingForExtension.filter(extensionId => extensionId !== meta.arg.extensionId),
			],
			fetchedForExtension: [
				...state.fetchedForExtension.filter(extensionId => extensionId !== meta.arg.extensionId),
				meta.arg.extensionId,
			],
			items: {
				...state.items,
				[meta.arg.extensionId]:
					payload !== null
						? [
								...payload.map(set => {
									switch (set.type) {
										case 'HOLIDAY':
											return {
												extensionId: set.extensionId,
												id: set.id,
												holidayPresetId: set.holidayPresetId,
												destination: set.destination,
												type: 'HOLIDAY' as const,
												selectedHolidayIds: set.selectedHolidayIds,
												name: set.name,
											};
										case 'CUSTOM':
											return {
												extensionId: set.extensionId,
												id: set.id,
												holidayPresetId: set.holidayPresetId,
												destination: set.destination,
												type: 'CUSTOM' as const,
												customDates: set.customDates.sort(),
												name: set.name,
											};
									}
									throw new Error('Unknown date based forwarding type');
								}),
							].sort((a, b) => Number.parseInt(a.id, 10) - Number.parseInt(b.id, 10))
						: null,
			},
		}));

		builder.addCase(saveDateBasedForwardings.fulfilled, (state, { meta, payload }) => ({
			...state,
			items: {
				...state.items,
				[meta.arg.extensionId]: [
					...payload.items
						.sort(
							(apiSetA, apiSetB) =>
								Number.parseInt(apiSetA.id, 10) - Number.parseInt(apiSetB.id, 10)
						)
						.map((dateBasedSet: ApiDateBasedSet): DateBasedForwarding => {
							switch (dateBasedSet.type) {
								case 'HOLIDAY':
									return {
										extensionId: meta.arg.extensionId,
										id: dateBasedSet.id,
										type: 'HOLIDAY' as const,
										holidayPresetId: dateBasedSet.preset,
										selectedHolidayIds: dateBasedSet.selectedHolidays,
										name: dateBasedSet.name,
										destination:
											state.items[meta.arg.extensionId]?.find(i => i.id === dateBasedSet.id)
												?.destination ?? null,
									};
								case 'CUSTOM':
									return {
										extensionId: meta.arg.extensionId,
										id: dateBasedSet.id,
										type: 'CUSTOM' as const,
										customDates: dateBasedSet.customDates.sort(),
										name: dateBasedSet.name || '',
										destination:
											state.items[meta.arg.extensionId]?.find(i => i.id === dateBasedSet.id)
												?.destination ?? null,
									};
							}
							throw new Error(`Invalid date based set type: ${dateBasedSet}`);
						}),
				],
			},
		}));

		builder.addCase(saveDateBasedForwardingDestination.fulfilled, (state, { meta }) => {
			if (!state.items[meta.arg.extensionId]) {
				return state;
			}

			const dateBasedForwarding = state.items[meta.arg.extensionId]!.find(
				dateBasedSet => dateBasedSet.id === meta.arg.dateBasedSetId
			);

			if (!dateBasedForwarding) {
				return state;
			}

			return {
				...state,
				items: {
					...state.items,
					[meta.arg.extensionId]: state.items[meta.arg.extensionId]!.map(forwarding => {
						if (forwarding.id === meta.arg.dateBasedSetId) {
							return {
								...forwarding,
								destination: meta.arg.destination,
							};
						}

						return forwarding;
					}),
				},
			};
		});
	},
}).reducer;
