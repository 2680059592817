import { createSlice } from '@reduxjs/toolkit';
import { AcdStatisticsState } from './types';
import {
	fetchAcdLiveStatistics,
	setAvailabilityTarget,
	setAverageHandlingTimeTarget,
	setCallPercentageInTimeLimitTarget,
	toggleAgentStatistics,
} from './actions';

const initialState: AcdStatisticsState = {
	items: {},
	fetchingForAcdStatistics: [],
	fetchedForAcdStatistics: [],
};

export const reducer = createSlice({
	name: 'acdStatistics',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchAcdLiveStatistics.pending, (state, action) => {
			return {
				...state,
				fetchingForAcdStatistics: [...state.fetchingForAcdStatistics, action.meta.arg.acdId],
			};
		});
		builder.addCase(fetchAcdLiveStatistics.fulfilled, (state, action) => {
			return {
				...state,
				fetchingForAcdStatistics: state.fetchingForAcdStatistics.filter(
					id => id !== action.meta.arg.acdId
				),
				fetchedForAcdStatistics: [...state.fetchedForAcdStatistics, action.meta.arg.acdId],
				items: {
					...state.items,
					[action.meta.arg.acdId]:
						action.payload.agentStatisticsEnabled && action.payload.agentStatistics
							? {
									agentsStateOverview: action.payload.agentsStateOverview,
									serviceLevel: action.payload.serviceLevel,
									queue: action.payload.queue,
									callStatistics: action.payload.callStatistics,
									agentStatisticsEnabled: action.payload.agentStatisticsEnabled,
									agentStatistics: action.payload.agentStatistics,
								}
							: {
									agentsStateOverview: action.payload.agentsStateOverview,
									serviceLevel: action.payload.serviceLevel,
									queue: action.payload.queue,
									callStatistics: action.payload.callStatistics,
									agentStatisticsEnabled:
										action.payload.agentStatisticsEnabled === null ? null : false,
								},
				},
			};
		});
		builder.addCase(setAvailabilityTarget.pending, (state, action) => ({
			...state,
			items: {
				...state.items,
				[action.meta.arg.acdId]: {
					...state.items[action.meta.arg.acdId],
					serviceLevel: {
						...state.items[action.meta.arg.acdId].serviceLevel,
						availability: {
							...state.items[action.meta.arg.acdId].serviceLevel.availability,
							target: action.meta.arg.target,
						},
					},
				},
			},
		}));
		builder.addCase(setCallPercentageInTimeLimitTarget.pending, (state, action) => ({
			...state,
			items: {
				...state.items,
				[action.meta.arg.acdId]: {
					...state.items[action.meta.arg.acdId],
					serviceLevel: {
						...state.items[action.meta.arg.acdId].serviceLevel,
						callPercentageInTimeLimit: {
							...state.items[action.meta.arg.acdId].serviceLevel.callPercentageInTimeLimit,
							timeLimit: action.meta.arg.timeLimit,
							target: action.meta.arg.target,
						},
					},
				},
			},
		}));
		builder.addCase(setCallPercentageInTimeLimitTarget.fulfilled, (state, action) => {
			return {
				...state,
				items: {
					...state.items,
					[action.meta.arg.acdId]: {
						...state.items[action.meta.arg.acdId],
						serviceLevel: {
							...state.items[action.meta.arg.acdId].serviceLevel,
							callPercentageInTimeLimit: {
								...action.payload,
							},
						},
					},
				},
			};
		});
		builder.addCase(setAverageHandlingTimeTarget.pending, (state, action) => ({
			...state,
			items: {
				...state.items,
				[action.meta.arg.acdId]: {
					...state.items[action.meta.arg.acdId],
					serviceLevel: {
						...state.items[action.meta.arg.acdId].serviceLevel,
						averageHandlingTime: {
							...state.items[action.meta.arg.acdId].serviceLevel.averageHandlingTime,
							target: action.meta.arg.target,
						},
					},
				},
			},
		}));
		builder.addCase(toggleAgentStatistics.pending, (state, action) => {
			if (action.meta.arg.enabled) {
				return {
					...state,
					items: {
						...state.items,
						[action.meta.arg.acdId]: {
							...state.items[action.meta.arg.acdId],
							agentStatisticsEnabled: action.meta.arg.enabled,
							agentStatistics: {},
						},
					},
				};
			}

			return {
				...state,
				items: {
					...state.items,
					[action.meta.arg.acdId]: {
						...state.items[action.meta.arg.acdId],
						agentStatisticsEnabled: action.meta.arg.enabled,
						agentStatistics: undefined,
					},
				},
			};
		});
	},
}).reducer;
