import React, { ComponentType } from 'react';

/**
 * Get a hopefully human-readable representation of a component.
 * You can pass a class, a function or a string (e.g. `div`).
 *
 * Useful if you have a `React.ReactElement` and want to print a
 * human-readable error:
 *
 * ```
 * throw new Error(`Invalid element passed: ${stringifyElementType(element.type)}`);
 * ```
 */
export function stringifyElementType(
	type: React.ComponentType | ((...args: never[]) => React.ReactElement | null) | string
) {
	if (typeof type === 'string') {
		return type;
	}

	if ('displayName' in type) {
		return type.displayName;
	}

	return type.name;
}

/**
 * Convert any kind of react children structure into a flat list of top-level nodes.
 * This flattens fragments and arrays and removes nulls and undefines.
 *
 * Useful when you are in a hacky mood and want to do something for all children of
 * your component.
 */
export function flattenChildren(
	children: React.ReactNode
): (React.ReactElement | string | number | React.ReactFragment | React.ReactPortal | boolean)[] {
	if (children === null || children === undefined) {
		return [];
	}

	if (Array.isArray(children)) {
		return children.flatMap(flattenChildren);
	}

	if (React.isValidElement(children)) {
		if (children.type === React.Fragment) {
			return flattenChildren(children.props.children);
		}

		return [children];
	}

	const asArray = React.Children.toArray(children);
	if (asArray.length > 1) {
		return asArray.flatMap(flattenChildren);
	}

	return asArray;
}

/**
 * Throws if the passed children contains anything that is not expected.
 *
 * Useful if you need to ensure your component is only passed a specific subset of elements.
 * Usually this would be a job for the type system, but typescript type-erases any rendered
 * element to `React.ReactElement`.
 *
 * The same rules as for `flattenChildren` apply, so `null` and `undefined` are ignored,
 * arrays are flattened and so on.
 *
 * **Example:**
 *
 * ```
 * const ITakeButtons = (props: { children: React.ReactNode }) => {
 *   assertChildTypes(props.children, ['button', Button]);
 *
 *   return props.children;
 * }
 *
 * // This fails at runtime
 * <ITakeButtons>
 *   <span>Hi</span>
 * </ITakeButtons>
 *
 * // And this works
 * <ITakeButtons>
 *   <button>Hi</button>
 * </ITakeButtons>
 * ```
 */
export function assertChildTypes(
	children: React.ReactNode,
	expected: (React.ComponentType | ((...args: never[]) => React.ReactElement | null) | string)[]
) {
	for (const child of flattenChildren(children)) {
		if (!React.isValidElement(child)) {
			throw new Error(
				`Expected children of types [${expected
					.map(stringifyElementType)
					.join(', ')}] but received ${typeof child === 'string' ? `"${child}"` : child}`
			);
		}

		if (!expected.includes(child.type)) {
			throw new Error(
				`Expected children of types [${expected
					.map(stringifyElementType)
					.join(', ')}] but received <${stringifyElementType(child.type)}>`
			);
		}
	}
}

export function isChildType<C extends ComponentType<any>>(
	child: React.ReactNode,
	expected: C
): child is React.ReactElement<React.ComponentProps<C>, C> {
	return React.isValidElement(child) && child.type === expected;
}
