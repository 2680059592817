import { State } from '../../state';
import { MarkUnreadPendingAction } from './types';

export const markUnreadPending = (state: State, action: MarkUnreadPendingAction): State => {
	if (!state.entries.result) {
		return state;
	}

	return {
		...state,

		entries: {
			...state.entries,

			result: {
				...state.entries.result,

				normalized: state.entries.result.normalized.map(entry => {
					if (!action.eventIds.includes(entry.originalEvent.id)) {
						return entry;
					}

					return {
						...entry,

						originalEvent: {
							...entry.originalEvent,
							read: 'UNREAD' as const,
						},
					};
				}),
			},
		},
	};
};
