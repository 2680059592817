import { handleActions } from '../..';
import { ApiError } from '../../../api';
import * as actions from './actions';
import { TwoFactorAuthenticationSettingsState } from './types';

const initialState: TwoFactorAuthenticationSettingsState = {
	items: [],
	fetching: [],
};

export default handleActions<TwoFactorAuthenticationSettingsState, PossibleActions<typeof actions>>(
	{
		TWO_FACTOR_AUTHENTICATION_SETTING_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetching: [...state.fetching, data.webuserId],
		}),
		TWO_FACTOR_AUTHENTICATION_SETTING_FETCH_SUCCESS: (state, { payload, data }) => {
			const newFetching = state.fetching.filter(webuserId => !webuserId.includes(data.webuserId));
			const newItems = state.items.filter(item => !item.webuserId.includes(data.webuserId));
			return {
				...state,
				items: [...newItems, payload],
				fetching: newFetching,
			};
		},
		TWO_FACTOR_AUTHENTICATION_SETTING_FETCH_FAILURE: (state, { payload }) => {
			if (payload instanceof ApiError && payload.status !== 404) {
				return state;
			}

			return {
				...state,
				items: [],
				fetching: [],
			};
		},
	},
	initialState
);
