import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchAccessibleNumbers } from './actions';
import { SliceData } from '../../index';
import { PhoneNumber } from './types';

export const useNumbers = (forceFetch?: boolean): SliceData<PhoneNumber[]> => {
	const dispatch = useDispatch();

	const numbers = useSelector(s => s.numbers);

	useEffect(() => {
		dispatch(fetchAccessibleNumbers(forceFetch));
	}, [dispatch, forceFetch]);

	if (!numbers.fetched) {
		return { data: null, fetched: false };
	}

	return {
		data: numbers.items,
		fetched: true,
	};
};
