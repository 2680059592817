import { createSlice } from '@reduxjs/toolkit';
import {
	createBatchUserAuthRequest,
	deleteBatchUser,
	deleteCurrentBatchJob,
	executeBatchUserJob,
	fetchBatchUsersFromProvider,
	getBatchJobs,
	getBatchUsers,
	updateBatchUser,
	updateBatchUsers,
	uploadCSVFile,
} from './actions';
import { BatchUserImportState } from './types';

export const initialState: BatchUserImportState = {
	jobs: {
		currentId: null,
		items: [],
		fetching: 'idle',
		deleting: 'idle',
	},
	users: {
		items: [],
		fetching: 'idle',
		deletingState: [],
		updating: 'idle',
	},
	authRequest: {
		redirectUrl: null,
		creatingAuthRequest: 'idle',
	},
	executing: 'idle',
	uploading: 'idle',
	updatingField: 'idle',
};

const slice = createSlice({
	name: 'batchUserImport',
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: builder => {
		// CSV Uploading
		builder.addCase(uploadCSVFile.pending, state => ({
			...state,
			uploading: 'pending',
		}));
		builder.addCase(uploadCSVFile.fulfilled, (state, action) => ({
			...state,
			jobs: {
				...state.jobs,
				currentId: action.payload.jobId,
			},
			uploading: 'succeeded',
		}));
		builder.addCase(uploadCSVFile.rejected, state => ({
			...state,
			uploading: 'failed',
		}));

		// getting batch users
		builder.addCase(getBatchUsers.pending, (state, action) => ({
			...state,
			jobs: {
				...state.jobs,
				currentId: action.meta.arg,
			},
			users: {
				...state.users,
				fetching: 'pending',
			},
		}));
		builder.addCase(getBatchUsers.fulfilled, (state, action) => ({
			...state,
			users: {
				...state.users,
				items: action.payload.items,
				fetching: 'succeeded',
			},
		}));
		builder.addCase(getBatchUsers.rejected, state => ({
			...state,
			users: {
				...state.users,
				fetching: 'failed',
			},
		}));
		// delete batch user
		builder.addCase(deleteBatchUser.pending, (state, action) => {
			const index = state.users.items.findIndex(user => user.id === action.meta.arg.userId);
			const users = [...state.users.items];
			users.splice(index, 1);

			const indexDeletingState = state.users.deletingState.findIndex(
				user => user.id === action.meta.arg.userId
			);
			const deletingState = [...state.users.deletingState];
			deletingState.splice(indexDeletingState, 1, { id: action.meta.arg.userId, state: 'pending' });

			return {
				...state,
				users: {
					...state.users,
					items: users,
					deletingState,
				},
			};
		});
		builder.addCase(deleteBatchUser.fulfilled, (state, action) => {
			const indexDeletingState = state.users.deletingState.findIndex(
				user => user.id === action.meta.arg.userId
			);
			const deletingState = [...state.users.deletingState];
			deletingState.splice(indexDeletingState, 1, {
				id: action.meta.arg.userId,
				state: 'succeeded',
			});

			return {
				...state,
				users: {
					...state.users,
					deletingState,
				},
			};
		});
		builder.addCase(deleteBatchUser.rejected, (state, action) => {
			const indexDeletingState = state.users.deletingState.findIndex(
				user => user.id === action.meta.arg.userId
			);
			const deletingState = [...state.users.deletingState];
			deletingState.splice(indexDeletingState, 1, { id: action.meta.arg.userId, state: 'failed' });

			return {
				...state,
				users: {
					...state.users,
					deletingState,
				},
			};
		});

		builder.addCase(updateBatchUser.pending, state => ({
			...state,
			users: {
				...state.users,
				updating: 'pending',
			},
		}));

		builder.addCase(updateBatchUser.fulfilled, (state, action) => {
			const index = state.users.items.findIndex(user => user.id === action.payload.id);
			const users = [...state.users.items];
			users.splice(index, 1, action.payload);
			return {
				...state,
				users: {
					...state.users,
					items: users,
					updating: 'succeeded',
				},
			};
		});

		builder.addCase(updateBatchUser.rejected, state => ({
			...state,
			users: {
				...state.users,
				updating: 'failed',
			},
		}));

		builder.addCase(updateBatchUsers.pending, state => ({
			...state,
			users: {
				...state.users,
				updating: 'pending',
			},
		}));

		builder.addCase(updateBatchUsers.fulfilled, (state, action) => {
			const newBatchUsers = [...state.users.items];
			action.payload.items.forEach(user => {
				const index = newBatchUsers.findIndex(batchUser => batchUser.id === user.id);
				newBatchUsers.splice(index, 1, user);
			});

			return {
				...state,
				users: {
					...state.users,
					items: newBatchUsers,
					updating: 'succeeded',
				},
			};
		});

		builder.addCase(updateBatchUsers.rejected, state => ({
			...state,
			users: {
				...state.users,
				updating: 'failed',
			},
		}));

		// executing batch user job
		builder.addCase(executeBatchUserJob.pending, state => ({
			...state,
			executing: 'pending',
		}));
		builder.addCase(executeBatchUserJob.fulfilled, state => ({
			...state,
			executing: 'succeeded',
		}));
		builder.addCase(executeBatchUserJob.rejected, state => ({
			...state,
			executing: 'failed',
		}));
		// delete batch job
		builder.addCase(deleteCurrentBatchJob.pending, state => ({
			...state,
			jobs: {
				...state.jobs,
				deleting: 'pending',
			},
		}));
		builder.addCase(deleteCurrentBatchJob.fulfilled, (state, action) => {
			const index = state.jobs.items.findIndex(job => job.id === action.meta.arg);
			const jobs = [...state.jobs.items];
			jobs.splice(index, 1);
			return {
				...state,
				jobs: {
					...state.jobs,
					items: jobs,
					deleting: 'succeeded',
				},
			};
		});
		builder.addCase(deleteCurrentBatchJob.rejected, state => ({
			...state,
			jobs: {
				...state.jobs,
				deleting: 'failed',
			},
		}));
		// get batch users
		builder.addCase(getBatchJobs.pending, state => ({
			...state,
			jobs: {
				...state.jobs,
				fetching: 'pending',
			},
		}));
		builder.addCase(getBatchJobs.fulfilled, (state, action) => ({
			...state,
			jobs: {
				...state.jobs,
				items: action.payload.items,
				fetching: 'succeeded',
			},
		}));
		builder.addCase(getBatchJobs.rejected, state => ({
			...state,
			jobs: {
				...state.jobs,
				fetching: 'failed',
			},
		}));
		// redirect url
		builder.addCase(createBatchUserAuthRequest.pending, state => ({
			...state,
			authRequest: {
				...state.authRequest,
				creatingAuthRequest: 'pending',
			},
		}));
		builder.addCase(createBatchUserAuthRequest.fulfilled, (state, action) => ({
			...state,
			authRequest: {
				...state.authRequest,
				redirectUrl: action.payload.redirectUrl,
				creatingAuthRequest: 'succeeded',
			},
		}));
		builder.addCase(createBatchUserAuthRequest.rejected, state => ({
			...state,
			authRequest: {
				...state.authRequest,
				creatingAuthRequest: 'failed',
			},
		}));

		builder.addCase(fetchBatchUsersFromProvider.pending, state => ({
			...state,
			jobs: {
				...state.jobs,
				fetching: 'pending',
			},
		}));

		builder.addCase(fetchBatchUsersFromProvider.fulfilled, (state, action) => ({
			...state,
			jobs: {
				...state.jobs,
				currentId: action.payload.jobId,
				items: [
					...state.jobs.items,
					{
						id: action.payload.jobId,
						status: 'created',
						provider: action.meta.arg.provider,
					},
				],
				fetching: 'succeeded',
			},
		}));

		builder.addCase(fetchBatchUsersFromProvider.rejected, state => ({
			...state,
			jobs: {
				...state.jobs,
				fetching: 'failed',
			},
		}));
	},
});

export const { reset } = slice.actions;
export const reducer = slice.reducer;
