import { useEffect } from 'react';
import { useAction } from '../..';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	createContact,
	deleteContacts,
	fetchContacts,
	updateContact,
	importFromCachedCSV,
	prepareCSVImport,
	updateColumnMapping,
	fetchContact,
} from './actions';

export const useContacts = () => {
	const contacts = useSelector(state => state.contacts);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!contacts.fetched) {
			dispatch(fetchContacts());
		}
	}, [dispatch, contacts]);

	return {
		fetchContacts: useAction(fetchContacts),
		contacts: contacts.items,
		importing: contacts.importing,
		importMapper: contacts.importMapper,
		contactsFetched: contacts.fetched,
		createContact: useAction(createContact),
		fetchContact: useAction(fetchContact),
		updateContact: useAction(updateContact),
		deleteContacts: useAction(deleteContacts),
		prepareCSVImport: useAction(prepareCSVImport),
		updateColumnMapping: useAction(updateColumnMapping),
		importFromCachedCSV: useAction(importFromCachedCSV),
	};
};
