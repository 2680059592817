import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';

export const fetchApiClients = createAsyncThunk('apiClients/fetch', () => api.getApiClients(), {
	condition: (_, { getState }) => !getState().apiClients.fetching && !getState().apiClients.fetched,
});

export const createApiClient = createAsyncThunk(
	'apiClients/create',
	async (apiClient: { name: string; description: string; privacyUrl: string; termsUrl: string }) =>
		api.createApiClient(apiClient)
);

export const deleteApiClient = createAsyncThunk('apiClients/delete', async (id: string) =>
	api.deleteApiClient(id)
);

export const updateApiClient = createAsyncThunk(
	'apiClients/update',
	async (data: {
		id: string;
		name: string;
		description: string;
		privacyUrl: string;
		termsUrl: string;
		redirectUris: string[];
		webOrigins: string[];
	}) =>
		api.updateApiClient(data.id, {
			name: data.name,
			description: data.description,
			privacyUrl: data.privacyUrl,
			termsUrl: data.termsUrl,
			redirectUris: data.redirectUris,
			webOrigins: data.webOrigins,
		})
);
