import { handleActions, Immutable } from '../..';
import { ForwardingStep } from '../../../api/types/forwardings';
import { deleteAudioFile } from '../audioFiles';
import * as actions from './actions';
import {
	ConditionalForwardingSet,
	ForwardingsState,
	TimeBasedForwardingSet,
	UnconditionalForwardingSet,
} from './types';

const initialState: ForwardingsState = {
	updating: {},
	fetching: {},
	fetched: {},
	items: {},
};

const removeAnnouncementFromForwardingStep = (step: ForwardingStep, announcementId: string) => {
	if (step.destination.type !== 'VOICEMAIL' || step.destination.announcementId !== announcementId) {
		return step;
	}

	return {
		...step,
		destination: {
			type: step.destination.type,
			voicemailId: step.destination.voicemailId,
		},
	};
};

const removeAnnouncementFromForwardingSet = (
	set: Immutable<TimeBasedForwardingSet>,
	announcementId: string
) => {
	if (set.type === 'unconditional') {
		return {
			...set,
			unconditional: set.unconditional.map(step =>
				removeAnnouncementFromForwardingStep(step, announcementId)
			),
		};
	}

	return {
		...set,
		online: set.online.map(step => removeAnnouncementFromForwardingStep(step, announcementId)),
		busy: set.busy.map(step => removeAnnouncementFromForwardingStep(step, announcementId)),
		offline: set.offline.map(step => removeAnnouncementFromForwardingStep(step, announcementId)),
	};
};

export default handleActions<
	ForwardingsState,
	PossibleActions<typeof actions | typeof deleteAudioFile>
>(
	{
		TIME_BASED_FORWARDINGS_FETCH_PENDING: (state, { data }) => {
			return {
				...state,
				fetching: {
					...state.fetching,
					[data.extensionId]: true,
				},
			};
		},

		TIME_BASED_FORWARDINGS_FETCH_SUCCESS: (state, { payload, data }) => {
			return {
				...state,
				fetching: {
					...state.fetching,
					[data.extensionId]: false,
				},
				fetched: {
					...state.fetched,
					[data.extensionId]: true,
				},
				items: {
					...state.items,
					[data.extensionId]: state.updating[data.extensionId]
						? state.items[data.extensionId]
						: payload,
				},
			};
		},

		TIME_BASED_FORWARDINGS_UPDATE_PENDING: (state, { data }) => {
			return {
				...state,
				updating: {
					...state.updating,
					[data.extensionId]: true,
				},
			};
		},

		TIME_BASED_SETS_UPDATE_PENDING: (state, { data }) => {
			return {
				...state,
				updating: {
					...state.updating,
					[data.extensionId]: true,
				},
				items: {
					...state.items,
					[data.extensionId]: data.timeBasedSets,
				},
			};
		},

		TIME_BASED_SETS_UPDATE_SUCCESS: (state, { data, payload }) => {
			const oldSets = state.items[data.extensionId];
			if (!oldSets) {
				return {
					...state,
					updating: {
						...state.updating,
						[data.extensionId]: false,
					},
					items: {
						...state.items,
						[data.extensionId]: payload.items.map(set => ({
							id: set.id,
							name: set.name || '',
							activeTimes: set.activeTimes,
							isUserDefined: set.isUserDefined,
							priority: set.priority,
							type: 'conditional' as const,
							busy: [],
							offline: [],
							online: [],
						})),
					},
				};
			}
			const newSets = payload.items;

			const oldSetsToBeKept = oldSets.filter(set =>
				payload.items.find(newSet => newSet.id === set.id)
			);
			const setsToBeSet = newSets.map(set => {
				const oldSet = oldSetsToBeKept.find(o => o.id === set.id);
				if (oldSet) {
					return {
						...oldSet,
						name: set.name || '',
						activeTimes: set.activeTimes,
						isUserDefined: set.isUserDefined,
						priority: set.priority,
					};
				}
				return {
					id: set.id,
					name: set.name || '',
					activeTimes: set.activeTimes,
					isUserDefined: set.isUserDefined,
					priority: set.priority,
					type: 'conditional' as const,
					busy: [],
					offline: [],
					online: [],
				};
			});

			return {
				...state,
				updating: {
					...state.updating,
					[data.extensionId]: false,
				},
				items: {
					...state.items,
					[data.extensionId]: setsToBeSet,
				},
			};
		},

		TIME_BASED_FORWARDINGS_UPDATE_SUCCESS: (state, { payload, data }) => {
			const oldSet = state.items[data.extensionId]!.find(set => set.id === data.forwardingSetId);
			if (!oldSet) {
				return state;
			}

			let forwardingSet: TimeBasedForwardingSet;
			if ('UNCONDITIONAL' in payload.forwardings) {
				forwardingSet = {
					id: oldSet.id,
					name: oldSet.name,
					activeTimes: oldSet.activeTimes,
					isUserDefined: oldSet.isUserDefined,
					priority: 'priority' in oldSet ? oldSet.priority : 0,
					type: 'unconditional',
					unconditional: payload.forwardings.UNCONDITIONAL,
				} as UnconditionalForwardingSet;
			} else {
				forwardingSet = {
					id: oldSet.id,
					name: oldSet.name,
					activeTimes: oldSet.activeTimes,
					isUserDefined: oldSet.isUserDefined,
					priority: 'priority' in oldSet ? oldSet.priority : 0,
					type: 'conditional',
					online: payload.forwardings.UNREACHABLE,
					busy: payload.forwardings.BUSY,
					offline: payload.forwardings.OFFLINE,
				} as ConditionalForwardingSet;
			}

			return {
				...state,
				updating: {
					...state.updating,
					[data.extensionId]: false,
				},
				items: {
					...state.items,
					[data.extensionId]: state.items[data.extensionId]!.map(set => {
						if (set.id === data.forwardingSetId) {
							return forwardingSet;
						}
						return set;
					}),
				},
			};
		},

		AUDIO_FILE_DELETE_PENDING: (state, { data }) => {
			const newState = {
				...state,
				items: {} as { [extensionId: string]: Immutable<TimeBasedForwardingSet[] | undefined> },
			};

			for (const extensionId in state.items) {
				newState.items[extensionId] = state.items[extensionId]!.map(set =>
					removeAnnouncementFromForwardingSet(set, data.audioFileId)
				);
			}

			return newState;
		},
	},

	initialState
);
