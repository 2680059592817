import { useEffect, useMemo } from 'react';
import { SliceData, useAction } from '../..';
import { useSelector, useDispatch } from '../../utils/wrapper';
import { fetchMobileInfo, forceFetchMobileInfo } from './actions';
import { MobileInfo } from './types';

export const useMobileInfo = (): SliceData<MobileInfo[]> => {
	const state = useSelector(s => s.mobile);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchMobileInfo());
	}, [dispatch]);

	return useMemo(() => {
		if (state.fetched) {
			return {
				data: state.data,
				fetched: true,
			};
		}

		return {
			fetched: false,
			data: null,
		};
	}, [state]);
};

export const useMobileInfoActions = () => ({
	forceFetchMobileInfo: useAction(forceFetchMobileInfo),
});
