import { hasKey } from '../utils/types';

export class ApiError extends Error {
	public readonly json: unknown;

	public readonly status: number;

	public constructor(json: unknown, status: number, statusText: string) {
		super(statusText);
		this.json = json;
		this.status = status;
	}
}

export function isApiError(error: unknown): error is ApiError {
	return hasKey(error, 'status') && hasKey(error, 'json') && typeof error.status === 'number';
}
