import { ReportsState } from './types';
import { handleActions } from '../../utils/actions';
import * as actions from './actions';

export const initialState: ReportsState = {
	items: [],
	fetched: false,
	fetching: false,
	deletedIds: [],
};

export default handleActions<ReportsState, PossibleActions<typeof actions>>(
	{
		REPORTS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		REPORTS_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			items: payload.reports.filter(report => !state.deletedIds.includes(report.id)),
			fetched: true,
			fetching: false,
		}),
		REPORTS_FETCH_FAILURE: state => ({ ...state, fetching: false }),
		REPORT_DELETE_PENDING: state => ({ ...state }),
		REPORT_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(item => item.id !== data.id),
			fetching: false,
			deletedIds: [...state.deletedIds, data.id],
		}),
		REPORT_DELETE_FAILURE: state => ({ ...state, fetching: false }),
		REPORT_RENAME_SUCCESS: (state, { data }) => {
			const index = state.items.findIndex(item => item.id === data.id);
			if (index !== -1) {
				return {
					...state,
					items: [
						...state.items.slice(0, index),
						{ ...state.items[index], name: data.name },
						...state.items.slice(index + 1),
					],
				};
			}

			return {
				...state,
			};
		},
		REPORT_ADD_PENDING: state => ({ ...state }),
		REPORT_ADD_FAILURE: state => ({ ...state }),
		REPORT_ADD_SUCCESS: (state, { payload }) => ({
			...state,
			items: [
				...state.items,
				{ ...payload, id: `${Math.floor(Math.random() * (10000 - 10) + 10)}` },
			],
		}),
	},
	initialState
);
