import { DateTime } from 'luxon';
import { ManagedFieldValidator } from '../ManagedForm';
import { renderError } from './translate';
import { Translate } from './types';

/**
 * Validate the value of an `<input type="date" />`.
 * It ensures the user picked a complete, valid date
 * within the provided range.
 *
 * ```
 * validateDate(translate, {
 *     min: new Date("2023-12-13"),
 *     max: new Date("2024-12-13")
 * })
 * ```
 */
export const validateDate =
	(
		translate: Translate | string,
		options: { min?: DateTime; max?: DateTime } = {}
	): ManagedFieldValidator<string, DateTime> =>
	(datestring: string) => {
		const [year, month, day] = datestring.split('-').map(part => parseInt(part, 10)) as (
			| number
			| undefined
		)[];

		if (typeof year === 'undefined' || typeof month === 'undefined' || typeof day === 'undefined') {
			return {
				valid: false,
				error: renderError(translate, 'ERRORHINT_INCOMPLETE_DATE'),
			};
		}

		if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
			return {
				valid: false,
				error: renderError(translate, 'ERRORHINT_INVALID_DATE'),
			};
		}

		// We intentionally create the date in local time because the user
		// will provide a date in their local time zone.
		const date = DateTime.local(year, month, day);

		if (!date.isValid) {
			return {
				valid: false,
				error: renderError(translate, 'ERRORHINT_INVALID_DATE'),
			};
		}

		if (options.max && options.min && (date > options.max || date < options.min)) {
			return {
				valid: false,
				error: renderError(
					translate,
					'ERRORHINT_DATE_BETWEEN',
					options.min.toLocaleString(),
					options.max.toLocaleString()
				),
			};
		}

		if (options.max && date > options.max) {
			return {
				valid: false,
				error: renderError(translate, 'ERRORHINT_DATE_AFTER', options.max.toLocaleString()),
			};
		}

		if (options.min && date < options.min) {
			return {
				valid: false,
				error: renderError(translate, 'ERRORHINT_DATE_BEFORE', options.min.toLocaleString()),
			};
		}

		return {
			valid: true,
			value: date,
		};
	};
