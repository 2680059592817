import { useSelector } from '../../utils/wrapper';

const useIgel = () => useSelector(state => state.igel);

export default useIgel;

export const useIsScrolled = () => {
	const igel = useIgel();

	return igel.isScrolled;
};
