import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';

export const fetchDomainVerifications = createAsyncThunk(
	'domainVerification/fetchAll',
	(_force?: boolean) => api.getDomainVerifications(),
	{
		condition: (force, { getState }) =>
			force === true ||
			(!getState().domainVerification.fetched && !getState().domainVerification.fetching),
	}
);

export const createDomainVerification = createAsyncThunk(
	'domainVerification/create',
	async (domain: string) => api.createDomainVerification(domain)
);

export const deleteDomainVerification = createAsyncThunk(
	'domainVerification/delete',
	(verificationId: string) => api.deleteDomainVerification(verificationId)
);

export const getDomainVerification = createAsyncThunk(
	'domainVerification/get',
	async (verificationId: string) => api.getDomainVerification(verificationId)
);

export const resetDomainVerification = createAsyncThunk(
	'domainVerification/reset',
	(verificationId: string) => api.resetDomainVerification(verificationId)
);
