import { hasKey } from '../../utils/types';
import {
	forceFetchRestrictions,
	forceFetchRestrictionsForUser,
	Restriction,
} from '../modules/restrictions';
import { isPromiseAction } from './redux-promise-middleware';

export type UpdateRestrictionMiddlewareNext<Action> = Action;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type UpdateRestrictionMiddlewareResult<Action, NextResult> = NextResult;

export type UpdateRestrictionMiddlewareEmits =
	| ReturnType<typeof forceFetchRestrictions>
	| ReturnType<typeof forceFetchRestrictionsForUser>;

interface RestrictedAction {
	payload: {
		restrictions: {
			userId?: string;
			restrictions: Restriction[];
		};
		promise: Promise<unknown>;
	};
}

function isRestrictedAction(action: unknown): action is RestrictedAction {
	return (
		hasKey(action, 'payload') && hasKey(action.payload, 'restrictions') && isPromiseAction(action)
	);
}

export const updateRestrictionMiddleware = ({
	dispatch,
}: {
	dispatch: (action: UpdateRestrictionMiddlewareEmits) => void;
}) => {
	return <Action, NextResult>(
			next: (action: UpdateRestrictionMiddlewareNext<Action>) => NextResult
		) =>
		(action: Action): UpdateRestrictionMiddlewareResult<Action, NextResult> => {
			if (!isRestrictedAction(action)) {
				return next(action);
			}

			action.payload.promise
				.then(() => {
					dispatch(
						action.payload.restrictions.userId
							? forceFetchRestrictionsForUser(
									action.payload.restrictions.userId,
									action.payload.restrictions.restrictions
								)
							: forceFetchRestrictions(action.payload.restrictions.restrictions)
					);
				})
				.catch(() => {});

			return next(action);
		};
};
