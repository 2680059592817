import { Store } from '@reduxjs/toolkit';

import { fetchHistory } from '../fetchHistory/action';
import { State } from '../../state';
import {
	DeleteEntriesPendingAction,
	DeleteEntriesSuccessAction,
	DeleteEntriesErrorAction,
} from './types';
import api from '../../../../../api';
import { Selections } from '../../../selections/parsers';

export const deleteEntries =
	(store: Store<State>) => async (eventIds: string[], selections: Selections) => {
		const { result } = store.getState().entries;
		const deletingAll = !!result && result.totalCount === eventIds.length;
		const deletingPage = !!result && result.normalized.length === eventIds.length;

		store.dispatch<DeleteEntriesPendingAction>({
			type: 'DELETE_ENTRIES_PENDING',
			eventIds,
		});

		try {
			await api.deleteHistoryEntries(eventIds);

			store.dispatch<DeleteEntriesSuccessAction>({
				type: 'DELETE_ENTRIES_SUCCESS',
			});
		} catch (e) {
			store.dispatch<DeleteEntriesErrorAction>({
				type: 'DELETE_ENTRIES_ERROR',
			});

			throw e;
		} finally {
			if (deletingPage && !deletingAll) {
				fetchHistory(store)(selections);
			}
		}
	};
