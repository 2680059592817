import { DateTime } from 'luxon';
import { createSlice } from '@reduxjs/toolkit';
import { AcdAudioFileState } from './types';
import {
	createAcdAudioFile,
	deleteAcdAudioFile,
	fetchAcdAudioFileContent,
	fetchAcdAudioFiles,
	setAcdAudioFileAlias,
} from './actions';

const initialState: AcdAudioFileState = {
	items: [],
	fetchedForAcd: [],
	fetchingForAcd: [],
	creatingAudioFileForAcd: [],
};

export const reducer = createSlice({
	name: 'acdAudioFiles',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchAcdAudioFiles.pending, (state, action) => ({
				...state,
				fetchingForAcd: [...state.fetchingForAcd, action.meta.arg.acdId],
			}))
			.addCase(fetchAcdAudioFiles.fulfilled, (state, action) => ({
				...state,

				items: [
					...state.items.filter(item => item.acdId !== action.meta.arg.acdId),
					...action.payload.items.map(item => ({
						id: item.id,
						alias: item.alias,
						url: state.items.find(stateItem => stateItem.id === item.id)?.url ?? {
							state: 'UNFETCHED' as const,
						},
						acdId: action.meta.arg.acdId,
						initial: item.initial,
						type: item.type,
						default: item.default,
					})),
				],

				fetchedForAcd: [
					...state.fetchedForAcd.filter(fetched => fetched.acdId !== action.meta.arg.acdId),
					{ acdId: action.meta.arg.acdId, timestamp: DateTime.now() },
				],
				fetchingForAcd: state.fetchingForAcd.filter(id => id !== action.meta.arg.acdId),
			}))
			.addCase(createAcdAudioFile.pending, (state, action) => ({
				...state,
				creatingAudioFileForAcd: [...state.creatingAudioFileForAcd, action.meta.arg.acdId],
			}))
			.addCase(createAcdAudioFile.fulfilled, (state, action) => ({
				...state,
				creatingAudioFileForAcd: state.creatingAudioFileForAcd.filter(
					acdId => acdId !== action.meta.arg.acdId
				),
				items: [
					...state.items.filter(item => item.id !== action.payload.id),
					{
						id: action.payload.id,
						alias: action.payload.alias,
						acdId: action.meta.arg.acdId,
						active: false,
						url: { state: 'UNFETCHED' as const },
						initial: false,
						type: action.payload.type,
						default: false,
					},
				],
			}))
			.addCase(deleteAcdAudioFile.pending, (state, action) => ({
				...state,
				items: state.items.filter(audioFile => audioFile.id !== action.meta.arg.audioFileId),
			}))
			.addCase(setAcdAudioFileAlias.pending, (state, action) => ({
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== action.meta.arg.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						alias: action.meta.arg.alias,
					};
				}),
			}))
			.addCase(fetchAcdAudioFileContent.pending, (state, action) => ({
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== action.meta.arg.audioFile.id) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'FETCHING' as const },
					};
				}),
			}))
			.addCase(fetchAcdAudioFileContent.fulfilled, (state, action) => ({
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== action.meta.arg.audioFile.id) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'FETCHED' as const, url: URL.createObjectURL(action.payload) },
					};
				}),
			}))
			.addCase(fetchAcdAudioFileContent.rejected, (state, action) => ({
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== action.meta.arg.audioFile.id) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'ERROR' as const, timestamp: DateTime.now() },
					};
				}),
			}));
	},
}).reducer;
