import { createSlice } from '@reduxjs/toolkit';
import { createJoyride, fetchJoyrides } from './actions';
import { JoyridesState } from './types';

export const initialState = {
	items: [],
	fetching: false,
	fetched: false,
} as JoyridesState;

const slice = createSlice({
	name: 'joyrides',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchJoyrides.pending, state => ({
			...state,
			fetching: true,
			fetched: false,
		}));

		builder.addCase(fetchJoyrides.fulfilled, (state, { payload }) => ({
			...state,
			items: payload.joyrides,
			fetching: false,
			fetched: true,
		}));

		builder.addCase(createJoyride.fulfilled, (state, { payload }) => ({
			items: [...state.items, ...payload.joyrides],
			fetching: false,
			fetched: true,
		}));
	},
});

export const reducer = slice.reducer;
