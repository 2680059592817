export const formatFromToUnitString = (
	amount: number,
	fromUnit: 'BYTE' | 'KILOBYTE' | 'MEGABYTE',
	targetUnit: 'MEGABYTE' | 'GIGABYTE' | 'KILOBYTE',
	toPrecision: number,
	locale: 'de' | 'en'
) => {
	let factor;
	let toFactor;
	let unit;

	switch (fromUnit) {
		case 'BYTE':
			factor = 1;
			break;
		case 'KILOBYTE':
			factor = 1 / 1024;
			break;
		case 'MEGABYTE':
			factor = 1 / 1024 / 1024;
			break;
	}

	const byteValue = amount * factor;

	switch (targetUnit) {
		case 'KILOBYTE':
			toFactor = 1024;
			unit = 'KB';
			break;
		case 'MEGABYTE':
			toFactor = 1024 * 1024;
			unit = 'MB';
			break;
		case 'GIGABYTE':
			toFactor = 1024 * 1024 * 1024;
			unit = 'GB';
			break;
	}

	return `${parseFloat((byteValue / toFactor).toPrecision(toPrecision)).toLocaleString(
		locale
	)} ${unit}`;
};
