import { createSlice } from '@reduxjs/toolkit';
import { SmsState } from './types';
import { createSms, deleteSms, fetchSms } from './actions';

const initialState: SmsState = {
	items: [],
	fetchingForUser: [],
	fetchedForUser: [],
};

const slice = createSlice({
	name: 'sms',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchSms.pending, (state, { meta }) => ({
			...state,
			fetchingForUser: [
				...state.fetchingForUser.filter(item => item !== meta.arg.userId),
				meta.arg.userId,
			],
		}));
		builder.addCase(fetchSms.fulfilled, (state, { meta, payload }) => {
			const newItems = [
				...state.items.filter(sms => sms.owner !== meta.arg.userId),
				...payload.items.map(sms => {
					return {
						...sms,
						owner: meta.arg.userId,
					};
				}),
			];

			return {
				...state,
				fetchingForUser: state.fetchingForUser.filter(item => item !== meta.arg.userId),
				fetchedForUser: [
					...state.fetchedForUser.filter(item => item !== meta.arg.userId),
					meta.arg.userId,
				],
				items: newItems,
			};
		});

		builder.addCase(createSms.fulfilled, (state, { meta, payload }) => ({
			...state,
			items: [...state.items, { ...payload, owner: meta.arg }],
		}));

		builder.addCase(deleteSms.fulfilled, (state, { meta }) => ({
			...state,
			items: state.items.filter(item => item.id !== meta.arg.smsId),
		}));
	},
});

export const reducer = slice.reducer;
