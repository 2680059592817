const isProduction = () => (process.env.NODE_ENV ? process.env.NODE_ENV === 'production' : true);
const WAS_NQ_HEADER = 'X-Sipgate-Was-NQ-Request';

class NqNupsiClient {
	public async isNq(): Promise<boolean> {
		let url = 'https://api.dev.sipgate.com/v2';
		if (isProduction()) {
			url = 'https://api.sipgate.com/v2';
		}

		const response = await fetch(url);
		return response.headers.get(WAS_NQ_HEADER) === 'true';
	}
}

export default new NqNupsiClient();
