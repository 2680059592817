import validator from 'card-validator';
import { ManagedFieldValidator } from '../ManagedForm';
import { renderError } from './translate';
import { Translate } from './types';

const testPrefixes = ['90', '91'];

export const validateCreditCard =
	(
		translate: Translate | string,
		{ allowTestNumbers } = { allowTestNumbers: false }
	): ManagedFieldValidator<string, string> =>
	(cardnumber: string) => {
		const stripped = cardnumber.replace(/\D/g, '');
		const result = validator.number(stripped);

		if (allowTestNumbers && testPrefixes.some(p => stripped.startsWith(p))) {
			return {
				valid: true,
				value: stripped,
			};
		}

		if (result.isValid) {
			return {
				valid: true,
				value: stripped,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_CREDIT_CARD_INVALID'),
		};
	};
