type ErrorHandler = (e: Error) => void;
type ValueHandler<T> = (value: T | undefined) => void;

export class AsyncValue<T> {
	private errorHandlers: ErrorHandler[] = [];

	private valueHandlers: ValueHandler<T>[] = [];

	private promise!: Promise<T>;

	private resolve!: (value: Promise<T> | T) => void;

	public pending = false;

	public constructor() {
		this.clear();
	}

	public clear() {
		if (this.pending) {
			return;
		}

		this.pending = true;
		this.valueHandlers.forEach(handler => handler(undefined));

		this.promise = new Promise(resolve => {
			this.resolve = (value: Promise<T> | T) => {
				this.pending = false;
				resolve(value);
			};
		});

		this.promise.catch(err => {
			this.errorHandlers.forEach(handler => handler(err));
		});

		this.promise.then(value => {
			this.valueHandlers.forEach(handler => handler(value));
		});
	}

	public get() {
		return this.promise;
	}

	public set(value: T) {
		this.clear();
		this.resolve(value);
	}

	public fail(error: Error) {
		this.clear();
		this.resolve(Promise.reject(error));
	}

	public onError(handler: ErrorHandler) {
		this.errorHandlers.push(handler);
	}

	public onValue(handler: ValueHandler<T>) {
		this.valueHandlers.push(handler);
	}
}
