import 'abortcontroller-polyfill';

if (!window.AudioContext && window.webkitAudioContext) {
	window.AudioContext = window.webkitAudioContext;
}

if (window.AnalyserNode && !window.AnalyserNode.prototype.getFloatTimeDomainData) {
	window.AnalyserNode.prototype.getFloatTimeDomainData = function getFloatTimeDomainData(
		array: Float32Array
	) {
		const uint8 = new Uint8Array(2048);
		this.getByteTimeDomainData(uint8);

		for (const i in array) {
			array[i] = (uint8[i] - 128) * 0.0078125; // eslint-disable-line no-param-reassign
		}
	};
}

// Neccessary for vfile < 5 which is a react-markdown dependency...
if (typeof process === 'undefined' || !process) {
	window.process = {} as NodeJS.Process;
}

if (typeof process.cwd !== 'function') {
	process.cwd = () => '/';
}
