import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import classes from './AccountVerificationButton.scss';
import { WithDialogProps, withDialogs } from '../routes/paths/dialogs';
import { connect, ReduxProps } from '../redux';
import { ReduxState } from '../redux/types';
import { TranslateProps, withTranslate } from '../helpers/withTranslations';

interface ExternalProps {
	accountIsVerified: boolean;
}

const mapStateToProps = (state: ReduxState) => ({
	events: state.events,
});

type Props = ExternalProps &
	WithDialogProps &
	RouteComponentProps &
	TranslateProps &
	ReduxProps<typeof mapStateToProps>;

class AccountVerificationButton extends React.Component<Props> {
	private handleClick = () => {
		if (this.props.events.fetched) {
			const maybeBusinessVerificationEvent = this.props.events.items.find(
				i => i.eventName === 'BUSINESSVERIFICATIONREQUIREDCTA'
			);
			if (maybeBusinessVerificationEvent !== undefined) {
				this.props.history.push(
					maybeBusinessVerificationEvent.payload.ctaPath.replace(
						'%BASE%',
						this.props.location.pathname
					)
				);
			} else {
				this.props.dialogs.verifyAccount.open();
			}
		}
	};

	public render() {
		if (!this.props.events.fetched) {
			return <></>;
		}

		const isWaitingForBusinessVerificationReview = !!this.props.events.items.find(
			i => i.eventName === 'BUSINESSVERIFICATIONPENDINGCTA'
		);

		const showVerifyAccountItem =
			!this.props.accountIsVerified && !isWaitingForBusinessVerificationReview;

		return showVerifyAccountItem ? (
			<div className={classes.startCodeButtonContainer}>
				<button type="button" className={classes.startCodeButton} onClick={this.handleClick}>
					<p className={classes.enterCodeText}>{this.props.translate('VERIFY_ACCOUNT_BUTTON')}</p>
					<p className={classes.completeVerificationText}>
						{this.props.translate('VERIFY_ACCOUNT_SUBLINE')}
					</p>
				</button>
			</div>
		) : null;
	}
}

export default withDialogs(
	withTranslate(connect(mapStateToProps)(withRouter(AccountVerificationButton)))
);
