import { handleActions } from '../..';

import * as actions from './actions';
import { setTariffAnnouncement } from '../devices';
import { MobileDeviceTariffAnnouncementState } from './types';

const initialState: MobileDeviceTariffAnnouncementState = {
	items: [],
	fetchedForDevice: [],
	fetchingForDevice: [],
	updatingForDevice: [],
};

export default handleActions<
	MobileDeviceTariffAnnouncementState,
	PossibleActions<typeof actions | typeof setTariffAnnouncement>
>(
	{
		DEVICE_TARIFF_ANNOUNCEMENT_FETCH_PENDING: (state, action) => ({
			...state,
			fetchingForDevice: [...state.fetchingForDevice, action.data.deviceId],
		}),

		DEVICE_TARIFF_ANNOUNCEMENT_FETCH_SUCCESS: (state, { data, payload }) => ({
			...state,

			items: [
				...state.items.filter(item => item.deviceId !== data.deviceId),
				{ deviceId: data.deviceId, enabled: payload.enabled },
			],

			fetchingForDevice: state.fetchingForDevice.filter(device => device !== data.deviceId),

			fetchedForDevice: [...state.fetchedForDevice, data.deviceId],
		}),

		DEVICE_TARIFF_ANNOUNCEMENT_SET_PENDING: (state, { data }) => ({
			...state,

			items: state.items.map(item => {
				if (item.deviceId !== data.deviceId) {
					return item;
				}

				return {
					...item,
					enabled: data.enabled,
				};
			}),

			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_TARIFF_ANNOUNCEMENT_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),
	},

	initialState
);
