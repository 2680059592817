import React from 'react';
import { useResizeObserver } from './useResizeObserver';

const BREAKPOINT = 700;

export function useResponsiveBreakpoints() {
	const [isSmall, setIsSmall] = React.useState(false);

	const ref = useResizeObserver(entry => {
		setIsSmall(entry.contentRect.width < BREAKPOINT);
	});

	return { ref, isSmall };
}
