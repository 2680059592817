import { handleActions } from '../..';
import * as actions from './actions';
import { SsoConfigurationState } from './types';

const initialState: SsoConfigurationState = {
	item: null,
	fetched: false,
	fetching: false,
};

type Actions = typeof actions;

export default handleActions<SsoConfigurationState, PossibleActions<Actions>>(
	{
		SSOCONFIGURATION_FETCH_PENDING: state => ({ ...state, fetching: true }),
		SSOCONFIGURATION_FETCH_FAILURE: state => ({ ...state, fetching: false }),
		SSOCONFIGURATION_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetched: true,
				fetching: false,
				item: payload,
			};
		},
		SSOCONFIGURATION_CREATE_PENDING: state => ({ ...state, fetching: true }),
		SSOCONFIGURATION_CREATE_FAILURE: state => ({ ...state, fetching: false }),
		SSOCONFIGURATION_CREATE_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetched: true,
				fetching: false,
				item: payload,
			};
		},

		SSOCONFIGURATION_UPDATE_PENDING: state => ({ ...state, fetching: true }),
		SSOCONFIGURATION_UPDATE_FAILURE: state => ({ ...state, fetching: false }),
		SSOCONFIGURATION_UPDATE_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetched: true,
				fetching: false,
				item: payload,
			};
		},
	},
	initialState
);
