import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';
import { paymentApi } from '../../../api';
import { MoneyAmount } from '../../../components/bookables/prices/util';
import { fetchOpenPositions } from '../payment';

const shouldFetchBalance = (state: ReduxState) => !state.balance.fetching && !state.balance.fetched;

export const fetchBalance = createAsyncThunk(
	'balance/fetch',
	async (_forceFetch?: boolean) => paymentApi.getBalance(),
	{
		condition: (forceFetch, { getState }) => {
			if (forceFetch === true) {
				return true;
			}
			return shouldFetchBalance(getState());
		},
	}
);

export const topupBalance = createAsyncThunk(
	'balance/create',
	async ({ paymentId, amount }: { paymentId: string; amount: MoneyAmount }, { dispatch }) => {
		const res = await paymentApi.topupBalance(paymentId, (amount.amount / amount.fraction) * 100);
		dispatch(fetchOpenPositions({ force: true }));
		return res;
	}
);
