import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, ReduxProps } from '../redux';

import auth from '../utils/authenticate/auth';
import { fetchLinks, LinksState } from '../redux/slices/links';

const mapStateToProps = ({ links }: { links: LinksState }) => ({ links });

const mapDispatchToProps = {
	fetchLinks,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & RouteComponentProps;

class WbciOptInRedirect extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchLinks();

		if (this.props.links.fetched) {
			this.redirect();
		}
	}

	public componentDidUpdate() {
		if (this.props.links.fetched) {
			this.redirect();
		}
	}

	public async redirect() {
		/* [0] accesses unnamed rest paramater (splat) */
		const wbciOptInHash = this.props.match.params[0];
		const { wbciOptInUrl, legacyTokenAuthenticateUrl } = this.props.links.items;

		const token = await auth.getToken();
		const encodedRedirectUrl = encodeURIComponent(`${wbciOptInUrl}/${wbciOptInHash}`);

		window.location.href = `${legacyTokenAuthenticateUrl}${token.access}&redirect=${encodedRedirectUrl}`;
	}

	public render() {
		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WbciOptInRedirect);
