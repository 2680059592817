import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';
import { AcdStatisticsState } from './types';

const hasNotYetFetched = (state: AcdStatisticsState, acdId: string) => {
	return (
		!state.fetchedForAcdStatistics.includes(acdId) &&
		!state.fetchingForAcdStatistics.includes(acdId)
	);
};

export const fetchAcdLiveStatistics = createAsyncThunk(
	'acdStatistics/fetch',
	async (data: { acdId: string; force?: boolean; onlyWhenAlreadyFetched?: boolean }) =>
		api.getAcdLiveStatistics(data.acdId),
	{
		condition: (arg, { getState }) =>
			(!arg.onlyWhenAlreadyFetched &&
				(arg.force || hasNotYetFetched(getState().acdStatistics, arg.acdId))) ||
			(arg.onlyWhenAlreadyFetched && !hasNotYetFetched(getState().acdStatistics, arg.acdId)),
	}
);

export const setAvailabilityTarget = createAsyncThunk(
	'acdStatistics/setAvailabilityTarget',
	async (data: { acdId: string; target: number }) =>
		api.setAcdStatisticsAvailabilityTarget(data.acdId, data.target)
);

export const setCallPercentageInTimeLimitTarget = createAsyncThunk(
	'acdStatistics/setCallPercentageInTimeLimitTarget',
	async (data: { acdId: string; timeLimit: number; target: number }) =>
		api.setAcdStatisticsCallPercentageInTimeLimitTarget(data.acdId, data.timeLimit, data.target)
);

export const setAverageHandlingTimeTarget = createAsyncThunk(
	'acdStatistics/setAverageHandlingTimeTarget',
	async (data: { acdId: string; target: number }) =>
		api.setAcdStatisticsAverageHandlingTimeTarget(data.acdId, data.target)
);

export const toggleAgentStatistics = createAsyncThunk(
	'acdStatistics/toggleAgentStatistics',
	async (data: { acdId: string; enabled: boolean }) =>
		api.toggleAgentStatistics(data.acdId, data.enabled)
);
