import { State } from '../state';
import { ChangePageSizeAction } from './types';

export const changePageSizeReducer = (state: State, action: ChangePageSizeAction): State => ({
	...state,
	entries: {
		abortController: state.entries.abortController,
		error: false,
		tooManyEvents: state.entries.tooManyEvents,
		result: null,
	},
	pageSize: action.size,
});
