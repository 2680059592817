import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchDataAutomationHistory = (state: ReduxState, containerId: string) =>
	!state.dataAutomationHistory.fetchingForContainer.includes(containerId) &&
	!state.dataAutomationHistory.fetchedForContainer.includes(containerId);

export const fetchDataAutomationHistory = createAction(
	'DATA_AUTOMATION_HISTORY_FETCH',
	(containerId: string, force?: boolean) => ({
		promise: () => api.getDataAutomationHistory(containerId),
		shouldFetch: (state: ReduxState) =>
			force || shouldFetchDataAutomationHistory(state, containerId),
		data: { containerId },
	})
);
