import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';
import { SsoConfigurationCreateReduxAction, SsoConfigurationUpdateReduxAction } from './types';

export const fetchSsoConfiguration = createAction('SSOCONFIGURATION_FETCH', (force?: boolean) => ({
	promise: () => api.fetchSsoConfiguration(),
	shouldFetch: (state: ReduxState) =>
		(!state.ssoConfiguration.fetched && !state.ssoConfiguration.fetching) || force,
}));

export const createSsoConfiguration = createAction(
	'SSOCONFIGURATION_CREATE',
	(ssoConfiguration: SsoConfigurationCreateReduxAction) => ({
		promise: () => api.createSsoConfiguration(ssoConfiguration),
	})
);

export const updateSsoConfiguration = createAction(
	'SSOCONFIGURATION_UPDATE',
	(ssoConfiguration: SsoConfigurationUpdateReduxAction) => ({
		promise: () => api.updateSsoConfiguration(ssoConfiguration),
	})
);
