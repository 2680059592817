import { RefObject, useEffect } from 'react';

const useOutsideClick = (ref: RefObject<HTMLElement>, callback: () => void, listen: boolean) => {
	useEffect(() => {
		const onClick = (event: MouseEvent | TouchEvent) => {
			if (!ref.current!.contains(event.target as HTMLElement)) {
				callback();
			}
		};

		if (listen) {
			document.addEventListener('mousedown', onClick);
			document.addEventListener('touchstart', onClick);
		} else {
			document.removeEventListener('mousedown', onClick);
			document.removeEventListener('touchstart', onClick);
		}

		return () => {
			document.removeEventListener('mousedown', onClick);
			document.removeEventListener('touchstart', onClick);
		};
	}, [listen, ref, callback]);
};

export default useOutsideClick;
