import { DateTime } from 'luxon';
import * as actions from './actions';
import { handleActions } from '../..';
import { CallRecordingAudioFileState } from './types';

const initialState: CallRecordingAudioFileState = {
	items: [],
	fetched: false,
	fetching: false,
	creatingAudioFile: [],
};

export default handleActions<CallRecordingAudioFileState, PossibleActions<typeof actions>>(
	{
		CALLRECORDING_AUDIOFILES_FETCH: state => ({ ...state, fetching: true }),

		CALLRECORDING_AUDIOFILES_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetched: true,
				fetching: false,
				items: [
					...payload.items.map(item => ({
						...item,
						url: state.items.find(stateItem => stateItem.id === item.id)?.url ?? {
							state: 'UNFETCHED' as const,
						},
					})),
				],
			};
		},

		CALLRECORDING_AUDIOFILE_FETCH_CONTENT_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'FETCHING' as const },
					};
				}),
			};
		},

		CALLRECORDING_AUDIOFILE_FETCH_CONTENT_SUCCESS: (state, { payload, data }) => {
			return {
				...state,
				fetching: false,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'FETCHED' as const, url: URL.createObjectURL(payload) },
					};
				}),
			};
		},

		CALLRECORDING_AUDIOFILE_FETCH_CONTENT_FAILURE: (state, { data }) => {
			return {
				...state,
				fetching: false,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'ERROR' as const, timestamp: DateTime.now() },
					};
				}),
			};
		},

		CALLRECORDING_AUDIOFILE_SET_ALIAS_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						alias: data.alias,
					};
				}),
			};
		},

		CALLRECORDING_AUDIOFILE_DELETE_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.filter(audioFile => audioFile.id !== data.audioFileId),
			};
		},

		CALLRECORDING_AUDIOFILE_CREATE_PENDING: state => ({
			...state,
			creatingAudioFile: [...state.creatingAudioFile],
		}),

		CALLRECORDING_AUDIOFILE_CREATE_SUCCESS: (state, { payload }) => ({
			...state,

			creatingAudioFile: state.creatingAudioFile,

			items: [
				...state.items.filter(item => item.id !== payload.id),
				{
					id: payload.id,
					alias: payload.alias,
					isActive: false,
					url: { state: 'UNFETCHED' as const },
					isInitial: false,
				},
			],
		}),

		CALLRECORDING_AUDIOFILE_SET_ACTIVE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(audioFile => {
				if (audioFile.id !== data.activeAudioFileId) {
					return {
						...audioFile,
						isActive: false,
					};
				}

				return {
					...audioFile,
					isActive: true,
				};
			}),
		}),
	},
	initialState
);
