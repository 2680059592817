import { TwoFactorAuthenticationSettingsState } from '.';

export const isTwoFactorEnabled = (
	webuserId: string,
	state: TwoFactorAuthenticationSettingsState
): boolean => {
	const twoFactorSetting = state.items.find(setting => setting.webuserId === webuserId);
	if (twoFactorSetting && twoFactorSetting.otpEnabled) {
		return true;
	}
	return false;
};

export const isTwoFactorFetching = (
	webuserId: string,
	state: TwoFactorAuthenticationSettingsState
): boolean => {
	return state.fetching.includes(webuserId);
};
