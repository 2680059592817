import { useEffect } from 'react';
import { SliceData, useAction } from '../..';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	completeCreditCardVerification,
	createSepaPaymentMethod,
	deletePaymentMethod,
	fetchAutoCreditingSettings,
	fetchOpenPositions,
	fetchPaymentMethods,
	setAutoCreditingSettings,
} from './actions';
import { AutoCreditingSettings, PaymentMethod } from './types';
import { MoneyAmount } from '../../../components/bookables/prices/util';

export const usePaymentMethods = (): SliceData<PaymentMethod[]> => {
	const dispatch = useDispatch();
	const payment = useSelector(state => state.payment);

	useEffect(() => {
		dispatch(fetchPaymentMethods());
	}, [dispatch]);

	return payment.fetchedMethods
		? {
				data: payment.paymentMethods,
				fetched: true,
			}
		: {
				data: null,
				fetched: false,
			};
};

export const useAutoCreditingSettings = (): SliceData<AutoCreditingSettings | null> => {
	const dispatch = useDispatch();
	const payment = useSelector(state => state.payment);

	useEffect(() => {
		dispatch(fetchAutoCreditingSettings());
	}, [dispatch]);

	return payment.fetchedAutoCrediting
		? {
				data: payment.autoCrediting,
				fetched: true,
			}
		: {
				data: null,
				fetched: false,
			};
};

export const useOpenPositions = (): SliceData<MoneyAmount | null> => {
	const dispatch = useDispatch();
	const payment = useSelector(state => state.payment);

	useEffect(() => {
		dispatch(fetchOpenPositions());
	}, [dispatch]);

	return payment.fetchedOpenPositions
		? {
				data: payment.openPositions,
				fetched: true,
			}
		: {
				data: null,
				fetched: false,
			};
};

export const usePaymentActions = () => ({
	createSepaPaymentMethod: useAction(createSepaPaymentMethod),
	setAutoCreditingSettings: useAction(setAutoCreditingSettings),
	completeCreditCardVerification: useAction(completeCreditCardVerification),
	deletePaymentMethod: useAction(deletePaymentMethod),
});
