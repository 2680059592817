import { Store } from '@reduxjs/toolkit';
import { ChangeActiveAudioPlayerAction } from './types';
import { State } from '../state';

export const changeActiveAudioPlayer =
	(store: Store<State>) => (eventId: string, playerId: string, playing: boolean) => {
		store.dispatch<ChangeActiveAudioPlayerAction>({
			type: 'CHANGE_ACTIVE_AUDIOPLAYER',
			eventId,
			playerId,
			playing,
		});
	};
