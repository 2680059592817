import sipgateStatusClient from '../../../api/client/SipgateStatusClient';
import { createAsyncThunk } from '../../utils/wrapper';

export const forceFetchSipgateStatus = createAsyncThunk(
	'sipgateStatus/forceFetch',
	() => sipgateStatusClient.getStatus(),
	{
		condition: (_, { getState }) => !getState().sipgateStatus.fetching,
	}
);

export const fetchSipgateStatus = createAsyncThunk(
	'sipgateStatus/fetch',
	async (_, { dispatch }) => {
		await dispatch(forceFetchSipgateStatus());
	},
	{
		condition: (_, { getState }) => {
			const state = getState().sipgateStatus;

			return !state.fetching && !state.fetched;
		},
	}
);
