import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';

export const fetchHolidayPresets = createAsyncThunk(
	'holidayPresets/fetch',
	async () => api.getHolidayPresets(),
	{
		condition: (_arg, { getState }) =>
			!getState().holidayPresets.fetched && !getState().holidayPresets.fetching,
	}
);
