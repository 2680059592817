import { createAction } from '../../..';
import { SipgateDomain } from '../../userinfo';

export const setPhoneNumber = createAction(
	'WEBPHONE_PHONE_NUMBERS_SET',
	(phoneNumber: string, domain: SipgateDomain) => ({
		phoneNumber,
		domain,
	})
);

export const setTransferPhoneNumber = createAction(
	'WEBPHONE_PHONE_NUMBERS_TRANSFER_SET',
	(transferPhoneNumber: string, domain: SipgateDomain) => ({
		transferPhoneNumber,
		domain,
	})
);
