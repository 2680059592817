import { useEffect } from 'react';
import { SliceData, useAction } from '../..';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { useUserInfo } from '../userinfo';
import {
	fetchTrunkContracts,
	cancelTrunkContract,
	changeTrunkContract,
	createTrunkContract,
	forceFetchTrunkContracts,
	revokeTrunkContractCancellation,
} from './actions';
import { TrunkContract } from './types';

export const useTrunkContracts = (): SliceData<TrunkContract[]> => {
	const dispatch = useDispatch();
	const userinfo = useUserInfo();
	const trunkContracts = useSelector(s => s.trunkContracts);

	useEffect(() => {
		dispatch(fetchTrunkContracts(userinfo.domain));
	}, [dispatch, userinfo.domain]);

	if (!trunkContracts.fetched) {
		return {
			fetched: false,
			data: null,
		};
	}

	return {
		fetched: true,
		data: trunkContracts.items,
	};
};

export const useTrunkContractActions = () => ({
	forceFetchTrunkContracts: useAction(forceFetchTrunkContracts),
	changeTrunkContract: useAction(changeTrunkContract),
	createTrunkContract: useAction(createTrunkContract),
	revokeTrunkContractCancellation: useAction(revokeTrunkContractCancellation),
	cancelTrunkContract: useAction(cancelTrunkContract),
});
