import React from 'react';
import { DropdownOpener } from './DropdownOpener';
import { Dropdown, DropdownPosition, DropdownSize } from './Dropdown';

export type VerticalOpeningPosition = 'Top' | 'Bottom';
export type HorizontalOpeningPosition = 'Left' | 'Center' | 'Right';

interface Props {
	openerId?: string;
	verticalOpeningPosition: VerticalOpeningPosition;
	open: boolean;
	opener:
		| ((open: boolean) => React.ReactElement<{
				ref: React.Ref<HTMLElement>;
				onClick: React.MouseEventHandler;
				id: string;
		  }>)
		| React.ReactElement<{
				ref: React.Ref<HTMLElement>;
				onClick: React.MouseEventHandler;
				id: string;
		  }>;
	onOpen: (focus: 'first' | 'last') => void;
	onClose: () => void;
	dialogCount: number;
	horizontalOpeningPosition: 'Left' | 'Right';
	preventBackgroundInteraction?: boolean;
	children: React.ReactNode;
}

interface State {
	position: DropdownPosition;
}

/**
 * A combination of e.g. a button and a dropdown.
 * Opens the dropdown based on the openers position.
 */
export class DropdownWithOpener extends React.PureComponent<Props, State> {
	public state: State = {
		position: {
			top: 0,
			right: 0,
		},
	};

	private openerRef = React.createRef<DropdownOpener>();

	private dropdownRef = React.createRef<Dropdown>();

	public static defaultProps = {
		horizontalOpeningPosition: 'Right',
		verticalOpeningPosition: 'Bottom',
		dialogCount: 0,
	};

	public componentDidUpdate(prevProps: Props) {
		if (this.props.open && !prevProps.open) {
			if (!this.openerRef.current || !this.dropdownRef.current) {
				return;
			}

			const size = this.dropdownRef.current.calculateSize();
			const position = this.openerRef.current.calculateDropdownPosition();

			if (position) {
				this.setState({ position: this.adjustPosition(position, size) });
			}
		}

		if (!this.props.open && prevProps.open) {
			this.openerRef.current?.focus();
		}
	}

	private adjustPosition(position: DropdownPosition, size: DropdownSize | null) {
		const adjustedPosition = {
			...position,
		};

		if ('top' in adjustedPosition) {
			adjustedPosition.top += 4;
		} else if ('bottom' in adjustedPosition) {
			adjustedPosition.bottom -= 4;
		}

		return this.shiftIntoViewPort(adjustedPosition, size);
	}

	private shiftIntoViewPort(position: DropdownPosition, size: DropdownSize | null) {
		if (!size || size.height + 8 >= window.innerHeight || size.width + 8 >= window.innerWidth) {
			return position;
		}

		const shiftedPosition: DropdownPosition = {
			...position,
		};

		if ('top' in shiftedPosition) {
			if (shiftedPosition.top < 8) {
				shiftedPosition.top = 8;
			}

			if (shiftedPosition.top + size.height - 8 > window.innerHeight) {
				shiftedPosition.top = window.innerHeight - size.height - 8;
			}
		}

		if ('left' in shiftedPosition) {
			if (shiftedPosition.left < 8) {
				shiftedPosition.left = 8;
			}

			if (shiftedPosition.left + size.width - 8 > window.innerWidth) {
				shiftedPosition.left = window.innerWidth - size.width - 8;
			}
		}

		if ('bottom' in shiftedPosition) {
			if (shiftedPosition.bottom < size.height + 8) {
				shiftedPosition.bottom = size.height + 8;
			}

			if (shiftedPosition.bottom - 8 > window.innerHeight) {
				shiftedPosition.bottom = window.innerHeight - 8;
			}
		}

		if ('right' in shiftedPosition) {
			if (shiftedPosition.right < size.width + 8) {
				shiftedPosition.right = size.width + 8;
			}

			if (shiftedPosition.right - 8 > window.innerWidth) {
				shiftedPosition.right = window.innerWidth - 8;
			}
		}

		return shiftedPosition;
	}

	public render() {
		const opener =
			typeof this.props.opener === 'function'
				? this.props.opener(this.props.open)
				: this.props.opener;

		return (
			<>
				<DropdownOpener
					id={this.props.openerId}
					ref={this.openerRef}
					verticalOpeningPosition={this.props.verticalOpeningPosition}
					horizontalOpeningPosition={this.props.horizontalOpeningPosition}
					opened={this.props.open}
					onOpen={this.props.onOpen}
					onClose={this.props.onClose}
				>
					{opener}
				</DropdownOpener>
				<Dropdown
					ref={this.dropdownRef}
					dialogCount={this.props.dialogCount}
					open={this.props.open}
					onClose={this.props.onClose}
					position={this.state.position}
					preventBackgroundInteraction={this.props.preventBackgroundInteraction}
				>
					{this.props.children}
				</Dropdown>
			</>
		);
	}
}
