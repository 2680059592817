import { createSlice } from '@reduxjs/toolkit';
import { LinksState } from './types';
import { fetchLinks } from './actions';

export const initialState: LinksState = {
	fetching: false,
	fetched: false,
	items: {
		accountsUrl: '',
		accountSettingsUrl: '',
		accountTopUpUrl: '',
		adminProductSettingsUrl: '',
		affiliateUrl: '',
		bookPhonenumberUrl: '',
		brandUrl: '',
		callPackageTacUrl: '',
		dataContingentTacUrl: '',
		devicePoolUrl: '',
		helpdeskPhoneConfigurationUrl: '',
		helpdeskUrl: '',
		legacyTokenAuthenticateUrl: '',
		loginUrl: '',
		logoTargetUrl: '',
		logoutPageUrl: '',
		logoutUrl: '',
		microsoftTeamsUrl: '',
		crmIntegrationsUrl: '',
		nonEWRHelpcenterUrl: '',
		notificationsUrl: '',
		paymentSettingsUrl: '',
		personalSettingsUrl: '',
		presenceContainerUrl: '',
		sendFaxUrl: '',
		startUpPageUrl: '',
		supportUrl: '',
		testProgrammeUrl: '',
		tokenAuthenticateUrl: '',
		trunkingContingentAdvertisementUrl: '',
		userSettingsUrl: '',
		wbciOptInUrl: '',
		whatsNewUrl: '',
		googleSignInUrl: '',
		trunkContingentTacUrl: '',
		trunkContractTacUrl: '',
		callRecorderTacUrl: '',
		microsoftSignInUrl: '',
		mobileDataVisualizationUrl: '',
		appleSignInUrl: '',
	},
};

const slice = createSlice({
	name: 'links',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchLinks.pending, state => ({ ...state, fetching: true }));
		builder.addCase(fetchLinks.fulfilled, (state, { payload }) => ({
			...state,
			fetching: false,
			fetched: true,
			items: payload,
		}));
	},
});

export const initialLinkState = { ...initialState };

export const reducer = slice.reducer;
