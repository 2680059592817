import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchProductTacs = (state: ReduxState, productId: string) =>
	!state.productsTacs.fetchedForProducts.includes(productId) &&
	!state.productsTacs.fetchingForProducts.includes(productId);

export const fetchProductTacs = createAction('PRODUCTS_TACS_FETCH', (productId: string) => ({
	promise: api.getTacsForProduct(productId),
	shouldFetch: (state: ReduxState) => shouldFetchProductTacs(state, productId),
	data: { productId },
}));
