import { createAction } from '../../..';
import api from '../../../../api';
import { ReduxState } from '../../../types';
import { User } from '../../users';

const shouldFetch = (state: ReduxState) =>
	!state.callRestrictions.fetched && !state.callRestrictions.fetching;

export const fetchUserCallRestrictions = createAction('CALLRESTRICTIONS_FETCH', () => ({
	promise: () => api.getCallRestrictions(),
	shouldFetch,
}));

export const forceFetchUserCallRestrictions = createAction('CALLRESTRICTIONS_FETCH', () => {
	return {
		promise: () => api.getCallRestrictions(),
	};
});

export const fetchUserCallRestrictionsForUser = createAction(
	'CALLRESTRICTIONS_FETCH',
	(user: User) => ({
		promise: () => api.getCallRestrictions(),
		shouldFetch: (state: ReduxState) =>
			shouldFetch(state) ||
			!state.callRestrictions.items.some(restriction => restriction.userId === user.id),
	})
);

export const setUserCallRestrictions = createAction(
	'CALLRESTRICTIONS_SET',
	(userId: string, restriction: string, enabled: boolean) => ({
		promise: () => api.setCallRestrictions(userId, restriction, enabled),
		data: { userId, restriction, enabled },
	})
);
