import React from 'react';
import { UsState } from '../../api/types/addresses';
import { Address as AddressType } from '../../redux/modules/addresses';
import { useTranslate } from '../../redux/slices/translations';
import { translateCountry } from '../../views/locations/util';

const usStateNames = {
	AL: 'Alabama',
	AK: 'Alaska',
	AZ: 'Arizona',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	DC: 'District of Columbia',
	FL: 'Florida',
	GA: 'Georgia',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PA: 'Pennsylvania',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
};

export function formatUsState(state: UsState) {
	return usStateNames[state];
}

export function formatAddressState(address: AddressType) {
	if (address.countryCode === 'US' && address.state) {
		return formatUsState(address.state);
	}

	if ('state' in address && address.state) {
		return address.state;
	}

	return null;
}

export function formatAddressLine1(address: AddressType) {
	if ('address' in address) {
		return address.address;
	}

	return `${address.street} ${address.number}`;
}

export function formatAddressCity(address: AddressType) {
	if (address.zip) {
		return `${address.zip} ${address.city}`;
	}

	return address.city;
}

export function formatAddress(address: AddressType) {
	const state = formatAddressState(address);

	return `${formatAddressLine1(address)}, ${formatAddressCity(address)}${
		state ? `, ${state}` : ''
	}`;
}

export const Address = ({ address }: { address: AddressType }) => {
	const translate = useTranslate();
	const state = formatAddressState(address);

	return (
		<>
			{formatAddressLine1(address)}
			<br />
			{address.additional && (
				<>
					{address.additional}
					<br />
				</>
			)}
			{formatAddressCity(address)}
			<br />
			{state ? (
				<>
					{state}
					<br />
				</>
			) : null}
			{translateCountry(translate, address.countryCode)}
		</>
	);
};
