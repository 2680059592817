import { useEffect, useMemo, useState } from 'react';
import { SliceData, useAction } from '../../index';
import { BaseContract, BaseContractCostSummary, BaseContractOption } from './types';
import api from '../../../api';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	bookBaseContract,
	cancelBaseContract,
	changeBaseContract,
	fetchBaseContract,
	revokeBaseContractCancellation,
	revokeBaseContractChange,
} from './action';
import { MoneyAmount } from '../../../components/bookables/prices/util';

// data === null means there is no base contract. The customer went out of preview phase without booking a full contract
type BaseContractSliceData<T> = { data: null; fetched: false } | { data: T | null; fetched: true };

export const useBaseContract = (): BaseContractSliceData<BaseContract> => {
	const state = useSelector(s => s.baseContract);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchBaseContract());
	}, [dispatch]);

	if (!state.fetched) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: state.data,
		fetched: state.fetched,
	};
};

export const useBaseContractOptions = (): SliceData<BaseContractOption[]> => {
	const [options, setOptions] = useState<BaseContractOption[] | null>(null);

	useEffect(() => {
		api.getBaseContractOptions().then(o => {
			setOptions(
				o.items.map(option => ({
					id: option.id,
					cost: {
						amount: {
							amount: option.pricePerSeat.amountInTenThousandths,
							fraction: 10_000,
							currency: option.pricePerSeat.currency,
						},
						interval: 'monthly',
						isNetto: option.pricePerSeat.net,
					},
				}))
			);
		});
	}, []);

	return useMemo(
		() =>
			options
				? {
						data: options,
						fetched: true,
					}
				: { data: null, fetched: false },
		[options]
	);
};

export const useBaseContractCostSummary = (
	optionId: string
): SliceData<BaseContractCostSummary> => {
	const [costSummary, setCostSummary] = useState<BaseContractCostSummary | null>(null);

	useEffect(() => {
		api
			.getBaseContractCostSummary(optionId)
			.then(res => {
				setCostSummary({
					optionId: res.optionId,
					totalPrice: {
						interval: 'monthly',
						isNetto: res.totalPrice.net,
						amount: {
							amount: res.totalPrice.amountInTenThousandths,
							fraction: 10_000,
							currency: res.totalPrice.currency,
						},
					},
					units: res.units.map(u => {
						return {
							name: u.name,
							amount: u.amount,
							pricePerUnit: {
								amount: {
									amount: Number(u.pricePerUnit.amountInTenThousandths),
									fraction: 10_000,
									currency: u.pricePerUnit.currency === 'EUR' ? 'EUR' : 'GBP',
								} as MoneyAmount,
								isNetto: u.pricePerUnit.net,
								interval: 'monthly',
							},
						};
					}),
				});
			})
			.catch(() => {
				setCostSummary(null);
			});
	}, [optionId]);

	return useMemo(
		() =>
			costSummary
				? {
						data: costSummary,
						fetched: true,
					}
				: { data: null, fetched: false },
		[costSummary]
	);
};

export const useBaseContractActions = () => ({
	bookBaseContract: useAction(bookBaseContract),
	changeBaseContract: useAction(changeBaseContract),
	revokeBaseContractChange: useAction(revokeBaseContractChange),
	cancelBaseContract: useAction(cancelBaseContract),
	revokeBaseContractCancellation: useAction(revokeBaseContractCancellation),
});
