import React from 'react';
import { Step } from 'react-joyride';
import { TourProps } from './types';
import JoyrideTour from './JoyrideTour';
import NavigationClasses from '../../accountNavigation/Navigation.scss';
import SipgateStatusClasses from '../../accountNavigation/components/SipgateStatus/SipgateStatusComponent.scss';
import MediaQueryDecorator, { MediaQueryProps } from '../../utils/MediaQueryDecorator';

export const TOUR_NAME = 'ADMIN_DASHBOARD_TOUR';

type Props = { showStatusStep: boolean } & TourProps & MediaQueryProps;

const AdminDashboardTour = (props: Props) => {
	if (!props.mediaQueries.isXLargeDevice()) {
		return <></>;
	}
	const steps: Step[] = [
		{
			target: 'body',
			title: `${props.translate('ADMIN_DASHBOARD_JOYRIDE_STEP_1_TITLE')} 🎉`,
			content: props.translate('ADMIN_DASHBOARD_JOYRIDE_STEP_1_CONTENT'),
			disableBeacon: true,
			placement: 'center',
		},
		{
			target: `.${NavigationClasses.toggleButton}`,
			title: props.translate('ADMIN_DASHBOARD_JOYRIDE_STEP_2_TITLE'),
			content: props.translate('ADMIN_DASHBOARD_JOYRIDE_STEP_2_CONTENT'),
			disableBeacon: false,
			placementBeacon: 'left',
			placement: 'right-start',
		},
	];

	if (props.showStatusStep) {
		steps.push({
			title: props.translate('ADMIN_DASHBOARD_JOYRIDE_STEP_3_TITLE'),
			content: props.translate('ADMIN_DASHBOARD_JOYRIDE_STEP_3_CONTENT'),
			target: `.${SipgateStatusClasses.statusButton}`,
			disableBeacon: true,
			placement: 'bottom-end',
		});
	}

	return <JoyrideTour onDone={props.onDone} steps={steps} translate={props.translate} />;
};

export default MediaQueryDecorator(AdminDashboardTour);
