import { Avatar, AvatarSize, AvatarSizeUrls, AvatarsState } from './types';

export const findAvatarForWebuserId = (
	state: AvatarsState,
	webuserId: string
): Avatar | undefined => {
	return state.items.find(avatar => avatar.webuserId === webuserId);
};

export const findAvatarForWebuserIdAndSize = (
	state: AvatarsState,
	webuserid: string,
	size: 'small' | 'medium' | 'large' | 'xlarge' | 'biggest'
): string | undefined => {
	const avatars = findAvatarForWebuserId(state, webuserid);
	if (avatars) {
		return avatars.urls[size.toUpperCase() as AvatarSize];
	}
	return undefined;
};

export const hasAvatarForWebuserId = (state: AvatarsState, webuserId: string): boolean => {
	return !!findAvatarForWebuserId(state, webuserId);
};

export const hasUploadedAvatar = (state: AvatarsState, webuserId: string): boolean => {
	const avatar = findAvatarForWebuserId(state, webuserId);
	return avatar ? avatar.group === 'UPLOADS' : false;
};

export const findAvatarForWebuserIdAndAllSizes = (
	state: AvatarsState,
	webuserid: string,
	size: 'small' | 'medium' | 'large' | 'xlarge' | 'biggest'
): AvatarSizeUrls | undefined => {
	const avatars = findAvatarForWebuserId(state, webuserid);
	if (avatars) {
		const map = new Map<AvatarSize, string>();
		Object.keys(avatars.urls)
			.filter(key => key.startsWith(size.toUpperCase()))
			.forEach(key => {
				map.set(key as AvatarSize, avatars.urls[key as AvatarSize]);
			});
		return Object.fromEntries(map) as AvatarSizeUrls;
	}

	return undefined;
};
