export type CSVMappingType = 'contacts' | 'batch-users';

export type CSVMappingParams = {
	filePseudo: string;
	textPseudo: string;
	type: CSVMappingType;
};

export type CSVMappingColumnOption = {
	labelLK: string;
	value: string;
};

export type CSVMappingColumnGroup = {
	labelLK: string;
	options: CSVMappingColumnOption[];
};

export type CSVColumnOptions = (CSVMappingColumnOption | CSVMappingColumnGroup)[];

const shortOptionType: CSVMappingColumnOption[] = [
	{
		labelLK: 'CSV_MAPPING_TYPE_BUSINESS',
		value: 'WORK',
	},
	{
		labelLK: 'CSV_MAPPING_TYPE_PRIVATE',
		value: 'HOME',
	},
	{
		labelLK: 'CSV_MAPPING_TYPE_OTHER',
		value: 'OTHER',
	},
];

const longOptionType: CSVMappingColumnOption[] = [
	{
		labelLK: 'CSV_MAPPING_TYPE_BUSINESS',
		value: 'WORK',
	},
	{
		labelLK: 'CSV_MAPPING_TYPE_PRIVATE',
		value: 'HOME',
	},
	{
		labelLK: 'CSV_MAPPING_TYPE_MOBILE',
		value: 'MOBILE',
	},
	{
		labelLK: 'CSV_MAPPING_TYPE_OTHER',
		value: 'OTHER',
	},
];

export const getCSVColumnMappingKindsByOptionAndType = (
	option: string,
	type: CSVMappingType
): CSVColumnOptions => {
	if (type !== 'contacts') {
		return [];
	}
	if (
		option === 'SG_STATE' ||
		option === 'SG_COUNTRY' ||
		option === 'SG_CITY' ||
		option === 'SG_STREET' ||
		option === 'SG_EXTENDED' ||
		option === 'SG_POBOX' ||
		option === 'SG_EMAIL' ||
		option === 'SG_FAX' ||
		option === 'SG_POSTAL' ||
		option === 'SG_SIPGATEADDRESS'
	) {
		return shortOptionType;
	}

	if (option === 'SG_PHONE') {
		return longOptionType;
	}

	return [];
};

export const getCSVColumnMappingOptionsByKind = (type: CSVMappingType): CSVColumnOptions => {
	if (type !== 'contacts') {
		return [];
	}

	return [
		{
			labelLK: 'CSV_MAPPING_COLUMN_GROUP_PERSONAL',
			options: [
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_PERSONAL_BIRTHDAY',
					value: 'SG_BIRTHDAY',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_PERSONAL_LASTNAME',
					value: 'SG_LASTNAME',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_PERSONAL_FIRSTNAME',
					value: 'SG_FIRSTNAME',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_PERSONAL_DISPLAY_NAME',
					value: 'SG_FULLNAME',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_PERSONAL_SUFFIX',
					value: 'SG_SUFFIX',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_PERSONAL_ADDITIONAL_FIRSTNAME',
					value: 'SG_ADDITIONAL',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_PERSONAL_GENDER',
					value: 'SG_GENDER',
				},
			],
		},
		{
			labelLK: 'CSV_MAPPING_COLUMN_GROUP_COMPANY',
			options: [
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_COMPANY_COMPANY',
					value: 'SG_COMPANY',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_COMPANY_DEPARTMENT',
					value: 'SG_DEPARTMENT',
				},
			],
		},
		{
			labelLK: 'CSV_MAPPING_COLUMN_GROUP_CONTACT',
			options: [
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_CONTACT_PHONE',
					value: 'SG_PHONE',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_CONTACT_FAX',
					value: 'SG_FAX',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_CONTACT_PAGER',
					value: 'SG_PAGER',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_CONTACT_EMAIL',
					value: 'SG_EMAIL',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_CONTACT_WEBSITE',
					value: 'SG_URL',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_CONTACT_NOTE',
					value: 'SG_NOTE',
				},
			],
		},
		{
			labelLK: 'CSV_MAPPING_COLUMN_GROUP_ADDRESS',
			options: [
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_ADDRESS_POSTBOX',
					value: 'SG_POBOX',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_ADDRESS_STREET',
					value: 'SG_STREET',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_ADDRESS_ADDITIONAL',
					value: 'SG_EXTENDED',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_ADDRESS_ZIP',
					value: 'SG_POSTAL',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_ADDRESS_CITY',
					value: 'SG_CITY',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_ADDRESS_STATE',
					value: 'SG_STATE',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_ADDRESS_COUNTRY',
					value: 'SG_COUNTRY',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_ADDRESS_COMPLETE',
					value: 'SG_SIPGATEADDRESS',
				},
			],
		},
		{
			labelLK: 'CSV_MAPPING_COLUMN_GROUP_OTHER',
			options: [
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_OTHER_NOTE',
					value: 'SG_OTHER',
				},
				{
					labelLK: 'CSV_MAPPING_COLUMN_GROUP_OTHER_IGNORE',
					value: 'SG_NOOP',
				},
			],
		},
	];
};

export const getCSVColumns = (fileContent: string): string[] => {
	const lines = fileContent.split('\n');
	const columns = lines[0].split(',');

	return columns;
};
