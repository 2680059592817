import { createSlice } from '@reduxjs/toolkit';
import { TranslationsState } from './types';
import { fetchTranslations, setDebug } from './actions';

export const initialState: TranslationsState = {
	items: {},
	fetching: false,
	fetched: false,
	debug: false,
	fake: false,
	locale: 'de_DE',
};

const slice = createSlice({
	name: 'translations',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchTranslations.pending, state => ({
			...state,
			fetching: true,
		}));
		builder.addCase(fetchTranslations.fulfilled, (state, { payload, meta }) => ({
			...state,
			items: payload,
			locale: meta.arg.locale,
			fetched: true,
			fetching: false,
		}));
		builder.addCase(setDebug, (state, { payload }) => ({
			...state,
			debug: payload,
		}));
	},
});

export const reducer = slice.reducer;
