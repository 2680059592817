import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';

export const fetchSipgateAppNotification = createAsyncThunk(
	'sipgateAppNotification/fetch',
	async () => api.fetchAppNotification()
);

export const createSipgateAppNotification = createAsyncThunk(
	'sipgateAppNotification/create',
	async () => api.createAppNotification()
);
