import { State } from '../../state';
import { SelectMultipleAction } from './types';

export const selectSubsetReducer = (state: State, action: SelectMultipleAction): State => {
	if (!state.entries.result) {
		return state;
	}

	return {
		...state,
		entries: {
			...state.entries,
			result: {
				...state.entries.result,
				normalized: state.entries.result.normalized.map(entry => ({
					...entry,
					selected: action.eventIds.includes(entry.originalEvent.id),
				})),
			},
		},
	};
};
