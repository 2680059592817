import { ManagedFieldValidator } from '../ManagedForm';
import { renderError } from './translate';
import { Translate } from './types';

/**
 * Ensures a string is comprised of only digits.
 * This is different from ensuring the input is a number, because:
 *
 *  1. It preserves leading zeroes
 *  2. It disallows fractions and other non-digit-only notations like 2e34
 *
 */
export const validateDigits =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	(value: string) => {
		if (/^\d*$/.test(value)) {
			return {
				valid: true,
				value,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'ERRORHINT_NOT_DIGITS'),
		};
	};
