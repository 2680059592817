import { useEffect, useMemo } from 'react';
import { SliceData, useAction } from '../..';
import { useSelector, useDispatch } from '../../utils/wrapper';
import { BaseContract, Contract, DeviceContract } from './types';
import {
	cancelDeviceContract,
	createDeviceContract,
	fetchContracts,
	fetchDeviceContracts,
	forceFetchContracts,
	forceFetchDeviceContracts,
	revokeDeviceContractCancellation,
	revokeDeviceContractDowngrade,
} from './actions';
import { fetchLegacyProduct, LegacyProduct, Product } from '../products';
import { useProducts } from '../products/hooks';
import { useUserInfo } from '../userinfo';

export const useContracts = (): SliceData<(Contract | BaseContract)[]> => {
	const dispatch = useDispatch();
	const contracts = useSelector(state => state.contracts);

	useEffect(() => {
		dispatch(fetchContracts());
	}, [dispatch]);

	if (contracts.fetched) {
		return {
			fetched: true,
			data: contracts.items,
		};
	}

	return {
		fetched: false,
		data: null,
	};
};

const useDeviceContracts = (deviceId: string): SliceData<DeviceContract[]> => {
	const state = useSelector(s => s.contracts);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchDeviceContracts(deviceId));
	}, [dispatch, deviceId]);

	return useMemo(() => {
		if (state.fetchedForDevice.includes(deviceId)) {
			return {
				fetched: true,
				data: state.deviceContracts.filter(c => c.deviceId === deviceId),
			};
		}

		return {
			fetched: false,
			data: null,
		};
	}, [deviceId, state.fetchedForDevice, state.deviceContracts]);
};

export const useDeviceProducts = <T extends 'CONTINGENT' | 'CALLPACKAGE'>(
	type: T,
	deviceId: string
): SliceData<
	{ contract: DeviceContract & { contractType: T }; product: Product | LegacyProduct }[]
> => {
	const dispatch = useDispatch();

	const userinfo = useUserInfo();
	const products = useProducts(type);
	const contracts = useDeviceContracts(deviceId);
	const legacyProducts = useSelector(s => s.products.legacyItems);

	useEffect(() => {
		if (!contracts.fetched || !products.fetched) {
			return;
		}

		for (const contract of contracts.data.filter(c => c.contractType === type)) {
			if (
				!products.data.some(p => p.id === contract.productId) &&
				!legacyProducts.some(p => p.id === contract.productId)
			) {
				dispatch(fetchLegacyProduct(contract.productId, userinfo.domain));
			}
		}
	}, [type, dispatch, contracts, products, legacyProducts, userinfo.domain]);

	return useMemo(() => {
		if (!products.fetched || !contracts.fetched) {
			return {
				fetched: false,
				data: null,
			};
		}

		const combined = contracts.data
			.filter((c): c is DeviceContract & { contractType: T } => c.contractType === type)
			.map(contract => ({
				contract,
				product:
					products.data.find(p => p.id === contract.productId) ||
					legacyProducts.find(p => p.id === contract.productId),
			}));

		const fetched = combined.filter(
			(f): f is { contract: typeof f.contract; product: Product | LegacyProduct } => !!f.product
		);

		if (fetched.length !== combined.length) {
			return {
				fetched: false,
				data: null,
			};
		}

		return {
			fetched: true,
			data: fetched,
		};
	}, [type, contracts, products, legacyProducts]);
};

export const useContractActions = () => ({
	forceFetchContracts: useAction(forceFetchContracts),
	forceFetchDeviceContracts: useAction(forceFetchDeviceContracts),
	createDeviceContract: useAction(createDeviceContract),
	cancelDeviceContract: useAction(cancelDeviceContract),
	revokeDeviceContractCancellation: useAction(revokeDeviceContractCancellation),
	revokeDeviceContractDowngrade: useAction(revokeDeviceContractDowngrade),
});
