import { Moment } from 'moment';
import { createAction } from '../..';
import { DataUsageState } from './types';
import api from '../../../api';

export const fetchDataUsage = createAction(
	'DATA_USAGE_FETCH',
	(containerId: string, dateStart: Moment, dateStop: Moment, intervalMinutes: number) => ({
		promise: () =>
			api.getDataUsage(
				containerId,
				dateStart.toISOString(),
				dateStop.toISOString(),
				intervalMinutes.toString()
			),
		data: { containerId, dateStart, dateStop, intervalMinutes },
	})
);

const shouldFetchDataUsageAggregation = (state: DataUsageState, deviceId: string) =>
	!state.itemsAggregation[deviceId] || !state.itemsAggregation[deviceId].length;

export const fetchDataUsageAggregation = createAction(
	'DATA_USAGE_AGGREGATION_FETCH',
	(containerId: string, months: string[], force?: boolean) => ({
		promise: () => api.getDataUsageAggregation(containerId, months),
		shouldFetch: (state: { dataUsage: DataUsageState }) =>
			force || shouldFetchDataUsageAggregation(state.dataUsage, containerId),
		data: { containerId, months },
	})
);

export const fetchDataUsageSettings = createAction(
	'DATA_USAGE_SETTINGS_FETCH',
	(containerId: string, force?: boolean) => ({
		promise: () => api.getDataUsageSettings(containerId),
		shouldFetch: (state: { dataUsage: DataUsageState }) =>
			force ||
			(!state.dataUsage.fetchedSettingsForDevice.includes(containerId) &&
				!state.dataUsage.fetchingSettingsForDevice.includes(containerId)),
		data: { containerId },
	})
);

export const setDataUsageSettings = createAction(
	'DATA_USAGE_SET_SETTINGS',
	(containerId: string, persistenceLevel: string) => ({
		promise: () => api.setDataUsageSettings(containerId, persistenceLevel),
		data: { containerId, persistenceLevel },
	})
);
