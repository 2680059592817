import { ApiContact, ContactScope, CSVPrepareResponse } from '../../../../api/types/contacts';

export * from './actions';

export const ContactEmailTypes = ['work', 'home', 'internet', 'other'] as const;
export type ContactEmailType = (typeof ContactEmailTypes)[number];

export const ContactNumberTypes = ['work', 'home', 'cell', 'pager', 'directdial', 'other'] as const;
export type ContactNumberType = (typeof ContactNumberTypes)[number];

export const ContactFaxNumberTypes = ['work', 'home', 'other'];
export type ContactFaxNumberType = (typeof ContactFaxNumberTypes)[number];

export const ContactWebsiteTypes = [
	'home',
	'work',
	'page',
	'ftp',
	'blog',
	'profile',
	'other',
] as const;
export type ContactWebsiteType = (typeof ContactWebsiteTypes)[number];

export const ContactAddressTypes = ['home', 'work', 'other'] as const;
export type ContactAddressType = (typeof ContactAddressTypes)[number];

export interface ContactNumber {
	fax: boolean;
	type: ContactNumberType;
	number: string;
}

export interface ContactEmail {
	type: ContactEmailType;
	address: string;
}

export interface ContactWebsite {
	type: ContactWebsiteType;
	url: string;
}

export interface ContactOrganization {
	company: string;
	unit: string;
}

export interface ContactAddress {
	poBox: string;
	extendedAddress: string;
	streetAddress: string;
	locality: string;
	region: string;
	postalCode: string;
	country: string;
	type: ContactAddressType;
}

export type ImageType = 'image/jpeg' | 'image/png';
export type Base64Content = string;
export type Base64EncodedImageString = `data:${ImageType};base64,${Base64Content}`;

export type Contact = {
	id: string | null;
	name: string;
	givenname: string;
	familyname: string;
	emails: ContactEmail[];
	numbers: ContactNumber[];
	websites: ContactWebsite[];
	scope: ContactScope;
	avatar: {
		type: ImageType;
		content: Base64Content;
	} | null;
	note: string | null;
	addresses: ContactAddress[];
	organizations: ContactOrganization[];
};

export type PersistedContact = Contact & { id: string; apiContact?: ApiContact };

export type ImportMapperState = CSVPrepareResponse;

export interface ContactsState {
	fetched: boolean;
	importing: boolean;
	abortController: AbortController | null;
	importMapper: ImportMapperState | null;
	items: Contact[];
}
