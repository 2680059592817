import { Store } from '@reduxjs/toolkit';
import api from '../../../../api';
import { State } from '../state';
import { NormalizedEvent } from '../../normalize/events';
import { FaxHistoryEntry } from '../../../../api/types/events';

export const resendFax = (store: Store<State>) => async (eventId: string) => {
	const state = store.getState();

	if (!state.entries.result) {
		return;
	}

	const faxEvent = state.entries.result.normalized.find(
		entry => entry.originalEvent.id === eventId
	) as NormalizedEvent<FaxHistoryEntry> | undefined;

	if (!faxEvent) {
		return;
	}

	await api.resendFax(undefined, faxEvent.originalEvent.id);
};
