import React from 'react';
import { RouteComponentProps } from 'react-router';
import serviceUrls from '@web-apps/service-urls';
import { HeadingBoundary, Headline } from '@panda/ui';
import { connect, ReduxProps } from '../redux';
import { ReduxState } from '../redux/types';

import { fetchTranslations } from '../redux/slices/translations';
import PageNotFoundRedirect from '../redirects/PageNotFoundRedirect';
import classes from './ElectronLoginRedirect.scss';
import { DeprecatedMarkdown } from '../helpers/Markdown';
import ClipboardButton from '../components/buttons/ClipboardButton';
import loginIcon from '../media/illustrations/Illustration_Softphone_Login_Code_Seite.png';
import { TranslateProps, withTranslate } from '../helpers/withTranslations';

const mapStateToProps = (_: ReduxState) => ({});

const mapDispatchToProps = {
	fetchTranslations,
};

type Props = RouteComponentProps &
	TranslateProps &
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class FaxdruckerLoginView extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchTranslations({
			locale: navigator.language.startsWith('en') ? 'en_GB' : 'de_DE',
		});
	}

	private getLoginLink = () => {
		const realm = 'sipgate-apps';
		const faxdruckerRedirect = `${serviceUrls.appWeb}/faxdrucker-login`;
		const loginRedirect = `${serviceUrls.authentication.keycloak}/realms/${realm}/protocol/openid-connect/auth?client_id=sipgate_faxdrucker_win&response_type=code&redirect_uri=${faxdruckerRedirect}`;
		const url = `${
			serviceUrls.authentication.keycloak
		}/realms/${realm}/protocol/openid-connect/logout?redirect_uri=${encodeURIComponent(
			loginRedirect
		)}`;

		return (
			<p className={classes.loginDescription}>
				<DeprecatedMarkdown source={this.props.translate('FAXDRUCKER_LOGIN_HELP_LINK_TEXT', url)} />
			</p>
		);
	};

	public render() {
		const code = new URLSearchParams(this.props.location.search).get('code');

		if (code) {
			return (
				<div className={classes.mainContainer}>
					<div className={classes.container}>
						<div className={classes.leftSide}>
							<Headline className={classes.loginHeadline}>
								<DeprecatedMarkdown source={this.props.translate('FAXDRUCKER_LOGIN_HEADLINE')} />
							</Headline>
							<HeadingBoundary>
								<p className={classes.loginDescription}>
									{this.props.translate('FAXDRUCKER_LOGIN_DESCRIPTION')}
								</p>
								<div className={classes.buttonContainer}>
									<div className={classes.clipboardInput}>
										<input className={classes.inputField} defaultValue={code} name="code" />
										<ClipboardButton text={code} size="large" />
									</div>
								</div>
								{this.getLoginLink()}
							</HeadingBoundary>
						</div>
						<img
							className={classes.icon}
							alt={this.props.translate('FAXDRUCKER_REDIRECT_ICON')}
							src={loginIcon}
						/>
					</div>
				</div>
			);
		}

		return <PageNotFoundRedirect />;
	}
}

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(FaxdruckerLoginView));
