/* eslint-disable react/no-unused-prop-types */
type Props = {
	/**
	 * Das `label` beschreibt den Inhalt des Tabs in 1-2 Wörtern.
	 */
	label: string;
	isActive?: boolean;
	/** Der disabled State kann auch über den Context gesteuert werden. [Info zu Disabled Context](../?path=/docs/utilities-disabledcontext--docs) */
	disabled?: boolean;
};

/**
 * This components exist so the usage of <Tabs/> has a pretty API
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Tab = (props: Props) => null;
