import React from 'react';
import classnames from 'classnames';
import classes from './Pill.scss';
import { useTranslate } from '../../redux/slices/translations';

export type PillType = 'NEW' | 'BETA' | 'AI';

type Props = {
	type: PillType;
};

export const Pill = ({ type }: Props) => {
	const translate = useTranslate();

	const mapTypeToLanguageKey = () => {
		switch (type) {
			case 'BETA':
				return translate('PILL_BETA');
			case 'NEW':
				return translate('PILL_NEW');
			case 'AI':
				return translate('PILL_AI');
		}
	};

	return (
		<span
			className={classnames(classes.pill, {
				[classes.beta]: type === 'BETA',
				[classes.new]: type === 'NEW',
				[classes.ai]: type === 'AI',
			})}
		>
			{mapTypeToLanguageKey()}
		</span>
	);
};
