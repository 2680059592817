import { Checkbox, VisualCheckbox } from '@panda/ui';
import React from 'react';
import classnames from 'classnames';
import { DropdownMenu } from '../../components/dropdowns/DropdownMenu';
import { DropdownMenuItem } from '../../components/dropdowns/DropdownMenuItem';
import caretDownIcon from '../../media/icons/icon-triangle_down-dark-solid-default-16.svg';

import classes from './SelectAll.scss';
import { NormalizedEvent } from './normalize/events';
import { Translate } from '../../redux/slices/translations';

interface Props {
	selected: boolean;
	translate: Translate;
	events: NormalizedEvent[];
	selectMultiple: (eventIds: string[]) => void;
}

export class SelectAll extends React.PureComponent<Props> {
	private preventOpeningDropdownOnDesktopCheckbox = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	private toggleSelectAll = () => (this.props.selected ? this.selectNone() : this.selectAll());

	private toggleByFilter = (filter: (event: NormalizedEvent) => boolean) =>
		this.props.selectMultiple(this.props.events.filter(filter).map(ev => ev.originalEvent.id));

	private selectAll = () => this.toggleByFilter(() => true);

	private selectNone = () => this.toggleByFilter(() => false);

	private selectRead = () => this.toggleByFilter(ev => ev.originalEvent.read === 'READ');

	private selectUnread = () => this.toggleByFilter(ev => ev.originalEvent.read === 'UNREAD');

	private selectStarred = () => this.toggleByFilter(ev => ev.originalEvent.starred === 'STARRED');

	private selectUnstarred = () =>
		this.toggleByFilter(ev => ev.originalEvent.starred === 'UNSTARRED');

	private getCheckboxState = () => {
		if (!this.props.selected) {
			return false;
		}

		return this.props.events.some(e => !e.selected) ? 'indeterminate' : true;
	};

	public render() {
		return (
			<div className={classes.selectAll}>
				<DropdownMenu
					left
					opener={open => (
						<button type="button" className={classes.opener}>
							<div
								className={classnames(classes.selectGroup, classes.mobileCheckbox, {
									[classes.open]: open,
								})}
							>
								<img alt="" src={caretDownIcon} className={classes.caret} />

								<span className={classes.checkboxHighlight}>
									<VisualCheckbox
										checked={this.getCheckboxState()}
										onChange={() => {}}
										aria-label={this.props.translate('SELECT_EVENTS_CHECKBOX_LABEL')}
									/>
								</span>
							</div>
							<div
								className={classnames(classes.selectGroup, classes.desktopCheckbox, {
									[classes.open]: open,
								})}
							>
								<img alt="" src={caretDownIcon} className={classes.caret} />

								{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
								<span
									onClick={this.preventOpeningDropdownOnDesktopCheckbox}
									className={classes.checkboxHighlight}
								>
									<Checkbox
										checked={this.getCheckboxState()}
										onChange={this.toggleSelectAll}
										ariaLabel={this.props.translate('SELECT_EVENTS_CHECKBOX_LABEL')}
									/>
								</span>
							</div>
						</button>
					)}
				>
					{[
						<DropdownMenuItem type="button" onClick={this.selectAll} key="all">
							{this.props.translate('SELECT_DROPDOWN_ALL')}
						</DropdownMenuItem>,
						<DropdownMenuItem type="button" onClick={this.selectNone} key="none">
							{this.props.translate('SELECT_DROPDOWN_NONE')}
						</DropdownMenuItem>,
						<DropdownMenuItem type="button" onClick={this.selectRead} key="read">
							{this.props.translate('SELECT_DROPDOWN_READ')}
						</DropdownMenuItem>,
						<DropdownMenuItem type="button" onClick={this.selectUnread} key="unread">
							{' '}
							{this.props.translate('SELECT_DROPDOWN_UNREAD')}
						</DropdownMenuItem>,
						<DropdownMenuItem type="button" onClick={this.selectStarred} key="starred">
							{' '}
							{this.props.translate('SELECT_DROPDOWN_STARRED')}
						</DropdownMenuItem>,
						<DropdownMenuItem type="button" onClick={this.selectUnstarred} key="unstarred">
							{' '}
							{this.props.translate('SELECT_DROPDOWN_UNSTARRED')}
						</DropdownMenuItem>,
					]}
				</DropdownMenu>
			</div>
		);
	}
}
