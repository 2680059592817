import api from '../../../api';
import { createAction } from '../..';
import { ApiAccountAudioFile } from '../../../api/types/accountAudioFiles';
import { ReduxState } from '../../types';

const shouldFetchTransferAudioFiles = (state: ReduxState) =>
	!state.transferAudioFiles.fetched && !state.transferAudioFiles.fetching;

export const fetchTransferAudioFiles = createAction('TRANSFER_AUDIOFILES_FETCH', () => ({
	promise: () => api.getTransferAudioFiles(),
	shouldFetch: shouldFetchTransferAudioFiles,
}));

export const fetchTransferAudioFileContent = createAction(
	'TRANSFER_AUDIOFILE_FETCH_CONTENT',
	(audioFile: ApiAccountAudioFile) => ({
		shouldFetch: () => audioFile.url.state === 'UNFETCHED',
		promise: api.getAudioFileContent(audioFile.id),
		data: {
			audioFileId: audioFile.id,
		},
	})
);

export const setTransferAudioFileAlias = createAction(
	'TRANSFER_AUDIOFILE_SET_ALIAS',
	(audioFileId: string, alias: string) => ({
		promise: () => api.setAudioFileAlias(audioFileId, alias),
		data: {
			audioFileId,
			alias,
		},
	})
);

export const setActiveTransferAudioFile = createAction(
	'TRANSFER_AUDIOFILE_SET_ACTIVE',
	(activeAudioFileId: string | null) => ({
		promise: api.setActiveTransferAudioFile(activeAudioFileId),
		data: {
			activeAudioFileId,
		},
	})
);

export const deleteTransferAudioFile = createAction(
	'TRANSFER_AUDIOFILE_DELETE',
	(audioFileId: string) => ({
		promise: api.deleteAudioFile(audioFileId),
		data: {
			audioFileId,
		},
	})
);

export const createTransferAudioFile = createAction(
	'TRANSFER_AUDIOFILE_CREATE',
	(filename: string, base64Content: string) => ({
		promise: api.createTransferAudioFile(filename, base64Content),
	})
);
