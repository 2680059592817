import { createSlice } from '@reduxjs/toolkit';
import {
	completeCreditCardVerification,
	createSepaPaymentMethod,
	deletePaymentMethod,
	fetchAutoCreditingSettings,
	fetchOpenPositions,
	fetchPaymentMethods,
	setAutoCreditingSettings,
} from './actions';
import { PaymentState } from './types';

const initialState: PaymentState = {
	fetchedMethods: false,
	fetchingMethods: false,
	paymentMethods: [],

	autoCrediting: null,
	fetchedAutoCrediting: false,
	fetchingAutoCrediting: false,

	openPositions: null,
	fetchedOpenPositions: false,
	fetchingOpenPositions: false,
};

export const reducer = createSlice({
	name: 'payment',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchPaymentMethods.pending, state => ({ ...state, fetchingMethods: true }))
			.addCase(fetchPaymentMethods.fulfilled, (state, action) => ({
				...state,
				fetchedMethods: true,
				fetchingMethods: false,
				paymentMethods: action.payload.items,
			}))
			.addCase(deletePaymentMethod.pending, (state, action) => ({
				...state,
				paymentMethods: [...state.paymentMethods.filter(pm => pm.id !== action.meta.arg.paymentId)],
			}))

			.addCase(fetchAutoCreditingSettings.pending, state => ({
				...state,
				fetchingAutoCrediting: true,
			}))
			.addCase(fetchAutoCreditingSettings.fulfilled, (state, action) => ({
				...state,
				fetchedAutoCrediting: true,
				fetchingAutoCrediting: false,
				autoCrediting: action.payload
					? {
							paymentMethod: action.payload.paymentMethod,
							charge: {
								amount: action.payload.chargeInCent,
								fraction: 100,
								currency: action.payload.currency,
							},
							threshold: {
								amount: action.payload.thresholdInFullCurrency,
								fraction: 1,
								currency: action.payload.currency,
							},
						}
					: null,
			}))
			.addCase(setAutoCreditingSettings.fulfilled, (state, action) => ({
				...state,
				autoCrediting: action.payload
					? {
							paymentMethod: action.payload.paymentMethod,
							charge: {
								amount: action.payload.chargeInCent,
								fraction: 100,
								currency: action.payload.currency,
							},
							threshold: {
								amount: action.payload.thresholdInFullCurrency,
								fraction: 1,
								currency: action.payload.currency,
							},
						}
					: null,
			}))

			.addCase(fetchOpenPositions.pending, state => ({
				...state,
				fetchingOpenPositions: true,
			}))
			.addCase(fetchOpenPositions.fulfilled, (state, action) => ({
				...state,
				fetchedOpenPositions: true,
				fetchingOpenPositions: false,
				openPositions: action.payload
					? {
							amount: action.payload.amountInTenThousandths,
							currency: action.payload.currency,
							fraction: 10_000,
						}
					: null,
			}))

			.addCase(createSepaPaymentMethod.fulfilled, (state, action) => ({
				...state,
				paymentMethods: [
					...state.paymentMethods.filter(p => p.id !== action.payload.id),
					action.payload,
				],
			}))
			.addCase(completeCreditCardVerification.fulfilled, (state, action) => ({
				...state,
				paymentMethods: [
					...state.paymentMethods.filter(p => p.id !== action.payload.id),
					action.payload,
				],
			}));
	},
}).reducer;
