import { Translate } from '../../../translations/src';
import { ManagedFieldValidator } from '../ManagedForm';
import { renderError } from './translate';

export const validateURL =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	value => {
		try {
			const _ = new URL(value);

			return {
				valid: true,
				value,
			};
		} catch {
			return {
				valid: false,
				error: renderError(translate, 'FORM_VALIDATION_INVALID_URL'),
			};
		}
	};
