import { Group } from './types';

export function selectGroup(groups: Group[], groupId: string) {
	return groups.find(group => group.id === groupId);
}

export function selectGroupsByUserId(groups: Group[], userId: string) {
	return groups.filter(group => {
		if (!group.users) {
			return false;
		}

		return group.users.includes(userId);
	});
}
