import React from 'react';
import { Button } from '@panda/ui';
import Snackbar from '../messages/Snackbar';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';

interface State {
	success: boolean;
	snackbarOpen: boolean;
}

interface ExternalProps {
	text: string;
	size: 'small' | 'medium' | 'large' | 'xlarge';
	/** @deprecated */
	deprecated?: boolean;
}

type Props = TranslateProps & ExternalProps;

class ClipboardButton extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			success: false,
			snackbarOpen: false,
		};
	}

	private handleClick = async () => {
		await navigator.clipboard.writeText(this.props.text);
		this.setState({ success: true });
		window.setTimeout(() => this.setState({ snackbarOpen: true }), 10);
	};

	private handleSnackbarClose = () => {
		this.setState({ snackbarOpen: false });
		window.setTimeout(() => this.setState({ success: false }), 400);
	};

	public render() {
		return (
			<>
				<Button
					aria-label={this.props.translate('CLIPBOARD_COPY')}
					onClick={this.handleClick}
					icon="copy"
					iconOnly
					variant="quiet"
					size={this.props.size}
					deprecated={this.props.deprecated}
				/>

				{this.state.success ? (
					<Snackbar
						snackbarOpen={this.state.snackbarOpen}
						closeSnackbar={this.handleSnackbarClose}
						autoHideDuration={3000}
						severity="info"
					>
						{this.props.translate('CLIPBOARD_COPY_SUCCESS')}
					</Snackbar>
				) : null}
			</>
		);
	}
}

export default withTranslate(ClipboardButton);
