import { useEffect } from 'react';

const useKeyboardEvents = (keycode: string, callback: () => void, listen: boolean) => {
	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if (event.code === keycode) {
				callback();
			}
		};

		if (listen) {
			document.addEventListener('keydown', onKeyDown);
		} else {
			document.removeEventListener('keydown', onKeyDown);
		}

		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, [keycode, callback, listen]);
};

export default useKeyboardEvents;
