import React from 'react';
import CenterContainer from '../../helpers/CenterContainer';
import Spinner from './Spinner';

export default class CenteredSpinner extends React.Component {
	private getStyle = () => ({
		container: {
			width: '100%',
			height: '100%',
		},
		spinnerContainer: {
			height: '45px',
			width: '45px',
		},
	});

	public render() {
		return (
			<CenterContainer style={this.getStyle().container}>
				<CenterContainer style={this.getStyle().spinnerContainer}>
					<Spinner />
				</CenterContainer>
			</CenterContainer>
		);
	}
}
