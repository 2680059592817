import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { breakPoints, MediaQueries } from './mediaQueries';

export interface MediaQueryProps {
	mediaQueries: MediaQueries;
}

const findBreakPoint = () => {
	const value = window.innerWidth;

	let currentBreakPoint = value;

	for (const breakPointSize of Object.values(breakPoints)) {
		if (value >= breakPointSize) {
			currentBreakPoint = breakPointSize;
		}
	}

	return currentBreakPoint;
};

const MediaQueryDecorator = <P extends MediaQueryProps>(ChildComponent: React.ComponentType<P>) => {
	class Decorator extends React.Component<Omit<P, keyof MediaQueryProps>> {
		public state = {
			mediaQueries: new MediaQueries(findBreakPoint()),
		};

		public componentDidMount() {
			window.addEventListener('resize', this.updateDeviceSize);

			this.updateDeviceSize();
		}

		public componentWillUnmount() {
			window.removeEventListener('resize', this.updateDeviceSize);
		}

		private updateDeviceSize = () => {
			const currentBreakPointWidth = this.state.mediaQueries.getCurrentDeviceWidth();
			const newBreakPointWidth = findBreakPoint();

			if (currentBreakPointWidth !== newBreakPointWidth) {
				this.setState({
					mediaQueries: new MediaQueries(newBreakPointWidth),
				});
			}
		};

		public render() {
			return (
				<ChildComponent
					// Allowed for HOC
					//
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...(this.props as P)}
					mediaQueries={this.state.mediaQueries}
				/>
			);
		}
	}

	return hoistNonReactStatics(Decorator, ChildComponent);
};

export default MediaQueryDecorator;
