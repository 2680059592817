import React from 'react';
import classnames from 'classnames';
import { Button, HeadingBoundary, Headline } from '@panda/ui';
import classes from './SlideIn.scss';
import { Overlay } from '../components/Overlay';

interface Props {
	children: React.ReactNode;
	title: React.ReactNode;
	onClose: () => void;
	onBack?: () => void;
	className?: string;
}

/**
 * Documentation:
 *
 * When to use this component:
 *
 * * When the customer should see the results of their actions in the background right away
 * * When there is lots of information to be shown, that would not fit in a normal dialog
 *
 * How to use this Component:
 *
 * * The component is always at least 320px wide making the content at least 288px wide
 * * The component scales with wider content
 * * The width of the component is at most 100%
 * * On mobile devices the component is always 100% wide
 */
export class SlideIn extends React.Component<Props> {
	public static transitionDuration = 400;

	public static transitionClasses = {
		enter: classes.enter,
		enterActive: classes.enterActive,
		exit: classes.exit,
		exitActive: classes.exitActive,
	};

	public render() {
		return (
			<Overlay onClose={this.props.onClose} className={classes.overlay}>
				<HeadingBoundary levelOverride={3}>
					<div className={classnames(classes.panel, this.props.className)}>
						<div className={classes.header}>
							{this.props.onBack ? (
								<Button icon="back" iconOnly variant="quiet" onClick={this.props.onBack} />
							) : (
								<div className={classes.leftClose}>
									<Button icon="close" iconOnly variant="quiet" onClick={this.props.onClose} />
								</div>
							)}

							<Headline className={classes.headline}>{this.props.title}</Headline>
							<div className={classes.rightClose}>
								<Button icon="close" iconOnly variant="quiet" onClick={this.props.onClose} />
							</div>
						</div>
						<HeadingBoundary>
							<div className={classes.content}>{this.props.children}</div>
						</HeadingBoundary>
					</div>
				</HeadingBoundary>
			</Overlay>
		);
	}
}
