import { configureStore } from '@reduxjs/toolkit';
import optimist from 'redux-optimist';
import { errorMiddleware } from './middleware/error-middleware';
import { loadingAnimationMiddleware } from './middleware/loading-animation-middleware';
import promiseMiddleware from './middleware/redux-promise-middleware';
import rtkOptimistMiddleware from './middleware/rtk-optimist-middleware';
import { shouldFetchMiddleware } from './middleware/should-fetch-middleware';
import { updateRestrictionMiddleware } from './middleware/update-restriction-middleware';
import { rootReducer } from './reducer';
import { ReduxState } from './types';

export function createStore(preloadedState?: Partial<ReduxState>) {
	return configureStore({
		reducer: optimist(rootReducer),
		middleware: defaults =>
			defaults({
				thunk: true,
				// TODO: Enable this and figure out all the locations this breaks.
				immutableCheck: false,
				// TODO: Enable this and figure out all the locations this breaks. I have a hunch its really not gonna like our translate() function
				serializableCheck: false,
				// TODO: Figure out what this does. Good documentation is good I guess.
				actionCreatorCheck: false,
			}).concat([
				rtkOptimistMiddleware,
				shouldFetchMiddleware,
				updateRestrictionMiddleware,
				errorMiddleware,
				promiseMiddleware,
				loadingAnimationMiddleware,
			]),
		preloadedState: preloadedState
			? {
					...rootReducer(undefined, { type: 'PRELOAD_TO_GENERATE_INITIAL_STATE' }),
					...preloadedState,
				}
			: undefined,
	});
}
