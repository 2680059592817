import React from 'react';
import { connect, ReduxProps } from '../redux';

import auth from '../utils/authenticate/auth';
import { fetchAccount } from '../redux/slices/account';
import { fetchLinks } from '../redux/slices/links';
import { ReduxState } from '../redux/types';

const mapStateToProps = (state: ReduxState) => ({
	account: state.account,
	links: state.links,
});

const mapDispatchToProps = {
	fetchAccount,
	fetchLinks,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class PureSatelliteRedirect extends React.Component<Props> {
	public constructor(props: Props) {
		super(props);
		this.props.fetchAccount();

		this.logoutSatellite();
	}

	public componentDidUpdate() {
		this.logoutSatellite();
	}

	private logoutSatellite() {
		if (!this.props.account.fetched || !this.props.links.fetched) {
			return;
		}

		if (this.props.account.mainProductType === 'SATELLITE') {
			auth.logoutAndRedirectToLogoutPage(this.props.links.items);
		}
	}

	public render() {
		return null;
	}
}

export const SatelliteRedirect = connect(
	mapStateToProps,
	mapDispatchToProps
)(PureSatelliteRedirect);
