import { createAction } from '../..';

import api from '../../../api';
import { MobileInfoState } from './types';

export const fetchMobileInfo = createAction('MOBILEINFO_FETCH', () => ({
	promise: () => api.getMobileInfo(),
	shouldFetch: ({ mobile }: { mobile: MobileInfoState }) => !mobile.fetched && !mobile.fetching,
}));

export const forceFetchMobileInfo = createAction('MOBILEINFO_FETCH', () => ({
	promise: () => api.getMobileInfo(),
}));
