import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { ReduxState } from '../../redux/types';
import { closeInsufficientFundsSnackbar } from '../../redux/slices/snackbar';
import Snackbar from './Snackbar';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';

const mapStateToProps = (state: ReduxState) => ({
	open: state.dialogs.insufficientFundsSnackbarOpen,
	accountTopUpUrl: state.links.items.accountTopUpUrl,
});

const mapDispatchToProps = {
	closeInsufficientFundsSnackbar,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & TranslateProps;

const InsufficientFundsSnackbar = (props: Props) => (
	<Snackbar
		key="warning-snackbar"
		snackbarOpen={props.open}
		closeSnackbar={props.closeInsufficientFundsSnackbar}
		severity="warning"
	>
		<div>
			{props.translate('INSUFFICIENTFUNDS_WARNING_TEXT')}{' '}
			<a href={props.accountTopUpUrl}>{props.translate('INSUFFICIENTFUNDS_WARNING_LINK_TEXT')}</a>
		</div>
	</Snackbar>
);

export default withTranslate(
	connect(mapStateToProps, mapDispatchToProps)(InsufficientFundsSnackbar)
);
