import { createSlice } from '@reduxjs/toolkit';
import { isNqContext } from './actions';
import { NQNupsiState } from './types';
import { setDebug } from '../translations';

const initialState: NQNupsiState = {
	isNq: false,
	fetching: 'idle',
	showLks: false,
};

const slice = createSlice({
	name: 'nqNupsi',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(isNqContext.pending, state => ({
			...state,
			isNq: false,
			fetching: 'pending',
		}));
		builder.addCase(isNqContext.fulfilled, (state, { payload }) => ({
			...state,
			isNq: payload,
			fetching: 'succeeded',
		}));
		builder.addCase(isNqContext.rejected, state => ({
			...state,
			isNq: false,
			fetching: 'failed',
		}));
		builder.addCase(setDebug, (state, { payload }) => ({
			...state,
			showLks: payload,
		}));
	},
});

export const reducer = slice.reducer;
