import { handleActions } from '../..';

import { ProvisionedPhone, PhoneProvisioningState } from './types';
import * as actions from './actions';

const initalState: PhoneProvisioningState = {
	items: [],
	fetched: [],
	fetching: [],
};

export default handleActions<PhoneProvisioningState, PossibleActions<typeof actions>>(
	{
		PHONE_PROVISIONING_PHONES_FETCH_PENDING: state => ({
			...state,
			fetched: [],
			fetching: [],
		}),
		PHONE_PROVISIONING_PHONES_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			items: payload.phones,
			fetched: payload.phones.map(item => item.macAddress),
			fetching: [],
		}),
		PHONE_PROVISIONING_UPDATE_CONFIG_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map((phone: ProvisionedPhone) => ({
				...phone,
				configuration: phone.macAddress === data.macAddress ? data.config : phone.configuration,
			})),
			fetching: [...state.fetching.filter(item => item !== data.macAddress), data.macAddress],
		}),
		PHONE_PROVISIONING_UPDATE_CONFIG_SUCCESS: (state, { data }) => ({
			...state,
			fetching: state.fetching.filter(item => item !== data.macAddress),
			fetched: [...state.fetched.filter(item => item !== data.macAddress), data.macAddress],
		}),
		PHONE_PROVISIONING_SET_WEBUSER_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(phone => ({
				...phone,
				webuserExtension:
					phone.macAddress === data.macAddress ? data.webUserExtension : phone.webuserExtension,
			})),
			fetching: [...state.fetching.filter(item => item !== data.macAddress), data.macAddress],
		}),
		PHONE_PROVISIONING_SET_WEBUSER_SUCCESS: (state, { data, payload }) => ({
			...state,
			items: state.items.map(phone => ({
				...phone,
				webuserExtension:
					phone.macAddress === payload.macAddress
						? payload.webuserExtension
						: phone.webuserExtension,
			})),
			fetching: state.fetching.filter(item => item !== data.macAddress),
			fetched: [...state.fetched.filter(item => item !== data.macAddress), data.macAddress],
		}),
		PHONE_PROVISIONING_DELETE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.filter(item => item.macAddress !== data.macAddress),
			fetching: [...state.fetching.filter(item => item !== data.macAddress), data.macAddress],
		}),
		PHONE_PROVISIONING_DELETE_SUCCESS: (state, { data }) => ({
			items: state.items.filter(item => item.macAddress !== data.macAddress),
			fetching: state.fetching.filter(item => item !== data.macAddress),
			fetched: [...state.fetched.filter(item => item !== data.macAddress)],
		}),
		PHONE_PROVISIONING_SYNC_CONTACTS_PENDING: (state, { data }) => {
			const changeItem = state.items.find(item => item.macAddress === data.macAddress);

			if (!changeItem) {
				return state;
			}

			const updatedPhone = {
				...changeItem,
				syncContacts: !changeItem.syncContacts,
			};

			return {
				...state,
				items: [...state.items.filter(item => item.macAddress !== data.macAddress), updatedPhone],
				fetching: [...state.fetching.filter(item => item !== data.macAddress), data.macAddress],
			};
		},
		PHONE_PROVISIONING_SYNC_CONTACTS_SUCCESS: (state, { data }) => ({
			...state,
			fetching: state.fetching.filter(item => item !== data.macAddress),
			fetched: [...state.fetched.filter(item => item !== data.macAddress), data.macAddress],
		}),
	},
	initalState
);
