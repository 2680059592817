import { createSlice } from '@reduxjs/toolkit';
import { ChangelogState } from './types';
import { fetchChangelog } from './actions';

export const initialState: ChangelogState = {
	items: [],
	fetching: 'idle',
};

const slice = createSlice({
	name: 'changelog',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchChangelog.pending, state => ({
			...state,
			fetching: 'pending',
		}));

		builder.addCase(fetchChangelog.fulfilled, (state, { payload: items }) => ({
			...state,
			items,
			fetching: 'succeeded',
		}));
	},
});

export const reducer = slice.reducer;
