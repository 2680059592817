import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';

export const fetchSmsCallerIds = createAsyncThunk(
	'smsCallerIds/fetch',
	async (data: { userId: string; smsId: string }) => {
		return api.fetchSmsCallerIds(data.userId, data.smsId);
	},
	{
		condition: (arg, { getState }) => !(arg.smsId in getState().smsCallerIds),
	}
);

export const createSmsCallerIds = createAsyncThunk(
	'smsCallerIds/create',
	async (data: { userId: string; smsId: string; phonenumber: string }) => {
		await api.createSmsCallerId(data.userId, data.smsId, data.phonenumber);
		const smsCallerIds = await api.fetchSmsCallerIds(data.userId, data.smsId);

		return {
			smsCallerIds,
			newSmsCallerId: smsCallerIds.items.find(item => item.phonenumber === data.phonenumber),
		};
	}
);

export const setSmsCallerIdActive = createAsyncThunk(
	'smsCallerIds/setActive',
	async (data: { userId: string; smsId: string; smsCallerId: number; isDefaultNumber: boolean }) =>
		api.setActiveSmsCallerId(data.userId, data.smsId, data.smsCallerId, data.isDefaultNumber)
);

export const verifySmsCallerId = createAsyncThunk(
	'smsCallerIds/verify',
	(data: { userId: string; smsId: string; smsCallerId: number; verificationCode: string }) =>
		api.verifySmsCallerId(data.userId, data.smsId, data.smsCallerId, data.verificationCode)
);
