import { Acd } from './types';

export function selectAcd(acds: Acd[], acdId: string) {
	return acds.find(acd => acd.id === acdId);
}

export function selectAcdsByAgentId(acds: Acd[], agentId: string) {
	return acds.filter(acd => acd.agents.some(agent => agent.id === agentId));
}

export function selectAcdsByAgentIdAndTeamLeadId(acds: Acd[], agentId: string) {
	return acds.filter(
		acd =>
			acd.teamLeadIds.some(teamlead => teamlead === agentId) ||
			acd.agents.some(agent => agent.id === agentId)
	);
}

export function selectAgentByAgentId(acds: Acd[], agentId: string) {
	return acds.flatMap(acd => acd.agents).find(agent => agent.id === agentId);
}
