import { Store } from '@reduxjs/toolkit';
import { State } from '../../state';
import { SelectMultipleAction } from './types';

export const selectMultiple = (store: Store<State>) => (eventIds: string[]) => {
	store.dispatch<SelectMultipleAction>({
		type: 'SELECT_MULTIPLE',
		eventIds,
	});
};
