import React from 'react';
import classnames from 'classnames';
import classes from './RevealComponent.scss';
import revealIcon from '../../media/icons/icon-show-dark-line-default-16.svg';
import hideIcon from '../../media/icons/icon-hide-dark-line-default-16.svg';
import { Translate } from '../../redux/slices/translations';

interface Props {
	secret: string;
	translate: Translate;
	className?: string;
}

class RevealComponent extends React.Component<Props> {
	public render() {
		return (
			<div className={classnames(classes.container)}>
				<div className={classes.revealComponent}>
					<div className={classes.public}>
						<div className={classnames(classes.text, this.props.className)}>
							{'•'.repeat(this.props.secret.length)}
						</div>
						<button
							type="button"
							className={classes.revealButton}
							onClick={event => {
								event.currentTarget.parentElement!.parentElement!.classList.add(classes.revealed);
							}}
							data-testid="device-card-show-password"
						>
							<img src={revealIcon} alt={this.props.translate('SHOW_PASSWORD')} />
						</button>
					</div>
					<div className={classes.secret}>
						<div className={classnames(classes.text, this.props.className)}>
							{this.props.secret}
						</div>
						<button
							type="button"
							className={classes.revealButton}
							onClick={event => {
								event.currentTarget!.parentElement!.parentElement!.classList.remove(
									classes.revealed
								);
							}}
							data-testid="device-card-hide-password"
						>
							<img src={hideIcon} alt={this.props.translate('HIDE_PASSWORD')} />
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default RevealComponent;
