import { ReduxState } from '../../types';
import api from '../../../api';
import { createAction } from '../..';

export const fetchRoutingConfigurations = createAction(
	'ROUTING_CONFIGURATIONS_FETCH',
	(extensionId: string) => ({
		promise: async () => {
			return (await api.getMicrosoftTeamsRoutingConfiguration(extensionId)).status;
		},
		shouldFetch: (state: ReduxState) => {
			return (
				!state.microsoftTeamsRoutingConfiguration.fetching[extensionId] &&
				!state.microsoftTeamsRoutingConfiguration.fetched[extensionId] &&
				!state.forwardings.updating[extensionId]
			);
		},
		data: { extensionId },
	})
);

export const setRoutingConfigurations = createAction(
	'ROUTING_CONFIGURATIONS_SET',
	(
		masterSipId: string,
		extensionId: string,
		phoneNumbers: string[],
		sipDomain: string,
		sipPassword: string
	) => {
		return {
			promise: () =>
				api.setMicrosoftTeamsRoutingConfiguration(
					masterSipId + extensionId,
					phoneNumbers,
					sipDomain,
					sipPassword
				),
			shouldFetch: (state: ReduxState) =>
				!state.microsoftTeamsRoutingConfiguration.updating[extensionId],
			data: { extensionId },
		};
	}
);

export const syncRoutingConfigurations = createAction(
	'ROUTING_CONFIGURATIONS_SYNC',
	(
		masterSipId: string,
		extensionId: string,
		phoneNumbers: string[],
		sipDomain: string,
		sipPassword: string
	) => {
		return {
			promise: () =>
				api.syncMicrosoftTeamsRoutingConfiguration(
					masterSipId + extensionId,
					phoneNumbers,
					sipDomain,
					sipPassword
				),
			shouldFetch: (state: ReduxState) =>
				!state.microsoftTeamsRoutingConfiguration.updating[extensionId],
			data: { extensionId },
		};
	}
);

export const deleteRoutingConfigurations = createAction(
	'ROUTING_CONFIGURATIONS_DELETE',
	(extensionId: string) => {
		return {
			promise: () => api.deleteMicrosoftTeamsRoutingConfiguration(extensionId),
			shouldFetch: (state: ReduxState) =>
				!state.microsoftTeamsRoutingConfiguration.updating[extensionId],
			data: { extensionId },
		};
	}
);
