import { handleActions } from '../..';
import { ProductsTacsState } from './types';
import * as actions from './actions';

const initialState: ProductsTacsState = {
	fetchingForProducts: [],
	fetchedForProducts: [],
	items: {},
};

export default handleActions<ProductsTacsState, PossibleActions<typeof actions>>(
	{
		PRODUCTS_TACS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForProducts: [
				...state.fetchingForProducts.filter(productId => productId !== data.productId),
				data.productId,
			],
		}),
		PRODUCTS_TACS_FETCH_SUCCESS: (state, { data, payload }) => ({
			fetchingForProducts: [
				...state.fetchingForProducts.filter(productId => productId !== data.productId),
			],
			fetchedForProducts: [
				...state.fetchedForProducts.filter(productId => productId !== data.productId),
				data.productId,
			],
			items: { ...state.items, [data.productId]: payload.items },
		}),
	},
	initialState
);
