import { SipgateLocale, UserInfo } from './types';

export const normalizeLocale = (locale: SipgateLocale) => (locale === 'de_DE' ? 'de-DE' : 'en-GB');

export const hasFeatureToggle = (userInfo: UserInfo | null, flag: string) => {
	if (userInfo === null) {
		return false;
	}

	return userInfo.flags.includes(flag);
};
