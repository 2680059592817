import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect, ReduxProps } from '../../redux';

import { EventList } from './EventList';
import {
	openAutoCloseInfoSnackbar,
	openAutoCloseSuccessSnackbar,
	openErrorSnackbar,
} from '../../redux/slices/snackbar';
import { fetchUserInfo, UserInfo } from '../../redux/modules/userinfo';
import {
	fetchDevices,
	getAppDevices,
	getOwnDevices,
	getUnlinkedDevices,
} from '../../redux/modules/devices';
import {
	fetchFaxlines,
	selectFaxlinesForOwner,
	selectFaxlinesForOwners,
} from '../../redux/modules/faxlines';
import {
	arePhonelinesFetchedForUser,
	fetchPhonelines,
	selectPhonelinesForWebuser,
} from '../../redux/modules/phonelines';
import { createPseudo } from '../../redux/modules/pseudo';
import { fetchVoicemails } from '../../redux/modules/voicemails';
import { fetchGroups } from '../../redux/modules/groups';
import { fetchSms, selectSmsForUser } from '../../redux/slices/sms';
import { createStore } from './state/store';
import {
	createBlocklistEntry,
	deleteBlocklistEntry,
	fetchBlocklistEntries,
} from '../../redux/slices/blocklist';
import { fetchUsers } from '../../redux/modules/users';
import { deserializeHash, modifyHash } from './selections';
import { selectionParsers, Selections } from './selections/parsers';
import { selectGroupsByUserId } from '../../redux/modules/groups/selectors';
import { fetchAccount, fetchEventLifetime } from '../../redux/slices/account';
import { ReduxState } from '../../redux/types';

import { fetchContacts, fetchContactsByPhonenumbers } from '../../redux/modules/contacts';
import { LinksState } from '../../redux/slices/links';
import { fetchAcds, selectAcdsByAgentIdAndTeamLeadId } from '../../redux/slices/acds';
import { createLabel, deleteLabel, editLabel, fetchLabels } from '../../redux/modules/labels';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';
import { fetchPhonenumbers } from '../../redux/slices/phonenumbers';

const mapStateToProps = (state: ReduxState) => ({
	account: state.account,
	users: state.users,
	contacts: state.contacts.items,
	blocklist: state.blocklist,
	sms: state.sms,
	groups: state.groups,
	devices: state.devices,
	faxlines: state.faxlines,
	phonelines: state.phonelines,
	voicemails: state.voicemails,
	acds: state.acds,
	phonenumbers: state.phonenumbers,
	restrictions: state.restrictions,
	labels: state.labels,
});

const mapDispatchToProps = {
	signalSuccess: openAutoCloseSuccessSnackbar,
	signalFailure: openErrorSnackbar,
	signalInfo: openAutoCloseInfoSnackbar,
	fetchUserInfo,

	fetchAccount,
	fetchEventLifetime,
	fetchSms,
	fetchUsers,
	fetchDevices,
	fetchFaxlines,
	fetchBlocklistEntries,
	fetchPhonelines,
	fetchVoicemails,
	fetchGroups,
	fetchContactsByPhonenumbers,
	fetchContacts,
	fetchPhonenumbers,
	fetchAcds,
	fetchLabels,

	createPseudo,
	createBlocklistEntry,
	deleteBlocklistEntry,
	createLabel,
	deleteLabel,
	editLabel,
};

interface ExternalProps {
	webuserId: string;
	onOpenBlacklist: () => void;
	onNewContact: (number: string) => void;
	onAddNumberToContact: (number: string) => void;
	onClick2Dial: (number: string) => void;
	onAnswerWithSms?: (number: string) => void;
	onClick2Play?: (eventId: string) => void;
	links: LinksState;
	userinfo: UserInfo;
}

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
	ExternalProps &
	TranslateProps &
	RouteComponentProps;

class ConnectedEventList extends React.Component<Props> {
	// We assume domain never changes without a full page reload as it is very heavily
	// attached to the user account without the ability to be changed.
	//
	// If this ever changes, we will need to build update logic.
	public store = createStore(this.props.userinfo.domain);

	public constructor(props: Props) {
		super(props);

		this.store.updateBlacklist(props.blocklist.items);

		this.store.updateContacts(props.contacts);

		this.store.updateNumbers(props.phonenumbers.items);

		if (props.labels.fetched) {
			this.store.updateLabels(props.labels.items);
		}

		this.store.updateExtensions({
			sms: props.sms.items,
			users: props.users.items,
			groups: props.groups.items,
			devices: [
				...getOwnDevices(props.devices.items, this.props.webuserId),
				...getUnlinkedDevices(props.devices.items),
				...getAppDevices(props.devices.items),
			],
			faxlines: [
				...selectFaxlinesForOwner(props.faxlines.items, this.props.webuserId),
				...selectFaxlinesForOwners(
					props.faxlines.items,
					props.groups.items.map(group => group.id)
				),
			],
			phonelines: selectPhonelinesForWebuser(props.phonelines.items, this.props.webuserId),
			voicemails: props.voicemails.items,
			conferenceRooms: [],
			acds: props.acds.items,
		});
	}

	public componentDidMount() {
		const webuserId = this.props.webuserId;

		this.props.fetchUserInfo();
		this.props.fetchAccount();
		this.props.fetchEventLifetime();
		this.props.fetchSms({ userId: webuserId });
		this.props.fetchUsers();
		this.props.fetchDevices(webuserId);
		this.props.fetchFaxlines();
		this.props.fetchBlocklistEntries();
		this.props.fetchVoicemails(webuserId);
		this.props.fetchContacts();
		this.props.fetchGroups();
		this.props.fetchPhonelines(webuserId);
		this.props.fetchPhonenumbers();
		this.props.fetchAcds();
		this.props.fetchLabels();
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			prevProps.sms !== this.props.sms ||
			prevProps.groups !== this.props.groups ||
			prevProps.devices !== this.props.devices ||
			prevProps.faxlines !== this.props.faxlines ||
			prevProps.phonelines !== this.props.phonelines ||
			prevProps.voicemails !== this.props.voicemails ||
			prevProps.acds !== this.props.acds
		) {
			this.store.updateExtensions({
				sms: this.props.sms.items,
				users: this.props.users.items,
				groups: this.props.groups.items,
				devices: [
					...getOwnDevices(this.props.devices.items, prevProps.webuserId),
					...getUnlinkedDevices(this.props.devices.items),
					...getAppDevices(this.props.devices.items),
				],
				faxlines: [
					...selectFaxlinesForOwner(this.props.faxlines.items, prevProps.webuserId),
					...selectFaxlinesForOwners(
						this.props.faxlines.items,
						this.props.groups.items.map(group => group.id)
					),
				],
				phonelines: selectPhonelinesForWebuser(this.props.phonelines.items, prevProps.webuserId),
				voicemails: this.props.voicemails.items,
				conferenceRooms: [],
				acds: this.props.acds.items,
			});
		}

		if (prevProps.phonenumbers !== this.props.phonenumbers) {
			this.store.updateNumbers(this.props.phonenumbers.items);
		}

		if (prevProps.contacts !== this.props.contacts) {
			this.store.updateContacts(this.props.contacts);
		}

		if (prevProps.blocklist !== this.props.blocklist) {
			this.store.updateBlacklist(this.props.blocklist.items);
		}

		if (prevProps.labels !== this.props.labels && this.props.labels.fetched) {
			this.store.updateLabels(this.props.labels.items);
		}

		prevProps.fetchPhonelines(this.props.webuserId);
	}

	private onBlocklist = async (number: string, blacklisted: boolean): Promise<void> => {
		if (blacklisted) {
			return this.props
				.createBlocklistEntry({ phoneNumber: number, isBlock: false })
				.then(() => {});
		}

		return this.props.deleteBlocklistEntry(number).then(() => {});
	};

	private changeUrlParameter = (newValues: Partial<Selections>) => {
		this.props.history.push({
			hash: modifyHash(selectionParsers, newValues, this.props.location.hash),
		});
	};

	private createLabel = async (name: string) => {
		const res = await this.props.createLabel(name).payload.promise;

		if (res.type === 'LABELS_CREATE_FAILURE') {
			throw new Error('Unknown error');
		}

		return res.payload;
	};

	private editLabel = async (id: number, name: string) => {
		const res = await this.props.editLabel(id, name).payload.promise;

		if (res.type === 'LABELS_EDIT_FAILURE') {
			throw new Error('Unknown error');
		}
	};

	private deleteLabel = async (id: number) => {
		const res = await this.props.deleteLabel(id).payload.promise;

		if (res.type === 'LABELS_DELETE_FAILURE') {
			throw new Error('Unknown error');
		}
	};

	public render() {
		if (!this.props.account.fetched || !this.props.account.eventLifetime.fetched) {
			return null;
		}

		const groups = this.props.groups.fetched
			? selectGroupsByUserId(this.props.groups.items, this.props.webuserId)
			: undefined;

		const acds = this.props.acds.fetched
			? selectAcdsByAgentIdAndTeamLeadId(this.props.acds.items, this.props.webuserId)
			: undefined;

		return (
			<this.store.Provider>
				<EventList
					enabled={this.props.account.eventLifetime.eventLifetime !== 'NONE'}
					isLimitedTo30Days={this.props.account.eventLifetime.eventLifetime === 'THIRTY_DAYS'}
					storeActions={this.store}
					webuserId={this.props.webuserId}
					masterSipId={this.props.userinfo.masterSipId}
					translate={this.props.translate}
					signalFailure={this.props.signalFailure}
					signalSuccess={this.props.signalSuccess}
					signalInfo={this.props.signalInfo}
					onBlacklist={this.onBlocklist}
					onNewContact={this.props.onNewContact}
					onAddNumberToContact={this.props.onAddNumberToContact}
					onClick2Dial={this.props.onClick2Dial}
					onClick2Play={this.props.onClick2Play}
					onAnswerWithSms={
						selectSmsForUser(this.props.sms.items, this.props.webuserId).length
							? this.props.onAnswerWithSms
							: undefined
					}
					changeUrlParameter={this.changeUrlParameter}
					selections={deserializeHash(selectionParsers, this.props.location.hash)}
					fetchContactsByPhonenumbers={this.props.fetchContactsByPhonenumbers}
					onOpenBlacklist={this.props.onOpenBlacklist}
					groups={groups}
					phonelines={
						arePhonelinesFetchedForUser(this.props.phonelines, this.props.webuserId)
							? selectPhonelinesForWebuser(this.props.phonelines.items, this.props.webuserId)
							: undefined
					}
					acds={acds}
					links={this.props.links}
					userinfo={this.props.userinfo}
					restrictions={this.props.restrictions}
					createLabel={this.createLabel}
					editLabel={this.editLabel}
					deleteLabel={this.deleteLabel}
				/>
			</this.store.Provider>
		);
	}
}

export default withTranslate(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedEventList))
);
