import { createSlice } from '@reduxjs/toolkit';
import { IgelState } from './types';

export const initialState: IgelState = {
	isScrolled: false,
};

const slice = createSlice({
	name: 'igel',
	initialState,
	reducers: {
		igelIsScrolled: (state, { payload: isScrolled }) => ({
			isScrolled,
		}),
	},
});

export const reducer = slice.reducer;
export const { igelIsScrolled } = slice.actions;
