import { AudioFile } from '../audioFiles';
import { ApiIvrForwarding, ApiIvr } from '../../../api/types/ivr';

export const selectUnusedAudioFilesInIvr = (audioFiles: AudioFile[], ivr: ApiIvr): AudioFile[] => {
	const usedAudioFileIds = Object.values(ivr.configuration)
		.filter(configuration => configuration && configuration.value)
		.map(({ value }) => value);
	return audioFiles.filter(({ id }) => !usedAudioFileIds.includes(id));
};

export const selectCurrentlySelectedAudioFileForIvrKey = (
	audioFiles: AudioFile[],
	ivr: ApiIvr,
	key: ApiIvrForwarding
): AudioFile[] => {
	if (ivr.configuration[key]?.type === 'EXTENSION') {
		return [];
	}
	return audioFiles.filter(({ id }) => ivr.configuration[key]?.value === id);
};
