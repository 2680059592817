import React from 'react';
import classes from './LogoSpinner.scss';

export class LogoSpinner extends React.PureComponent {
	public render() {
		return (
			<div className={classes.container}>
				<svg viewBox="0 0 52 80" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g
						className={classes.spinner}
						strokeLinecap="round"
						strokeDasharray="2.8225 138.3041"
						strokeDashoffset="1.4112"
					>
						<path
							d="
                                M 26.2718 3.3269
                                C 20.9237 3.32665 15.7247 5.08954 11.4798 8.34261
                                C 7.23494 11.5957 4.18091 16.1575 2.79062 21.3216
                                C 1.40032 26.4858 1.75132 31.9643 3.78927 36.9088
                                C 5.82722 41.8534 9.43843 45.9881 14.0638 48.6729
                                L 38.4798 30.9339
                                L 50.0468 22.5339
                                C 48.8768 17.0968 45.8789 12.2248 41.5527 8.72986
                                C 37.2265 5.23488 31.8333 3.32797 26.2718 3.3269
                                V 3.3269Z
                            "
						/>

						<path
							x="0"
							y="40"
							d="
                                M 26.2718 3.3269
                                C 20.9237 3.32665 15.7247 5.08954 11.4798 8.34261
                                C 7.23494 11.5957 4.18091 16.1575 2.79062 21.3216
                                C 1.40032 26.4858 1.75132 31.9643 3.78927 36.9088
                                C 5.82722 41.8534 9.43843 45.9881 14.0638 48.6729
                                L 38.4798 30.9339
                                L 50.0468 22.5339
                                C 48.8768 17.0968 45.8789 12.2248 41.5527 8.72986
                                C 37.2265 5.23488 31.8333 3.32797 26.2718 3.3269
                                V 3.3269Z
                            "
							transform="rotate(-180 26 40)"
						/>
					</g>
				</svg>
			</div>
		);
	}
}
