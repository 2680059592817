import React from 'react';
import classnames from 'classnames';
import { Button } from './Button';
import classes from './GhostButton.scss';

interface Props {
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	children?: React.ReactNode;
}

export class GhostButton extends React.Component<Props> {
	public render() {
		return (
			<Button
				className={classnames(classes.ghostButton, this.props.className)}
				onClick={this.props.onClick}
			>
				{this.props.children}
			</Button>
		);
	}
}
