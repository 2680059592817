import { combineReducers } from '@reduxjs/toolkit';

import phoneNumbers from './phone-numbers/reducer';
import views from './views/reducer';

export * from './phone-numbers/actions';
export * from './views/actions';

export const reducer = combineReducers({
	phoneNumbers,
	views,
});
