import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import auth from '../utils/authenticate/auth';

export const TokenAuthenticator = (props: RouteComponentProps) => {
	const params = new URLSearchParams(props.location.search);

	const token = params.get('token');
	const redirect = params.get('redirect');
	const refreshToken = params.get('refresh_token');

	useEffect(() => {
		if (!token) {
			props.history.replace('/');
			return;
		}

		if (refreshToken) {
			auth.initializeFromToken({
				access: token,
				refresh: refreshToken,
			});
		} else {
			auth.initializeFromToken({
				access: token,
			});
		}

		props.history.replace(redirect || '/');
	}, [token, redirect, refreshToken, props.history]);

	return null;
};
