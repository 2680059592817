import { Checkbox, Button } from '@panda/ui';
import classnames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';
import { ApiLabel } from '../../../api/types/labels';
import starFilledSvg from '../../../media/icons/icon-star-orange-solid-default-32.svg';
import starSvg from '../../../media/icons/icon-star-dark-line-default-32.svg';
import { Translate } from '../../../redux/slices/translations';
import { UserLabel } from '../labels/UserLabel';
import classes from './Event.scss';
import RevealComponent from '../../../components/reveal/RevealComponent';
import { HistoryEntry } from '../../../api/types/events';
import { trackClick } from '../../../third-party/mixpanel';
import { ariaId } from '../../../utils/a11y/aria-id';

/* Maximum length the database accepts for event notes */
const MAX_NOTE_LENGTH = 256;

interface Props {
	read?: boolean;
	starred?: boolean;
	eventType: HistoryEntry['type'];
	incoming?: boolean;
	source: string;
	target: string;
	systemLabels: React.ReactNode[];
	inlineText?: string;
	note?: string;
	date: Date;
	eventId: string;
	length?: string;
	translate: Translate;
	selected: boolean;
	onSelection: (eventId: string) => void;
	onRead: (eventIds: string[]) => void;
	onClose?: () => void;
	onNoteChange: (eventId: string, note: string) => void;
	onStar: (eventId: string, starred: boolean) => void;
	labels: ApiLabel[];
	impersonated?: boolean;
	children: React.ReactNode;
}

interface State {
	note: string | null;
	collapsed: boolean;
}

export class Event extends React.PureComponent<Props, State> {
	public state: State = {
		note: null,
		collapsed: true,
	};

	private getNote() {
		if (this.state.note === null) {
			return this.props.note || '';
		}
		return this.state.note;
	}

	private onToggleCollapsed = () => {
		if (!this.state.collapsed && this.props.onClose) {
			this.props.onClose();
		}

		if (this.state.collapsed && !this.props.read) {
			this.props.onRead([this.props.eventId]);
		}

		if (this.state.collapsed) {
			trackClick('Eventlist Entry', {
				type: this.props.eventType,
				direction: this.props.incoming ? 'INCOMING' : 'OUTGOING',
			});
		}

		this.setState(state => ({ collapsed: !state.collapsed }));
	};

	private onToggleSelection = () => {
		this.props.onSelection(this.props.eventId);
	};

	private onChangeNote = (event: React.FormEvent<HTMLTextAreaElement>) => {
		this.setState({ note: event.currentTarget.value });
	};

	private onSaveNote = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!this.isNoteInvalid()) {
			this.props.onNoteChange(this.props.eventId, this.getNote());
		}
	};

	private onStar = () => {
		this.props.onStar(this.props.eventId, !this.props.starred);
	};

	private getAlias() {
		if (this.props.incoming) {
			return this.props.source;
		}

		return this.props.target;
	}

	private isNoteInvalid() {
		return this.getNote().length > MAX_NOTE_LENGTH;
	}

	private renderNoteArea() {
		const id = ariaId();
		return (
			<form onSubmit={this.onSaveNote} className={classes.noteArea}>
				<label
					htmlFor={id}
					aria-label={this.props.translate(
						'EVENTLIST_NOTE_ARIA_HEADLINE',
						this.getNote().length,
						MAX_NOTE_LENGTH
					)}
				>
					{this.props.translate('EVENTLIST_NOTE_HEADLINE')} ({this.getNote().length}/
					{MAX_NOTE_LENGTH})
				</label>
				<textarea
					rows={2}
					maxLength={512}
					id={id}
					onChange={this.onChangeNote}
					placeholder={this.props.translate('NOTE_PLACEHOLDER')}
					value={this.getNote()}
				/>
				<Button type="submit" disabled={this.isNoteInvalid()}>
					{this.props.translate('SAVE_NOTE')}
				</Button>
				{this.isNoteInvalid() ? (
					<span aria-live="polite" className={classes.noteWarning}>
						{this.props.translate('NOTE_WARNING_LENGTH')}
					</span>
				) : null}
			</form>
		);
	}

	private renderInlineText() {
		if (!this.props.inlineText) {
			return null;
		}

		if (this.props.impersonated) {
			return (
				<div className={classes.note}>
					<RevealComponent secret={this.props.inlineText} translate={this.props.translate} />
				</div>
			);
		}

		return (
			<div title={this.props.inlineText} className={classes.note}>
				{this.props.inlineText}
			</div>
		);
	}

	private renderLabels() {
		return this.props.labels.map(label => (
			<UserLabel key={`label-${label.id}`}>{label.name}</UserLabel>
		));
	}

	private renderAllTheLabels() {
		return [...this.props.systemLabels, ...this.renderLabels()];
	}

	private renderType() {
		switch (this.props.eventType) {
			case 'CALL':
				return this.props.translate('EVENTTYPE_CALL');
			case 'FAX':
				return this.props.translate('EVENTTYPE_FAX');
			case 'SMS':
				return this.props.translate('EVENTTYPE_SMS');
			case 'VOICEMAIL':
				return this.props.translate('EVENTTYPE_VOICEMAIL');
		}
	}

	public render() {
		const momentDate = moment(this.props.date);
		const renderedLabels = this.renderLabels();

		return (
			<li
				className={classnames(classes.event, {
					[classes.read]: this.props.read,
					[classes.open]: !this.state.collapsed,
					[classes.selected]: this.props.selected,
				})}
				data-test-selector="eventlist-entry"
			>
				<button
					type="button"
					className={classes.star}
					onClick={this.onStar}
					aria-pressed={this.props.starred}
				>
					{this.props.starred ? (
						<img src={starFilledSvg} alt={this.props.translate('ALT_TAG_EVENT_STARRED')} />
					) : (
						<img src={starSvg} alt={this.props.translate('ALT_TAG_EVENT_STAR')} />
					)}
				</button>

				<button
					type="button"
					className={classes.eventlistButton}
					id={`eventListButton-${this.props.eventId}`}
					onClick={this.onToggleCollapsed}
					aria-expanded={!this.state.collapsed}
					aria-controls={`expandedContainer-${this.props.eventId}`}
				>
					<div className={classes.grid}>
						<div className={classes.wrapper}>
							<span className={classes.type}>
								{this.renderType()}{' '}
								{this.props.incoming
									? this.props.translate('EVENT_FROM')
									: this.props.translate('EVENT_TO')}
							</span>

							<span className={classes.alias}>{this.getAlias()}</span>
						</div>

						{this.props.systemLabels.length > 0 || renderedLabels.length > 0 ? (
							<div className={classes.labels}>{this.renderAllTheLabels()}</div>
						) : null}

						{this.renderInlineText()}

						<div className={classes.date}>
							{momentDate.format('L')} {momentDate.format('LT')}
						</div>
						<div className={classes.length}>{this.props.length ? this.props.length : null}</div>
					</div>
				</button>
				<div className={classes.checkbox}>
					<Checkbox
						onChange={this.onToggleSelection}
						checked={this.props.selected}
						ariaLabel={this.props.translate('EVENT_CHECKBOX_LABEL')}
					/>
				</div>
				<div
					id={`expandedContainer-${this.props.eventId}`}
					className={classes.detail}
					role="region"
					aria-labelledby={`eventListButton-${this.props.eventId}`}
					hidden={this.state.collapsed}
				>
					<section className={classes.detailContent}>
						<span className={classes.info}>{this.props.translate('EVENT_INFORMATION')}</span>
						{this.props.children}
						{this.renderNoteArea()}
					</section>
					<div className={classes.detailLinks}>
						<Button
							onClick={this.onToggleCollapsed}
							variant="quiet"
							aria-expanded={!this.state.collapsed}
							aria-controls={`expandedContainer-${this.props.eventId}`}
						>
							{this.props.translate('EVENTLIST_HIDE_ADDITIONAL_INFO')}
						</Button>
						<div>{this.props.translate('EVENTLIST_DATA_ID') + this.props.eventId}</div>
					</div>
				</div>
			</li>
		);
	}
}
