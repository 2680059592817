import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router';

import { useBreadcrumbs } from '../../foundation/breadcrumb';
import { Translate, useTranslate } from '../../redux/slices/translations';
import useLinks from '../../redux/slices/links/hooks';
import {
	accountManagementPath,
	appPath,
	blocklistPath,
	devicePoolPath,
	eventlistPath,
	groupsPath,
	hardwarePath,
	isAccountSection,
	ivrPath,
	ivrsPath,
	locationsPath,
	microsoftTeamsPath,
	mobileTelephonyPath,
	phonenumbersPath,
	presencePath,
	routingPath,
	samlSsoPath,
	telephonySettingsPath,
	userNotificationsPath,
} from '../../routes/paths';
import { Breadcrumb } from './Breadcrumb';
import classes from './BreadcrumbsBar.scss';

interface UrlMapping {
	getBreadcrumbs: (translate: Translate, path: string) => { name: string; link: string }[];
	keywords: RegExp[];
}

const cleanupLink = (link: string) => {
	return link.replace(new RegExp(/^\//), '');
};

const accountSectionUrlMap: UrlMapping[] = [
	{
		getBreadcrumbs: translate => [
			{
				name: translate('GROUP_ADMINISTRATION'),
				link: cleanupLink(groupsPath.build()),
			},
		],
		keywords: [/\/team\/settings\/group/, /\/team\/settings\/forwarding\/department/],
	},
	{
		getBreadcrumbs: translate => [
			{
				name: translate('ACCOUNT_NAVIGATION_SAML_SSO'),
				link: cleanupLink(samlSsoPath.build()),
			},
		],
		keywords: [samlSsoPath.regex],
	},

	{
		getBreadcrumbs: translate => [
			{
				name: translate('ACCOUNT_NAVIGATION_LOCATIONS'),
				link: cleanupLink(locationsPath.build()),
			},
		],
		keywords: [locationsPath.regex],
	},
	{
		getBreadcrumbs: translate => [
			{
				name: translate('ACCOUNT_NAVIGATION_NUMBERS'),
				link: cleanupLink(phonenumbersPath.build()),
			},
		],
		keywords: [
			/\/team\/settings\/phonenumbers/,
			/\/team\/settings\/numbers/,
			/\/team\/settings\/portednumbers/,
			/\/team\/settings\/mobilenumberimport/,
		],
	},

	{
		getBreadcrumbs: translate => [
			{
				name: translate('ACCOUNT_NAVIGATION_MS_TEAMS'),
				link: cleanupLink(microsoftTeamsPath.build()),
			},
		],
		keywords: [microsoftTeamsPath.regex],
	},

	{
		getBreadcrumbs: translate => [
			{
				name: translate('UNLINKED_DEVICE_POOLS_ADMINISTRATION'),
				link: cleanupLink(mobileTelephonyPath.build()),
			},
		],
		keywords: [mobileTelephonyPath.regex],
	},

	{
		getBreadcrumbs: translate => [
			{
				name: translate('ACCOUNT_NAVIGATION_PHONES'),
				link: cleanupLink(hardwarePath.build()),
			},
		],
		keywords: [hardwarePath.regex],
	},

	{
		getBreadcrumbs: translate => [
			{
				name: translate('IVR_ADMINISTRATION'),
				link: cleanupLink(ivrsPath.build()),
			},
		],
		keywords: [ivrsPath.regex, ivrPath.regex],
	},

	{
		getBreadcrumbs: translate => [
			{
				name: translate('ACCOUNT_NAVIGATION_APP'),
				link: cleanupLink(appPath.build()),
			},
		],
		keywords: [appPath.regex],
	},

	{
		getBreadcrumbs: translate => [
			{
				name: translate('ACCOUNT_NAVIGATION_TELEPHONY_SETTINGS_TITLE'),
				link: cleanupLink(telephonySettingsPath.build()),
			},
		],
		keywords: [telephonySettingsPath.regex],
	},
	{
		getBreadcrumbs: translate => [
			{
				name: translate('PRESENCE'),
				link: cleanupLink(presencePath.build()),
			},
		],
		keywords: [presencePath.regex],
	},
	{
		getBreadcrumbs: translate => [
			{
				name: translate('ROUTING_VIEW'),
				link: cleanupLink(routingPath.build()),
			},
		],
		keywords: [routingPath.regex],
	},
	{
		getBreadcrumbs: translate => [
			{
				name: translate('DEVICE_POOL'),
				link: cleanupLink(devicePoolPath.build()),
			},
		],
		keywords: [devicePoolPath.regex],
	},
	{
		getBreadcrumbs: translate => [
			{
				name: translate('NOTIFICATIONS'),
				link: cleanupLink(userNotificationsPath.build()),
			},
		],
		keywords: [userNotificationsPath.regex],
	},
	{
		getBreadcrumbs: translate => [
			{
				name: translate('BLACKLIST'),
				link: cleanupLink(blocklistPath.build()),
			},
		],
		keywords: [blocklistPath.regex],
	},
];

export const BreadcrumbsBar = () => {
	const links = useLinks();
	const location = useLocation();
	const translate = useTranslate();
	const contextBreadcrumbs = useBreadcrumbs();

	if (!links) {
		return <nav className={classes.bar} aria-label={translate('BREADCRUMB_NAVIGATION_LABEL')} />;
	}

	const breadcrumbItems: React.ReactNode[] = [];

	const urlMapping = accountSectionUrlMap.find(i =>
		i.keywords.some(reg => {
			return reg.test(location.pathname);
		})
	);
	if (urlMapping) {
		urlMapping.getBreadcrumbs(translate, location.pathname).forEach((crumb, index, arr) => {
			breadcrumbItems.push(
				<Breadcrumb
					key={`${crumb.name}-${urlMapping}`}
					link={`/${crumb.link}`}
					current={index === arr.length + contextBreadcrumbs.length - 1}
				>
					{crumb.name}
				</Breadcrumb>
			);
		});
	}

	const link = isAccountSection(location.pathname) ? accountManagementPath : eventlistPath;

	if (isAccountSection(location.pathname)) {
		breadcrumbItems.unshift(
			<Breadcrumb key="account" link={link.build()} current={breadcrumbItems.length === 0}>
				{translate('ACCOUNT_NAVIGATION_HEADING_HOME')}
			</Breadcrumb>
		);
	}

	const currentPageTitle =
		urlMapping === undefined
			? translate('ACCOUNT_NAVIGATION_HEADING_HOME')
			: urlMapping.getBreadcrumbs(translate, location.pathname).pop()!.name;

	return (
		<nav className={classes.bar} aria-label={translate('BREADCRUMB_NAVIGATION_LABEL')}>
			<ol>
				{[
					...breadcrumbItems,
					...contextBreadcrumbs.map((crumb, index) => (
						<Breadcrumb
							key={`context-${index}`}
							link={crumb.path}
							current={index === contextBreadcrumbs.length - 1}
						>
							{crumb.name}
						</Breadcrumb>
					)),
				]}
			</ol>
			<Helmet>
				<title>{`${currentPageTitle} - sipgate | App`}</title>
			</Helmet>
		</nav>
	);
};
