import { createSlice } from '@reduxjs/toolkit';
import { fetchConnectedAppIntegrations } from './actions';
import { ConnectedAppIntegrationsState } from './types';

const initialState: ConnectedAppIntegrationsState = {
	fetching: false,
	fetched: false,
	items: {},
};

export const reducer = createSlice({
	name: 'connectedAppIntegrations',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchConnectedAppIntegrations.pending, (state, _) => ({
			...state,
			fetching: true,
		}));
		builder.addCase(fetchConnectedAppIntegrations.fulfilled, (state, action) => ({
			...state,
			fetching: false,
			fetched: true,
			items: action.payload.items,
		}));
	},
}).reducer;
