import React from 'react';
import { connect, ReduxProps } from '../redux';
import { ReduxState } from '../redux/types';
import classes from './LoadingBar.scss';

const mapStateToProps = (state: ReduxState) => ({
	loading: state.loadingIndicator.loading,
});

type Props = ReduxProps<typeof mapStateToProps>;

class LoadingBar extends React.Component<Props> {
	public render() {
		return this.props.loading ? <div className={classes.loadingBar} /> : null;
	}
}

export default connect(mapStateToProps)(LoadingBar);
