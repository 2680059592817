import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

export const fetchTwoFactorAuthenticationSetting = createAction(
	'TWO_FACTOR_AUTHENTICATION_SETTING_FETCH',
	(webuserId: string) => ({
		data: { webuserId },
		promise: () => api.getTwoFactorAuthenticationSetting(webuserId),
		shouldFetch: (state: ReduxState) =>
			!state.twoFactorAuthenticationSettings.fetching.includes(webuserId),
	})
);
