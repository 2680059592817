import { State } from '../state';
import { UpdateNumbersAction } from './types';
import { normalizeEvents } from '../../normalize/events';

export const updateNumbersReducer = (state: State, action: UpdateNumbersAction) => {
	if (!state.entries.result) {
		return {
			...state,
			numbers: action.numbers,
		};
	}

	return {
		...state,
		numbers: action.numbers,

		entries: {
			...state.entries,

			result: {
				...state.entries.result,

				normalized: normalizeEvents(
					state.entries.result.normalized.map(event => ({
						originalEvent: event.originalEvent,
						selected: event.selected,
					})),
					state.contacts,
					state.extensions,
					state.blocklist,
					action.numbers,
					state.domain
				),
			},
		},
	};
};
