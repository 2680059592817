import { handleActions } from '../..';
import { AddressState } from './types';
import * as actions from './actions';

const initialState: AddressState = {
	fetched: false,
	fetching: false,
	items: [],
	hasBrokenAddresses: false,
	fetchedBusinessTypes: false,
	fetchingBusinessTypes: false,
	businessTypes: [],
};

export default handleActions<AddressState, PossibleActions<typeof actions>>(
	{
		NEW_ADDRESSES_FETCH_PENDING: state => ({ ...state, fetching: true, fetched: false }),
		NEW_ADDRESSES_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetched: true,
			fetching: false,
			items: payload.items,
			hasBrokenAddresses: payload.omittedErrors,
		}),
		NEW_ADDRESS_CREATE_SUCCESS: (state, { payload }) => ({
			...state,
			items: [...state.items, payload],
		}),
		NEW_ADDRESS_EDIT_SUCCESS: (state, { payload }) => {
			const newItems = [...state.items];
			newItems.splice(
				newItems.findIndex(a => a.id === payload.id),
				1,
				payload
			);

			return {
				...state,
				items: newItems,
			};
		},
		NEW_ADDRESS_MOVE_SUCCESS: (state, { payload, data }) => {
			if ('relocation' in payload.address && payload.address.relocation) {
				return {
					...state,
					items: [...state.items, payload.address],
				};
			}

			return {
				...state,
				items: [...state.items.filter(item => item.id !== data.sourceId), payload.address],
			};
		},
		NEW_ADDRESS_DELETE_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.filter(addr => addr.id !== data.addressId),
			};
		},
		BUSINESS_TYPES_FETCH_PENDING: state => {
			return {
				...state,
				fetchingBusinessTypes: true,
			};
		},
		BUSINESS_TYPES_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				fetchingBusinessTypes: false,
				fetchedBusinessTypes: true,
				businessTypes: payload.businessTypes,
			};
		},
	},
	initialState
);
