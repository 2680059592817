import { handleActions } from '../..';
import * as actions from './actions';
import { LabelsState } from './types';

const initialState: LabelsState = {
	fetched: false,
	fetching: false,
	items: [],
};

export default handleActions<LabelsState, PossibleActions<typeof actions>>(
	{
		LABELS_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),
		LABELS_FETCH_SUCCESS: (state, { payload }) => ({
			...state,
			fetched: true,
			fetching: false,
			items: payload.items,
		}),
		LABELS_CREATE_SUCCESS: (state, { payload }) => ({
			...state,
			items: [...state.items, payload],
		}),
		LABELS_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(label => label.id !== data.id),
		}),
		LABELS_EDIT_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.map(label => {
				if (label.id !== data.id) {
					return label;
				}

				return {
					...label,
					name: data.name,
				};
			}),
		}),
	},
	initialState
);
