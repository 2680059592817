import { handleActions } from '../..';
import * as actions from './actions';
import { DeviceLocalPrefixState } from './types';

const initialState: DeviceLocalPrefixState = {
	items: [],
	fetchedForDevice: [],
	fetchingForDevice: [],
	updatingForDevice: [],
};

export default handleActions<DeviceLocalPrefixState, PossibleActions<typeof actions>>(
	{
		DEVICE_LOCALPREFIX_FETCH_PENDING: (state, action) => ({
			...state,
			fetchingForDevice: [...state.fetchingForDevice, action.data.deviceId],
		}),

		DEVICE_LOCALPREFIX_FETCH_SUCCESS: (state, action) => ({
			...state,
			items: [
				...state.items.filter(prefix => prefix.deviceId !== action.data.deviceId),
				{
					value: action.payload.value,
					active: action.payload.active,
					deviceId: action.data.deviceId,
				},
			],
			fetchingForDevice: state.fetchingForDevice.filter(device => device !== action.data.deviceId),
			fetchedForDevice: [...state.fetchedForDevice, action.data.deviceId],
		}),

		DEVICE_LOCALPREFIX_SET_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(item => {
				if (item.deviceId !== data.deviceId) {
					return item;
				}

				return {
					active: data.active,
					deviceId: data.deviceId,
					value: data.localprefix,
				};
			}),
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_LOCALPREFIX_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),
	},

	initialState
);
