import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { ReduxState } from '../../redux/types';
import { closeInvalidAccountStateSnackbar } from '../../redux/slices/snackbar';
import Snackbar from './Snackbar';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';

const mapStateToProps = (state: ReduxState) => ({
	open: state.dialogs.invalidAccountStateSnackbarOpen,
});

const mapDispatchToProps = {
	closeInvalidAccountStateSnackbar,
};

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & TranslateProps;

const InvalidAccountStateSnackbar = (props: Props) => (
	<Snackbar
		key="invalid-account-state-snackbar"
		snackbarOpen={props.open}
		closeSnackbar={props.closeInvalidAccountStateSnackbar}
		severity="error"
	>
		{props.translate('INVALID_ACCOUNT_STATE_SNACKBAR_TEXT')}
	</Snackbar>
);

export default withTranslate(
	connect(mapStateToProps, mapDispatchToProps)(InvalidAccountStateSnackbar)
);
