import { handleActions } from '../..';

import * as actions from './actions';
import { MobileInfoState } from './types';

const initialState: MobileInfoState = {
	data: null,
	fetched: false,
	fetching: false,
};

export default handleActions<MobileInfoState, PossibleActions<typeof actions>>(
	{
		MOBILEINFO_FETCH_PENDING: state => ({ ...state, fetching: true }),
		MOBILEINFO_FETCH_SUCCESS: (state, { payload }) => {
			return {
				...state,
				data: payload,
				fetched: true,
				fetching: false,
			};
		},
	},
	initialState
);
