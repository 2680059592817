import { Store } from '@reduxjs/toolkit';
import { State } from '../../state';
import { ToggleEntrySelectionAction } from './types';

export const toggleEntrySelection = (store: Store<State>) => (eventId: string) => {
	store.dispatch<ToggleEntrySelectionAction>({
		type: 'TOGGLE_ENTRY_SELECTION',
		eventId,
	});
};
