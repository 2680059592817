import { createSlice } from '@reduxjs/toolkit';
import { LoadingIndicatorState } from './types';
import * as actions from './actions';

export const initialState: LoadingIndicatorState = {
	loading: false,
};

const slice = createSlice({
	name: 'loadingIndicator',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(actions.toggleLoadingAnimation, (state, { payload }) => ({
			loading: payload,
		}));
	},
});

export const reducer = slice.reducer;
