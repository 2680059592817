import React, { useContext } from 'react';
import type { BotifyChatWidgetProps } from '@sipgate/botify-chat-widget';

interface BotifyContext {
	deeplink: string | null;
	openDeeplink: (value: string) => void;
}

const BotifyContext = React.createContext<BotifyContext>({
	deeplink: null,
	openDeeplink: () => {},
});

interface ExternalProps {
	children: React.ReactNode;
}

type Props = ExternalProps & Omit<BotifyChatWidgetProps, 'deeplink'>;

interface State {
	deeplink: string | null;
}

export class BotifyProvider extends React.Component<Props, State> {
	public state: State = {
		deeplink: null,
	};

	private openDeeplink = (deeplink: string) => {
		this.setState({ deeplink });
	};

	public render() {
		return (
			<BotifyContext.Provider
				value={{
					deeplink: this.state.deeplink,
					openDeeplink: this.openDeeplink,
				}}
			>
				{this.props.children}
			</BotifyContext.Provider>
		);
	}
}

export const BotifyConsumer = BotifyContext.Consumer;

export interface BotifyProps {
	botify: BotifyContext;
}

export function withBotify<InnerProps extends BotifyProps>(
	Component: React.ComponentType<InnerProps>
) {
	return function WithBotify(props: Omit<InnerProps, 'botify'>) {
		const ctx = useContext(BotifyContext);

		// Fine for a HOC
		//
		// eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-explicit-any
		return <Component {...(props as any)} botify={ctx} />;
	};
}
