import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

function shouldFetchPortings(state: ReduxState) {
	return !state.portings.items.length && !state.portings.fetching && !state.portings.fetched;
}

export const fetchPortings = createAction('PORTINGS_FETCH', () => ({
	promise: () => api.getPortings(),
	shouldFetch: (state: ReduxState) => shouldFetchPortings(state),
}));

export const revokePorting = createAction('PORTINGS_REVOKE', (portingId: string) => ({
	promise: () => api.revokePorting(portingId),
	data: {
		portingId,
	},
}));
