import { FileEntry } from '@panda/ui';
import { useCallback } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	createBatchUserAuthRequest,
	deleteBatchUser,
	deleteCurrentBatchJob,
	executeBatchUserJob,
	fetchBatchUsersFromProvider,
	getBatchJobs,
	getBatchUsers,
	updateBatchUser,
	updateBatchUsers,
	uploadCSVFile,
} from './actions';
import { reset } from './reducer';
import { BatchUser, ExternalBatchUserProvider } from '../../../api/types/batch-user';

export const useBatchUserImport = () => {
	const dispatch = useDispatch();
	return {
		reset: () => dispatch(reset()),
	};
};

export const useBatchUserImportUsers = () => {
	const dispatch = useDispatch();
	return {
		getBatchUsers: (jobId: string) => dispatch(getBatchUsers(jobId)),
		updateBatchUser: (jobId: string, user: BatchUser) => dispatch(updateBatchUser({ jobId, user })),
		updateBatchUsers: (jobId: string, users: BatchUser[]) =>
			dispatch(updateBatchUsers({ jobId, users })),
		deleteBatchUserById: (jobId: string, userId: string) =>
			dispatch(deleteBatchUser({ jobId, userId })),
		batchUsers: useSelector(state => state.batchUserImport.users.items),
		usersFetching: useSelector(state => state.batchUserImport.users.fetching === 'pending'),
		usersFetched: useSelector(state => state.batchUserImport.users.fetching === 'succeeded'),
		usersUpdating: useSelector(state => state.batchUserImport.users.updating === 'pending'),
		usersDeleting: useSelector(state => state.batchUserImport.users.deletingState),
	};
};

export const useBatchUserImportUser = (userId: string, jobId: string) => {
	const dispatch = useDispatch();
	return {
		deleteBatchUser: () => dispatch(deleteBatchUser({ jobId, userId })),
		userDeleting: useSelector(
			state =>
				state.batchUserImport.users.deletingState.find(
					operationState => operationState.id === userId && operationState.state === 'pending'
				) !== undefined
		),
		userDeleted: useSelector(
			state =>
				state.batchUserImport.users.deletingState.find(
					operationState => operationState.id === userId && operationState.state === 'succeeded'
				) !== undefined
		),
	};
};

export const useBatchUserJobs = () => {
	const dispatch = useDispatch();

	return {
		jobId: useSelector(state => state.batchUserImport.jobs.currentId),
		getBatchJobs: useCallback(() => dispatch(getBatchJobs()), [dispatch]),
		fetchBatchUsersFromProvider: (provider: ExternalBatchUserProvider, code: string) =>
			dispatch(
				fetchBatchUsersFromProvider({
					provider,
					code,
				})
			),
		batchJobs: useSelector(state => state.batchUserImport.jobs.items),
		deleteCurrentBatchJob: (jobId: string) => dispatch(deleteCurrentBatchJob(jobId)),
		deleting: useSelector(state => state.batchUserImport.jobs.deleting === 'pending'),
		deleted: useSelector(state => state.batchUserImport.jobs.deleting === 'succeeded'),
		jobsFetching: useSelector(state => state.batchUserImport.jobs.fetching === 'pending'),
		jobsFetched: useSelector(state => state.batchUserImport.jobs.fetching === 'succeeded'),
		jobsFetchError: useSelector(state => state.batchUserImport.jobs.fetching === 'failed'),
	};
};

export const useBatchUserCSVImport = () => {
	const dispatch = useDispatch();

	return {
		uploading: useSelector(state => state.batchUserImport.uploading === 'pending'),
		uploadError: useSelector(state => state.batchUserImport.uploading === 'failed'),
		wasUploaded: useSelector(state => state.batchUserImport.uploading === 'succeeded'),
		uploadCSV: (file: FileEntry) => dispatch(uploadCSVFile(file)),
	};
};

export const useBatchUserImportAuthRequest = () => {
	const dispatch = useDispatch();

	return {
		createBatchUserAuthRequest: (provider: ExternalBatchUserProvider) =>
			dispatch(createBatchUserAuthRequest(provider)),
		creatingAuthRequest: useSelector(
			state => state.batchUserImport.authRequest.creatingAuthRequest === 'pending'
		),
		authRequestCreated: useSelector(
			state => state.batchUserImport.authRequest.creatingAuthRequest === 'succeeded'
		),
		authRequestFailed: useSelector(
			state => state.batchUserImport.authRequest.creatingAuthRequest === 'failed'
		),
		redirectUrl: useSelector(state => state.batchUserImport.authRequest.redirectUrl),
	};
};

export const useBatchUserImportExecution = () => {
	const dispatch = useDispatch();

	return {
		executeBatchUserJob: (jobId: string) => dispatch(executeBatchUserJob(jobId)),
		executing: useSelector(state => state.batchUserImport.executing === 'pending'),
		executed: useSelector(state => state.batchUserImport.executing === 'succeeded'),
	};
};
