import { handleActions } from '../..';
import { DataUsageState } from './types';
import * as actions from './actions';

const initialState: DataUsageState = {
	items: {},
	fetchedForDevice: [],
	fetchingForDevice: [],
	itemsAggregation: {},
	fetchedAggregationForDevice: [],
	fetchingAggregationForDevice: [],
	itemsSettings: {},
	fetchedSettingsForDevice: [],
	fetchingSettingsForDevice: [],
};

export default handleActions<DataUsageState, PossibleActions<typeof actions>>(
	{
		DATA_USAGE_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchedForDevice: [
				...state.fetchedForDevice.filter(containerId => containerId !== data.containerId),
			],
			fetchingForDevice: [
				...state.fetchingForDevice.filter(containerId => containerId !== data.containerId),
				data.containerId,
			],
		}),
		DATA_USAGE_FETCH_SUCCESS: (state, { data, payload }) => {
			return {
				...state,
				items: { ...state.items, [data.containerId]: payload.items },
				fetchingForDevice: state.fetchingForDevice.filter(
					containerId => containerId !== data.containerId
				),
				fetchedForDevice: [
					...state.fetchedForDevice.filter(containerId => containerId !== data.containerId),
					data.containerId,
				],
			};
		},
		DATA_USAGE_AGGREGATION_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingAggregationForDevice: [
				...state.fetchingAggregationForDevice.filter(
					containerId => containerId !== data.containerId
				),
				data.containerId,
			],
		}),
		DATA_USAGE_AGGREGATION_FETCH_SUCCESS: (state, { data, payload }) => {
			return {
				...state,
				itemsAggregation: {
					...state.itemsAggregation,
					[data.containerId]: payload.items.sort((a, b) => b.month.localeCompare(a.month)),
				},
				fetchingAggregationForDevice: state.fetchingAggregationForDevice.filter(
					containerId => containerId !== data.containerId
				),
				fetchedAggregationForDevice: [
					...state.fetchedAggregationForDevice.filter(
						containerId => containerId !== data.containerId
					),
					data.containerId,
				],
			};
		},
		DATA_USAGE_SETTINGS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingSettingsForDevice: [
				...state.fetchingSettingsForDevice.filter(containerId => containerId !== data.containerId),
				data.containerId,
			],
		}),
		DATA_USAGE_SETTINGS_FETCH_SUCCESS: (state, { data, payload }) => {
			return {
				...state,
				itemsSettings: {
					...state.itemsSettings,
					[data.containerId]: payload,
				},
				fetchingSettingsForDevice: state.fetchingSettingsForDevice.filter(
					containerId => containerId !== data.containerId
				),
				fetchedSettingsForDevice: [
					...state.fetchedSettingsForDevice.filter(containerId => containerId !== data.containerId),
					data.containerId,
				],
			};
		},
	},
	initialState
);
