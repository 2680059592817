type IncidentUpdate = {
	body: string;
	created_at: string;
	display_at: string;
	id: string;
	incident_id: string;
	status: string;
	updated_at: string;
};

type ApiIncident = {
	created_at: string;
	id: string;
	impact: 'none' | 'minor' | 'major' | 'critical' | 'maintenance';
	incident_updates: IncidentUpdate[];
	monitoring_at: string | null;
	name: string;
	page_id: string;
	resolved_at: string | null;
	status: string;
	updated_at: string;
};

export interface ApiSipgateStatus {
	page: {
		id: string;
		name: string;
		url: string;
		updated_at: string;
	};
	status: {
		indicator: 'none' | 'minor' | 'major' | 'critical' | 'maintenance';
		description: string;
	};
	incidents: ApiIncident[];
}

export const isApiSipgateStatus = (response: unknown): response is ApiSipgateStatus => {
	if (response === null || response === undefined) {
		return false;
	}
	const maybeApiStatus = response as ApiSipgateStatus;
	return maybeApiStatus.page !== undefined && maybeApiStatus.status !== undefined;
};
