import React from 'react';
import urls from '@web-apps/service-urls';
import type { BotifyChatWidgetProps } from '@sipgate/botify-chat-widget';
import { RouteComponentProps, withRouter } from 'react-router';
import { fetchDevices, getOwnDevices, isMobileDevice } from '../redux/modules/devices';
import {
	accountManagementPath,
	accountSettingsPath,
	administrationPath,
	autoCreditingPath,
	baseContractPath,
	contactsPath,
	creditingPath,
	devicePoolPath,
	eventlistPath,
	evnPath,
	groupsPath,
	invoicePath,
	locationsPath,
	mobilePortingsPath,
	personalSettingsPath,
	phonenumbersPath,
	portingsPath,
	routingPath,
	userNotificationsPath,
	usersPath,
} from '../routes/paths';
import { connect, ReduxProps } from '../redux';
import { ReduxState } from '../redux/types';
import { mixpanel } from '../third-party/mixpanel';
import auth from '../utils/authenticate/auth';
import { BotifyProps, withBotify } from './BotifyContext';
import { lazyLoadWithoutSpinner } from '../helpers/lazy-loading';
import { WithDialogProps, withDialogs } from '../routes/paths/dialogs';

const BotifyChatWidget = lazyLoadWithoutSpinner<BotifyChatWidgetProps>(() =>
	import('@sipgate/botify-chat-widget').then(module => ({ default: module.BotifyChatWidget }))
);

const mapStateToProps = (state: ReduxState) => ({
	devices: state.devices,
	userinfo: state.userinfo,
});

const mapDispatchToProps = {
	fetchDevices,
};

type Props = BotifyProps &
	RouteComponentProps &
	WithDialogProps &
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class BotifyWidget extends React.Component<Props> {
	private eventCallbacks = {
		onChatOpen: () => mixpanel.track('Chat Widget', { 'Chat Widget Event': 'WIDGET_OPENED' }),
		onChatClose: () => mixpanel.track('Chat Widget', { 'Chat Widget Event': 'WIDGET_CLOSED' }),
	};

	private navigateCallbacks = {
		accountOverview: () => this.props.history.push(accountManagementPath.build()),
		contactList: () => this.props.history.push(contactsPath.build()),
		faxNew: () =>
			this.props.userinfo.fetched &&
			this.props.dialogs.sendFax.open({ webuserId: this.props.userinfo.data.sub }),
		itemizedBill: () => this.props.history.push(evnPath.build()),
		usersOverview: () => this.props.history.push(usersPath.build()),
		settingsPhoneNumbers: () => this.props.history.push(phonenumbersPath.build()),
		settingsBookNumbers: () =>
			this.props.dialogs.bookPhonenumbers.at(phonenumbersPath.build()).open(),
		settingsPortedNumbers: () => this.props.history.push(portingsPath.build()),
		settingsMobileNumberImport: () => this.props.history.push(mobilePortingsPath.build()),
		settingsNewWebuser: () => this.props.dialogs.createSingleUser.open(),
		settingsProducts: () => this.props.history.push(baseContractPath.build()),
		settingsInvoices: () => this.props.history.push(invoicePath.build()),
		settingsLocation: () => this.props.history.push(locationsPath.build()),
		settingsAccount: () => this.props.history.push(administrationPath.build()),
		settingsAccountConfigure: () => this.props.history.push(accountSettingsPath.build()),
		settingsAccountCredit: () => this.props.history.push(creditingPath.build()),
		settingsAccountCreditAuto: () => this.props.history.push(autoCreditingPath.build()),
		settingsUserSetupMisc: () => this.props.history.push(personalSettingsPath.build()),
		devicePool: () => this.props.history.push(devicePoolPath.build()),
		verifyAccount: () => this.props.dialogs.verifyAccount.open(),
		routing: () => this.props.history.push(routingPath.build()),
		cancelAccount: () => this.props.dialogs.cancelAccount.open(),
		eventList: () => this.props.history.push(eventlistPath.build()),
		groupsOverview: () => this.props.history.push(groupsPath.build()),
		notifications: () => this.props.history.push(userNotificationsPath.build()),

		devicePoolBookContingent: async () => {
			if (!this.props.userinfo.fetched) {
				return;
			}

			const userId = this.props.userinfo.data.sub;

			const action = this.props.fetchDevices(userId);
			if (action.type !== 'DEVICES_FETCH') {
				return;
			}

			const result = await action.payload.promise;

			if (typeof result === 'function') {
				const device = getOwnDevices(this.props.devices.items, userId).find(isMobileDevice);

				if (device) {
					this.props.dialogs.mobileContingent.open({ deviceId: device.id });
				}
			} else if (result.type === 'DEVICES_FETCH_SUCCESS') {
				const device = getOwnDevices(result.payload.items, userId).find(isMobileDevice);

				if (device) {
					this.props.dialogs.mobileContingent.open({ deviceId: device.id });
				}
			}
		},
	};

	public render() {
		if (!this.props.userinfo.fetched || this.props.userinfo.data.domain !== 'sipgate.de') {
			return null;
		}

		return (
			<BotifyChatWidget
				deeplink={this.props.botify.deeplink || undefined}
				browserLocation={this.props.location}
				navigateCallbacks={this.navigateCallbacks}
				onWidgetEvent={this.eventCallbacks}
				socketUrl={urls.botify.socketUrl}
				socketPath="/chat/session/socket.io"
				httpBackendUrl={urls.botify.socketUrl}
				getToken={() => auth.getToken().then(tok => tok.access)}
				getTokenIntervalMs={997}
				invalidateToken={() => auth.refreshToken()}
				profileAvatar="https://static.sipgate.com/botify/images/avatars/BO-transparent-64.gif"
				welcomeMessages={[]}
				launchIntent
				launcherGreetingsDelayMs={5000}
				locationChangeEventsActiveSeconds={600}
				styleTheme={{
					conversationWindowHeightLarge: '752px',
					conversationWindowWidthLarge: '848px',
				}}
				userInfo={{
					customerId: this.props.userinfo.data.masterSipId,
					userId: this.props.userinfo.data.sub,
				}}
			/>
		);
	}
}

export default withBotify(
	withDialogs(withRouter(connect(mapStateToProps, mapDispatchToProps)(BotifyWidget)))
);
