import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchDevices } from './actions';
import { getOwnDevices, isAppDevice } from './selectors';

export const useUserDevices = (userId: string) => {
	const devicesState = useSelector(state => state.devices);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchDevices(userId));
	}, [dispatch, userId]);

	const devices = getOwnDevices(devicesState.items, userId);
	const registerDevices = devices.filter(device => device.type === 'REGISTER');

	return {
		devices,
		devicesFetchedForUser: devicesState.fetchedForUser.includes(userId),
		registerDevices,
	};
};

export const useAllDevices = (userId: string | undefined) => {
	const devicesState = useSelector(state => state.devices);
	const dispatch = useDispatch();

	useEffect(() => {
		if (userId) {
			dispatch(fetchDevices(userId));
		}
	}, [dispatch, userId]);

	return {
		data: devicesState.items.filter(device => !isAppDevice(device)),
		fetched: userId && devicesState.fetchedForUser.includes(userId),
	};
};
