import { useEffect } from 'react';
import { SliceData, useAction } from '../../index';
import { createSipgateAppNotification, fetchSipgateAppNotification } from './actions';
import { useDispatch, useSelector } from '../../utils/wrapper';

export const useSipgateAppNotification = (): SliceData<{ canNotify: boolean }> => {
	const { fetched, canNotify } = useSelector(state => state.sipgateAppNotification);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchSipgateAppNotification());
	}, [dispatch]);

	if (!fetched) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: { canNotify },
		fetched: true,
	};
};

export const useSipgateAppNotificationActions = () => ({
	createSipgateAppNotification: useAction(createSipgateAppNotification),
});
