import { createSlice } from '@reduxjs/toolkit';
import {
	bookSipgateIo,
	cancelSipgateIo,
	fetchSipgateIo,
	revokeSipgateIoCancellation,
	updateSipgateIoSettings,
} from './actions';
import { SipgateIoState } from './types';

export const reducer = createSlice({
	name: 'sipgateIo',
	initialState: {
		data: null,
		fetched: false,
		fetching: true,
	} as SipgateIoState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(fetchSipgateIo.pending, state => ({
				...state,
				fetching: true,
			}))
			.addCase(fetchSipgateIo.fulfilled, (state, action) => ({
				...state,
				fetched: true,
				fetching: false,
				data: {
					cancellation: action.payload.cancellation,
					nextCancellationDate: action.payload.nextCancellationDate,
					optionId: action.payload.optionId,
					settings: action.payload.settings,
					cost: {
						amount: {
							amount: action.payload.price.amountInTenThousandths,
							fraction: 10_000,
							currency: action.payload.price.currency,
						},
						interval: 'monthly',
						isNetto: action.payload.price.net,
					},
				},
			}))
			.addCase(bookSipgateIo.fulfilled, (state, action) => ({
				...state,
				data: {
					cancellation: action.payload.cancellation,
					nextCancellationDate: action.payload.nextCancellationDate,
					optionId: action.payload.optionId,
					settings: action.payload.settings,
					cost: {
						amount: {
							amount: action.payload.price.amountInTenThousandths,
							fraction: 10_000,
							currency: action.payload.price.currency,
						},
						interval: 'monthly',
						isNetto: action.payload.price.net,
					},
				},
			}))
			.addCase(updateSipgateIoSettings.pending, (state, action) => ({
				...state,
				data: state.data
					? {
							...state.data,
							settings: action.meta.arg,
						}
					: null,
			}))
			.addCase(cancelSipgateIo.fulfilled, (state, action) => ({
				...state,
				data: state.data
					? {
							...state.data,
							cancellation: action.payload,
						}
					: null,
			}))
			.addCase(revokeSipgateIoCancellation.pending, state => ({
				...state,
				data: state.data
					? {
							...state.data,
							cancellation: null,
						}
					: null,
			})),
}).reducer;
