import { ProductOption } from './types';

export const selectContingentType = (contingent: ProductOption): 'mobile' | 'landline' | null => {
	if (contingent.name.includes('mobile')) {
		return 'mobile';
	}

	if (contingent.name.includes('landline')) {
		return 'landline';
	}

	return null;
};
