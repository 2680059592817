import classnames from 'classnames';
import { debounce } from 'lodash';
import React, { createContext } from 'react';
import { Redirect, Route, RouteComponentProps, withRouter } from 'react-router';

import Header from '../accountNavigation/Header';
import BotifyLinkHandler from '../botify/BotifyLinkHandler';
import BotifyWidget from '../botify/BotifyWidget';
import IdentityVerificationCageDialog from '../components/dialogs/identityverification/IdentityVerificationCageDialog';
import LoadingBar from '../components/LoadingBar';
import ClickToDialSnackbar from '../components/messages/ClickToDialSnackbar';
import EventSnackbar from '../components/messages/EventSnackbar';
import InsufficientFundsSnackbar from '../components/messages/InsufficientFundsSnackbar';
import InvalidAccountStateSnackbar from '../components/messages/InvalidAccountStateSnackbar';
import Pusher from '../components/Pusher';
import TourGuide from '../components/TourGuide/TourGuide';
import { BreadcrumbProvider } from '../foundation/breadcrumb';
import { ViewSwitch } from '../foundation/view';
import { ensureVoicemail } from '../helpers/ensure-extension/voicemail';
import { lazyLoad } from '../helpers/lazy-loading';
import { TranslateProps, withTranslate } from '../helpers/withTranslations';
import { SideNavigation } from '../navigation/SideNavigation/SideNavigation';
import SipgateAppRedirect from '../redirects/SipgateAppRedirect';
import { connect, ReduxProps } from '../redux';
import {
	fetchEvents,
	forceFetchEvents,
	isAccountActivationNeeded,
	isBatchUserCreationProcessFailed,
	isBatchUserCreationProcessSucceeded,
	isBusinessVerificationFailed,
	isBusinessVerificationSucceeded,
	isSatelliteEnterpriseSupport,
} from '../redux/modules/events';
import { fetchIdentityVerification } from '../redux/modules/identityVerification';
import { hasRestriction } from '../redux/modules/restrictions';
import { fetchUserInfo } from '../redux/modules/userinfo';
import { fetchAccount } from '../redux/slices/account';
import { fetchAppProperties } from '../redux/slices/appProperties';
import { fetchBalance } from '../redux/slices/balance';
import { fetchLinks } from '../redux/slices/links';
import { ReduxState } from '../redux/types';
import {
	accountManagementPath,
	acdPath,
	acdsPath,
	acdTeamLeadDashboardPath,
	acdTeamLeadOverviewPath,
	affiliatePath,
	apiClientPath,
	apiClientsPath,
	appPath,
	autoCreditingPath,
	baseContractPath,
	blocklistPath,
	conferenceRoomPath,
	conferenceRoomsPath,
	contactDetailPath,
	contactsPath,
	creditingPath,
	devicePoolPath,
	eventlistPath,
	groupPath,
	groupsPath,
	hardwarePath,
	isAccountSection,
	ivrPath,
	ivrsPath,
	locationsPath,
	microsoftTeamsPath,
	mobileTelephonyDevicePath,
	mobileTelephonyPath,
	personalAccessTokenPath,
	personalSettingsPath,
	phonenumbersPath,
	presencePath,
	reportPath,
	reportsPath,
	routingPath,
	samlSsoPath,
	sipgateIoPath,
	telephonySettingsPath,
	trunkPath,
	trunksPath,
	unlinkedDevicePath,
	unlinkedDevicesPath,
	userNotificationsPath,
	userPath,
	usersPath,
} from '../routes/paths';
import { DialogRedirect, WithDialogProps, withDialogs } from '../routes/paths/dialogs';
import { pusher } from '../third-party/pusher';
import { ariaId } from '../utils/a11y/aria-id';
import auth from '../utils/authenticate/auth';
import MediaQueryDecorator, { MediaQueryProps } from '../utils/MediaQueryDecorator';
import { getAccountMenuLinks } from '../utils/sideNavigation/accountMenu';
import { getUserMenuLinks } from '../utils/sideNavigation/userMenu';
import HistoryOverview from '../views/HistoryOverview';
import classes from './AuthenticatedLayout.scss';
import { PandaOneColumnLayout } from './PandaOneColumnLayout';

const LoadablePresenceContainer = lazyLoad(() => import('../views/presence/WebBlfView'));
const LoadableBlocklist = lazyLoad(() => import('../views/blocklist/Blocklist'));
const LoadablePersonalSettings = lazyLoad(
	() => import('../views/users/user/settings/UserSettingsView')
);
const LoadablePersonalAccessToken = lazyLoad(
	() => import('../views/personal-access-token/PersonalAccessToken')
);

const LoadableUserNotificationsView = lazyLoad(
	() => import('../views/user-notifications/UserNotificationsView')
);

const LoadableUserView = lazyLoad(() => import('../views/users/user/UserView'));
const LoadableUsers = lazyLoad(() => import('../views/users/overview/UsersOverview'));

const LoadableApiClientsOveriew = lazyLoad(() => import('../views/api-clients/ApiClientsOverview'));
const LoadableApiClientView = lazyLoad(() => import('../views/api-clients/ApiClientView'));

const LoadableTrunksOverview = lazyLoad(
	() => import('../views/trunks/trunksOverview/TrunksOverviewContainer')
);
const LoadableTrunkView = lazyLoad(() => import('../views/trunks/trunkView/TrunkView'));

const LoadableLocationsView = lazyLoad(() => import('../views/locations/LocationsView'));

const LoadableGroups = lazyLoad(() => import('../views/groups/GroupsOverview'));
const LoadableGroupView = lazyLoad(() => import('../views/groups/GroupView'));
const LoadableUnlinkedDevicesOverview = lazyLoad(
	() => import('../views/unlinked-devices/UnlinkedDevicesOverview')
);

const LoadableAcdsOverview = lazyLoad(() => import('../views/acds/AcdsOverview'));
const LoadableAcdView = lazyLoad(() => import('../views/acds/AcdView'));
const LoadableAcdTeamLeadDashboardView = lazyLoad(
	() => import('../views/acds/teamLead/TeamLeadDashboard')
);
const LoadableUnlinkedDeviceView = lazyLoad(
	() => import('../views/unlinked-devices/UnlinkedDeviceView')
);
const LoadableAdminDevicePoolLayout = lazyLoad(
	() => import('../views/mobile-telephony/AdminDevicePoolLayout')
);
const LoadableTeamView = lazyLoad(() => import('../teamweb/TeamView'));
const LoadableMicrosoftTeamsIntegrationView = lazyLoad(
	() => import('../views/microsoft-teams/MicrosoftTeamsIntegrationView')
);
const LoadableHardwareView = lazyLoad(() => import('../views/hardware/HardwareView'));
const LoadableDevicePoolLayout = lazyLoad(() => import('../views/device-pool/DevicePoolLayout'));
const LoadableDevicePoolListView = lazyLoad(
	() => import('../views/mobile-telephony/DevicePoolListView')
);
const LoadableAccountManagement = lazyLoad(() => import('../views/AccountManagementOverview'));
const LoadableAccountDashboard = lazyLoad(() => import('../views/AccountDashboard'));
const LoadableUserRoutingView = lazyLoad(() => import('../views/routing/UserRoutingView'));
const LoadableConferenceRoomOverview = lazyLoad(
	() => import('../views/conference-room/ConferenceRoomOverview')
);
const LoadableConferenceRoomView = lazyLoad(
	() => import('../views/conference-room/ConferenceRoomView')
);
const LoadableIvrsOverview = lazyLoad(() => import('../views/ivr/IvrsOverview'));
const LoadableSamlSsoOverview = lazyLoad(() => import('../views/saml-sso/SamlSsoOverview'));

const LoadableTelephonySettingsView = lazyLoad(
	() => import('../views/telephony-settings/TelephonySettingsView')
);

const EmptyComponent = () => null;
const LoadableVoicemailTeamView = ensureVoicemail(LoadableTeamView);

const LoadableReportsOverview = lazyLoad(() => import('../views/reports/ReportsOverview'));
const LoadableReportView = lazyLoad(() => import('../views/reports/ReportView'));
const LoadableAdminstrationView = lazyLoad(
	() => import('../views/administration/AdministrationView')
);
const LoadableAffiliateView = lazyLoad(() => import('../views/affiliate/AffiliateView'));

const LoadableSipgateIoView = lazyLoad(() => import('../views/sipgate-io/SipgateIoView'));
const LoadableIVRDetailView = lazyLoad(() => import('../views/ivr/IvrDetailView'));

const LoadablePhonenumbersOverview = lazyLoad(
	() => import('../views/phonenumbers/PhonenumbersOverview')
);

const LoadableContactsOverview = lazyLoad(() => import('../views/contacts/ContactsOverview'));
const LoadableContactView = lazyLoad(() => import('../views/contacts/contact/ContactView'));

export interface AuthenticatedLayoutContextType {
	onToggleNavigation: () => void;
	onNavigation: () => void;
	mainNavigationId: string;
	isOpen: boolean;
	isMobile: boolean;
	hasBeenNavigated: boolean;
}

export const AuthenticatedLayoutContext = createContext<AuthenticatedLayoutContextType>({
	onToggleNavigation: () => {},
	onNavigation: () => {},
	mainNavigationId: 'mainNav',
	isOpen: false,
	isMobile: false,
	hasBeenNavigated: false,
});

interface State {
	navigationOpen: boolean;
	hasBeenNavigated: boolean;
}

const mapStateToProps = (state: ReduxState) => ({
	appProperties: state.appProperties,
	userinfo: state.userinfo,
	account: state.account,
	events: state.events,
	links: state.links,
	restrictions: state.restrictions,
});

const mapDispatchToProps = {
	fetchAppProperties,
	fetchIdentityVerification,
	fetchBalance,
	fetchUserInfo,
	fetchAccount,
	fetchEvents,
	fetchLinks,
	forceFetchEvents,
};

interface ExternalProps {
	webuserId: string;
}

type Props = ExternalProps &
	WithDialogProps &
	TranslateProps &
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
	MediaQueryProps &
	RouteComponentProps;

class AuthenticatedLayout extends React.Component<Props, State> {
	public state = {
		navigationOpen: this.props.mediaQueries.isXLargeDevice(),
		hasBeenNavigated: false,
	};

	private ref = React.createRef<HTMLDivElement>();

	private mainNavigationId = ariaId();

	public componentDidMount() {
		this.props.fetchUserInfo();
		this.props.fetchAppProperties();
		this.props.fetchEvents();
		this.props.fetchAccount();
		this.props.fetchLinks();

		if (this.props.appProperties.fetched) {
			this.props.fetchIdentityVerification(this.props.appProperties);
		}

		if (this.props.events.fetched) {
			this.checkAccountVerificationStatus();
			this.checkBatchUserCreationStatus();
		}

		this.registerEventPushHandler(this.props.webuserId);
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.events.fetched && this.props.events !== prevProps.events) {
			this.checkAccountVerificationStatus();
			this.checkBatchUserCreationStatus();
		}

		if (this.props.appProperties.fetched && !prevProps.appProperties.fetched) {
			this.props.fetchIdentityVerification(this.props.appProperties);
		}

		if (
			!this.props.mediaQueries.isXLargeDevice() &&
			prevProps.mediaQueries.isXLargeDevice() &&
			this.state.navigationOpen
		) {
			this.setState({ navigationOpen: false });
		}

		if (
			this.naviClosed(this.props.location.pathname) &&
			isAccountSection(prevProps.location.pathname) &&
			this.state.navigationOpen &&
			prevState.navigationOpen
		) {
			this.setState({ navigationOpen: false });
		}
	}

	public componentWillUnmount() {
		this.unbindPusher();
		this.unbindPusher = () => {};
	}

	private unbindPusher = () => {};

	private naviClosed(pathname: string) {
		return pathname.match(`/routing`) !== null;
	}

	private checkAccountVerificationStatus() {
		if (isAccountActivationNeeded(this.props.events)) {
			this.props.dialogs.unverified.open();
		}

		const businessVerificationFailure = isBusinessVerificationFailed(this.props.events);
		if (businessVerificationFailure) {
			this.props.dialogs.businessVerificationFailed.open(businessVerificationFailure);
		}

		if (isBusinessVerificationSucceeded(this.props.events)) {
			this.props.dialogs.businessVerificationSucceeded.open();
		}

		if (isSatelliteEnterpriseSupport(this.props.events)) {
			this.props.dialogs.satelliteEnterpriseSupport.open();
		}
	}

	// we don't check here for currently running batch jobs, as we don't want to show the dialog every time the user navigates to a new page
	private checkBatchUserCreationStatus() {
		if (isBatchUserCreationProcessFailed(this.props.events)) {
			this.props.dialogs.batchImportUserExecutionFailed.open();
		}

		if (isBatchUserCreationProcessSucceeded(this.props.events)) {
			this.props.dialogs.batchImportUserExecutionSucceeded.open();
		}
	}

	private registerEventPushHandler = (webuserId: string) => {
		const channel = `${this.props.userinfo.data?.masterSipId}${webuserId}`;
		const debouncedFetch = debounce(() => this.props.forceFetchEvents(), 150);

		pusher.then(client => {
			this.unbindPusher = client.listen(channel, 'reloadEvents', () => {
				debouncedFetch();
			});
		});
	};

	private onNavigation = () => {
		this.setState({ hasBeenNavigated: true });
		if (!this.props.mediaQueries.isXLargeDevice()) {
			this.setState({ navigationOpen: false });
		}
	};

	private onOpenNavigation = () => {
		this.setState({ navigationOpen: true });
		this.props.fetchBalance(true);
	};

	private onCloseNavigation = () => {
		this.setState({ navigationOpen: false });
	};

	private renderRoutes = () => {
		let dashboard = LoadableAccountManagement;

		if (
			this.props.userinfo.data?.flags.includes('ADMIN_CONTROL_PANEL_NEO') &&
			hasRestriction(this.props.restrictions.items, 'CAN_USE_ACD', this.props.webuserId)
		) {
			dashboard = LoadableAccountDashboard;
		}

		return (
			<>
				<ViewSwitch>
					{
						// This route is printed(!!) onto the letters sent to customers as part of the address
						// verification process.
					}
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/verify"
						to={this.props.dialogs.verifyAccount.at(eventlistPath.build()).build()}
					/>
					<Route
						path="/botify/:deeplinkId(.*)"
						render={({ match }) => <BotifyLinkHandler deeplink={match.params.deeplinkId} />}
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/forwarding/index/(.*)"
						to={routingPath.build()}
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/forwarding/department/(.*)"
						to={groupsPath.build()}
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/forwarding/unlinked/(.*)"
						to={unlinkedDevicesPath.build()}
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/voicemail/index(.*)"
						to={routingPath.build()}
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/conferenceroom"
						to={conferenceRoomsPath.build()}
						exact
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/ivr"
						to={ivrsPath.build()}
						exact
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/trunking"
						to={trunksPath.build()}
						exact
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/trunking/detailtrunk"
						to={trunksPath.build()}
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/design"
						to={telephonySettingsPath.build()}
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/location"
						to={locationsPath.build()}
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/configure"
						to={userNotificationsPath.build()}
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/account/databalance"
						to={mobileTelephonyPath.build()}
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/customisation"
						to={telephonySettingsPath.build()}
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/settings/hardware"
						to={hardwarePath.build()}
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from={`/fax/:view/:draftId(\\d+)?`}
						to={this.props.dialogs.sendFax
							.at(eventlistPath.build())
							.build({ webuserId: this.props.webuserId })}
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/products/changecontract(.*)"
						to={baseContractPath.build()}
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/products"
						to={accountManagementPath.build()}
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/account/creditaccount/type/auto(.*)"
						to={autoCreditingPath.build()}
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/account"
						to="/administration/statement"
						exact
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/account/creditaccount(.*)"
						to={creditingPath.build()}
					/>

					<Route
						path="/team(/settings/invoices)(/.*)?"
						render={({ match, location }) => (
							<Redirect
								/* eslint-disable-next-line no-restricted-syntax */
								to={{
									hash: location.hash,
									search: location.search,
									pathname: `/administration/invoices${match.params[0]}${match.params[1] || ''}`,
								}}
							/>
						)}
					/>

					<Route
						path="/team(/settings/account/itemizedbill)(/.*)?"
						render={({ match, location }) => (
							<Redirect
								/* eslint-disable-next-line no-restricted-syntax */
								to={{
									hash: location.hash,
									search: location.search,
									pathname: `/administration/evn${match.params[0]}${match.params[1] || ''}`,
								}}
							/>
						)}
					/>

					<Route
						path="/team(/settings/account/configure)(/.*)?"
						render={({ match, location }) => (
							<Redirect
								/* eslint-disable-next-line no-restricted-syntax */
								to={{
									hash: location.hash,
									search: location.search,
									pathname: `/administration/settings${match.params[0]}${match.params[1] || ''}`,
								}}
							/>
						)}
					/>

					<Route
						path="/team(/settings/account)(/.*)?"
						render={({ match, location }) => (
							<Redirect
								/* eslint-disable-next-line no-restricted-syntax */
								to={{
									hash: location.hash,
									search: location.search,
									pathname: `/administration/crediting${match.params[0]}${match.params[1] || ''}`,
								}}
							/>
						)}
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/phonenumbers"
						to={phonenumbersPath.build()}
						exact
					/>
					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/booknumbers"
						to={this.props.dialogs.bookPhonenumbers.at(phonenumbersPath.build()).build()}
					/>

					<Redirect
						/* eslint-disable-next-line no-restricted-syntax */
						from="/team/settings/numbers/new"
						to={this.props.dialogs.bookPhonenumbers.at(phonenumbersPath.build()).build()}
					/>

					<Route
						path="/book-app"
						render={() => (
							<SipgateAppRedirect
								webuserId={this.props.webuserId}
								requestParams={this.props.location.search}
							/>
						)}
					/>
					<Route
						path="/book-app-bulk"
						render={() => (
							<SipgateAppRedirect
								webuserId={this.props.webuserId}
								requestParams={this.props.location.search}
								isBulk
							/>
						)}
					/>
					<Route path="/blank/(.+)" component={EmptyComponent} />

					<HistoryOverview
						path={eventlistPath.path}
						name={this.props.translate('EVENTLISTVIEW_TITLE')}
						webuserId={this.props.webuserId}
					/>

					<Route
						path={presencePath.path}
						render={() => <LoadablePresenceContainer webuserId={this.props.webuserId} />}
					/>

					<Route
						path={blocklistPath.path}
						render={() => <LoadableBlocklist webuserId={this.props.webuserId} />}
					/>
					<Route
						path={personalSettingsPath.path}
						render={() => (
							<PandaOneColumnLayout>
								<LoadablePersonalSettings
									path={personalSettingsPath.path}
									webuserId={this.props.webuserId}
									name={this.props.translate('PERSONAL_SETTINGS')}
								/>
							</PandaOneColumnLayout>
						)}
					/>
					<Route
						path={personalAccessTokenPath.path}
						render={() => <LoadablePersonalAccessToken webuserId={this.props.webuserId} />}
					/>
					<Route
						path={userNotificationsPath.path}
						render={() => (
							<PandaOneColumnLayout>
								<LoadableUserNotificationsView />
							</PandaOneColumnLayout>
						)}
					/>
					<LoadableUsers
						path={`${usersPath.path}(/dialog/.*)?`}
						name={this.props.translate('USER_ADMINISTRATION')}
					>
						<LoadableUserView path={userPath.path} />
					</LoadableUsers>
					<LoadableApiClientsOveriew
						path={`${apiClientsPath.path}(/dialog/.*)?`}
						name={this.props.translate('API_CLIENTS')}
					>
						<LoadableApiClientView path={apiClientPath.path} />
					</LoadableApiClientsOveriew>
					<LoadableTrunksOverview
						path={trunksPath.path}
						name={this.props.translate('ACCOUNT_NAVIGATION_TRUNKS')}
					>
						<LoadableTrunkView path={trunkPath.path} />
					</LoadableTrunksOverview>
					<Route path={locationsPath.path} component={LoadableLocationsView} />
					<Route path={samlSsoPath.path} component={LoadableSamlSsoOverview} />

					<LoadableGroups
						path={groupsPath.path}
						name={this.props.translate('GROUP_ADMINISTRATION')}
					>
						<LoadableGroupView path={groupPath.path} webuserId={this.props.webuserId} />
					</LoadableGroups>

					<LoadableAcdsOverview
						path={acdsPath.path}
						name={this.props.translate('CHANNEL_ADMINISTRATION')}
					>
						<LoadableAcdView path={acdPath.path} />
					</LoadableAcdsOverview>
					<LoadableAcdsOverview
						path={acdTeamLeadOverviewPath.path}
						name={this.props.translate('CHANNEL_ADMINISTRATION')}
						teamLead
					>
						<LoadableAcdTeamLeadDashboardView path={acdTeamLeadDashboardPath.path} />
					</LoadableAcdsOverview>

					<LoadableUnlinkedDevicesOverview
						path={unlinkedDevicesPath.path}
						name={this.props.translate('PUBLIC_PHONES_ADMINISTRATION')}
					>
						<LoadableUnlinkedDeviceView path={unlinkedDevicePath.path} />
					</LoadableUnlinkedDevicesOverview>

					<Route
						path={mobileTelephonyDevicePath.path}
						render={({ match }) => (
							<LoadableAdminDevicePoolLayout
								deviceId={match.params.deviceId}
								webuserId={match.params.webuserId}
							/>
						)}
					/>
					<Route
						path={mobileTelephonyPath.path}
						render={() => <LoadableDevicePoolListView webuserId={this.props.webuserId} />}
					/>
					<Route
						exact
						path="/team(/settings/voicemail.*?)(/)?(/dialog/.*|/dialog)?"
						render={({ match }) => (
							<LoadableVoicemailTeamView
								webuserId={this.props.webuserId}
								iframePath={match.params[0] || ''}
								trailingSlash={!!match.params[1]}
								pathPrefix="/team"
							/>
						)}
					/>
					<Route
						exact
						path="/team(/configure/filter.*?)(/)?(/dialog/.*|/dialog)?"
						render={({ match }) => (
							<LoadableVoicemailTeamView
								webuserId={this.props.webuserId}
								iframePath={match.params[0] || ''}
								trailingSlash={!!match.params[1]}
								pathPrefix="/team"
							/>
						)}
					/>
					<Route
						exact
						path="/team(/settings/setup/miscellaneous.*?)(/)?(/dialog/.*|/dialog)?"
						render={({ match }) => (
							<div className={classes.igelPadding}>
								<LoadableTeamView
									webuserId={this.props.webuserId}
									iframePath={match.params[0] || ''}
									trailingSlash={!!match.params[1]}
									pathPrefix="/team"
								/>
							</div>
						)}
					/>
					<Route
						exact
						path="/team(/.+?)(/)?(/dialog/.*|/dialog)?"
						render={({ match }) => (
							<LoadableTeamView
								webuserId={this.props.webuserId}
								iframePath={match.params[0] || ''}
								trailingSlash={!!match.params[1]}
								pathPrefix="/team"
							/>
						)}
					/>
					<Route
						path={hardwarePath.path}
						render={({ match }) => <LoadableHardwareView phoneId={match.params.phoneId} />}
					/>
					<Route path={microsoftTeamsPath.path} component={LoadableMicrosoftTeamsIntegrationView} />
					<Route
						path={devicePoolPath.path}
						render={() => <LoadableDevicePoolLayout webuserId={this.props.webuserId} />}
					/>
					<Route
						path={routingPath.path}
						render={() => <LoadableUserRoutingView webuserId={this.props.webuserId} />}
					/>
					<Route path={telephonySettingsPath.path} component={LoadableTelephonySettingsView} />

					<LoadableConferenceRoomOverview
						path={conferenceRoomsPath.path}
						name={this.props.translate('ACCOUNT_NAVIGATION_CONFERENCEROOM')}
					>
						<LoadableConferenceRoomView path={conferenceRoomPath.path} />
					</LoadableConferenceRoomOverview>

					<LoadableIVRDetailView path={ivrPath.path} />
					<Route path={ivrsPath.path} component={LoadableIvrsOverview} />

					<LoadableAdminstrationView
						path="/administration"
						name={this.props.translate('ACCOUNT_NAVIGATION_ACCOUNT')}
					/>

					<LoadableAffiliateView
						path={affiliatePath.path}
						name={this.props.translate('ACCOUNT_NAVIGATION_AFFILIATE')}
					/>

					<LoadablePhonenumbersOverview
						path={phonenumbersPath.path}
						name={this.props.translate('ACCOUNT_NAVIGATION_PHONENUMBERS')}
					/>
					<LoadableContactsOverview
						path={`${contactsPath.path}(/dialog/.*)?`}
						name={this.props.translate('CONTACTS')}
					>
						<LoadableContactView path={contactDetailPath.path} />
					</LoadableContactsOverview>

					<Route path={accountManagementPath.path} component={dashboard} />
					<Route path={appPath.path} render={() => <Redirect to={usersPath.path} />} />

					<LoadableReportsOverview
						path={reportsPath.path}
						name={this.props.translate('REPORTS_HUB')}
					>
						<LoadableReportView path={reportPath.path} />
					</LoadableReportsOverview>

					<LoadableSipgateIoView
						path={sipgateIoPath.path}
						name={this.props.translate('SIPGATE_IO')}
					/>

					<DialogRedirect location={this.props.location} />
				</ViewSwitch>

				<IdentityVerificationCageDialog />
			</>
		);
	};

	private renderNavigation = () => {
		if (
			this.props.location.pathname === '/' ||
			!this.props.links.fetched ||
			!this.props.restrictions ||
			!this.props.appProperties.fetched
		) {
			return null;
		}

		const userMenu = getUserMenuLinks(
			this.props.links.items,
			this.props.restrictions,
			this.props.appProperties
		);

		const accountMenu = getAccountMenuLinks(
			this.props.links.items,
			this.props.restrictions,
			this.props.webuserId,
			this.props.userinfo
		);

		const canUsePBXFeatures = hasRestriction(this.props.restrictions.items, 'CAN_USE_PBX_FEATURES');

		return (
			<SideNavigation
				menu={
					isAccountSection(this.props.history.location.pathname) || !canUsePBXFeatures
						? accountMenu
						: userMenu
				}
			/>
		);
	};

	public render() {
		return (
			<AuthenticatedLayoutContext.Provider
				value={{
					onToggleNavigation: this.state.navigationOpen
						? this.onCloseNavigation
						: this.onOpenNavigation,
					onNavigation: this.onNavigation,
					mainNavigationId: this.mainNavigationId,
					isOpen: this.state.navigationOpen,
					isMobile: !this.props.mediaQueries.isXLargeDevice(),
					hasBeenNavigated: this.state.hasBeenNavigated,
				}}
			>
				<BreadcrumbProvider>
					<div
						className={classnames(classes.rootContainer, {
							[classes.affiliateImpersonation]:
								auth.getAuthenticationType() === 'AFFILIATE_IMPERSONATION',
							[classes.helpdeskImpersonation]: auth.getAuthenticationType() === 'IMPERSONATION',
						})}
						data-test-selector="app-web-authenticated"
						ref={this.ref}
					>
						<a href="#main-content" className={classnames(classes.skipToContentLink, 'sr-only')}>
							{this.props.translate('MAIN_NAV_SKIP_LINK')}
						</a>

						{!this.state.navigationOpen && (
							<button
								type="button"
								onClick={this.state.navigationOpen ? this.onCloseNavigation : this.onOpenNavigation}
								className={classnames(classes.skipToContentLink, 'sr-only')}
								aria-expanded={this.state.navigationOpen}
								aria-controls="aria-0"
							>
								{this.props.translate('ACCOUNT_NAVIGATION_TOGGLE_ARIA_BUTTON')}
							</button>
						)}

						{this.props.userinfo.fetched && (
							<Pusher
								masterSipid={this.props.userinfo.data.masterSipId}
								userId={this.props.webuserId}
							/>
						)}
						<LoadingBar />

						<div className={classes.container}>
							{this.renderNavigation()}
							<div className={classes.stickyContainer}>
								<Header />
								<main id="main-content" className={classes.childContainer}>
									<div className={classes.content}>{this.renderRoutes()}</div>
								</main>
							</div>
						</div>
						<EventSnackbar />
						<InsufficientFundsSnackbar />
						<InvalidAccountStateSnackbar />
						<ClickToDialSnackbar />
						<TourGuide />
						<BotifyWidget />
					</div>

					{auth.getImpersonated() ? (
						<div
							className={classnames(classes.impersonationOverlay, {
								[classes.affiliateImpersonationOverlay]:
									auth.getAuthenticationType() === 'AFFILIATE_IMPERSONATION',
								[classes.helpdeskImpersonationOverlay]:
									auth.getAuthenticationType() === 'IMPERSONATION',
							})}
						>
							{' '}
							{this.props.translate.markdown.inline(
								'AFFILIATE_IMPERSONATION_INFORMATION_TEXT',
								this.props.account.fetched ? this.props.account.company : '',
								this.props.userinfo.data?.masterSipId || ''
							)}
						</div>
					) : (
						<></>
					)}
				</BreadcrumbProvider>
			</AuthenticatedLayoutContext.Provider>
		);
	}
}

export default withDialogs(
	withTranslate(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(MediaQueryDecorator(withRouter(AuthenticatedLayout)))
	)
);
