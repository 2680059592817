import { DownloadLink, Button } from '@panda/ui';
import moment from 'moment-timezone';
import React from 'react';

import { isMobileNumber, localizeNumber } from '@web-apps/phonenumbers-utils';
import { CallHistoryEntry, Recording } from '../../../api/types/events';
import { SystemLabel } from '../../../components/SystemLabel';
import { DeprecatedMarkdown } from '../../../helpers/Markdown';
import { Translate } from '../../../redux/slices/translations';
import { AudioPlayer } from '../AudioPlayer';
import { C2DButton } from '../buttons/C2DButton';
import { NormalizedEndpoint } from '../normalize/endpoint';
import { NormalizedEvent } from '../normalize/events';
import {
	formatDuration,
	formatExtension,
	getLocalizedEventSource,
	getLocalizedEventTarget,
} from '../util';
import classes from './CallEvent.scss';
import { Event } from './Event';
import eventClasses from './Event.scss';
import { SipgateDomain } from '../../../redux/modules/userinfo';

interface Props {
	domain: SipgateDomain;
	event: NormalizedEvent<CallHistoryEntry>;
	translate: Translate;
	playingRecordingId: string | null;
	onRead: (eventIds: string[]) => void;
	onClick2Dial: (number: string) => void;
	onAnswerWithSms?: (number: string) => void;
	onNewContact: (number: string) => void;
	onAddNumberToContact?: (number: string) => void;
	onNoteChange: (eventId: string, note: string) => void;
	onPlayPause: (eventId: string, recordingId: string, playing: boolean) => void;
	onSelection: (eventId: string) => void;
	onBlacklist?: (number: string, blacklisted: boolean) => void;
	onStar: (eventId: string, starred: boolean) => void;
	onOpenBlacklist: () => void;
}

export class CallEvent extends React.PureComponent<Props> {
	private onNewContact = () => {
		if (this.props.event.them.number) {
			this.props.onNewContact(this.props.event.them.number);
		}
	};

	private onAddNumberToContact = () => {
		if (this.props.onAddNumberToContact && this.props.event.them.number) {
			this.props.onAddNumberToContact(this.props.event.them.number);
		}
	};

	private onClose = () => {
		for (const recording of this.props.event.originalEvent.recordings) {
			this.props.onPlayPause(this.props.event.originalEvent.id, recording.id, false);
		}
	};

	private onBlacklist = () => {
		if (this.props.onBlacklist && this.props.event.them.rawNumber) {
			this.props.onBlacklist(this.props.event.them.rawNumber, true);
		}
	};

	private onUnblacklist = () => {
		if (this.props.onBlacklist && this.props.event.them.blacklistEntry) {
			this.props.onBlacklist(this.props.event.them.blacklistEntry.phoneNumber, false);
		}
	};

	private renderDetailHeadline() {
		const direction = this.props.event.incoming
			? this.props.translate('INCOMING_CALL')
			: this.props.translate('OUTGOING_CALL');

		const formattedDate = moment(this.props.event.originalEvent.dateCreated).calendar();
		const lowercasedFormattedDate = formattedDate[0].toLocaleLowerCase() + formattedDate.slice(1);

		if (this.props.event.originalEvent.duration > 0) {
			return (
				<p>
					<span className={classes.bold}>{direction}</span> {lowercasedFormattedDate},{' '}
					{formatDuration(this.props.event.originalEvent.duration)}
				</p>
			);
		}

		return (
			<p>
				<span className={classes.bold}>{direction}</span> {lowercasedFormattedDate}
			</p>
		);
	}

	private renderIncomingSourceLine() {
		if ('contact' in this.props.event.them) {
			return (
				<>
					{this.props.translate('CALL_FROM')}{' '}
					<span className={classes.bold}>{this.props.event.them.contact.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		if (this.props.event.source.name && this.props.event.them.number) {
			return (
				<>
					{this.props.translate('CALL_FROM')}{' '}
					<span className={classes.bold}>{this.props.event.source.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		return (
			<>
				{this.props.translate('CALL_FROM')}{' '}
				<span className={classes.bold}>
					{getLocalizedEventSource(this.props.event, this.props.domain, this.props.translate)}{' '}
				</span>
			</>
		);
	}

	private renderIncomingEndpoint(endpoint: NormalizedEndpoint, isLastEndpoint: boolean) {
		const extension = formatExtension(this.props.translate, endpoint.type, endpoint.name);
		const number = this.props.event.target.number
			? `(${localizeNumber(this.props.event.target.number, this.props.domain)})`
			: null;

		switch (endpoint.routedType) {
			case 'FORWARDED':
				return (
					<span>
						{this.props.translate('EVENTLIST_FORWARDED_TO')} {extension}
					</span>
				);
			case 'PICKUP':
				return (
					<span>
						{this.props.translate('EVENTLIST_PICKED_UP_BY')} {extension}
					</span>
				);
			case 'ROUTED': {
				if (
					!isLastEndpoint ||
					endpoint.type === 'GROUP' ||
					this.props.event.originalEvent.status === 'FAILURE'
				) {
					return (
						<span>
							{this.props.translate('EVENTLIST_ARRIVED_AT')} {extension} {number}
						</span>
					);
				}

				if (endpoint.type === 'USER') {
					return (
						<span>
							{this.props.translate('EVENTLIST_ACCEPTED_BY')} {extension}
						</span>
					);
				}

				if (endpoint.type === 'VOICEMAIL') {
					return (
						<span>
							{this.props.translate('EVENTLIST_ACCEPTED_BY')} {extension} {number}
						</span>
					);
				}

				return (
					<span>
						<DeprecatedMarkdown source={this.props.translate('EVENTLIST_CALL_RECEIVED_BY_ME')} />{' '}
						{extension} {number}
					</span>
				);
			}

			default:
				return null;
		}
	}

	private renderIncomingDetail() {
		const lines = this.props.event.endpoints.map((endpoint, index) => (
			<React.Fragment key={index}>
				<br />
				{this.renderIncomingEndpoint(endpoint, index === this.props.event.endpoints.length - 1)}
			</React.Fragment>
		));

		return (
			<p>
				{this.renderIncomingSourceLine()}
				{lines}
			</p>
		);
	}

	private renderOutgoingTargetLine() {
		if ('contact' in this.props.event.them) {
			return (
				<>
					{this.props.translate('CALL_TO')}{' '}
					<span className={classes.bold}>{this.props.event.them.contact.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		if (this.props.event.target.name && this.props.event.them.number) {
			return (
				<>
					{this.props.translate('CALL_TO')}{' '}
					<span className={classes.bold}>{this.props.event.target.name}</span>{' '}
					{localizeNumber(this.props.event.them.number, this.props.domain)}
				</>
			);
		}

		return (
			<>
				{this.props.translate('CALL_TO')}{' '}
				<span className={classes.bold}>
					{getLocalizedEventTarget(this.props.event, this.props.domain, this.props.translate)}{' '}
				</span>
			</>
		);
	}

	private renderOutgoingDetail() {
		return (
			<p>
				{this.props.translate('CALL_FROM')}{' '}
				<span className={classes.bold}>{this.props.translate('EVENTLIST_ME')}</span>{' '}
				{this.renderOutgoingExtensionMaybe()}
				<br />
				{this.renderOutgoingTargetLine()}
			</p>
		);
	}

	private renderOutgoingExtensionMaybe() {
		const endpoint = [...this.props.event.endpoints].reverse().find(item => !!item);

		if (!endpoint) {
			return null;
		}

		const extension = formatExtension(this.props.translate, endpoint.type, endpoint.name);

		return (
			<>
				{this.props.translate('EVENTLIST_USING')} <strong>{extension}</strong>
			</>
		);
	}

	private renderAudioPlayers(elements: Recording[]) {
		const audioPlayers = elements.map(element => {
			const filename = `${this.props.event.date.toISOString()} - callrecording - ${
				this.props.event.them.number ? this.props.event.them.number : 'anonymous'
			}.mp3`;

			return (
				<React.Fragment key={element.id}>
					<div className={classes.player}>
						<AudioPlayer
							url={element.url}
							paused={this.props.playingRecordingId !== element.id}
							onPlayPause={playing =>
								this.props.onPlayPause(this.props.event.originalEvent.id, element.id, playing)
							}
							translate={this.props.translate}
						/>
						<DownloadLink button fileName={filename} url={element.url}>
							{this.props.translate('DOWNLOAD_CALLRECORDING')}
						</DownloadLink>
					</div>
				</React.Fragment>
			);
		});
		return <div>{audioPlayers}</div>;
	}

	private renderClick2DialButton() {
		if (!this.props.event.them.number) {
			return null;
		}

		const contact = 'contact' in this.props.event.them ? this.props.event.them.contact : undefined;

		return (
			<C2DButton
				number={this.props.event.them.number}
				contact={contact}
				isReturningCall={this.props.event.incoming}
				translate={this.props.translate}
				onClick2Dial={this.props.onClick2Dial}
				domain={this.props.domain}
			/>
		);
	}

	private renderSmsButton() {
		const number = this.props.event.them.number;
		if (
			!this.props.event.incoming ||
			!this.props.onAnswerWithSms ||
			!number ||
			!isMobileNumber(number, this.props.domain)
		) {
			return null;
		}

		return (
			<Button
				onClick={() => {
					if (this.props.onAnswerWithSms) {
						this.props.onAnswerWithSms(number);
					}
				}}
			>
				{this.props.translate('ANSWER_WITH_SMS_BUTTON')}
			</Button>
		);
	}

	private renderContactButton() {
		if (
			('contact' in this.props.event.them && this.props.event.them.contact) ||
			!this.props.event.them.number
		) {
			return null;
		}

		if (this.props.onAddNumberToContact) {
			return (
				<>
					<Button onClick={this.onNewContact}>
						{this.props.translate('SAVE_NUMBER_TO_CONTACT')}
					</Button>

					<Button onClick={this.onAddNumberToContact}>
						{this.props.translate('ADD_TO_CONTACT')}
					</Button>
				</>
			);
		}

		return (
			<Button onClick={this.onNewContact}>{this.props.translate('SAVE_NUMBER_TO_CONTACT')}</Button>
		);
	}

	private renderBlacklistButton() {
		if (!this.props.event.incoming) {
			return null;
		}

		if (!this.props.onBlacklist) {
			return null;
		}

		if ('contact' in this.props.event.them) {
			return null;
		}

		if (!this.props.event.them.rawNumber) {
			return null;
		}

		if (this.props.event.endpoints.length > 0 && this.props.event.endpoints[0].type === 'ACD') {
			return null;
		}

		if (this.props.event.them.blacklistEntry) {
			if (this.props.event.them.blacklistEntry.isBlock) {
				return (
					<>
						<Button onClick={this.props.onOpenBlacklist}>
							{this.props.translate('EVENTLIST_OPEN_BLACKLIST')}
						</Button>
					</>
				);
			}

			return (
				<Button onClick={this.onUnblacklist}>
					{this.props.translate('EVENTLIST_DELETE_BLACKLIST')}
				</Button>
			);
		}

		return (
			<Button onClick={this.onBlacklist}>{this.props.translate('EVENTLIST_BLACKLIST')}</Button>
		);
	}

	private renderActionButtons() {
		return (
			<div className={classes.actionButtons}>
				{this.renderClick2DialButton()}
				{this.renderSmsButton()}
				{this.renderContactButton()}
				{this.renderBlacklistButton()}
			</div>
		);
	}

	private renderCallStatus() {
		switch (this.props.event.originalEvent.callStatus) {
			case 'SUCCESS':
				return [];
			case 'REJECTED':
				return [
					<SystemLabel
						className={eventClasses.systemLabel}
						key="rejected"
						tooltip={this.props.translate('EVENT_STATUS_REJECTED_TOOLTIP')}
						intentness="strong"
					>
						{this.props.translate('EVENT_STATUS_REJECTED')}
					</SystemLabel>,
				];
			case 'REJECTED_DND':
				return [
					<SystemLabel
						className={eventClasses.systemLabel}
						key="rejected-dnd"
						tooltip={this.props.translate('EVENT_STATUS_TOOLTIP_REJECTED_DND')}
						intentness="strong"
					>
						{this.props.translate('EVENT_STATUS_REJECTED_DND')}
					</SystemLabel>,
				];
			case 'VOICEMAIL_NO_MESSAGE':
				return [
					<SystemLabel
						className={eventClasses.systemLabel}
						key="voicemail-no-message"
						tooltip={this.props.translate('EVENT_STATUS_TOOLTIP_VOICEMAIL_NO_MESSAGE')}
						intentness="strong"
					>
						{this.props.translate('EVENT_STATUS_VOICEMAIL_NO_MESSAGE')}
					</SystemLabel>,
				];
			case 'BUSY_ON_BUSY':
				return [
					<SystemLabel
						className={eventClasses.systemLabel}
						key="busy-on-busy"
						tooltip={this.props.translate('EVENT_STATUS_TOOLTIP_BUSY_ON_BUSY')}
						intentness="strong"
					>
						{this.props.translate('EVENT_STATUS_BUSY_ON_BUSY')}
					</SystemLabel>,
				];
			case 'BUSY':
				return [
					<SystemLabel className={eventClasses.systemLabel} key="busy" intentness="strong">
						{this.props.translate('EVENT_STATUS_BUSY')}
					</SystemLabel>,
				];
			case 'MISSED':
				if (this.props.event.originalEvent.direction === 'INCOMING') {
					return [
						<SystemLabel
							className={eventClasses.systemLabel}
							key="missed-incoming"
							tooltip={this.props.translate('EVENT_STATUS_TOOLTIP_MISSED_INCOMING')}
							intentness="strong"
						>
							{this.props.translate('EVENT_STATUS_MISSED_INCOMING')}
						</SystemLabel>,
					];
				}
				return [
					<SystemLabel
						className={eventClasses.systemLabel}
						key="missed-incoming"
						tooltip={this.props.translate('EVENT_STATUS_TOOLTIP_MISSED_OUTGOING')}
						intentness="strong"
					>
						{this.props.translate('EVENT_STATUS_MISSED_OUTGOING')}
					</SystemLabel>,
				];
			case 'FAILURE':
			default:
				if (this.props.event.originalEvent.direction === 'INCOMING') {
					return [
						<SystemLabel
							className={eventClasses.systemLabel}
							key="failure-incoming"
							intentness="strong"
						>
							{this.props.translate('EVENT_STATUS_NOPICKUP')}
						</SystemLabel>,
					];
				}
				return [
					<SystemLabel
						className={eventClasses.systemLabel}
						key="failure-outgoing"
						intentness="strong"
					>
						{this.props.translate('EVENT_STATUS_NOPICKUP_OUTGOING')}
					</SystemLabel>,
				];
		}
	}

	private renderSystemLabels() {
		const systemLabels = this.renderCallStatus();

		const lastEndpoint = this.props.event.endpoints[this.props.event.endpoints.length - 1];
		if (lastEndpoint && lastEndpoint.routedType === 'PICKUP') {
			systemLabels.push(
				<SystemLabel className={eventClasses.systemLabel} key="picked-up-by" intentness="strong">
					{this.props.translate('EVENT_STATUS_PICKED_UP_BY')}
				</SystemLabel>
			);
		}

		for (const connection of this.props.event.connections) {
			if ((connection.type === 'GROUP' || connection.type === 'ACD') && connection.name) {
				systemLabels.push(
					<SystemLabel className={eventClasses.systemLabel} key={`endpoint-${connection.id}`}>
						{connection.name}
					</SystemLabel>
				);
			}
		}

		if (this.props.event.originalEvent.recordings.length > 0) {
			systemLabels.push(
				<SystemLabel
					className={eventClasses.systemLabel}
					key={`recording-${this.props.event.originalEvent.id}`}
				>
					{this.props.translate('EVENT_WITH_CALL_RECORDING')}
				</SystemLabel>
			);
		}

		return systemLabels;
	}

	private renderInlineText() {
		return this.props.event.originalEvent.note ? this.props.event.originalEvent.note : '';
	}

	public render() {
		return (
			<Event
				eventId={this.props.event.originalEvent.id}
				translate={this.props.translate}
				eventType="CALL"
				source={getLocalizedEventSource(this.props.event, this.props.domain, this.props.translate)}
				target={getLocalizedEventTarget(this.props.event, this.props.domain, this.props.translate)}
				date={this.props.event.date}
				length={
					this.props.event.originalEvent.duration > 0
						? formatDuration(this.props.event.originalEvent.duration)
						: undefined
				}
				note={this.props.event.originalEvent.note ? this.props.event.originalEvent.note : undefined}
				read={this.props.event.originalEvent.read === 'READ'}
				incoming={this.props.event.incoming}
				onNoteChange={this.props.onNoteChange}
				onRead={this.props.onRead}
				onClose={this.onClose}
				systemLabels={this.renderSystemLabels()}
				selected={this.props.event.selected}
				onSelection={this.props.onSelection}
				onStar={this.props.onStar}
				starred={this.props.event.originalEvent.starred === 'STARRED'}
				labels={this.props.event.originalEvent.labels}
				inlineText={this.renderInlineText()}
			>
				{this.renderDetailHeadline()}
				{this.props.event.incoming ? this.renderIncomingDetail() : this.renderOutgoingDetail()}

				{this.renderAudioPlayers(this.props.event.originalEvent.recordings)}

				{this.renderActionButtons()}
			</Event>
		);
	}
}
