import { handleActions } from '../..';
import { GroupState } from './types';
import * as actions from './actions';
import * as actionsAudioFiles from '../audioFiles/actions';

const initialState: GroupState = {
	items: [],
	fetched: false,
	fetching: false,
	updatingForGroup: [],
};

type Actions = typeof actionsAudioFiles & typeof actions;

export default handleActions<GroupState, PossibleActions<Actions>>(
	{
		GROUPS_FETCH_PENDING: state => ({ ...state, fetching: true }),
		GROUPS_FETCH_SUCCESS: (state, { payload }) => {
			const newItems = [...payload.items];
			newItems.sort((a, b) => a.alias.localeCompare(b.alias));

			return {
				...state,
				fetched: true,
				fetching: false,
				items: newItems,
			};
		},

		GROUP_CREATE_SUCCESS: (state, { payload }) => {
			return {
				...state,
				items: [...state.items, payload],
			};
		},

		GROUP_ALIAS_SET_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(group => {
				const newGroup = { ...group };
				if (newGroup.id === data.groupId) {
					newGroup.alias = data.alias;
				}

				return newGroup;
			}),
			updatingForGroup: [...state.updatingForGroup, data.groupId],
		}),

		GROUP_ALIAS_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForGroup: state.updatingForGroup.filter(group => group !== data.groupId),
		}),

		GROUP_DELETE_PENDING: (state, { data }) => ({
			...state,
			updatingForGroup: [...state.updatingForGroup, data.groupId],
		}),

		GROUP_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(group => group.id !== data.groupId),
			updatingForGroup: state.updatingForGroup.filter(group => group !== data.groupId),
		}),

		GROUP_STATISTICS_CONTRACT_BOOK_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.map(group => {
				if (group.id !== data.groupId) {
					return group;
				}

				return {
					...group,
					statisticsBooked: true,
				};
			}),
		}),

		GROUP_MEMBERS_SET_PENDING: (state, { data }) => ({
			...state,
			updatingForGroup: [...state.updatingForGroup, data.groupId],
			items: state.items.map(group => {
				if (group.id !== data.groupId) {
					return group;
				}

				return { ...group, users: data.memberIds };
			}),
		}),

		GROUP_MEMBERS_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForGroup: state.updatingForGroup.filter(group => group !== data.groupId),
		}),

		GROUP_GREETING_CREATE_SUCCESS: (state, { data, payload }) => ({
			...state,
			items: state.items.map(group => {
				if (group.id !== data.groupId) {
					return group;
				}

				return { ...group, greeting: payload };
			}),
		}),

		GROUP_GREETING_DELETE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(group => {
				if (group.id !== data.groupId) {
					return group;
				}

				return { ...group, greeting: undefined };
			}),
			updatingForGroup: [...state.updatingForGroup, data.groupId],
		}),

		GROUP_GREETING_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			updatingForGroup: state.updatingForGroup.filter(group => group !== data.groupId),
		}),

		GROUP_CALL_QUEUE_CREATE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.map(group => {
				if (group.id !== data.groupId) {
					return group;
				}

				return { ...group, callQueue: { activeAudioFileId: null } };
			}),
		}),

		GROUP_CALL_QUEUE_DELETE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(group => {
				if (group.id !== data.groupId) {
					return group;
				}

				return { ...group, callQueue: undefined };
			}),
			updatingForGroup: [...state.updatingForGroup, data.groupId],
		}),

		GROUP_CALL_QUEUE_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			updatingForGroup: state.updatingForGroup.filter(group => group !== data.groupId),
		}),

		ACTIVE_AUDIOFILE_GREETING_SET_PENDING: (state, { data }) => ({
			...state,
			updatingForGroup: [...state.updatingForGroup, data.groupId],
			items: state.items.map(group => {
				if (group.id !== data.groupId) {
					return group;
				}

				return {
					...group,
					greeting: group.greeting
						? { ...group.greeting, activeAudioFileId: data.activeAudioFileId }
						: undefined,
				};
			}),
		}),

		ACTIVE_AUDIOFILE_GREETING_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForGroup: state.updatingForGroup.filter(group => group !== data.groupId),
		}),

		ACTIVE_AUDIOFILE_CALL_QUEUE_SET_PENDING: (state, { data }) => ({
			...state,
			updatingForGroup: [...state.updatingForGroup, data.groupId],
			items: state.items.map(group => {
				if (group.id !== data.groupId) {
					return group;
				}

				return {
					...group,
					callQueue: group.callQueue ? { activeAudioFileId: data.activeAudioFileId } : undefined,
				};
			}),
		}),

		ACTIVE_AUDIOFILE_CALL_QUEUE_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForGroup: state.updatingForGroup.filter(group => group !== data.groupId),
		}),

		AUDIO_FILE_DELETE_SUCCESS: (state, { data }) => {
			if (data.ownerId.startsWith('r')) {
				return {
					...state,
					items: state.items.map(group => {
						if (
							!group.greeting ||
							group.greeting.id !== data.ownerId ||
							group.greeting.activeAudioFileId !== data.audioFileId
						) {
							return group;
						}
						return {
							...group,
							greeting: {
								id: group.greeting.id,
								activeAudioFileId: null,
							},
						};
					}),
				};
			}

			if (data.ownerId.startsWith('g')) {
				return {
					...state,
					items: state.items.map(group => {
						if (
							!group.callQueue ||
							group.id !== data.ownerId ||
							group.callQueue.activeAudioFileId !== data.audioFileId
						) {
							return group;
						}
						return {
							...group,
							callQueue: {
								activeAudioFileId: null,
							},
						};
					}),
				};
			}

			return { ...state };
		},
	},
	initialState
);
