import { createSlice } from '@reduxjs/toolkit';
import { createApiClient, deleteApiClient, fetchApiClients, updateApiClient } from './actions';
import { ApiClientsState } from './types';

const initialState: ApiClientsState = {
	fetched: false,
	fetching: false,
	apiClients: [],
};
export const reducer = createSlice({
	name: 'apiClients',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchApiClients.pending, state => {
				return { ...state, fetching: true };
			})
			.addCase(fetchApiClients.fulfilled, (state, action) => {
				return {
					...state,
					fetched: true,
					fetching: false,
					apiClients: action.payload.data.map(c => ({
						id: c.clientId,
						clientSecret: c.clientSecret,
						name: c.name,
						description: c.description,
						redirectUris: c.redirectUris,
						webOrigins: c.webOrigins,
						privacyUrl: c.privacyUrl,
						termsUrl: c.termsUrl,
					})),
				};
			})
			.addCase(createApiClient.fulfilled, (state, action) => ({
				...state,
				apiClients: [
					...state.apiClients,
					{
						id: action.payload.clientId,
						clientSecret: action.payload.clientSecret,
						name: action.payload.name,
						description: action.payload.description,
						redirectUris: action.payload.redirectUris,
						webOrigins: action.payload.webOrigins,
						privacyUrl: action.payload.privacyUrl,
						termsUrl: action.payload.termsUrl,
					},
				],
			}))
			.addCase(updateApiClient.pending, (state, action) => ({
				...state,
				apiClients: state.apiClients.map(client => {
					if (client.id !== action.meta.arg.id) {
						return client;
					}

					return {
						...client,
						name: action.meta.arg.name,
						description: action.meta.arg.description,
						privacyUrl: action.meta.arg.privacyUrl,
						termsUrl: action.meta.arg.termsUrl,
						redirectUris: action.meta.arg.redirectUris,
						webOrigins: action.meta.arg.webOrigins,
					};
				}),
			}))
			.addCase(deleteApiClient.pending, (state, action) => ({
				...state,
				apiClients: state.apiClients.filter(client => client.id !== action.meta.arg),
			}));
	},
}).reducer;
