import { Store } from '@reduxjs/toolkit';
import { State } from '../state';
import { UpdateExtensionAction } from './types';
import { ExtensionState } from '../../../../utils/endpoints';

export const updateExtensions = (store: Store<State>) => (extensions: ExtensionState) => {
	store.dispatch<UpdateExtensionAction>({
		type: 'UPDATE_EXTENSIONS',
		extensions,
	});
};
