import React, { ComponentProps } from 'react';
import { DisabledContext, isDisabled } from '../../contexts/disabledContext';
import { MainTab } from './MainTab';
import { MainTabs } from './MainTabs';
import { SubTab } from './SubTab';
import { MainTabItem } from './MainTabItem';
import { SubTabItem } from './SubTabItem';
import { SubTabs } from './SubTabs';

interface SelectedTab {
	mainTab: string;
	subTab: number | null;
}

type Props = {
	children:
		| React.ReactElement<ComponentProps<typeof MainTab>>[]
		| React.ReactElement<ComponentProps<typeof MainTab>>;
	selectedTab: SelectedTab;
	onMainTabChange: (mainTab: string) => void;
	onSubTabChange: (subTab: number) => void;
	actionElement: React.ReactNode;
};

const NestedTabs = ({
	children,
	selectedTab,
	onMainTabChange,
	onSubTabChange,
	actionElement,
}: Props): JSX.Element | null => {
	const disabledContextValue = React.useContext(DisabledContext);

	if (React.Children.count(children) === 0) {
		return null;
	}

	let selectedMainTab = React.Children.toArray(children).filter(
		mainTab =>
			typeof mainTab === 'object' && 'props' in mainTab && mainTab.props.id === selectedTab.mainTab
	)[0] as React.ReactElement<ComponentProps<typeof MainTab>>;

	if (!selectedMainTab) {
		selectedMainTab = React.Children.toArray(children)[0] as React.ReactElement<
			ComponentProps<typeof MainTab>
		>;
	}

	return (
		<>
			<MainTabs>
				{children &&
					React.Children.map(
						children,
						(mainTab: React.ReactElement<ComponentProps<typeof MainTab>>, mainTabIndex) => (
							<MainTabItem
								key={mainTab.props.id}
								label={mainTab.props.label}
								sublabel={mainTab.props.sublabel}
								isSelected={
									(selectedTab === null && mainTabIndex === 0) ||
									selectedTab?.mainTab === mainTab.props.id
								}
								isActive={mainTab.props.isActive}
								disabled={isDisabled(mainTab.props.disabled, disabledContextValue)}
								handleOnClick={() => onMainTabChange(mainTab.props.id)}
							/>
						)
					)}
			</MainTabs>
			<SubTabs actionElement={actionElement}>
				{selectedMainTab.props.children &&
					React.Children.map(
						selectedMainTab.props.children,
						(subTab: React.ReactElement<ComponentProps<typeof SubTab>>, subTabIndex) => (
							<SubTabItem
								key={subTab.props.id}
								label={subTab.props.label}
								isSelected={
									(selectedTab.subTab === null && subTabIndex === 0) ||
									selectedTab.subTab === subTab.props.id
								}
								disabled={isDisabled(subTab.props.disabled, disabledContextValue)}
								handleOnClick={() => onSubTabChange(subTab.props.id)}
							/>
						)
					)}
			</SubTabs>
		</>
	);
};

export { NestedTabs };
