import { createAction } from '../..';

import api from '../../../api';

export const fetchFqdns = createAction('FQDNS_FETCH', () => ({
	promise: () => api.getMicrosoftTeamsFqdn(),
}));

export const createFqdns = createAction('FQDNS_CREATE', () => ({
	promise: () => api.createMicrosoftTeamsFqdn(),
}));
