import api from '../../../api';
import { LinksState } from './types';
import { createAsyncThunk } from '../../utils/wrapper';

const shouldFetchLinks = (state: LinksState) => !state.fetching && !state.fetched;

export const fetchLinks = createAsyncThunk(
	'links/fetchLinks',
	async (_forceFetch?: boolean) => api.fetchLinks(),
	{
		condition: (forceFetch: boolean | undefined, { getState }) => {
			if (forceFetch === true) {
				return true;
			}

			return shouldFetchLinks(getState().links);
		},
	}
);
