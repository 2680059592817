import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import auth from '../utils/authenticate/auth';

export const ImplicitAuthenticator = (props: RouteComponentProps) => {
	const hash = new URLSearchParams(props.location.hash);
	const params = new URLSearchParams(props.location.search);

	const fromIdp = params.get('from_idp');
	const redirect = params.get('redirect');
	const accessToken = hash.get('access_token');

	useEffect(() => {
		window.localStorage.setItem('from_idp', fromIdp || '');

		if (!redirect || !accessToken) {
			props.history.replace('/');
			return;
		}

		auth.initializeFromToken({ access: accessToken });
		props.history.replace(redirect);
	}, [accessToken, fromIdp, redirect, props.history]);

	return null;
};
