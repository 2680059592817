/* eslint-disable jsx-a11y/no-onchange */
import React, { ChangeEvent } from 'react';
import classes from './Select.scss';

interface Props {
	onChange: (value: string) => void;

	label: string;
	value: string | number;
	children: React.ReactNode;
	className?: string;
}

export class Select extends React.PureComponent<Props> {
	private onChange = (e: ChangeEvent<HTMLSelectElement>) => {
		this.props.onChange(e.target.value);
	};

	public render() {
		return (
			<label className={`${classes.select} ${this.props.className || ''}`}>
				{this.props.label}
				<select value={this.props.value} onChange={this.onChange}>
					{this.props.children}
				</select>
			</label>
		);
	}
}
