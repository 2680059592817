import React from 'react';
import Snackbar, { SnackbarSeverity } from './Snackbar';
import classes from './Snackbar.scss';

interface Props {
	message: string;
	open: boolean;
	severity: SnackbarSeverity;
	onClose: () => void;
}

export const AutoCloseSnackbar = (props: Props) => (
	<div className={classes.snackbarContainer}>
		<Snackbar snackbarOpen={props.open} closeSnackbar={props.onClose} severity={props.severity}>
			{props.message}
		</Snackbar>
	</div>
);
