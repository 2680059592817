export type ApiSimIntendedUse = 'VOICE' | 'DATA';

export enum ApiSimState {
	INACTIVE = 'INACTIVE',
	PENDING = 'PENDING',
	ACTIVE = 'ACTIVE',
}

export type ApiSim = {
	id: string;
	alias: string;
	state: ApiSimState;
	esim: boolean;
	simId: string;
	puk1: string;
	puk2: string;
	intendedUse: ApiSimIntendedUse;
};
