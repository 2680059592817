import React, { useContext } from 'react';

export function createPropContext<Props>() {
	const PropsContext = React.createContext<Partial<Omit<Props, 'children'>>>({});

	return {
		PropsProvider: (props: Partial<Omit<Props, 'children'>> & { children: React.ReactNode }) => {
			const innerProps = { ...props };

			delete innerProps.children;

			return <PropsContext.Provider value={innerProps}>{props.children}</PropsContext.Provider>;
		},
		usePropsOverride: (props: Props) => ({ ...props, ...useContext(PropsContext) }),
	};
}
