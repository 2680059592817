import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';

const shouldFetchLabels = (state: ReduxState) => !state.labels.fetched && !state.labels.fetching;

export const fetchLabels = createAction('LABELS_FETCH', () => ({
	promise: () => api.fetchLabels(),
	shouldFetch: shouldFetchLabels,
}));

export const forceFetchLabels = createAction('LABELS_FETCH', () => ({
	promise: () => api.fetchLabels(),
}));

export const createLabel = createAction('LABELS_CREATE', (name: string) => ({
	promise: () => api.createLabel(name),
}));

export const deleteLabel = createAction('LABELS_DELETE', (id: number) => ({
	promise: () => api.deleteLabel(id),
	data: { id },
}));

export const editLabel = createAction('LABELS_EDIT', (id: number, name: string) => ({
	promise: () => api.editLabel(id, name),
	data: { id, name },
}));
