import { createAction } from '../..';
import api, { ApiError } from '../../../api';
import { AppProperties } from '../../slices/appProperties';

export const fetchIdentityVerification = createAction(
	'IDENTITY_VERIFICATION_FETCH',
	(appProperties: AppProperties) => ({
		promise: async () => {
			if (
				appProperties.identityVerificationRequired ||
				appProperties.identityVerificationRequiredForMobile
			) {
				try {
					return await api.getIdentityVerification(true);
				} catch (e) {
					// First call to identity verification route may return 404, if so
					// we retry in the hopes that the second call returns something sensible
					// after the first initiated idNow verification.
					if (e instanceof ApiError && e.status === 404) {
						// Delay a couple of seconds in hopes that the backend processes are done
						// by then.
						await new Promise(res => setTimeout(res, 3000));

						return api.getIdentityVerification(true);
					}

					throw e;
				}
			}

			return null;
		},
		data: {
			shouldBeCaged: appProperties.identityVerificationRequired,
		},
	})
);

export const setAfterVideoIdent = createAction(
	'IDENTITY_VERIFICATION_SET_AFTER_VIDEO_IDENT',
	(videoIdentCompletedWithinSession: boolean) => videoIdentCompletedWithinSession
);
