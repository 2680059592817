import { DeviceProperty } from '../../../api/types/DeviceProperty';

export interface DevicePropertiesState {
	items: DeviceProperty[];
	fetchingForDevice: string[];
	fetchedForDevice: string[];
}

export const deviceIconTypes = [
	'TABLET',
	'MOBILE',
	'SMARTWATCH',
	'COMPUTER',
	'ESIM',
	'SIM',
	'OTHER',
] as const;

export type DeviceIconType = typeof deviceIconTypes extends readonly (infer T)[] ? T : never;
