import { Store } from '@reduxjs/toolkit';

import { State } from '../../state';
import { ToggleStarPendingAction, ToggleStarSuccessAction, ToggleStarErrorAction } from './types';
import api from '../../../../../api';
import { HistoryEntryStarred } from '../../../../../api/types/events';

export const toggleStar =
	(store: Store<State>) => async (eventId: string, starred: HistoryEntryStarred) => {
		store.dispatch<ToggleStarPendingAction>({
			type: 'TOGGLE_STAR_PENDING',
			eventId,
			starred,
		});

		try {
			await api.updateHistoryEntry(eventId, { starred: starred === 'STARRED' });

			store.dispatch<ToggleStarSuccessAction>({ type: 'TOGGLE_STAR_SUCCESS' });
		} catch (e) {
			store.dispatch<ToggleStarErrorAction>({ type: 'TOGGLE_STAR_ERROR' });

			throw e;
		}
	};
