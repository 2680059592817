import { HeadingBoundary, InternalLink } from '@panda/ui';
import classnames from 'classnames';
import pathToRegex from 'path-to-regexp';
import React, { useContext, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { Preview } from '../../accountNavigation/components/Preview/Preview';
import SipgateStatusComponent from '../../accountNavigation/components/SipgateStatus/SipgateStatusComponent';
import { AuthenticatedLayoutContext } from '../../layouts/AuthenticatedLayout';
import SipgateLogoSchwarz from '../../media/logo/sipgate_wort-bild-marke_schwarz.svg';
import SipgateLogo from '../../media/logo/sipgate_wort-bild-marke_weiss.svg';
import { useAccount } from '../../redux/slices/account';
import { useTranslate } from '../../redux/slices/translations';
import { useUserInfo } from '../../redux/modules/userinfo';
import * as paths from '../../routes/paths';
import { accountManagementPath, eventlistPath, isAccountSection } from '../../routes/paths';
import { trackClick } from '../../third-party/mixpanel';
import AccountVerificationButton from '../AccountVerificationButton';
import ConnectedCommunicationButtons from '../ConnectedCommunicationButtons';
import { WebphoneButton } from '../softphone/WebphoneButton';
import ToggleNavigationGray from '../../media/icons/navi_toggle-navigation-dark-line-24.svg';
import ToggleNavigation from '../../media/icons/navi_toggle-navigation-light-line-24.svg';
import classes from './SideNavigation.scss';
import { SideNavigationMenu } from './SideNavigation.types';
import {
	ActiveGroupContext,
	AreColorsInvertedContext,
	IsSideNavigationOpenContext,
	IsTransitioningContext,
} from './SideNavigation.utils';
import { SideNavigationGroup } from './SideNavigationGroup/SideNavigationGroup';
import { SideNavigationLink } from './SideNavigationLink/SideNavigationLink';
import { hasRestriction, useRestrictions } from '../../redux/modules/restrictions';
import AppWidget from '../../views/users/user/app/Widgets/AppWidget/AppWidget';

type Props = {
	menu: SideNavigationMenu;
};

export const SideNavigation = ({ menu }: Props) => {
	const translate = useTranslate();
	const [inTransition, setInTransition] = React.useState(false);
	const history = useHistory();
	const { hasBeenNavigated } = useContext(AuthenticatedLayoutContext);
	const account = useAccount();
	const userInfo = useUserInfo();
	const restrictions = useRestrictions(['CAN_USE_PBX_FEATURES']);
	const canUsePbxFeatures = hasRestriction(restrictions.data, 'CAN_USE_PBX_FEATURES');
	const dashboardActive = pathToRegex('/controlpanel', { end: false }).test(
		history.location.pathname
	);
	const [openGroups, setOpenGroups] = React.useState<string[]>(
		!hasBeenNavigated && dashboardActive ? ['ACCOUNT_NAVIGATION_HEADING_TELEPHONY'] : []
	);

	const { onToggleNavigation, isOpen, isMobile, onNavigation } = React.useContext(
		AuthenticatedLayoutContext
	);
	const isInAdminArea = isAccountSection(history.location.pathname) || !canUsePbxFeatures;
	const isSideNavigationOpen = isInAdminArea || isMobile ? isOpen : true;

	const onToggleNavigationClick = () => {
		trackClick('Navigation Toggle Button Clicked', {
			isSideNavigationOpen,
		});

		const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

		if (!isMobile && !prefersReducedMotion) {
			setInTransition(true);
		}
		setOpenGroups([]);
		onToggleNavigation();
	};

	useEffect(() => {
		if (isMobile) {
			onNavigation();
		}

		if (!isOpen) {
			setOpenGroups([]);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location]);

	useEffect(() => {
		if (!isSideNavigationOpen) {
			setOpenGroups([]);
		}
	}, [isSideNavigationOpen]);

	const onScroll = (e: React.UIEvent<HTMLUListElement>) => {
		if (e.currentTarget.scrollTop > 0) {
			e.currentTarget.classList.add(classes.scrolled);
		} else {
			e.currentTarget.classList.remove(classes.scrolled);
		}
	};

	return (
		<IsSideNavigationOpenContext.Provider value={isSideNavigationOpen}>
			<IsTransitioningContext.Provider value={inTransition}>
				<ActiveGroupContext.Provider value={openGroups}>
					<AreColorsInvertedContext.Provider value={!isInAdminArea}>
						<div
							className={classnames(classes.overlay, {
								[classes.closed]: !isSideNavigationOpen,
							})}
							onClick={onToggleNavigationClick}
							role="button"
							onKeyUp={onToggleNavigationClick}
							tabIndex={-1000}
						/>
						<div
							className={classnames(classes.navigation, {
								[classes.closed]: !isSideNavigationOpen,
								[classes.inTransition]: inTransition,
								[classes.invertColors]: !isInAdminArea,
							})}
							onTransitionEnd={() => {
								setInTransition(false);
							}}
						>
							<nav id="aria-0" aria-label={translate('ACCOUNT_NAVIGATION_NAV_ARIA_LABEL')}>
								<div className={classes.header}>
									<NavLink
										to={isInAdminArea ? accountManagementPath.build() : eventlistPath.build()}
									>
										<img
											src={isInAdminArea ? SipgateLogo : SipgateLogoSchwarz}
											alt="sipgate logo"
											className={classes.logo}
										/>
									</NavLink>
									{(isInAdminArea || isMobile) && (
										<button
											type="button"
											onClick={onToggleNavigationClick}
											className={classes.toggleButton}
											data-testid="close-navigation-button"
											aria-expanded={isSideNavigationOpen}
											aria-label={translate('ACCOUNT_NAVIGATION_TOGGLE_ARIA_LABEL')}
											aria-controls="aria-0"
										>
											<img src={isInAdminArea ? ToggleNavigation : ToggleNavigationGray} alt="" />
										</button>
									)}

									<SipgateStatusComponent variant="button" />
								</div>
								{account && !isInAdminArea && (
									<AccountVerificationButton accountIsVerified={account.verified} />
								)}
								{!isInAdminArea && userInfo && (
									<div className={classes.communicationButtons}>
										<ConnectedCommunicationButtons webuserId={userInfo.sub} />
									</div>
								)}
								<HeadingBoundary>
									<ul onScroll={onScroll}>
										{menu.items.map(item => {
											if (item.kind === 'LINK') {
												return <SideNavigationLink key={item.path} item={item} />;
											}
											return (
												<SideNavigationGroup
													key={item.title}
													item={item}
													toggleOpenGroup={() => {
														const hasOpenGroup = openGroups.includes(item.title);

														setOpenGroups([
															...openGroups.filter(a => a !== item.title),
															...(hasOpenGroup ? [] : [item.title]),
														]);
													}}
												/>
											);
										})}
									</ul>
								</HeadingBoundary>
							</nav>
							{isInAdminArea && !inTransition && (
								<Preview variant={isSideNavigationOpen ? 'large' : 'small'} />
							)}
							{!isInAdminArea && userInfo && (
								<div className={classes.widgets}>
									<AppWidget userId={userInfo.sub} />
								</div>
							)}
							{!isInAdminArea && (
								<WebphoneButton
									handleClick={() => window.open(paths.softphonePath.build())}
									translate={translate}
								/>
							)}
							<div className={classes.mobileSectionSwitcher}>
								{isInAdminArea && canUsePbxFeatures && (
									<InternalLink
										to={eventlistPath.build()}
										variant="loud"
										size="large"
										width="max"
										button
									>
										{translate('USER_AREA')}
									</InternalLink>
								)}
								{/* isAdmin check should probably be a restriction */}
								{!isInAdminArea && userInfo.isAdmin && (
									<InternalLink
										to={accountManagementPath.build()}
										variant="loud"
										size="large"
										width="max"
										button
									>
										{translate('DEDICATED_ADMIN_AREA')}
									</InternalLink>
								)}
							</div>
						</div>
					</AreColorsInvertedContext.Provider>
				</ActiveGroupContext.Provider>
			</IsTransitioningContext.Provider>
		</IsSideNavigationOpenContext.Provider>
	);
};
