import React from 'react';
import moment from 'moment-timezone';

import { DownloadLink, Button } from '@panda/ui';
import { isMobileNumber, localizeNumber } from '@web-apps/phonenumbers-utils';
import { Event } from './Event';
import classes from './VoicemailEvent.scss';
import { NormalizedEvent } from '../normalize/events';
import { Translate } from '../../../redux/slices/translations';

import {
	formatDuration,
	formatExtension,
	getLocalizedEventSource,
	getLocalizedEventTarget,
} from '../util';
import { AudioPlayer } from '../AudioPlayer';
import { C2DButton } from '../buttons/C2DButton';
import { SystemLabel } from '../../../components/SystemLabel';
import { NormalizedEndpoint } from '../normalize/endpoint';
import { VoicemailHistoryEntry } from '../../../api/types/events';
import { WithDialogProps, withDialogs } from '../../../routes/paths/dialogs';
import { hasRestriction, RestrictionState } from '../../../redux/modules/restrictions';
import { SipgateDomain } from '../../../redux/modules/userinfo';

interface Props {
	domain: SipgateDomain;
	event: NormalizedEvent<VoicemailHistoryEntry>;
	paused: boolean;
	translate: Translate;
	onRead: (eventIds: string[]) => void;
	onClick2Dial: (number: string) => void;
	onAnswerWithSms?: (number: string) => void;
	onNewContact: (number: string) => void;
	onAddNumberToContact?: (number: string) => void;
	onClick2Play?: (eventId: string) => void;
	onNoteChange: (eventId: string, note: string) => void;
	onPlayPause: (eventId: string, voicemailId: string, playing: boolean) => void;
	onSelection: (eventId: string) => void;
	onStar: (eventId: string, starred: boolean) => void;
	restrictions: RestrictionState;
	impersonated: boolean;
}

class VoicemailEvent extends React.PureComponent<Props & WithDialogProps> {
	private onNewContact = () => {
		if (this.props.event.them.number) {
			this.props.onNewContact(this.props.event.them.number);
		}
	};

	private onAddNumberToContact = () => {
		if (this.props.onAddNumberToContact && this.props.event.them.number) {
			this.props.onAddNumberToContact(this.props.event.them.number);
		}
	};

	private onClose = () => {
		this.props.onPlayPause(
			this.props.event.originalEvent.id,
			this.props.event.originalEvent.id,
			false
		);
	};

	private onPlayPause = (playing: boolean) => {
		this.props.onPlayPause(
			this.props.event.originalEvent.id,
			this.props.event.originalEvent.id,
			playing
		);
	};

	private onClick2Play = () => {
		if (this.props.onClick2Play) {
			this.props.onClick2Play(this.props.event.originalEvent.id);
		}
	};

	private onTranscribe = () => {
		this.props.dialogs.transcribeSingleVoicemail.open({
			voicemailId: this.props.event.endpoints.find(e => e.type === 'VOICEMAIL')!.id,
			eventId: this.props.event.originalEvent.id,
		});
	};

	private renderSystemLabels() {
		const tags = [];

		for (const connection of this.props.event.connections) {
			if (connection.type === 'GROUP' && connection.name) {
				tags.push(<SystemLabel key={`group-${connection.id}`}>{connection.name}</SystemLabel>);
			}
		}

		return tags;
	}

	private renderDetailHeadline() {
		const formattedDate = moment(this.props.event.originalEvent.dateCreated).calendar();
		const lowercasedFormattedDate = formattedDate[0].toLocaleLowerCase() + formattedDate.slice(1);

		return (
			<p>
				<span className={classes.bold}>{this.props.translate('INCOMING_VOICEMAIL')}</span>{' '}
				{lowercasedFormattedDate}, {formatDuration(this.props.event.originalEvent.duration)}
			</p>
		);
	}

	private renderIncomingSourceLine() {
		if ('contact' in this.props.event.them) {
			return (
				<>
					{this.props.translate('VOICEMAIL_FROM')}{' '}
					<span className={classes.bold}>{this.props.event.them.contact.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		if (this.props.event.source.name && this.props.event.them.number) {
			return (
				<>
					{this.props.translate('VOICEMAIL_FROM')}{' '}
					<span className={classes.bold}>{this.props.event.source.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		return (
			<>
				{this.props.translate('VOICEMAIL_FROM')}{' '}
				<span className={classes.bold}>
					{getLocalizedEventSource(this.props.event, this.props.domain, this.props.translate)}{' '}
				</span>
			</>
		);
	}

	private renderIncomingEndpoint(endpoint: NormalizedEndpoint) {
		const extension = formatExtension(this.props.translate, endpoint.type, endpoint.name);
		const number = this.props.event.target.number
			? `(${localizeNumber(this.props.event.target.number, this.props.domain)})`
			: null;

		if (endpoint.type === 'VOICEMAIL') {
			return (
				<span>
					{this.props.translate('EVENTLIST_ACCEPTED_BY')} {extension}
				</span>
			);
		}

		switch (endpoint.routedType) {
			case 'FORWARDED':
				return (
					<span>
						{this.props.translate('EVENTLIST_FORWARDED_TO')} {extension}
					</span>
				);
			case 'ROUTED':
				return (
					<span>
						{this.props.translate('EVENTLIST_ARRIVED_AT')} {extension} {number}
					</span>
				);

			default:
				return null;
		}
	}

	private renderDetail() {
		const lines = this.props.event.endpoints.map((endpoint, index) => (
			<React.Fragment key={index}>
				<br />
				{this.renderIncomingEndpoint(endpoint)}
			</React.Fragment>
		));

		return (
			<p>
				{this.renderIncomingSourceLine()}
				{lines}
			</p>
		);
	}

	private renderAudioPlayer() {
		const filename = `${this.props.event.date.toISOString()} - voicemail - ${
			this.props.event.them.number ? this.props.event.them.number : 'anonymous'
		}.mp3`;

		return (
			<>
				<div className={classes.player}>
					<AudioPlayer
						url={this.props.event.originalEvent.recordingUrl}
						paused={this.props.paused}
						onPlayPause={this.onPlayPause}
						translate={this.props.translate}
					/>
					<DownloadLink
						button
						fileName={filename}
						url={this.props.event.originalEvent.recordingUrl}
					>
						{this.props.translate('DOWNLOAD_VOICEMAIL')}
					</DownloadLink>
				</div>
				{this.props.event.originalEvent.transcription ? (
					<section className={classes.transcription}>
						<span className={classes.transcriptionHead}>
							{this.props.translate('VOICEMAIL_TRANSCRIPTION_HEAD')}
						</span>
						<p>{this.props.event.originalEvent.transcription}</p>
					</section>
				) : null}
			</>
		);
	}

	private renderClick2DialButton() {
		if (!this.props.event.them.number) {
			return null;
		}

		const contact = 'contact' in this.props.event.them ? this.props.event.them.contact : undefined;

		return (
			<C2DButton
				isReturningCall
				number={this.props.event.them.number}
				contact={contact}
				translate={this.props.translate}
				onClick2Dial={this.props.onClick2Dial}
				domain={this.props.domain}
			/>
		);
	}

	private renderSmsButton() {
		const number = this.props.event.them.number;
		if (
			!this.props.event.incoming ||
			!this.props.onAnswerWithSms ||
			!number ||
			!isMobileNumber(number, this.props.domain)
		) {
			return null;
		}

		return (
			<Button
				onClick={() => {
					if (this.props.onAnswerWithSms) {
						this.props.onAnswerWithSms(number);
					}
				}}
			>
				{this.props.translate('ANSWER_WITH_SMS_BUTTON')}
			</Button>
		);
	}

	private renderContactButton() {
		if (
			('contact' in this.props.event.them && this.props.event.them.contact) ||
			!this.props.event.them.number
		) {
			return null;
		}

		if (this.props.onAddNumberToContact) {
			return (
				<>
					<Button onClick={this.onNewContact}>
						{this.props.translate('SAVE_NUMBER_TO_CONTACT')}
					</Button>

					<Button onClick={this.onAddNumberToContact}>
						{this.props.translate('ADD_TO_CONTACT')}
					</Button>
				</>
			);
		}
	}

	private renderClick2PlayButton() {
		if (!this.props.onClick2Play) {
			return null;
		}

		return (
			<Button onClick={this.onClick2Play}>{this.props.translate('VOICEMAIL_CLICK_2_PLAY')}</Button>
		);
	}

	private renderTranscribeButton() {
		const voicemail = this.props.event.endpoints.find(element => element.type === 'VOICEMAIL');

		if (!voicemail) {
			return null;
		}

		if (
			!hasRestriction(this.props.restrictions.items, 'CAN_CHANGE_VOICEMAIL_SETTINGS', voicemail.id)
		) {
			return null;
		}

		return (
			<Button onClick={this.onTranscribe}>
				{this.props.event.originalEvent.transcription
					? this.props.translate('VOICEMAIL_RETRANSCRIBE')
					: this.props.translate('VOICEMAIL_TRANSCRIBE')}
			</Button>
		);
	}

	private renderActionButtons() {
		return (
			<div className={classes.actionButtons}>
				{this.renderClick2DialButton()}
				{this.renderSmsButton()}
				{this.renderContactButton()}
				{this.renderClick2PlayButton()}
				{this.renderTranscribeButton()}
			</div>
		);
	}

	private renderInlineText() {
		if (this.props.event.originalEvent.note) {
			return this.props.event.originalEvent.note;
		}

		if (this.props.event.originalEvent.transcription) {
			return `${this.props.translate('VOICEMAIL_TRANSCRIPTION_HEAD')}: ${
				this.props.event.originalEvent.transcription
			}`;
		}

		return '';
	}

	public render() {
		return (
			<Event
				date={this.props.event.date}
				source={getLocalizedEventSource(this.props.event, this.props.domain, this.props.translate)}
				target={getLocalizedEventTarget(this.props.event, this.props.domain, this.props.translate)}
				eventId={this.props.event.originalEvent.id}
				eventType="VOICEMAIL"
				onRead={this.props.onRead}
				onClose={this.onClose}
				onNoteChange={this.props.onNoteChange}
				translate={this.props.translate}
				read={this.props.event.originalEvent.read === 'READ'}
				length={formatDuration(this.props.event.originalEvent.duration)}
				note={this.props.event.originalEvent.note || undefined}
				systemLabels={this.renderSystemLabels()}
				incoming
				selected={this.props.event.selected}
				onSelection={this.props.onSelection}
				onStar={this.props.onStar}
				starred={this.props.event.originalEvent.starred === 'STARRED'}
				labels={this.props.event.originalEvent.labels}
				inlineText={this.renderInlineText()}
				impersonated={this.props.impersonated}
			>
				{this.renderDetailHeadline()}
				{this.renderDetail()}
				{this.renderAudioPlayer()}
				{this.renderActionButtons()}
			</Event>
		);
	}
}

export default withDialogs(VoicemailEvent);
