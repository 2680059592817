import { DateTime } from 'luxon';
import { AudioFileState } from './types';
import * as actions from './actions';
import { handleActions } from '../..';

const initialState: AudioFileState = {
	items: [],
	fetchedForOwner: [],
	fetchingForOwner: [],
	creatingAudioFileForOwner: [],
};

export default handleActions<AudioFileState, PossibleActions<typeof actions>>(
	{
		AUDIO_FILES_FETCH_PENDING: (state, action) => ({
			...state,
			fetchingForOwner: [...state.fetchingForOwner, action.data.ownerId],
		}),

		AUDIO_FILES_FETCH_SUCCESS: (state, { payload, data }) => {
			return {
				...state,

				items: [
					...state.items.filter(item => item.ownerId !== data.ownerId),
					...payload.items.map(item => ({
						id: item.id,
						alias: item.alias,
						url: state.items.find(stateItem => stateItem.id === item.id)?.url ?? {
							state: 'UNFETCHED' as const,
						},
						ownerId: data.ownerId,
						recordingEnabled: item.recordingEnabled,
						initial: item.initial,
						holidayDefault: item.holidayDefault,
					})),
				],

				fetchedForOwner: [
					...state.fetchedForOwner.filter(fetched => fetched.ownerId !== data.ownerId),
					{ ownerId: data.ownerId, timestamp: DateTime.now() },
				],
				fetchingForOwner: state.fetchingForOwner.filter(id => id !== data.ownerId),
			};
		},

		AUDIO_FILE_CREATE_PENDING: (state, { data }) => ({
			...state,
			creatingAudioFileForOwner: [...state.creatingAudioFileForOwner, data.voicemailId],
		}),

		AUDIO_FILE_CREATE_SUCCESS: (state, { payload, data }) => ({
			...state,

			creatingAudioFileForOwner: state.creatingAudioFileForOwner.filter(
				v => v !== data.voicemailId
			),

			items: [
				...state.items.filter(item => item.id !== payload.id),
				{
					id: payload.id,
					alias: payload.alias,
					recordingEnabled: payload.recordingEnabled,
					ownerId: data.voicemailId,
					active: false,
					url: { state: 'UNFETCHED' as const },
					initial: false,
					holidayDefault: false,
				},
			],
		}),

		AUDIO_FILE_DELETE_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.filter(audioFile => audioFile.id !== data.audioFileId),
			};
		},

		AUDIO_FILE_SET_ALIAS_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						alias: data.alias,
					};
				}),
			};
		},

		AUDIO_FILE_SET_RECORDING_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						recordingEnabled: data.recordingEnabled,
					};
				}),
			};
		},

		AUDIO_FILE_FETCH_CONTENT_PENDING: (state, { data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'FETCHING' as const },
					};
				}),
			};
		},

		AUDIO_FILE_FETCH_CONTENT_SUCCESS: (state, { payload, data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'FETCHED' as const, url: URL.createObjectURL(payload) },
					};
				}),
			};
		},

		AUDIO_FILE_FETCH_CONTENT_FAILURE: (state, { data }) => {
			return {
				...state,
				items: state.items.map(audioFile => {
					if (audioFile.id !== data.audioFileId) {
						return audioFile;
					}

					return {
						...audioFile,
						url: { state: 'ERROR' as const, timestamp: DateTime.now() },
					};
				}),
			};
		},
	},
	initialState
);
