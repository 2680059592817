import React from 'react';
import { Button } from '@panda/ui';
import MediaQueryDecorator, { MediaQueryProps } from '../../../../utils/MediaQueryDecorator';
import { DeprecatedDialogContent } from '../../DeprecatedDialogContent';
import { Translate } from '../../../../redux/slices/translations';
import IdentityVerificationAbortIcon from '../../../../media/icons/icon-verification-abort-dark-line-default-64.svg';
import VideoIdentDelayHint from './components/VideoIdentDelayHint';
import classes from './VideoIdentAbortedDialog.scss';

interface ExternalProps {
	translate: Translate;
	videoIdentLink?: string;
	autoIdentLink?: string;
	showIdnowDelayWarning: boolean;
}

type Props = ExternalProps & MediaQueryProps;

class VideoIdentAbortedDialog extends React.Component<Props> {
	private redirectToIdNow = (url: string) => {
		window.open(url, '_blank');
	};

	private renderButtons() {
		if (this.props.showIdnowDelayWarning) {
			return null;
		}

		return (
			<div className={classes.buttonContainer}>
				{this.props.videoIdentLink && (
					<Button
						onClick={() => this.redirectToIdNow(this.props.videoIdentLink!)}
						size="xlarge"
						width="max-on-touch-device"
					>
						{this.props.translate('IDENTITY_VERIFICATION_START_VIDEO_IDENT_DIALOG_BUTTON')}
					</Button>
				)}
				{this.props.autoIdentLink && (
					<Button
						onClick={() => this.redirectToIdNow(this.props.autoIdentLink!)}
						size="xlarge"
						width="max-on-touch-device"
					>
						{this.props.translate('IDENTITY_VERIFICATION_START_AUTO_IDENT_DIALOG_BUTTON')}
					</Button>
				)}
			</div>
		);
	}

	public render() {
		return (
			<DeprecatedDialogContent
				icon={<img src={IdentityVerificationAbortIcon} alt="" />}
				headline={this.props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_ABORTED_DIALOG_HEADLINE')}
				button={this.renderButtons()}
				hintText={
					<VideoIdentDelayHint
						translate={this.props.translate}
						showIdnowDelayWarning={this.props.showIdnowDelayWarning}
					/>
				}
			>
				<div className={classes.content}>
					{this.props.translate('IDENTITY_VERIFICATION_VIDEO_IDENT_ABORTED_DIALOG_TEXT')}
				</div>
			</DeprecatedDialogContent>
		);
	}
}

export default MediaQueryDecorator(VideoIdentAbortedDialog);
