interface PollInstance {
	instance: number | undefined;
	fn: () => void;
}

let config = {
	interval: 1000 * 60 * 60,
};

const FETCH_CONFIG_INTERVAL = 1000 * 60 * 5;
const intervalMap = new Map<string, PollInstance>();
const INTERVAL_URL = 'https://static.sipgate.com/app-web/interval.json';

const getPollerConfig = () => {
	fetch(INTERVAL_URL)
		.then(response => response.text())
		.then(response => JSON.parse(response))
		.then(response => {
			if (response.interval !== config.interval) {
				config = response;
				intervalMap.forEach((poller, key) => {
					clearInterval(poller.instance);
					const instance = window.setInterval(poller.fn, config.interval);
					intervalMap.set(key, { instance, fn: poller.fn });
				});
			}
		});
};

export const startPollingService = () => {
	setInterval(getPollerConfig, FETCH_CONFIG_INTERVAL);
	getPollerConfig();
};

export const registerPoller = (fn: () => void, interval = config.interval) => {
	const key = Math.random().toString(36).slice(2, 12);
	const instance = window.setInterval(fn, interval);
	intervalMap.set(key, {
		instance,
		fn,
	});
	return key;
};

export const deregisterPoller = (key: string) => {
	if (!key) {
		return;
	}
	const poller = intervalMap.get(key);
	if (!poller) {
		return;
	}
	clearInterval(poller.instance);
	intervalMap.delete(key);
};
