import { handleActions } from '../..';
import { IdentityVerificationState } from './types';
import * as actions from './actions';
import { ApiError } from '../../../api';
import { IdentityVerificationError } from '.';

const initialState: IdentityVerificationState = {
	fetched: false,
	fetching: false,

	data: null,
	error: null,
	videoIdentCompletedWithinSession: false,
};

function toIdentError(err: ApiError): IdentityVerificationError {
	switch (err.status) {
		case 404:
			return 'NOT_FOUND';
		case 503:
			return 'UNAVAILABLE';
		default:
			return 'OTHER';
	}
}

export default handleActions<IdentityVerificationState, PossibleActions<typeof actions>>(
	{
		IDENTITY_VERIFICATION_FETCH_PENDING: state => ({
			...state,
			fetching: true,
		}),

		IDENTITY_VERIFICATION_FETCH_FAILURE: (state, { payload }) => {
			return {
				fetched: false,
				fetching: false,

				data: null,
				error: payload instanceof ApiError ? toIdentError(payload) : null,
				videoIdentCompletedWithinSession: false,
			};
		},

		IDENTITY_VERIFICATION_FETCH_SUCCESS: (state, { payload, data }) => {
			if (!payload) {
				return {
					fetching: false,
					fetched: true,

					data: {
						data: null,
						shouldBeCaged: false,
						verified: true,
					},
					error: null,
					videoIdentCompletedWithinSession: false,
				};
			}

			return {
				fetching: false,
				fetched: true,

				data: {
					data: payload,
					shouldBeCaged: data.shouldBeCaged && payload.state !== 'SUCCESS',
					verified: payload.state === 'SUCCESS',
				},
				error: null,

				videoIdentCompletedWithinSession:
					(payload.state !== 'PENDING' && state.data?.data?.state === 'PENDING') ||
					state.videoIdentCompletedWithinSession,
			};
		},

		IDENTITY_VERIFICATION_SET_AFTER_VIDEO_IDENT: (
			state,
			{ payload: videoIdentCompletedWithinSession }
		) => {
			return {
				...state,
				videoIdentCompletedWithinSession,
			};
		},
	},
	initialState
);
