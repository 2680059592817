import { Sms, SmsState } from './types';

export const selectSms = (sms: SmsState, smsId: string) =>
	sms.items.find(item => item.id === smsId);

export function selectFirstSmsId(sms: Sms[]) {
	return sms.length > 0 ? sms[0].id : undefined;
}

export function selectSmsForUser(sms: Sms[], userId: string) {
	return sms.filter(item => item.owner === userId);
}

export function areSmsFetchedForUser(sms: SmsState, webuserId: string) {
	return sms.fetchedForUser.includes(webuserId);
}
