import { DateTime } from 'luxon';
import { createSlice } from '@reduxjs/toolkit';
import { fetchHolidayPresets } from './actions';
import { HolidayPresetState } from './types';

const initialState: HolidayPresetState = {
	items: [],
	fetched: false,
	fetching: false,
};

export const reducer = createSlice({
	name: 'holidayPresets',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchHolidayPresets.pending, state => ({
			...state,
			fetching: true,
		}));
		builder.addCase(fetchHolidayPresets.fulfilled, (state, action) => ({
			...state,
			fetched: true,
			fetching: false,
			items: action.payload.items.map(item => ({
				...item,
				upcomingHolidays: item.upcomingHolidays
					.map(holiday => ({
						...holiday,
						id: holiday.holidayId,
						date: DateTime.fromISO(holiday.date, { zone: 'utc' }),
						observedDate: DateTime.fromISO(holiday.observedDate, { zone: 'utc' }),
					}))
					.sort((a, b) => a.date.toMillis() - b.date.toMillis()),
			})),
		}));
	},
}).reducer;
