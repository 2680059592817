import { createSlice } from '@reduxjs/toolkit';
import {
	closeAutoCloseInfoSnackbar,
	closeAutoCloseSuccessSnackbar,
	closeClickToDialSnackbar,
	closeErrorSnackbar,
	closeInsufficientFundsSnackbar,
	closeInvalidAccountStateSnackbar,
	closeWarningSnackbar,
	openAutoCloseInfoSnackbar,
	openAutoCloseSuccessSnackbar,
	openClickToDialSnackbar,
	openDialog,
	openErrorSnackbar,
	openInsufficientFundsSnackbar,
	openInvalidAccountStateSnackbar,
	openWarningSnackbar,
	SnackbarState,
} from './index';
import { closeDialog } from './actions';

export const initialState: SnackbarState = {
	dialogCount: 0,
	warningSnackbarMessage: '',
	warningSnackbarOpen: false,
	errorSnackbarMessage: '',
	errorSnackbarOpen: false,
	invalidAccountStateSnackbarOpen: false,
	insufficientFundsSnackbarOpen: false,
	autoCloseInfoSnackbarOpen: false,
	autoCloseSuccessSnackbarOpen: false,
	autoCloseInfoSnackbarMessage: '',
	autoCloseSuccessSnackbarMessage: '',
	clickToDialSession: undefined,
};

const slice = createSlice({
	name: 'snackbar',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(openDialog, state => ({
			...state,
			dialogCount: state.dialogCount + 1,
		}));
		builder.addCase(closeDialog, state => ({
			...state,
			dialogCount: state.dialogCount - 1,
		}));
		builder.addCase(openWarningSnackbar, (state, { payload }) => ({
			...state,
			warningSnackbarOpen: true,
			warningSnackbarMessage: payload,
		}));
		builder.addCase(openErrorSnackbar, (state, { payload }) => ({
			...state,
			errorSnackbarOpen: true,
			errorSnackbarMessage: payload,
		}));
		builder.addCase(closeWarningSnackbar, state => ({
			...state,
			warningSnackbarOpen: false,
			warningSnackbarMessage: '',
		}));
		builder.addCase(closeErrorSnackbar, state => ({
			...state,
			errorSnackbarOpen: false,
			errorSnackbarMessage: '',
		}));
		builder.addCase(openInvalidAccountStateSnackbar, state => ({
			...state,
			invalidAccountStateSnackbarOpen: true,
		}));
		builder.addCase(closeInvalidAccountStateSnackbar, state => ({
			...state,
			invalidAccountStateSnackbarOpen: false,
		}));
		builder.addCase(openAutoCloseInfoSnackbar, (state, { payload }) => ({
			...state,
			autoCloseInfoSnackbarOpen: true,
			autoCloseInfoSnackbarMessage: payload,
		}));
		builder.addCase(closeAutoCloseInfoSnackbar, state => ({
			...state,
			autoCloseInfoSnackbarOpen: false,
			autoCloseInfoSnackbarMessage: '',
		}));
		builder.addCase(openAutoCloseSuccessSnackbar, (state, { payload }) => ({
			...state,
			autoCloseSuccessSnackbarOpen: true,
			autoCloseSuccessSnackbarMessage: payload,
		}));
		builder.addCase(closeAutoCloseSuccessSnackbar, state => ({
			...state,
			autoCloseSuccessSnackbarOpen: false,
			autoCloseSuccessSnackbarMessage: '',
		}));
		builder.addCase(openInsufficientFundsSnackbar, state => ({
			...state,
			insufficientFundsSnackbarOpen: true,
		}));
		builder.addCase(closeInsufficientFundsSnackbar, state => ({
			...state,
			insufficientFundsSnackbarOpen: false,
		}));
		builder.addCase(openClickToDialSnackbar, (state, { payload }) => ({
			...state,
			clickToDialSession: {
				sessionId: payload.sessionId,
				alias: payload.alias,
			},
		}));
		builder.addCase(closeClickToDialSnackbar, state => ({
			...state,
			clickToDialSession: undefined,
		}));
	},
});

export const reducer = slice.reducer;
