import {
	SideNavigationItem,
	SideNavigationMenu,
	SideNavigationMenuGroup,
	SideNavigationMenuLink,
} from '../../navigation/SideNavigation/SideNavigation.types';
import * as paths from '../../routes/paths';
import eventListIcon from './userMenuIcons/eventList.svg';
import presenceIcon from './userMenuIcons/presence.svg';
import acdIcon from './userMenuIcons/acd.svg';
import contactsIcon from './userMenuIcons/contacts.svg';
import devicesIcon from './userMenuIcons/devices.svg';
import routingIcon from './userMenuIcons/routing.svg';
import settingsIcon from './userMenuIcons/settings.svg';
import { ApiLinks } from '../../api/types/links';
import {
	hasAnyTargetedRestriction,
	hasRestriction,
	RestrictionState,
} from '../../redux/modules/restrictions';
import { AppProperties } from '../../redux/slices/appProperties';

const getSettingsGroup = (links: ApiLinks): SideNavigationMenuGroup => {
	const groupLinks: SideNavigationMenuLink[] = [];

	if (links.notificationsUrl) {
		groupLinks.push({
			kind: 'LINK',
			path: paths.userNotificationsPath.build(),
			title: 'NOTIFICATIONS',
		});
	}

	groupLinks.push({
		kind: 'LINK',
		path: paths.blocklistPath.build(),
		title: 'BLACKLIST',
	});

	if (links.personalSettingsUrl) {
		groupLinks.push({
			kind: 'LINK',
			path: paths.personalSettingsPath.build(),
			title: 'PERSONAL_SETTINGS',
		});
	}

	return {
		kind: 'GROUP',
		icon: settingsIcon,
		title: 'SETTINGS',
		links: groupLinks,
	};
};

export const getUserMenuLinks = (
	links: ApiLinks,
	restrictions: RestrictionState,
	appProperties: AppProperties
): SideNavigationMenu => {
	const items: SideNavigationItem[] = [];

	items.push({
		kind: 'LINK',
		path: paths.eventlistPath.build(),
		title: 'EVENTLISTVIEW_TITLE',
		icon: eventListIcon,
	});

	if (hasAnyTargetedRestriction(restrictions.items, 'CAN_SEE_ACD_DASHBOARD')) {
		items.push({
			kind: 'LINK',
			path: paths.acdTeamLeadOverviewPath.build(),
			title: 'CHANNEL_TEAM_LEAD_OVERVIEW',
			icon: acdIcon,
		});
	}

	if (links.presenceContainerUrl && hasRestriction(restrictions.items, 'CAN_USE_WEB_BLF')) {
		items.push({
			kind: 'LINK',
			path: links.presenceContainerUrl,
			title: 'PRESENCE',
			icon: presenceIcon,
		});
	}

	items.push({
		kind: 'LINK',
		path: paths.contactsPath.build(),
		title: 'CONTACTS',
		icon: contactsIcon,
	});

	items.push({
		kind: 'LINK',
		path: paths.routingPath.build(),
		title: 'APP_NAVIGATION_ROUTING',
		icon: routingIcon,
	});

	if (appProperties.showSimRelevantOptions) {
		items.push({
			kind: 'LINK',
			path: paths.devicePoolPath.build(),
			title: 'DEVICE_POOL',
			icon: devicesIcon,
		});
	}
	items.push(getSettingsGroup(links));

	return { items };
};
