import { Store } from '@reduxjs/toolkit';

import { State, PAGE_SIZE_LOCALSTORAGE_KEY } from '../state';
import { ChangePageSizeAction } from './types';
import { fetchHistory } from '../history/fetchHistory/action';
import { Selections } from '../../selections/parsers';

export const changePageSize = (store: Store<State>) => (size: number, selections: Selections) => {
	localStorage.setItem(PAGE_SIZE_LOCALSTORAGE_KEY, size.toString(10));

	store.dispatch<ChangePageSizeAction>({
		type: 'CHANGE_PAGE_SIZE',
		size,
	});

	fetchHistory(store)(selections);
};
