import { handleActions } from '../..';
import { VoicemailState } from './types';
import * as actions from './actions';
import { deleteAudioFile } from '../audioFiles/actions';
import { setGroupAlias } from '../groups/actions';

const initialState: VoicemailState = {
	fetchingForUser: [],
	fetchedForUser: [],
	fetchingForAccount: false,
	fetchedForAccount: false,
	items: [],
};

export default handleActions<
	VoicemailState,
	PossibleActions<typeof actions | typeof deleteAudioFile | typeof setGroupAlias>
>(
	{
		VOICEMAILS_FOR_ACCOUNT_FETCH_PENDING: state => ({
			...state,
			fetchingForAccount: true,
		}),

		VOICEMAILS_FOR_ACCOUNT_FETCH_SUCCESS: (state, { payload }) => {
			const newIds = new Set(payload.items.map(v => v.id));

			const updatedItems = payload.items.map(apiVoicemail => {
				const oldVoicemail = state.items.find(v => v.id === apiVoicemail.id);

				if (oldVoicemail) {
					return {
						id: apiVoicemail.id,
						alias: apiVoicemail.alias,
						transcription: apiVoicemail.transcription,
						parentExtension: apiVoicemail.parentExtension,
						accessNumber: apiVoicemail.accessNumber,
						pin: [...oldVoicemail.pin],
						authorizedNumbers: [...oldVoicemail.authorizedNumbers],
						activeAudioFileId: apiVoicemail.activeGreetingId,
					};
				}

				return {
					id: apiVoicemail.id,
					alias: apiVoicemail.alias,
					transcription: apiVoicemail.transcription,
					parentExtension: apiVoicemail.parentExtension,
					accessNumber: apiVoicemail.accessNumber,
					pin: [],
					authorizedNumbers: [],
					activeAudioFileId: apiVoicemail.activeGreetingId,
				};
			});

			return {
				...state,
				items: [...state.items.filter(v => !newIds.has(v.id)), ...updatedItems],
				fetchingForAccount: false,
				fetchedForAccount: true,
			};
		},

		VOICEMAILS_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForUser: [...state.fetchingForUser, data.userId],
		}),

		VOICEMAILS_FETCH_SUCCESS: (state, { data, payload }) => {
			const newIds = new Set(payload.items.map(v => v.id));

			const updatedItems = payload.items.map(apiVoicemail => {
				const oldVoicemail = state.items.find(v => v.id === apiVoicemail.id);

				if (oldVoicemail) {
					const newPin = [...oldVoicemail.pin.filter(pin => pin.userId !== data.userId)];
					if (apiVoicemail.pin) {
						newPin.push({
							pin: apiVoicemail.pin,
							userId: data.userId,
						});
					}

					const newAuthorizedNumbers = [
						...oldVoicemail.authorizedNumbers.filter(number => number.userId !== data.userId),
					];
					if (apiVoicemail.authorizedNumbers) {
						newAuthorizedNumbers.push({
							authorizedNumbers: apiVoicemail.authorizedNumbers,
							userId: data.userId,
						});
					}

					return {
						id: apiVoicemail.id,
						alias: apiVoicemail.alias,
						transcription: apiVoicemail.transcription,
						parentExtension: apiVoicemail.parentExtension,
						accessNumber: apiVoicemail.accessNumber,
						pin: newPin,
						authorizedNumbers: newAuthorizedNumbers,
						activeAudioFileId: apiVoicemail.activeGreetingId,
					};
				}

				const newPin = [];
				if (apiVoicemail.pin) {
					newPin.push({
						userId: data.userId,
						pin: apiVoicemail.pin,
					});
				}

				const newAuthorizedNumbers = [];
				if (apiVoicemail.authorizedNumbers) {
					newAuthorizedNumbers.push({
						authorizedNumbers: apiVoicemail.authorizedNumbers,
						userId: data.userId,
					});
				}

				return {
					id: apiVoicemail.id,
					alias: apiVoicemail.alias,
					transcription: apiVoicemail.transcription,
					parentExtension: apiVoicemail.parentExtension,
					accessNumber: apiVoicemail.accessNumber,
					pin: newPin,
					authorizedNumbers: newAuthorizedNumbers,
					activeAudioFileId: apiVoicemail.activeGreetingId,
				};
			});

			return {
				...state,
				fetchingForUser: state.fetchingForUser.filter(id => id !== data.userId),
				fetchedForUser: [...state.fetchedForUser, data.userId],
				items: [...state.items.filter(v => !newIds.has(v.id)), ...updatedItems],
			};
		},

		VOICEMAILS_RESET: () => initialState,

		VOICEMAIL_CREATE_SUCCESS: (state, { payload, data }) => {
			const newPin = [];
			if (payload.pin) {
				newPin.push({
					pin: payload.pin,
					userId: data.userId,
				});
			}

			const newAuthorizedNumbers = [];
			if (payload.authorizedNumbers) {
				newAuthorizedNumbers.push({
					authorizedNumbers: payload.authorizedNumbers,
					userId: data.userId,
				});
			}

			return {
				...state,
				items: [
					...state.items.filter(v => v.id !== payload.id),
					{
						...payload,
						pin: newPin,
						authorizedNumbers: newAuthorizedNumbers,
						activeAudioFileId: payload.activeGreetingId,
					},
				],
			};
		},

		AUDIO_FILE_DELETE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(voicemail => {
				if (voicemail.activeAudioFileId !== data.audioFileId) {
					return voicemail;
				}

				return {
					...voicemail,
					activeAudioFileId: null,
				};
			}),
		}),

		VOICEMAIL_UPDATE_TRANSCRIPTION_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(voicemail => {
				if (voicemail.id !== data.voicemailId) {
					return voicemail;
				}

				return {
					...voicemail,
					transcription: data.active,
				};
			}),
		}),

		VOICEMAIL_SET_ALIAS_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(voicemail => {
				if (voicemail.id !== data.voicemailId) {
					return voicemail;
				}

				return {
					...voicemail,
					alias: data.alias,
				};
			}),
		}),

		VOICEMAIL_SET_PIN_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(voicemail => {
				if (voicemail.id !== data.voicemailId) {
					return voicemail;
				}

				return {
					...voicemail,
					pin: [
						...voicemail.pin.filter(pin => pin.userId !== data.userId),
						{
							userId: data.userId,
							pin: { isSet: true, pin: data.loggedInUserId === data.userId ? data.pin : undefined },
						},
					],
				};
			}),
		}),

		VOICEMAIL_DELETE_PIN_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(voicemail => {
				if (voicemail.id !== data.voicemailId) {
					return voicemail;
				}

				return {
					...voicemail,
					pin: [
						...voicemail.pin.filter(pin => pin.userId !== data.userId),
						{
							userId: data.userId,
							pin: { isSet: false },
						},
					],
				};
			}),
		}),

		VOICEMAIL_SET_AUTHORIZED_NUMBERS_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(voicemail => {
				if (voicemail.id !== data.voicemailId) {
					return voicemail;
				}

				return {
					...voicemail,
					authorizedNumbers: [
						...voicemail.authorizedNumbers.filter(numbers => numbers.userId !== data.userId),
						{
							userId: data.userId,
							authorizedNumbers: data.numbers,
						},
					],
				};
			}),
		}),

		VOICEMAIL_SET_ACTIVE_AUDIOFILE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(voicemail => {
				if (voicemail.id !== data.voicemailId) {
					return voicemail;
				}

				return {
					...voicemail,
					activeAudioFileId: data.audioFileId,
				};
			}),
		}),

		AUDIO_FILE_DELETE_SUCCESS: (state, { data }) => {
			if (!data.ownerId.startsWith('v')) {
				return { ...state };
			}
			return {
				...state,
				items: state.items.map(voicemail => {
					if (voicemail.id !== data.ownerId || voicemail.activeAudioFileId !== data.audioFileId) {
						return voicemail;
					}
					return {
						...voicemail,
						activeAudioFileId: null,
					};
				}),
			};
		},

		GROUP_ALIAS_SET_SUCCESS: (state, { data }) => {
			return {
				...state,
				items: state.items.map(voicemail => {
					if (voicemail.parentExtension.extension === data.groupId) {
						return {
							...voicemail,
							alias: voicemail.alias.replace(data.oldAlias, data.alias),
						};
					}
					return voicemail;
				}),
			};
		},
	},
	initialState
);
