import React from 'react';

interface TableBodyContextType {
	rowIndex: number;
	isActiveRow: boolean;
}

export const TableBodyContext = React.createContext<TableBodyContextType | undefined>(undefined);

export const useTableBodyContext = () => {
	const context = React.useContext(TableBodyContext);

	if (context === undefined) {
		throw new Error('useTableBodyContext needs to be used inside a TableBodyContext.Provider');
	}

	return context;
};
