import { OptGroup, Option, Select } from '@panda/ui';
import React, { useEffect } from 'react';

import { SipgateCsvMappingSuggestions } from '../../api/types/contacts';

import { useTranslate } from '../../redux/slices/translations';

import { useOnScreen } from '../../utils/onScreenHelper';
import {
	CSVMappingColumnGroup,
	CSVMappingColumnOption,
	CSVMappingType,
	getCSVColumnMappingKindsByOptionAndType,
	getCSVColumnMappingOptionsByKind,
} from './CSVMappingHelpers';
import { useContacts } from '../../redux/modules/contacts/hooks';

type Props = {
	column: string;
	preview: string;
	type: CSVMappingType;
	rowIndex: number;
	error?: string;
};

const CSVMappingRow = ({ column, type, preview, rowIndex, error }: Props) => {
	const ref = React.useRef(null);
	const isInViewport = useOnScreen(ref, '');
	const [option, setOption] = React.useState<SipgateCsvMappingSuggestions>('');
	const translate = useTranslate();
	const [kind, setKind] = React.useState('');

	const { importMapper, updateColumnMapping } = useContacts();
	const columnOptions = getCSVColumnMappingOptionsByKind(type);
	const kindOptions = getCSVColumnMappingKindsByOptionAndType(option, type);

	useEffect(() => {
		if (option === 'SG_NOOP') {
			setKind('');
		} else {
			const maybeKind = kindOptions.find(opt => {
				if ('options' in opt) return false;
				return true;
			}) as CSVMappingColumnOption | undefined;
			setKind(maybeKind?.value || '');
		}
	}, [kindOptions, option]);

	useEffect(() => {
		if (option === '') return;
		const hasType = kindOptions.length > 0;
		const proposedValue = hasType ? `${option}_${kind}` : option;
		updateColumnMapping(rowIndex, proposedValue as SipgateCsvMappingSuggestions);
	}, [kind, kindOptions.length, option, rowIndex, updateColumnMapping]);

	useEffect(() => {
		if (importMapper) {
			const proposedValue = importMapper.columns[rowIndex];
			if (
				proposedValue.includes('SG_') &&
				proposedValue.includes('_') &&
				proposedValue.split('_').length > 2
			) {
				const proposalParts = proposedValue.split('_');
				setOption(`${proposalParts[0]}_${proposalParts[1]}` as SipgateCsvMappingSuggestions);
				setKind(`${proposalParts[2]}${proposalParts.length > 3 ? `_${proposalParts[3]}` : ''}`);
				return;
			}
			setOption(proposedValue);
		}
	}, [rowIndex, importMapper]);

	const renderOption = (opt: CSVMappingColumnOption | CSVMappingColumnGroup) => {
		if ('options' in opt) {
			return <OptGroup label={translate(opt.labelLK)}>{opt.options.map(renderOption)}</OptGroup>;
		}

		return (
			<Option key={opt.value} value={opt.value}>
				{translate(opt.labelLK)}
			</Option>
		);
	};

	return (
		<tr ref={ref}>
			{isInViewport && (
				<>
					<td>{column}</td>
					<td>{preview}</td>
					<td>
						<Select
							placeholder="Option wählen"
							value={option}
							error={error}
							onChange={value => {
								setOption(value as SipgateCsvMappingSuggestions);
							}}
						>
							{columnOptions.map(renderOption)}
						</Select>
					</td>
					<td>
						{kindOptions.length > 0 ? (
							<Select placeholder="Option wählen" value={kind} onChange={setKind}>
								{kindOptions.map(renderOption)}
							</Select>
						) : (
							<></>
						)}
					</td>
					<td />
				</>
			)}
		</tr>
	);
};

export default CSVMappingRow;
