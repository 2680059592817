import { createAction } from '../..';

import api from '../../../api';
import {
	CreateUserNotificationRequest,
	ReplaceUserNotificationRequest,
} from '../../../api/types/userNotification';
import { ReduxState } from '../../types';

function shouldFetchUserNotifications(state: ReduxState) {
	return !state.userNotifications.fetching && !state.userNotifications.fetched;
}

export const fetchUserNotifications = createAction(
	'USERNOTIFICATIONS_FETCH',
	(force?: boolean) => ({
		promise: () => api.getUserNotifications(),
		shouldFetch: (state: ReduxState) => force || shouldFetchUserNotifications(state),
	})
);

export const createUserNotification = createAction(
	'USERNOTIFICATION_CREATE',
	(createUserNotificationRequest: CreateUserNotificationRequest) => ({
		promise: api.createUserNotification(createUserNotificationRequest),
	})
);

export const replaceUserNotification = createAction(
	'USERNOTIFICATION_REPLACE',
	(replaceUserNotificationRequest: ReplaceUserNotificationRequest) => ({
		promise: api.replaceUserNotification(replaceUserNotificationRequest),
	})
);

export const deleteUserNotification = createAction(
	'USERNOTIFICATION_DELETE',
	(notificationId: string) => ({
		promise: api.deleteUserNotification(notificationId),
		data: {
			notificationId,
		},
	})
);
