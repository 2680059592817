import { InternalLink } from '@panda/ui';
import React from 'react';
import { Translate } from '../../redux/slices/translations';

interface Props {
	accountSettingsUrl: string;
	renderButton: boolean;
	translate: Translate;
}

export class TimeToLiveSettingsLink extends React.Component<Props> {
	public render() {
		return (
			<InternalLink button={this.props.renderButton} to={this.props.accountSettingsUrl}>
				{this.props.translate('EVENT_LIST_TIME_TO_LIVE_EDIT_LINK')}
			</InternalLink>
		);
	}
}
