import { State } from '../../state';
import { ArchiveEntriesPendingAction } from './types';

export const archiveEntriesPending = (state: State, action: ArchiveEntriesPendingAction): State => {
	if (!state.entries.result) {
		return state;
	}

	const hasMoreEntries = state.entries.result.totalCount > action.eventIds.length;
	const archivedAllEntries = state.entries.result.normalized.length === action.eventIds.length;

	if (hasMoreEntries && archivedAllEntries) {
		return {
			...state,
			entries: {
				...state.entries,
				error: false,
				result: null,
			},
		};
	}

	return {
		...state,
		entries: {
			...state.entries,
			result: {
				...state.entries.result,
				normalized: state.entries.result.normalized.filter(
					entry => !action.eventIds.includes(entry.originalEvent.id)
				),
				totalCount: state.entries.result.totalCount - action.eventIds.length,
			},
		},
	};
};

export const archiveEntriesSuccess = (state: State): State => state;

export const archiveEntriesError = (state: State): State => state;
