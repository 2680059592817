import React from 'react';
import moment from 'moment-timezone';

import { Button } from '@panda/ui';
import { isMobileNumber, localizeNumber } from '@web-apps/phonenumbers-utils';
import { Event } from './Event';
import { NormalizedEvent } from '../normalize/events';

import { Translate } from '../../../redux/slices/translations';

import classes from './SmsEvent.scss';
import eventClasses from './Event.scss';
import { C2DButton } from '../buttons/C2DButton';
import { SystemLabel } from '../../../components/SystemLabel';
import { formatExtension, getLocalizedEventSource, getLocalizedEventTarget } from '../util';
import { SmsHistoryEntry } from '../../../api/types/events';
import { SipgateDomain } from '../../../redux/modules/userinfo';

interface Props {
	event: NormalizedEvent<SmsHistoryEntry>;
	domain: SipgateDomain;
	onRead: (eventIds: string[]) => void;
	onClick2Dial: (number: string) => void;
	onAnswerWithSms?: (number: string) => void;
	onNewContact: (number: string) => void;
	onAddNumberToContact?: (number: string) => void;
	onNoteChange: (eventId: string, note: string) => void;
	onSelection: (eventId: string) => void;
	onStar: (eventId: string, starred: boolean) => void;
	translate: Translate;
	impersonated: boolean;
}

export class SmsEvent extends React.PureComponent<Props> {
	private onNewContact = () => {
		if (this.props.event.them.number) {
			this.props.onNewContact(this.props.event.them.number);
		}
	};

	private onAddNumberToContact = () => {
		if (this.props.onAddNumberToContact && this.props.event.them.number) {
			this.props.onAddNumberToContact(this.props.event.them.number);
		}
	};

	private renderDetailHeadline() {
		const direction = this.props.event.incoming
			? this.props.translate('INCOMING_SMS')
			: this.props.translate('OUTGOING_SMS');

		const formattedDate = moment(this.props.event.originalEvent.dateCreated).calendar();
		const lowercasedFormattedDate = formattedDate[0].toLocaleLowerCase() + formattedDate.slice(1);

		return (
			<p>
				<span className={classes.bold}>{direction}</span> {lowercasedFormattedDate}
			</p>
		);
	}

	private renderScheduledDetailHeadline() {
		const formattedScheduledDate = moment(this.props.event.scheduledDate).calendar();
		const lowercasedFormattedScheduledDate =
			formattedScheduledDate[0].toLocaleLowerCase() + formattedScheduledDate.slice(1);

		return (
			<p>
				<span className={classes.bold}>{this.props.translate('SCHEDULED_SMS')}</span>{' '}
				{this.props.translate('SCHEDULED_FOR')} {lowercasedFormattedScheduledDate}
			</p>
		);
	}

	private renderIncomingSourceLine() {
		if ('contact' in this.props.event.them) {
			return (
				<>
					{this.props.translate('SMS_FROM')}{' '}
					<span className={classes.bold}>{this.props.event.them.contact.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		if (this.props.event.source.name && this.props.event.them.number) {
			return (
				<>
					{this.props.translate('SMS_FROM')}{' '}
					<span className={classes.bold}>{this.props.event.source.name}</span>{' '}
					{localizeNumber(this.props.event.them.number, this.props.domain)}
				</>
			);
		}

		return (
			<>
				{this.props.translate('SMS_FROM')}{' '}
				<span className={classes.bold}>
					{getLocalizedEventSource(this.props.event, this.props.domain, this.props.translate)}{' '}
				</span>
			</>
		);
	}

	private renderIncomingDetail() {
		const endpoint = this.props.event.endpoints[0];
		const extension = formatExtension(this.props.translate, endpoint.type, endpoint.name);

		return (
			<p>
				{this.renderIncomingSourceLine()}
				<br />
				{this.props.translate('SMS_ON')} <span className={classes.bold}>{extension}</span>{' '}
				{this.props.event.us.number
					? `(${localizeNumber(this.props.event.us.number, this.props.domain)})`
					: null}
			</p>
		);
	}

	private renderOutgoingTargetLine() {
		if ('contact' in this.props.event.them) {
			return (
				<>
					{this.props.translate('SMS_TO')}{' '}
					<span className={classes.bold}>{this.props.event.them.contact.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		if (this.props.event.target.name && this.props.event.them.number) {
			return (
				<>
					{this.props.translate('SMS_TO')}{' '}
					<span className={classes.bold}>{this.props.event.target.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		return (
			<>
				{this.props.translate('SMS_TO')}{' '}
				<span className={classes.bold}>
					{getLocalizedEventTarget(this.props.event, this.props.domain, this.props.translate)}{' '}
				</span>
			</>
		);
	}

	private renderOutgoingDetail() {
		const endpoint = this.props.event.endpoints[0];
		const name = endpoint && endpoint.name ? endpoint.name : this.props.translate('EVENTLIST_ME');

		return (
			<p>
				{this.props.translate('SMS_FROM')} <span className={classes.bold}>{name}</span> <br />
				{this.renderOutgoingTargetLine()}
			</p>
		);
	}

	private renderContent() {
		return (
			<section>
				<p>{this.props.event.originalEvent.content}</p>
			</section>
		);
	}

	private renderClick2DialButton() {
		if (!this.props.event.them.number) {
			return null;
		}

		const contact = 'contact' in this.props.event.them ? this.props.event.them.contact : undefined;

		return (
			<C2DButton
				number={this.props.event.them.number}
				contact={contact}
				isReturningCall={false}
				translate={this.props.translate}
				onClick2Dial={this.props.onClick2Dial}
				domain={this.props.domain}
			/>
		);
	}

	private renderSmsButton() {
		const number = this.props.event.them.number;
		if (
			!this.props.event.incoming ||
			!this.props.onAnswerWithSms ||
			!number ||
			!isMobileNumber(number, this.props.domain)
		) {
			return null;
		}

		return (
			<Button
				onClick={() => {
					if (this.props.onAnswerWithSms) {
						this.props.onAnswerWithSms(number);
					}
				}}
			>
				{this.props.translate('ANSWER_WITH_SMS_BUTTON')}
			</Button>
		);
	}

	private renderContactButton() {
		if (
			('contact' in this.props.event.them && this.props.event.them.contact) ||
			!this.props.event.them.number
		) {
			return null;
		}

		if (this.props.onAddNumberToContact) {
			return (
				<>
					<Button onClick={this.onNewContact}>
						{this.props.translate('SAVE_NUMBER_TO_CONTACT')}
					</Button>

					<Button onClick={this.onAddNumberToContact}>
						{this.props.translate('ADD_TO_CONTACT')}
					</Button>
				</>
			);
		}
	}

	private renderActionButtons() {
		return (
			<div className={classes.actionButtons}>
				{this.renderClick2DialButton()}
				{this.renderSmsButton()}
				{this.renderContactButton()}
			</div>
		);
	}

	private renderSystemLabels() {
		const tags = [];

		if (this.props.event.scheduledDate) {
			const label = `${this.props.translate('SMS_STATUS_SCHEDULED')} ${moment(
				this.props.event.scheduledDate
			).format('L')}`;

			tags.push(
				<SystemLabel className={eventClasses.systemLabel} key="scheduled" intentness="weak">
					{label}
				</SystemLabel>
			);
		}

		return tags;
	}

	private renderInlineText() {
		if (this.props.event.originalEvent.note) {
			return this.props.event.originalEvent.note;
		}

		return `${this.props.translate('SMS_CONTENT_TITLE')}: ${
			this.props.event.originalEvent.content
		}`;
	}

	public render() {
		return (
			<Event
				date={this.props.event.date}
				eventId={this.props.event.originalEvent.id}
				incoming={this.props.event.incoming}
				onNoteChange={this.props.onNoteChange}
				read={this.props.event.originalEvent.read === 'READ'}
				onRead={this.props.onRead}
				eventType="SMS"
				source={getLocalizedEventSource(this.props.event, this.props.domain, this.props.translate)}
				target={getLocalizedEventTarget(this.props.event, this.props.domain, this.props.translate)}
				systemLabels={this.renderSystemLabels()}
				translate={this.props.translate}
				note={this.props.event.originalEvent.note || undefined}
				selected={this.props.event.selected}
				onSelection={this.props.onSelection}
				onStar={this.props.onStar}
				starred={this.props.event.originalEvent.starred === 'STARRED'}
				labels={this.props.event.originalEvent.labels}
				inlineText={this.renderInlineText()}
				impersonated={this.props.impersonated}
			>
				{this.props.event.scheduledDate
					? this.renderScheduledDetailHeadline()
					: this.renderDetailHeadline()}
				{this.props.event.incoming ? this.renderIncomingDetail() : this.renderOutgoingDetail()}
				{this.renderContent()}
				{this.renderActionButtons()}
			</Event>
		);
	}
}
