import { Sms } from '../redux/slices/sms';
import { User } from '../redux/modules/users';
import { Group } from '../redux/modules/groups';
import { Device, isMobileDevice } from '../redux/modules/devices';

import { Voicemail } from '../redux/modules/voicemails';
import { ApiEndpointType } from '../api/types/events';
import { Phoneline } from '../api/types/phonelines';
import { Faxline } from '../api/types/faxlines';
import { ApiSim } from '../api/types/sim';
import { ConferenceRoom } from '../redux/slices/conferenceRoom';
import { Acd } from '../redux/slices/acds';

export type Endpoint =
	| Phoneline
	| Device
	| Group
	| Faxline
	| Voicemail
	| Sms
	| User
	| ApiSim
	| ConferenceRoom
	| Acd;

export type EndpointType = ApiEndpointType;

export type EndpointId = {
	type: EndpointType;
	extension: string;
};

export interface ExtensionState {
	sms: Sms[];
	users: User[];
	groups: Group[];
	devices: Device[];
	faxlines: Faxline[];
	phonelines: Phoneline[];
	voicemails: Voicemail[];
	conferenceRooms: ConferenceRoom[];
	acds: Acd[];
}

const getMobileDevice = (state: ExtensionState, endpoint: EndpointId): Endpoint | undefined => {
	const matchingPool = state.devices.find(device => device.id === endpoint.extension);
	if (matchingPool) {
		return matchingPool;
	}

	for (const pool of state.devices.filter(isMobileDevice)) {
		const sim = pool.sims.find(s => s.id === endpoint.extension);

		if (sim) {
			return sim;
		}
	}
};

export const resolveEndpoint = (
	state: ExtensionState,
	endpoint: EndpointId
): Endpoint | string | undefined => {
	switch (endpoint.type) {
		case 'PHONELINE':
			return state.phonelines.find(phoneline => phoneline.id === endpoint.extension);
		case 'REGISTER':
		case 'EXTERNAL':
			return state.devices.find(device => device.id === endpoint.extension);
		case 'MOBILE':
			return getMobileDevice(state, endpoint);
		case 'FAX':
			return state.faxlines.find(faxline => faxline.id === endpoint.extension);
		case 'VOICEMAIL':
			return state.voicemails.find(voicemail => voicemail.id === endpoint.extension);
		case 'GROUP':
			return state.groups.find(group => group.id === endpoint.extension);
		case 'SMS':
			return state.sms.find(sms => sms.id === endpoint.extension);
		case 'USER':
			return state.users.find(user => user.id === endpoint.extension);
		case 'NUMBER':
			return endpoint.extension;
		case 'CONFERENCEROOM':
			return state.conferenceRooms.find(conferenceRoom => conferenceRoom.id === endpoint.extension);
		case 'ACD':
			return state.acds.find(acd => acd.id === endpoint.extension);

		default:
			return undefined;
	}
};

export const createEndpointIdFromExtension = (extension: string): EndpointId | undefined => {
	if (extension.match(/^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/)) {
		return { type: 'ACD', extension };
	}

	switch (extension.substring(0, 1)) {
		case 'g':
			return { type: 'GROUP', extension };
		case 'p':
			return { type: 'PHONELINE', extension };
		case 'e':
			return { type: 'REGISTER', extension };
		case 'x':
			return { type: 'EXTERNAL', extension };
		case 'i':
		case 'y':
			return { type: 'MOBILE', extension };
		case 'f':
			return { type: 'FAX', extension };
		case 'v':
			return { type: 'VOICEMAIL', extension };
		case 's':
			return { type: 'SMS', extension };
		case 'w':
			return { type: 'USER', extension };
		case 'c':
			return { type: 'CONFERENCEROOM', extension };
	}
};
