import { createAction } from '../../..';
import api from '../../../../api';
import { ReduxState } from '../../../types';

export const fetchTrunkCallRestrictions = createAction('CALLRESTRICTIONS_TRUNK_FETCH', () => ({
	promise: () => api.getTrunkCallRestrictions(),
	shouldFetch: (state: ReduxState) =>
		!state.trunkCallRestrictions.fetched && !state.trunkCallRestrictions.fetching,
}));

export const forceFetchTrunkCallRestrictions = createAction('CALLRESTRICTIONS_TRUNK_FETCH', () => ({
	promise: () => api.getTrunkCallRestrictions(),
}));

export const setTrunkCallRestrictions = createAction(
	'CALLRESTRICTIONS_TRUNK_SET',
	(
		id: string,
		restriction: 'foreignSpecial' | 'charged' | 'highCostForeignNumbers',
		enabled: boolean
	) => ({
		promise: () => api.setTrunkCallRestrictions(id, restriction, enabled),
		data: { id, restriction, enabled },
	})
);
