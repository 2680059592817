import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { connect, ReduxProps } from '../redux';

import { fetchUserInfo } from '../redux/modules/userinfo';
import { ReduxState } from '../redux/types';

type Props = ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> & RouteComponentProps;

const mapDispatchToProps = {
	fetchUserInfo,
};

const mapStateToProps = (state: ReduxState) => ({
	userinfo: state.userinfo,
});

class UserRedirect extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchUserInfo();
	}

	public render() {
		if (!this.props.userinfo.fetched) {
			return null;
		}

		return (
			<Redirect
				to={{
					pathname: `/${this.props.match.params[0]}`,
					hash: this.props.location.hash,
					search: this.props.location.search,
				}}
			/>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRedirect);
