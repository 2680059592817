import api from '../../../api';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';

const shouldFetchPhonenumbers = (state: ReduxState) =>
	!state.phonenumbers.fetched && !state.phonenumbers.fetching;

export const fetchPhonenumbers = createAsyncThunk(
	'phonenumbers/fetch',
	async () => api.getPhonenumbers(),
	{
		condition: (_arg, { getState }) => shouldFetchPhonenumbers(getState()),
	}
);

export const releaseMobilePhonenumber = createAsyncThunk(
	'phonenumbers/releaseMobile',
	async (data: { e164Numbers: string[] }) => api.releaseMobilePhonenumber(data.e164Numbers)
);

export const setRoutings = createAsyncThunk(
	'phonenumbers/setRoutings',
	async (data: { e164Numbers: string[]; extensionId: string }) =>
		api.setRoutings(data.e164Numbers, data.extensionId)
);

export const setChannelRoutings = createAsyncThunk(
	'phonenumbers/setChannelRoutings',
	async (data: { e164Numbers: string[]; channelId: string }) =>
		api.setChannelRoutings(data.e164Numbers, data.channelId)
);

export const deleteRoutings = createAsyncThunk(
	'phonenumbers/deleteRoutings',
	async (data: { e164Numbers: string[] }) => api.deleteRoutings(data.e164Numbers)
);

export const cancelContract = createAsyncThunk(
	'phonenumbers/cancelContract',
	async (data: { contractId: string }) => api.cancelPhonenumberContract(data.contractId)
);

export const revokeContractCancellation = createAsyncThunk(
	'phonenumbers/revokeContractCancellation',
	async (data: { contractId: string }) => api.revokePhonenumberContractCancellation(data.contractId)
);

export const forceFetchPhonenumbers = createAsyncThunk('phonenumbers/fetch', async () =>
	api.getPhonenumbers()
);
