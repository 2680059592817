import React from 'react';
import { ManagedFieldValidator } from '@web-apps/forms';
import { InternationalCountryCode, UsState } from '../../api/types/addresses';
import { formatUsState } from '../../components/address/Address';
import { Translate } from '../../redux/slices/translations';

export type DeFormAddress = {
	countryCode: 'DE';
	zip: string;
	city: string;
	street: string;
	number: string;
	additional: string;
};

export type InternationalFormAddress = {
	countryCode: InternationalCountryCode;
	zip: string;
	city: string;
	address: string;
	additional: string;
};

export type UsFormAddress = {
	countryCode: 'US';
	zip: string;
	city: string;
	address: string;
	additional: string;
	state: UsState;
};

export type GbFormAddress = {
	countryCode: 'GB';
	zip: string;
	city: string;
	address: string;
	additional: string;
	state: string;
	businessSuffix: string;
};

export const translateCountry = (translate: Translate, countryCode: string) => {
	switch (countryCode) {
		case 'DE':
		case 'DEU':
			return translate('LOCATIONS_COUNTRY_DE');
		case 'AT':
		case 'AUT':
			return translate('LOCATIONS_COUNTRY_AT');
		case 'CH':
		case 'CHE':
			return translate('LOCATIONS_COUNTRY_CH');
		case 'AC':
		case 'ASC': // Assuming 'AC' is a code for some country or territory.
			return translate('LOCATIONS_COUNTRY_AC');
		case 'AD':
		case 'AND':
			return translate('LOCATIONS_COUNTRY_AD');
		case 'AE':
		case 'ARE':
			return translate('LOCATIONS_COUNTRY_AE');
		case 'AF':
		case 'AFG':
			return translate('LOCATIONS_COUNTRY_AF');
		case 'AG':
		case 'ATG':
			return translate('LOCATIONS_COUNTRY_AG');
		case 'AI':
		case 'AIA':
			return translate('LOCATIONS_COUNTRY_AI');
		case 'AL':
		case 'ALB':
			return translate('LOCATIONS_COUNTRY_AL');
		case 'AM':
		case 'ARM':
			return translate('LOCATIONS_COUNTRY_AM');
		case 'AN': // AN is not an official ISO country code anymore (used for the Netherlands Antilles)
			return translate('LOCATIONS_COUNTRY_AN');
		case 'AO':
		case 'AGO':
			return translate('LOCATIONS_COUNTRY_AO');
		case 'AQ':
		case 'ATA':
			return translate('LOCATIONS_COUNTRY_AQ');
		case 'AR':
		case 'ARG':
			return translate('LOCATIONS_COUNTRY_AR');
		case 'AS':
		case 'ASM':
			return translate('LOCATIONS_COUNTRY_AS');
		case 'AU':
		case 'AUS':
			return translate('LOCATIONS_COUNTRY_AU');
		case 'AW':
		case 'ABW':
			return translate('LOCATIONS_COUNTRY_AW');
		case 'AZ':
		case 'AZE':
			return translate('LOCATIONS_COUNTRY_AZ');
		case 'BA':
		case 'BIH':
			return translate('LOCATIONS_COUNTRY_BA');
		case 'BB':
		case 'BRB':
			return translate('LOCATIONS_COUNTRY_BB');
		case 'BD':
		case 'BGD':
			return translate('LOCATIONS_COUNTRY_BD');
		case 'BE':
		case 'BEL':
			return translate('LOCATIONS_COUNTRY_BE');
		case 'BF':
		case 'BFA':
			return translate('LOCATIONS_COUNTRY_BF');
		case 'BG':
		case 'BGR':
			return translate('LOCATIONS_COUNTRY_BG');
		case 'BH':
		case 'BHR':
			return translate('LOCATIONS_COUNTRY_BH');
		case 'BI':
		case 'BDI':
			return translate('LOCATIONS_COUNTRY_BI');
		case 'BJ':
		case 'BEN':
			return translate('LOCATIONS_COUNTRY_BJ');
		case 'BM':
		case 'BMU':
			return translate('LOCATIONS_COUNTRY_BM');
		case 'BN':
		case 'BRN':
			return translate('LOCATIONS_COUNTRY_BN');
		case 'BO':
		case 'BOL':
			return translate('LOCATIONS_COUNTRY_BO');
		case 'BR':
		case 'BRA':
			return translate('LOCATIONS_COUNTRY_BR');
		case 'BS':
		case 'BHS':
			return translate('LOCATIONS_COUNTRY_BS');
		case 'BT':
		case 'BTN':
			return translate('LOCATIONS_COUNTRY_BT');
		case 'BV':
		case 'BVT':
			return translate('LOCATIONS_COUNTRY_BV');
		case 'BW':
		case 'BWA':
			return translate('LOCATIONS_COUNTRY_BW');
		case 'BY':
		case 'BLR':
			return translate('LOCATIONS_COUNTRY_BY');
		case 'BZ':
		case 'BLZ':
			return translate('LOCATIONS_COUNTRY_BZ');
		case 'CA':
		case 'CAN':
			return translate('LOCATIONS_COUNTRY_CA');
		case 'CC':
		case 'CCK':
			return translate('LOCATIONS_COUNTRY_CC');
		case 'CD':
		case 'COD':
			return translate('LOCATIONS_COUNTRY_CD');
		case 'CF':
		case 'CAF':
			return translate('LOCATIONS_COUNTRY_CF');
		case 'CG':
		case 'COG':
			return translate('LOCATIONS_COUNTRY_CG');
		case 'CI':
		case 'CIV':
			return translate('LOCATIONS_COUNTRY_CI');
		case 'CK':
		case 'COK':
			return translate('LOCATIONS_COUNTRY_CK');
		case 'CL':
		case 'CHL':
			return translate('LOCATIONS_COUNTRY_CL');
		case 'CM':
		case 'CMR':
			return translate('LOCATIONS_COUNTRY_CM');
		case 'CN':
		case 'CHN':
			return translate('LOCATIONS_COUNTRY_CN');
		case 'CO':
		case 'COL':
			return translate('LOCATIONS_COUNTRY_CO');
		case 'CR':
		case 'CRI':
			return translate('LOCATIONS_COUNTRY_CR');
		case 'CU':
		case 'CUB':
			return translate('LOCATIONS_COUNTRY_CU');
		case 'CV':
		case 'CPV':
			return translate('LOCATIONS_COUNTRY_CV');
		case 'CX':
		case 'CXR':
			return translate('LOCATIONS_COUNTRY_CX');
		case 'CY':
		case 'CYP':
			return translate('LOCATIONS_COUNTRY_CY');
		case 'CZ':
		case 'CZE':
			return translate('LOCATIONS_COUNTRY_CZ');
		case 'DG':
			return translate('LOCATIONS_COUNTRY_DG');
		case 'DJ':
		case 'DJI':
			return translate('LOCATIONS_COUNTRY_DJ');
		case 'DK':
		case 'DNK':
			return translate('LOCATIONS_COUNTRY_DK');
		case 'DM':
		case 'DMA':
			return translate('LOCATIONS_COUNTRY_DM');
		case 'DO':
		case 'DOM':
			return translate('LOCATIONS_COUNTRY_DO');
		case 'DZ':
		case 'DZA':
			return translate('LOCATIONS_COUNTRY_DZ');
		case 'EC':
		case 'ECU':
			return translate('LOCATIONS_COUNTRY_EC');
		case 'EE':
		case 'EST':
			return translate('LOCATIONS_COUNTRY_EE');
		case 'EG':
		case 'EGY':
			return translate('LOCATIONS_COUNTRY_EG');
		case 'EH':
		case 'ESH':
			return translate('LOCATIONS_COUNTRY_EH');
		case 'ER':
		case 'ERI':
			return translate('LOCATIONS_COUNTRY_ER');
		case 'ES':
		case 'ESP':
			return translate('LOCATIONS_COUNTRY_ES');
		case 'ET':
		case 'ETH':
			return translate('LOCATIONS_COUNTRY_ET');
		case 'FI':
		case 'FIN':
			return translate('LOCATIONS_COUNTRY_FI');
		case 'FJ':
		case 'FJI':
			return translate('LOCATIONS_COUNTRY_FJ');
		case 'FK':
		case 'FLK':
			return translate('LOCATIONS_COUNTRY_FK');
		case 'FM':
		case 'FSM':
			return translate('LOCATIONS_COUNTRY_FM');
		case 'FO':
		case 'FRO':
			return translate('LOCATIONS_COUNTRY_FO');
		case 'FR':
		case 'FRA':
			return translate('LOCATIONS_COUNTRY_FR');
		case 'GA':
		case 'GAB':
			return translate('LOCATIONS_COUNTRY_GA');
		case 'GB':
		case 'GBR':
			return translate('LOCATIONS_COUNTRY_GB');
		case 'GD':
		case 'GRD':
			return translate('LOCATIONS_COUNTRY_GD');
		case 'GE':
		case 'GEO':
			return translate('LOCATIONS_COUNTRY_GE');
		case 'GF':
		case 'GUF':
			return translate('LOCATIONS_COUNTRY_GF');
		case 'GH':
		case 'GHA':
			return translate('LOCATIONS_COUNTRY_GH');
		case 'GI':
		case 'GIB':
			return translate('LOCATIONS_COUNTRY_GI');
		case 'GL':
		case 'GRL':
			return translate('LOCATIONS_COUNTRY_GL');
		case 'GM':
		case 'GMB':
			return translate('LOCATIONS_COUNTRY_GM');
		case 'GN':
		case 'GIN':
			return translate('LOCATIONS_COUNTRY_GN');
		case 'GP':
		case 'GLP':
			return translate('LOCATIONS_COUNTRY_GP');
		case 'GQ':
		case 'GNQ':
			return translate('LOCATIONS_COUNTRY_GQ');
		case 'GR':
		case 'GRC':
			return translate('LOCATIONS_COUNTRY_GR');
		case 'GT':
		case 'GTM':
			return translate('LOCATIONS_COUNTRY_GT');
		case 'GU':
		case 'GUM':
			return translate('LOCATIONS_COUNTRY_GU');
		case 'GW':
		case 'GNB':
			return translate('LOCATIONS_COUNTRY_GW');
		case 'GY':
		case 'GUY':
			return translate('LOCATIONS_COUNTRY_GY');
		case 'HK':
		case 'HKG':
			return translate('LOCATIONS_COUNTRY_HK');
		case 'HN':
		case 'HND':
			return translate('LOCATIONS_COUNTRY_HN');
		case 'HR':
		case 'HRV':
			return translate('LOCATIONS_COUNTRY_HR');
		case 'HT':
		case 'HTI':
			return translate('LOCATIONS_COUNTRY_HT');
		case 'HU':
		case 'HUN':
			return translate('LOCATIONS_COUNTRY_HU');
		case 'ID':
		case 'IDN':
			return translate('LOCATIONS_COUNTRY_ID');
		case 'IE':
		case 'IRL':
			return translate('LOCATIONS_COUNTRY_IE');
		case 'IL':
		case 'ISR':
			return translate('LOCATIONS_COUNTRY_IL');
		case 'IN':
		case 'IND':
			return translate('LOCATIONS_COUNTRY_IN');
		case 'IO':
		case 'IOT':
			return translate('LOCATIONS_COUNTRY_IO');
		case 'IQ':
		case 'IRQ':
			return translate('LOCATIONS_COUNTRY_IQ');
		case 'IR':
		case 'IRN':
			return translate('LOCATIONS_COUNTRY_IR');
		case 'IS':
		case 'ISL':
			return translate('LOCATIONS_COUNTRY_IS');
		case 'IT':
		case 'ITA':
			return translate('LOCATIONS_COUNTRY_IT');
		case 'JM':
		case 'JAM':
			return translate('LOCATIONS_COUNTRY_JM');
		case 'JO':
		case 'JOR':
			return translate('LOCATIONS_COUNTRY_JO');
		case 'JP':
		case 'JPN':
			return translate('LOCATIONS_COUNTRY_JP');
		case 'KE':
		case 'KEN':
			return translate('LOCATIONS_COUNTRY_KE');
		case 'KG':
		case 'KGZ':
			return translate('LOCATIONS_COUNTRY_KG');
		case 'KH':
		case 'KHM':
			return translate('LOCATIONS_COUNTRY_KH');
		case 'KI':
		case 'KIR':
			return translate('LOCATIONS_COUNTRY_KI');
		case 'KM':
		case 'COM':
			return translate('LOCATIONS_COUNTRY_KM');
		case 'KN':
		case 'KNA':
			return translate('LOCATIONS_COUNTRY_KN');
		case 'KP':
		case 'PRK':
			return translate('LOCATIONS_COUNTRY_KP');
		case 'KR':
		case 'KOR':
			return translate('LOCATIONS_COUNTRY_KR');
		case 'KW':
		case 'KWT':
			return translate('LOCATIONS_COUNTRY_KW');
		case 'KY':
		case 'CYM':
			return translate('LOCATIONS_COUNTRY_KY');
		case 'KZ':
		case 'KAZ':
			return translate('LOCATIONS_COUNTRY_KZ');
		case 'LA':
		case 'LAO':
			return translate('LOCATIONS_COUNTRY_LA');
		case 'LB':
		case 'LBN':
			return translate('LOCATIONS_COUNTRY_LB');
		case 'LC':
		case 'LCA':
			return translate('LOCATIONS_COUNTRY_LC');
		case 'LI':
		case 'LIE':
			return translate('LOCATIONS_COUNTRY_LI');
		case 'LK':
		case 'LKA':
			return translate('LOCATIONS_COUNTRY_LK');
		case 'LR':
		case 'LBR':
			return translate('LOCATIONS_COUNTRY_LR');
		case 'LS':
		case 'LSO':
			return translate('LOCATIONS_COUNTRY_LS');
		case 'LT':
		case 'LTU':
			return translate('LOCATIONS_COUNTRY_LT');
		case 'LU':
		case 'LUX':
			return translate('LOCATIONS_COUNTRY_LU');
		case 'LV':
		case 'LVA':
			return translate('LOCATIONS_COUNTRY_LV');
		case 'LY':
		case 'LBY':
			return translate('LOCATIONS_COUNTRY_LY');
		case 'MA':
		case 'MAR':
			return translate('LOCATIONS_COUNTRY_MA');
		case 'MC':
		case 'MCO':
			return translate('LOCATIONS_COUNTRY_MC');
		case 'MD':
		case 'MDA':
			return translate('LOCATIONS_COUNTRY_MD');
		case 'MG':
		case 'MDG':
			return translate('LOCATIONS_COUNTRY_MG');
		case 'MH':
		case 'MHL':
			return translate('LOCATIONS_COUNTRY_MH');
		case 'MK':
		case 'MKD':
			return translate('LOCATIONS_COUNTRY_MK');
		case 'ML':
		case 'MLI':
			return translate('LOCATIONS_COUNTRY_ML');
		case 'MM':
		case 'MMR':
			return translate('LOCATIONS_COUNTRY_MM');
		case 'MN':
		case 'MNG':
			return translate('LOCATIONS_COUNTRY_MN');
		case 'MO':
		case 'MAC':
			return translate('LOCATIONS_COUNTRY_MO');
		case 'MP':
		case 'MNP':
			return translate('LOCATIONS_COUNTRY_MP');
		case 'MQ':
		case 'MTQ':
			return translate('LOCATIONS_COUNTRY_MQ');
		case 'MR':
		case 'MRT':
			return translate('LOCATIONS_COUNTRY_MR');
		case 'MS':
		case 'MSR':
			return translate('LOCATIONS_COUNTRY_MS');
		case 'MT':
		case 'MLT':
			return translate('LOCATIONS_COUNTRY_MT');
		case 'MU':
		case 'MUS':
			return translate('LOCATIONS_COUNTRY_MU');
		case 'MV':
		case 'MDV':
			return translate('LOCATIONS_COUNTRY_MV');
		case 'MW':
		case 'MWI':
			return translate('LOCATIONS_COUNTRY_MW');
		case 'MX':
		case 'MEX':
			return translate('LOCATIONS_COUNTRY_MX');
		case 'MY':
		case 'MYS':
			return translate('LOCATIONS_COUNTRY_MY');
		case 'MZ':
		case 'MOZ':
			return translate('LOCATIONS_COUNTRY_MZ');
		case 'NA':
		case 'NAM':
			return translate('LOCATIONS_COUNTRY_NA');
		case 'NC':
		case 'NCL':
			return translate('LOCATIONS_COUNTRY_NC');
		case 'NE':
		case 'NER':
			return translate('LOCATIONS_COUNTRY_NE');
		case 'NF':
		case 'NFK':
			return translate('LOCATIONS_COUNTRY_NF');
		case 'NG':
		case 'NGA':
			return translate('LOCATIONS_COUNTRY_NG');
		case 'NI':
		case 'NIC':
			return translate('LOCATIONS_COUNTRY_NI');
		case 'NL':
		case 'NLD':
			return translate('LOCATIONS_COUNTRY_NL');
		case 'NO':
		case 'NOR':
			return translate('LOCATIONS_COUNTRY_NO');
		case 'NP':
		case 'NPL':
			return translate('LOCATIONS_COUNTRY_NP');
		case 'NR':
		case 'NRU':
			return translate('LOCATIONS_COUNTRY_NR');
		case 'NU':
		case 'NIU':
			return translate('LOCATIONS_COUNTRY_NU');
		case 'NZ':
		case 'NZL':
			return translate('LOCATIONS_COUNTRY_NZ');
		case 'OM':
		case 'OMN':
			return translate('LOCATIONS_COUNTRY_OM');
		case 'PA':
		case 'PAN':
			return translate('LOCATIONS_COUNTRY_PA');
		case 'PE':
		case 'PER':
			return translate('LOCATIONS_COUNTRY_PE');
		case 'PF':
		case 'PYF':
			return translate('LOCATIONS_COUNTRY_PF');
		case 'PG':
		case 'PNG':
			return translate('LOCATIONS_COUNTRY_PG');
		case 'PH':
		case 'PHL':
			return translate('LOCATIONS_COUNTRY_PH');
		case 'PK':
		case 'PAK':
			return translate('LOCATIONS_COUNTRY_PK');
		case 'PL':
		case 'POL':
			return translate('LOCATIONS_COUNTRY_PL');
		case 'PM':
		case 'SPM':
			return translate('LOCATIONS_COUNTRY_PM');
		case 'PN':
		case 'PCN':
			return translate('LOCATIONS_COUNTRY_PN');
		case 'PR':
		case 'PRI':
			return translate('LOCATIONS_COUNTRY_PR');
		case 'PS':
		case 'PSE':
			return translate('LOCATIONS_COUNTRY_PS');
		case 'PT':
		case 'PRT':
			return translate('LOCATIONS_COUNTRY_PT');
		case 'PW':
		case 'PLW':
			return translate('LOCATIONS_COUNTRY_PW');
		case 'PY':
		case 'PRY':
			return translate('LOCATIONS_COUNTRY_PY');
		case 'QA':
		case 'QAT':
			return translate('LOCATIONS_COUNTRY_QA');
		case 'RE':
		case 'REU':
			return translate('LOCATIONS_COUNTRY_RE');
		case 'RO':
		case 'ROU':
			return translate('LOCATIONS_COUNTRY_RO');
		case 'RS':
		case 'SRB':
			return translate('LOCATIONS_COUNTRY_RS');
		case 'RU':
		case 'RUS':
			return translate('LOCATIONS_COUNTRY_RU');
		case 'RW':
		case 'RWA':
			return translate('LOCATIONS_COUNTRY_RW');
		case 'SA':
		case 'SAU':
			return translate('LOCATIONS_COUNTRY_SA');
		case 'SB':
		case 'SLB':
			return translate('LOCATIONS_COUNTRY_SB');
		case 'SC':
		case 'SYC':
			return translate('LOCATIONS_COUNTRY_SC');
		case 'SD':
		case 'SDN':
			return translate('LOCATIONS_COUNTRY_SD');
		case 'SE':
		case 'SWE':
			return translate('LOCATIONS_COUNTRY_SE');
		case 'SG':
		case 'SGP':
			return translate('LOCATIONS_COUNTRY_SG');
		case 'SH':
		case 'SHN':
			return translate('LOCATIONS_COUNTRY_SH');
		case 'SI':
		case 'SVN':
			return translate('LOCATIONS_COUNTRY_SI');
		case 'SJ':
		case 'SJM':
			return translate('LOCATIONS_COUNTRY_SJ');
		case 'SK':
		case 'SVK':
			return translate('LOCATIONS_COUNTRY_SK');
		case 'SL':
		case 'SLE':
			return translate('LOCATIONS_COUNTRY_SL');
		case 'SM':
		case 'SMR':
			return translate('LOCATIONS_COUNTRY_SM');
		case 'SN':
		case 'SEN':
			return translate('LOCATIONS_COUNTRY_SN');
		case 'SO':
		case 'SOM':
			return translate('LOCATIONS_COUNTRY_SO');
		case 'SR':
		case 'SUR':
			return translate('LOCATIONS_COUNTRY_SR');
		case 'ST':
		case 'STP':
			return translate('LOCATIONS_COUNTRY_ST');
		case 'SV':
		case 'SLV':
			return translate('LOCATIONS_COUNTRY_SV');
		case 'SY':
		case 'SYR':
			return translate('LOCATIONS_COUNTRY_SY');
		case 'SZ':
		case 'SWZ':
			return translate('LOCATIONS_COUNTRY_SZ');
		case 'TC':
		case 'TCA':
			return translate('LOCATIONS_COUNTRY_TC');
		case 'TD':
		case 'TCD':
			return translate('LOCATIONS_COUNTRY_TD');
		case 'TG':
		case 'TGO':
			return translate('LOCATIONS_COUNTRY_TG');
		case 'TH':
		case 'THA':
			return translate('LOCATIONS_COUNTRY_TH');
		case 'TJ':
		case 'TJK':
			return translate('LOCATIONS_COUNTRY_TJ');
		case 'TK':
		case 'TKL':
			return translate('LOCATIONS_COUNTRY_TK');
		case 'TM':
		case 'TKM':
			return translate('LOCATIONS_COUNTRY_TM');
		case 'TN':
		case 'TUN':
			return translate('LOCATIONS_COUNTRY_TN');
		case 'TO':
		case 'TON':
			return translate('LOCATIONS_COUNTRY_TO');
		case 'TR':
		case 'TUR':
			return translate('LOCATIONS_COUNTRY_TR');
		case 'TT':
		case 'TTO':
			return translate('LOCATIONS_COUNTRY_TT');
		case 'TV':
		case 'TUV':
			return translate('LOCATIONS_COUNTRY_TV');
		case 'TW':
		case 'TWN':
			return translate('LOCATIONS_COUNTRY_TW');
		case 'TZ':
		case 'TZA':
			return translate('LOCATIONS_COUNTRY_TZ');
		case 'UA':
		case 'UKR':
			return translate('LOCATIONS_COUNTRY_UA');
		case 'UG':
		case 'UGA':
			return translate('LOCATIONS_COUNTRY_UG');
		case 'US':
		case 'USA':
			return translate('LOCATIONS_COUNTRY_US');
		case 'UY':
		case 'URY':
			return translate('LOCATIONS_COUNTRY_UY');
		case 'UZ':
		case 'UZB':
			return translate('LOCATIONS_COUNTRY_UZ');
		case 'VA':
		case 'VAT':
			return translate('LOCATIONS_COUNTRY_VA');
		case 'VC':
		case 'VCT':
			return translate('LOCATIONS_COUNTRY_VC');
		case 'VE':
		case 'VEN':
			return translate('LOCATIONS_COUNTRY_VE');
		case 'VG':
		case 'VGB':
			return translate('LOCATIONS_COUNTRY_VG');
		case 'VI':
		case 'VIR':
			return translate('LOCATIONS_COUNTRY_VI');
		case 'VN':
		case 'VNM':
			return translate('LOCATIONS_COUNTRY_VN');
		case 'VU':
		case 'VUT':
			return translate('LOCATIONS_COUNTRY_VU');
		case 'WF':
		case 'WLF':
			return translate('LOCATIONS_COUNTRY_WF');
		case 'WS':
		case 'WSM':
			return translate('LOCATIONS_COUNTRY_WS');
		case 'YE':
		case 'YEM':
			return translate('LOCATIONS_COUNTRY_YE');
		case 'YT':
		case 'MYT':
			return translate('LOCATIONS_COUNTRY_YT');
		case 'ZA':
		case 'ZAF':
			return translate('LOCATIONS_COUNTRY_ZA');
		case 'ZM':
		case 'ZMB':
			return translate('LOCATIONS_COUNTRY_ZM');
		case 'ZW':
		case 'ZWE':
			return translate('LOCATIONS_COUNTRY_ZW');

		case 'YU':
			// Previously Yugoslavia, but it doesn't exist anymore. Legacy data.
			return translate('LOCATIONS_COUNTRY_UNKNOWN');
	}

	return translate('LOCATIONS_COUNTRY_UNKNOWN');
};

export const renderGermanFormAddress = (translate: Translate, address: DeFormAddress) => {
	return (
		<>
			{address.street} {address.number}
			<br />
			{address.additional && (
				<>
					{address.additional}
					<br />
				</>
			)}
			{address.zip} {address.city}
			<br />
			{translateCountry(translate, address.countryCode)}
		</>
	);
};

export const renderInternationalFormAddress = (
	translate: Translate,
	address: InternationalFormAddress
) => {
	return (
		<>
			{address.address}
			<br />
			{address.additional && (
				<>
					{address.additional}
					<br />
				</>
			)}
			{address.zip} {address.city}
			<br />
			{translateCountry(translate, address.countryCode)}
		</>
	);
};

export const renderUsFormAddress = (translate: Translate, address: UsFormAddress) => {
	return (
		<>
			{address.address}
			<br />
			{address.additional && (
				<>
					{address.additional}
					<br />
				</>
			)}
			{address.zip} {address.city}
			<br />
			{formatUsState(address.state)}
			<br />
			{translateCountry(translate, address.countryCode)}
		</>
	);
};

export const renderGbFormAddress = (translate: Translate, address: GbFormAddress) => {
	return (
		<>
			{address.address}
			<br />
			{address.additional && (
				<>
					{address.additional}
					<br />
				</>
			)}
			{address.zip} {address.city}
			<br />
			{address.state && (
				<>
					{address.state}
					<br />
				</>
			)}
			{translateCountry(translate, address.countryCode)}
		</>
	);
};

/*
 * Only exists because our backend is too gosh-darn dumb to print non-latin1-extended
 * text onto letters. This is bad! If you are tempted to use this validator, please
 * make sure there is absolutely no way to get your backends to work with proper unicode
 * text first!
 */
export const validateLatin1Extended =
	(translate: Translate): ManagedFieldValidator<string, string> =>
	(value: string) => {
		for (let i = 0; i < value.length; i += 1) {
			if (value.charCodeAt(i) > 255) {
				return {
					valid: false,
					error: translate('ERRORHINT_NON_LATIN1_EXTENDED'),
				};
			}
		}

		return {
			valid: true,
			value,
		};
	};
