import { Store } from '@reduxjs/toolkit';
import { SaveNotePendingAction } from './types';
import api from '../../../../../api';
import { fetchHistory } from '../fetchHistory/action';
import { State } from '../../state';
import { Selections } from '../../../selections/parsers';

export const saveNote =
	(store: Store<State>) => async (eventId: string, note: string, selections: Selections) => {
		store.dispatch<SaveNotePendingAction>({ type: 'SAVE_NOTE_PENDING', eventId, note });

		try {
			await api.updateHistoryEntry(eventId, { note });
		} catch (e) {
			/* Reload clean data */
			fetchHistory(store)(selections);

			throw e;
		}
	};
