import api from '../../../api';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';

const shouldFetchIncomingBlocklistEntries = (state: ReduxState) =>
	!state.blocklist.fetched && !state.blocklist.fetching;

export const createBlocklistEntry = createAsyncThunk(
	'blocklist/createEntry',
	async ({ phoneNumber, isBlock }: { phoneNumber: string; isBlock: boolean }) =>
		api.createIncomingBlacklistEntry(phoneNumber, isBlock)
);

export const deleteBlocklistEntry = createAsyncThunk(
	'blocklist/deleteEntry',
	async (phoneNumber: string) => api.deleteIncomingBlacklistEntry(phoneNumber)
);

export const fetchBlocklistEntries = createAsyncThunk(
	'blocklist/fetchEntries',
	async (_forceFetch?: boolean) => api.getIncomingBlacklistEntries(),
	{
		condition: (forceFetch, { getState }) =>
			forceFetch === true || shouldFetchIncomingBlocklistEntries(getState()),
	}
);

export const updateBlocklistEntry = createAsyncThunk(
	'blocklist/updateEntry',
	async ({
		oldPhoneNumber,
		newPhoneNumber,
		isBlock,
	}: {
		oldPhoneNumber: string;
		newPhoneNumber: string;
		isBlock: boolean;
	}) => {
		await api.deleteIncomingBlacklistEntry(oldPhoneNumber);
		return api.createIncomingBlacklistEntry(newPhoneNumber, isBlock);
	}
);
