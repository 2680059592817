import { getE164Number } from '@web-apps/phonenumbers-utils';
import { Contact, PersistedContact } from '../types';
import { SipgateDomain } from '../../userinfo';

export * from './search';

export function findContactsForPhonenumber(
	contacts: Contact[],
	number: string,
	domain: SipgateDomain
) {
	const e164Number = getE164Number(number, domain);

	return contacts.filter(contact => !!contact.numbers.find(num => num.number === e164Number));
}

export function selectExternalContacts<T extends Contact>(contacts: T[]) {
	return contacts.filter(contact => contact.scope !== 'INTERNAL') as (T & Contact)[];
}

export function selectPersistedContacts<T extends Contact>(contacts: T[]) {
	return contacts.filter(contact => contact.id !== null) as (T & PersistedContact)[];
}

export const selectContactByContactId = (
	contacts: Contact[],
	contactId: string
): Contact | undefined => contacts.find(contact => contact.id === contactId);
